import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useField } from 'formik';
import { Contact as ContactType } from '../../graphql/types';
import useContactTable from '../../hooks/useContactTable';
import AppListTable from '../../components/tables/list-table/AppListTable';
import SupplierElpevFromBaseDB from '../../supplier/components/SupplierElpevFromBaseDB';

interface ClientContactProps {
	onClickImportClientFromBaseDB?: () => void;
	formikContactName: string;
	contactDisplayLimit: number;
	title: string;
}

const Contact = ({ formikContactName, contactDisplayLimit, title }: ClientContactProps) => {
	const [field] = useField<ContactType[] | null>(formikContactName);
	const contacts = useMemo(
		() => field.value?.map(({ id, ...rest }, index) => ({ ...rest, id: id ?? index.toString() })),
		[field.value]
	);
	const tableInstance = useContactTable(contacts ?? [], contactDisplayLimit);

	return (
		<Box mb={5} pt={5}>
			<Grid container>
				<Grid item xs={1}></Grid>
				<SupplierElpevFromBaseDB title={title} />
			</Grid>

			<Grid container>
				<Grid item xs={2}></Grid>
				<Grid item xs={10}>
					<Box mb={5} width={'100%'}>
						<AppListTable tableInstance={tableInstance} />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Contact;
