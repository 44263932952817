import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useField } from 'formik';
import React, { useState } from 'react';
import { TransportModeOption } from '../definitions';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material';

export interface TransportAutocompleteFieldProps {
	name: string;
	placeholder: string;
	options?: Array<TransportModeOption>;
	margin?: 'none' | 'dense' | 'normal';
	fullWidth?: boolean;
	tagCreator?: (title: string) => TransportModeOption;
}

const TransportAutocompleteField = ({
	name,
	placeholder,
	options = [],
	fullWidth,
	margin,
}: TransportAutocompleteFieldProps): JSX.Element => {
	const [{ onBlur, value }, meta, helper] = useField<TransportModeOption[]>(name);
	const [inputValue, setInputValue] = useState<string>('');

	const handleChange = (
		event: React.ChangeEvent<{}>,
		newValue: TransportModeOption[] | null,
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<TransportModeOption | string>
	) => {
		if (!newValue) return;
		if (reason === 'createOption' && details && typeof details.option === 'string') {
			helper.setValue([...value, { title: details.option }]);
		} else if (newValue) {
			helper.setValue(newValue);
		}
		helper.setTouched(true);
	};

	return (
		<Autocomplete
			autoComplete
			multiple={true}
			options={options}
			limitTags={2}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			freeSolo
			renderTags={(options, getTagProps) =>
				options.map(({ title }, index) => <Chip label={title} {...getTagProps({ index })} />)
			}
			renderInput={(params) => (
				<TextField
					{...params}
					name={name}
					error={meta.touched && Boolean(meta.error)}
					helperText={meta.touched && meta.error}
					variant="outlined"
					margin={margin}
					fullWidth={fullWidth}
					placeholder={placeholder}
				/>
			)}
			value={value}
			inputValue={inputValue}
			onChange={(event, value, reason, details) =>
				handleChange(event, value as TransportModeOption[] | null, reason, details)
			}
			onInputChange={(e, value) => setInputValue(value)}
			onBlur={onBlur}
		/>
	);
};

export default TransportAutocompleteField;
