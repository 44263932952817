import * as React from 'react';
import Grid from '@mui/material/Grid';
import MyGrid from '../../components/MyGrid/MyGrid';
import makeStyles from '@mui/styles/makeStyles';
import { Coordinates, Range } from '../../components/MyGrid/Cell/Cell';
import Button from '@mui/material/Button';
import Loading from '../../components/loading/Loading';
import { Data } from '../Displaydata/Displaydata';

type props = {
	goTo: Function;
	setData: React.Dispatch<React.SetStateAction<Data>>;
	data: Data;
};

interface dragData {
	color?: string;
	range?: Range;
}

// convert number index to excel index
export const numToExcelColumn = (num: number) => {
	let excelIndex = '',
		index;

	while (num > 0) {
		index = (num - 1) % 26;
		excelIndex = String.fromCharCode(65 + index) + excelIndex;
		num = ((num - index) / 26) | 0;
	}
	return excelIndex || undefined;
};

function Formatting(props: props) {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [destinationData, setDestinationData] = React.useState<{ [key: string]: any[] }>({});
	const [maskActiveCells, setMaskActiveCells] = React.useState<Range[]>([]);
	const [cellToCopy, setCellToCopy] = React.useState<any>({});
	const [dragStart, setDragStart] = React.useState<dragData>({});
	const [sourceGrid, setSourceGrid] = React.useState<any>({});

	const useStyles = makeStyles({
		fullHeight: {
			height: '100%',
		},
		gridsContainer: {
			height: '73vh',
		},
		button: {
			marginTop: '5vh',
		},
		alignCenter: {
			textAlign: 'center',
			paddingTop: '20vh',
		},
	});

	const classes = useStyles();

	React.useEffect(() => {
		emptyToDataSheet();
	}, [props.data]);

	const eventhandler = (event: any, range: any) => {
		if (range) {
			let charCode = String.fromCharCode(event.which).toLowerCase();
			//metaKey for MAC to detect cmd key
			if ((event.ctrlKey && charCode === 'c') || (event.metaKey && charCode === 'c')) {
				setCellToCopy(destinationData[Object.keys(destinationData)[0]][range.start.row][range.start.col]);
				// let newArr = { ...destinationData };
				// destinationData[Object.keys(destinationData)[0]][range.start.row][range.start.col] = cellToCopy
			} else if ((event.ctrlKey && charCode === 'v') || (event.metaKey && charCode === 'v')) {
				let newArr = { ...destinationData };

				for (let row = range.start.row; row <= range.end.row; row++) {
					for (let col = range.start.col; col <= range.end.col; col++) {
						if (cellToCopy) {
							newArr[Object.keys(newArr)[0]][row][col] = cellToCopy;
						}
						// let activeCells = maskActiveCells;
						// let cell : Range = {start: range.start,end: range.end,  isCopied: true, color: "#23412F", droppedAt}
						// activeCells.push(cell) ;
						// setMaskActiveCells(activeCells)
					}
				}

				// newArr[cellToCopy.oldRow][cellToCopy.oldCol] =cellToCopy
				setDestinationData(newArr);
				setCellToCopy(undefined);
			}
		}
	};

	const onDragStart = (bgColor: string) => {
		setDragStart({ color: bgColor, range: maskActiveCells[maskActiveCells.length - 1] });
	};

	const onDrop = (coordinates: Coordinates) => {
		setLoading(true);
		let newArr = { ...destinationData };
		if (dragStart.range) {
			let newIndexCol = coordinates.col;
			let newIndexRow = coordinates.row;
			let foundCell: any;
			if (dragStart.range) {
				sourceGrid.map((row: any) => {
					if (!foundCell) {
						return (foundCell = row.find(
							(cell: any) =>
								dragStart.range &&
								cell.oldCol === dragStart.range.start.col &&
								cell.oldRow === dragStart.range.start.row
						));
					}
					return false;
				});
			}
			let colspan = foundCell ? foundCell.colSpan - 1 : 0;
			let rowspan = foundCell ? foundCell.rowSpan - 1 : 0;

			for (let row = dragStart.range.start.row; row <= dragStart.range.end.row - rowspan; row++) {
				for (let col = dragStart.range.start.col; col <= dragStart.range.end.col - colspan; col++) {
					let colIndex = numToExcelColumn(col + 1);
					newArr['destination'][newIndexRow][newIndexCol].value = colIndex ? `${colIndex} ${row + 1}` : '';
					newArr['destination'][newIndexRow][newIndexCol].bgColor = dragStart.color;

					let cell: any;
					if (dragStart.range) {
						sourceGrid.map((sourceRow: any) => {
							if (!cell) {
								return (cell = sourceRow.find((el: any) => el.oldCol === col && el.oldRow === row));
							}
							return false;
						});
					}
					if (cell) {
						newArr['destination'][newIndexRow][newIndexCol].realValue = cell.value ? cell.value : '';
					}
					newIndexCol++;
				}
				newIndexRow++;
				newIndexCol = coordinates.col;
			}

			maskActiveCells[maskActiveCells.length - 1].isCopied = true;
			maskActiveCells[maskActiveCells.length - 1].droppedAt = { col: coordinates.col, row: coordinates.row };
			setDestinationData(newArr);
		}
		setLoading(false);
	};

	const cancelDroppedZone = (coordinates: any) => {
		setLoading(true);
		let removedCell = maskActiveCells.find((selectedCells) => {
			if (checkIfCellInRange(coordinates.row, coordinates.col, selectedCells)) {
				return true;
			} else {
				return false;
			}
		});
		let newActiveCells = maskActiveCells.filter((selectedCells) => {
			return removedCell?.start.col !== selectedCells.start.col && removedCell?.start.row !== selectedCells.start.row;
		});
		setMaskActiveCells(newActiveCells);

		let newArr = { ...destinationData };
		if (removedCell && removedCell.isCopied) {
			let newIndexCol = removedCell.droppedAt ? removedCell.droppedAt.col : 0;
			let newIndexRow = removedCell.droppedAt ? removedCell.droppedAt.row : 0;
			for (let row = removedCell.start.row; row <= removedCell.end.row; row++) {
				for (let col = removedCell.start.col; col <= removedCell.end.col; col++) {
					newArr['destination'][newIndexRow][newIndexCol].value = '';
					newArr['destination'][newIndexRow][newIndexCol].bgColor = undefined;
					newIndexCol++;
				}
				newIndexRow++;
				newIndexCol = removedCell.droppedAt ? removedCell.droppedAt.col : 0;
			}
		}
		setLoading(false);
	};

	const cancelMask = () => {
		setLoading(true);
		let newArr = { ...destinationData };
		maskActiveCells.forEach((cell) => {
			if (cell && cell.isCopied) {
				let newIndexCol = cell.droppedAt ? cell.droppedAt.col : 0;
				let newIndexRow = cell.droppedAt ? cell.droppedAt.row : 0;
				for (let row = cell.start.row; row <= cell.end.row; row++) {
					for (let col = cell.start.col; col <= cell.end.col; col++) {
						newArr['destination'][newIndexRow][newIndexCol].value = '';
						newArr['destination'][newIndexRow][newIndexCol].bgColor = undefined;
						newIndexCol++;
					}
					newIndexRow++;
					newIndexCol = cell.droppedAt ? cell.droppedAt.col : 0;
				}
			}
		});
		newArr['destination'].forEach((row: any) => {
			row.forEach((col: any) => {
				col.value = '';
				col.bgColor = undefined;
			});
		});
		setDestinationData(newArr);
		setLoading(false);
	};

	const applyMask = () => {
		setLoading(true);
		let newArr = { ...destinationData };
		maskActiveCells.forEach((cell: Range) => {
			let sourceRowRange = cell.end.row - cell.start.row;
			let sourceColRange = cell.end.col - cell.start.col;
			if (cell.droppedAt) {
				for (let row = cell.droppedAt.row; row <= cell.droppedAt.row + sourceRowRange; row++) {
					for (let col = cell.droppedAt.col; col <= cell.droppedAt.col + sourceColRange; col++) {
						newArr['destination'][row][col].value =
							destinationData['destination'][row][col] && destinationData['destination'][row][col].realValue
								? destinationData['destination'][row][col].realValue
								: destinationData['destination'][row][col].value;
					}
				}
			}
		});

		setDestinationData(newArr);
		setLoading(false);
	};

	const checkIfCellInRange = (RowIndex: number, colIndex: number, selectedRange: Range) => {
		//select from top left to bottom right  || from bottom right to top left

		if (
			RowIndex >= selectedRange.start.row &&
			RowIndex <= selectedRange.end.row &&
			colIndex >= selectedRange.start.col &&
			colIndex <= selectedRange.end.col
		) {
			return true;
		} else {
			return false;
		}
	};

	// const onDragOver = (e: any) => {};

	const emptyToDataSheet = () => {
		setLoading(true);
		const numberOfColumns = 50;
		const numberOfLines = 100;
		let finalOutput: any[][] = [];
		let elementOutput: Object[] = [];
		for (let rowIndex = 0; rowIndex < numberOfLines; rowIndex++) {
			for (let colIndex = 0; colIndex < numberOfColumns; colIndex++) {
				if (rowIndex === 0) {
					elementOutput.push({
						value: `Intitulé ${numToExcelColumn(colIndex + 1)}`,
						oldCol: colIndex,
						oldRow: rowIndex,
					});
				} else {
					elementOutput.push({ value: '', oldCol: colIndex, oldRow: rowIndex });
				}
			}
			finalOutput.push(elementOutput);
			elementOutput = [];
		}

		setDestinationData({ destination: finalOutput });
		setLoading(false);
	};
	return (
		<Grid container>
			<Loading loading={loading} />
			<Grid alignItems="stretch" container direction="row-reverse" spacing={0}>
				<Grid item xs={4} sm={4}></Grid>
				<Grid item xs={4} sm={5}>
					<h2 className="title">Intégration des données fournisseurs</h2>
				</Grid>
			</Grid>
			<Grid className={classes.gridsContainer} container spacing={0}>
				<Grid className={classes.fullHeight} item xs={6} sm={6}>
					<MyGrid
						setSourceGrid={setSourceGrid}
						cancelMask={cancelMask}
						cancelDroppedZone={cancelDroppedZone}
						maskActiveCells={maskActiveCells}
						setMaskActiveCells={setMaskActiveCells}
						data={props.data ? props.data : undefined}
						empty={false}
						onDragStart={onDragStart}
						isColor={true}
						setData={props.setData}
						// empty={false}
						// setLoading={setLoading}
					/>
				</Grid>
				<Grid item xs={6} sm={1} className={classes.alignCenter}>
					{maskActiveCells.length > 0 &&
					maskActiveCells.some((currentValue) => {
						return currentValue.isCopied;
					}) ? (
						<Button onClick={applyMask} size="small" variant="contained" color="primary">
							Executer
						</Button>
					) : null}
				</Grid>
				<Grid className={classes.fullHeight} item xs={4} sm={4}>
					<MyGrid
						eventhandler={eventhandler}
						editCell={true}
						data={{ grids: destinationData, step: props.data.step }}
						setData={props.setData}
						empty={true}
						onDrop={onDrop}
						copycell={true}
						isColor={true}
					/>
				</Grid>
			</Grid>

			<Button
				className={classes.button}
				size="small"
				variant="contained"
				color="primary"
				onClick={() => {
					props.setData({
						step: 'formatting',
						grids: destinationData,
					});
					props.goTo('Displaydata');
				}}
			>
				Enregistrer
			</Button>
		</Grid>
	);
}
export default Formatting;
