import * as React from 'react';
import { Route, Switch, BrowserRouter as Router, BrowserRouterProps } from 'react-router-dom';
import { Box } from '@mui/material';
import { LoginPage } from '../auth/LoginPage';
import { AuthContext } from '../context/Auth';
import { useContext } from 'react';
import AuthRoutes from './AuthRoutes.';
import colors from '../app/theme/colors.scss';

export default function AppRoutes(
	props: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<Router> & Readonly<BrowserRouterProps>
) {
	const context = useContext(AuthContext);
	const isAuth = context?.accessToken;

	return (
		<Box display="flex" flexDirection="column" minHeight="100vh" pt={isAuth ? 6 : 0}>
			<Box flex=" 1 0 auto" width="100%" height="100%" bgcolor={isAuth ? 'inherit' : colors.documentHeaderDark}>
				<Router {...props}>
					<Switch>
						<Route exact path="/login" component={LoginPage} />
						<Route path="/" component={() => <AuthRoutes />} />
					</Switch>
				</Router>
			</Box>
		</Box>
	);
}
