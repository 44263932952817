import React, { memo, useMemo } from 'react';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import DashedHeader from '../../../../components/DashedHeader';
import { LayoutField } from '../../../../components/form/LayoutField';
import IconButton from '@mui/material/IconButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../../app/theme/colors.scss';
import usePopin from '../../../../hooks/usePopin';
import { OrderFormModel } from '../definition';
import ProductsDistribution from './ProductsDistribution';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import OrderPrice from '../components/OrderPrice';
import { getShippingAddress } from '../../../helpers';

export const useStylesOrder = makeStyles(() =>
	createStyles({
		disabled: {
			backgroundColor: colors.grey,
		},
	})
);

interface AdditionalInformationProps {
	order: OrderFormModel;
}

const AdditionalInformation = ({ order }: AdditionalInformationProps): JSX.Element => {
	const classes = useStylesOrder();
	const [openQuotaFilePopin, renderQuotaFilePopin] = usePopin({
		headerTitle: 'Répartition des produits par magasin',
		buttonLabel: 'Imprimer',
		maxWidth: 'xl',
		fullWidth: true,
		iconButton: <PrintOutlinedIcon />,
		isDisplayHeader: true,
	});

	const productsName = order.childrenOrder
		? order.childrenOrder
				.map((order) => {
					if (!order.orderItem) return [];
					return order.orderItem.map((item) => item.productName);
				})
				.flat()
		: [];

	const headerTable = Array.from(
		new Set([
			'Magasin',
			'Numéro du magasin',
			'Contact',
			'Téléphone',
			'Adresse 1',
			'Adresse 2',
			'Adresse 3',
			'Code postal',
			...productsName,
		])
	);

	const shippingAddress = useMemo(() => getShippingAddress(order?.childrenOrder ?? []), [order]);

	return (
		<>
			<Box mb={4} mt={4}>
				<Box>
					<Typography variant="h2">Informations complémentaires</Typography>
				</Box>
				<Box display="flex" mx={15} mt={3}>
					<Box width={'55%'} pr={10}>
						<DashedHeader>Source et paiement</DashedHeader>
						<Grid container justifyContent="space-between" spacing={5}>
							<Grid item sm={6}>
								<Box width="270px">
									<LayoutField label="Source de la commande">
										<TextField
											value={order.source === 'OneSupply' ? 'OneSupply' : 'OnePac / BigShop'}
											fullWidth
											variant="outlined"
											disabled
											className={classes.disabled}
										/>
									</LayoutField>
								</Box>
							</Grid>
							<Grid item sm={6}>
								<Box mb={3} width="270px">
									<LayoutField label="Type de paiment">
										<TextField
											value={order.paymentType === 'ByCB' ? 'Par CB' : 'Sur Facture'}
											fullWidth
											variant="outlined"
											className={classes.disabled}
											disabled
										/>
									</LayoutField>
								</Box>
							</Grid>
						</Grid>

						<Grid container justifyContent="space-between" spacing={2}>
							<Grid item sm={6}>
								<Box mb={3}>
									<DashedHeader>Adresse de facturation</DashedHeader>
									{order.billingAddress ? (
										<>
											<Typography variant="h4">{order.billingAddress.name}</Typography>
											<Typography variant="h5">{order.billingAddress.firstAddress}</Typography>
											<Typography variant="h5">{order.billingAddress.secondAddress}</Typography>
											<Typography variant="h5">{order.billingAddress.thirdAddress}</Typography>
											<Typography variant="h5">
												{order.billingAddress.zipCode + ' '}
												{order.billingAddress.deliveryDepartment}
											</Typography>
											<Typography variant="h5">{order.billingAddress.city}</Typography>
										</>
									) : (
										<Typography variant="h4"> Facturation sur répartition</Typography>
									)}
								</Box>
							</Grid>
							<Grid item sm={6}>
								<Box mb={3}>
									<DashedHeader>Adresse de livraison</DashedHeader>
									{shippingAddress ? (
										<Box>
											<Typography variant="h4">{shippingAddress.name}</Typography>
											<Typography variant="h5">{shippingAddress.firstAddress}</Typography>
											<Typography variant="h5">{shippingAddress.secondAddress}</Typography>
											<Typography variant="h5">{shippingAddress.thirdAddress}</Typography>
											<Typography variant="h5">
												{shippingAddress.zipCode + ' '}
												{shippingAddress.deliveryDepartment}
											</Typography>
											<Typography variant="h5">{shippingAddress?.city}</Typography>
										</Box>
									) : (
										<IconButton color={'primary'} onClick={openQuotaFilePopin} size="large">
											<FormatListBulletedIcon />
											<Typography variant="h4"> Fichier de répartition</Typography>
										</IconButton>
									)}
									{renderQuotaFilePopin(<ProductsDistribution order={order} headerTable={headerTable} />)}
								</Box>
							</Grid>
						</Grid>

						<DashedHeader>Commentaires sur la commande</DashedHeader>
						<Grid container>
							<Grid item xs={12}>
								<Box mb={3}>
									<LayoutField label="Commentaire">
										<TextField
											multiline={true}
											rows={6}
											value={'Le ' + new Date(order.createdAt).toLocaleDateString() + ': ' + order.comment}
											variant="outlined"
											disabled={true}
											className={classes.disabled}
										/>
									</LayoutField>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Divider orientation="vertical" flexItem />
					<Box width="45%" pl={10}>
						<DashedHeader>Total</DashedHeader>
						<Box>
							<OrderPrice
								variant="h5"
								title="Total PA HT avec RFA (€) :"
								price={order.buyingPriceHtWithRfa}
								color="textSecondary"
							/>
							<OrderPrice variant="h2" title="Total PA HT sans RFA (€)" price={order.buyingPriceHt} pb={1} />
							<OrderPrice variant="h2" title="Total PV HT (€) :" price={order.sellingPriceHt} pb={1} />
							<OrderPrice
								variant="h5"
								title="Marge HT avec RFA client (€) :"
								price={order.marginWithClientRfa}
								color="textSecondary"
							/>
							<OrderPrice variant="h4" title="Marge HT réel (€) :" price={order.realMargin} />
							<OrderPrice variant="h5" title="Taux de marge (%) :" price={order.marginRate} color="textSecondary" />
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default memo(AdditionalInformation);
