import { gql } from '@apollo/client';
import FILE_REFERENCE_FRAGMENT from '../../graphql/fragments/file-reference/fileReferenceFragment';

export const GET_FILE_REFERENCES = gql`
	query GetFileReferences(
		$search: FileReferenceSearchInput
		$sort: FileReferenceSortInput
		$pagination: PaginationInput
		$filters: FileReferenceFilterInput
	) {
		fileReferences(search: $search, sort: $sort, pagination: $pagination, filters: $filters) {
			pagination {
				totalItemsCount
			}
			fileReferences {
				...FileReference
				elpevResponsible {
					givenName
					familyName
				}
				client {
					name
				}
			}
		}
	}
	${FILE_REFERENCE_FRAGMENT}
`;
