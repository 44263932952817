import { useMutation } from '@apollo/client';
import { UpdateTransportersMutation, UpdateTransportersMutationVariables } from '../graphql/mutations.definitions';
import { UPDATE_TRANSPORTERS } from '../graphql/mutations';
import { Transporter } from '../../../graphql/types';

const useTransportersUpdate = () => {
	const [updateTransportersMutation] = useMutation<UpdateTransportersMutation, UpdateTransportersMutationVariables>(
		UPDATE_TRANSPORTERS
	);

	const updateTransporters = async (Transporters: Transporter[]) => {
		await updateTransportersMutation({
			variables: {
				Transporters,
			},
			awaitRefetchQueries: true,
		});
	};

	return { updateTransporters };
};

export default useTransportersUpdate;
