import { Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

interface HeaderPopinProps {
	headerTitle: string;
	closePopin: () => void;
}

const useStyles = makeStyles(() => ({
	header: {
		margin: 0,
		padding: '20px',
	},
	closeButton: {
		position: 'absolute',
		right: '30px',
		top: '22px',
	},
}));

const HeaderPopin = ({ closePopin, headerTitle }: HeaderPopinProps): JSX.Element => {
	const classes = useStyles();

	return (
		<MuiDialogTitle className={classes.header}>
			<Typography variant="h2">{headerTitle}</Typography>
			<IconButton aria-label="close" className={classes.closeButton} onClick={closePopin} size="large">
				<CloseIcon />
			</IconButton>
		</MuiDialogTitle>
	);
};

export default HeaderPopin;
