import { BoxShippingMode, PalletShippingMode } from '../../../graphql/types';

export const boxShippingModeOptions = [
	{ value: BoxShippingMode.BeforeNine, name: 'Livraison avant 9 h', label: 'Avant 9h' },
	{ value: BoxShippingMode.BeforeThirteen, name: 'Livraison avant 13 h', label: 'Avant 13h' },
];

export const palletShippingModeOptions = [
	{ key: PalletShippingMode.Strandard, value: 'Camion standard' },
	{ key: PalletShippingMode.Tailgate, value: 'Camionnette avec hayon' },
	{ key: PalletShippingMode.Other, value: 'Coursier / autre moyen' },
];
