import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import EditOutlined from '@mui/icons-material/EditOutlined';

import { useStylesOrderItemSupplierPrices } from './styles';
import { CellOrderItemSuppliersPricesType } from '../../../definition';

export const CellOrderItemSuppliersPrices = ({
	label,
	value,
	index,
	widthRoot,
	isMarginRoot = false,
	borderColor,
	variant = 'body1',
	padding,
	children,
	handleEdit,
}: CellOrderItemSuppliersPricesType) => {
	const classes = useStylesOrderItemSupplierPrices({ index, widthRoot, isMarginRoot, borderColor, padding });

	return (
		<Box className={classes.root}>
			<Box>
				<Typography>{label ?? ''}</Typography>
			</Box>
			<Box className={classes.cell}>
				<Box display="flex" width="100%" justifyContent="space-between">
					<Typography variant={variant}>{children ?? value}</Typography>
					{handleEdit ? (
						<Button variant="text" onClick={() => handleEdit()}>
							<EditOutlined />
						</Button>
					) : null}
				</Box>
			</Box>
		</Box>
	);
};
