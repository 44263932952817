import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import usePopin from 'hooks/usePopin';
import CreateIcon from '@mui/icons-material/Create';
import { useFormikContext } from 'formik';
import { Invoice } from 'graphql/types';
import usePossibleBillingsClientsQuery from 'clients/hooks/usePossibleBillingsClientsQuery';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LayoutField } from 'components/form/LayoutField';
import colors from 'app/theme/colors.scss';
import { InvoiceOrderModel } from '../../definitions';

const DeliveryInformationsDialogForm = (): JSX.Element => {
	const { setFieldValue, values } = useFormikContext<Invoice>();
	const { possibleBillingClients, getPossibleBillingClients } = usePossibleBillingsClientsQuery();
	const [invoiceOrder, setInvoiceOrder] = useState<InvoiceOrderModel | null>();
	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: "Modification de l'addresse de facturation",
		maxWidth: 'sm',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const getBillingAddress = () => {
		if (values.order?.clientId)
			getPossibleBillingClients({
				variables: {
					id: values.order?.clientId,
				},
			});
		setInvoiceOrder(null);
	};

	const validateBillingAddress = () => {
		if (invoiceOrder?.billingAddress) setFieldValue('order.billingAddress', invoiceOrder?.billingAddress);
		closePopin();
	};

	return (
		<Box mt={0.75} ml={1}>
			<>
				<IconButton color="primary" onClick={openPopin} size="large">
					<Tooltip title="Modifier l'adresse">
						<CreateIcon onClick={() => getBillingAddress()} />
					</Tooltip>
				</IconButton>
				{renderPopin(
					<Box>
						<Box mt={1} textAlign="center">
							<Typography variant="h3">Veuillez indiquer ci dessous la nouvelle adresse de facturation</Typography>
						</Box>
						<Box p={3}>
							<LayoutField label="Nom commercial">
								<Autocomplete
									freeSolo
									forcePopupIcon
									size="small"
									options={possibleBillingClients ?? []}
									/*renderOption={(option) => <>{option.name as string}</>}*/
									/*getOptionLabel={(option) => option.name}*/
									onChange={(event, order) => setInvoiceOrder(order as InvoiceOrderModel | null)}
									fullWidth
									renderInput={(params) => <TextField {...params} variant="outlined" />}
								/>
							</LayoutField>

							<Box border={'1px solid'} mt={1} p={1} height={170} borderColor={colors.gray300} borderRadius="4px">
								<Typography variant="h4">{invoiceOrder?.billingAddress?.name}</Typography>
								<Typography variant="h5">{invoiceOrder?.billingAddress?.firstAddress}</Typography>
								<Typography variant="h5">
									{invoiceOrder?.billingAddress?.zipCode} {invoiceOrder?.billingAddress?.city}
								</Typography>
							</Box>
						</Box>
						<Divider />
						<Box flexDirection="row-reverse" display="flex" p={2}>
							<Button color="primary" variant="contained" onClick={() => validateBillingAddress()}>
								Valider
							</Button>
							<Button color="primary" onClick={closePopin}>
								Annuler
							</Button>
						</Box>
					</Box>
				)}
			</>
		</Box>
	);
};
export default DeliveryInformationsDialogForm;
