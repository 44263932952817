import { Box } from '@mui/material';
import AppListTable from 'components/tables/list-table/AppListTable';
import { FabricationTime } from 'graphql/types';
import React from 'react';
import useFabricationTimesTale from '../hooks/useFabricationTimesTale';

interface FabricationTimesTableprops {
	support?: SupportFromModel;
	setFabricationTimesData: React.Dispatch<React.SetStateAction<any>>;
	fabricationTimesData: FabricationTime[];
}

const FabricationTimesTable = ({
	support,
	setFabricationTimesData,
	fabricationTimesData,
}: FabricationTimesTableprops) => {
	const fabricationTimesTable = useFabricationTimesTale(
		fabricationTimesData,
		support?.name ?? 'support',
		setFabricationTimesData
	);

	return (
		<Box mt={4} mx={12} width="600px">
			<AppListTable tableInstance={fabricationTimesTable} />
		</Box>
	);
};

export default FabricationTimesTable;
