import { gql } from '@apollo/client';

const INSERT_ROLE = gql`
	mutation InsertRole($data: RoleInput!) {
		createRole(data: $data) {
			name
			description
			permissions {
				name
				key
			}
		}
	}
`;

const UPDATE_ROLE_BY_ID = gql`
	mutation UpdateRoleById($roleId: String!, $data: RoleInput!) {
		updateRole(roleId: $roleId, data: $data) {
			name
			description
			permissions {
				name
				key
			}
		}
	}
`;

export { INSERT_ROLE, UPDATE_ROLE_BY_ID };
