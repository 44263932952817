export const QUOTE_CLAUSE = [
	'(1) Nos prix sont valables pour des fichiers sécurisés fournis par vos soins conformes à notre cahier des\n' +
		"\t\t\t\tcharges. Ces fichiers doivent obligatoirement être accompagnés d'une épreuve couleur contractuelle générée à\n" +
		'\t\t\t\tpartir de ces fichiers.',
	"(2) Les tolérances que le client est tenu d'accepter sont de plus ou moins 2 à 10% selon le tirage ( cf. art. 38\n" +
		'\t\t\t\tdes Usages Professionnels )',
	"La présente offre n'engagera notre société qu'après analyse des garanties que nous serions susceptibles de vous\n" +
		'\t\t\t\tdemander. Prix valable 30 jours et révisable en fonction des fluctuations des prix de matières et des\n' +
		'\t\t\t\trépartitions définitives de transport.',
];

export const DELIVERY_INFO = [
	'Les délais de livraison exprimés en jours ouvrés et les prix indiqués pour les modes "Standard" et "Express"\n' +
		'\t\t\t\t\tconcernent des commandes passées avant 10h du matin. Le mode "Urgent" concerne des commandes passées après 10h\n' +
		'\t\t\t\t\tet avant 13h',
];

export const CORRESPONDENCES_ELPEV_SITE = {
	Empty: '',
	Paris: 'ELPEV - 123 rue Jules Guesde - 92300 LEVALLOIS-PERRET -  FRANCE',
	Lille: 'ELPEV - 19 rue du 8 Mai 1945 - 59113 SECLIN - FRANCE',
	Wittelsheim: 'ELPEV - 23 av. Joseph Else - ZAC HEIDEN EST - Joseph Else - Secoia - 68310 WITTESHEIM - FRANCE',
};
