import React from 'react';
import SearchBar from '../components/inputs/SearchBar';

const useSearch = <T extends object>(key: keyof T, items: T[]) => {
	const [searchQuery, setSearch] = React.useState<string>('');
	const handleChangeSearch = (value: string) => {
		setSearch(value);
	};

	const renderSearchComponent = (placeholder: string) => (
		<SearchBar
			iconColor="disabled"
			placeholder={placeholder}
			onChange={(e) => handleChangeSearch(e.target.value as string)}
			value={searchQuery}
		/>
	);

	const filtredItems =
		items?.filter((item) => {
			const name = (item[key] as unknown) as string;
			return name.toLowerCase().includes(searchQuery.toLowerCase());
		}) ?? [];

	return { renderSearchComponent, filtredItems, handleChangeSearch, searchQuery };
};

export default useSearch;
