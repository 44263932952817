import { Typography } from '@mui/material';
import { TextField } from 'formik-mui';
import React from 'react';
import { Field } from 'formik';
import { CellProps } from 'react-table';
import { ClientStructure, EntityType } from '../../../../graphql/types';

interface EntityTitleFieldProps extends CellProps<ClientStructure, string> {}

const EntityTitleCellField = ({ value, row: { original, index } }: EntityTitleFieldProps): JSX.Element => {
	if (
		![EntityType.SubGroup1, EntityType.SubGroup2, EntityType.SubGroup3, EntityType.SubGroup4].includes(
			original.type as EntityType
		)
	) {
		return <Typography color="textSecondary">{value}</Typography>;
	}

	return <Field component={TextField} name={`structures[${index}].title`} />;
};

export default EntityTitleCellField;
