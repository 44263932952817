import { useMutation } from '@apollo/client';
import { DELETE_SHIPPING_ADDRESS_BY_ID } from '../graphql/mutations';
import { GET_CLIENT_BY_ID, GET_CLIENT_SHIPPING_ADDRESS } from '../graphql/queries';
import {
	DeleteShippingAddressByIdMutation,
	DeleteShippingAddressByIdMutationVariables,
} from '../graphql/mutations.definitions';

const useShippingAddressDelete = () => {
	const [deleteShippingAddressMutation] = useMutation<
		DeleteShippingAddressByIdMutation,
		DeleteShippingAddressByIdMutationVariables
	>(DELETE_SHIPPING_ADDRESS_BY_ID);

	const deleteShippingAddressById = async (clientShippingAddressId: string, clientId: string, addressId?: string) => {
		await deleteShippingAddressMutation({
			variables: { clientShippingAddressId, addressId: addressId ?? '' },
			refetchQueries: [
				{
					query: GET_CLIENT_SHIPPING_ADDRESS,
				},
				{
					query: GET_CLIENT_BY_ID,
					variables: {
						id: clientId,
					},
				},
			],
			awaitRefetchQueries: true,
		});
	};
	return { deleteShippingAddressById };
};

export default useShippingAddressDelete;
