import React, { useContext } from 'react';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import DashedHeader from '../../../../components/DashedHeader';
import { LayoutField } from '../../../../components/form/LayoutField';
import colors from '../../../../app/theme/colors.scss';
import { FormikSwitchField } from '../../../../components/form/FormikSwitchField';
import { BoxShippingMode, Client, ClientPrice, ClientPriceStatus } from '../../../../graphql/types';
import { useClientPriceByClientIdAndSupportId } from '../../../hooks/useClientPriceByClientIdAndSupportId';
import { useClientsByCurrentUser } from '../../../../clients/hooks/useClientsByCurrentUser';
import ExistingClientPricesForProduct from './ExistingClientPricesForProduct';
import usePopin from '../../../../hooks/usePopin';
import { MainInformationProps } from '../MainInformation';
import { useStylesCustom } from '../styles';
import { useClientPriceExistingByProduct } from '../../../hooks/useClientPriceExistingByProduct';
import { ClientPriceModel } from '../../ClientPriceForm';
import PricesProductContext from '../../../../context/pricesProductContext/PricesProductContext';
import { DEFAULT_MAX_BOX_WEIGHT } from '../../definitions';
import FormikAutocompleteField from 'components/form/FormikAutocompleteField';
import { isString } from 'utils/typeGuards';
import { useAsyncDebounce } from 'react-table';
import FormikTextField from 'components/form/FormikTextField';

type ClientType = Pick<Client, 'id' | 'name'>;
type ClientPriceType = Pick<ClientPrice, 'productName' | 'status'>;

export const NamesAndProduct = ({ isClariPrint, isConsultSellingPrice }: MainInformationProps): JSX.Element => {
	const classes = useStylesCustom();
	const params = useParams<{ id?: string }>();
	const { clientsUsers } = useClientsByCurrentUser(true);
	const { setFieldValue, values, errors } = useFormikContext<ClientPriceModel>();

	const { clientPrices, getClientPrices } = useClientPriceByClientIdAndSupportId();
	const context = useContext(PricesProductContext);
	const { getClientPricesExisting } = useClientPriceExistingByProduct();
	const [openPopin, renderingExistingClientPrice] = usePopin({
		headerTitle: `Prix de vente déjà existants pour le produit ${values.productName ?? ''}`,
		fullWidth: true,
		isDisplayHeader: true,
		maxWidth: 'lg',
	});

	const getValue = (value?: number | null): number => value ?? 0;

	const handleSelectClient = async (value: Pick<Client, 'id' | 'name'> | null): Promise<void> => {
		const clientId = value?.id as string;
		setFieldValue('productName', '');

		if (clientsUsers) {
			const client = clientsUsers?.find(({ id }) => id === clientId);
			const mainAddress = client?.clientShippingAddress?.filter((address) => address.isMain)[0];
			const deliveryDepartment = mainAddress?.address?.deliveryDepartment;
			const clientZipCode = mainAddress?.address?.zipCode;

			setFieldValue('defaultCommercialProductMargin', getValue(client?.defaultCommercialProductMargin));
			setFieldValue('defaultCommercialShippingMargin', getValue(client?.defaultCommercialShippingMargin));
			setFieldValue('deliveryDepartment', deliveryDepartment ?? '');
			setFieldValue('clientZipCode', clientZipCode ?? '');
			setFieldValue('mainRfaRate', getValue(client?.mainRfaRate));
			setFieldValue('deliveryParcel', mainAddress?.boxShippingMode || BoxShippingMode.BeforeThirteen);
			setFieldValue('maxBoxWeight', mainAddress?.maxBoxWeight || DEFAULT_MAX_BOX_WEIGHT);
		}
		setFieldValue('clientId', clientId);

		await getClientPrices({
			variables: {
				clientId,
				supportId: params.id ? params.id : '',
			},
		});
	};

	const handleChange = (product: Pick<ClientPrice, 'productName' | 'status'> | null): void => {
		setFieldValue('productId', context?.selectedProduct?.id);
		setFieldValue('productName', product?.productName);
		setFieldValue('status', product?.status ?? ClientPriceStatus.InProgress);
	};

	const debouncedProductName = useAsyncDebounce((productName: string) => {
		setFieldValue('status', ClientPriceStatus.InProgress);

		getClientPricesExisting({
			variables: {
				filters: {
					clientId: values?.clientId,
					productName,
				},
			},
		});
	}, 500);

	const handleInputChange = React.useCallback(
		(productName: string) => {
			setFieldValue('productName', productName);
			setFieldValue('productId', context?.selectedProduct?.id);
			debouncedProductName(productName);
		},
		[debouncedProductName, setFieldValue, context?.selectedProduct]
	);

	const displayErrors = (errors: string) => <Typography color="error">{errors}</Typography>;

	const findClient = React.useMemo(() => clientsUsers?.find(({ id }) => id === values?.clientId), [
		clientsUsers,
		values,
	]);

	return (
		<Box mt={5}>
			<>
				{renderingExistingClientPrice(
					<ExistingClientPricesForProduct clientPricesExisting={values.clientPricesExisting} />
				)}
				<DashedHeader>Noms et produit</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<LayoutField label="Nom du client">
							<Box width="95%" display="flex">
								<FormControl fullWidth>
									<FormikAutocompleteField<ClientType>
										options={
											clientsUsers?.map(({ id, name }) => ({
												name,
												id,
											})) ?? []
										}
										value={{ name: findClient?.name as string, id: findClient?.id as string }}
										disabled={(!isClariPrint && !context?.selectedProduct?.codeAt) || isConsultSellingPrice}
										getOptionLabel={(option) => (option as ClientType).name ?? ''}
										isOptionEqualToValue={(option) => option.id === values?.clientId}
										onChange={async (_, value) => {
											if (!isString(value)) {
												await handleSelectClient(value);
											}
										}}
										autoSelect
										textField={{
											size: 'small',
										}}
									/>
								</FormControl>
								<Box color={colors.gray100} className={classes.boxIconClient}>
									<PublishOutlinedIcon fontSize="small" />
								</Box>
							</Box>
							{errors.clientId && displayErrors(errors.clientId)}
						</LayoutField>
					</Grid>

					<Grid item xs={5}>
						<LayoutField label="Nom commercial du produit">
							<Box width="100%" display="flex" mt={-2} pt={2}>
								<FormControl fullWidth>
									<FormikAutocompleteField<ClientPriceType>
										options={clientPrices ?? []}
										getOptionLabel={(option) => (option as ClientPriceType).productName}
										isOptionEqualToValue={(option, value) => option.productName === value.productName}
										onChange={(_, value) => {
											if (!isString(value)) {
												handleChange(value);
											}
										}}
										onInputChange={(_, newValue) => handleInputChange(newValue)}
										inputValue={values.productName ?? ''}
										disabled={isConsultSellingPrice || values?.clientId === ''}
										textField={{
											size: 'small',
										}}
										selectOnFocus={false}
										freeSolo
									/>
								</FormControl>
								<Box color={colors.primary} className={classes.boxIconProduct}>
									<HelpOutlinedIcon fontSize="small" onClick={openPopin} />
								</Box>
							</Box>
							{errors.productName && displayErrors(errors.productName)}
						</LayoutField>
					</Grid>
					<Grid item xs={12}>
						<Grid item xs={2}>
							<Box mt={3}>
								<LayoutField label="Produit prepresse">
									<FormikSwitchField testid="is-prepress" name="isPrepress" disabled={isConsultSellingPrice} />
								</LayoutField>
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={2}>
						<Box mt={3}>
							<LayoutField label="Envoi spécifique" aria-label="specific-shipment">
								<FormikSwitchField name="isSpecificShipment" disabled />
							</LayoutField>
						</Box>
					</Grid>

					{values.isSpecificShipment && (
						<>
							<Grid item xs={4}>
								<Box mt={3}>
									<LayoutField label="Fournisseur spécifique">
										<FormikTextField
											fullWidth
											name="specificSupplier.name"
											variant="outlined"
											type="text"
											disabled
											testid="specific-supplier"
										/>
									</LayoutField>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Box mt={3}>
									<LayoutField label="Email spécifique">
										<FormikTextField
											fullWidth
											name="specificEmail"
											variant="outlined"
											type="text"
											disabled
											testid="specific-email"
										/>
									</LayoutField>
								</Box>
							</Grid>
						</>
					)}
				</Grid>
			</>
		</Box>
	);
};
