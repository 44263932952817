import { gql } from "@apollo/client";

export const GENERATE_TEMPLATE_URL = gql`
	mutation GenerateTemplateUrl($templateName: TemplateName!) {
		generateFileUrl(templateName: $templateName) {
			url
		}
	}
`;

export const IMPORT_SHOPS = `
  mutation ImportShops($file: Upload!, $rootClientName: String!) {
    importClients(file: $file, rootClientName: $rootClientName) {
      id
    }
  }
`;