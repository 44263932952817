import React from 'react';
import { Box } from '@mui/material';
import { Tree, TreeNode } from 'react-organizational-chart';
import Loading from 'components/loading/Loading';
import Stores from './Stores';
import ChildLabel from './ChildLabel';
import { ClientChildren } from 'graphql/types';
import useClientChildrenQuery from '../hooks/useClientChildrenQuery';
import './ClientOrganigram.css';
import colors from 'app/theme/colors.scss';
import { sortByKey } from 'utils/arrays';

interface ClientOrganigramProps {
	id: string;
	name: string;
	isActif: boolean;
	type: string | undefined;
	fileCode?: string | null;
}

const clientChildren = (children: ClientChildren[]) => {
	const verifyIsStoreType = (child: ClientChildren): boolean => child.type === 'Magasin';
	const isStoreType = children.some(verifyIsStoreType);

	if (isStoreType) {
		return <TreeNode label={<Stores children={children} />} />;
	}

	return children.map(({ name, type, isActif, id, children }: ClientChildren) => (
		<React.Fragment key={id}>
			<TreeNode label={<ChildLabel name={name} type={type} isActif={isActif} />}>{clientChildren(children)}</TreeNode>
		</React.Fragment>
	));
};

const ClientOrganigram = ({ id, name, isActif, type, fileCode }: ClientOrganigramProps) => {
	const { children, loading } = useClientChildrenQuery(id);

	if (loading) return <Loading loading={loading} />;

	return (
		<Box mt={6} mb={6}>
			<Tree
				lineWidth={'2px'}
				lineColor={colors.gray200}
				label={<ChildLabel isActif={isActif} type={type} name={name} fileCode={fileCode} />}
			>
				{children &&
					sortByKey(children, 'name').map(({ id, name, type, isActif, fileCode, children }: ClientChildren) => (
						<React.Fragment key={id}>
							<TreeNode label={<ChildLabel type={type} isActif={isActif} name={name} fileCode={fileCode} />}>
								{clientChildren(children)}
							</TreeNode>
						</React.Fragment>
					))}
			</Tree>
		</Box>
	);
};

export default ClientOrganigram;
