import { useQuery } from '@apollo/client';
import { GetOrdersQuery, GetOrdersQueryVariables } from '../graphql/queries.definitions';
import { GET_ORDERS } from '../graphql/queries';
import { OrderSearchInput, OrderSortInput, PaginationInput } from 'graphql/types';

const useOrdersWithPaginQuery = (search?: OrderSearchInput, sort?: OrderSortInput, pagination?: PaginationInput, isOrderCockpit?: boolean) => {
	const { data, loading } = useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GET_ORDERS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			search,
			sort,
			pagination,
			isOrderCockpit,
		},
	});

	return { orders: data?.ordersWithPagination.orders, total: data?.ordersWithPagination.total, loading };
};

export default useOrdersWithPaginQuery;
