import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LayoutFieldForm from '../../../components/form/LayoutFieldForm';
import DateLayoutField from '../../../components/form/DateLayoutField';
import colors from '../../../app/theme/colors.scss';
import { LayoutField } from '../../../components/form/LayoutField';
import { useField } from 'formik';
import FormikRadioField from 'components/form/FormikRadioField';
import { ClientQuoteOptions } from '../helpers';
import { ClientQuoteStatus, QuoteType } from 'graphql/types';
import SelectClientQuoteStatus from '../../components/SelectClientQuoteStatus/SelectClientQuoteStatus';

const GeneralInformation = (): JSX.Element => {
	const [statusField] = useField<ClientQuoteStatus>('status');
	const [, typeMeta, typeHelpers] = useField<QuoteType | null>('type');

	return (
		<Box mb={5} mt={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">Informations générales</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box mb={5}>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={5}>
						<LayoutField label={'Status'}>
							<Box mb={3}>
								<Box width={'417px'} bgcolor={colors.grey}>
									<SelectClientQuoteStatus />
								</Box>
							</Box>
						</LayoutField>
						<LayoutFieldForm
							disabled
							label="Version"
							name="version"
							type="number"
							width="164px"
							inputProps={{
								inputProps: {
									min: 1,
									step: 1,
								},
							}}
						/>
						<LayoutField label="Objectif du devis">
							<FormikRadioField
								isRow
								name="type"
								options={ClientQuoteOptions}
								disabled={statusField.value !== ClientQuoteStatus.InProgress}
								onChange={(_, type) => typeHelpers.setValue(type as QuoteType)}
							/>
							<Typography color="error">{typeMeta.error}</Typography>
						</LayoutField>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={3}>
						<DateLayoutField label="Devis créer le" name="createdAt" width="164px" disabled />
						<DateLayoutField label="Modifier le" name="updatedAt" width="164px" disabled />
					</Grid>
					<Grid item xs={2} />
				</Grid>
			</Box>
		</Box>
	);
};

export default GeneralInformation;
