import { Box, Typography, Grid, FormLabel } from '@mui/material';
import { Alert } from '@mui/material';
import React from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import { FormikCheckboxField } from '../../../../components/form/FormikChecboxField';
import { FormikNumberField } from '../../../../components/form/FormikNumberField';
import { LayoutField } from '../../../../components/form/LayoutField';
import { transportList } from '../definitions';
import TransportAutocompleteField from './transportAutoCopmeteField';

const BigShopFactoryFields = () => {
	return (
		<Box mt={4}>
			<Box my={4}>
				<DashedHeader>Fabrication Bigshop</DashedHeader>
				<Box mb={4}>
					<Alert severity="info">
						<Typography variant="h4" color="primary">
							Ne peut être actif que si le fournisseur a prévu un ramassage quotidien avec transporteur colis
						</Typography>
					</Alert>
				</Box>
				<Grid container>
					<Grid item xs={4}>
						<Grid container direction="column" justifyContent="space-between" spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h4">
									Mode Express <Typography variant="caption">(commande avant 10h)</Typography>
								</Typography>
								<Box display="flex" alignItems="center">
									<FormikCheckboxField name="expressMode" />
									<Typography variant="h5">Accepte le mode express</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<LayoutField label="Supplément mode express">
									<Box width="110px" display="flex">
										<FormikNumberField
											decimalScale={2}
											name="expressModeSupplement"
											width="139px"
											maxValue={100}
											variant="outlined"
										/>
										<Box mx={1} mt={0.5}>
											<Typography variant="h4"> % </Typography>
										</Box>
									</Box>
								</LayoutField>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						<Grid container direction="column" justifyContent="space-between" spacing={2}>
							<Grid item xs={12}>
								<FormLabel>
									<Typography variant="h4">
										Mode Urgent <Typography variant="caption">(commande avant 13h)</Typography>
									</Typography>
								</FormLabel>
								<Box display="flex" alignItems="center">
									<FormikCheckboxField name="urgentMode" />
									<Typography variant="h5">Accepte le mode express</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<LayoutField label="Supplément mode urgent">
									<Box width="110px" display="flex">
										<FormikNumberField
											decimalScale={2}
											name="urgentModeSupplement"
											width="139px"
											maxValue={100}
											variant="outlined"
										/>
										<Box mx={1} mt={0.5}>
											<Typography variant="h4"> % </Typography>
										</Box>
									</Box>
								</LayoutField>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3}>
						<FormLabel>Nom du ou des transporteur(s) passant quotidiennement</FormLabel>
						<Box width="280px">
							<TransportAutocompleteField
								name="transportModes"
								margin="dense"
								placeholder="Transporteurs"
								fullWidth
								options={transportList?.map((transporter: { title: string }, index: number) => ({
									id: index,
									title: transporter.title,
								}))}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default BigShopFactoryFields;
