import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';
import { Column, Row } from 'react-table';
import { ClientShippingAddress } from 'utils/distribution';

interface EditableCellProps {
	value: ClientShippingAddress | null;
	row: Row;
	column: Column;
	updateMyData: (index: number, id: string, value: string) => void;
}
const EditableSelectShippingAddress = ({
	value: initialValue,
	row: { index },
	column,
	updateMyData,
	clientShippingAddresses,
	selectedClientId,
}: EditableCellProps & {
	clientShippingAddresses: { [key: string]: ClientShippingAddress[] };
	selectedClientId: string;
}) => {
	const [value, setValue] = React.useState<ClientShippingAddress | null>({
		id: initialValue?.id || '',
		address:
			clientShippingAddresses[selectedClientId]?.find((item: ClientShippingAddress) => item.id === initialValue?.id)
				?.address || {},
	});

	const [inputValue, setInputValue] = React.useState<string>(
		clientShippingAddresses[selectedClientId]?.find((item: ClientShippingAddress) => item.id === initialValue?.id)
			?.address?.name || ''
	);

	const handleOnChange = (newValue: ClientShippingAddress | null) => {
		setValue(newValue);
		if (!column.id) {
			return;
		}
		updateMyData(index, column.id, newValue?.id || '');
	};

	return (
		<Box>
			<Autocomplete
				aria-label={`editable-shipping-address`}
				defaultValue={value}
				onChange={(_, newValue: ClientShippingAddress | null) => {
					handleOnChange(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				options={clientShippingAddresses[selectedClientId] || []}
				isOptionEqualToValue={(option, value) => option.id === value.id && option.address === value.address}
				getOptionLabel={(option: ClientShippingAddress) => {
					return option?.address?.name || '';
				}}
				fullWidth
				size="small"
				renderInput={(params) => <TextField {...params} variant="outlined" />}
			/>
		</Box>
	);
};

export default EditableSelectShippingAddress;
