import React from 'react';
import { Box, Chip, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useField, useFormikContext } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import colors from '../app/theme/colors.scss';

interface FormikTagsProps {
	defaultValues: string[];
	label: string;
	name: string;
}
interface tagInput {
	name: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		textfieldRoot: {
			'& .MuiInputBase-root': {
				paddingTop: 0,
			},
		},
	})
);

function FormikTags<T>({ defaultValues, label, name }: FormikTagsProps) {
	const classes = useStyles();

	const { setFieldValue } = useFormikContext<T>();
	const [{ value }] = useField<tagInput[]>(name);
	const handleChange = (tags: string[]) => {
		setFieldValue(
			name,
			tags.map((tag) => ({ name: tag }))
		);
	};

	return (
		<Box className={classes.textfieldRoot}>
			{/* workaround to type autocomplete (see autocomplete argument Types) */}
			<Autocomplete<string, boolean, boolean, boolean>
				disableListWrap={true}
				multiple
				freeSolo
				size="small"
				options={[]}
				onChange={(_, tags) => handleChange(tags as string[])}
				value={value.map((val) => val.name)}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							style={{ backgroundColor: colors.blueDisabled, color: colors.primary, fontWeight: 'bold' }}
							label={option}
							size="small"
							{...getTagProps({ index })}
						/>
					))
				}
				renderInput={(params) => <TextField name={name} {...params} variant="outlined" placeholder={label} />}
			/>
		</Box>
	);
}

export default FormikTags;
