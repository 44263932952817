import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import FormikTextField from 'components/form/FormikTextField';
import { useField } from 'formik';
import insertElementAtSpecificIndex from 'utils/insertElementAtSpecificIndex';
import colors from 'app/theme/colors.scss';

interface HardlyAccessibleAreasProps {
	transporterIndex: number;
}

const HardlyAccessibleAreas = ({ transporterIndex }: HardlyAccessibleAreasProps) => {
	const [{ value: hardlyAccessibleAreas }, , helperHardlyAccessibleAreas] = useField<{ postalCode?: number | null }[]>(
		`transporters[${transporterIndex}].hardlyAccessibleAreas`
	);

	return (
		<Box textAlign="center">
			<Box py={0.5}>
				<Typography variant="h3">Code postaux</Typography>
			</Box>
			{hardlyAccessibleAreas.map((_, index) => (
				<Box display="flex" justifyContent="center" alignItems="center" key={index}>
					<Box border={3} color={colors.gray200} width="310px" height="78px" pt={2.6} borderBottom="none">
						<Box display="flex" justifyContent="center" alignItems="center">
							<FormikTextField
								name={`transporters[${transporterIndex}].hardlyAccessibleAreas[${index}].postalCode`}
								variant="outlined"
								width="164px"
								inputProps={{ maxLength: 5 }}
							/>
							<Box width="100px" display="flex" pl={2}>
								<DeleteForeverOutlinedIcon
									color="primary"
									onClick={() => {
										const filtredHardlyAccessibleAreas = hardlyAccessibleAreas.filter((_, i) => i !== index);
										helperHardlyAccessibleAreas.setValue(filtredHardlyAccessibleAreas);
									}}
								/>
								<IconButton
									onClick={() => {
										const result = insertElementAtSpecificIndex(hardlyAccessibleAreas, index + 1, { postalCode: null });
										helperHardlyAccessibleAreas.setValue(result);
									}}
									size="large"
								>
									<AddIcon color="primary" />
								</IconButton>
							</Box>
						</Box>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default HardlyAccessibleAreas;
