import React, { useMemo } from 'react';
import { Column, TableInstance, useTable } from 'react-table';
import { Box, Typography } from '@mui/material';
import { PriceSummaryTable } from '../definitions';
import { FormikNumberField } from 'components/form/FormikNumberField';
import { FormikSelectAdditionalMarginField } from '../components/AdditionalMargins';
import { MarginType } from '../../../../graphql/types';
import { formatNumberDisplay } from '../../../../utils/prices';
import SellingMarginField from '../components/SellingMarginField';

export const usePriceSummaryTable = (data: PriceSummaryTable[], disabled?: boolean): TableInstance<any> => {
	const columns = useMemo<Column<PriceSummaryTable>[]>(
		() => [
			{
				accessor: 'price',
				width: 1200,
				Header: () => null,
				Cell: ({ row }) => <Typography>{row.original?.price}</Typography>,
			},
			{
				accessor: 'prixCalage',
				Header: () => <Typography>prix calage</Typography>,
				Cell: ({ row, rows }) => {
					//TODO: convert to generic function
					const marginPrice =
						row.original.prixCalage !== '' &&
						rows[2].original.prixCalage !== '' &&
						rows[3].original.prixCalage === MarginType.Percent &&
						(rows[4].original.prixCalage as number) > 0 &&
						row.index === 5
							? ` (+${formatNumberDisplay(
									(row.original.prixCalage as number) - (rows[2].original.prixCalage as number),
									2
							  )})`
							: '';

					if (row.index === 3)
						return <FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.prixCalage" />;
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.prixCalage" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.prixCalage"
								data={row.original?.prixCalage >= 0 ? (row.original.prixCalage as number) : 0}
								marginPrice={marginPrice}
							/>
						);
					else
						return (
							<Typography>
								{row.original?.prixCalage >= 0
									? formatNumberDisplay(row.original.prixCalage as number, 2) + marginPrice
									: ''}
							</Typography>
						);
				},
			},
			{
				accessor: 'prixHorsCalage',
				Header: () => <Typography>Prix hors calage</Typography>,
				Cell: ({ row, rows }) => {
					const marginPrice =
						row.original['prixHorsCalage'] !== '' &&
						rows[2].original.prixHorsCalage !== '' &&
						rows[3].original.prixHorsCalage === MarginType.Percent &&
						(rows[4].original.prixHorsCalage as number) > 0 &&
						row.index === 5
							? `  (+${formatNumberDisplay(
									(row.original.prixHorsCalage as number) - (rows[2].original.prixHorsCalage as number),
									2
							  )})`
							: '';

					if (row.index === 1)
						return <FormikNumberField disabled={disabled} name="buyingPriceWithAmalgam" decimalScale={2} />;
					if (row.index === 3)
						return <FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.prixHorsCalage" />;
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.prixHorsCalage" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.prixHorsCalage"
								data={row.original.prixHorsCalage as number}
								marginPrice={marginPrice}
							/>
						);
					if (row.original?.prixHorsCalage >= 0)
						return (
							<Box display="flex" flexDirection="row">
								<Typography>{formatNumberDisplay(row.original?.prixHorsCalage as number, 2)}</Typography>
								<Typography style={{ fontSize: '12px' }}> {marginPrice} </Typography>
							</Box>
						);

					return null;
				},
			},
			{
				accessor: 'prixExemplaireSupp',
				Header: () => <Typography>Prix Ex.Sup.</Typography>,
				Cell: ({ row, rows }) => {
					const marginPrice =
						row.original.prixExemplaireSupp !== '' &&
						rows[2].original.prixExemplaireSupp !== '' &&
						rows[3].original.prixExemplaireSupp === MarginType.Percent &&
						(rows[4].original.prixExemplaireSupp as number) > 0 &&
						row.index === 5
							? ` (+${formatNumberDisplay(
									(row.original.prixExemplaireSupp as number) - (rows[2].original.prixExemplaireSupp as number),
									2
							  )})`
							: '';
					if (row.index === 3)
						return (
							<FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.prixExemplaireSupp" />
						);
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.prixExemplaireSupp" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.prixExemplaireSupp"
								data={row.original?.prixExemplaireSupp as number}
								marginPrice={marginPrice}
							/>
						);

					return (
						<Typography>
							{row.original?.prixExemplaireSupp >= 0
								? formatNumberDisplay(row.original?.prixExemplaireSupp as number, 5) + marginPrice
								: ''}
						</Typography>
					);
				},
			},
			{
				accessor: 'prixChangementPlaque',
				Header: () => <Typography>Prix changt par plaque</Typography>,
				Cell: ({ row, rows }) => {
					const marginPrice =
						row.original.prixChangementPlaque !== '' &&
						rows[2].original.prixChangementPlaque !== '' &&
						rows[3].original.prixChangementPlaque === MarginType.Percent &&
						(rows[4].original.prixChangementPlaque as number) > 0 &&
						row.index === 5
							? ` (+${formatNumberDisplay(
									(row.original.prixChangementPlaque as number) - (rows[2].original.prixChangementPlaque as number),
									2
							  )})`
							: '';
					if (row.index === 3)
						return (
							<FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.prixChangementPlaque" />
						);
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.prixChangementPlaque" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.prixChangementPlaque"
								data={row.original?.prixChangementPlaque >= 0 ? (row.original.prixChangementPlaque as number) : 0}
								marginPrice={marginPrice}
							/>
						);
					else
						return (
							<Typography>
								{row.original?.prixChangementPlaque >= 0
									? formatNumberDisplay(row.original?.prixChangementPlaque as number, 2) + marginPrice
									: ''}
							</Typography>
						);
				},
			},
			{
				accessor: 'prixTraitementFichier',
				Header: () => <Typography>Prix traitement fichier</Typography>,
				Cell: ({ row, rows }) => {
					const marginPrice =
						row.original.prixTraitementFichier !== '' &&
						rows[2].original.prixTraitementFichier !== '' &&
						rows[3].original.prixTraitementFichier === MarginType.Percent &&
						(rows[4].original.prixTraitementFichier as number) > 0 &&
						row.index === 5
							? ` (+${formatNumberDisplay(
									(row.original.prixTraitementFichier as number) - (rows[2].original.prixTraitementFichier as number),
									2
							  )})`
							: '';
					if (row.index === 3)
						return (
							<FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.prixTraitementFichier" />
						);
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.prixTraitementFichier" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.prixTraitementFichier"
								data={row.original?.prixTraitementFichier >= 0 ? (row.original.prixTraitementFichier as number) : 0}
								marginPrice={marginPrice}
							/>
						);
					else
						return (
							<Typography>
								{row.original?.prixTraitementFichier >= 0
									? formatNumberDisplay(row.original?.prixTraitementFichier as number, 2) + marginPrice
									: ''}
							</Typography>
						);
				},
			},
			{
				accessor: 'autrePrixFixe',
				Header: () => <Typography>Autre prix fixe</Typography>,
				Cell: ({ row, rows }) => {
					const marginPrice =
						row.original.autrePrixFixe !== '' &&
						rows[2].original.autrePrixFixe !== '' &&
						rows[3].original.autrePrixFixe === MarginType.Percent &&
						(rows[4].original.autrePrixFixe as number) > 0 &&
						row.index === 5
							? ` (+${formatNumberDisplay(
									(row.original.autrePrixFixe as number) - (rows[2].original.autrePrixFixe as number),
									2
							  )})`
							: '';
					if (row.index === 3)
						return <FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.autrePrixFixe" />;
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.autrePrixFixe" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.autrePrixFixe"
								data={row.original?.autrePrixFixe >= 0 ? (row.original.autrePrixFixe as number) : 0}
								marginPrice={marginPrice}
							/>
						);
					else
						return (
							<Typography>
								{row.original?.autrePrixFixe >= 0
									? formatNumberDisplay(row.original?.autrePrixFixe as number, 2) + marginPrice
									: ''}
							</Typography>
						);
				},
			},
			{
				accessor: 'prixCliche',
				width: 120,
				Header: () => <Typography>Prix cliché</Typography>,
				Cell: ({ row, rows }) => {
					const marginPrice =
						row.original.prixCliche !== '' &&
						rows[2].original.prixCliche !== '' &&
						rows[3].original.prixCliche === MarginType.Percent &&
						(rows[4].original.prixCliche as number) > 0 &&
						row.index === 5
							? ` (+${formatNumberDisplay(
									(row.original.prixCliche as number) - (rows[2].original.prixCliche as number),
									2
							  )})`
							: '';

					if (row.index === 3)
						return <FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.prixCliche" />;
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.prixCliche" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.prixCliche"
								data={row.original?.prixCliche >= 0 ? (row.original.prixCliche as number) : 0}
								marginPrice={marginPrice}
							/>
						);
					else
						return (
							<Typography>
								{row.original?.prixCliche >= 0
									? formatNumberDisplay(row.original?.prixCliche as number, 2) + marginPrice
									: ''}
							</Typography>
						);
				},
			},
			{
				accessor: 'prixOutillage',
				width: 120,
				Header: () => <Typography>Prix outillage</Typography>,
				Cell: ({ row, rows }) => {
					const marginPrice =
						row.original.prixOutillage !== '' &&
						rows[2].original.prixOutillage !== '' &&
						rows[3].original.prixOutillage === MarginType.Percent &&
						(rows[4].original.prixOutillage as number) > 0 &&
						row.index === 5
							? ` (+${formatNumberDisplay(
									(row.original.prixOutillage as number) - (rows[2].original.prixOutillage as number),
									2
							  )})`
							: '';

					if (row.index === 3)
						return <FormikSelectAdditionalMarginField disabled={disabled} name="additionalMargins.prixOutillage" />;
					if (row.index === 4)
						return <FormikNumberField disabled={disabled} name="margins.prixOutillage" decimalScale={2} />;
					if (row.index === 5)
						return (
							<SellingMarginField
								name="sellingPriceWithMargin.prixOutillage"
								data={row.original?.prixOutillage >= 0 ? (row.original.prixOutillage as number) : 0}
								marginPrice={marginPrice}
							/>
						);
					else
						return (
							<Typography>
								{row.original?.prixOutillage >= 0
									? formatNumberDisplay(row.original?.prixOutillage as number, 2) + marginPrice
									: ''}
							</Typography>
						);
				},
			},
		],
		[disabled]
	);

	return useTable({
		data,
		columns,
		isBorderless: true,
	});
};
