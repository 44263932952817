import { Box, Divider, Typography } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import usePopin from 'hooks/usePopin';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ClientQuoteItem } from '../../../../graphql/types';
import { SubmitAndResetButton } from '../../../../components/buttons/submit-and-reset.button';
import colors from '../../../../app/theme/colors.scss';

interface DeleteProductPopinProps {
	index: number;
}

const DeleteProductPopin = ({ index }: DeleteProductPopinProps): JSX.Element => {
	const [fieldClientQuoteItem, , helpersClientQuoteItem] = useField<ClientQuoteItem[]>('clientQuoteItem');
	const [fieldClientQuoteItemIdsToDelete, , helpersClientQuoteItemIdsToDelete] = useField<string[]>(
		'clientQuoteItemIdsToDelete'
	);

	const handleDeleteProduct = () => {
		const newClientQuoteItem = [...fieldClientQuoteItem.value];
		const clientQuoteItemToDelete = newClientQuoteItem?.splice(index, 1);
		if (clientQuoteItemToDelete[0].id)
			helpersClientQuoteItemIdsToDelete.setValue([
				clientQuoteItemToDelete[0].id,
				...fieldClientQuoteItemIdsToDelete.value,
			]);
		helpersClientQuoteItem.setValue(newClientQuoteItem);
		closePopin();
	};

	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: "Suppression d'un produit",
		maxWidth: 'md',
		fullWidth: false,
		isDisplayHeader: true,
	});

	return (
		<>
			<DeleteForeverOutlinedIcon onClick={openPopin} />
			{renderPopin(
				<>
					<Box p={3}>
						<Typography variant="h4">
							Etes-vous sûr de vouloir supprimer le produit {fieldClientQuoteItem.value[index].productName}?
						</Typography>
					</Box>
					<Divider />
					<SubmitAndResetButton
						handleCancel={closePopin}
						handleSubmit={handleDeleteProduct}
						submitBtnTitle="Supprimer le produit"
						cancelBtnTitle="Annuler"
						bkgColor={colors.error}
					/>
				</>
			)}
		</>
	);
};

export default DeleteProductPopin;
