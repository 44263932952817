import { Box, TextField } from '@mui/material';

import React, { ChangeEvent } from 'react';
import { Column, Row } from 'react-table';

import NumberFormatCustom from 'components/form/NumberFormat';
import { Distribution } from 'graphql/types';

interface EditableCellProps {
	value: string | number;
	row: Row<
		Distribution & {
			id: string;
		}
	>;
	column: Column<
		Distribution & {
			id: string;
		}
	>;
	updateMyData: (index: number, id: string | undefined, value: number | string) => void;
}
const EditableCellField = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateMyData,
}: EditableCellProps) => {
	const [value, setValue] = React.useState(initialValue);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	const onBlur = () => {
		updateMyData(index, id, value);
	};

	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<Box>
			{id === 'quantity' && (
				<TextField
					data-testid={`quantity-${index}`}
					variant="outlined"
					name={`input${index}`}
					InputProps={{
						inputComponent: NumberFormatCustom as any,
					}}
					value={value ?? ''}
					onChange={onChange}
					onBlur={onBlur}
					autoComplete="off"
				/>
			)}
			{id === 'zip' && (
				<TextField
					data-testid={`zip-${index}`}
					name={`input${index}`}
					value={value ?? ''}
					variant="outlined"
					autoComplete="off"
					onChange={onChange}
					onBlur={onBlur}
				/>
			)}
		</Box>
	);
};

export default EditableCellField;
