import { Box, Grid } from '@mui/material';
import { FormikNumberField } from 'components/form/FormikNumberField';
import { FormikSwitchField } from 'components/form/FormikSwitchField';
import { LayoutField } from 'components/form/LayoutField';
import React from 'react';

type OnSupplyProductType = {
	nbOfAmxiPoses?: false | number | null;
	disabled?: boolean;
};

export const OnSupplyProduct = ({ nbOfAmxiPoses, disabled = false }: OnSupplyProductType) => (
	<Grid container>
		<Grid item xs={3}>
			<LayoutField label="Nombre de traitements de fichiers">
				<FormikNumberField
					testid="number-file-processing"
					disabled={disabled}
					decimalScale={0}
					name="numberFileProcessing"
					width="164px"
					variant="outlined"
				/>
			</LayoutField>
		</Grid>
		<Grid item xs={3}>
			<Box display="flex" flexDirection="column">
				<LayoutField label="Nombre de changements de plaque">
					<FormikNumberField
						testid="number-plate-changes"
						disabled={disabled}
						decimalScale={0}
						name="numberPlateChanges"
						width="164px"
						variant="outlined"
					/>
				</LayoutField>
			</Box>
		</Grid>
		<Grid item xs={3}>
			<Box ml={2}>
				<LayoutField label="Nombre de cliché">
					<FormikNumberField
						testid="number-shots"
						disabled={disabled}
						decimalScale={0}
						name="numberShots"
						width="164px"
						variant="outlined"
					/>
				</LayoutField>
			</Box>
		</Grid>
		<Grid item xs={3}>
			<LayoutField label="Outillage">
				<FormikSwitchField testid="tools" disabled={disabled} name="tools" />
			</LayoutField>
		</Grid>
	</Grid>
);

export default OnSupplyProduct;
