import { PDFDocument } from 'pdf-lib';
import printJS from 'print-js';

const downloadFile = (data: Uint8Array) => {
	const blob = new Blob([data], { type: 'application/pdf' });
	const url = window.URL.createObjectURL(blob);
	printJS({
		printable: url,
		type: 'pdf',
	});
};

export const printPDFS = async (pdfUrls: string[]) => {
	const mergedPdf = await PDFDocument.create();
	for (const pdfCopyDoc of (pdfUrls.flatMap((i) => [i, i]) as unknown) as string) {
		const pdfBytes = await fetch(pdfCopyDoc).then((res) => res.arrayBuffer());

		const pdf = await PDFDocument.load(pdfBytes);
		const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
		copiedPages.forEach((page) => {
			mergedPdf.addPage(page);
		});
	}
	try {
		const mergedPdfFile = await mergedPdf.save();
		downloadFile(mergedPdfFile);
	} catch (error) {
		console.error(error);
	}
};
