import React from 'react';
import colors from '../../app/theme/colors.scss';
import { useField } from 'formik';
import { createStyles, withStyles } from '@mui/styles';
import { CheckboxProps, FormControlLabel, Switch, SwitchClassKey, SwitchProps, Theme } from '@mui/material';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
	focusVisible?: string;
}

interface Props extends SwitchProps {
	classes: Styles;
}

type FormikSwitchFieldProps = { name: string; value?: boolean; testid?: string } & Omit<
	CheckboxProps,
	'value' | 'onChange'
>;

export const IOSSwitch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 40,
			height: 20,
			padding: 0,
			margin: theme.spacing(1),
		},
		switchBase: {
			padding: 1,
			'&$checked': {
				transform: 'translateX(20px)',
				color: theme.palette.common.white,
				'& + $track': {
					backgroundColor: colors.primary,
					opacity: 1,
					border: 'none',
				},
			},
			'&$focusVisible $thumb': {
				color: colors.primary,
				border: '6px solid #fff',
			},
		},
		thumb: {
			width: 18,
			height: 18,
		},
		track: {
			borderRadius: 26 / 2,
			border: `1px solid ${theme.palette.grey[400]}`,
			backgroundColor: theme.palette.grey[50],
			opacity: 1,
			transition: theme.transitions.create(['background-color', 'border']),
		},
		checked: {},
		focusVisible: {},
	})
)(({ classes, ...props }: Props) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

export const FormikSwitchField = ({ name, value, disabled = false, testid }: FormikSwitchFieldProps): JSX.Element => {
	const [field] = useField<boolean>(name);

	return (
		<FormControlLabel
			disabled={disabled}
			control={
				<IOSSwitch data-testid={testid} checked={value ?? field.value ?? false} onChange={field.onChange} name={name} />
			}
			label={value ?? field.value ? 'Oui' : 'Non'}
		/>
	);
};
