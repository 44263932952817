import React from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';

export const IndeterminateCheckbox = ({ indeterminate, ...rest }: TableToggleAllRowsSelectedProps) => {
	return (
		<>
			<input type="checkbox" {...rest} />
		</>
	);
};
