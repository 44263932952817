import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { FILTERED_CLIENT_QUOTES } from '../graphql/queries';
import { GetClientQuotesFilteredQuery, GetClientQuotesFilteredQueryVariables } from '../graphql/queries.definitions';

const useFilteredClientQuotesLazyQuery = (
	options?: LazyQueryHookOptions<GetClientQuotesFilteredQuery, GetClientQuotesFilteredQueryVariables>
) => {
	const [getFilteredClientQuotes, { data }] = useLazyQuery<
		GetClientQuotesFilteredQuery,
		GetClientQuotesFilteredQueryVariables
	>(FILTERED_CLIENT_QUOTES, options);

	return { getFilteredClientQuotes, filteredClientQuotes: data?.filteredClientQuotes };
};

export default useFilteredClientQuotesLazyQuery;
