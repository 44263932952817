import React, { useState, SetStateAction } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import { PriceDetailsInterface } from '../../order-cockpit/definition';
import { CellOrderItemSuppliersPrices } from './CellOrderItemSuppliersPrices';
import colors from '../../../../app/theme/colors.scss';
import { getPadding, getWidthRoot } from '../../../helpers';
import ButtonDisplayContent from '../../../../components/ButtonDisplayContent';
import { TransportColis } from './TransportColis';
import { PriceDetails } from './PriceDetails';
import { useStylesSupplierPrices } from './styles';
import usePopin from 'hooks/usePopin';
import { OrderItem } from 'graphql/types';
import OrderItemEditTransport from './OrderItemEditTransport';
import OrderItemEditSupplier from './OrderItemEditSupplier';

const OrderItemCollapse = ({
	orderItem,
	orderItemIndex,
}: {
	orderItem: PriceDetailsInterface;
	orderItemIndex: number;
}) => {
	const classes = useStylesSupplierPrices();
	const [displayPriceDetails, setDisplayPriceDetails] = useState(false);

	const [selectedOrderItem, setSelectedOrderItem] = React.useState<OrderItem>();
	const [openSuppliersPopin, renderSuppliersPopin, closeSuppliersPopin] = usePopin({
		headerTitle: 'Liste des fournisseurs possibles',
		maxWidth: 'xl',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const [openTransportPopin, renderTransportPopin, closeTransportPopin] = usePopin({
		headerTitle: 'Liste des transporteurs possibles',
		maxWidth: 'xl',
		fullWidth: true,
		isDisplayHeader: true,
	});

	return (
		<Grid xs={10} item>
			{renderSuppliersPopin(
				<OrderItemEditSupplier
					orderItem={selectedOrderItem}
					orderItemIndex={orderItemIndex}
					closePopin={closeSuppliersPopin}
				/>
			)}
			{renderTransportPopin(
				<OrderItemEditTransport
					orderItem={selectedOrderItem}
					orderItemIndex={orderItemIndex}
					closePopin={closeTransportPopin}
				/>
			)}
			<Grid key={`supplier_${orderItem.productName}`} container sx={{ alignContent: 'center' }}>
				<Box className={classes.listSupplier}>
					<CellOrderItemSuppliersPrices
						label={orderItemIndex === 0 ? 'Fournisseurs les moins chers par produit' : ''}
						value={orderItem.productName}
						index={0}
						borderColor={colors.indigo700}
						widthRoot={getWidthRoot(0)}
						variant="h4"
						padding={getPadding(0)}
					/>
					<CellOrderItemSuppliersPrices
						label={orderItemIndex === 0 ? 'Fournisseur' : ''}
						value={orderItem.bestSupplier?.supplierName}
						index={1}
						borderColor={colors.indigo700}
						widthRoot={getWidthRoot(1)}
						variant="h5"
						padding={getPadding(1)}
						handleEdit={() => {
							setSelectedOrderItem(orderItem as SetStateAction<OrderItem | undefined>);
							openSuppliersPopin();
						}}
					/>
					<CellOrderItemSuppliersPrices
						label={orderItemIndex === 0 ? 'Département de départ' : ''}
						value={orderItem.bestSupplier?.deliveryDepartment || ''}
						index={2}
						borderColor={colors.indigo700}
						widthRoot={getWidthRoot(2)}
						variant="h5"
						padding={getPadding(2)}
					/>
					<CellOrderItemSuppliersPrices
						label={orderItemIndex === 0 ? 'PA Total HT(€)' : ''}
						value={(orderItem.totalPurchasePriceExcludingTax ?? 0).toFixed(2)}
						index={3}
						borderColor={colors.indigo700}
						widthRoot={getWidthRoot(3)}
						variant="h5"
						padding={getPadding(3)}
					/>
					<Box pt={orderItemIndex === 0 ? 2.5 : 0} width="2%">
						<CellOrderItemSuppliersPrices widthRoot="100%" label="" padding={getPadding()}>
							<ButtonDisplayContent
								displayContent={displayPriceDetails}
								OnDisplayContent={setDisplayPriceDetails}
								isActive
							/>
						</CellOrderItemSuppliersPrices>
					</Box>
				</Box>
			</Grid>
			<Grid container className={classes.wrapperCollapse} key={`${orderItem.productName}`}>
				<Grid xs={12} item>
					<Collapse in={displayPriceDetails}>
						<TransportColis
							orderItem={orderItem}
							handleEdit={() => {
								setSelectedOrderItem(orderItem as SetStateAction<OrderItem | undefined>);
								openTransportPopin();
							}}
						/>
						<PriceDetails orderItem={orderItem} label={orderItem.productName} />
					</Collapse>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default OrderItemCollapse;
