import { CircularProgress, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import React, { ChangeEvent } from 'react';
import NumberFormatCustom from './NumberFormat';

interface FormikNumberFieldProps {
	name: string;
	decimalScale?: number;
	width?: string;
	maxValue?: number;
	variant?: 'standard' | 'filled' | 'outlined' | undefined;
	label?: string;
	disabled?: boolean;
	bgColor?: string;
	value?: number | string;
	color?: string;
	testid?: string;
	loading?: boolean;
	onChangeControllable?: (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const FormikNumberField = ({
	name,
	decimalScale,
	width,
	maxValue,
	variant,
	label,
	disabled,
	bgColor,
	value,
	color,
	onChangeControllable,
	testid,
	loading = false,
}: FormikNumberFieldProps) => {
	const [field] = useField<number | undefined>(name);
	let val: number | undefined = value === 0 ? value : Number(value) || (Number(field.value ?? undefined) ?? '');

	return (
		<TextField
			data-testid={testid}
			variant={variant}
			style={{ width, backgroundColor: bgColor }}
			InputProps={{
				inputComponent: NumberFormatCustom as any,
				endAdornment: loading && (
					<InputAdornment position="end">
						<CircularProgress color="inherit" size={20} />
					</InputAdornment>
				),
			}}
			inputProps={{ decimalScale, maxValue, color }}
			{...field}
			label={label}
			onChange={onChangeControllable ?? field?.onChange}
			type="text"
			id="formatted-numberformat-input"
			value={val}
			disabled={disabled}
		/>
	);
};
