import { useContext } from 'react';
import PermissionContext from './PermissionContext';

type Props = {
	to?: string;
	children: JSX.Element;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted = ({ to, children }: Props): JSX.Element | null => {
	const { isAllowedTo } = useContext(PermissionContext);

	// If the user has that permission, render the children
	if ((to && isAllowedTo(to)) || !to) {
		return children;
	}

	// Otherwise, do not render anything
	return null;
};

export default Restricted;
