import { CheckboxProps } from '@mui/material';
import { Field, useField } from 'formik';
import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../app/theme/colors.scss';

type FieldCheckboxProps = {
	name: string;
	handleClick?: () => void;
	testid?: string;
} & Omit<CheckboxProps, 'value' | 'error'>;

export const useStyles = makeStyles(() =>
	createStyles({
		root: {
			margin: '8px',
			cursor: 'pointer',
			color: colors.blue500,
		},
	})
);

export const FormikCheckboxField = ({ name, handleClick, disabled = false, ...rest }: FieldCheckboxProps) => {
	const classes = useStyles();
	const [field] = useField<string | unknown | null | undefined>(name);

	return (
		<Field
			data-testid={rest.testid}
			name={name}
			type="checkbox"
			onClick={handleClick}
			value={field.value ?? false}
			checked={field.value ?? false}
			color="primary"
			disabled={disabled}
			className={classes.root}
			{...rest}
		/>
	);
};
