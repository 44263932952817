import { Box, Chip, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import colors from '../../app/theme/colors.scss';

export interface StatusSwitchProps {
	status: boolean;
	isEditable?: boolean;
	className?: string;
	onStatusChange?: (status: boolean) => void;
	activeChipLabel?: string;
	inactiveChipLabel?: string;
	variantBgColorActif?: string;
	variantBgColorDisabled?: string;
	disabled?: boolean;
	testid?: string;
}

type BackgroundColorType = {
	status: boolean;
	variantBgColorActif: string;
	variantBgColorDisabled: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chipContainer: {
			height: 20,
			'& > .MuiChip-label': {
				height: 20,
				display: 'flex',
				alignItems: 'center',
				paddingLeft: 2,
				paddingRight: 2,
			},
		},
		chipContainerSelected: {
			color: theme.palette.text.primary,
			'&, &:hover, &:focus': {
				backgroundColor: ({ status, variantBgColorDisabled }: BackgroundColorType) =>
					status ? colors.blueDisabled : variantBgColorDisabled,
			},
		},
		childChip: {
			height: 16,
			fontSize: 12,
			'& > .MuiChip-label': {
				paddingLeft: 10,
				paddingRight: 10,
			},
		},
		chipSelected: {
			color: colors.white,
			'&, &:hover, &:focus': {
				backgroundColor: ({ status, variantBgColorActif }: BackgroundColorType) =>
					status ? colors.primary : variantBgColorActif,
			},
		},
		chipNotSelected: {
			color: ({ status, variantBgColorActif }: BackgroundColorType) => (status ? colors.primary : variantBgColorActif),
			'&, &:hover, &:focus': {
				backgroundColor: 'transparent',
			},
		},
	})
);

export const StatusSwitch = ({
	status,
	isEditable = true,
	disabled = false,
	onStatusChange,
	className,
	activeChipLabel = 'Actif',
	inactiveChipLabel = 'Inactif',
	variantBgColorActif = colors.orange,
	variantBgColorDisabled = colors.orangeDisabled,
	testid,
}: StatusSwitchProps): JSX.Element => {
	const classes = useStyles({ status, variantBgColorActif, variantBgColorDisabled });

	const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		if (isEditable && onStatusChange) {
			onStatusChange(!status);
		}
	};

	const activeChip = (
		<Chip
			className={`${classes.childChip} ${status ? classes.chipSelected : classes.chipNotSelected}`}
			label={activeChipLabel}
			clickable={isEditable}
		/>
	);
	const inactiveChip = (
		<Chip
			className={`${classes.childChip} ${!status ? classes.chipSelected : classes.chipNotSelected}`}
			label={inactiveChipLabel}
			clickable={isEditable}
		/>
	);

	return (
		<Box className={className}>
			<Chip
				data-testid={testid}
				className={`${classes.chipContainer} ${classes.chipContainerSelected}`}
				label={
					<>
						{activeChip}
						{inactiveChip}
					</>
				}
				disabled={disabled}
				clickable={isEditable}
				onClick={onChipClick}
				color="primary"
			/>
		</Box>
	);
};
