import { gql } from '@apollo/client';
import SUPPLIER_FRAGMENT from '../supplier/supplierFragment';

export const CLIENT_PRICE_FRAGMENT = gql`
	fragment ClientPrice on ClientPrice {
		createdAt
		cost
		defaultCommercialProductMargin
		defaultCommercialShippingMargin
		deliveryPackagePurchasePriceInfo
		franco
		isPrepress
		isSpecificShipment
		specificSupplierId
		specificSupplier{
			...Supplier
		}
		specificEmail
		mainRfaRate
		nbShippingPoints
		palettes
		status
		tax
		updatedAt
		productName
		clientId
		productId
		quantity
		transportPurchasePriceExcludingVAT
		transportSalesPriceExcludingVAT
		productSalesPriceExcludingVAT
		productPurchasePriceExcludingVAT
		salePriceExcludingVAT
		validatedQuoteDate
		corsicaDeliveryExtra
		isMechanizable
		isFinalDistribution
	}
	${SUPPLIER_FRAGMENT}
`;
