import { gql } from '@apollo/client';

const ATTRIBUT_FRAGMENT = gql`
	fragment Attribute on Attribute {
		id
		name
		family
		index
		key
		clientQuoteKey
		clientQuoteItemKey
	}
`;

export default ATTRIBUT_FRAGMENT;
