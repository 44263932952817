import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStylesClient = makeStyles(() =>
	createStyles({
		refreshBtn: {
			margin: 0,
			padding: 0,
		},
	})
);
