import * as React from 'react';
import { Box, Button, Collapse, Divider, FormLabel, TextField, Typography } from '@mui/material';

import { VariantType, useSnackbar } from 'notistack';

import { permissionsIdsList } from '../permissions/permissionMenu';
import UsersList from '../user/UsersList';
import PermissionsList from '../permissions/PermissionsList';
import { DisplayInterface } from '../user/definitions';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Path from '../../components/Path';
import useRoleQuery from './hooks/useRoleQuery';
import { PATH_NAMES } from '../../Header/menus';

import { useRoleInsert } from './hooks/useRoleInsert';
import { useRoleUpdate } from './hooks/useRoleUpdate';
import { LineTab, LineTabs } from '../../components/tabs/LineTabs';
import useCollapse from '../../hooks/useCollapse';

interface RoleInformationsProps {
	onDisplayInterfaceUser: React.Dispatch<React.SetStateAction<DisplayInterface>>;
}

const RoleInformations = ({ onDisplayInterfaceUser }: RoleInformationsProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { id: roleId } = useParams<{ id: string }>();

	const { role } = useRoleQuery(roleId);

	const paths: (string | undefined)[] = [PATH_NAMES.Admin, PATH_NAMES.User, role ? role.name : PATH_NAMES.NewRole];

	const { renderCollapseComponent, displayContent } = useCollapse();
	const [tabIndex, setTabIndex] = React.useState<number>(0);

	const [error, setError] = React.useState<boolean>(false);

	const insertRole = useRoleInsert();

	const [roleName, setRoleName] = React.useState<string>(role ? role.name : '');

	const [roleDescription, setRoleDescription] = React.useState<string>(role ? role.description : '');

	const updateRole = useRoleUpdate();

	const history = useHistory();
	useEffect(() => {
		let permissionsList: Record<string, boolean> = {};
		const permissionsIds = role?.permissions.map((permission) => permission.key);

		permissionsIdsList.forEach((menu) =>
			permissionsIds?.includes(menu.key) ? (permissionsList[menu.key] = true) : (permissionsList[menu.key] = false)
		);

		roleId ? setCheckedItems(permissionsList) : setCheckedItems({});

		if (role) {
			setRoleName(role.name);
			setRoleDescription(role.description);
		}
	}, [roleId, role]);

	const [checkedItems, setCheckedItems] = React.useState<Record<string, boolean>>({});
	const tabs: string[] = ['Informations', 'Permissions'];

	const handleChangeRoleName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRoleName(event.target.value);
		setError(false);
	};

	const handleChangeDescriptionRole = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRoleDescription(event.target.value);

		setError(false);
	};

	const save = async () => {
		let checkedPermissions: string[] = [];

		checkedPermissions = Object.keys(checkedItems).filter((checkedItem) => checkedItems[checkedItem]);

		const variant: VariantType = 'success';
		if (roleName !== '') {
			if (roleId && roleId !== 'newrole') {
				await updateRole({
					roleId: roleId,
					data: { name: roleName, description: roleDescription, permissionKeys: checkedPermissions },
				});

				history.push(`/admin/role/${roleId}`);
			} else {
				await insertRole({
					data: {
						name: roleName,
						description: roleDescription,
						permissionKeys: checkedPermissions ?? [],
					},
				});
				setRoleName('');
				setRoleDescription('');
				setCheckedItems({});
				history.push('/admin/role/newrole');
			}

			enqueueSnackbar('Modifications enregistrées', { variant });
		} else {
			setError(true);
		}
	};

	const cancel = () => {
		let permissionsList: Record<string, boolean> = {};
		if (role) {
			setRoleName(role.name);
			setRoleDescription(role.description);
			const permissionsIds = role?.permissions.map((permission) => permission.key);
			permissionsIdsList.forEach((id) =>
				permissionsIds?.includes(id.key) ? (permissionsList[id.key] = true) : (permissionsList[id.key] = false)
			);

			setCheckedItems(permissionsList);
		} else {
			setRoleName('');
			setRoleDescription('');
			setCheckedItems({});
		}
	};
	const handleLeave = () => {
		setRoleName('');
		setRoleDescription('');
		setCheckedItems({});

		history.push('/admin/user');
	};

	const roleUsers = role?.users.map((user) => {
		return {
			id: user.id,
			familyName: user.familyName,
			givenName: user.givenName,
			role: role.name,
			phoneNumber: user.phoneNumber,
			email: user.email,
			isActive: user.isActive,
		};
	});

	return (
		<Box pt={3} ml={10} mr={10}>
			<Path paths={paths} />
			<Divider />
			<Box display={'flex'} mt={4} mb={4}>
				<Box display={'flex'} flexGrow={1}>
					<Box pr={2}>
						<Typography variant="h2">Information des rôles</Typography>
					</Box>
					{renderCollapseComponent()}
				</Box>
				<Button color="primary" onClick={cancel}>
					Annuler
				</Button>
				<Button variant="contained" color="primary" onClick={save}>
					Enregistrer les modifications
				</Button>
				<Button variant="contained" color="primary" onClick={handleLeave}>
					Quitter
				</Button>
			</Box>
			<Collapse in={displayContent}>
				<LineTabs value={tabIndex} onChange={(event, newIndex) => setTabIndex(newIndex)}>
					{tabs?.map((tab) => (
						<LineTab key={tab} label={tab} />
					))}
				</LineTabs>
				{tabIndex === 0 && (
					<Box>
						<Box width="20%">
							<Box pt={2} pb={1}>
								<FormLabel>Nom du rôle</FormLabel>
							</Box>

							<TextField
								name="name"
								variant="outlined"
								fullWidth
								error={error}
								helperText={error && 'Veuillez entrer un nom de rôle'}
								value={roleName}
								onChange={handleChangeRoleName}
							/>
							<Box pt={2} pb={1}>
								<FormLabel color="secondary">Description du rôle</FormLabel>
							</Box>
							<TextField
								multiline
								rows={5}
								fullWidth
								variant="outlined"
								value={roleDescription}
								onChange={handleChangeDescriptionRole}
							/>
						</Box>
						{roleId && roleId !== 'newrole' && (
							<UsersList
								users={roleUsers}
								titleList={'Liste des utilisateurs du rôle'}
								onDisplayFormUser={onDisplayInterfaceUser}
								isUserAddButtonDisplayed={false}
							/>
						)}
					</Box>
				)}
				{tabIndex === 1 && (
					<PermissionsList checkedItems={checkedItems} setCheckedItems={setCheckedItems} error={error} />
				)}
			</Collapse>
		</Box>
	);
};
export default RoleInformations;
