import React, { useMemo } from 'react';
import { Cell, CellValue, Column, ColumnInstance, Row, TableInstance, useRowSelect, useTable } from 'react-table';
import { Typography, Box } from '@mui/material';
import { isBoolean } from '../utils/typeGuards';
import { Contact } from '../graphql/types';

const getContactValue = (
	props:
		| (TableInstance<Contact> & {
				column: ColumnInstance<Contact>;
				row: Row<Contact>;
				cell: Cell<Contact, any>;
				value: CellValue<any>;
		  })
		| (TableInstance<Contact> & {
				column: ColumnInstance<Contact>;
				row: Row<Contact>;
				cell: Cell<Contact, string | null | undefined>;
				value: CellValue<string | null | undefined>;
		  })
		| (TableInstance<Contact> & {
				column: ColumnInstance<Contact>;
				row: Row<Contact>;
				cell: Cell<Contact, string>;
				value: CellValue<string>;
		  })
		| (TableInstance<Contact> & {
				column: ColumnInstance<Contact>;
				row: Row<Contact>;
				cell: Cell<Contact, 'Contact' | undefined>;
				value: CellValue<'Contact' | undefined>;
		  })
		| (TableInstance<Contact> & {
				column: ColumnInstance<Contact>;
				row: Row<Contact>;
				cell: Cell<Contact, boolean | null | undefined>;
				value: CellValue<boolean | null | undefined>;
		  })
): JSX.Element => {
	let content = '';
	if (isBoolean(props.value)) {
		content = props.value ? 'inactif' : 'actif';
	} else {
		if (Date.parse(props.value)) {
			content = new Date(props.value).toLocaleDateString();
		} else content = props.value;
	}

	return (
		<Box>
			<Typography>{content}</Typography>
		</Box>
	);
};

function useContactTable(contacts: Contact[], contactsDisplayLimit: number) {
	const columns = useMemo<Column<Contact>[]>(
		() => [
			{
				accessor: 'civility',
				Header: () => (
					<Box width="57px">
						<Typography>Civilité</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'name',
				Header: () => (
					<Box width="222px">
						<Typography>Nom et prénom</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'functions',
				Header: () => (
					<Box width="146px">
						<Typography>Fonction</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'landlinePhone',
				Header: () => (
					<Box width="128px">
						<Typography>Ligne directe</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'gsmPhone',
				Header: () => (
					<Box width="128px">
						<Typography>Téléphone GSM</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'privatePhone',
				Header: () => (
					<Box width="128px">
						<Typography>Téléphone privé</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'email',
				Header: () => (
					<Box width="219px">
						<Typography>Adresse mail</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'comment',
				Header: () => (
					<Box width="384px">
						<Typography>Commentaires</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'updatedAt',
				Header: () => (
					<Box width="128px">
						<Typography>Modifier le</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'inactif',
				Header: () => (
					<Box width="128px">
						<Typography>Etat</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
			{
				accessor: 'linkedIn',
				Header: () => (
					<Box width="235px">
						<Typography>LinkedIn</Typography>
					</Box>
				),
				Cell: getContactValue,
			},
		],
		[]
	);

	return useTable(
		{
			columns,
			data: contacts,
			getRowId: (item) => item.id,
			isBorderless: true,
			height: '340px',
			overflow: 'auto',
			tableCellHeight: '60px',
			scroll: contacts.length > contactsDisplayLimit ? 'scroll' : '',
		},
		useRowSelect
	);
}

export default useContactTable;
