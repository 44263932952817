import { useMemo } from 'react';
import { CellProps, Column, useTable } from 'react-table';
import { ClientStructure } from '../../../../graphql/types';
import useClientsQuery from '../../../hooks/useClientsQuery';
import EntityTitleCell from '../fields/EntityTitleCell';

const useEntitiesTable = (structures: ClientStructure[], id: string) => {
	const { clients: entities } = useClientsQuery({ OR: [{ isRoot: true, id }, { rootClientId: id }] });

	const entitiesRows = useMemo(() => {
		const initialRow = structures.map((structure) => ({ [structure.type]: '' }));
		const entitiesRows: Record<string, string>[] = [];

		structures.forEach((structure) => {
			const filtredEntitiesNames = Array.from(
				new Set(entities?.filter((entity) => entity.type === structure.type).map((entity) => entity.name))
			);

			filtredEntitiesNames.forEach((entityName, ind) => {
				if (entitiesRows[ind]) {
					entitiesRows[ind][structure.type] = entityName;
				} else {
					entitiesRows.push({ ...Object.assign({}, ...initialRow), [structure.type]: entityName });
				}
			});
		});

		return entitiesRows;
	}, [structures, entities]);

	const columns = useMemo<Column<Record<string, string>>[]>(
		() =>
			structures.map((structure) => ({
				accessor: structure.type,
				Header: structure.title,
				Cell: (cell: CellProps<Record<string, string>, string>) =>
					EntityTitleCell(cell.value, structure.type, entities),
			})),
		[structures, entities]
	);

	return useTable({
		columns,
		data: entitiesRows,
	});
};

export default useEntitiesTable;
