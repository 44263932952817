import { OrderItem } from '../graphql/types';
import { sum } from 'ramda';
import subtractPercentageToValue from '../utils/subtractPercentageToValue';

export const calculatePriceHt = (
	a: number | null | undefined,
	b: number | null | undefined,
	c: number | null | undefined
) => (a ?? 0) + (b ?? 0) + (c ?? 0);

export const calculateMargeWithRfa = (
	realMargin: number | null | undefined,
	isWithRfa: boolean | null | undefined,
	newValueRfa: boolean,
	sellingPriceHt: number,
	buyingPriceHt: number,
	rfaRate: number
) => {
	if (isWithRfa && !newValueRfa) {
		return sellingPriceHt - buyingPriceHt;
	} else if (!isWithRfa && newValueRfa) {
		return subtractPercentageToValue(rfaRate, sellingPriceHt) - buyingPriceHt;
	}
	return realMargin;
};

export const sumMargeWithRfa = (orderItem: OrderItem[]) =>
	sum(orderItem.map((orderItemValue) => orderItemValue.productMargin + orderItemValue.transportMargin));

export const sumRealMargeWithRfa = (orderItem: OrderItem[]) =>
	sum(orderItem.map((orderItemValue) => orderItemValue.realProductMargin + orderItemValue.realTransportMargin));
