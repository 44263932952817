import { gql } from '@apollo/client';
import CLIENT_QUOTE_FRAGMENT from '../../graphql/fragments/client-quote/clientQuoteFragment';

export const UPSERT_CLIENT_QUOTE = gql`
	mutation UpsertClientQuote($data: ClientQuoteUpsertInput!) {
		upsertClientQuote(data: $data) {
			...clientQuote
		}
	}
	${CLIENT_QUOTE_FRAGMENT}
`;

export const CREATE_CLIENT_QUOTE_CONFIG = gql`
	mutation CreateClientQuoteConfig($clientQuoteConfig: ClientQuoteConfigAttributesCreateInput!) {
		createClientQuoteConfig(clientQuoteConfig: $clientQuoteConfig) {
			quoteClient {
				elpevSite
				translation
			}
			quoteConfig {
				attributeId
				clientId
			}
		}
	}
`;
