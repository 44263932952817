import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import DashedHeader from '../../../../components/DashedHeader';
import { LayoutField } from '../../../../components/form/LayoutField';
import {
	IsShowSellingPriceSelectedProps,
	OnSelectStatusSellingPriceProps,
	status,
	StatusClientPrice,
} from '../../definitions';
import FormikTextField from '../../../../components/form/FormikTextField';
import EventIcon from '@mui/icons-material/Event';
import colors from '../../../../app/theme/colors.scss';
import { ClientPrice, ClientPriceStatus } from '../../../../graphql/types';
import { useFormikContext } from 'formik';
import { generateCurrentDate } from '../../../helpers';
import { BootstrapInputCustom } from '../../../../components/BootstrapInputCustom';

type DateAndStatusType = { isConsultAndEditSellingPrice?: boolean } & OnSelectStatusSellingPriceProps &
	IsShowSellingPriceSelectedProps;

export const DateAndStatus = ({
	onSelectStatusSellingPrice,
	isConsultSellingPrice,
	isConsultAndEditSellingPrice,
}: DateAndStatusType): JSX.Element => {
	const { setFieldValue, values } = useFormikContext<ClientPrice>();
	const [selectMarginType, setSelectMarginType] = useState<ClientPriceStatus>(values?.status);

	const handleStatusSelected = (event: SelectChangeEvent<unknown>) => {
		event.preventDefault();
		const status = event.target.value as ClientPriceStatus;
		const isStatus = event.target.value === ClientPriceStatus.ValidatedByCustomerQuote;
		setSelectMarginType(status);

		setFieldValue('status', status);
		isStatus
			? setFieldValue(
					'validatedQuoteDate',
					!values.validatedQuoteDate ? generateCurrentDate() : values.validatedQuoteDate
			  )
			: setFieldValue('validatedQuoteDate', null);
		onSelectStatusSellingPrice(event.target.value === ClientPriceStatus.ValidatedByCustomerQuote);
	};

	useEffect(() => {
		if (values?.status !== ClientPriceStatus.InProgress) {
			setFieldValue('status', values.status);
			setSelectMarginType(values?.status);
		} else {
			setFieldValue('status', ClientPriceStatus.InProgress);
			setSelectMarginType(ClientPriceStatus.InProgress);
		}
	}, [isConsultSellingPrice, setFieldValue, values, values.status]);

	return (
		<Box mt={5}>
			<DashedHeader>Date et statut</DashedHeader>
			<Grid container justifyContent="space-between">
				<Grid item xs={5}>
					<LayoutField label="Date de création">
						<Box width="170px" display="flex" bgcolor={colors.grey}>
							<FormControl fullWidth>
								<FormikTextField
									testid="created-at"
									name="createdAt"
									type="date"
									variant="outlined"
									disabled
									InputProps={{
										endAdornment: <EventIcon />,
									}}
								/>
							</FormControl>
						</Box>
					</LayoutField>
				</Grid>
				<Grid item xs={5}>
					<LayoutField label="Date de dernière modification">
						<Box width="170px" display="flex" bgcolor={colors.grey}>
							<FormControl fullWidth>
								<FormikTextField
									testid="updated-at"
									name="updatedAt"
									type="date"
									variant="outlined"
									disabled
									InputProps={{
										endAdornment: <EventIcon />,
									}}
								/>
							</FormControl>
						</Box>
					</LayoutField>
				</Grid>
				<Grid item xs={5}>
					<LayoutField label="Statut du prix de vente" mt={5}>
						<Box width="90%" display="flex">
							<FormControl fullWidth>
								<Select
									data-testid="status"
									value={selectMarginType}
									input={<BootstrapInputCustom />}
									variant="outlined"
									disabled={!isConsultSellingPrice && !isConsultAndEditSellingPrice}
									onChange={handleStatusSelected}
								>
									{status.map((st) => (
										<MenuItem value={st} key={st}>
											{StatusClientPrice[st]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</LayoutField>
				</Grid>
				<Grid item xs={5}>
					<LayoutField label="Date de mise en place du tarif" mt={5}>
						<Box width="170px" display="flex" bgcolor={colors.grey}>
							<FormControl fullWidth>
								<FormikTextField
									testid="validated-quote-date"
									name="validatedQuoteDate"
									type="date"
									variant="outlined"
									disabled
									InputProps={{
										endAdornment: <EventIcon />,
									}}
								/>
							</FormControl>
						</Box>
					</LayoutField>
				</Grid>
			</Grid>
		</Box>
	);
};
