import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { GET_CLIENT_PRICE_EXISTING_BY_PRODUCT_NAME } from '../graphql/queries';
import {
	ClientPricesByProductNameAndClientIdQuery,
	ClientPricesByProductNameAndClientIdQueryVariables,
} from '../graphql/queries.definitions';

export const useClientPriceExistingByProduct = (
	options?: LazyQueryHookOptions<
		ClientPricesByProductNameAndClientIdQuery,
		ClientPricesByProductNameAndClientIdQueryVariables
	>
) => {
	const [getClientPricesExisting, { data }] = useLazyQuery<
		ClientPricesByProductNameAndClientIdQuery,
		ClientPricesByProductNameAndClientIdQueryVariables
	>(GET_CLIENT_PRICE_EXISTING_BY_PRODUCT_NAME, options);

	return { clientPricesExisting: data?.clientPricesExisting, getClientPricesExisting };
};
