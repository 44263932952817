import React, { useCallback, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';

import AppListTable from 'components/tables/list-table/AppListTable';
import DashedHeader from 'components/DashedHeader';

import useSuppliersTable from '../../../hooks/useSuppliersTable';
import { OrderItem, OrderItemSuppliersPricesModel } from '../../../../graphql/types';
import { useField } from 'formik';
import FormikTextField from 'components/form/FormikTextField';
import { productAndTransporterCalculation } from 'order/helpers';

const OrderItemEditSupplier = ({
	orderItem,
	orderItemIndex,
	closePopin,
}: {
	orderItem?: OrderItem;
	orderItemIndex: number;
	closePopin: () => void;
}) => {
	const [currentSupplierId, setCurrentSupplierId] = useState(orderItem?.bestSupplierId);

	const [supplierCommentField] = useField(`orderItem[${orderItemIndex}].supplierComment`);
	const [orderItemField, , orderItemFieldHelpers] = useField(`orderItem`);

	const toggleCurrentSupplier = useCallback((currentSupplierId?: string) => {
		setCurrentSupplierId(currentSupplierId);
	}, []);

	const suplliersTable = useSuppliersTable(
		orderItem?.suppliersPrices as OrderItemSuppliersPricesModel[],
		orderItem?.bestSupplierId,
		toggleCurrentSupplier
	);

	const handleUpdateBestSupplier = () => {
		orderItemField.value[orderItemIndex].bestSupplierId = currentSupplierId;
		orderItemFieldHelpers.setValue(productAndTransporterCalculation(orderItemField.value));
		closePopin();
	};

	return (
		<Box mb={4} px={2}>
			<AppListTable tableInstance={suplliersTable} />

			<DashedHeader>Justification du changement de fournisseur</DashedHeader>
			<Grid container alignItems="center">
				<Grid item xs={9}>
					<FormikTextField
						multiline
						rows={6}
						variant="outlined"
						value={supplierCommentField.value}
						name={`orderItem[${orderItemIndex}].supplierComment`}
						fullWidth
						placeholder="Mon commentaire"
						aria-label="Update supplier comment"
					/>
				</Grid>
				<Grid item xs={3}>
					<Box display="flex" justifyContent="center">
						<Button
							variant="contained"
							color="primary"
							disabled={!supplierCommentField.value}
							onClick={handleUpdateBestSupplier}
							aria-label="Update best supplier"
						>
							Enregistrer le choix
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default OrderItemEditSupplier;
