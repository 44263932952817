import React from 'react';
import { Box, Divider, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { useQuotePreviewStyles } from '../styles';
import {
	CLIENT_QUOTE_LANGUAGE,
	ClientQuoteTranslateProp,
	clientsUsersProps,
	ELPEV_SITE,
	ElpevSiteProp,
} from '../../definition';
import SelectDataClientQuote from '../../component/selectDataClientQuote';
import { LayoutField } from '../../../../components/form/LayoutField';
import { ClientQuoteTranslate, ElpevSite } from '../../../../graphql/types';
import { HandleSelectClient } from '../../../definitions';

const ClientInformation = ({ clientsUsers, clientId, context }: clientsUsersProps): JSX.Element => {
	const classes = useQuotePreviewStyles();

	const handleSelectClient: HandleSelectClient = (value) => {
		if (context?.getQuoteDataSelectedClient) {
			context?.getQuoteDataSelectedClient(value?.name as string);
		}
	};

	const handleSelectElpevSite = (event: SelectChangeEvent<unknown>) =>
		context?.selectClientDataQuote({
			elpevSite: (event.target.value as ElpevSite) ?? ElpevSite.Paris,
		});

	const handleSelectTranslate = (event: SelectChangeEvent<unknown>) =>
		context?.selectClientDataQuote({
			translation: (event.target.value as ClientQuoteTranslate) ?? ClientQuoteTranslate.French,
		});

	return (
		<Grid xs={12} className={classes.body} item>
			<Grid container justifyContent="flex-end" spacing={3}>
				<Grid xs={12} item>
					<Box>
						<Typography variant="h2">Informations client</Typography>
					</Box>
				</Grid>
				<Grid xs={12} item>
					<Grid container direction="row" justifyContent="space-between">
						<Grid xs={3} item>
							<SelectDataClientQuote
								handleSelectClient={handleSelectClient}
								clientsUsers={clientsUsers}
								id=""
								clientId={clientId}
							/>
						</Grid>
						<Grid xs={3} item>
							<LayoutField label="Site Elpev">
								<Box>
									<FormControl fullWidth>
										<Select
											value={context?.clientDataSelected.elpevSite ?? ElpevSite.Empty}
											defaultValue={ElpevSite.Empty}
											variant="outlined"
											onChange={handleSelectElpevSite}
										>
											{ELPEV_SITE.map((elpevSite) => (
												<MenuItem value={elpevSite} key={elpevSite}>
													{ElpevSiteProp[elpevSite]}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							</LayoutField>
						</Grid>
						<Grid xs={3} item>
							<LayoutField label="Traduction">
								<Box>
									<FormControl fullWidth>
										<Select
											value={context?.clientDataSelected.translation ?? ClientQuoteTranslateProp.French}
											defaultValue={ClientQuoteTranslateProp[ClientQuoteTranslate.French]}
											variant="outlined"
											onChange={handleSelectTranslate}
										>
											{CLIENT_QUOTE_LANGUAGE.map((language) => (
												<MenuItem value={language} key={language}>
													{ClientQuoteTranslateProp[language]}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							</LayoutField>
						</Grid>
					</Grid>
					<Box width="100" my={2}>
						<Divider />
					</Box>
				</Grid>
				<Grid xs={12} item>
					<Alert severity="info">
						<Typography variant="h6" color="primary">
							Cocher les cases qui apparaitront sur la facture
						</Typography>
					</Alert>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ClientInformation;
