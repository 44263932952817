import { gql } from '@apollo/client';

const ADDRESS_FRAGMENT = gql`
	fragment Address on Address {
		name
		city
		country
		id
		firstAddress
		deliveryDepartment
		isShipping
		name
		postBox
		secondAddress
		thirdAddress
		zipCode
	}
`;

export default ADDRESS_FRAGMENT;
