import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import colors from '../app/theme/colors.scss';
import AppMenu from './AppMenu';

export const drawerWidth: number = 264;

type props = {
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
	openDrawer: boolean;
};

const useStyles = makeStyles(() =>
	createStyles({
		hide: {
			display: 'none',
		},
		drawerPaper: {
			width: drawerWidth,
			background: colors.black100,
			color: colors.white,
			borderStyle: 'none',
		},
	})
);

function Sidebar({ setOpenDrawer, openDrawer }: props) {
	const classes = useStyles();

	return (
		<Box>
			<Box bgcolor={colors.black100} border={0} height={44} pl={3} pr={3} pt={0.55}>
				{!openDrawer ? (
					<IconButton
						color="inherit"
						onClick={() => setOpenDrawer(true)}
						className={clsx(openDrawer && classes.hide)}
						size="large"
					>
						<MenuIcon fontSize={'large'} />
						<Box ml={1} fontSize={18} mt={0.4}>
							Menu
						</Box>
					</IconButton>
				) : (
					<IconButton
						onClick={() => setOpenDrawer(false)}
						aria-label="open drawer"
						color="inherit"
						className={clsx(!openDrawer && classes.hide)}
						size="large"
					>
						<CloseIcon fontSize={'large'} />
						<Box ml={1} fontSize={18} mt={0.4}>
							Menu
						</Box>
					</IconButton>
				)}
			</Box>

			<Box>
				<Drawer variant="persistent" anchor="left" open={openDrawer} classes={{ paper: classes.drawerPaper }}>
					<AppMenu onClose={() => setOpenDrawer(false)} />
				</Drawer>
			</Box>
		</Box>
	);
}

export default Sidebar;
