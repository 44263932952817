export interface WorkingHours {
	workingDays: WorkingDay[];
}

export interface WorkingDay {
	typename?: string;
	day: string;
	isWorkingDay?: boolean;
	start?: Date;
	end?: Date;
}

export enum WeekDay {
	monday = 'monday',
	tuesday = 'tuesday',
	wednesday = 'wednesday',
	thursday = 'thursday',
	friday = 'friday',
	saturday = 'saturday',
	sunday = 'sunday',
}

export const WeekTranslate: { [x: string]: string } = {
	monday: 'Lundi',
	tuesday: 'Mardi',
	wednesday: 'Mercredi',
	thursday: 'Jeudi',
	friday: 'Vendredi',
	saturday: 'Samedi',
	sunday: 'Dimanche',
};

export const initialWorkingStartHour = new Date(2021, 9, 6, 9);
export const initialWorkingEndHour = new Date(2021, 9, 6, 18);

export const initialWorkingDays = [
	{
		day: WeekDay.monday,
		isWorkingDay: true,
		start: initialWorkingStartHour,
		end: initialWorkingEndHour,
	},
	{
		day: WeekDay.tuesday,
		isWorkingDay: true,
		start: initialWorkingStartHour,
		end: initialWorkingEndHour,
	},
	{
		day: WeekDay.wednesday,
		isWorkingDay: true,
		start: initialWorkingStartHour,
		end: initialWorkingEndHour,
	},
	{
		day: WeekDay.thursday,
		isWorkingDay: true,
		start: initialWorkingStartHour,
		end: initialWorkingEndHour,
	},
	{
		day: WeekDay.friday,
		isWorkingDay: true,
		start: initialWorkingStartHour,
		end: initialWorkingEndHour,
	},
	{
		day: WeekDay.saturday,
		isWorkingDay: false,
		start: initialWorkingStartHour,
		end: initialWorkingEndHour,
	},
	{
		day: WeekDay.sunday,
		isWorkingDay: false,
		start: initialWorkingStartHour,
		end: initialWorkingEndHour,
	},
];
