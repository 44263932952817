import { Download, Upload } from '@mui/icons-material';
import {
	Grid,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import colors from 'app/theme/colors.scss';
import { TemplateNameEnum } from 'graphql/types';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import { typeNotifications } from 'utils/definition';
import { FileUploadRestApi } from 'utils/upload-file';
import { IMPORT_SHOPS } from '../graphql/mutations';
import useTemplateUrlGenerate from '../hooks/useTemplateUrlGenerate';

export const ShopImport = ({ clientNames }: { clientNames: string[] }) => {
	const [clientName, setClientName] = useState<string>('');
	const [clientNameError, setClientNameError] = useState<boolean>(false);
	const [shopTemplateUrl, setShopTemplateUrl] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { generateTemplateUrl } = useTemplateUrlGenerate();

	const { isUploadInProgress, uploadFile, response } = FileUploadRestApi(IMPORT_SHOPS, {
		file: null,
		rootClientName: clientName,
	});

	useEffect(() => {
		if (!shopTemplateUrl) {
			generateTemplateUrl({
				variables: {
					templateName: {
						templateName: TemplateNameEnum.Store,
					},
				},
			}).then((response) => {
				setShopTemplateUrl('http://' + response?.data?.generateFileUrl.url);
			});
		}

		if (response) {
			if (response.data && response.data.importClients) {
        const dataCount = response.data.importClients.length;
				enqueueSnackbar(`L'import des magasins a réussi. ${dataCount} lignes traitées.`, { variant: typeNotifications.Success });
			} else {
        enqueueSnackbar('L\'import des magasins a échoué', { variant: typeNotifications.Error });
			}
		}
	}, [enqueueSnackbar, generateTemplateUrl, response, shopTemplateUrl]);

	const handleChangeClientName = (event: SelectChangeEvent) => {
		setClientName(event.target.value);
		setClientNameError(false);
	};

	const handleChangeImportShopsInput = (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return;
		}
		const file = event.target.files[0];
		handleImportShops(file);
	};

	const handleClickImportShopsButton = (event: MouseEvent<HTMLElement>) => {
		if (!clientName) {
			event.preventDefault();
			setClientNameError(true);
		}
	};

	const handleImportShops = async (file: File) => {
		uploadFile(file, 'shopImport');
	};

	return (
		<Grid container spacing={2} my={2}>
			<Grid item xs={3}>
				<Typography variant="h3">Importer des magasins</Typography>
			</Grid>
			<Grid item xs={2}>
				{shopTemplateUrl ? (
					<Button href={shopTemplateUrl} sx={{ textTransform: 'none' }} color="primary" variant="contained">
						<>
							<Download sx={{ marginRight: 1 }} />
							Récupérer le template
						</>
					</Button>
				) : null}
			</Grid>
			<Grid item xs={1}></Grid>
			<Grid item xs={2}>
				<FormControl fullWidth>
					<InputLabel sx={{ lineHeight: '0.5rem' }}>Client</InputLabel>
					<Select value={clientName} label="Client" onChange={handleChangeClientName} error={clientNameError}>
						{clientNames.map((clientName: string) => (
							<MenuItem value={clientName} key={clientName}>
								{clientName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={4}>
				<Button
					sx={{ textTransform: 'none' }}
					disabled={isUploadInProgress}
					color="primary"
					variant="contained"
					component="label"
					onClick={handleClickImportShopsButton}
				>
					<input accept=".xlsx" onChange={handleChangeImportShopsInput} type="file" hidden />
					{isUploadInProgress ? (
						<CircularProgress size="1.5rem" sx={{ color: colors.white }} />
					) : (
						<>
							<Upload sx={{ marginRight: 1 }} />
							Importer un fichier
						</>
					)}
				</Button>
			</Grid>
		</Grid>
	);
};
