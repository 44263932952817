import React, { useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useClientInvoiceTable } from './hooks/useClientInvoiceTable';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { useFormikContext } from 'formik';
import { InvoiceStatus } from '../../../../graphql/types';
import { getClientInvoices } from '../../../helpers';
import { OrderFormModel } from '../definition';

export type ClientInvoicesTable = {
	createdAt: null | string;
	id: string;
	sendAt: null | string;
	icons: string;
	magasin: string | null | undefined;
	validateAt: null | string;
	status?: InvoiceStatus | null;
	file?: string | ArrayBuffer | null;
	invoiceId?: string | null;
	url: string | null;
};

export const ClientInvoices = () => {
	const { values } = useFormikContext<OrderFormModel & { invoiceFile: Record<string, File> }>();
	const clientInvoices = useMemo(() => {
		return getClientInvoices(values);
	}, [values]);

	const tableInstance = useClientInvoiceTable(clientInvoices);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box mb={4}>
					<Box mt={6} mb={4}>
						<Divider />
					</Box>
					<Typography variant="h2">Documents client</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<AppListTable tableInstance={tableInstance} />
			</Grid>
		</Grid>
	);
};
