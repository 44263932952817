import React, { useState } from 'react';
import { Column, useTable } from 'react-table';

import { Maybe, OrderItem } from 'graphql/types';
import { Radio, Typography } from '@mui/material';
import { formatNumberDisplay } from 'utils/prices';

type SupplierColumn = Partial<{
	supplierName?: Maybe<string> | string;
	supplierPrice?: Maybe<number> | number;
	supplierTotalPAHT?: Maybe<number> | number;
	actions?: Maybe<string> | string;
	supplierId?: Maybe<string> | string;
}>;

const useSupplierTable = (
	supplierPrices: SupplierColumn[],
	bestSupplierId?: OrderItem['bestSupplierId'],
	toggleSelectedSupplier?: (supplierId?: string) => void
) => {
	const bestSupplier = supplierPrices?.find((supplierPrice) => supplierPrice.supplierId === bestSupplierId);
	const [currentSupplierId, setCurrentSupplierId] = useState(bestSupplierId);

	const columns = React.useMemo<Column<SupplierColumn>[]>(
		() => [
			{
				Header: 'Nom',
				accessor: 'supplierName',
			},
			{
				Header: 'PA produit HT avec RFA et RSP (€)',
				accessor: 'supplierPrice',
				Cell: ({ value }) => <Typography>{value?.toFixed(2)}</Typography>,
			},
			{
				Header: 'Différence en %',
				accessor: 'supplierPrice',
				id: 'percentageDiff',
				Cell: ({ value }) => {
					const currentPrice = bestSupplier?.supplierPrice ? bestSupplier.supplierPrice : 0;
					const diff = value ? value - currentPrice : 0;
					const percentDiff = diff !== 0 ? (currentPrice !== 0 ? (diff / currentPrice) * 100 : 100) : 0;
					return <Typography>{percentDiff ? `${formatNumberDisplay(percentDiff, 2)} %` : '-'}</Typography>;
				},
			},
			{
				Header: 'Différence en euros',
				accessor: 'supplierPrice',
				id: 'euroDiff',
				Cell: ({ value }) => {
					const currentPrice = bestSupplier?.supplierPrice ? bestSupplier.supplierPrice : 0;
					const diff = value ? value - currentPrice : 0;
					return <Typography>{diff ? `${formatNumberDisplay(diff, 2)} €` : '-'}</Typography>;
				},
			},
			{
				Header: 'Choix',
				accessor: 'supplierId',
				Cell: ({ value: supplierId }) => (
					<Radio
						checked={supplierId === currentSupplierId}
						inputProps={{
							'aria-label': `Select supplier ${supplierId}`,
						}}
						onClick={() => {
							setCurrentSupplierId(supplierId);
							toggleSelectedSupplier && toggleSelectedSupplier(supplierId as string);
						}}
					/>
				),
			},
		],
		[toggleSelectedSupplier, bestSupplier, currentSupplierId]
	);

	return useTable({
		columns,
		data: supplierPrices ?? [],
	});
};

export default useSupplierTable;
