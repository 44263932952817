import React from 'react';
import { Box } from '@mui/material';
import DashedHeader from '../../../../components/DashedHeader';
import { CellOrderItemSuppliersPrices } from './CellOrderItemSuppliersPrices';
import colors from '../../../../app/theme/colors.scss';
import { useStylesSupplierPrices } from './styles';
import { PriceDetailsType } from '../../order-cockpit/definition';
import { getPadding } from '../../../helpers';

export function PriceDetails({ orderItem, label }: PriceDetailsType): JSX.Element {
	const classes = useStylesSupplierPrices();

	return (
		<Box className={classes.collapse} p={2}>
			<Box className={classes.wrapperSuppliersPrices}>
				<Box width="100%" mt={5}>
					<DashedHeader>{`Détails prix - ${label}`}</DashedHeader>
				</Box>
				<Box className={classes.listSupplierPrice}>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="PA Produit HT (€)"
						value={(orderItem?.productPurchasePriceExcludingVAT ?? 0).toFixed(2)}
						index={0}
						borderColor={colors.turquoise500}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_productPrice${0}`}
					/>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="Taux RSP (%)"
						value={orderItem?.bestSupplier?.supplierRspRate ?? 0}
						index={1}
						borderColor={colors.turquoise500}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_productPrice${1}`}
					/>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="Taux RFA (%)"
						value={orderItem?.bestSupplier?.supplierRfaRate ?? 0}
						index={2}
						borderColor={colors.turquoise500}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_productPrice${2}`}
					/>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="PA produit HT avec RFA (€)"
						value={(orderItem?.productPurchasePriceExcludingVATWithRFA ?? 0).toFixed(2)}
						index={3}
						borderColor={colors.turquoise500}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_productPrice${3}`}
					/>
				</Box>
				<Box className={classes.listSupplierPrice}>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="PA Transport HT (€)"
						value={(orderItem?.transporterPurchasePriceExcludingVAT ?? 0).toFixed(2)}
						index={0}
						borderColor={colors.turquoise500}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_transportPrice${0}`}
					/>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="Taux RFA (%)"
						value={orderItem?.transporterRfaRate}
						index={1}
						borderColor={colors.turquoise500}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_transportPrice${0}`}
					/>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="PA Transport HT avec RFA (€)"
						value={(orderItem?.transporterPurchaseExcludingVATWithRfa ?? 0).toFixed(2)}
						index={2}
						borderColor={colors.turquoise500}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_transportPrice${0}`}
					/>
				</Box>
				<Box className={classes.listSupplierPrice}>
					<CellOrderItemSuppliersPrices
						widthRoot="24%"
						label="PA Total HT avec RFA (€)"
						value={(orderItem?.totalPurchasePriceExcludingTax ?? 0).toFixed(2)}
						index={0}
						isMarginRoot
						padding={getPadding(1)}
						key={`price_details_transportPrice${0}`}
					/>
				</Box>
			</Box>
		</Box>
	);
}
