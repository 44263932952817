import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ListCharacteristics from './ListCharacteristics';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../../app/theme/colors.scss';
import { CharacteristicsCardProps } from '../../definitions';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: '80%',
			marginLeft: '130px',
			display: 'grid',
		},
		bodyHeader: {
			width: 'inherit',
			height: '80px',
			marginTop: '16px',
			padding: '8px 3px',
		},
		bodyCardDivider: {
			width: 'inherit',
			padding: '4px 0px 4px 10px',
			color: colors.gray100,
			borderBottom: `1px solid ${colors.gray200}`,
		},
		bodyCard: {
			width: 'inherit',
			borderRadius: '4px',
			marginBottom: '56.36px',
			backgroundColor: colors.white,
			border: `2px solid ${colors.gray200}`,
		},
		bodyCardContent: {
			width: 'inherit',
			padding: '20px 0px',
			cursor: 'pointer',
		},
	})
);

export const CharacteristicsCard = ({
	name,
	listItemAttributes,
	handleOpenPopinToDeleteAttribute,
	disabled,
	listItemPrintValues,
	editPrintValue,
	handleOpenPopinToDeletePrintValue,
	handleAttributeSelected,
	handleOpenPopinToAddPrintValue,
	handleOpenPopinToAddAttribute,
	isSelected,
	currentAttributeId,
}: CharacteristicsCardProps) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Grid xs={12} item>
				<Grid xs={4} item>
					<Box className={classes.bodyHeader}>
						<Typography variant="h2">{!disabled ? name : ''}</Typography>
					</Box>
				</Grid>
			</Grid>

			<Grid item container xs={12}>
				<Grid item xs={9}>
					<Box className={classes.bodyCard}>
						<Box className={classes.bodyCardDivider} bgcolor={disabled && isSelected ? colors.blue200 : colors.white}>
							<Typography variant="h4">
								{disabled ? name : `Caractéristiques de la famille ${name?.toLowerCase()}`}
							</Typography>
						</Box>
						{listItemAttributes &&
							listItemAttributes.map((listItem, i) => (
								<ListCharacteristics
									key={`${listItem.id}_${listItem.name}_${i}`}
									id={listItem.id}
									name={listItem.name}
									isSelected={isSelected}
									currentAttributeId={currentAttributeId}
									handleOpenPopinToDeleteAttribute={handleOpenPopinToDeleteAttribute}
									handleAttributeSelected={handleAttributeSelected}
								/>
							))}
						{listItemPrintValues &&
							listItemPrintValues.map((listItem, i) => (
								<ListCharacteristics
									key={`${listItem.attributeId}_${i}`}
									id={listItem.attributeId}
									name={listItem.printValue}
									disabled
									handleOpenPopinToDeletePrintValue={handleOpenPopinToDeletePrintValue}
									editPrintValue={editPrintValue}
								/>
							))}
						<Box className={classes.bodyCardContent} ml={0}>
							<Button
								color="primary"
								onClick={disabled ? handleOpenPopinToAddPrintValue : handleOpenPopinToAddAttribute}
								startIcon={<AddCircleSharpIcon fontSize="large" />}
							>
								{disabled ? 'Ajouter une valeur' : 'Ajouter une caractéristique'}
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
