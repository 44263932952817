import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER } from '../graphql/queries';
import { GetCurrentUserQuery, GetCurrentUserQueryVariables } from '../graphql/queries.definitions';

const useCurrentUserQuery = () => {
	const { data, refetch: refetchUser } = useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GET_CURRENT_USER, {
		fetchPolicy: 'network-only',
	});
	return { user: data?.currentUser, refetchUser };
};

export default useCurrentUserQuery;
