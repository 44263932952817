import { useMutation } from '@apollo/client';
import { INSERT_PRINT_VALUES } from '../graphql/mutations';
import { InsertPrintValuesMutation, InsertPrintValuesMutationVariables } from '../graphql/mutations.definitions';
import { PrintValueGroupByFamily } from '../definitions';
import { normalizePrintValues } from '../helpers';
import { PRINT_VALUES } from '../graphql/queries';

export const usePrintValuesMutation = () => {
	const [insertPrintValueMutation] = useMutation<InsertPrintValuesMutation, InsertPrintValuesMutationVariables>(
		INSERT_PRINT_VALUES
	);

	const insertPrintValues = async (prints: PrintValueGroupByFamily[]) => {
		const { data } = await insertPrintValueMutation({
			variables: {
				prints: normalizePrintValues(prints),
			},
			refetchQueries: [
				{
					query: PRINT_VALUES,
				},
			],
		});

		return { printValues: data?.printValues };
	};

	return { insertPrintValues };
};
