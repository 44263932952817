import * as React from 'react';
import { useField } from 'formik';
import { MenuItem, TextField } from '@mui/material';

import FormikSelectField from 'components/form/FormikSelectField';
import { ClientQuoteStatus, QuoteType } from 'graphql/types';

import {
	clientQuoteStatus,
	OPTIONS_CLIENT_ORDER_TO_BE_CREATE,
	OPTIONS_CLIENT_PRICE_TO_BE_VALIDATE,
} from '../../definitions';

const SelectClientQuoteStatus = () => {
	const [statusField] = useField<ClientQuoteStatus>('status');
	const [{ value: quoteType }] = useField<QuoteType>('type');

	const optionsClientQuoteStatus = React.useMemo(
		() =>
			quoteType === QuoteType.ClientOrderToBeCreate
				? OPTIONS_CLIENT_ORDER_TO_BE_CREATE
				: OPTIONS_CLIENT_PRICE_TO_BE_VALIDATE,
		[quoteType]
	);

	if (statusField?.value === ClientQuoteStatus.InProgress) {
		return (
			<TextField
				fullWidth
				variant="outlined"
				disabled={statusField?.value === ClientQuoteStatus.InProgress}
				value={clientQuoteStatus.find((status) => status.key === statusField.value)?.value}
			/>
		);
	}

	return (
		<FormikSelectField name="status" variant="outlined">
			{optionsClientQuoteStatus.map(({ name, value }) => (
				<MenuItem key={value} value={value}>
					{name}
				</MenuItem>
			))}
		</FormikSelectField>
	);
};

export default SelectClientQuoteStatus;
