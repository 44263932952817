import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import colors from '../../app/theme/colors.scss';

interface InformationMessageProps {
	title: string;
	message: string;
}

const useStyles = makeStyles({
	iconButton: {
		backgroundColor: colors.grayCard,
		padding: '2rem',
		fontSize: '6rem',
		marginBottom: '0.75rem',
	},
});

const InformationMessage = ({ title, message }: InformationMessageProps): JSX.Element => {
	const classes = useStyles();
	return (
		<Box mt={3} p={3} bgcolor={colors.white} textAlign={'center'}>
			<SearchIcon className={classes.iconButton} color={'action'} fontSize="large" />
			<Typography variant="h2">{title}</Typography>
			<Typography variant="h5">{message}</Typography>
		</Box>
	);
};

export default InformationMessage;
