import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from 'app/theme/colors.scss';
import { OrderItemUpsertInput } from 'graphql/types';
import { formatNumberDisplay } from 'utils/prices';

interface OrderItemDetailsProps {
	orderItem: OrderItemUpsertInput;
}

const useStyles = makeStyles({
	firstCell: {
		borderLeft: '3px solid',
		borderLeftColor: colors.turquoise500,
		borderTopLeftRadius: '3px',
		borderTopRightRadius: '3px',
	},
});

const OrderItemDetails = ({ orderItem }: OrderItemDetailsProps) => {
	const classes = useStyles();
	const horizontalCell = [
		{ label: 'Quantité', value: orderItem.quantity ?? 0 },
		{ label: 'Poids théorique', value: orderItem.productTotalWeight ?? 0, fraction: 2 },
		{ label: 'Nb de version', value: orderItem.numberVersion ?? 0 },
		{ label: 'Nb de plaque', value: orderItem.numberPlateChanges ?? 0 },
		{ label: 'Nb de traitement de fichier', value: orderItem.numberFileProcessing ?? 0 },
		{ label: 'Nb de cliché', value: orderItem.numberShots ?? 0 },
		{ label: 'Outillage', value: !orderItem.tools ? 'Non' : 'Oui' },
	];

	return (
		<Box display="flex" py={4} pl={1}>
			{horizontalCell.map(({ label, value, fraction }, index) => (
				<Box mr={0.5} key={index} width="100%">
					<Typography variant="h6" color="textSecondary">
						{label}
					</Typography>
					<Box
						className={index === 0 ? classes.firstCell : ''}
						borderLeft={'5px'}
						borderColor={colors.red500}
						width={label === 'Nb de traitement de fichier' ? '170px' : '100%'}
						bgcolor={colors.white}
						p={1}
					>
						<Typography variant="h5">{formatNumberDisplay(value as number, fraction)}</Typography>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default OrderItemDetails;
