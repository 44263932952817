import colors from 'app/theme/colors.scss';
import { useField } from 'formik';
import React, { ChangeEvent } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export type OnChangeUnControlledArgsType = ChangeEvent<HTMLInputElement>;
type FormikTextFieldProps = {
	name: string;
	value?: unknown;
	bgColor?: string;
	width?: string;
	testid?: string;
	onChangeUnControlled?: (event: OnChangeUnControlledArgsType) => void;
} & Omit<TextFieldProps, 'value' | 'onChange' | 'error' | 'helperText' | 'format'>;

const FormikTextField = ({
	name,
	value,
	bgColor,
	width,
	disabled,
	onChangeUnControlled,
	testid,
	...rest
}: FormikTextFieldProps): JSX.Element => {
	const [field, meta] = useField<unknown | null | undefined>(name);

	return (
		<TextField
			variant="standard"
			name={name}
			style={{ width, backgroundColor: bgColor ?? (disabled ? colors.grey : undefined) }}
			value={value ?? field.value ?? ''}
			onChange={onChangeUnControlled ?? field.onChange}
			onBlur={field.onBlur}
			disabled={disabled}
			data-testid={testid}
			error={meta.touched && Boolean(meta.error)}
			helperText={meta.touched && meta.error}
			{...rest}
		/>
	);
};

export default FormikTextField;
