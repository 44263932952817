import { useMutation } from '@apollo/client';
import { GENERATE_AN_ORDER_INVOICE } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { GenerateOrderPdfMutation, GenerateOrderPdfMutationVariables } from '../graphql/mutations.definitions';
import { typeNotifications } from '../../utils/definition';
import { useParams } from 'react-router-dom';
import { GET_ORDER_BY_ID } from 'order/graphql/queries';

export const useGenerateOrderInvoicePdf = () => {
	const { id } = useParams<{ id: string }>();
	const { enqueueSnackbar } = useSnackbar();

	const [generateOrderPdfMutation, { loading }] = useMutation<
		GenerateOrderPdfMutation,
		GenerateOrderPdfMutationVariables
	>(GENERATE_AN_ORDER_INVOICE);

	const generateOrderInvoicePdf = async (orderId: string, invoiceId?: string | null, parentOrderId?: string) => {
		const { data } = await generateOrderPdfMutation({
			variables: {
				orderId,
				invoiceId,
			},
			refetchQueries: [
				{
					query: GET_ORDER_BY_ID,
					variables: {
						id: parentOrderId ?? id,
					},
				},
			],
		});

		const variant = data?.pdf.success ? typeNotifications.Success : typeNotifications.Error;
		enqueueSnackbar(data?.pdf.description, { variant });
	};
	return { generateOrderInvoicePdf, loading };
};

export default useGenerateOrderInvoicePdf;
