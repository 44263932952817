import React from 'react';
import './accountTable.css';
import { AccountModel } from '../../definition';

export const AccountTable = ({ account }: { account?: AccountModel[] }): JSX.Element => (
	<table className="accountTable">
		<tbody>
			{account?.map((row, i) => (
				<tr key={row.value + i}>
					<th className="accountTable-th">{row.name}</th>
					<th className="accountTable-th">{row.value}</th>
				</tr>
			))}
		</tbody>
	</table>
);
