import { Box, FormControl, Grid, Typography } from '@mui/material';
import { LayoutField } from 'components/form/LayoutField';
import { useFormikContext } from 'formik';
import colors from 'app/theme/colors.scss';
import React, { useMemo, useState } from 'react';
import { useClientsByCurrentUser } from 'clients/hooks/useClientsByCurrentUser';
import DashedHeader from 'components/DashedHeader';
import { NewOrderItem, OrderFormModel, SelectBillingAddress } from '../order-cockpit/definition';
import { Client, Source } from 'graphql/types';
import FormikAutocompleteField from 'components/form/FormikAutocompleteField';
import { isString } from 'utils/typeGuards';
import { HandleSelectClient } from 'client-quote/definitions';
import SelectFileReferences from '../order-cockpit/components/SelectFileReferences';
import useFileReferencesLazyQuery from 'file-reference/hooks/useFileReferencesLazyQuery';
import usePossibleBillingsClientsQuery from 'clients/hooks/usePossibleBillingsClientsQuery';
import { AutocompleteValue } from '@mui/material/useAutocomplete';
import { ComponentTitle } from '../../definition';

const ClientInformations = ({ title }: ComponentTitle): JSX.Element => {
	const { clientsUsers, loading: loadingClientsUsers } = useClientsByCurrentUser(true);
	const { getFileReferences, fileReferences, loading } = useFileReferencesLazyQuery();
	const [hasInitialBillingAddress, setHasInitialBillingAddress] = useState(false);

	const { setFieldValue, values, errors } = useFormikContext<
		OrderFormModel & {
			newOrderItem: NewOrderItem;
			clientName: string;
			fileReferenceId: string;
			billingEntityObject: string;
		}
	>();

	const {
		possibleBillingClients,
		getPossibleBillingClients,
		loading: loadingpossibleBillingClients,
	} = usePossibleBillingsClientsQuery(clientsUsers);

	const showBillingAddress = ({ name, billingAddress }: any) => {
		setFieldValue('billingEntityObject', { id: values?.clientId, name });
		setFieldValue('billingEntity', values?.clientId);
		setFieldValue('billingAddress', {
			name: billingAddress?.name,
			firstAddress: billingAddress?.firstAddress,
			zipCode: billingAddress?.zipCode,
			city: billingAddress?.city,
			country: billingAddress?.country,
		});
	};

	const setOrderDistribution = (clientId: string) => {
		const shippingAddress = clientsUsers
			?.find((client) => client.id === clientId)
			?.clientShippingAddress?.find(({ isMain }) => isMain);

		setFieldValue('orderDistributions[0].clientId', clientId);
		setFieldValue('orderDistributions[0].clientShippingAddressId', shippingAddress?.id);
		setFieldValue('orderDistributions[0].clientShippingAddressName', shippingAddress?.address?.name);
	};

	const handleSelectClient: HandleSelectClient = async (value) => {
		const clientId = value?.id;
		if (clientId) {
			setFieldValue('clientId', clientId);
			setFieldValue('clientName', clientsUsers?.find((client) => client.id === clientId)?.name);
			setOrderDistribution(clientId);
			if (hasInitialBillingAddress) setHasInitialBillingAddress(false);

			await getFileReferences({
				variables: {
					filters: {
						clientId,
						source: Source.OneSupply,
					},
				},
			});

			await getPossibleBillingClients({
				variables: {
					id: clientId,
				},
			});
		} else {
			setFieldValue('clientId', undefined);
			setFieldValue('clientName', '');
			setFieldValue('newOrderItem.productId', undefined);
			setFieldValue('newOrderItem.productName', '');
			setFieldValue('operationName', '');
			setFieldValue('fileReferenceId', '');
			setFieldValue('clientQuoteId', '');
			setHasInitialBillingAddress(true);
			setFieldValue('billingEntity', undefined);
			setFieldValue('billingEntityObject', undefined);
			setFieldValue('billingAddress', undefined);
		}
	};

	const handleShowBillingAddress = (value: AutocompleteValue<SelectBillingAddress, false, any, true>) => {
		showBillingAddress(value);
	};

	const clientsOptions = useMemo(
		() =>
			clientsUsers
				?.map((clientUser) => ({
					id: clientUser.id,
					name: clientUser.name,
				}))
				.reduce<Pick<Client, 'name' | 'id'>[]>((acc, clientUser) => {
					const index = acc.findIndex((client) => client.id === clientUser.id);
					if (index === -1) acc = [...acc, clientUser];
					return acc;
				}, []) ?? [],
		[clientsUsers]
	);

	const clientShippingAddressOptions = useMemo(
		() =>
			!hasInitialBillingAddress && possibleBillingClients
				? possibleBillingClients.map((billingClient) => billingClient)
				: [],
		[possibleBillingClients, hasInitialBillingAddress]
	);

	const displayErrors = (errors: string) => <Typography color="error">{errors}</Typography>;

	return (
		<Box mb={5} mt={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">{title}</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box mb={5}>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={3}>
						<DashedHeader>{title} </DashedHeader>

						<LayoutField label="Nom du client">
							<Box mb={3} bgcolor={!!values.clientId ? colors.grey : ''}>
								<FormikAutocompleteField<Pick<Client, 'name' | 'id'>>
									options={clientsOptions}
									loading={loadingClientsUsers}
									getOptionLabel={(option) =>
										clientsOptions.length ? (option as Pick<Client, 'name' | 'id'>).name : ''
									}
									onChange={(_, value) => {
										if (!isString(value)) {
											handleSelectClient(value);
										}
									}}
									value={{ id: values?.clientId, name: values?.clientName }}
									autoHighlight
									textField={{ error: !!errors.clientId }}
								/>
								{errors.clientId && displayErrors(errors.clientId)}
							</Box>
						</LayoutField>

						<SelectFileReferences fileReferences={fileReferences} loading={loading} />
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={3}>
						<DashedHeader>Adresse de facturation</DashedHeader>
						<Box mb={3} mt={3}>
							<LayoutField label="Client à facturer">
								<FormControl fullWidth>
									<FormikAutocompleteField<any>
										disabled={!clientShippingAddressOptions.length}
										loading={loadingpossibleBillingClients}
										options={clientShippingAddressOptions}
										value={values.billingEntityObject ?? ''}
										getOptionLabel={(option) => (option as SelectBillingAddress).name ?? ''}
										onChange={(_, value) => handleShowBillingAddress(value)}
										autoHighlight
									/>
								</FormControl>
							</LayoutField>
						</Box>

						<Typography variant="h4">{values.billingAddress?.name}</Typography>
						<Typography variant="h5">{values.billingAddress?.firstAddress}</Typography>
						<Typography variant="h5">
							{values.billingAddress?.zipCode} {values.billingAddress?.city}
						</Typography>
						<Typography variant="h5">{values.billingAddress?.country}</Typography>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ClientInformations;
