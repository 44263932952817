import React from 'react';
import { Box, Button, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LayoutField } from '../../../components/form/LayoutField';
import FormikSelectField from '../../../components/form/FormikSelectField';
import EventIcon from '@mui/icons-material/Event';
import { Client } from '../../../graphql/types';
import useUsersQuery from '../../../admin/user/hooks/useUsersQuery';
import colors from '../../../app/theme/colors.scss';
import FormikTextField from '../../../components/form/FormikTextField';
import DashedHeader from 'components/DashedHeader';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import CommercialInformation from './CommercialInformation';
import EmailAutocompleteField from './EmailAutocompleteField';
interface ElpevInformationProps {
	client: Client;
	onClickImportClientFromBaseDB: () => void;
	isRoleAdmin: boolean;
}

const ElpevInformation = ({
	client,
	onClickImportClientFromBaseDB,
	isRoleAdmin,
}: ElpevInformationProps): JSX.Element => {
	const { users } = useUsersQuery();

	return (
		<Box my={3}>
			<Box mb={4}>
				<Box mb={1}>
					<Typography variant="h2">Informations Elpev</Typography>
				</Box>
				<Button color="primary" onClick={onClickImportClientFromBaseDB}>
					<RefreshIcon height="20px" />
					<Typography variant="h5" color="primary">
						Récupérer les informations de la base Elpev
					</Typography>
				</Button>
			</Box>

			<Box mx={15}>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<LayoutField label="Directeur Clientèle">
							<Box mb={3} bgcolor={colors.grey}>
								<FormikTextField name="users.commercial.fullName" fullWidth variant="outlined" disabled={true} />
							</Box>
						</LayoutField>

						<LayoutField label="Responsable Direction">
							<Box mb={3} width="100%" bgcolor={colors.grey}>
								<FormikTextField name="users.responsable.fullName" fullWidth variant="outlined" disabled={true} />
							</Box>
						</LayoutField>
						<LayoutField label="Chef de projet">
							<Box width="100%">
								<FormControl fullWidth>
									<FormikSelectField name="projectManagerId" variant="outlined">
										{users?.map((item) => (
											<MenuItem value={item.id} key={item.id}>
												{`${item.givenName}  ${item.familyName}`}
											</MenuItem>
										))}
									</FormikSelectField>
								</FormControl>
							</Box>
						</LayoutField>
						<Box width="100%" mt={2}>
							<Box mb={3} width="100%">
								<LayoutField label="Emails de notification">
									<EmailAutocompleteField name="emails" fullWidth placeholder="Saisir un email et ⏎ " options={[]} />
								</LayoutField>
							</Box>
						</Box>
						<Box mt={3}>
							<DashedHeader>Informations comptables</DashedHeader>

							<LayoutFieldForm label="Code analytique" name="clientAnalyticCode" disabled={!isRoleAdmin} />
							<LayoutFieldForm label="Compte client" name="clientAccountingAccount" disabled={!isRoleAdmin} />
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box mb={3} width={'50%'}>
							<LayoutField label="Crée le">
								<Box width={'100%'} bgcolor={colors.grey}>
									<TextField
										name={client.updatedAt}
										fullWidth
										variant="outlined"
										value={new Date(client.createdAt).toLocaleString()}
										disabled
										InputProps={{
											endAdornment: <EventIcon />,
										}}
									/>
								</Box>
							</LayoutField>
						</Box>

						<Box mb={3} width={'50%'}>
							<LayoutField label="Modifié le">
								<Box width={'100%'} bgcolor={colors.grey}>
									<TextField
										name={client.updatedAt}
										fullWidth
										variant="outlined"
										value={new Date(client.updatedAt).toLocaleString()}
										disabled
										InputProps={{
											endAdornment: <EventIcon />,
										}}
									/>
								</Box>
							</LayoutField>
						</Box>

						<LayoutField label="Modifié par ">
							<Box mb={3} width={'100%'} bgcolor={colors.grey}>
								<TextField name={client.updatedAt} fullWidth variant="outlined" disabled />
							</Box>
						</LayoutField>
						<CommercialInformation />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ElpevInformation;
