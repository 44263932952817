import React, { useCallback, useMemo } from 'react';
import { CheckCircleOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import colors from '../../../../../app/theme/colors.scss';
import withStyles from '@mui/styles/withStyles';
import { UncontrolledDatePicker } from '../../../../../components/picker/UncontrolledDatePicker';
import { useField, useFormikContext } from 'formik';
import { getStatusByType } from '../../../../helpers';
import { INDEX_CHILD_ORDER, INDEX_ORDER } from '../definitions';
import { OrderFormModel } from '../../definition';

const ItemCell = withStyles(() => ({
	root: {
		display: 'flex',
		width: '100%',
	},
}))(Box);

const WrapCell = withStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'space-around',
	},
}))(ItemCell);

interface CellClientInvoiceTableProps {
	name: string;
}

export const ClientBillingTableByDate = ({ name }: CellClientInvoiceTableProps) => {
	const { setFieldValue } = useFormikContext<OrderFormModel>();
	const [field, meta] = useField<Date | null | undefined>(name);
	const statusList = useMemo(() => name.split('.'), [name]);

	const handleValidToChange = (date: unknown) => {
		const statusName =
			statusList.length > INDEX_CHILD_ORDER ? `${statusList[0]}.${statusList[1]}.status` : `${statusList[0]}.status`;
		setFieldValue(name, date);
		setFieldValue(statusName, getStatusByType(statusList[statusList.length > 2 ? INDEX_CHILD_ORDER : INDEX_ORDER]));
	};

	const getNameField = useMemo(
		() =>
			statusList.length > INDEX_CHILD_ORDER
				? [...statusList].slice(0, statusList.length - 1).join('.')
				: `${statusList[0]}`,
		[statusList]
	);

	const handleUpdateStatus = useCallback(() => {
		if (statusList[statusList.length - 1] === 'sendAt') {
			setFieldValue(`${getNameField}.status`, 'ToCount');
		} else if (statusList[statusList.length - 1] === 'validateAt') {
			setFieldValue(`${getNameField}.sendAt`, null);
			setFieldValue(`${getNameField}.status`, 'Created');
		} else {
			setFieldValue(`${getNameField}.validateAt`, null);
			setFieldValue(`${getNameField}.sendAt`, null);
			setFieldValue(`${getNameField}.status`, '');
		}
	}, [getNameField, setFieldValue, statusList]);

	return (
		<WrapCell>
			<ItemCell>
				<UncontrolledDatePicker
					inputVariant="standard"
					value={field.value ?? null}
					handleClear={() => setFieldValue(name, null)}
					onChange={handleValidToChange}
					handleUpdateStatus={handleUpdateStatus}
					name={name}
				/>
				<Box color={field.value && !meta.error ? colors.green800 : colors.error}>
					{field.value && !meta.error ? (
						<CheckCircleOutlined fontSize="inherit" />
					) : (
						<ErrorOutlineOutlined fontSize="inherit" />
					)}
				</Box>
			</ItemCell>
			{meta.error && (
				<Box fontSize="10px" width="80%" color={colors.error}>
					{meta.error}
				</Box>
			)}
		</WrapCell>
	);
};
