import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

interface Props {
	label: string;
	value?: string | null;
}

export const ProductInformation = ({ label, value }: Props): JSX.Element => (
	<>
		<Box display="flex">
			<Typography variant="h5"> {label}</Typography>
			<Box mx="6px">
				<Typography variant="h5">:</Typography>
			</Box>
			<Typography variant="h4">{value}</Typography>
		</Box>
	</>
);
