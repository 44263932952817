import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import LayoutFieldForm from '../../../../../components/form/LayoutFieldForm';
import { useField } from 'formik';
import { ClientShippingAddress, OrderItem } from '../../../../../graphql/types';
import { useQueryProvider } from '../../../../../hooks/useProvider';
import { GET_DEPARTMENT } from '../../../../../graphql/queries/queries';
import { DepartmentQuery, DepartmentQueryVariables } from '../../../../../graphql/queries/queries.definitions';

const ShowProductAndTransport = () => {
	const [fieldOrderItem] = useField<OrderItem[] | null | undefined>('orderItem');
	const [fieldIsDistribution] = useField<Boolean | undefined>('isDistribution');
	const [fieldOrderShippingAddress] = useField<ClientShippingAddress | undefined>('shippingAddress');

	//@todo replace by clientShippingAddress of OrderDistribution model
	const { data } = useQueryProvider<DepartmentQuery, DepartmentQueryVariables>(GET_DEPARTMENT, {
		variables: {
			filter: {
				code: fieldOrderShippingAddress.value?.address?.zipCode?.substring(0, 2) ?? '',
			},
		},
	});

	const productTotalWeight = useMemo(
		() =>
			fieldOrderItem?.value?.reduce((sum, item) => {
				sum += item?.productTotalWeight;
				return sum;
			}, 0),
		[fieldOrderItem]
	);

	return (
		<Grid xs={8}>
			<Box pl={3}>
				<Grid container alignItems="center" spacing={3}>
					<Grid xs={3} item>
						<LayoutFieldForm
							placeholder="0"
							label="Nombre de produits"
							name="productsNumber"
							value={fieldOrderItem?.value?.length}
							type="text"
							disabled
						/>
					</Grid>
					{!fieldIsDistribution?.value && (
						<Grid xs={4} item>
							<LayoutFieldForm
								label="Département de destination"
								name="department"
								value={data?.department}
								type="text"
								disabled
							/>
						</Grid>
					)}
					<Grid xs={3} item>
						<LayoutFieldForm
							placeholder="0.0"
							label="Poids total (kg)"
							name="productTotalWeight"
							value={productTotalWeight?.toFixed(2) ?? 0.0}
							type="text"
							disabled
						/>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);
};

export default ShowProductAndTransport;
