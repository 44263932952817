import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Checkbox,
	TableSortLabel,
	TableHead,
	Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { isBoolean, isNumber } from '../utils/typeGuards';
import { useHistory } from 'react-router-dom';

interface AppTableProps {
	bodyCelles: {}[];
	headCells: { key: string; label: string; sort: boolean }[];
	pathRoute?: string;
}

const useStyles = makeStyles({
	tableContainer: {
		maxHeight: 300,
	},
});

const AppTable = ({ bodyCelles, headCells, pathRoute }: AppTableProps): JSX.Element => {
	const classes = useStyles();
	const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');
	const history = useHistory();

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator<Key extends string>(
		order: 'asc' | 'desc',
		orderBy: Key
	): (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function stableSort<T>(array: {}[], comparator: (a: T, b: T) => number) {
		const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	const handleRequestSort = (event: React.MouseEvent<Element>, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler = (property: string) => (event: React.MouseEvent<Element>) => {
		handleRequestSort(event, property);
	};

	return (
		<Paper>
			<TableContainer className={classes.tableContainer}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							{headCells.map((headCell: { key: string; label: string; sort: boolean; width?: string }) => {
								return (
									<TableCell
										width={headCell.width}
										key={headCell.key}
										sortDirection={orderBy === headCell.key ? order : false}
									>
										{headCell.sort ? (
											<TableSortLabel hideSortIcon={true} onClick={createSortHandler(headCell.key)}>
												{headCell.label}
												{order === 'desc' && orderBy === headCell.key ? (
													<ArrowDropUpIcon key={headCell.key} />
												) : (
													<ArrowDropDownIcon key={headCell.key} />
												)}
											</TableSortLabel>
										) : (
											headCell.label
										)}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{stableSort(bodyCelles, getComparator(order, orderBy)).map((row, index) => {
							return (
								<TableRow
									hover
									key={index}
									onClick={() => {
										pathRoute && history.push(`${pathRoute + row.id}`);
									}}
								>
									{headCells.map((headCell, index) => (
										<TableCell key={index}>
											{!isBoolean(row[headCell.key]) ? (
												isNumber(row[headCell.key]) ? (
													row[headCell.key]
														?.toString()
														.replace(/(\d)(?=(?:\d{3})+(?:$))/g, '$1 ')
														.replace('.', ',')
												) : (
													row[headCell.key]
												)
											) : (
												<Checkbox color="primary" checked={row[headCell.key] === true} />
											)}
										</TableCell>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default AppTable;
