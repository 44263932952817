import { useMutation } from '@apollo/client';
import { UPDATE_SUPPLIER_BY_COMPANYBASE } from '../graphql/mutations';
import {
	UpdateSupplierByCompanyBaseMutation,
	UpdateSupplierByCompanyBaseMutationVariables,
} from '../graphql/mutations.definitions';
import { GET_SUPPLIER_BY_ID } from '../graphql/queries';
import { ERROR_MESSAGE, SUCCESS_UPDATE_MESSAGE, typeNotifications } from '../../utils/definition';
import { useSnackbar } from 'notistack';

const useSupplierFromBaseDBUpdate = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [updateClientFromBaseMutation] = useMutation<
		UpdateSupplierByCompanyBaseMutation,
		UpdateSupplierByCompanyBaseMutationVariables
	>(UPDATE_SUPPLIER_BY_COMPANYBASE);

	let variant = typeNotifications.Error;

	const updateSupplierFromBaseDB = async (companyBase: string, supplierId: string) => {
		try {
			const { data } = await updateClientFromBaseMutation({
				variables: { companyBase, supplierId },
				refetchQueries: [
					{
						query: GET_SUPPLIER_BY_ID,
						variables: {
							id: supplierId,
						},
					},
				],
			});
			const variant = data ? typeNotifications.Success : typeNotifications.Error;
			const message = data ? SUCCESS_UPDATE_MESSAGE : ERROR_MESSAGE;
			enqueueSnackbar(message, { variant });
		} catch (error) {
			enqueueSnackbar(ERROR_MESSAGE, { variant });
		}
	};

	return { updateSupplierFromBaseDB };
};
export default useSupplierFromBaseDBUpdate;
