import React from 'react';
import { Box, Typography } from '@mui/material';
import { useField } from 'formik';
import { Order } from 'graphql/types';
import OrderInformation from './OrderInformation';
import { InvoiceOrderModel } from '../../definitions';

interface ProductInvoiceProps {
	order?: InvoiceOrderModel;
}

export const ProductInvoice = ({ order }: ProductInvoiceProps): JSX.Element => {
	const [field] = useField<Order | null | undefined>('order');
	return (
		<>
			<Box mb={4} mt={4}>
				<Typography variant="h2">Produits</Typography>
				<Box mx={15} mt={3}>
					{field?.value?.orderItem?.map((item, index) => (
						<Box m={2} key={index}>
							<OrderInformation orderItem={item} index={index} order={order} />
						</Box>
					))}
				</Box>
			</Box>
		</>
	);
};
