import { useMutation } from '@apollo/client';
import { OrderItemUpsertInput, UpsertOrderInput, Maybe } from 'graphql/types';
import { UPSERT_ORDER } from 'order/graphql/mutations';
import { UpsertOrderMutation, UpsertOrderMutationVariables } from 'order/graphql/mutations.definitions';
import { GET_ORDERS, GET_ORDER_BY_ID } from 'order/graphql/queries';
import omit from 'ramda/src/omit';
import { orderItemPick } from '../form/order-creation/definitions';
import { pick } from 'ramda';
import { FilesFromOrderItem } from 'order/definition';
import Axios from 'axios';

export const getFilesFromOrderItems = (orderItems: Maybe<OrderItemUpsertInput[]> | undefined): FilesFromOrderItem[] => {
	const files: FilesFromOrderItem[] = [];
	for (let item of orderItems as (OrderItemUpsertInput & {
		assetFile: File[];
	})[]) {
		files.push({ file: item.assetFile || null, orderItemId: undefined });
	}
	return files;
};

export const setOrderItemsIdsToFiles = (
	orderItems: OrderItemUpsertInput[] | undefined,
	files: FilesFromOrderItem[]
) => {
	if (!orderItems) {
		return;
	}

	for (let index = 0; index < orderItems.length; index++) {
		if (!!files[index].file) {
			const id = orderItems[index].id;
			files[index].orderItemId = id as string;
		}
	}
};

export const uploadOrderItemsFilesAndThumbnails = async (files: FilesFromOrderItem[]) => {
	const formData = new FormData();

	files.forEach((item) => {
		if (!!item.file) {
			formData.append(`${item.orderItemId}`, item.file[0]);
		}
	});

	const token = localStorage.getItem('accessToken');

	Axios.post(`${process.env.REACT_APP_REST_ENDPOINT}/order/upload/files`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			authorization: `Bearer ${token}`,
		},
	}).catch((error) => {
		console.log(error);
	});
};

const useOrderUpsert = () => {
	const [upsertOrderMutation] = useMutation<UpsertOrderMutation, UpsertOrderMutationVariables>(UPSERT_ORDER);

	return async (orderUpsert: UpsertOrderInput) => {
		const orderToCreate = omit(
			[
				'client',
				'invoiceFile',
				'clientPricesExisting',
				'__typename',
				'source',
				'paymentType',
				'comment',
				'childrenOrder',
				'createdAt',
				'fileReference',
				'bestTransporter',
				'bestDeliveryParcelPurchasePrice',
				'followStatus',
				'onepacUserId',
				'onepacUserName',
				'statusComment',
				'statusUpdatedAt',
				'shippingAddress',
				'billingEntityObject',
			],
			orderUpsert
		);

		const { billingAddress, orderItem, orderDistributions, ...res } = orderToCreate;

		const mutation = await upsertOrderMutation({
			variables: {
				upsertOrderInput: {
					orderItem: orderItem?.map((item) => ({
						...pick(orderItemPick, item),
						weight: item.productTotalWeight,
						transportersPrices: item.transportersPrices
							? item.transportersPrices.map((transporter) => ({
									...omit(['__typename'], transporter),
							  }))
							: [],
						transportSellingPriceHtWithClientRfaRectified: item?.transportSellingPriceHtWithClientRfaRectified ?? 0,
						suppliersPrices: item.suppliersPrices?.map((supplierPrice) =>
							omit(['__typename', 'supplierTotalPAHT', 'siteAddress1', 'deliveryDepartment'], supplierPrice)
						),
					})),
					billingAddress: {
						city: billingAddress?.city,
						name: billingAddress?.name ?? '',
						country: billingAddress?.country,
						firstAddress: billingAddress?.firstAddress,
						secondAddress: billingAddress?.secondAddress,
						zipCode: billingAddress?.zipCode,
						thirdAddress: billingAddress?.thirdAddress,
					},
					orderDistributions: orderDistributions?.map(
						({ clientId, clientShippingAddressId, clientShippingAddressName }) => ({
							clientId,
							clientShippingAddressId,
							clientShippingAddressName,
						})
					),
					...res,
				},
			},
			refetchQueries: [
				{
					query: GET_ORDERS,
				},
				(() =>
					res.id
						? {
								query: GET_ORDER_BY_ID,
								variables: { id: res.id ?? undefined },
						  }
						: '')(),
			],
		});

		const files: { file: File[]; orderItemId: string | undefined }[] = getFilesFromOrderItems(orderItem);
		const orderItems = mutation.data?.upsertOrder?.orderItem;
		setOrderItemsIdsToFiles(orderItems, files);
		if (!!files) {
			await uploadOrderItemsFilesAndThumbnails(files);
			return;
		}

		return mutation;
	};
};

export default useOrderUpsert;
