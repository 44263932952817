import React, { memo, useMemo } from 'react';
import { Box, FormControl } from '@mui/material';
import { LayoutField } from './form/LayoutField';
import useDepartmentQuery from '../clients/hooks/useDepartmentQuery';
import colors from '../app/theme/colors.scss';
import FormikAutocompleteField from './form/FormikAutocompleteField';
import { Department } from '../graphql/types';
import { useField } from 'formik';
import { isString } from 'utils/typeGuards';

interface DepartmentsProps {
	title: string;
	name: string;
	disabledDepartments?: boolean;
	testid?: string;
}

const Departments = ({ title, name, disabledDepartments, testid }: DepartmentsProps): JSX.Element => {
	const { departments, loading } = useDepartmentQuery();

	const [, , { setValue }] = useField<string>(name);

	const optionsDepartments = useMemo(
		() =>
			departments?.map(({ name, code }) => ({
				name,
				code,
			})) ?? [],
		[departments]
	);

	return (
		<LayoutField label={title}>
			<Box mb={3} width="100%" bgcolor={disabledDepartments ? colors.grey : ''}>
				<FormControl fullWidth>
					<FormikAutocompleteField<Department>
						testid={testid}
						options={optionsDepartments}
						loading={loading}
						getOptionLabel={(option) => `${(option as Department).code} - ${(option as Department).name}`}
						name={name}
						disabled={disabledDepartments}
						textField={{
							name,
						}}
						autoHighlight
						onChange={(_, value) => {
							if (!isString(value)) {
								setValue(value?.code as string);
							}
						}}
					/>
				</FormControl>
			</Box>
		</LayoutField>
	);
};

export default memo(Departments);
