import React, { Dispatch, memo, SetStateAction, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { QuoteHeaderDataDisplay } from '../component/QuoteHeaderDataDisplay';
import logo from '../../../../public/images/logo_nanotera.png';
import { useQuotePreviewStyles } from '../styles';
import { AccountTable } from '../component/AccountTable';
import { QuoteVirtualizationFooter } from '../component/QuoteVirtualizationFooter';
import { useQuotePreviewTable } from './hooks/useQuotePreviewTable';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { QuoteAttributeContextProp, QuotePreviewTable } from '../../definition';

import { setQuoteValuesToAttributes } from '../../helpers';
import { useFormikContext } from 'formik';
import { ClientQuote } from 'graphql/types';

export interface VisualizationQuoteProps {
	context?: QuoteAttributeContextProp;
	setIsShowQuotePreview: Dispatch<SetStateAction<boolean>>;
	handleClosePopin: () => void;
}

const PreviewQuoteTable = ({
	quoteAttribute,
	index,
}: {
	quoteAttribute: QuotePreviewTable[];
	index: number;
}): JSX.Element => {
	const tablesInstances = useQuotePreviewTable(quoteAttribute, index);

	return <AppListTable tableInstance={tablesInstances} />;
};

export const VisualizationQuote = memo(
	({ context, setIsShowQuotePreview, handleClosePopin }: VisualizationQuoteProps) => {
		const classes = useQuotePreviewStyles();
		const { values } = useFormikContext<ClientQuote>();
		const quoteAttributes = useMemo(
			() =>
				context?.quotePreviewData?.quoteAttributes?.map((quoteAttributeConfig) => {
					return setQuoteValuesToAttributes([quoteAttributeConfig], values);
				}),
			[context, values]
		);

		const versionDate = useMemo(() => {
			const hours = context?.quotePreviewData?.createdAt
				? context?.quotePreviewData?.createdAt.toString().split('T')[1].slice(0, 8)
				: 0;
			const versionNumber = context?.quotePreviewData?.version ?? 0;
			return (
				versionNumber +
				' ' +
				(context?.quotePreviewData?.createdAt
					? ` du ${new Date(context?.quotePreviewData?.createdAt).toLocaleDateString()} ${hours}`
					: '')
			);
		}, [context]);

		const billingAddress = useMemo(
			() =>
				`${context?.quotePreviewData?.clientInvoice?.billingAddress?.zipCode ?? ''}, ${
					context?.quotePreviewData?.clientInvoice?.billingAddress?.city ?? ''
				}, ${context?.quotePreviewData?.clientInvoice?.billingAddress?.country ?? ''}`,
			[context]
		);

		return (
			<Grid xs={12} item>
				<Box className={classes.wrapVisualization}>
					<Grid container justifyContent="space-around">
						<Grid xs={8} item>
							<Grid container>
								<Grid xs={12} item>
									<img width="30%" alt="logo" src={logo} />
								</Grid>
							</Grid>
							<Grid container>
								<Grid xs={6} item style={{ marginBottom: 10 }}>
									{context?.quotePreviewData?.infosElpev.map(({ label, ...rest }, i) =>
										Object.keys(rest).map((key) => (
											<QuoteHeaderDataDisplay key={label} label={label} value={rest[key]} />
										))
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid xs={4} item>
							<Grid xs={12} item>
								<Box border="1px solid #000" pl={1}>
									<Box>
										<Typography variant="h4">{context?.quotePreviewData?.clientInvoice?.name ?? ''}</Typography>
										<Typography variant="h6">
											{context?.quotePreviewData?.clientInvoice?.billingAddress?.firstAddress ?? ''}
										</Typography>
										<Typography variant="h6">{billingAddress}</Typography>
									</Box>
									<Box className={classes.marginHeader}>
										<Typography variant="h6">
											N° TVA: {context?.quotePreviewData?.clientInvoice?.vatNumber ?? ''}
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid container>
						<Grid xs={4} item>
							{context?.quotePreviewData?.operations.map(({ label, ...rest }) =>
								Object.keys(rest).map((key) => <QuoteHeaderDataDisplay key={label} label={label} value={rest[key]} />)
							)}
						</Grid>
						<Grid xs={4} item>
							<Box textAlign="center" pr={15}>
								<Typography variant="h3">Devis n° {values.id}</Typography>
								<Typography variant="h4">Version {versionDate}</Typography>
							</Box>
						</Grid>
						<Grid xs={4} item>
							<Box textAlign="right">
								<AccountTable account={context?.quotePreviewData?.account} />
							</Box>
							<Box textAlign="right">
								<Typography style={{ color: 'red' }} variant="h6">
									{context?.quotePreviewData?.sousReserveDeRepartitionDefinitve}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid container justifyContent="flex-end">
						<Grid xs={12} item>
							<Box className={classes.quoteTabData}>
								{(quoteAttributes ? quoteAttributes.slice(0, -1) : []).map((quoteAttribute, index) => (
									<PreviewQuoteTable index={index} quoteAttribute={quoteAttribute} key={`clientQuoteTable_${index}`} />
								))}
							</Box>
						</Grid>
					</Grid>
					<Grid container>
						<QuoteVirtualizationFooter
							context={context}
							setIsShowQuotePreview={setIsShowQuotePreview}
							handleClosePopin={handleClosePopin}
						/>
					</Grid>
				</Box>
			</Grid>
		);
	}
);
