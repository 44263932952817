export interface FileReferenceType {
	clientId: string;
	operationName: string;
	elpevResponsibleId: string;
	askBy: string | null;
	clientReference: string | null;
}

export const initialValues = {
	clientId: '',
	operationName: '',
	elpevResponsibleId: '',
	askBy: null,
	clientReference: null,
};
