import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { Typography } from '@mui/material';
import { FilterClientPrice } from '../../definitions';
import { formatNumberDisplay } from '../../../../utils/prices';
import { ClientPriceStatus } from 'graphql/types';

const useExistingClientPricesTable = (clientPricesExisting?: FilterClientPrice[]) => {
	const columns = useMemo<Column<FilterClientPrice>[]>(
		() => [
			{
				accessor: 'quantity',
				Header: 'Quantité',
				Cell: ({ row }) => <Typography>{formatNumberDisplay(row.original?.quantity, 0) ?? ''}</Typography>,
			},
			{
				accessor: 'productPurchasePriceExcludingVAT',
				Header: 'PA produit HT  (€)',
				Cell: ({ row }) => (
					<Typography>{formatNumberDisplay(row.original?.productPurchasePriceExcludingVAT, 2) ?? ''}</Typography>
				),
			},
			{
				accessor: 'productSalesPriceExcludingVAT',
				Header: 'PV Produit HT  (€)',
				Cell: ({ row }) => (
					<Typography>{formatNumberDisplay(row.original?.productSalesPriceExcludingVAT, 2) ?? ''}</Typography>
				),
			},
			{
				accessor: 'transportPurchasePriceExcludingVAT',
				Header: 'PA Transport HT  (€)',
				Cell: ({ row }) => (
					<Typography>{formatNumberDisplay(row.original?.transportPurchasePriceExcludingVAT, 2) ?? ''}</Typography>
				),
			},
			{
				accessor: 'transportSalesPriceExcludingVAT',
				Header: 'PV Transport HT  (€)',
				Cell: ({ row }) => (
					<Typography>{formatNumberDisplay(row.original?.transportSalesPriceExcludingVAT, 2) ?? ''}</Typography>
				),
			},
			{
				accessor: 'cost',
				Header: 'Prix de vente HT  (€)',
				Cell: ({ row }) => <Typography>{formatNumberDisplay(row.original?.cost, 2) ?? ''}</Typography>,
			},
			{
				accessor: 'status',
				Header: 'Validé par devis',
				Cell: ({ row }) => (
					<Typography>
						{(row.original?.status === ClientPriceStatus.ValidatedByCustomerQuote ? '0ui' : 'Non') ?? ''}
					</Typography>
				),
			},
		],
		[]
	);

	return useTable({
		data: clientPricesExisting ?? [],
		columns,
		border: 'none',
	});
};

export default useExistingClientPricesTable;
