import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export interface LayoutFieldProps {
	[x: string]: string | undefined | ReactNode;
	label?: string;
	children: ReactNode;
	alignLabel?: 'center' | 'right' | 'left';
	color?: string;
}

export const LayoutField = ({
	color = 'inherit',
	label,
	children,
	alignLabel = 'left',
	...rest
}: LayoutFieldProps): JSX.Element => {
	return (
		<Box display="flex" flexDirection="column" {...rest}>
			<Box mb={1} textAlign={alignLabel}>
				<Typography variant="h4" style={{ color }}>
					{label}
				</Typography>
			</Box>
			{children}
		</Box>
	);
};
