import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import FormikTextField from '../../../../components/form/FormikTextField';
import { LayoutField } from '../../../../components/form/LayoutField';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { DiscountInput } from '../../../../graphql/types';
import { useDiscountTable } from '../../../hooks/useStructureDiscountTable';

interface DiscountFieldProps {
	discounts: DiscountInput[] | undefined | null;
	isRoleAdmin: boolean;
}

const DiscountField = ({ discounts, isRoleAdmin }: DiscountFieldProps): JSX.Element => {
	const tableInstance = useDiscountTable(discounts ? discounts : []);
	return (
		<Box mt={-3}>
			<DashedHeader>Remise de fin d’année</DashedHeader>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" spacing={8}>
					<Grid item xs={6}>
						<LayoutField label="% RFA en fonction du CA">
							<Box width="305px" display="flex">
								<AppListTable tableInstance={tableInstance} />
							</Box>
						</LayoutField>
					</Grid>

					<Grid item xs={6}>
						<LayoutField label="Taux de RFA par défaut">
							<Box width="110px" display="flex">
								<FormikTextField
									name="rfa"
									variant="outlined"
									type="number"
									style={{ width: '139px' }}
									value={discounts ? discounts[0]?.rfa : null}
									disabled={!isRoleAdmin}
								/>

								<Box mx={1} mt={0.5}>
									<Typography variant="h4"> % </Typography>
								</Box>
							</Box>
						</LayoutField>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DiscountField;
