import { gql } from '@apollo/client';
import MARGIN_FRAGMENT from '../../graphql/fragments/margins/marginsFragment';

export const CLIENT_PRICE_UPSERT = gql`
	mutation UpsertClientPrice($clientPrice: ClientPriceInput!) {
		upsertClientPrice(clientPrice: $clientPrice) {
			defaultCommercialShippingMargin
			defaultCommercialProductMargin
			validatedQuoteDate
			cost
			isWithRfaClient
			clientId
			franco
			isPrepress
			mainRfaRate
			nbShippingPoints
			productId
			productName
			quantity
			status
			updatedAt
			numberPlateChanges
			numberFileProcessing
			numberVersion
			numberShots
			tools
			buyingPriceWithAmalgam
			deliveryPackagePurchasePriceInfo
			additionalMargins {
				prixCalage
				prixHorsCalage
				prixExemplaireSupp
				prixChangementPlaque
				prixTraitementFichier
				autrePrixFixe
				prixCliche
				prixOutillage
			}
			margins {
				...Margins
			}
			purchasePriceExcludingTax {
				...Margins
			}

			sellingPriceWithMargin {
				...Margins
			}
			productTotalWeight
			deliveryParcel
			transportMoment
			maxBoxWeight
			bestDeliveryParcelPurchasePrice
			transportPurchasePriceHt
			transportSellingPriceHtAdditionalMargin
			transportRfa
			transportSellingPriceHt
			transportSellingPriceMargin
			transportSellingPriceByDefaultHt
			transportPurchasePriceHtWithRfa
			corsicaDeliveryExtra
			isMechanizable
			transportType
			isFinalDistribution
			isIncluded
			productSalesPriceExcludingVATRectified
			totalProductSalesPriceExcludingVAT
			sellingPriceMarginRate
			rfaClientEuro
			productPurchasePriceExcludingVATWithRFA
			transportSellingPriceHtWithClientRfa
			sellingPriceMargin
			transportPurchasePriceExcludingVAT
			transportSellingPriceHtWithClientRfaRectified
			isIdentical
			quantities
		}
	}
	${MARGIN_FRAGMENT}
`;
