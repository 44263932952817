import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import { Form } from 'formik';
import colors from 'app/theme/colors.scss';
import { HeadLines, useManualTabs } from 'hooks/useManualTabs';
import { ClariPrintCorrespondence } from 'graphql/types';
import { TAB_MENU_CLIENT_PRICE } from '../definitions';
import { FilterClientPrice, IsShowSellingPriceSelectedProps, OnSelectStatusSellingPriceProps } from './definitions';
import ClientPriceSupportCharacteristics, {
	ClientPriceSupportCharacteristicsProps,
} from '../form/ClientPriceSupportCharacteristics/ClientPriceSupportCharacteristics';
import { MainInformation } from './main-information/MainInformation';
import EcotaxeInformation from '../form/EcotaxeInformation/EcotaxeInformation';
import { Transport } from './transport/Transport';
import { ProductSellingPrice } from './product-selling-price/ProductSellingPrice';
import { ClientPriceDistribution } from './distribution/ClientPriceDistribution';
import { useFiltredSupplierPrices } from '../../supplier/hooks/useFiltredSupplierPrices';
import { InformationsValideProvider } from '../context/informationsValideProvider';
import { SupplierPriceFormModel } from 'supplier/definitions';
import { ProductDetailsByFamily, ProductDetailsByFamilyType } from 'product/components/ProductDetailsByFamily';
import { Transporter } from './helpers/getTransportDeliveryParcelPurchasePrice';
import { ClientPriceQuery } from '../graphql/queries.definitions';
import { Link } from 'react-router-dom';

export type ClientPriceModel = ClientPriceQuery['clientPrice'] & {
	supplierPrices: SupplierPriceFormModel[] | null;
	clientPricesExisting?: FilterClientPrice[];
} & { transportersPrices: Transporter[]; isDistribution: boolean };

interface ClientPriceFormProps extends IsShowSellingPriceSelectedProps, OnSelectStatusSellingPriceProps {
	paths: string[];
	headlines: HeadLines[];
	support?: SupportFromModel;
	isConsultSellingPrice: boolean;
	isPLV: boolean;
	product?: ProductDetailsByFamilyType | null;
	supportId?: string;
	supportMetaData?: ClariPrintCorrespondence;
	isConsultAndEditSellingPrice: boolean;
	transporterId?: string | null;
}

type ClientPriceFormType = ClientPriceFormProps & Omit<ClientPriceSupportCharacteristicsProps, 'onGetSupplierPrices'>;

export const ClientPriceForm = ({
	paths,
	headlines,
	attributes,
	printAttributes,
	products,
	isClariPrint,
	isPLV,
	supportId,
	supportMetaData,
	product,
	isConsultAndEditSellingPrice,
	isConsultSellingPrice,
	onSelectStatusSellingPrice,
	transporterId,
}: ClientPriceFormType): JSX.Element => {
	const Buttons = () => (
		<Box>
			<Link to="/sellingPrice" style={{ textDecoration: 'none' }}>
				<Button color="primary" type="reset">
					Annuler
				</Button>
			</Link>

			<Button type="submit" variant="contained" color="primary">
				Enregistrer les modifications
			</Button>
		</Box>
	);

	console.log(paths);

	const { renderingTabs } = useManualTabs({
		tabs: TAB_MENU_CLIENT_PRICE,
		paths: [{ name: paths[0], href: '/sellingPrice' }, paths[1]],
		headlines,
		fixedHeaderHeight: 258,
		ButtonAction: Buttons,
	});

	const { getSupplierPrices, loading: supplierPricesLoading } = useFiltredSupplierPrices();

	return (
		<Box my={3}>
			<Form>
				<Box position="fixed" width="100%" top={30} left={0} px={10} bgcolor={colors.white} zIndex={100}>
					{renderingTabs()}
				</Box>

				<Box mt={30}>
					<div ref={TAB_MENU_CLIENT_PRICE[0].ref}>
						{isConsultSellingPrice ? (
							<ProductDetailsByFamily product={product} />
						) : (
							<ClientPriceSupportCharacteristics
								attributes={attributes}
								printAttributes={printAttributes}
								products={products}
								product={product}
								supportId={supportId}
								isClariPrint={isClariPrint}
								onGetSupplierPrices={getSupplierPrices}
							/>
						)}
					</div>
				</Box>
				<Box mt={7}>
					<div ref={TAB_MENU_CLIENT_PRICE[1].ref}>
						<Divider />
						<MainInformation
							onSelectStatusSellingPrice={onSelectStatusSellingPrice}
							isClariPrint={isClariPrint}
							isConsultSellingPrice={isConsultSellingPrice}
							isConsultAndEditSellingPrice={isConsultAndEditSellingPrice}
						/>
					</div>
				</Box>
				<InformationsValideProvider
					isPLV={isPLV}
					supportId={supportId ?? ''}
					supportMetaData={supportMetaData}
					productId={product?.id}
					transporterId={transporterId}
					onGetSupplierPrices={getSupplierPrices}
					isClariPrint={isClariPrint}
					isConsultSellingPrice={isConsultSellingPrice}
					isConsultAndEditSellingPrice={isConsultAndEditSellingPrice}
				>
					<>
						<Box mt={7}>
							<div ref={TAB_MENU_CLIENT_PRICE[2].ref}>
								<Divider />
								<ProductSellingPrice
									onGetSupplierPrices={getSupplierPrices}
									supplierPricesLoading={supplierPricesLoading}
									isClariPrint={isClariPrint}
								/>
							</div>
						</Box>
						<Box mt={7}>
							<div ref={TAB_MENU_CLIENT_PRICE[3].ref}>
								<Divider />
								<ClientPriceDistribution isConsultSellingPrice={isConsultSellingPrice} />
							</div>
						</Box>
						<Box mt={7}>
							<div ref={TAB_MENU_CLIENT_PRICE[4].ref}>
								<Divider />
								<Transport
									isPLV={isPLV}
									isClariPrint={isClariPrint}
									isConsultAndEditSellingPrice={isConsultAndEditSellingPrice}
									isConsultSellingPrice={isConsultSellingPrice}
								/>
							</div>
						</Box>
					</>
				</InformationsValideProvider>
				<Box mt={7}>
					<div ref={TAB_MENU_CLIENT_PRICE[5].ref}>
						<Divider />
						<EcotaxeInformation isConsultSellingPrice={isConsultSellingPrice} supportId={supportId} />
					</div>
				</Box>
			</Form>
		</Box>
	);
};
