import * as React from 'react';
import Grid from '@mui/material/Grid';
import Cell, { Coordinates, Borders, Range } from './Cell/Cell';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MyModal from './Modal/Modal';
import './MyGrid.css';
import { numToExcelColumn } from '../../Quatref/Formatting/Formatting';
import HeaderBtn from './HeaderBtn/HeaderBtn';
import Box from '@mui/material/Box';
import CheckboxCol from './CheckboxCol/CheckboxCol';
import { Data } from '../../Quatref/Displaydata/Displaydata';

type props = {
	// openDropDown?: Function;
	empty?: Boolean;
	data?: Data;
	setData: React.Dispatch<React.SetStateAction<Data>>;
	onDragStart?: Function;
	onDrop?: Function;
	// onDragEnd?: Function;
	// onDragOver?: Function;
	// destinationData?: any[][];
	setMaskActiveCells?: Function;
	maskActiveCells?: any;
	cancelDroppedZone?: Function;
	cancelMask?: Function;
	editCell?: boolean;
	setSourceGrid?: Function;
	// setLoading?: Function;
	copycell?: Boolean;
	eventhandler?: Function;
	disabled?: Boolean;
	isColor?: Boolean;
	addCol?: boolean;
	excludeColDC?: Array<number>;
	setExcludeColDC?: Function;
};

const maskColors = [
	'#E67E30',
	'#82C46C',
	'#884DA7',
	'#FFCB60',
	'#568203',
	'#007FFF',
	'#8B6C42',
	'#F0E36B',
	'#E8D630',
	'#048B9A',
	'#8EA2C6',
	'#77B5FE',
	'#E2BC74',
	'#DF6D14',
	'#DC143C',
	'#B36700',
	'#BF3030',
	'#FD3F92',
	'#BBD2E1',
	'#649B88',
	'#C9A0DC',
	'#E9383F',
	'#FF6F7D',
	'#791CF8',
	'#E7F00D',
	'#9683EC',
	'#AC1E44',
	'#B666D2',
	'#FEA347',
	'#B3B191',
	'#16B84E',
	'#C7CF00',
	'#FC5D5D',
	'#955628',
	'#DFAF2C',
	'#DD985C',
	'#708D23',
	'#FFD700',
	'#CC5500',
	'#DA70D6',
	'#FEE347',
	'#D58490',
	'#CCCCFF',
	'#BEF574',
	'#B67823',
	'#4CA66B',
	'#811453',
	'#997A8D',
	'#F7230C',
	'#FF4901',
	'#CD5C5C',
	'#850606',
	'#E0115F',
	'#008080',
	'#F88E55',
	'#AE8964',
	'#149414',
	'#DE2916',
	'#FAEA73',
	'#BBACAC',
	'#E1CE9A',
	'#B0F2B6',
	'#85C17E',
	'#A5D152',
	'#5A6521',
	'#95A595',
	'#723E64',
	'#40826D',
	'#6C0277',
];

function MyGrid(props: props) {
	const [grid, setGrid] = React.useState<any[][]>([]);
	const [selectedTab, setSelectedTab] = React.useState<string>('');
	const [selectedRange, setSelectedRange] = React.useState<Range>();
	const [selectedMask, setSelectedMask] = React.useState<Range>();
	const [availableColors, setAvailableColors] = React.useState<string[]>(maskColors);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openModal, setOpenModal] = React.useState(false);
	const [zoneToCancel, setZoneToCancel] = React.useState<Coordinates>();

	const onDragStart = (value: string, coordinates: Coordinates, bgColor: string) => {
		if (props.onDragStart) props.onDragStart(bgColor);
	};

	const onDrop = (coordinates: Coordinates) => {
		if (props.onDrop) {
			props.onDrop(coordinates);
			let colorsArray = availableColors;
			if (colorsArray) {
				colorsArray.shift();
			}
			setAvailableColors(colorsArray);

			if (maskColors.length === 1) {
				setAvailableColors(maskColors);
			}
		}
	};

	React.useEffect(() => {
		if (props.setSourceGrid && props.data && props.data.grids) {
			props.setSourceGrid(props.data.grids[selectedTab ? selectedTab : Object.keys(props.data.grids)[0]]);
		}
	}, [selectedTab, props.data, props]);

	React.useEffect(() => {
		if (props.data && props.data.grids) {
			if (selectedTab) {
				setGrid(props.data.grids[selectedTab]);
			} else {
				setGrid(props.data.grids[Object.keys(props.data.grids)[0]]);
			}
		}
	}, [selectedTab, props]);

	const onKeyPress = (event: any) => {
		if (props.eventhandler) {
			props.eventhandler(event, selectedRange);
		}
	};

	const renderTableData = () => {
		if (props.data && props.data.grids && Object.keys(props.data.grids).length !== 0) {
			return props.data.grids[selectedTab ? selectedTab : Object.keys(props.data.grids)[0]].map((row, rowIndex) => {
				return (
					<tr key={rowIndex}>
						<th scope="row" className="header">
							{rowIndex + 1}
						</th>
						{row.map((cell: any, colIndex: number) => {
							let coordinates: Coordinates;
							if (!cell.oldRow && !cell.oldCol) {
								cell.oldRow = rowIndex;
								cell.oldCol = colIndex;
							}
							coordinates = { row: cell.oldRow, col: cell.oldCol };
							let selected = false;

							if (selectedRange) {
								if (checkIfCellInRange(cell.oldRow, cell.oldCol, selectedRange, cell)) {
									selected = true;
								} else {
									selected = false;
								}
							}

							let draggable = false;
							let borders: Borders = { top: false, bottom: false, left: false, right: false };

							if (selectedMask) {
								borders = setBorders(cell.oldRow, cell.oldCol, cell.colSpan, cell.rowSpan, selectedMask);

								if (checkIfCellInMultipleRanges(cell.oldRow, cell.oldCol, props.maskActiveCells, cell)) {
									draggable = true;
								} else {
									cell.bgColor = undefined;
								}
							}

							return (
								<Cell
									keyPress={onKeyPress}
									onDragStart={onDragStart}
									onDrop={onDrop}
									borders={borders}
									onMouseUp={(e: any) =>
										onMouseUp(
											coordinates /* , props.data && props.data.grids ? props.data.grids[selectedTab] : undefined */
										)
									}
									coordinates={coordinates}
									value={cell.value}
									key={colIndex}
									onMouseDown={(e: any) => onMouseDown(coordinates)}
									selected={selected}
									colSpan={cell.colSpan}
									rowSpan={cell.rowSpan}
									onContextMenu={onContextMenu}
									bgColor={props.isColor ? cell.bgColor : undefined}
									draggable={draggable}
									editCell={props.editCell}
									setGrid={setGrid}
									grid={grid}
								/>
							);
						})}
					</tr>
				);
			});
		}
	};

	const setBorders = (
		rowIndex: number,
		colIndex: number,
		colspan: number,
		rowspan: number,
		selectedRange: Range | undefined
	) => {
		let borders: Borders = { top: false, bottom: false, left: false, right: false };
		// select from bottom right to top left
		if (selectedRange) {
			if (
				rowIndex === selectedRange.start.row &&
				colIndex + colspan - 1 >= selectedRange.start.col &&
				colIndex + colspan - 1 <= selectedRange.end.col
			) {
				borders.top = true;
			}
			if (
				colIndex === selectedRange.start.col &&
				rowIndex + rowspan - 1 >= selectedRange.start.row &&
				rowIndex + rowspan - 1 <= selectedRange.end.row
			) {
				borders.left = true;
			}

			if (
				colIndex + colspan - 1 === selectedRange.end.col &&
				rowIndex >= selectedRange.start.row &&
				rowIndex <= selectedRange.end.row
			) {
				borders.right = true;
			}
			if (
				rowIndex + rowspan - 1 === selectedRange.end.row &&
				colIndex + colspan - 1 >= selectedRange.start.col &&
				colIndex + colspan - 1 <= selectedRange.end.col
			) {
				borders.bottom = true;
			}
		}

		return borders;
	};

	const checkIfCellInRange = (RowIndex: number, colIndex: number, selectedRange: Range, cell: any[]) => {
		//select from top left to bottom right  || from bottom right to top left
		if (
			(RowIndex >= selectedRange.start.row &&
				RowIndex <= selectedRange.end.row &&
				colIndex >= selectedRange.start.col &&
				colIndex <= selectedRange.end.col) ||
			(RowIndex <= selectedRange.start.row &&
				RowIndex >= selectedRange.end.row &&
				colIndex <= selectedRange.start.col &&
				colIndex >= selectedRange.end.col)
		) {
			return true;
		} else {
			return false;
		}
	};

	const checkIfCellInMultipleRanges = (RowIndex: number, colIndex: number, maskActiveCells: Range[], cell: any) => {
		//select from top left to bottom right  || from bottom right to top left
		let response = false;
		maskActiveCells.forEach((selectedRange) => {
			if (
				(RowIndex >= selectedRange.start.row &&
					RowIndex <= selectedRange.end.row &&
					colIndex >= selectedRange.start.col &&
					colIndex <= selectedRange.end.col) ||
				(RowIndex <= selectedRange.start.row &&
					RowIndex >= selectedRange.end.row &&
					colIndex <= selectedRange.start.col &&
					colIndex >= selectedRange.end.col)
			) {
				response = true;
				cell.bgColor = selectedRange.color;
			}
		});
		return response;
	};

	const onMouseDown = (coordinates: Coordinates) => {
		if (!props.disabled) {
			setSelectedRange({
				start: {
					col: coordinates.col,
					row: coordinates.row,
				},
				end: {
					col: coordinates.col,
					row: coordinates.row,
				},
			});
		}
	};

	const onMouseUp = (coordinates: Coordinates /* , grid: any[] | undefined */) => {
		if (!props.disabled && props.data && props.data.grids) {
			let endCol = coordinates.col;
			let endRow = coordinates.row;
			let offsetRows = [];
			let offsetCols = [];
			if (selectedRange) {
				for (let row = selectedRange.start.row; row <= coordinates.row; row++) {
					let offsetCol = 0;
					for (let col = selectedRange.start.col; col <= coordinates.col; col++) {
						let find: any = undefined;
						props.data.grids[selectedTab ? selectedTab : Object.keys(props.data.grids)[0]].forEach((rowArray) => {
							if (!find) {
								find = rowArray.find((cell: any) => cell.oldCol === col && cell.oldRow === row);
							}
						});
						offsetCol += find ? find.colSpan : 0;
					}
					offsetCols.push(offsetCol);
				}

				for (let col = selectedRange.start.col; col <= coordinates.col; col++) {
					let rowsOffset = 0;
					for (let row = selectedRange.start.row; row <= coordinates.row; row++) {
						let find: any = undefined;
						props.data.grids[selectedTab ? selectedTab : Object.keys(props.data.grids)[0]].forEach((rowArray) => {
							if (!find) {
								find = rowArray.find((cell: any) => cell.oldCol === col && cell.oldRow === row);
							}
						});
						rowsOffset += find ? find.rowSpan : 0;
					}
					offsetRows.push(rowsOffset);
				}

				if (endCol - selectedRange.start.col < Math.max(...offsetCols)) {
					endCol = selectedRange.start.col + Math.max(...offsetCols) - 1;
				}

				if (endRow - selectedRange.start.row < Math.max(...offsetRows)) {
					endRow = selectedRange.start.row + Math.max(...offsetRows) - 1;
				}
			}
			if (selectedMask) {
				let range = {
					start: {
						col: selectedRange ? selectedRange.start.col : 0,
						row: selectedRange ? selectedRange.start.row : 0,
					},
					end: {
						col: endCol,
						row: endRow,
					},
					color: availableColors[0],
					isCopied: false,
				};
				if (
					!props.maskActiveCells[props.maskActiveCells.length - 1] ||
					props.maskActiveCells[props.maskActiveCells.length - 1].isCopied === true
				) {
					if (props.setMaskActiveCells) props.setMaskActiveCells([...props.maskActiveCells, range]);
				} else {
					let activeCells = props.maskActiveCells;
					activeCells[props.maskActiveCells.length - 1] = range;
					if (props.setMaskActiveCells) props.setMaskActiveCells(activeCells);
				}
			}
			setSelectedRange({
				start: {
					col: selectedRange ? selectedRange.start.col : 0,
					row: selectedRange ? selectedRange.start.row : 0,
				},
				end: {
					col: endCol,
					row: endRow,
				},
			});
		}
	};

	const renderHeader = () => {
		let headerAlph: JSX.Element[] = [];
		let rowLength: any[] = [];
		if (props.data && props.data.grids && Object.keys(props.data.grids).length !== 0) {
			props.data.grids[selectedTab ? selectedTab : Object.keys(props.data.grids)[0]].forEach((row: any) => {
				let lineLength = 0;
				row.forEach((cell: any) => {
					lineLength += cell.colSpan ? cell.colSpan : 1;
				});
				rowLength.push(lineLength);
			});
		}
		const maxLength = Math.max(...rowLength);

		for (let i = 1; i <= maxLength; i++) {
			headerAlph.push(
				<th className="header" key={i}>
					<Box display="flex" alignItems="center">
						<Box p={1} width="100%">
							{numToExcelColumn(i)}
						</Box>
						{props.addCol ? (
							<Box pr={0.5}>
								<HeaderBtn grid={grid} setGrid={setGrid} colindex={i - 1} />
							</Box>
						) : null}
						{props.setExcludeColDC && props.excludeColDC ? (
							<Box pr={0.5}>
								<CheckboxCol
									setExcludeColDC={props.setExcludeColDC}
									excludeColDC={props.excludeColDC}
									colindex={i - 1}
								/>
							</Box>
						) : null}
					</Box>
				</th>
			);
		}
		return (
			<tr>
				<th></th>
				{headerAlph}
			</tr>
		);
	};

	const selectTab = (e: any) => {
		if (!selectedMask) {
			setSelectedTab(e.target.dataset.value);
		} else {
			handleOpenModal();
		}
	};

	const onContextMenu = (event: any, coordinates: any) => {
		openMenu(event);
		setZoneToCancel(coordinates);
	};

	const createMask = () => {
		if (!props.disabled) {
			if (
				selectedRange &&
				selectedRange.start.row > selectedRange?.end?.row &&
				selectedRange.start.col > selectedRange.end.col
			) {
				setSelectedMask({
					start: {
						col: selectedRange.end.col,
						row: selectedRange.end.row,
					},
					end: {
						col: selectedRange.start.col,
						row: selectedRange.start.row,
					},
				});
			} else {
				setSelectedMask(selectedRange);
			}
			setSelectedRange(undefined);
			setAnchorEl(null);
		}
	};

	const cancelZone = () => {
		if (props.cancelDroppedZone) {
			props.cancelDroppedZone(zoneToCancel);
			setAnchorEl(null);
		}
	};

	const removeMask = () => {
		setSelectedMask(undefined);
		if (props.setMaskActiveCells && props.cancelMask) {
			props.setMaskActiveCells([]);
			props.cancelMask();
		}
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Grid container className="fullHeight gridContainer">
			<div className="fullHeight">
				<div className="datasheetContainer">
					<table id="grid">
						<thead>{renderHeader()}</thead>
						<tbody>{renderTableData()}</tbody>
					</table>
				</div>
				<div className="sheetTabs">
					{props.data && props.data.grids
						? Object.keys(props.data.grids).map((tab, i: number) => {
								return (
									<div
										data-value={tab}
										key={i}
										className={`${selectedTab === tab ? 'active' : ''} sheetTab ${selectedMask ? 'disabled' : ''}`}
										onClick={selectTab}
									>
										{tab}
									</div>
								);
						  })
						: null}
				</div>
				<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
					{!selectedMask && !props.disabled ? (
						<MenuItem onClick={createMask}>Définir comme zone de travail</MenuItem>
					) : (
						<MenuItem onClick={removeMask}>Annuler la zone de travail</MenuItem>
					)}
					{props.maskActiveCells && props.maskActiveCells.length > 0 ? (
						<MenuItem onClick={cancelZone}>Annuler la zone</MenuItem>
					) : null}
				</Menu>
			</div>
			<MyModal
				title="Attention"
				body="Veuillez annuler la sélection de travail pour pouvoir changer de feuille"
				openModal={openModal}
				handleClose={handleCloseModal}
			/>
		</Grid>
	);
}

export default MyGrid;
