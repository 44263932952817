import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

interface RetrieveElpevInformationButtonProps {
	handleClick?: () => void | Promise<void>;
}

const RetrieveElpevInformationButton = ({ handleClick }: RetrieveElpevInformationButtonProps): JSX.Element => (
	<Box mt={1}>
		<Button color="primary" onClick={handleClick && handleClick}>
			<RefreshIcon height="24px" />
			<Typography variant="h5" color="primary">
				Récupérer les informations de la base Elpev
			</Typography>
		</Button>
	</Box>
);

export default memo(RetrieveElpevInformationButton);
