import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Divider, FormControl, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LayoutField } from 'components/form/LayoutField';
import FormikRadioField from 'components/form/FormikRadioField';
import { FormikSwitchField } from 'components/form/FormikSwitchField';
import { BootstrapInputCustom } from 'components/BootstrapInputCustom';
import { boxShippingModeOptions } from 'clients/form/delivery-information/definitions';
import colors from 'app/theme/colors.scss';
import { getDeliveryParcelPurchasePriceInfo } from '../../helpers';
import usePopin from 'hooks/usePopin';
import DistribitionsDetails from '../../distribution/components/DistribitionsDetails';
import usePrevious from 'hooks/usePrevious';
import { useFormikContext } from 'formik';
import { TRANSPORTMOMENT } from 'clientPrice/form/definitions';
import { InformationsValideContext } from '../../../context/informationsValideProvider';
import useGetBestTransportDeliveryParcelPurchasePrice from 'clientPrice/hooks/useGetBestTransportDeliveryParcelPurchasePrice';
import { IsShowSellingPriceSelectedProps } from '../../definitions';
import { ClientPriceModel } from '../../ClientPriceForm';
import { BoxShippingMode, Transporter, TransportMoment } from '../../../../graphql/types';
import { formatNumberDisplay } from 'utils/prices';

const DeliveryParcel = ({
	isConsultSellingPrice,
	isConsultAndEditSellingPrice,
}: IsShowSellingPriceSelectedProps & {
	isConsultAndEditSellingPrice: boolean;
}): JSX.Element => {
	const context = useContext(InformationsValideContext);
	const { setFieldValue, values } = useFormikContext<ClientPriceModel>();

	const handleChangePricesSource = (transporter: Transporter) => {
		setFieldValue('transporter', transporter);
		context?.setSelectedTransporterId(transporter.id);
		handleChangeTransportAttributes();
	};

	const [openPopin, renderPopin] = usePopin({
		headerTitle: 'Détail répartition',
		maxWidth: 'lg',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const {
		selectedTransporter,
		transportersPrices,
		lowerRoundedWholeDigitTotalWeight,
		remainingWeigh,
		isOneBox,
		maxBoxWeight,
		isDistribution,
		nbShippingPoints,
		isKnownDistributions,
	} = useGetBestTransportDeliveryParcelPurchasePrice(
		context?.isClariPrint,
		context?.selectedTransporterId || '',
		context?.isValidatePrintsByVersion || isConsultAndEditSellingPrice || isConsultSellingPrice
	);

	const handleChangeTransportAttributes = (value?: string, name?: string) => {
		switch (name) {
			case 'deliveryParcel':
				const selectValueBoxShippingMode = BoxShippingMode[value as keyof typeof BoxShippingMode];
				setFieldValue('deliveryParcel', selectValueBoxShippingMode);
				context?.setIsTransportAttributeChange(true);
				break;
			case 'transportMoment':
				const selectValueTransportMoment = TransportMoment[value as keyof typeof TransportMoment];
				setFieldValue('transportMoment', selectValueTransportMoment);
				context?.setIsTransportAttributeChange(true);
				break;
			default:
				context?.setIsTransportAttributeChange(true);
		}
	};

	useEffect(() => {
		if (
			(isConsultAndEditSellingPrice && context?.isTransportAttributeChange) ||
			(!isConsultSellingPrice && context?.isTransportAttributeChange)
		) {
			context?.setIsTransportAttributeChange(false);
			setFieldValue('bestDeliveryParcelPurchasePrice', selectedTransporter?.deliveryParcelPurchasePrice);
		}
	}, [
		isConsultAndEditSellingPrice,
		isConsultSellingPrice,
		context,
		selectedTransporter,
		setFieldValue,
		values.bestDeliveryParcelPurchasePrice,
	]);

	const prevTransportersPrices = usePrevious(transportersPrices);

	useEffect(() => {
		if (JSON.stringify(prevTransportersPrices) !== JSON.stringify(transportersPrices)) {
			if (transportersPrices && transportersPrices.length && !values?.transporter) {
				setFieldValue('transporter.id', transportersPrices[0].id);
				setFieldValue('transporter.name', transportersPrices[0].name);
			}
			setFieldValue('transportersPrices', transportersPrices || []);
		}
	}, [prevTransportersPrices, transportersPrices, setFieldValue, values]);

	useEffect(() => {
		if (
			!isConsultSellingPrice &&
			!isConsultAndEditSellingPrice &&
			lowerRoundedWholeDigitTotalWeight !== null &&
			remainingWeigh !== null
		) {
			const packagePurchasePriceInfo = getDeliveryParcelPurchasePriceInfo(
				isOneBox ?? false,
				maxBoxWeight ?? 0,
				lowerRoundedWholeDigitTotalWeight ?? 0,
				remainingWeigh ?? 0,
				nbShippingPoints ?? 0,
				isDistribution
			);
			setFieldValue('deliveryPackagePurchasePriceInfo', packagePurchasePriceInfo);
		}
	}, [
		setFieldValue,
		isOneBox,
		maxBoxWeight,
		lowerRoundedWholeDigitTotalWeight,
		remainingWeigh,
		nbShippingPoints,
		isDistribution,
		isConsultSellingPrice,
		values.deliveryPackagePurchasePriceInfo,
		isConsultAndEditSellingPrice,
	]);

	return (
		<Box px={1} mt={2} display="flex" style={{ gap: '100px' }}>
			<>
				<Box display="flex">
					<Box mr={7}>
						<LayoutField label="Livraison transport par colis">
							<FormControl fullWidth>
								<FormikRadioField
									testid="delivery-parcel"
									disabled={isConsultSellingPrice}
									name="deliveryParcel"
									options={boxShippingModeOptions}
									onChange={(_, value) => handleChangeTransportAttributes(value, 'deliveryParcel')}
								/>
							</FormControl>
						</LayoutField>
					</Box>
					<Box>
						<LayoutField label="Moment">
							<FormControl fullWidth>
								<FormikRadioField
									testid="transport-moment"
									onChange={(_, value) => handleChangeTransportAttributes(value, 'transportMoment')}
									disabled={isConsultSellingPrice}
									name="transportMoment"
									options={TRANSPORTMOMENT}
								/>
							</FormControl>
						</LayoutField>
						<Box display="flex">
							<Typography variant="h4" style={{ marginRight: '10px' }}>
								Mécanisable
							</Typography>
							{selectedTransporter && (
								<Tooltip
									title={`Longueur maxi ${selectedTransporter?.maxMechanisableParcelLengthCm} cm - Largeur maxi ${selectedTransporter?.maxMechanisableParcelWidthCm} cm`}
									placement="top"
								>
									<InfoOutlinedIcon color="disabled" />
								</Tooltip>
							)}
						</Box>
						<FormikSwitchField
							testid="is-mechanizable"
							disabled={true}
							name="isMechanizable"
							value={selectedTransporter?.isMechanizable || false}
						/>
					</Box>
				</Box>
				<Divider orientation="vertical" flexItem />
				<Box>
					<LayoutField label="Prix achat transport colis HT (€)">
						<Box display="flex">
							<TextField
								name="bestDeliveryParcelPurchasePrice"
								sx={{ width: '164px', backgroundColor: colors.grey }}
								variant="outlined"
								disabled
								value={formatNumberDisplay(selectedTransporter?.deliveryParcelPurchasePrice, 2)}
							/>
							{isKnownDistributions && (
								<Box pt={0.6}>
									<InfoOutlinedIcon onClick={openPopin} color="disabled" />
								</Box>
							)}
						</Box>
					</LayoutField>
					{!isKnownDistributions && (
						<Typography variant="body1" data-testid="deliveryPackagePurchasePriceInfo">
							{values?.deliveryPackagePurchasePriceInfo}
						</Typography>
					)}
					<LayoutField label="Transporteur" mt={5}>
						<Box width="90%" display="flex">
							<FormControl fullWidth>
								<Select
									data-testid="selected-transporter-name"
									disabled={isConsultSellingPrice}
									name={'selectedTransporterName'}
									input={<BootstrapInputCustom />}
									variant="outlined"
									value={selectedTransporter?.name ?? values?.transporter?.name ?? ''}
									defaultValue={selectedTransporter?.name ?? values?.transporter?.name ?? ''}
								>
									{context?.transporters?.map((transporter) => (
										<MenuItem
											value={transporter.name}
											key={transporter.name}
											onClick={() => handleChangePricesSource(transporter)}
										>
											{transporter.name.toUpperCase()}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</LayoutField>
				</Box>
				{renderPopin(<DistribitionsDetails distributions={selectedTransporter?.distributions} />)}
			</>
		</Box>
	);
};

export default DeliveryParcel;
