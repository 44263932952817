import { CurrentUserClientsQuery } from '../../clients/graphql/queries.definitions';
import { ClientPricesByClientIdQuery } from '../graphql/queries.definitions';
import {
	AttributeKey,
	Client,
	ClientPrice,
	ClientQuoteFileType,
	ClientQuoteItemKey,
	ClientQuoteKey,
	ClientQuoteStatus,
	ClientQuoteTranslate,
	ClientQuoteVat,
	ButtonType,
	ElpevSite,
	QuoteAttributesConfig,
	AttributConditionnement,
	CharacteristicsQuoteAttribute,
	QuoteType,
	BoxShippingMode,
	TransportType,
	TransportMoment,
	MarginsInput,
	FileReference,
	MarginType,
} from '../../graphql/types';
import { ClientPricesByProductNameAndClientIdQuery } from '../../clientPrice/graphql/queries.definitions';
import { GetProductsQuery } from '../../product/graphql/queries.definitions';
import { CurrentUser } from 'admin/user/definitions';
import { Distribution } from 'utils/distribution';

export enum VatOptions {
	Francaise = '20',
}

export const clientQuoteItemPick = [
	'quantity',
	'productName',
	'productId',
	'numberVersion',
	'productPurchasePriceExcludingVAT',
	'productSalesPriceExcludingVAT',
	'transportPurchasePriceExcludingVAT',
	'transportSalesPriceExcludingVAT',
	'prepressPriceExcludingVat',
	'prepressSalesPriceExcludingVat',
	'cost',
	'comment',
	'vatAmount',
	'salePrice',
	'tools',
	'extraUnitsCost',
	'estimatedCiteoTax',
	'product',
	'sellingPriceMargin',
	'deliveryParcel',
	'transportType',
	'transportMoment',
	'sellingPriceIncludingTax',
	'numberPlateChanges',
	'numberFileProcessing',
	'numberShots',
	'productTotalWeight',
];

export const attributClientPriceExisting = [
	'productTotalWeight',
	'nbShippingPoints',
	'rfaClientEuro',
	'numberShots',
	'transportType',
	'transportSellingPriceHtWithClientRfaRectified',
	'transportMoment',
	'transportSellingPriceHtAdditionalMargin',
	'transportSalesPriceExcludingVAT',
	'transportPurchasePriceHt',
	'transportPurchasePriceExcludingVAT',
	'transportSellingPriceMargin',
	'transportSellingPriceHtWithClientRfa',
	'transportPurchasePriceHtWithRfa',
	'transportRfa',
	'transportSellingPriceHt',
	'transportSellingPriceByDefaultHt',
];

export const initialClientQuote = {
	id: null,
	version: 1,
	versionNumber: 1,
	status: ClientQuoteStatus.InProgress,
	clientId: null,
	clientName: null,
	createdAt: new Date().toLocaleString(),
	fileDeliveryDate: null,
	deliveryDate: null,
	contact: null,
	quoteRequestedBy: null,
	operationName: null,
	client: null,
	clientReference: null,
	vat: ClientQuoteVat.Francaise,
	vatRate: 20,
	quantity: 0,
	products: null,
	selectedProduct: null,
	clientPricesExisting: null,
	clientQuoteItemIdsToDelete: [],
	summaryPrice: {
		quantity: 0,
		productName: '',
		productId: '',
		numberVersion: 1,
		productPurchasePriceExcludingVAT: 0,
		productSalesPriceExcludingVAT: 0,
		transportPurchasePriceExcludingVAT: 0,
		transportSalesPriceExcludingVAT: 0,
		prepressPriceExcludingVat: 0,
		prepressSalesPriceExcludingVat: 0,
		transportPurchasePriceHt: 0,
		transportMoment: TransportMoment.InWeek,
		transportSellingPriceHtAdditionalMargin: MarginType.Percent,
		transportType: TransportType.WithoutTransport,
		transportRfa: 0,
		transportSellingPriceMargin: 0,
		transportSellingPriceByDefaultHt: 0,
		transportPurchasePriceHtWithRfa: 0,
		transportSellingPriceHtWithClientRfa: 0,
		transportSellingPriceHtWithClientRfaRectified: 0,
		cost: 0,
		comment: null,
		vatAmount: 0,
		salePrice: 0,
		estimatedCiteoTax: 0,
		tools: false,
		extraUnitsCost: 0,
		sellingPriceMargin: 0.0,
		sellingPriceMarginRate: 0.0,
		type: null,
		deliveryParcel: BoxShippingMode.BeforeThirteen,
		nbShippingPoints: 1,
		productTotalWeight: 0,
		numberFileProcessing: 0,
		franco: false,
		numberPlateChanges: 0,
		rfaClientEuro: 0,
		sellingPriceIncludingTax: 0,
		sellingPriceWithMargin: {
			prixCalage: 0,
			prixHorsCalage: 0,
			prixExemplaireSupp: 0,
			prixChangementPlaque: 0,
			prixTraitementFichier: 0,
			autrePrixFixe: 0,
			prixCliche: 0,
			prixOutillage: 0,
		},
		numberShots: 0,
		corsicaDeliveryExtra: 0,
		isMechanizable: false,
		isFinalDistribution: true,
	},
	billingAddress: {
		city: null,
		country: null,
		firstAddress: null,
		name: null,
		secondAddress: null,
		thirdAddress: null,
		zipCode: null,
	},
	clientQuoteItem: [],
	totalPurchasePriceExcludingVAT: 0,
	totalSalesPriceExcludingVAT: 0,
	vatAmount: 0,
	totalSalesPriceVAT: 100,
	margin: 0,
	marginRate: 0,
	estimatedCiteoTax: 0,
	type: QuoteType.ClientOrderToBeCreate,
	nbShippingPoints: 1,
	isDistribution: false,
	distributionsGroupedByAddress: [],
	clientQuoteDistributions: [],
};

export interface BillingAddressType {
	city?: string | null;
	country?: string | null;
	firstAddress?: string | null;
	name?: string | null;
	secondAddress?: string | null;
	thirdAddress?: string | null;
	zipCode?: string | null;
}

export type ProductModel = GetProductsQuery['products'][0];

export interface SummaryPriceType {
	quantity: number;
	productName: string;
	productId: string;
	numberVersion: number;
	productPurchasePriceExcludingVAT?: number | null;
	productSalesPriceExcludingVAT?: number | null;
	transportPurchasePriceExcludingVAT?: number | null;
	transportSalesPriceExcludingVAT?: number | null;
	prepressPriceExcludingVat?: number | null;
	prepressSalesPriceExcludingVat?: number | null;
	cost?: number | null;
	vatAmount?: number | null;
	salePrice?: number | null;
	estimatedCiteoTax?: number | null;
	comment?: string | null;
	product?: ProductModel;
	tools: boolean;
	numberFileProcessing: number;
	franco: boolean;
	numberPlateChanges: number;
	extraUnitsCost?: number | null;
	prixCalageHT?: number | null;
	prixExSupHT?: number | null;
	nbDeCliche?: number | null;
	sellingPriceMargin: number;
	deliveryParcel: BoxShippingMode;
	transportType: TransportType;
	transportMoment: TransportMoment;
	sellingPriceWithMargin: MarginsInput;
	totalProductSalesPriceExcludingVAT?: number | null;
	nbShippingPoints: number;
	productTotalWeight: number;
	rfaClientEuro: number;
	sellingPriceIncludingTax: number;
	numberShots: number;
	transportPurchasePriceHt?: number | null;
	transportSellingPriceHtAdditionalMargin: MarginType;
	transportRfa?: number | null;
	transportSellingPriceMargin?: number | null;
	transportSellingPriceByDefaultHt?: number | null;
	transportPurchasePriceHtWithRfa?: number | null;
	transportSellingPriceHtWithClientRfa?: number | null;
	transportSellingPriceHtWithClientRfaRectified?: number | null;
	corsicaDeliveryExtra: number;
	isMechanizable: boolean;
	isFinalDistribution: boolean;
}

export interface ClientDataSelected {
	elpevSite?: ElpevSite;
	translation?: ClientQuoteTranslate;
}

export enum ClientQuoteTranslateProp {
	French = 'Français',
}

export enum ElpevSiteProp {
	Empty = '',
	Lille = 'Lille',
	Paris = 'Paris',
	Wittelsheim = 'Wittelsheim',
}

export const ELPEV_SITE = [ElpevSite.Empty, ElpevSite.Lille, ElpevSite.Paris, ElpevSite.Wittelsheim];
export const CLIENT_QUOTE_LANGUAGE = [ClientQuoteTranslate.French];

export interface ValuesType {
	id: string | null;
	version: number;
	versionNumber: number;
	status: ClientQuoteStatus;
	clientId: string | null;
	clientName: string | null;
	fileDeliveryDate: Date | null;
	deliveryDate: Date | null;
	contact: string | null;
	quoteRequestedBy: string | null;
	operationName: string | null;
	client: string | null;
	clientReference: string | null;
	quantity: number;
	vat: ClientQuoteVat;
	selectedProduct: Pick<ClientPrice, 'productName' | 'productId' | 'product'> | null;
	clientPricesExisting: ClientPricesByProductNameAndClientIdQuery['clientPricesExisting'] | null;
	summaryPrice: SummaryPriceType;
	clientQuoteItem: SummaryPriceType[] | null;
	vatRate: number;
	totalPurchasePriceExcludingVAT: number;
	totalSalesPriceExcludingVAT: number;
	vatAmount: number;
	totalSalesPriceVAT: number;
	margin: number;
	marginRate: number;
	estimatedCiteoTax: number;
	billingAddress?: BillingAddressType | null;
	productPurchasePriceExcludingVAT?: number | null;
	productSalesPriceExcludingVAT?: number | null;
	transportPurchasePriceExcludingVAT?: number | null;
	transportSalesPriceExcludingVAT?: number | null;
	cost?: number | null;
	createdAt?: string;
	updatedAt?: string;
	clients?: Client[];
	clientQuoteCreatedAt?: Date;
	type: QuoteType;
	fileReferenceId?: string | null;
	comment?: string | null;
	clientQuoteItemIdsToDelete?: string[];
	nbShippingPoints: number;
	isDistribution: boolean;
	clientQuoteDistributions: Distribution[] | null;
}

export type FileReferencePick = Pick<FileReference, 'askBy' | 'clientReference'>;
export type CurrentUserClientsFromModel = CurrentUserClientsQuery['clientsUsers'][0];
export type ProductListModel = ClientPricesByClientIdQuery['products'];
export type ClientQuoteModel = ValuesType & {
	products: ProductListModel | null;
	fileReferenceSelected?: FileReferencePick;
};

export interface QuotePreviewProps {
	handleCloseQuotePreview: (arg: boolean) => void;
}

export interface clientsUsersProps {
	clientsUsers?: CurrentUserClientsFromModel[];
	clientId?: string;
	context?: QuoteAttributeContextProp;
	loadingClientsUsers?: boolean;
}

type BillingAddressModel = Omit<Client['billingAddress'], 'id' | 'isShipping'>;

interface InfoModel {
	[x: string]: string | null | undefined;
	clientReference?: string | null;
	operationName?: string | null;
	fileDeliveryDate?: string | null;
	deliveryDate?: string | null;
	quoteRequestedBy?: string | null;
	elpevUser?: string | null;
	phoneNumber?: string | null;
	label: string;
}

export interface AccountModel {
	name: string;
	value: number;
}

export interface QuotePreviewDataProp {
	comment: string;
	numberQuote: string;
	type: QuoteType;
	clientInvoice?: Client | null;
	billingAddress?: BillingAddressModel | null;
	quoteRequestedBy: string | null;
	version: number;
	createdAt?: Date | null;
	operations: { [P in keyof InfoModel]: InfoModel[P] }[];
	infosElpev: { [P in keyof InfoModel]: InfoModel[P] }[];
	clientQuoteItem: SummaryPriceType[] | null;
	account: AccountModel[];
	quoteAttributes: QuoteAttributesConfig[];
	sousReserveDeRepartitionDefinitve: string;
}

export type OptionsButtonType = { label: string; value: string; attributeId: string };
export type GroupeByRadioButtonType = (CharacteristicsQuoteAttribute & {
	options?: OptionsButtonType[];
	defaultValue?: string;
})[];

export interface QuoteAttributeContextProp {
	clientDataSelected: ClientDataSelected;
	quoteConfig: QuoteAttributesConfig[];
	currentUser?: CurrentUser;
	clientQuoteData: QuoteAttributesConfig[] | null;
	clientUser?: CurrentUserClientsFromModel;
	clientsUsers?: CurrentUserClientsFromModel[];
	handleResetQuoteAttribute: () => void;
	quotePreviewData?: QuotePreviewDataProp;
	saveTheSettingsForTheClient: () => void;
	generateClientQuote: () => void;
	generateQuoteFile: (type: ClientQuoteFileType) => void;
	selectClientDataQuote: (arg: ClientDataSelected) => void;
	getQuoteDataSelectedClient: (arg: string) => void;
	handleCheckedAttributes: (tableName: string, attribute: string, family: string) => void;
	handleRadioAttributes: (value: string, options: OptionsButtonType[], type: ButtonType) => void;
}

export type CharacteristicsType = {
	value: string | number | boolean | AttributConditionnement;
	key: AttributeKey | null;
	clientQuoteItemKey: ClientQuoteItemKey | null;
	clientQuoteKey: ClientQuoteKey | null;
};

export interface QuotePreviewTable {
	[x: string]: {
		characteristics: CharacteristicsType[];
		family: string;
	};
}
