import React from 'react';
import Box from '@mui/material/Box';
import { DistributionsDetails } from 'clientPrice/form/definitions';
import useDistributionsDetailsTable from '../hooks/useDistributionsDetailsTable';
import AppListTable from 'components/tables/list-table/AppListTable';

interface DistribitionsDetailsProps {
	distributions?: DistributionsDetails[] | null;
}

const DistribitionsDetails = ({ distributions }: DistribitionsDetailsProps): JSX.Element => {
	const tableInstance = useDistributionsDetailsTable(distributions ?? []);

	return (
		<Box p={3}>
			<AppListTable tableInstance={tableInstance} />
		</Box>
	);
};

export default DistribitionsDetails;
