import {ProductFromModel} from "../../product/definitions";
import {ClariPrintCorrespondence} from "../../graphql/types";
import {calculateProductUnitWeightGr} from "./calculateProductUnitWeightGr";

export const calculateProductTotalWeight = (
	quantity: number,
	selectedProduct: Pick<
		ProductFromModel,
		| 'largeurFormatFiniCm'
		| 'longueurFormatFiniCm'
		| 'largeurFormatOuvertCm'
		| 'longueurFormatOuvertCm'
		| 'paginationTotale'
		| 'grammageGr'
		> = {
		largeurFormatFiniCm: '',
		longueurFormatFiniCm: '',
		largeurFormatOuvertCm: '',
		longueurFormatOuvertCm: '',
		grammageGr: '',
		paginationTotale: '',
	},
	unitWeightGr?: number | null,
	supportMetaData?: ClariPrintCorrespondence
) => {
	const productUnitWeight = unitWeightGr || calculateProductUnitWeightGr(selectedProduct, undefined, supportMetaData);
	return (quantity * Number(productUnitWeight)) / 1000;
};
