import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormikTextField from 'components/form/FormikTextField';
import { LayoutField } from 'components/form/LayoutField';
import { UncontrolledDatePicker } from 'components/picker/UncontrolledDatePicker';
import { ErrorMessage, useFormikContext } from 'formik';
import colors from 'app/theme/colors.scss';
import { NewOrderItem, OrderFormModel } from '../definition';

interface DateAddCommentProps {
	dateLabel: string;
	dateName: string;
	comentLabel: string;
	commentName: string;
	value: Date | null;
	isCell?: boolean;
	handleChange: (date: Date | null) => void;
}
const useStyles = makeStyles(() =>
	createStyles({
		textField: {
			backgroundColor: colors.white,
			'& .MuiFormHelperText-root.Mui-error ': {
				background: colors.bodyBackgroundLight,
				margin: 0,
			},
		},
		cell: {
			backgroundColor: colors.white,
			borderLeft: '10px solid',
			borderTopLeftRadius: 6,
			borderBottomLeftRadius: 6,
			borderLeftColor: colors.turquoise500,
			padding: 2,
		},
	})
);

const DateAddCommentProductInformationFiled = ({
	comentLabel,
	commentName,
	dateLabel,
	dateName,
	value,
	isCell,
	handleChange,
}: DateAddCommentProps) => {
	const classes = useStyles();
	const { setFieldValue } = useFormikContext<
		OrderFormModel & {
			newOrderItem: NewOrderItem;
		}
	>();

	return (
		<Box mt={2}>
			<Grid container alignItems="center">
				<Grid item xs={2}>
					<LayoutField label={dateLabel}>
						<Box
							className={isCell ? classes.cell : ''}
							component={(props) => (
								<UncontrolledDatePicker
									className={classes.textField}
									placeholder="jj/mm/aaaa"
									style={{ width: '164px' }}
									margin="none"
									value={value ?? null}
									handleClear={() => setFieldValue(dateName, null)}
									onChange={handleChange}
									shouldDisableDate={(date: Date | undefined) =>
										!!((date && (date as Date).getDay() === 0) || (date && (date as Date).getDay() === 6))
									}
									{...props}
								/>
							)}
						/>
					</LayoutField>

					<ErrorMessage name={dateName}>
						{(msg) => <Typography style={{ color: 'error' }}>{msg}</Typography>}
					</ErrorMessage>
				</Grid>

				<Grid item xs={8}>
					<LayoutField label={comentLabel}>
						<FormikTextField
							className={classes.textField}
							fullWidth
							rows={1}
							name={commentName}
							variant="outlined"
							placeholder="Ajouter un commentaire "
						/>
					</LayoutField>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DateAddCommentProductInformationFiled;
