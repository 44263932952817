import { AttributeType, PrintValueGroupByFamily, PrintValuesType } from '../definitions';
import { PrintValue, PrintValueInput } from '../../../graphql/types';
import { sortFamilyAttributes } from '../constant';

const sortedPrintValues = (printsGroupByFamily: PrintValueGroupByFamily[]) => {
	if (!printsGroupByFamily.length) {
		return sortFamilyAttributes.map((title) => ({
			family: title,
			attributes: [],
		}));
	}

	const capitalizeFirstFamilyLetter = (family: string): string =>
		family.charAt(0).toUpperCase() + family.substring(1).toLowerCase();

	return sortFamilyAttributes.map((family) => {
		const printValueFind = printsGroupByFamily.find((print) => print.family.toLowerCase() === family.toLowerCase());
		if (printValueFind) {
			return {
				family: capitalizeFirstFamilyLetter(printValueFind.family),
				attributes: printValueFind.attributes,
			};
		} else return { family, attributes: [] };
	});
};

export const printValueGroupByFamily = (printValues: PrintValue[]): PrintValueGroupByFamily[] => {
	const printsGroupByFamily = printValues.reduce<PrintValueGroupByFamily[]>((acc, current) => {
		const printIndex = acc.findIndex((print) => print['family'] === current.attribute.family);

		if (printIndex !== -1) {
			acc[printIndex].family = current.attribute.family ?? '';
			acc[printIndex].attributes = [
				...acc[printIndex].attributes,
				{
					id: current.attribute.id,
					name: current.attribute.name,
					values: [{ attributeId: current.attribute.id, printValue: current.printValue, printName: current.printName }],
				},
			];
		} else {
			acc.push({
				family: current.attribute.family ?? '',
				attributes: [
					{
						id: current.attribute.id,
						name: current.attribute.name,
						values: [
							{ attributeId: current.attribute.id, printValue: current.printValue, printName: current.printName },
						],
					},
				],
			});
		}
		return acc;
	}, []);

	printsGroupedByName(printsGroupByFamily);

	return sortedPrintValues(printsGroupByFamily);
};

const printsGroupedByName = (printsGroupByFamily: PrintValueGroupByFamily[]): void => {
	printsGroupByFamily.map((printValues, i) => {
		printValues.attributes = printValues.attributes.reduce<AttributeType[]>((acc, current) => {
			const index = acc.findIndex((print) => print.name === current.name);
			if (index !== -1) {
				acc[index].id = current.id;
				acc[index].name = current.name;
				acc[index].values = [...acc[index].values, ...current.values];
			} else {
				acc.push(current);
			}
			return acc;
		}, []);
		return printValues;
	});
};

export const normalizePrintValues = (prints: PrintValueGroupByFamily[]): PrintValueInput[] => {
	let printValues: PrintValueInput[] = [];
	prints.forEach((print) => {
		print.attributes.forEach((attributes) => {
			printValues = [...printValues, ...attributes.values];
		});
	});

	return printValues;
};

export const removingAPrintValueFromAnAttribute = (
	printsAttributes: PrintValuesType,
	id: string,
	printValue: string
): PrintValueGroupByFamily[] => {
	return printsAttributes.prints.map((print) => {
		print.attributes = print.attributes.map((attributes) => {
			if (attributes.id === id) {
				attributes.values = attributes.values.filter(
					(value) => value.printValue.toLowerCase() !== printValue.toLowerCase()
				);
			}
			return attributes;
		});
		return print;
	});
};

export const removingAnAttributeFromItsNameAndId = (printsAttributes: PrintValuesType, id: string, name: string) => {
	return printsAttributes.prints.map((print) => {
		if (print.family === name) print.attributes = print.attributes.filter((attribute) => attribute.id !== id);
		return print;
	});
};

export const addingAPrintValueToAnAttribute = (printsAttributes: PrintValuesType, attributeId: string) => {
	return printsAttributes.prints.map((print) => {
		print.attributes = print.attributes.map((attributes, i) => {
			if (attributes.id === attributeId) {
				attributes.values = [
					{
						attributeId,
						printValue: printsAttributes.printValues[0].printValue,
						printName: printsAttributes.printValues[0].printName,
					},
					...attributes.values,
				];
			}
			return attributes;
		});
		return print;
	});
};
