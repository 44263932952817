import { useLazyQuery } from '@apollo/client';
import { GET_FILE_REFERENCES } from '../graphql/queries';
import { GetFileReferencesQuery, GetFileReferencesQueryVariables } from '../graphql/queries.definitions';

const useFileReferencesLazyQuery = () => {
	const [getFileReferences, { data, loading }] = useLazyQuery<GetFileReferencesQuery, GetFileReferencesQueryVariables>(
		GET_FILE_REFERENCES
	);

	return {
		fileReferences: data?.fileReferences?.fileReferences,
		loading,
		getFileReferences,
	};
};

export default useFileReferencesLazyQuery;
