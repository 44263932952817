import * as React from 'react';
import { Box } from '@mui/material';
import { Search } from '@mui/icons-material';
import colors from 'app/theme/colors.scss';

interface SearchInputProps {
	id: string;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const styles = (id: string) => ({
	bgcolor: colors.white,
	border: '1px solid',
	borderColor: colors.gray200,
	borderRadius: '20px',
	px: 4,
	py: '5px',
	id,
	width: '100%',
	boxSizing: 'border-box',
});

const SearchInput = ({ id, handleChange }: SearchInputProps) => {
	return (
		<Box position="relative" height="28">
			<Box position="absolute" top="2px" left="5px">
				<Search width="12" height="12" style={{ padding: 2, fill: colors.gray600 }} />
			</Box>
			<Box sx={styles(id)} component="input" onChange={handleChange} />
		</Box>
	);
};

export default React.memo(SearchInput);
