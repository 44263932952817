import { gql } from '@apollo/client';
import { ORDER_ADDRESS_FRAGMENT } from 'order/graphql/queries';

export const UPSERT_INVOICE = gql`
	mutation UpsertInvoice($invoice: InvoiceUpsertInput!) {
		upsertInvoice(invoice: $invoice) {
			id
			orderId
			status
			sendAt
			isExternal
			order {
				id
				parentOrderId
				billingAddress {
					...OrderAddress
				}
			}
		}
	}
	${ORDER_ADDRESS_FRAGMENT}
`;

export const VALIDATE_INVOICE = gql`
	mutation ValidateInvoice($invoice: InvoiceValidationInput!) {
		validateInvoice(invoice: $invoice) {
			id
			orderId
			status
			sendAt
			isExternal
		}
	}
`;

export const UPDATE_ONE_OR_MANY_INVOICES = gql`
	mutation updateOneOrManyInvoices($Ids: InvoicesToUpdateInput!) {
		updateOneOrManyInvoices(invoiceIds: $Ids)
	}
`;
