import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import usePopin from '../../../hooks/usePopin';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import useShippingAddressDelete from '../../hooks/useShippingAddressDelete';
import { useSnackbar } from 'notistack';
import { typeNotifications } from '../../../utils/definition';

interface AdressDialogFormProps {
	clientShippingAddressId?: string;
	clientId: string;
	addressId?: string;
}
const AdressDialogForm = ({ clientShippingAddressId, clientId, addressId }: AdressDialogFormProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { deleteShippingAddressById } = useShippingAddressDelete();
	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Suppression',
		maxWidth: 'xs',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const onSubmit = async () => {
		try {
			if (clientShippingAddressId) await deleteShippingAddressById(clientShippingAddressId, clientId, addressId);
			enqueueSnackbar('suppression effectuée avec succès', { variant: typeNotifications.Success });
			closePopin();
		} catch (error) {
			enqueueSnackbar('Erreur', { variant: typeNotifications.Error });
		}
	};

	return (
		<Box mt={0.75} ml={1}>
			<IconButton onClick={openPopin} size="large">
				<Tooltip title="Supprimer une addresse">
					<DeleteForeverOutlinedIcon />
				</Tooltip>
			</IconButton>
			{renderPopin(
				<>
					<Box display="flex" p={3}>
						<Box mr={2}>
							<DeleteForeverOutlinedIcon />
						</Box>
						<Typography variant="h5">Confirmez-vous la suppression de cette adresse ?</Typography>
					</Box>
					<Divider />
					<Box p={3} display="flex" flexDirection="row-reverse">
						{clientShippingAddressId && (
							<Button variant="contained" color="primary" onClick={onSubmit}>
								Supprimer définitivement
							</Button>
						)}
					</Box>
				</>
			)}
		</Box>
	);
};
export default AdressDialogForm;
