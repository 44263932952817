import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikCheckboxField } from '../../../components/form/FormikChecboxField';
import { SummaryPrice } from '../../../components/summaryPrice/SummaryPrice';

import colors from '../../../app/theme/colors.scss';
import { useField } from 'formik';
import { getTotalClientPrices, getVerticalTotalClientPrices } from '../helpers';
import { IsShowSellingPriceSelectedProps } from '../definitions';
import useFabricationTimes from '../../hooks/useFabricationTimes';

const EcotaxeInformation = ({ isConsultSellingPrice, supportId }: IsShowSellingPriceSelectedProps) => {
	const [{ value: transportSellingPriceHtWithClientRfaRectified }] = useField<number | undefined>(
		'transportSellingPriceHtWithClientRfaRectified'
	);
	const [{ value: transportSellingPriceHtWithClientRfa }] = useField<number>('transportSellingPriceHtWithClientRfa');
	const [{ value: transportPurchasePriceHtWithRfa }] = useField<number>('transportPurchasePriceHtWithRfa');
	const [{ value: cost }] = useField<number>('cost');
	const [{ value: productPurchasePriceExcludingVATWithRFA }] = useField<number>(
		'productPurchasePriceExcludingVATWithRFA'
	);
	const [{ value: sellingPriceMargin }] = useField<number>('sellingPriceMargin');
	const [{ value: mainRfaRate }] = useField<number>('mainRfaRate');
	const [{ value: productSalesPriceExcludingVATWithRfa }] = useField<number>('productSalesPriceExcludingVATWithRfa');
	const [{ value: rfaClientEuro }] = useField<number>('rfaClientEuro');
	const [{ value: productSalesPriceExcludingVATRectified }] = useField<number>(
		'productSalesPriceExcludingVATRectified'
	);
	const [{ value: isWithRfaClient }] = useField<boolean>('isWithRfaClient');
	const [{ value: quantity }] = useField<number>('quantity');

	const { fabricationTimes } = useFabricationTimes({ quantity, supportId: supportId as string });

	return (
		<Box mx={20}>
			<Box my={5} display="flex">
				<Box flexGrow={1}>
					<Typography variant="h2">Informations taxe Citéo</Typography>
				</Box>
				<Box display="flex" alignItems="center">
					<FormikCheckboxField
						testid="support-excluding-ecotax"
						disabled={isConsultSellingPrice}
						name="support.excludingEcotax"
					/>
					<Typography variant="h3">Hors Ecotaxe</Typography>
				</Box>
			</Box>
			<Box width="inherit">
				<SummaryPrice
					title="Récapitulatif d'un prix de vente"
					bgcolor={colors.blue200}
					padding={1}
					horizontalDataCell={getTotalClientPrices(
						transportSellingPriceHtWithClientRfa,
						transportPurchasePriceHtWithRfa,
						transportSellingPriceHtWithClientRfaRectified,
						productSalesPriceExcludingVATRectified,
						productSalesPriceExcludingVATWithRfa
					)}
					verticalDataCell={getVerticalTotalClientPrices(
						productPurchasePriceExcludingVATWithRFA,
						transportPurchasePriceHtWithRfa,
						cost,
						rfaClientEuro,
						sellingPriceMargin,
						mainRfaRate,
						isWithRfaClient
					)}
					fabricationTimes={fabricationTimes}
					hasProductionTimes
				/>
			</Box>
		</Box>
	);
};

export default EcotaxeInformation;
