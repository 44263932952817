import { useField } from 'formik';
import { TRANSPORT_BY_SUPPLIER, WITHOUT_TRANSPORT } from '../../../form/definitions';
import addPercentageToValue from 'utils/addPercentageToValue';
import { MarginType } from 'graphql/types';
import { isTransportBySupplier } from '../../../helpers';
import { SupplierPriceFormModel } from 'supplier/definitions';

const useTotalTransport = (selectTransportType: string, isPLV?: boolean) => {
	const [{ value: supplierPrices }] = useField<SupplierPriceFormModel[] | undefined>('supplierPrices');
	const [{ value: bestDeliveryParcelPurchasePrice }] = useField<number>('bestDeliveryParcelPurchasePrice');
	const [{ value: transportRfa }] = useField<number>('transportRfa');
	const [{ value: defaultCommercialShippingMargin }] = useField<number>('defaultCommercialShippingMargin');
	const [{ value: transportSellingPriceHtAdditionalMargin }] = useField<string>(
		'transportSellingPriceHtAdditionalMargin'
	);
	const [{ value: transportSellingPriceMargin }] = useField<number>('transportSellingPriceMargin');
	const [{ value: isWithRfaClient }] = useField<boolean>('isWithRfaClient');
	const [{ value: mainRfaRate }] = useField<number>('mainRfaRate');

	const isNotDelivreyByParcel =
		selectTransportType === WITHOUT_TRANSPORT ||
		selectTransportType === TRANSPORT_BY_SUPPLIER ||
		(!selectTransportType && isTransportBySupplier(supplierPrices, isPLV));

	const transportPurchasePriceHt =
		(!selectTransportType && isTransportBySupplier(supplierPrices, isPLV)) || isNotDelivreyByParcel
			? 0
			: bestDeliveryParcelPurchasePrice;

	const transportPurchasePriceHtWithRfaValue = isNotDelivreyByParcel ? 0 : transportPurchasePriceHt - transportRfa;

	const transportSellingPriceByDefaultHt = isNotDelivreyByParcel
		? 0
		: addPercentageToValue(defaultCommercialShippingMargin, transportPurchasePriceHtWithRfaValue);

	const transportSellingPriceHt = isNotDelivreyByParcel
		? 0
		: transportSellingPriceHtAdditionalMargin === MarginType.Percent
		? addPercentageToValue(transportSellingPriceMargin, transportSellingPriceByDefaultHt)
		: transportSellingPriceMargin + transportSellingPriceByDefaultHt;

	const transportSellingPriceHtWithClientRfa = isWithRfaClient
		? addPercentageToValue(mainRfaRate, transportSellingPriceHt)
		: transportSellingPriceHt;

	return {
		transportPurchasePriceHt,
		transportPurchasePriceHtWithRfaValue,
		transportSellingPriceByDefaultHt,
		transportSellingPriceHt,
		transportRfa,
		transportSellingPriceHtWithClientRfa,
	};
};

export default useTotalTransport;
