import React from 'react';
import { Box, Typography } from '@mui/material';
import colors from 'app/theme/colors.scss';

interface ChildLabelProps {
	name: string;
	isActif: boolean;
	type: string | undefined | null;
	fileCode?: string | null;
}

const ChildLabel = ({ name, isActif, type, fileCode }: ChildLabelProps): JSX.Element => (
	<Box
		display="inline-block"
		borderRadius="4px"
		border={`2px solid ${colors.gray200}`}
		bgcolor={colors.bodyBackgroundLight}
		boxSizing="border-box"
		width="204.21px"
	>
		<Typography variant="h5" color={isActif ? 'textPrimary' : 'error'}>
			{name}
		</Typography>
		{fileCode && (
			<Typography variant="body2" color={'error'}>
				(Réf : {fileCode})
			</Typography>
		)}
		<Typography variant="h6" color="primary">
			{type}
		</Typography>
	</Box>
);

export default ChildLabel;
