import React, { useMemo } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import { useField } from 'formik';
import { FormControl, FormControlLabel, Radio, Typography } from '@mui/material';

interface RadioProps {
	name: string;
	options?: { label: string; value: string | boolean; disabled?: boolean }[];
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
	isRow?: boolean;
	defaultValue?: string;
	disabled?: boolean;
	value?: string;
	testid?: string;
}

const FormikRadioField = ({ name, options, isRow, disabled = false, testid, ...props }: RadioProps) => {
	const [field, meta] = useField<string>(name);

	options = useMemo(
		() =>
			options?.map((option) => ({
				...option,
				disabled,
			})),
		[disabled, options]
	);

	return (
		<FormControl error={meta.touched && Boolean(meta.error)} required>
			<RadioGroup {...field} name={name} row={isRow} {...props} data-testid={testid}>
				{options?.map((option, index) => (
					<FormControlLabel
						key={index}
						value={option.value}
						control={<Radio color="primary" />}
						disabled={option.disabled}
						label={<Typography variant="h5">{option.label}</Typography>}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
export default FormikRadioField;
