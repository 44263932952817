import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Support } from '../../graphql/types';
import ListSupportsSelect from '../support/ListSupportsSelect';
import { MenuComponentProps } from '../../Header/definitions';
import useSupportsQuery from '../support/hooks/useSupportsQuery';

interface BigShopMenuProps extends MenuComponentProps {}

const BigShopConfigMenu = ({ onClose }: BigShopMenuProps): JSX.Element => {
	const history = useHistory();
	const { supports } = useSupportsQuery();
	const handleSupportSelected = (rowSupport: Support) => {
		history.push(`/BigShopManagement/${rowSupport.id}`);
		onClose?.();
	};

	return (
		<Box>
			{supports && (
				<ListSupportsSelect
					supports={supports}
					path={'/BigShopManagement/'}
					onSupportSelected={handleSupportSelected}
				/>
			)}
		</Box>
	);
};

export default BigShopConfigMenu;
