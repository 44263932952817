import React from 'react';
import { Box, Collapse, Typography, Button } from '@mui/material';
import SuppliersList from './SuppliersList';
import { AttributesFilterInput, PricesSource } from '../graphql/types';
import SellingPricesByClient from './SellingPricesByClient';
import SupportCharacteristics from '../components/SupportCharacteristics/SupportCharacteristics';
import useSupportCharacteristics from '../components/SupportCharacteristics/hooks/useSupportCharacteristics';
import useCollapse from '../hooks/useCollapse';
import { omit } from 'ramda';

interface FiltersProductsProps {
	supportId: string;
	support: SupportFromModel;
}

const FiltersProducts = ({ supportId, support }: FiltersProductsProps): JSX.Element => {
	const { renderCollapseComponent, displayContent } = useCollapse();
	const attributes = support.supportAttributes;
	const printAttributes = support?.printAttributes || [];
	const products = support?.products || [];
	const isClariPrint = support?.pricesSource === PricesSource.ClariPrint;
	const {
		productsAttributesByFamily,
		handleFilterChange,
		filtredProducts,
		setProductFilter,
		setProduct,
		selectedSupportId,
		selectedProduct,
		productFilter,
		conditionnement,
		setConditionnement,
		numberOfLots,
		setNumberOfLots,
	} = useSupportCharacteristics({
		supportId,
		attributes,
		printAttributes,
		products,
		isClariPrint,
	});

	const filter = omit(
		['conditionnement1', 'conditionnement2', 'conditionnement3', 'conditionnement'],
		productFilter ?? {}
	);

	const filters = (Object.keys(productFilter ?? {}) as Array<keyof typeof filter>).map((key) =>
		productFilter ? productFilter[key]?.value : ''
	);

	return (
		<Box pt={3}>
			<Box display={'flex'}>
				<Box display={'flex'} flexGrow={1}>
					<Box pr={2}>
						<Typography variant="h2">Caractéristiques correspondants au support</Typography>
					</Box>
					{renderCollapseComponent()}
				</Box>
				<Button
					onClick={() => {
						setProductFilter(null);
						setProduct(null);
					}}
					color={'primary'}
					variant={'outlined'}
				>
					Réinitialiser
				</Button>
			</Box>
			<Collapse in={displayContent}>
				{attributes && (
					<SupportCharacteristics
						productsAttributesByFamily={productsAttributesByFamily}
						handleFilterChange={handleFilterChange}
						setProductFilter={setProductFilter}
						productFilter={productFilter}
						setProduct={setProduct}
						selectedProduct={selectedProduct}
						selectedSupportId={selectedSupportId}
						filtredProducts={filtredProducts}
						supportId={supportId}
						attributes={attributes}
						printAttributes={printAttributes}
						products={products}
						isClariPrint={isClariPrint}
						isProductPage
						conditionnement={conditionnement}
						setConditionnement={setConditionnement}
						numberOfLots={numberOfLots}
						setNumberOfLots={setNumberOfLots}
					/>
				)}
			</Collapse>

			{products && support && support.supportAttributes && (
				<SuppliersList products={filtredProducts} supportName={support.name} attributes={support.supportAttributes} />
			)}

			{products && (
				<SellingPricesByClient
					supportId={supportId}
					filters={filters as AttributesFilterInput}
					products={filtredProducts}
				/>
			)}
		</Box>
	);
};
export default FiltersProducts;
