import React, { memo } from 'react';
import DashedHeader from 'components/DashedHeader';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import { Box, Grid } from '@mui/material';
import DateLayoutField from 'components/form/DateLayoutField';

interface SupplierLoginInformationProps {
	isUserSupplier?: boolean;
}

const SupplierLoginInformation = ({ isUserSupplier }: SupplierLoginInformationProps): JSX.Element => (
	<Box mb={7}>
		<Grid container justifyContent="space-between">
			<Grid item xs={2} />
			<Grid item xs={8}>
				<DashedHeader>Information de connexion</DashedHeader>
			</Grid>
			<Grid item xs={2} />
		</Grid>
		<Grid container justifyContent="space-between">
			<Grid item xs={2} />
			<Grid item xs={3}>
				<LayoutFieldForm label="Nom d'utilisateur" name="user.email" />
			</Grid>
			<Grid item xs={2} />
			<Grid item xs={3}>
				<Box width="164px">
					<DateLayoutField label="Dernière connexion le" name="lastConnection" disabled />
				</Box>
			</Grid>
			<Grid item xs={2} />
		</Grid>
		<Grid container justifyContent="space-between">
			<Grid item xs={2} />
			<Grid item xs={3}>
				<LayoutFieldForm label="Mot de passe" name="user.password" placeholder={isUserSupplier ? '******' : ''} />
			</Grid>
			<Grid item xs={7} />
		</Grid>
	</Box>
);

export default memo(SupplierLoginInformation);
