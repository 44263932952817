import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import OutlinedButton from 'components/buttons/OutlinedButton';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { LayoutField } from 'components/form/LayoutField';
import { FormikNumberField } from 'components/form/FormikNumberField';
import FormikTextField, { OnChangeUnControlledArgsType } from 'components/form/FormikTextField';
import { StatusSwitchField } from 'components/Switch/StatusSwitchField';
import ClariPrintProduct from './ClariPrintProduct';
import OnSupplyProduct from './OnSupplyProduct';
import DashedHeader from 'components/DashedHeader';
import colors from 'app/theme/colors.scss';
import PricesProductContext from 'context/pricesProductContext/PricesProductContext';
import usePrevious from 'hooks/usePrevious';
import { useField } from 'formik';
import { equals } from 'ramda';
import { useSnackbar } from 'notistack';
import { typeNotifications } from '../../../../utils/definition';

type ProductSellingPriceItemsType = {
	quantityDisabled: boolean;
	isClariPrint?: boolean;
	isIdentical: boolean;
	handleStatusSwitch: (status: boolean) => void;
	handleVersionNumber: (event: OnChangeUnControlledArgsType) => void;
	handleSupplierList: () => void;
	getClientPrices: () => void;
	nbOfMaxPoses?: false | number | null;
	numberVersion: number;
	error?: string;
	quantities: number[];
	disabled: boolean;
};

export const ProductSellingPriceItems = ({
	handleSupplierList,
	quantityDisabled,
	handleVersionNumber,
	isClariPrint,
	handleStatusSwitch,
	nbOfMaxPoses,
	isIdentical,
	numberVersion,
	error,
	quantities,
	getClientPrices,
	disabled,
}: ProductSellingPriceItemsType): JSX.Element | null => {
	const context = useContext(PricesProductContext);
	const { enqueueSnackbar } = useSnackbar();
	const [fieldQuantity, , helpersQuantity] = useField<number | undefined | null>('quantity');
	const [validatePrintByVersionsLoading, setValidatePrintByVersionsLoading] = useState<boolean>(false);
	const prevTotal = usePrevious(context?.productFilter?.conditionnement?.total);

	const handleValidatePrintByVersions = async () => {
		setValidatePrintByVersionsLoading(true);
		try {
			await getClientPrices();
		} catch (e) {
			console.error(e);
			enqueueSnackbar(
				'Le produit sélectionné ne peut pas être réalisé. Merci de revoir la configuration de votre produit.',
				{ variant: typeNotifications.Error }
			);
		} finally {
			setValidatePrintByVersionsLoading(false);
		}
	};

	const printsByVersionsAndSuppliersListDisabled =
		!isIdentical &&
		isClariPrint &&
		context?.productFilter?.conditionnement?.total !== fieldQuantity.value &&
		context?.productFilter?.conditionnement?.numberOfLots !== 1;

	useEffect(() => {
		if (
			!equals(prevTotal, context?.productFilter?.conditionnement?.total) &&
			isClariPrint &&
			context?.productFilter?.conditionnement?.total &&
			context?.productFilter?.conditionnement?.total > 1
		)
			helpersQuantity.setValue(context?.productFilter.conditionnement?.total);
	}, [helpersQuantity, prevTotal, context?.productFilter?.conditionnement, isClariPrint, context?.productFilter]);

	return (
		<>
			<Grid xs={12} item>
				<Grid spacing={1} container>
					<Grid xs={6} item>
						<Typography variant="h2">Produit</Typography>
					</Grid>
					<Grid xs={6} item>
						<Box width="inherit" textAlign="right">
							<OutlinedButton
								disabled={disabled || printsByVersionsAndSuppliersListDisabled}
								Icon={FormatListBulletedOutlinedIcon}
								handleClickBtn={handleSupplierList}
								title="Liste des fournisseurs"
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Grid xs={12} item>
				<Box display="flex" flexDirection="column" justifyContent="space-between" mt={4}>
					<Box display="flex" justifyContent="space-between" mb={4}>
						<Box width="45%">
							<Grid container justifyContent="space-between" spacing={1}>
								<Grid xs={3} item>
									<LayoutField label="Quantité">
										<Box width="100%" display="flex">
											<FormikNumberField
												testid="quantity"
												decimalScale={0}
												disabled={disabled || !!context?.productFilter?.conditionnement?.total}
												width="100%"
												variant="outlined"
												name="quantity"
												bgColor={!context?.productFilter?.conditionnement?.total ? colors.white200 : colors.grey}
											/>
										</Box>
										{error && (
											<Typography color="error" style={{ marginTop: '-10px' }}>
												Vous devez saisir une quantité valide !
											</Typography>
										)}
									</LayoutField>
								</Grid>
								<Grid xs={3} item>
									<LayoutField label="Nombre de version">
										<FormControl fullWidth>
											<FormikTextField
												disabled={disabled}
												name="numberVersion"
												type="number"
												variant="outlined"
												onChangeUnControlled={handleVersionNumber}
												inputProps={{ min: 1 }}
												testid="number-version"
												bgColor={colors.white200}
											/>
										</FormControl>
									</LayoutField>
								</Grid>
								<Grid xs={3} item>
									{isClariPrint && numberVersion > 1 && (
										<LayoutField label="Tirage" ml={2} alignLabel="center">
											<Box pt={1}>
												<StatusSwitchField
													testid="is-identical"
													disabled={disabled}
													handleStatusSwitch={handleStatusSwitch}
													name="isIdentical"
													activeChipLabel="Identique"
													inactiveChipLabel="Différent"
													variantBgColorActif={colors.primary}
													variantBgColorDisabled={colors.blueDisabled}
												/>
											</Box>
										</LayoutField>
									)}
								</Grid>
							</Grid>
							{printsByVersionsAndSuppliersListDisabled && (
								<Typography color="error" variant="h5">
									Attention, le total des quantités indiquées dans ce champ est différent du total indiqué dans le
									conditionnement par lot.
								</Typography>
							)}
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Grid container>
							<Grid xs={12} item>
								{isClariPrint && numberVersion > 1 && (
									<DashedHeader>Tirage par version en nombre d’exemplaire</DashedHeader>
								)}
							</Grid>
							<Grid xs={12} item>
								{isClariPrint ? (
									<ClariPrintProduct disabled={disabled} quantities={quantities} isIdentical={isIdentical} />
								) : (
									<OnSupplyProduct disabled={disabled} nbOfAmxiPoses={nbOfMaxPoses} />
								)}
							</Grid>
						</Grid>
					</Box>
					<Box my={6}>
						<OutlinedButton
							title="Valider les tirages par versions"
							handleClickBtn={handleValidatePrintByVersions}
							disabled={disabled || printsByVersionsAndSuppliersListDisabled || validatePrintByVersionsLoading}
							loading={validatePrintByVersionsLoading}
						/>
					</Box>
				</Box>
			</Grid>
		</>
	);
};
