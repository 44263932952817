import { Box, IconButton, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import usePopin from '../../../../hooks/usePopin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { BoxShippingMode, ClientShippingAddressInsertInput, PalletShippingMode } from '../../../../graphql/types';
import useClientShippingAddressInsert from '../../../hooks/useClientShippingAddressInsert';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import ShippingAddressForm from './components/ShippingAddressForm';
import { typeNotifications } from '../../../../utils/definition';

interface AdressDialogFormProps {
	isRoleAdmin: boolean;
	hasMainAddress: boolean;
}

const AdressDialogForm = ({ isRoleAdmin, hasMainAddress }: AdressDialogFormProps): JSX.Element => {
	const { id } = useParams<{ id?: string }>();
	const { enqueueSnackbar } = useSnackbar();
	const { insertClientShippingAddress } = useClientShippingAddressInsert();
	const initialValues = {
		clientId: id ?? '',
		contact: {
			fax: '',
			email: '',
			phone: '',
			website: '',
			storeCode: '',
			directorName: '',
			isNotificated: false,
		},
		address: {
			name: '',
			firstAddress: '',
			secondAddress: '',
			thirdAddress: '',
			postBox: '',
			zipCode: '',
			city: '',
			country: '',
			isShipping: true,
			deliveryDepartment: '',
		},
		isMain: false,
		boxShippingMode: BoxShippingMode.BeforeNine,
		maxBoxWeight: 15,
		palletShippingMode: PalletShippingMode.Strandard,
		comment: '',
		workingDays: null,
	};

	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Ajouter une addresse de livraison',
		maxWidth: 'xs',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const handleSubmit = async (clientAddress: ClientShippingAddressInsertInput) => {
		if (clientAddress.address.name === '') {
			enqueueSnackbar('Vous devez spécifier un nom commercial', { variant: typeNotifications.Error });
		} else {
			try {
				await insertClientShippingAddress(clientAddress, id ?? '');
				closePopin();
				enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
			} catch (error) {
				enqueueSnackbar('Erreur', { variant: typeNotifications.Error });
			}
		}
	};

	return (
		<Box mt={0.75} ml={1}>
			<>
				<IconButton color="primary" onClick={openPopin} size="large">
					<Tooltip title="Ajouter une nouvelle adresse">
						<AddCircleOutlineIcon />
					</Tooltip>
				</IconButton>
				{renderPopin(
					<Formik<ClientShippingAddressInsertInput> initialValues={initialValues} onSubmit={handleSubmit}>
						<ShippingAddressForm isRoleAdmin={isRoleAdmin} hasMainAddress={hasMainAddress} />
					</Formik>
				)}
			</>
		</Box>
	);
};
export default AdressDialogForm;
