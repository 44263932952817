import { IconButton, TextField, TextFieldProps } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/lab';
import React, { useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useField } from 'formik';

type UncontrolledDatePickerProps = {
	value: Date | null;
	inputVariant?: TextFieldProps['variant'];
	handleClear: ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
	handleUpdateStatus?: () => void;
	format?: string;
	name: string;
};

type UncontrolledDatePickerType = UncontrolledDatePickerProps &
	Omit<DatePickerProps<Date | unknown | null>, 'openPicker' | 'rawValue' | 'renderInput'>;

export const CustomTextField: React.FC<TextFieldProps> = (props) => <TextField {...props} size="small" />;

export const UncontrolledDatePicker = ({
	value,
	handleClear,
	inputVariant = 'outlined',
	handleUpdateStatus,
	name,
	format = 'dd-MM-yyyy',
	...keyboardDatePickerProps
}: UncontrolledDatePickerType): JSX.Element => {
	const [open, setOpen] = useState<boolean>(false);
	const [, , helpersDatePicker] = useField<Date | null | undefined>(name ?? '');

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					renderInput={(props) => <CustomTextField {...props} />}
					inputFormat={format}
					value={value}
					open={open}
					InputProps={{
						endAdornment: (
							<>
								{value ? (
									<IconButton
										disabled={keyboardDatePickerProps.disabled}
										onClick={(event) => {
											if (handleClear) {
												handleClear(event);
											}
											handleUpdateStatus && handleUpdateStatus();
										}}
										size="large"
									>
										<ClearIcon />
									</IconButton>
								) : (
									<IconButton disabled={keyboardDatePickerProps.disabled} onClick={() => setOpen(true)} size="large">
										<EventOutlinedIcon />
									</IconButton>
								)}
							</>
						),
					}}
					InputAdornmentProps={{
						position: 'start',
						style: {
							display: 'none',
						},
					}}
					onClose={() => {
						setOpen(false);
					}}
					onError={(reason) => {
						switch (reason) {
							case 'maxDate':
							case 'minDate':
								helpersDatePicker?.setError('Date non valide');
								break;
							default:
								helpersDatePicker?.setError('');
						}
					}}
					{...keyboardDatePickerProps}
				/>
			</LocalizationProvider>
			{/*{metaDatePicker.touched && <Typography>{metaDatePicker.error}</Typography>}*/}
		</>
	);
};
