import { useQuery } from '@apollo/client';
import { GET_CLIENT_SHIPPING_ADDRESS } from '../graphql/queries';
import { GetClientShippingAddressQuery, GetClientShippingAddressQueryVariables } from '../graphql/queries.definitions';

const useClientShippingAddressQuery = (clientId?: string) => {
	const { data, loading } = useQuery<GetClientShippingAddressQuery, GetClientShippingAddressQueryVariables>(
		GET_CLIENT_SHIPPING_ADDRESS,
		{
			variables: {
				clientId,
			},
		}
	);
	return { clientShippingAddress: data?.clientShippingAddress, loading };
};

export default useClientShippingAddressQuery;
