import { PricesSource, SupportCategory } from '../../../graphql/types';

export const getSupportCategory = (pricesSource: PricesSource) => {
	switch (pricesSource) {
		case PricesSource.OneSupply:
			return SupportCategory.Plv;
		case PricesSource.ClariPrint:
			return SupportCategory.Print;
		case PricesSource.Digital:
			return SupportCategory.Digital;
		case PricesSource.ElpevBase:
			return SupportCategory.Roto;
		default:
			return null;
	}
};
