import { Box, Typography } from '@mui/material';
import PdfCell from 'invoice/form/invoicesList-to-be-accounted/PdfCell';
import React, { useMemo } from 'react';
import { Column, CellProps, useTable, useSortBy, useAsyncDebounce, FilterProps, useFilters } from 'react-table';
import { formatNumberDisplay } from 'utils/prices';
import { InvoiceListType } from 'invoice/definitions';
import SearchInput from 'file-reference/components/SearchInput/SearchInput';
import { TableType } from 'components/tables/list-table/tableType';
import { GetInvoicesQuery } from 'invoice/graphql/queries.definitions';

export const useInvoicesCountedListTable = (
	invoices: GetInvoicesQuery['invoices']['invoices'] | undefined,
	loading: boolean = false,
	isChecked: (id: string) => boolean,
	toggleSelect: (id: string) => void,
	toggleAll: (isChecked: boolean) => void,
	allChecked: () => boolean
) => {
	const invoicesList = useMemo(
		() =>
			invoices
				? invoices?.map((invoice) => ({
						invoiceId: '',
						orderId: invoice?.order.parentOrderId ?? '',
						createdAt: new Date(invoice?.createdAt).toLocaleString('fr-FR', { timeZone: 'UTC' }),
						clientName: invoice.order?.clientName,
						sellingPriceHt: invoice.order?.sellingPriceHt,
						orderTva: invoice.order?.tva,
						sellingPriceTtc: invoice.order?.sellingPriceTtc,
						validator: invoice?.validator,
						validateAt: new Date(invoice.validateAt).toLocaleString('fr-FR', { timeZone: 'UTC' }),
						icons: '',
						url: invoice.url,
						id: invoice.id,
				  }))
				: [],
		[invoices]
	);

	const defaultColumn = React.useMemo(
		() => ({
			Filter: ({ column: { setFilter, filterValue, id } }: FilterProps<InvoiceListType>) => {
				const [, setValue] = React.useState(filterValue);

				const onChange = useAsyncDebounce((value) => {
					setFilter(value || undefined);
				}, 300);

				return (
					<Box>
						<SearchInput
							id={id}
							handleChange={(e) => {
								setValue(e.target.value);
								onChange(e.target.value);
							}}
						/>
					</Box>
				);
			},
		}),
		[]
	);

	const columns = useMemo<Column<InvoiceListType>[]>(
		() => [
			{
				id: 'selection',

				Header: () => (
					<div>
						<input type="checkbox" checked={allChecked()} onChange={(e) => toggleAll(e.target.checked)} />
					</div>
				),

				Cell: ({ row }: CellProps<InvoiceListType>) => (
					<div>
						<input type="checkbox" checked={isChecked(row.values.id)} onChange={() => toggleSelect(row.values.id)} />
					</div>
				),
			},
			{
				accessor: 'id',
				Header: ' N° de facture',
				sortType: 'basic',
				isSearchable: true,
				canFilter: true,
			},

			{
				accessor: 'orderId',
				Header: 'Référence OS',
				defaultCanFilter: false,
				isSearchable: true,
			},
			{
				accessor: 'createdAt',
				Header: 'Date de création',
				disableFilters: true,
				Cell: ({ value }) => <Typography>{value}</Typography>,
			},
			{
				accessor: 'validateAt',
				Header: 'Comptabilisée le',
				disableFilters: true,
				Cell: ({ value }) => <Typography>{value}</Typography>,
			},
			{
				accessor: 'clientName',
				Header: 'Client facturé',
				defaultCanFilter: false,
				isSearchable: true,
			},

			{
				accessor: 'sellingPriceHt',
				Header: 'Montant',
				disableFilters: true,
				disableSortBy: true,
				Cell: ({ row }) => {
					return (
						<>
							<Typography>HT : {formatNumberDisplay(row.original.sellingPriceHt, 2)} €</Typography>
							<Typography>TVA : {formatNumberDisplay(row.original.orderTva, 2)} €</Typography>
							<Typography>TTC : {formatNumberDisplay(row.original.sellingPriceTtc, 2)} €</Typography>
						</>
					);
				},
			},
			{
				accessor: 'validator',
				Header: 'Valideur',
				defaultCanFilter: false,
				isSearchable: true,
			},

			{
				accessor: 'invoiceId',
				disableFilters: true,
				disableSortBy: true,
				Header: () => null,

				Cell: ({ row }) => {
					return <PdfCell row={row} />;
				},
			},
		],
		[allChecked, isChecked, toggleAll, toggleSelect]
	);

	return useTable(
		{
			columns: columns,
			data: invoicesList,
			borderLess: true,
			type: TableType.invoice,
			height: 200,
			scroll: 'scroll',
			manualFilters: true,
			manualSortBy: true,
			defaultColumn,
			loading,
		},
		useFilters,
		useSortBy
	);
};
