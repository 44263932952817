import { useMutation } from '@apollo/client';
import { INSERT_SUPPLIER } from '../graphql/mutations';
import { InsertSupplierMutation, InsertSupplierMutationVariables } from '../graphql/mutations.definitions';
import { GET_SUPPLIERS } from '../graphql/queries';

const useSupplierInsert = () => {
	const [insertSupplierMutation] = useMutation<InsertSupplierMutation, InsertSupplierMutationVariables>(
		INSERT_SUPPLIER
	);
	const insertSupplier = async (name: string) => {
		await insertSupplierMutation({
			variables: { name },
			refetchQueries: [
				{
					query: GET_SUPPLIERS,
				},
			],
			awaitRefetchQueries: true,
		});
	};
	return { insertSupplier };
};

export default useSupplierInsert;
