import { Box, Button, Grid, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useMemo } from 'react';
import DashedHeader from '../../../components/DashedHeader';
import FormikTextField from '../../../components/form/FormikTextField';
import { LayoutField } from '../../../components/form/LayoutField';
import { useField } from 'formik';
import { useStylesClient } from '../../style';
import FormikRadioField from '../../../components/form/FormikRadioField';
import { TerritorialArea } from '../../../graphql/types';

interface ClientDetailedInformationFormProps {
	onClickImportClientFromBaseDB: () => void;
	isRoleAdmin: boolean;
}

const ClientDetailedInformationForm = ({
	onClickImportClientFromBaseDB,
	isRoleAdmin,
}: ClientDetailedInformationFormProps): JSX.Element | null => {
	const classes = useStylesClient();
	const [field] = useField<string | null | undefined>('vatType');
	const [{ value: territorialArea }] = useField<TerritorialArea>('territorialArea');

	const territorialOptions = useMemo(
		() =>
			Object.entries(TerritorialArea).map(([key, value]) => ({
				label: key === TerritorialArea.PeriUrbanAreaRural ? 'Mix(PeriUrban/Rural)' : key,
				value,
			})),
		[]
	);

	return (
		<Box my={3}>
			<Box mb={4}>
				<Box mb={1}>
					<Typography variant="h2">Informations Client</Typography>
				</Box>
				<Button color="primary" className={classes.refreshBtn} onClick={onClickImportClientFromBaseDB}>
					<RefreshIcon height="24px" />
					<Typography variant="h5" color="primary" style={{ fontWeight: 500 }}>
						Récupérer les informations de la base Elpev
					</Typography>
				</Button>
			</Box>

			<Box mx={15}>
				{/** Nom et repiquage*/}
				<DashedHeader>Nom et repiquage</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<Box mb={3}>
							<LayoutField label="Nom commercial sur la facture">
								<FormikTextField name="tradeNameInvoice" fullWidth variant="outlined" placeholder="" />
							</LayoutField>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box mb={3}>
							<LayoutField label="Nom de repiquage">
								<FormikTextField name="transplantName" fullWidth variant="outlined" placeholder="" />
							</LayoutField>
						</Box>
					</Grid>
				</Grid>

				{/** siret et tva*/}
				<DashedHeader>Siret et TVA</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<Box mb={3}>
							<LayoutField label="Numéro de siret">
								<FormikTextField name="siretNumber" fullWidth variant="outlined" disabled={!isRoleAdmin} />
							</LayoutField>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box mb={3}>
							<LayoutField label="Numéro de TVA">
								<FormikTextField name="vatNumber" fullWidth variant="outlined" disabled={!isRoleAdmin} />
							</LayoutField>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box mb={3}>
							<LayoutField label="Numéro de RCS">
								<FormikTextField name="rcsNumber" fullWidth variant="outlined" disabled={!isRoleAdmin} />
							</LayoutField>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<LayoutField label="Type de TVA">
							<FormikTextField
								name="vatType"
								fullWidth
								variant="outlined"
								disabled={true}
								value={field.value === null || field.value === undefined ? '' : field.value ? 'Etrangère' : 'Française'}
							/>
						</LayoutField>
					</Grid>
				</Grid>

				<DashedHeader>Zone territoriale</DashedHeader>
				<Box display="flex" alignItems="center">
					<Typography variant="h4" style={{ color: 'inherit' }}>
						Type de zone
					</Typography>
					<Box ml={2}>
						<FormikRadioField name="territorialArea" options={territorialOptions} isRow value={territorialArea} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ClientDetailedInformationForm;
