import { Box, Button, Chip, Divider } from '@mui/material';

import colors from 'app/theme/colors.scss';
import Path from 'components/Path';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatDateByDDMMYYYY } from 'utils/normalizer';
import useClientQuotesQuery from './hooks/useClientQuotesQuery';
import { formatNumberDisplay } from 'utils/prices';
import {
	DataGrid,
	GridColDef,
	GridValueGetterParams,
	GridRowParams,
	GridFilterModel,
	getGridStringOperators,
	getGridDateOperators,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridSortModel,
} from '@mui/x-data-grid';
import { PATH_NAMES } from 'Header/menus';
import { ClientQuoteFilterInput, ClientQuoteSortInput, ClientQuoteStatus } from 'graphql/types';
import theme from 'app/theme/theme';
import { ClientQuoteStatusTrans, getStatusValueFilter } from './definitions';
const set = require('lodash.set');

interface StatusChipProps {
	status?: keyof typeof ClientQuoteStatus;
}

const statusChipStyle = (status: keyof typeof ClientQuoteStatus) => {
	switch (status) {
		case ClientQuoteStatus.Ready:
			return {
				background: colors.green100,
				color: theme.palette.getContrastText(colors.green100),
			};
		case ClientQuoteStatus.Waiting:
			return {
				background: colors.yellow100,
				color: theme.palette.getContrastText(colors.yellow100),
			};
		case ClientQuoteStatus.InProgress:
			return {
				background: colors.turquoise100,
				color: theme.palette.getContrastText(colors.turquoise100),
			};
		case ClientQuoteStatus.Validated:
			return {
				background: colors.blue200,
				color: theme.palette.getContrastText(colors.blue200),
			};
		case ClientQuoteStatus.Canceled:
			return {
				background: colors.red100,
				color: theme.palette.getContrastText(colors.red100),
			};
	}
};

const StatusChip = ({ status }: StatusChipProps) => {
	if (!status) {
		return <span>-</span>;
	}
	return <Chip label={ClientQuoteStatusTrans[status]} style={{ ...statusChipStyle(status) }} />;
};

const ClientQuoteList = () => {
	const history = useHistory();
	const [filters, setFilters] = useState<ClientQuoteFilterInput>();
	const [sorts, setSorts] = useState<ClientQuoteSortInput>();
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const pagination = { take: pageSize, skip: pageSize * page };

	const { clientQuotes, total, loading } = useClientQuotesQuery(filters, sorts, pagination);
	const columns: GridColDef[] = React.useMemo(
		() => [
			{
				headerName: 'N° de devis',
				field: 'id',
				flex: 1,
				type: 'text',

				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},
			{
				headerName: 'Date de création',
				field: 'createdAt',
				flex: 1,
				type: 'date',
				filterOperators: getGridDateOperators().filter((operator) =>
					['onOrAfter', 'onOrBefore'].includes(operator.value)
				),
				valueGetter: ({ row }: GridValueGetterParams) => formatDateByDDMMYYYY(row.createdAt),
				disableColumnMenu: true,
			},
			{
				headerName: 'N° Réf OS',
				field: 'fileReference.id',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row.fileReference.id,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},
			{
				headerName: 'Client',
				field: 'client.name',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row.client.name,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},
			{
				headerName: 'Demandé par',
				field: 'quoteRequestedBy',
				flex: 1,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},
			{
				headerName: "Nom de l'opération",
				field: 'fileReference.operationName',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row.fileReference.operationName,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},
			{
				headerName: 'Réf client',
				field: 'fileReference.clientReference',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row.fileReference.clientReference,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},
			{
				headerName: 'Responsable ELPEV',
				field: 'elpevResponsible.givenName',
				flex: 1,
				filterable: false,
				sortable: false,
				valueGetter: ({ row }: GridValueGetterParams) =>
					`${row.fileReference?.elpevResponsible?.givenName} ${row.fileReference?.elpevResponsible?.familyName}`,
				disableColumnMenu: true,
			},
			{
				headerName: 'Statut',
				field: 'status',
				flex: 1,
				type: 'singleSelect',
				valueOptions: () => {
					return Object.values(ClientQuoteStatusTrans);
				},
				renderCell: ({ value }) => {
					return <StatusChip status={value} />;
				},
				disableColumnMenu: true,
			},
			{
				headerName: 'Nb de produits',
				field: 'nbProducts',
				flex: 1,
				filterable: false,
				sortable: false,
				valueGetter: ({ row }: GridValueGetterParams) => formatNumberDisplay(row.clientQuoteItem?.length),
				disableColumnMenu: true,
			},
			{
				headerName: 'Prix Vente HT (€)',
				field: 'totalSalesPriceExcludingVAT',
				flex: 1,
				filterable: false,
				valueGetter: ({ row }: GridValueGetterParams) => `${formatNumberDisplay(row.totalSalesPriceExcludingVAT, 2)} €`,
				disableColumnMenu: true,
			},
		],
		[]
	);

	const handleRowClick = ({ row }: GridRowParams) => {
		if (!row.id) return;
		history.push(`/clientQuotes/${row.id}`);
	};

	const onSortChange = (sortModel: GridSortModel) => {
		const sorts = sortModel.reduce((acc, sort) => {
			set(acc, sort.field, sort.sort);
			return acc;
		}, {});
		setSorts(sorts);
	};

	const onFilterChange = (filterModel: GridFilterModel) => {
		const filters = filterModel.items.reduce((acc: Record<string, string>, filter) => {
			switch (filter.operatorValue) {
				case 'contains':
					set(acc, filter.columnField, { contains: filter.value });
					break;
				case 'onOrAfter':
					set(acc, filter.columnField, { gte: filter.value });
					break;
				case 'onOrBefore':
					set(acc, filter.columnField, { lte: filter.value });
					break;
				default:
					set(
						acc,
						filter.columnField,
						filter.columnField === 'status' ? getStatusValueFilter(filter.value) : filter.value
					);
					break;
			}

			return acc;
		}, {});

		setFilters(filters);
	};
	const CustomToolbar = () => (
		<GridToolbarContainer>
			<GridToolbarFilterButton />
			<GridToolbarColumnsButton />
		</GridToolbarContainer>
	);
	return (
		<Box mx={7}>
			<Box position="fixed" width="100%" top={40} left={0} px={11.75} bgcolor={colors.bodyBackgroundLight} zIndex={100}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Box>
						<Path paths={[PATH_NAMES.ClientQuote]} />
					</Box>
					<Box>
						<Link to="/clientQuotes/new" style={{ textDecoration: 'none' }}>
							<Button variant="contained" color="primary">
								Créer un devis
							</Button>
						</Link>
					</Box>
				</Box>
				<Divider />
				<Box
					mt={2}
					sx={{
						height: '75vh',
						width: '100%',
						'.rowLeftBorder': {
							borderLeft: `8px solid ${colors.primary}`,
						},
					}}
				>
					<DataGrid
						sx={{ fontSize: '0.835rem' }}
						columns={columns}
						loading={!clientQuotes && loading}
						rows={clientQuotes ?? []}
						onRowClick={handleRowClick}
						filterMode="server"
						onFilterModelChange={onFilterChange}
						sortingMode="server"
						onSortModelChange={onSortChange}
						getRowClassName={() => 'rowLeftBorder'}
						components={{
							Toolbar: CustomToolbar,
						}}
						rowsPerPageOptions={[10, 25, 50, 100]}
						pagination={true}
						page={page}
						pageSize={pageSize}
						rowCount={total || 0}
						paginationMode="server"
						onPageChange={setPage}
						onPageSizeChange={setPageSize}
					/>
				</Box>
			</Box>
		</Box>
	);
};
export default ClientQuoteList;
