import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import OrderTableHead from 'order/form/order-cockpit/components/OrderTableHead';
import { TableCell, TableRow, Typography } from '@mui/material';
import { INVOICE_PRODUCT_DETAILS_TABLE_HEAD_COLUMNS, InvoiceOrderModel } from '../../definitions';
import colors from 'app/theme/colors.scss';
import { useStyles } from 'client-quote/form/quote/components/SalePriceTable';
import { CharacteristicByProduct } from 'product/components/CharacteristicByProduct';
import { Designation } from './Designation';
import { OrderItem } from 'graphql/types';
import { AdditionalInformation } from './AdditionalInformation';
import { calculatePriceHt } from '../../helpers';
import useCollapse from '../../../hooks/useCollapse';
import { formatNumberDisplay } from '../../../utils/prices';
import DashedHeader from '../../../components/DashedHeader';
import DeliveryInformationByProduct from './DeliveryInformationByProduct';

interface props {
	orderItem: OrderItem;
	index: number;
	order?: InvoiceOrderModel;
}

const OrderInformation = ({ orderItem, index, order }: props): JSX.Element => {
	const classes = useStyles();
	const { renderCollapseComponent, displayContent } = useCollapse(false);

	return (
		<>
			<Box display="flex" bgcolor={colors.white} className={classes.cell}>
				<Box p={1} flexGrow={1}>
					<Typography variant="h4">{orderItem.productName}</Typography>
				</Box>
				{renderCollapseComponent()}
			</Box>
			<Collapse in={displayContent}>
				<DashedHeader>Information de commande</DashedHeader>
				<TableContainer component={Paper}>
					<Table>
						<OrderTableHead align={'center'} columns={INVOICE_PRODUCT_DETAILS_TABLE_HEAD_COLUMNS} isColorBlue />
						<TableBody>
							<TableRow>
								<TableCell align={'center'} width="20%">
									<Designation index={index} order={order} />
								</TableCell>
								<TableCell width="30%">
									<CharacteristicByProduct product={orderItem.product} />
								</TableCell>
								<TableCell align={'center'} width="20%">
									<AdditionalInformation />
								</TableCell>
								<TableCell align={'center'} width="10%">
									{formatNumberDisplay(orderItem.quantity)}
								</TableCell>
								<TableCell align={'center'} width="10%">
									Non applicable
								</TableCell>
								<TableCell align={'center'} width="10%">
									{calculatePriceHt(
										orderItem.transportSellingPriceHt,
										orderItem.prepressSellingPriceHt,
										orderItem.productSellingPriceHt
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<Box mt={1.5}>
					<DashedHeader>Information de livraison</DashedHeader>
					<DeliveryInformationByProduct orderItem={orderItem} />
				</Box>
			</Collapse>
		</>
	);
};

export default OrderInformation;
