import React from 'react';
import { Box, Typography } from '@mui/material';
import { ClientChildren } from 'graphql/types';
import colors from 'app/theme/colors.scss';
import { sortByKey } from 'utils/arrays';

interface StoresProps {
	children: ClientChildren[];
}

const Stores = ({ children }: StoresProps): JSX.Element => (
	<Box
		display="inline-block"
		borderRadius="4px"
		border={`2px solid ${colors.gray200}`}
		bgcolor={colors.bodyBackgroundLight}
		boxSizing="border-box"
		width="204.21px"
	>
		{sortByKey(children, 'name').map(({ id, name, isActif, fileCode }: ClientChildren) => (
			<React.Fragment key={id}>
				<Typography variant="h5" color={isActif ? 'textPrimary' : 'error'}>
					{name}
				</Typography>
				{fileCode && (
					<Typography variant="body2" color={'error'}>
						(Réf : {fileCode})
					</Typography>
				)}
			</React.Fragment>
		))}
		<Typography color="primary" variant="h6">
			Magasin
		</Typography>
	</Box>
);

export default Stores;
