import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import DashedHeader from 'components/DashedHeader';
import OrderPrice from 'order/form/order-cockpit/components/OrderPrice';
import DeliveryInformationsDialogForm from './DeliveryInformationsDialogForm';
import { useField } from 'formik';
import { Order } from 'graphql/types';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import { sumMargeWithRfa, sumRealMargeWithRfa } from '../../helpers';
import colors from 'app/theme/colors.scss';

export const GeneralInformation = (): JSX.Element => {
	const [field] = useField<Order>('order');
	const totalBuyingPriceHt = field ? (field?.value?.transportBuyingPriceHt ?? 0) + field?.value?.buyingPriceHt : 0;

	return (
		<>
			<Box mb={4} mt={4}>
				<Typography variant="h2">Informations générales</Typography>
				<Box display="flex" mx={15} mt={3}>
					<Box display="flex" width={'70%'} mr={10}>
						<Grid container spacing={5}>
							<Grid item xs={6}>
								<DashedHeader>Informations OneSupply</DashedHeader>
								<LayoutFieldForm
									label="N/Rèf"
									name={field?.value?.parentOrderId ? 'order.parentOrderId' : 'values.order?.id'}
									disabled
								/>
								<LayoutFieldForm label="Date de création" name="createdAt" disabled />
							</Grid>
							<Grid item xs={6}>
								<DashedHeader>Contact</DashedHeader>
								<LayoutFieldForm label="Contact Elpev" name="elepvContactName" disabled />
								<LayoutFieldForm label="Email" name="elpevContactEmail" disabled />
								<LayoutFieldForm label="Téléphone" name="elpevContactPhone" disabled />
							</Grid>
							<Grid item xs={6}>
								<DashedHeader>Client</DashedHeader>
								<LayoutFieldForm label="Client" name="order.clientName" disabled />
								<LayoutFieldForm label="Nom de l'opération" name="order.operationName" disabled />
								<LayoutFieldForm label="Référence client" name="order.clientReference" disabled />
							</Grid>
							<Grid item xs={6}>
								<DashedHeader>Adresse de facturation</DashedHeader>
								<Box display="flex" flexGrow={1}>
									<DeliveryInformationsDialogForm />
									{field?.value?.billingAddress && (
										<Box ml={1}>
											<Typography variant="h4">{field.value.billingAddress.name}</Typography>
											<Typography variant="h5">{field.value.billingAddress.firstAddress}</Typography>
											<Typography variant="h5">{field.value.billingAddress.secondAddress}</Typography>
											<Typography variant="h5">{field.value.billingAddress.thirdAddress}</Typography>
											<Typography variant="h5">
												{field.value.billingAddress?.zipCode + ' '}
												{field.value.billingAddress?.deliveryDepartment}
											</Typography>
											<Typography variant="h5">{field.value.billingAddress?.city}</Typography>
										</Box>
									)}
								</Box>
							</Grid>
						</Grid>
					</Box>

					<Divider orientation="vertical" flexItem />
					<Box width="30%" pl={10}>
						<Box pb={1} borderBottom={`1px dashed ${colors.turquoise500}`} mb={4}>
							<Box display="flex" flexDirection="row" justifyContent="space-between" color={colors.turquoise500}>
								<Typography variant="h3">Total</Typography>
								<Typography variant="h3">Prévisionnel</Typography>
								<Typography variant="h3">Réel</Typography>
							</Box>
						</Box>
						<Box>
							<OrderPrice
								variant="h5"
								title="Total PA HT (€)"
								price={totalBuyingPriceHt}
								realPrice={totalBuyingPriceHt}
								color="textSecondary"
								isInvoice={true}
							/>
							<OrderPrice
								variant="h2"
								title="Total PV HT (€)"
								price={field.value?.sellingPriceHt}
								realPrice={field.value?.sellingPriceHt}
								pb={1}
								isInvoice={true}
							/>
							<OrderPrice
								variant="h2"
								title="Marge avec RFA :"
								price={sumMargeWithRfa(field.value?.orderItem ?? [])}
								realPrice={sumRealMargeWithRfa(field.value?.orderItem ?? [])}
								pb={1}
								isInvoice={true}
							/>
							<OrderPrice
								variant="h5"
								title="Taux de marge :"
								price={field.value?.marginRate}
								realPrice={field.value?.marginRate}
								color="textSecondary"
								isInvoice={true}
							/>
							<OrderPrice
								variant="h4"
								title="Taux T.V.A (%) :"
								price={field.value?.tvaRate}
								realPrice={field.value?.tvaRate}
								isInvoice={true}
							/>
							<OrderPrice
								variant="h5"
								title="Montant T.V.A :"
								price={field.value?.tva}
								realPrice={field.value?.tva}
								color="textSecondary"
								isInvoice={true}
							/>
							<OrderPrice
								variant="h2"
								title="Total PV TTC :"
								price={field.value?.sellingPriceTtc}
								realPrice={field.value?.sellingPriceTtc}
								isInvoice={true}
							/>
							<OrderPrice
								variant="h5"
								title="Estimatif taxe Citéo :"
								price={0}
								realPrice={0}
								color="textSecondary"
								isInvoice={true}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
