import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import colors from '../../../../app/theme/colors.scss';
import { FormikNumberField } from 'components/form/FormikNumberField';

const useStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: '7px solid',
			borderBottomColor: colors.bodyBackgroundLight,
			padding: '6px',
		},
	},
	cell: {
		backgroundColor: colors.white,
		borderLeft: '3px solid',
		borderLeftColor: colors.indigo700,
	},
});

interface TableRowPredefinedQuantityProps {
	name: string;
}

const TableRowPredefinedQuantity = ({ name }: TableRowPredefinedQuantityProps): JSX.Element => {
	const classes = useStyles();
	return (
		<Box width={135} className={classes.cell} my={1} justifyContent="center">
			<Box className={classes.root}>
				<FormikNumberField decimalScale={0} name={name} variant="filled" />
			</Box>
		</Box>
	);
};

export default TableRowPredefinedQuantity;
