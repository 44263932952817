import React, { useEffect, useMemo } from 'react';
import { AutocompleteValue, Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useFileReferencesQuery from 'file-reference/hooks/useFileReferencesQuery';
import { useField } from 'formik';
import { LayoutField } from 'components/form/LayoutField';
import colors from 'app/theme/colors.scss';
import DashedHeader from 'components/DashedHeader';
import { UncontrolledDatePicker } from 'components/picker/UncontrolledDatePicker';
import DisplayTextInField from 'components/form/DisplayTextInField';
import { removeDuplicatesAndSort } from 'utils/arrays';
import { FileReferencePick } from '../definition';
import { Source } from '../../../graphql/types';
import usePrevious from 'hooks/usePrevious';

const FileReference = (): JSX.Element => {
	const [fieldClientId] = useField<string>('clientId');
	const [fieldFileReferenceId, , helpersFileReferenceId] = useField<string | null | undefined>('fileReferenceId');
	const [fieldFileDeliveryDate, , helpersFileDeliveryDate] = useField<Date | null | unknown>('fileDeliveryDate');
	const [fieldDeliveryDate, , helpersDeliveryDate] = useField<Date | unknown | null>('deliveryDate');
	const [, , helperFileReferenceSelected] = useField<undefined | FileReferencePick>('fileReferences');
	const [fieldOperationName, , helpersOperationName] = useField<string | undefined>('operationName');
	const { fileReferences } = useFileReferencesQuery(undefined, undefined, undefined, {
		clientId: fieldClientId.value ?? undefined,
		source: Source.OneSupply,
	});

	const previousFileReferences = usePrevious(fileReferences);
	const previousClientId = usePrevious(fieldClientId.value);

	useEffect(() => {
		if (
			previousClientId === fieldClientId.value &&
			previousFileReferences &&
			fileReferences &&
			previousFileReferences.length < fileReferences?.length
		) {
			helpersFileReferenceId.setValue(fileReferences?.[0]?.id);
			helpersOperationName.setValue(fileReferences?.[0]?.operationName);
		}
	}, [
		fileReferences,
		previousFileReferences,
		helpersFileReferenceId,
		helpersOperationName,
		previousClientId,
		fieldClientId.value,
	]);

	const handleChange = (value: AutocompleteValue<unknown, undefined, undefined, undefined>) => {
		const fileReferenceSelected = fileReferences?.find(({ id }) => id === value);
		helpersFileReferenceId.setValue(value as string);
		helperFileReferenceSelected.setValue(fileReferenceSelected);
		helpersOperationName.setValue(fileReferenceSelected?.operationName ?? undefined);
	};

	const fileReferenceSelected = useMemo(() => {
		return fileReferences?.find(({ id }) => id === fieldFileReferenceId.value);
	}, [fileReferences, fieldFileReferenceId.value]);

	return (
		<Box>
			<LayoutField label="Réference Dossier">
				<Box mb={3} bgcolor={!fieldClientId.value ? colors.grey : ''}>
					<Autocomplete
						disabled={!fieldClientId.value}
						fullWidth
						size="small"
						clearOnBlur
						value={fieldFileReferenceId.value ?? ''}
						getOptionLabel={(option) => option}
						onChange={(e, newValue) => handleChange(newValue)}
						options={fileReferences?.map(({ id }) => id).sort((a, b) => b.localeCompare(a)) ?? []}
						renderInput={(params) => <TextField {...params} variant="outlined" />}
						data-testid="file-reference-autocomplete"
					/>
				</Box>
			</LayoutField>
			<LayoutField label="Nom de l'opération">
				<Box mb={3} bgcolor={!fieldClientId.value || !!fieldFileReferenceId.value ? colors.grey : ''}>
					<Autocomplete
						disabled={!fieldClientId.value || !!fieldFileReferenceId.value}
						fullWidth
						size="small"
						clearOnBlur
						value={fieldOperationName.value ?? ''}
						getOptionLabel={(option) => option}
						onChange={(e, newValue) => helpersOperationName.setValue(newValue as string)}
						options={removeDuplicatesAndSort(fileReferences?.map((ref) => ref.operationName) ?? []) as string[]}
						renderInput={(params) => <TextField {...params} variant="outlined" />}
						data-testid="operation-name-autocomplete"
					/>
				</Box>
			</LayoutField>
			<DisplayTextInField
				label="Responsable ELPEV"
				value={
					fileReferenceSelected && fieldFileReferenceId.value
						? ` ${fileReferenceSelected.elpevResponsible.givenName} ${fileReferenceSelected.elpevResponsible.familyName}`
						: ''
				}
			/>
			<DashedHeader>Référence et date</DashedHeader>
			<DisplayTextInField
				label="Devis demandé par"
				value={fieldFileReferenceId.value ? ` ${fileReferenceSelected?.askBy ?? ''} ` : ''}
			/>
			<DisplayTextInField
				label="Référence client"
				value={fieldFileReferenceId.value ? ` ${fileReferenceSelected?.clientReference ?? ''} ` : ''}
			/>
			<>
				<LayoutField label="Date de remise des fichiers">
					<Box width={164} mb={2}>
						<UncontrolledDatePicker
							disabled
							value={(fieldFileDeliveryDate.value as Date) ?? new Date()}
							handleClear={() => helpersFileDeliveryDate.setValue(null)}
							maxDate={fieldDeliveryDate.value ?? undefined}
							onChange={(date) => {
								helpersFileDeliveryDate.setValue(date);
							}}
							name=""
						/>
					</Box>
				</LayoutField>
				<LayoutField label="Date de livraison">
					<Box width={164} mb={2}>
						<UncontrolledDatePicker
							disabled
							value={fieldDeliveryDate.value as Date}
							handleClear={() => helpersDeliveryDate.setValue(null)}
							onChange={(date) => {
								helpersDeliveryDate.setValue(date);
							}}
							name=""
						/>
					</Box>
				</LayoutField>
			</>
		</Box>
	);
};

export default FileReference;
