import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React from 'react';
import colors from '../../app/theme/colors.scss';

interface FormikGroupCheckboxFieldProps {
	name: string;
	handleClick?: (value: any) => void;
	value?: string;
}

export const useStyles = makeStyles(() =>
	createStyles({
		root: {
			margin: '8px',
			cursor: 'pointer',
			color: colors.blue500,
		},
	})
);

const FormikGroupCheckboxField = ({ value, name, handleClick, ...rest }: FormikGroupCheckboxFieldProps) => {
	const classes = useStyles();

	return (
		<Field
			className={classes.root}
			name={name}
			type="checkbox"
			onClick={handleClick}
			value={value}
			color="primary"
			{...rest}
		/>
	);
};
export default FormikGroupCheckboxField;
