import { GetInvoiceByIdQuery, GetInvoicesQuery } from './graphql/queries.definitions';
import { Tab } from '../hooks/useManualTabs';
import React from 'react';

export const TAB_MENU_INVOICE: Tab[] = [
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations générales',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Produits',
	},
];

export const invoiceStatus = [
	{ key: 'Created', value: 'Créer' },
	{ key: 'ToCount', value: 'A comptabiliser' },
	{ key: 'Counted', value: 'Comptabilisé' },
];

export const INVOICE_PRODUCT_DETAILS_TABLE_HEAD_COLUMNS = [
	'Désignation',
	'Caractéristique du produit',
	'Informations complémentaires',
	'Quantité',
	'Prépresse',
	'Prix HT (en €)',
];

export const IS_WITH_RFA_OPTIONS = [
	{ label: 'Oui', value: true },
	{ label: 'Non', value: false },
];

export interface InvoiceListType {
	invoiceId: string;
	orderId?: string;
	createdAt: string;
	clientName: string;
	sellingPriceHt?: number | null;
	orderTva?: number | null;
	sellingPriceTtc?: number | null;
	validateAt?: string;
	validator?: string | null;
	icons: string;
	url: string;
	id: string;
}

export type InvoicesModel = GetInvoicesQuery['invoices']['invoices'][0];
export type InvoiceModel = GetInvoiceByIdQuery['invoice'];
export type InvoiceOrderModel = GetInvoiceByIdQuery['invoice']['order'];
