import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LayoutField } from '../../../../../components/form/LayoutField';
import useSupportsQuery from '../../../../../admin/support/hooks/useSupportsQuery';
import { BootstrapInput } from '../../../../../components/inputs/bootstrap-input';
import { ClientSupport, MarginType } from '../../../../../graphql/types';
import { useField } from 'formik';
import { Margin, marginType } from '../definitions';
import { MarginSupport } from '../specific-margin-support';

interface AddingSupportsProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	handleCloseClick: () => void;
	marginSupportData: MarginSupport[];
	marginSupportToEdit: MarginSupport | null;
	setIsEditable: Dispatch<SetStateAction<boolean>>;
	isEditable: boolean;
	setMarginSupportToEdit: Dispatch<SetStateAction<MarginSupport | null>>;
	setMarginSupportData: Dispatch<SetStateAction<MarginSupport[]>>;
}

export const AddingSupports = ({
	setIsOpen,
	isOpen,
	handleCloseClick,
	setMarginSupportData,
	marginSupportToEdit,
	marginSupportData,
	setIsEditable,
	isEditable,
	setMarginSupportToEdit,
}: AddingSupportsProps): JSX.Element | null => {
	const { supports } = useSupportsQuery();
	const [, meta, helpers] = useField<ClientSupport[]>('clientsSupports');
	const [margin, setMargin] = useState<number>(0);
	const [support, setSupport] = useState<string>('');
	const [selectMarginType, setSelectMarginType] = useState<MarginType>(MarginType.Percent);

	const handleChangeSupport = (event: SelectChangeEvent<unknown>): void => {
		event.preventDefault();
		setIsEditable(false);
		setSupport((event.target.value as unknown) as string);
	};

	const handleChangeMarginType = (event: SelectChangeEvent<unknown>): void => {
		event.preventDefault();
		setIsEditable(false);
		setSelectMarginType(event.target.value as MarginType);
	};

	const handleChangeMargin = (event: SelectChangeEvent<number>): void => {
		event.preventDefault();
		setIsEditable(false);
		setMargin(+event.target.value);
	};

	const handleAddSupport = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const splitSupport = support.split('_');
		let supports = [...marginSupportData];
		let supportsEdit: MarginSupport[] | [] = [];

		if (!marginSupportToEdit && !splitSupport[0].length) {
			helpers.setError("une erreur c'est produite");
			return;
		}

		if (marginSupportToEdit) {
			supports = supports.filter((support) => support.supportId !== marginSupportToEdit.supportId);
		}

		const supportIndex = supports.findIndex((item) => item.supportId === splitSupport[0]);

		if (supportIndex === -1) {
			supportsEdit = [
				{
					support: {
						id: marginSupportToEdit ? marginSupportToEdit.support.id : splitSupport[0],
						name: marginSupportToEdit ? marginSupportToEdit.support.name : splitSupport[1],
						excludingEcotax: marginSupportToEdit
							? marginSupportToEdit.support.excludingEcotax
							: JSON.parse(String(splitSupport[2])),
					},
					supportId: marginSupportToEdit ? marginSupportToEdit.support.id : splitSupport[0],
					margin: marginSupportToEdit ? (margin === 0 ? marginSupportToEdit.margin : margin) : margin,
					icons: '',
					marginType: selectMarginType,
				},
				...supports,
			];
			setSupport('');
			setMargin(0);
			setSelectMarginType(MarginType.Percent);
			setMarginSupportToEdit(null);
			setMarginSupportData(supportsEdit);
			helpers.setValue(supportsEdit);
			setIsEditable(false);
			setIsOpen(!isOpen);
		} else helpers.setError("une erreur c'est produite");
	};

	if (!supports) return null;

	return (
		<Dialog onClose={() => setIsOpen(false)} open={isOpen} maxWidth="sm" fullWidth>
			<DialogTitle>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={11}>
						<Typography variant="h2">Ajout de marge spécifique pour un support</Typography>
					</Grid>
					<Grid>
						<IconButton aria-label="close" onClick={handleCloseClick} size="large">
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<Divider />
			<Box py={1} px={3}>
				{meta.error && (
					<Typography color="error">
						Données invalide, veuillez verifier tous les champs, ou support déjà assigné...
					</Typography>
				)}
				<Typography variant="h4">Support</Typography>
				<FormControl fullWidth>
					<Select
						value={
							marginSupportToEdit
								? `${marginSupportToEdit?.support.id}_${marginSupportToEdit?.support.name}_${marginSupportToEdit?.support.excludingEcotax}`
								: support
						}
						input={<BootstrapInput />}
						variant="outlined"
						disabled={!!marginSupportToEdit}
						onChange={handleChangeSupport}
					>
						{supports.map(({ name, id, excludingEcotax }) => (
							<MenuItem value={`${id}_${name}_${excludingEcotax}`} key={id}>
								{`${name}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item xs={5}>
					<Box py={1} px={3}>
						<LayoutField label="Type de marge">
							<Box width="100%">
								<FormControl fullWidth>
									<Select
										value={isEditable ? marginSupportToEdit?.marginType : selectMarginType}
										input={<BootstrapInput />}
										variant="outlined"
										onChange={handleChangeMarginType}
									>
										<MenuItem value="" />
										{marginType.map((item) => (
											<MenuItem key={item} value={item === Margin.euro ? MarginType.Euro : MarginType.Percent}>
												{item}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</LayoutField>
					</Box>
				</Grid>
				<Grid item xs={5}>
					<Box py={1} px={3}>
						<LayoutField label="Marge">
							<Box width="100%" display="flex" flexDirection="row" alignItems="center">
								<BootstrapInput
									value={isEditable ? marginSupportToEdit?.margin : margin}
									type="number"
									inputProps={{ min: 0.0, step: '0.01' }}
									onChange={handleChangeMargin}
								/>
								<Box ml={1}>
									<Typography variant="h4">{selectMarginType === MarginType.Percent ? ' %' : ' €'}</Typography>
								</Box>
							</Box>
						</LayoutField>
					</Box>
				</Grid>
			</Grid>
			<Box p={3} display="flex" flexDirection="row-reverse">
				<Button variant="contained" color="primary" onClick={(e) => handleAddSupport(e)}>
					Ajouter
				</Button>
			</Box>
		</Dialog>
	);
};
