import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import React, { useState } from 'react';
import colors from '../../../../../app/theme/colors.scss';
import { LayoutField } from '../../../../../components/form/LayoutField';
import { useParams } from 'react-router-dom';
import useClientShippingAddressQuery from '../../../../hooks/useClientShippingAddressQuery';
import { Autocomplete } from '@mui/material';
import WorkingHours from '../../../working-days/WorkingHours';
import ContactForm from './ContactForm';
import TransportForm from './TransportForm';
import { BoxShippingMode, ClientShippingAddress, PalletShippingMode } from '../../../../../graphql/types';
import CommentForm from './CommentForm';
import { initialWorkingDays } from '../../../working-days/definitions';
import { isString } from '../../../../../utils/typeGuards';
import Address from '../../../../../components/Address/Address';
import { Checkbox } from 'formik-mui';

interface ShippingAddressFormProps {
	isRoleAdmin: boolean;
	hasMainAddress: boolean;
}

const ShippingAddressForm = ({ isRoleAdmin, hasMainAddress }: ShippingAddressFormProps): JSX.Element => {
	const { id } = useParams<ParamsRouteProps>();
	const { clientShippingAddress } = useClientShippingAddressQuery();
	const [disabled, setDisabled] = useState<boolean>(false);
	const [clientAddressFilter, setClientAddressFilter] = useState<ClientShippingAddress | undefined>(undefined);
	const { setValues } = useFormikContext();

	const handleChange = (address: ClientShippingAddress | null | string) => {
		if (address && !isString(address)) {
			setDisabled(address?.isMain ?? false);
			setClientAddressFilter(address);
			setValues({ ...address, isMain: false });
		}
	};

	const handleInputChange = (value: string) => {
		setDisabled(false);
		setValues({
			clientId: id,
			contact: {
				fax: '',
				email: '',
				phone: '',
				website: '',
				storeCode: '',
				directorName: '',
				isNotificated: false,
			},
			address: {
				name: value ?? '',
				firstAddress: '',
				secondAddress: '',
				thirdAddress: '',
				postBox: '',
				zipCode: '',
				city: '',
				country: '',
				isShipping: true,
				deliveryDepartment: '',
			},
			isMain: false,
			boxShippingMode: BoxShippingMode.BeforeNine,
			maxBoxWeight: 15,
			palletShippingMode: PalletShippingMode.Strandard,
			comment: '',
			workingDays: initialWorkingDays,
		});
	};

	return (
		<Form>
			<Box p={2} mb={2}>
				<Box bgcolor={colors.grayCard} textAlign="center" borderColor={colors.indigo700} p={1} mb={2}>
					<Typography variant="h4">Adresse postale</Typography>
				</Box>

				<LayoutField label="Nom commercial">
					<Box mt={-2}>
						<Autocomplete
							size="small"
							forcePopupIcon
							freeSolo
							options={
								clientShippingAddress
									? clientShippingAddress.filter((option) => option.isMain && option.clientId !== id)
									: []
							}
							onChange={(event, newValue) => handleChange(newValue)}
							onInputChange={(event, newValue) => handleInputChange(newValue)}
							getOptionLabel={(option) => (!isString(option) ? option?.address?.name || '' : '')}
							renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
							data-testid="shipping-address-autocomplete"
						/>
					</Box>
				</LayoutField>
				<Address isDisplayDepartements name="address" disabled={disabled} disabledDepartments={disabled} />
				{isRoleAdmin && !disabled && !hasMainAddress && (
					<Box display="flex" alignItems="center" justifyContent="flex-start">
						<Field name="isMain" component={Checkbox} type="checkbox" disabled={disabled} size="small" />
						<Box>
							<Typography variant="h5">Adresse principale</Typography>
						</Box>
					</Box>
				)}
				<Box bgcolor={colors.grayCard} textAlign="center" borderColor={colors.indigo700} p={1} my={2}>
					<Typography variant="h4">Horaires</Typography>
				</Box>
				<WorkingHours client={clientAddressFilter} disabled={disabled} />
				<ContactForm disabled={disabled} />
				<CommentForm disabled={disabled} />
				<TransportForm disabled={disabled} />
				<Divider />
				<Box p={3} display="flex" flexDirection="row-reverse">
					<Button variant="contained" color="primary" type="submit">
						Ajouter
					</Button>
				</Box>
			</Box>
		</Form>
	);
};
export default ShippingAddressForm;
