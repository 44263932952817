import React, { CSSProperties } from 'react';
import colors from '../../app/theme/colors.scss';
import { createStyles, makeStyles } from '@mui/styles';
import { Button, SvgIconTypeMap, CircularProgress } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type StylesProps = {
	color: string;
	bgColor?: string;
};

type OutlinedButtonType = {
	title: string;
	variant?: 'text' | 'outlined' | 'contained';
	handleClickBtn: () => void;
	color?: string;
	Icon?: OverridableComponent<SvgIconTypeMap>;
	bgColor?: string;
	styles?: CSSProperties;
	disabled?: boolean;
	loading?: boolean;
};

const useStyles = makeStyles(() =>
	createStyles({
		btn: {
			border: ({ color }: StylesProps) => `1px solid ${color}`,
			color: ({ color }: StylesProps) => color,
			backgroundColor: ({ bgColor }: StylesProps) => bgColor ?? 'none',
			marginLeft: 0,
		},
	})
);

const OutlinedButton = ({
	title,
	variant = 'outlined',
	handleClickBtn,
	Icon,
	color = colors.primary,
	bgColor,
	styles,
	disabled = false,
	loading = false,
}: OutlinedButtonType) => {
	const classes = useStyles({ color, bgColor });

	return (
		<Button
			disabled={disabled}
			variant={variant}
			onClick={handleClickBtn}
			className={classes.btn}
			startIcon={Icon && <Icon fontSize="small" />}
			endIcon={loading && <CircularProgress size={16} aria-label="circular-progress" />}
			style={{ ...styles }}
		>
			{title}
		</Button>
	);
};

export default OutlinedButton;
