import { Box, Button, FormLabel, Grid, TextField, TextFieldProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import React from 'react';
import usePasswordQuery from '../hooks/usePasswordQuery';
type PasswordFieldProps = { name: string } & Omit<TextFieldProps, 'value' | 'onChange' | 'error' | 'helperText'>;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textField: {
			backgroundColor: 'white',
		},
	})
);

const PasswordField = ({ name, ...rest }: PasswordFieldProps) => {
	const classes = useStyles();
	const { password, refetchPassword } = usePasswordQuery();
	const [field, meta, helpers] = useField<unknown | null | undefined>(name);

	const handleGeneratePassword = () => {
		refetchPassword();
		helpers.setValue(password);
	};

	return (
		<>
			<Box pt={2}>
				<FormLabel>Mot de Passe</FormLabel>
			</Box>
			<Grid container direction="row" justifyContent="center" alignContent="center">
				<Grid item xs={9}>
					<TextField
						name={name}
						placeholder="mot de passe "
						variant="outlined"
						margin="dense"
						fullWidth
						className={classes.textField}
						onChange={field.onChange}
						value={field.value ?? ''}
						error={meta.touched && Boolean(meta.error)}
						helperText={meta.touched && meta.error}
						{...rest}
					/>
				</Grid>
				<Grid item xs={3}>
					<Box mt={1} ml={3}>
						<Button onClick={handleGeneratePassword} variant="outlined" color="primary">
							Générer
						</Button>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default PasswordField;
