import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import FormikRadioField from 'components/form/FormikRadioField';
import { useField } from 'formik';
import { InvoiceOrderModel, IS_WITH_RFA_OPTIONS } from '../../definitions';
import { OrderItem } from 'graphql/types';
import { calculateMargeWithRfa } from '../../helpers';

interface DesignationProps {
	index: number;
	order?: InvoiceOrderModel;
}

export const Designation = ({ index, order }: DesignationProps): JSX.Element => {
	const [field] = useField<OrderItem>(`order.orderItem[${index}]`);
	const [, , realProductMarginHelpers] = useField<number | null | undefined>(
		`order.orderItem[${index}].realProductMargin`
	);
	const [, , realTransportMarginHelpers] = useField<number | null | undefined>(
		`order.orderItem[${index}].realTransportMargin`
	);
	const [, , isWithRfaProductFieldHelpers] = useField<boolean>(`order.orderItem[${index}].isWithRfaProduct`);
	const [, , isWithRfaTransportFieldHelpers] = useField<boolean>(`order.orderItem[${index}].isWithRfaTransport`);

	const onchange = (newValue: boolean, type: string) => {
		if (order?.orderItem) {
			if (type === 'product') {
				isWithRfaProductFieldHelpers.setValue(newValue);
				realProductMarginHelpers.setValue(
					calculateMargeWithRfa(
						order?.orderItem[index].realProductMargin,
						order?.orderItem[index].isWithRfaProduct,
						newValue,
						field.value.productSellingPriceHt ?? 0,
						field.value.buyingPriceHt ?? 0,
						field.value.rfaRate
					)
				);
			} else {
				isWithRfaTransportFieldHelpers.setValue(newValue);
				realTransportMarginHelpers.setValue(
					calculateMargeWithRfa(
						order?.orderItem[index].realTransportMargin,
						order?.orderItem[index].isWithRfaTransport,
						newValue,
						field.value.transportSellingPriceHt ?? 0,
						field.value.transportBuyingPriceHt ?? 0,
						field.value.rfaRate
					)
				);
			}
		}
	};

	return (
		<>
			<Box display="flex" justifyContent="center" alignItems="center" mb={5}>
				<img
					width="80%"
					height="70%"
					src="https://image.shutterstock.com/image-vector/empty-placeholder-image-icon-design-260nw-1366372628.jpg"
					alt=""
				/>
			</Box>
			<Divider />

			<Box mt={5} justifyContent={'center'}>
				<Typography variant="h4">Appliquer RFA client sur le produit</Typography>
				<FormikRadioField
					isRow
					name={`order.orderItem[${index}].isWithRfaProduct`}
					options={IS_WITH_RFA_OPTIONS}
					onChange={(_, newValue) => onchange(newValue === 'true', 'product')}
				/>
			</Box>
			<Box pt={3} justifyContent={'center'}>
				<Typography variant="h4">Appliquer RFA client sur le transport</Typography>
				<FormikRadioField
					isRow
					name={`order.orderItem[${index}].isWithRfaTransport`}
					options={IS_WITH_RFA_OPTIONS}
					onChange={(_, newValue) => onchange(newValue === 'true', 'transport')}
				/>
			</Box>
		</>
	);
};
