import * as Yup from 'yup';
import { ClientPrice } from '../../graphql/types';
import { ClientPricesByProductNameAndClientIdQuery } from '../graphql/queries.definitions';

export const validationSchema = (
	values: ClientPrice,
	filtredClientPrices?: ClientPricesByProductNameAndClientIdQuery['clientPricesExisting']
) => {
	const isClientPriceHasDifferentNbVersion = (value?: number) => {
		const clientPricesNbsVersions = filtredClientPrices
			?.filter((clientPrice) => clientPrice.quantity === values.quantity)
			.map((clientPrice) => clientPrice.numberVersion);
		const isClientPriceHasDifferentNbVersion =
			value && clientPricesNbsVersions ? !clientPricesNbsVersions.includes(value) : true;
		return isClientPriceHasDifferentNbVersion;
	};

	return Yup.object().shape({
		clientId: Yup.string().required('Vous devez sélectionner un nom du client'),
		productName: Yup.string().required('Vous devez sélectionner un nom du produit'),
		quantity: Yup.number().min(1, 'Vous devez saisir une quantité valide'),
		numberVersion: Yup.number().test(
			'isClientPriceHasSameNbVersion',
			'Le produit pour cette quantité et ce nombre de version existe déja !',
			(value) => isClientPriceHasDifferentNbVersion(value)
		),
	});
};
