import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import Path from '../../../components/Path';
import ManualTabs from '../../../components/tabs/ManualTabs';
import { BigShopConfig } from '../../../graphql/types';
import { Form, Formik } from 'formik';
import Configuration from './configuration/Configuration';
import colors from '../../../app/theme/colors.scss';

interface BigShopConfigFormProps {
	paths: (string | undefined)[];
	bigShopConfig: BigShopConfig | undefined;
	onSubmit: (bigShop: BigShopConfig | undefined) => void;
}

const BigShopConfigForm = ({ paths, bigShopConfig, onSubmit }: BigShopConfigFormProps): JSX.Element | null => {
	const tabs = useMemo(
		() => [
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Configuration / Définition',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Spécification',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: "Calcul des prix d'achats BigShop",
			},
		],
		[]
	);

	const [currentTab, setCurrentTab] = useState(tabs[0]);
	const handleTabChange = (tab: typeof tabs[0]) => {
		const offsetTop = tab.ref.current?.offsetTop;
		const fixedHeaderHeight = 230;
		setCurrentTab(tab);

		if (offsetTop) window.scroll(0, offsetTop - fixedHeaderHeight);
	};

	const bigShopConfigValues = {
		...bigShopConfig,
	};

	return (
		<Box m={1}>
			<Formik<BigShopConfig>
				initialValues={bigShopConfigValues}
				enableReinitialize
				onSubmit={async ({ ...restBigShopConfig }) => {
					onSubmit(restBigShopConfig);
				}}
			>
				<Form>
					<Box position="fixed" width="100%" top={40} left={0} px={10} zIndex={50} bgcolor={colors.bodyBackgroundLight}>
						<Box display={'flex'}>
							<Box flexGrow={1}>
								<Path paths={paths} />
							</Box>
						</Box>
						<Divider />
						<Box display="flex" justifyContent="space-between" mb={2} pt={5}>
							<Typography variant="h2">Configuration BigShop pour le support</Typography>
							<Box>
								<Button color="primary" type="reset">
									Annuler
								</Button>
								<Button variant="contained" color="primary" type="submit">
									Enregistrer les modifications
								</Button>
							</Box>
						</Box>
						<Box mx={13}>
							<ManualTabs currentTab={currentTab} tabs={tabs} onChange={handleTabChange} />
						</Box>
					</Box>
					<Box mt={20} mx={15}>
						<div ref={tabs[0].ref}>
							<Configuration />
						</div>
					</Box>
				</Form>
			</Formik>
		</Box>
	);
};

export default BigShopConfigForm;
