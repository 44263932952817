import React, { ReactNode } from 'react';
import { Dialog } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import HeaderPopin from './HeaderPopin';
import FooterPopin from './FooterPopin';

interface PopinProps {
	children: ReactNode;
	headerTitle: string;
	buttonLabel?: string;
	isPopinOpen: boolean;
	closePopin: () => void;
	maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	fullWidth: boolean;
	iconButton?: JSX.Element;
	isDisplayHeader?: boolean;
	bottomFooter?: ReactNode;
	isButtonFooterClosePopin?: boolean;
}

const Popin = ({
	children,
	isPopinOpen,
	closePopin,
	headerTitle,
	buttonLabel,
	maxWidth,
	fullWidth,
	iconButton,
	isDisplayHeader,
	bottomFooter,
	isButtonFooterClosePopin,
}: PopinProps): JSX.Element => (
	<Dialog onClose={closePopin} open={isPopinOpen} maxWidth={maxWidth} fullWidth={fullWidth}>
		{isDisplayHeader && (
			<>
				<HeaderPopin closePopin={closePopin} headerTitle={headerTitle} />
			</>
		)}
		<MuiDialogContent dividers style={{ padding: 0 }}>
			{children}
		</MuiDialogContent>
		{buttonLabel && (
			<FooterPopin
				buttonLabel={buttonLabel}
				iconButton={iconButton}
				isButtonFooterClosePopin={isButtonFooterClosePopin}
				closePopin={closePopin}
			/>
		)}
		{bottomFooter}
	</Dialog>
);

export default Popin;
