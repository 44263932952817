import React from 'react';
import { InputBase, InputBaseProps, Paper, SvgIconTypeMap } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			padding: '2px 4px',
			display: 'flex',
			alignItems: 'center',
			borderRadius: '20px',
		},
	})
);

interface SearchBarProps extends InputBaseProps {
	iconColor?: SvgIconTypeMap['props']['color'];
	iconFontSize?: SvgIconTypeMap['props']['fontSize'];
	isForm?: boolean;
}

const SearchBar = ({ iconFontSize, iconColor, isForm, ...inputBaseProps }: SearchBarProps): JSX.Element => {
	const classes = useStyles();

	return (
		<Paper className={classes.root}>
			<InputBase
				startAdornment={<SearchOutlinedIcon color={iconColor} fontSize={iconFontSize} />}
				{...inputBaseProps}
			/>
		</Paper>
	);
};

export default SearchBar;
