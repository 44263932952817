import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import colors from '../../../../app/theme/colors.scss';
import { ItemCellSupplierPriceProps } from '../../../definition';

export const useStylesOrderItemSupplierPrices = makeStyles(() =>
	createStyles({
		root: {
			width: ({ widthRoot }: ItemCellSupplierPriceProps) => widthRoot,
			margin: ({ isMarginRoot }: ItemCellSupplierPriceProps) => (isMarginRoot ? '4px 2px' : 0),
		},
		cell: {
			width: '100%',
			margin: '10px 0',
			height: '48px',
			display: 'flex',
			backgroundColor: colors.white,
			alignAItems: 'center',
			padding: ({ padding }: ItemCellSupplierPriceProps) => padding,
			borderTopLeftRadius: ({ index }: ItemCellSupplierPriceProps) => (index === 0 ? '3px' : 'none'),
			borderBottomLeftRadius: ({ index }: ItemCellSupplierPriceProps) => (index === 0 ? '3px' : 'none'),
			borderLeft: ({ index, borderColor }: ItemCellSupplierPriceProps) =>
				index === 0 ? `3px solid ${borderColor}` : 'none',
		},
	})
);

export const useStylesSupplierPrices = makeStyles(() =>
	createStyles({
		root: {
			margin: '10px 0',
		},
		listSupplier: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		listSupplierPrice: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			paddingRight: 40,
		},
		wrapperCollapse: {
			marginTop: '-10px',
			backgroundColor: colors.blue200,
		},
		collapse: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		wrapperSuppliersPrices: {
			width: '60%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		totalWithRfa: {
			width: '44%',
		},
		summaryCell: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			paddingTop: '20px',
			marginRight: '50px',
		},
	})
);

export const useStylesTransportColis = makeStyles(() =>
	createStyles({
		boxWrapper: {
			width: '90%',
			margin: '10px auto',
		},
		wrapperTransport: {
			width: '100%',
			height: 'auto',
			backgroundColor: colors.white,
			borderRadius: '5px',
		},
		header: {
			width: '100%',
			height: '40px',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			backgroundColor: colors.grayCard,
		},
		iconWrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		icon: {
			backgroundColor: colors.gray300,
		},
	})
);
