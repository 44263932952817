import React, { useMemo } from 'react';
import { Column, useRowSelect, useTable } from 'react-table';
import { getEntityTitle } from './helpers';
import BillableCellField from '../fields/BillableCellField';
import EntityTitleCellField from '../fields/EntityTitleCellField';
import { ClientStructure } from '../../../../graphql/types';
import { Typography } from '@mui/material';

const useClientStructuresTable = (structures: ClientStructure[]) => {
	const columns = useMemo<Column<ClientStructure>[]>(
		() => [
			{
				accessor: 'level',
				Header: 'Rang',
				Cell: ({ value }) => <Typography>{value + 1}</Typography>,
			},
			{
				accessor: 'type',
				Header: 'Structure',
				Cell: ({ row }) => <Typography>{getEntityTitle({ value: row.original.type })}</Typography>,
			},
			{
				accessor: 'title',
				Header: 'Nom de substitution',
				Cell: EntityTitleCellField,
			},
			{
				accessor: 'isBillable',
				Header: 'Facturable',
				Cell: BillableCellField,
			},
		],
		[]
	);

	return useTable(
		{
			columns,
			data: structures,
			autoResetSelectedRows: false,
			getRowId: ({ type }) => type,
		},
		useRowSelect
	);
};

export default useClientStructuresTable;
