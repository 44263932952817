import React from 'react';
import { MarginType } from '../../../../graphql/types';
import { MenuItem } from '@mui/material';
import FormikSelectField from '../../../../components/form/FormikSelectField';

interface FormikSelectAdditionalMarginFieldProps {
	name: string;
	disabled?: boolean;
	testid?: string;
}

export const FormikSelectAdditionalMarginField = ({
	name,
	disabled = false,
	testid,
}: FormikSelectAdditionalMarginFieldProps): JSX.Element => {
	return (
		<FormikSelectField testid={testid} disabled={disabled} name={name} variant="outlined">
			<MenuItem value={MarginType.Percent} key={MarginType.Percent}>
				En %
			</MenuItem>
			<MenuItem value={MarginType.Euro} key={MarginType.Euro}>
				En Euro
			</MenuItem>
		</FormikSelectField>
	);
};
