import * as XLSX from 'xlsx';

export default function ExportToXlsx(dataTable: { [key: string]: any[] }, fileName: string) {
	const newDataTable = dataTable[Object.keys(dataTable)[0]].slice(1).map((elm) => {
		let obj: any = {};

		for (let i = 0; i < elm.length; i++) {
			let elmValue: any = dataTable[Object.keys(dataTable)[0]][0][i].value;
			obj[elmValue] = elm[i].value;
		}
		return obj;
	});

	const ws = XLSX.utils.json_to_sheet(newDataTable);
	const wb: XLSX.WorkBook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, 'Feuil1');
	XLSX.writeFile(wb, fileName + '.xlsx');
}
