import { Box } from '@mui/material';
import React from 'react';
import Loading from '../components/loading/Loading';
import { PATH_NAMES } from '../Header/menus';
import { OrderFormModel } from './form/order-cockpit/definition';
import useOrderByIdQuery from './hooks/useOrderByIdQuery';
import { OrderStatus } from '../graphql/types';
import OrderCockpitForm from './form/OrderCockpitForm';
import { useSnackbar } from 'notistack';
import { useUpsertCockpitOrder } from './hooks/useUpsertCockpitOrder';

export const orderStatus = [
	{ key: OrderStatus.InProgress, value: 'En cours' },
	{ key: OrderStatus.Delivered, value: 'Livrée' },
	{ key: OrderStatus.Canceled, value: 'Annulée' },
];

const OrderCockpitDetail = ({ orderId }: { orderId: string }): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { order, loading } = useOrderByIdQuery(orderId);
	const { upsertCockpitOrder } = useUpsertCockpitOrder();


	const orderStatusManagement = (key: OrderStatus) => {
		switch (order?.followStatus) {
			case 'ProductionInProgress':
			case 'Billed':
			case 'Validated':
				return OrderStatus.InProgress === key
			case 'Delivered':
			case 'DeliveryInProgress':
				return OrderStatus.Delivered === key
			default:
				return OrderStatus.Canceled === key
		}
	}

	const path = [{name: PATH_NAMES.orderCockpit, href: '/orderCockpit'},
		          orderStatus.find((status) => orderStatusManagement(status.key))?.value,
		          order?.id
	];
	const onSubmit = async (order: OrderFormModel, invoiceFile: Record<string, File> | null) => {
		try {
			await upsertCockpitOrder(order, invoiceFile);
			enqueueSnackbar('Modifications enregistrées', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Erreur', { variant: 'error' });
		}
	};

	if (!order || loading) return <Loading loading={loading} />;

	return (
		<Box mx={7}>
			<OrderCockpitForm onSubmit={onSubmit} order={order} paths={path} />
		</Box>
	);
};

export default OrderCockpitDetail;
