import { useMutation } from '@apollo/client';
import { InvoiceStatus } from 'graphql/types';
import { VALIDATE_INVOICE } from 'invoice/graphql/mutations';
import { ValidateInvoiceMutation, ValidateInvoiceMutationVariables } from 'invoice/graphql/mutations.definitions';
import { GET_INVOICES } from 'invoice/graphql/queries';
import { typeNotifications } from 'utils/definition';

export const useValidateInvoice = () => {
	const [validateInvoiceMutation] = useMutation<ValidateInvoiceMutation, ValidateInvoiceMutationVariables>(
		VALIDATE_INVOICE
	);

	const validateInvoice = async (
		invoiceId: ValidateInvoiceMutationVariables['invoice']['id'],
		orderItems: ValidateInvoiceMutationVariables['invoice']['orderItems']
	) => {
		const { data, errors } = await validateInvoiceMutation({
			variables: {
				invoice: { id: invoiceId, orderItems },
			},
			refetchQueries: [
				{ query: GET_INVOICES, variables: { search: {}, sort: {}, filter: { status: InvoiceStatus.ToValidate } } },
				{ query: GET_INVOICES, variables: { search: {}, sort: {}, filter: { status: InvoiceStatus.ToCount } } },
			],
		});
		if (errors) throw new Error(typeNotifications.Error);
		return data?.validateInvoice;
	};
	return { validateInvoice };
};
