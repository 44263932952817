import React, { useMemo } from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import { usePriceSummaryTable } from '../hooks/usePriceSummaryTable';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { AdditionalMarginsInput, Margins, MarginsInput } from '../../../../graphql/types';
import { getInitialSupplierPriceForTable } from '../../helpers';
import { SummaryPrice } from '../../../../components/summaryPrice/SummaryPrice';
import { Grid, Typography } from '@mui/material';
import colors from '../../../../app/theme/colors.scss';
import { useField } from 'formik';
import { getProductTotalPrices } from '../../../helpers';
import { SupplierPriceFormModel } from 'supplier/definitions';

interface PriceSummaryProps {
	supplierPricesLoading: boolean;
	supplierPrices?: SupplierPriceFormModel[];
	disabled?: boolean;
}

export const PriceSummary = ({
	supplierPrices,
	supplierPricesLoading,
	disabled = false,
}: PriceSummaryProps): JSX.Element => {
	const [defaultCommercialProductMarginField] = useField<number>('defaultCommercialProductMargin');
	const [buyingPriceWithAmalgamField] = useField<number>('buyingPriceWithAmalgam');
	const [additionalMarginsField] = useField<AdditionalMarginsInput>('additionalMargins');
	const [marginsField] = useField<MarginsInput>('margins');
	const [numberPlateChangesField] = useField<number>('numberPlateChanges');
	const [numberFileProcessingField] = useField<number>('numberFileProcessing');
	const [numberShotsField] = useField<number>('numberShots');
	const [supplierRfaField] = useField<number | undefined>('supplierPrices[0].rfa');
	const [toolsField] = useField<boolean>('tools');
	const [mainRfaRateField] = useField<number | undefined>('mainRfaRate');
	const [isWithRfaClientField] = useField<boolean>('isWithRfaClient');
	const [{ value: purchasePriceExcludingTax }] = useField<Margins>('purchasePriceExcludingTax');
	const [sellingPriceWithMargin, ,] = useField<MarginsInput>('sellingPriceWithMargin');

	const initialSupplierPrices = useMemo(
		() =>
			getInitialSupplierPriceForTable(
				defaultCommercialProductMarginField.value,
				buyingPriceWithAmalgamField.value,
				additionalMarginsField.value,
				marginsField.value,
				purchasePriceExcludingTax,
				sellingPriceWithMargin.value,
				supplierPrices
			),
		[
			additionalMarginsField.value,
			buyingPriceWithAmalgamField.value,
			defaultCommercialProductMarginField.value,
			marginsField.value,
			supplierPrices,
			purchasePriceExcludingTax,
			sellingPriceWithMargin.value,
		]
	);
	const [quantity] = useField<number>('quantity');
	const tableInstance = usePriceSummaryTable(initialSupplierPrices, disabled);

	const HORIZONTAL_PRODUCT_DATA_CELL = useMemo(
		() =>
			getProductTotalPrices(
				numberPlateChangesField.value,
				numberFileProcessingField.value,
				numberShotsField.value,
				toolsField.value,
				buyingPriceWithAmalgamField.value,
				supplierRfaField.value ?? 0,
				initialSupplierPrices[5],
				mainRfaRateField.value,
				isWithRfaClientField.value,
				supplierPrices?.[0]
			).map((product) => ({
				...product,
				isNotDisabled: !disabled,
			})),
		[
			buyingPriceWithAmalgamField.value,
			disabled,
			initialSupplierPrices,
			isWithRfaClientField.value,
			mainRfaRateField.value,
			numberPlateChangesField.value,
			numberShotsField.value,
			numberFileProcessingField.value,
			supplierPrices,
			supplierRfaField.value,
			toolsField.value,
		]
	);

	return (
		<Grid container>
			<Grid xs={12} item>
				<DashedHeader>Répartitions des prix</DashedHeader>
				{supplierPrices?.length === 0 && quantity.value > 1 && !supplierPricesLoading && (
					<Typography variant="h3" color="error" style={{ marginBottom: '15px' }}>
						Aucun fournisseur n'est en capacité de réaliser ce produit !
					</Typography>
				)}
			</Grid>
			<Grid xs={12} item>
				<AppListTable tableInstance={tableInstance} />
			</Grid>
			<Grid xs={12} item>
				<SummaryPrice
					padding={1}
					bgcolor={colors.blue200}
					horizontalDataCell={HORIZONTAL_PRODUCT_DATA_CELL}
					verticalDataCell={[]}
					supplierPrices={supplierPrices && supplierPrices[0]}
				/>
			</Grid>
		</Grid>
	);
};
