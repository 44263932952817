import React from 'react';
import { PrintOutlined, SearchOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import ImportInvoice from './component/ImportInvoice';
import { useField } from 'formik';
import usePopin from '../../../../hooks/usePopin';
import { usePrintFile } from '../../../../hooks/usePrintFile';
import { PreviewFiles } from './component/PreviewFiles';
import { Row } from 'react-table';
import { ClientInvoicesTable } from './ClientInvoices';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

import { useSnackbar } from 'notistack';
import { InvoiceStatus } from 'graphql/types';
import { typeNotifications } from 'utils/definition';
import { useInvoiceUpsert } from 'invoice/hooks/useInvoiceUpsert';

interface ManagementInvoiceProps {
	row: Row<ClientInvoicesTable>;
}

export const ManagementInvoice = ({ row }: ManagementInvoiceProps) => {
	const invoiceName = `invoiceFile.${row.original.invoiceId}`;
	const { enqueueSnackbar } = useSnackbar();

	/**  	@TODO redirection vers la prefacturation a voir après ou est que on mit la generation pdf*/

	//	const { generateOrderInvoicePdf } = useGenerateOrderInvoicePdf();

	const { upsertInvoice } = useInvoiceUpsert();
	const [createdAtField, , createdAtHelpers] = useField<Date | null | undefined>(
		`childrenOrder[${row.id}].invoices[0].createdAt`
	);
	const [, , statustHelpers] = useField<InvoiceStatus>(`childrenOrder[${row.id}].invoices[0].status`);

	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Aperçu facture',
		maxWidth: 'md',
		fullWidth: true,
	});
	/**  	@TODO redirection vers la prefacturation a voir après ou est que on mit la generation pdf */

	/* const generateAPdfInvoice = async (orderId: string, invoiceId?: string | null, parentOrderId?: string) => {
		try {
			await generateOrderInvoicePdf(orderId, invoiceId, parentOrderId);
		} catch (error) {
			enqueueSnackbar(error.message, { variant: error });
		}
	}; */

	const handleUpsertInvoice = async (orderId: string, invoiceId?: string | null) => {
		createdAtHelpers.setValue(new Date());
		statustHelpers.setValue(InvoiceStatus.ToValidate);

		try {
			await upsertInvoice({ orderId, id: invoiceId ?? '', isExternal: false });
			enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
		} catch (error) {
			enqueueSnackbar("erreur d'enrigistrement", { variant: typeNotifications.Error });
		}
	};

	const [handlePrintFile, renderingComponentToPrint] = usePrintFile({
		file: row.original.url ?? '',
	});

	const isPreviewInvoice = !!row.original.url?.length;

	if (!renderingComponentToPrint) return null;

	return (
		<>
			{renderingComponentToPrint()}
			{row.original.url?.length && renderPopin(<PreviewFiles file={row.original.url} />)}
			<Box display="flex" justifyContent="space-around" alignItems="center">
				<ImportInvoice accept="application/pdf" name={invoiceName} row={row} />
				<IconButton component="span" onClick={handlePrintFile} disabled={!row.original.url?.length}>
					<PrintOutlined fontSize="small" />
				</IconButton>
				<IconButton
					disabled={!row.original.url?.length}
					component="span"
					onClick={() => (isPreviewInvoice ? openPopin() : closePopin())}
				>
					<SearchOutlined fontSize="small" />
				</IconButton>
				{/**	@TODO redirection vers la prefacturation a voir apres ou est que on mit la generation pdf*/}
				{/*
                <IconButton component="span" onClick={() => generateAPdfInvoice(row.original.id, row.original.invoiceId)}> */}
				<IconButton
					component="span"
					onClick={() => handleUpsertInvoice(row.original.id, row.original.invoiceId)}
					disabled={!!createdAtField.value}
					size="large"
				>
					<PictureAsPdfOutlinedIcon fontSize="small" />
				</IconButton>
			</Box>
		</>
	);
};
