import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import downloadFile from '../../utils/downloadFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Box from '@mui/material/Box';
import { isString } from '../../utils/typeGuards';

type DocumentDownloadType = {
	documentUrl?: string | string[] | null;
	documentName?: string | null;
	label: string;
};

export const DocumentDownloadButton = ({ documents }: { documents: DocumentDownloadType[] }) => {
	const handleDownload = useCallback(async (url: string, name?: string | null) => {
		await downloadFile(url, 'application/pdf', name ?? undefined);
	}, []);

	const downloadManager = useCallback(
		async (documentUrl?: string | string[] | null, documentName?: string | null) => {
			if (documentUrl) {
				if (isString(documentUrl)) {
					await handleDownload(documentUrl, documentName);
				} else {
					for (let i = 0; i < documentUrl.length; i++) {
						await handleDownload(documentUrl[i], documentName);
					}
				}
			} else
				await handleDownload(
					'https://image.shutterstock.com/image-vector/empty-placeholder-image-icon-design-260nw-1366372628.jpg'
				);
		},
		[handleDownload]
	);

	return (
		<Box display="flex" justifyContent="flex-end" padding={3}>
			{documents.map(({ documentUrl, documentName, label }) => (
				<Button
					variant="contained"
					color="primary"
					disabled={!documentUrl}
					onClick={async () => await downloadManager(documentUrl, documentName)}
				>
					<CloudDownloadIcon />
					{label}
				</Button>
			))}
		</Box>
	);
};
