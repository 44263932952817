import React from 'react';
import { Tab } from '../hooks/useManualTabs';
import { Client, ClientQuoteStatus } from '../graphql/types';

export const TAB_MENU_CLIENT_QUOTE: Tab[] = [
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations générales',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations client',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Ajout d’un prix de vente au devis',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Livraison',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Devis',
	},
];

export interface ClientQuote {
	versionNumber: number;
	status: string;
	productId: string;
	quantity: string;
}

export const initialClientQuote: ClientQuote = {
	versionNumber: 1,
	status: 'En cours',
	productId: '',
	quantity: '0',
};

export enum ClientQuoteStatusType {
	InProgress = 'En cours',
	Validated = 'Validé par le client',
	Waiting = 'En attente de validation client',
	Ready = 'Bon pour commande',
	Canceled = 'Annulé',
}

export const clientQuoteStatus = [
	{ key: 'InProgress', value: 'En cours' },
	{ key: 'Waiting', value: 'En attente de validation client' },
	{ key: 'Validated', value: 'Validé par le client' },
	{ key: 'Ready', value: 'Bon pour commande' },
	{ key: 'Canceled', value: 'Annulé' },
];

export enum DataQuotteStatus {
	inclus = 'inclus',
	notInclus = 'non inclus',
	notApplicable = 'non applicable',
}

export type HandleSelectClient = (value: Pick<Client, 'name' | 'id'> | null) => void;

export const OPTIONS_CLIENT_PRICE_TO_BE_VALIDATE = [
	{ name: 'En attente de validation client', value: ClientQuoteStatus.Waiting },
	{ name: 'Validé par client', value: ClientQuoteStatus.Validated },
	{ name: 'Annulé', value: ClientQuoteStatus.Canceled },
];

export const OPTIONS_CLIENT_ORDER_TO_BE_CREATE = [
	{ name: 'En attente de validation client', value: ClientQuoteStatus.Waiting },
	{ name: 'Bon pour commande', value: ClientQuoteStatus.Ready },
	{ name: 'Annulé', value: ClientQuoteStatus.Canceled },
];

export enum ClientQuoteStatusTrans {
	InProgress = 'En cours',
	Validated = 'Validé',
	Waiting = 'En Attante',
	Ready = 'Prêt',
	Canceled = 'Annulé',
}

export const getStatusValueFilter = (status: ClientQuoteStatusTrans) => {
	switch (status) {
		case ClientQuoteStatusTrans.InProgress:
			return ClientQuoteStatus.InProgress;
		case ClientQuoteStatusTrans.Validated:
			return ClientQuoteStatus.Validated;
		case ClientQuoteStatusTrans.Waiting:
			return ClientQuoteStatus.Waiting;
		case ClientQuoteStatusTrans.Canceled:
			return ClientQuoteStatus.Canceled;
		case ClientQuoteStatusTrans.Ready:
			return ClientQuoteStatus.Ready;
		default:
			return undefined;
	}
};
