import * as React from 'react';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import { Box, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import colors from '../../app/theme/colors.scss';

type FormikAutocompleteFieldProps<T extends { [K in keyof T]: T[K] }> = {
	textField?: TextFieldProps;
	name?: string;
	loading?: boolean;
	testid?: string;
} & Omit<AutocompleteProps<T, false, any, true>, 'renderInput'>;

const FormikAutocompleteField = <T extends { [P in keyof T]: T[P] }>({
	name,
	textField,
	loading,
	...props
}: FormikAutocompleteFieldProps<T>) => {
	return (
		<Autocomplete
			{...props}
			size="small"
			options={props.options}
			loading={loading}
			loadingText="Chargement en cours..."
			style={{
				cursor: props.disabled ? 'default' : 'pointer',
			}}
			renderInput={(paramsInput) => (
				<Box bgcolor={props?.disabled ? colors.grey : colors.white}>
					<TextField
						name={name}
						variant="outlined"
						{...paramsInput}
						{...textField}
						InputProps={{
							...paramsInput.InputProps,
							endAdornment: !props?.disabled && (
								<>
									{loading && <CircularProgress color="inherit" size={20} />}
									{paramsInput.InputProps.endAdornment}
								</>
							),
						}}
					/>
				</Box>
			)}
			data-testid={props.testid}
		/>
	);
};

export default FormikAutocompleteField;
