import { useQuery } from '@apollo/client';
import { GET_ROLES } from '../graphql/queries';
import { GetRolesQuery, GetRolesQueryVariables } from '../graphql/queries.definitions';

const useRolesQuery = () => {
	const { data } = useQuery<GetRolesQuery, GetRolesQueryVariables>(GET_ROLES);

	return { roles: data?.roles };
};

export default useRolesQuery;
