import React from 'react';
import { LayoutField } from '../../../../components/form/LayoutField';
import { useField } from 'formik';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { categories } from './definitions';
import TableRowPredefinedQuantity from './TableRowPredefinedQuantity';
import { FormikNumberField } from '../../../../components/form/FormikNumberField';

const Configuration = (): JSX.Element => {
	const [fieldCategory, , helpersCategory] = useField<string | undefined>('category');
	const [fieldSubCategory, , helpersSubCategory] = useField<string | undefined>('subCategory');

	const handleChangeCategory = (event: SelectChangeEvent<string>) => {
		helpersCategory.setValue(event.target.value as string);
	};

	const handleChangeSubCategory = (event: SelectChangeEvent<string>) => {
		helpersSubCategory.setValue(event.target.value as string);
	};

	return (
		<Box pt={10}>
			<Typography variant="h2">Configuration / Définition</Typography>
			<Box width={500} mb={3} mt={3}>
				<LayoutField label="Catégorie BigShop">
					<FormControl fullWidth>
						<Select value={fieldCategory.value ?? ''} variant="outlined" onChange={handleChangeCategory}>
							{categories?.map((option) => (
								<MenuItem key={option.key} value={option.key}>
									{option.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</LayoutField>
			</Box>
			<Box width={500} mb={3}>
				<LayoutField label="Sous-catégorie BigShop">
					<FormControl fullWidth>
						<Select value={fieldSubCategory.value ?? ''} variant="outlined" onChange={handleChangeSubCategory}>
							{fieldCategory.value &&
								categories
									?.find(({ key }) => key === fieldCategory?.value)
									?.subCategory?.map((option) => (
										<MenuItem key={option.key} value={option.key}>
											{option.value}
										</MenuItem>
									))}
						</Select>
					</FormControl>
				</LayoutField>
			</Box>
			<LayoutField label="Quantité d'appel BigShop/Thématiques">
				<Box width={150}>
					<FormikNumberField decimalScale={0} name="callQuantity" width="150px" variant="outlined" />
				</Box>
			</LayoutField>

			<Box mt={3}>
				<LayoutField label="Quantités prédéfinies BigShop/Thématiques">
					<Box width={150}>
						<TableRowPredefinedQuantity name={'predefinedQuantity1'} />
						<TableRowPredefinedQuantity name={'predefinedQuantity2'} />
						<TableRowPredefinedQuantity name={'predefinedQuantity3'} />
						<TableRowPredefinedQuantity name={'predefinedQuantity4'} />
						<TableRowPredefinedQuantity name={'predefinedQuantity5'} />
					</Box>
				</LayoutField>
			</Box>
		</Box>
	);
};

export default Configuration;
