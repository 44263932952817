import { GetFileReferencesQuery } from './graphql/queries.definitions';

export enum FileReferenceStatusTrans {
	InStudy = 'En étude',
	InProgress = 'En progress',
	InProduction = 'En production',
	Delivered = 'Livré',
	Canceled = 'Annulée',
}

export type FileReferenceFromModel = GetFileReferencesQuery['fileReferences']['fileReferences'][0] & {
	clientName?: string;
	userName?: string;
};
