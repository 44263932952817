import { useMutation } from '@apollo/client';
import { omit } from 'ramda';
import {
	CreateClientQuoteConfigMutation,
	CreateClientQuoteConfigMutationVariables,
} from '../graphql/mutations.definitions';
import { CREATE_CLIENT_QUOTE_CONFIG } from '../graphql/mutations';
import { GET_CONFIG_QUOTE } from '../graphql/queries';
import { GET_CLIENTS_BY_CURRENT_USER } from '../../clients/graphql/queries';

export type clientQuoteConfigMutationModel = CreateClientQuoteConfigMutationVariables['clientQuoteConfig'];

export const useClientQuoteConfigCreate = () => {
	const [createClientQuoteMutation] = useMutation<
		CreateClientQuoteConfigMutation,
		CreateClientQuoteConfigMutationVariables
	>(CREATE_CLIENT_QUOTE_CONFIG);
	const createClientQuoteConfig = async (clientQuoteConfig: clientQuoteConfigMutationModel) => {
		const { data } = await createClientQuoteMutation({
			variables: {
				clientQuoteConfig: {
					data: clientQuoteConfig.data,
					quoteClient: clientQuoteConfig.quoteClient ? omit(['__typename'], clientQuoteConfig.quoteClient) : null,
				},
			},
			refetchQueries: [
				{
					query: GET_CONFIG_QUOTE,
					variables: {
						clientId: clientQuoteConfig.data[0].clientId,
					},
				},
				{
					query: GET_CLIENTS_BY_CURRENT_USER,
					variables: {
						hasChildren: true,
					},
				},
			],
		});

		return {
			clientQuoteConfig: data?.createClientQuoteConfig.quoteConfig,
			quoteClient: data?.createClientQuoteConfig.quoteClient,
		};
	};
	return { createClientQuoteConfig };
};
