import { Box, Grid, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import FormikSelectField from '../../../components/form/FormikSelectField';
import { LayoutField } from '../../../components/form/LayoutField';
import { UserCreateInput } from '../../../graphql/types';
import useUsersQuery from '../hooks/useUsersQuery';
import colors from '../../../app/theme/colors.scss';
import { UncontrolledDatePicker } from '../../../components/picker/UncontrolledDatePicker';

const useStyles = makeStyles(() =>
	createStyles({
		textField: {
			backgroundColor: colors.white,
			'& .MuiFormHelperText-root.Mui-error ': {
				background: colors.bodyBackgroundLight,
				margin: 0,
			},
		},
	})
);

interface ReplacedBySubFormProps {
	userId: string;
}

const ReplacedBySubForm = ({ userId }: ReplacedBySubFormProps): JSX.Element => {
	const classes = useStyles();
	const {
		values: { ...userCreateInput },
		setFieldValue,
	} = useFormikContext<UserCreateInput>();

	const { users } = useUsersQuery();
	const handleValidFromChange = (date: unknown) => {
		setFieldValue('replacedFrom', date as Date);
	};

	const handleValidToChange = (date: unknown) => {
		setFieldValue('replacedTo', date as Date);
	};

	return (
		<Box>
			<Box px={2}>
				<Grid container justifyContent="space-between" spacing={2}>
					<Grid item xs={3}>
						<Grid item xs={12}>
							<LayoutField label="Date de début d’absence">
								<UncontrolledDatePicker
									name="replacedFrom"
									className={classes.textField}
									/*	placeholder="Début d’absence"*/
									/*maxDateMessage={<>Date non valide</>}
									minDateMessage={<>Date non valide</>}
									invalidDateMessage={<>Date non valide</>}*
									fullWidth
									margin="none"
									disablePast*/
									disabled
									value={userCreateInput?.replacedFrom}
									maxDate={userCreateInput?.replacedTo ?? undefined}
									handleClear={() => setFieldValue('replacedFrom', null)}
									onChange={handleValidFromChange}
								/>
							</LayoutField>
							<ErrorMessage name="replacedFrom">{(msg) => <Box style={{ color: 'red' }}>{msg}</Box>}</ErrorMessage>
						</Grid>

						<Grid item xs={12}>
							<Box mt={2}>
								<LayoutField label="Date de retour d’absence">
									<UncontrolledDatePicker
										className={classes.textField}
										/*placeholder="Fin d’absence"
										maxDateMessage={<>Date non valide</>}
										minDateMessage={<>Date non valide</>}
										invalidDateMessage={<>Date non valide</>}
										fullWidth
										margin="none"*/
										value={userCreateInput?.replacedTo}
										minDate={userCreateInput?.replacedFrom ?? moment()}
										handleClear={() => setFieldValue('replacedTo', null)}
										onChange={handleValidToChange}
										name="replacedTo"
									/>
								</LayoutField>
								<ErrorMessage name="replacedTo">{(msg) => <Box style={{ color: 'red' }}>{msg}</Box>}</ErrorMessage>
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={7}>
						<Grid item xs={8}>
							<LayoutField label="Cet utilisateur sera remplacé par">
								<Box className={classes.textField}>
									<FormikSelectField name="replacedById" variant="outlined" style={{ width: '100%' }}>
										{users &&
											users
												?.filter(({ id }) => id !== userId)
												?.map((option) => (
													<MenuItem key={option.id} value={option.id}>
														{`${option.givenName} ${option.familyName}`}
													</MenuItem>
												))}
									</FormikSelectField>
								</Box>
								<ErrorMessage name="replacedById">{(msg) => <Box style={{ color: 'red' }}>{msg}</Box>}</ErrorMessage>
								<Typography variant="inherit" align="center" color="textSecondary">
									En cas de remplacement l'utilisateur indiqué récupère tous les droits et notifications de
									l'utilisateur absent
								</Typography>
							</LayoutField>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ReplacedBySubForm;
