import React, { useState } from 'react';
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';
import { OrderItem, Product } from 'graphql/types';

import { LayoutField } from 'components/form/LayoutField';
import { FormikSwitchField } from 'components/form/FormikSwitchField';
import { initialOrderItem, OrderFormModel } from '../order-cockpit/definition';
import DatesAndCommentsProductInformation from '../order-cockpit/components/DatesAndCommentsProductInformation';
import FormikTextField from 'components/form/FormikTextField';

import SelectProductName from '../order-cockpit/product-information/SelectProductName';
import SelectProductQuantity from '../order-cockpit/product-information/SelectProductQuantity';
import {
	ProductInformationProvider,
	useProductInformation,
} from '../order-cockpit/product-information/ProductInformationContext';
import SelectProductVersionNumber from '../order-cockpit/product-information/SelectProductVersionNumber';
import { ComponentTitle } from '../../definition';
import { useQueryLazyProvider } from '../../../hooks/useProvider';
import {
	GetTransportersPricesForOrderItemQuery,
	GetTransportersPricesForOrderItemQueryVariables,
} from '../../graphql/queries.definitions';
import { GET_TRANSPORTERS_PRICES_FOR_ORDER_ITEM } from '../../graphql/queries';
import { FormikNumberField } from 'components/form/FormikNumberField';

const ProductInformation = ({ title, setIsDisabled }: ComponentTitle) => {
	const [isAddingProductLoading, setIsAddingProductLoading] = useState<boolean>(false);

	const { clientQuote } = useProductInformation();

	const { setFieldValue, values } = useFormikContext<
		OrderFormModel & {
			newOrderItem: OrderItem;
			products: Product[];
			clientQuote: { id: string; version: number };
		}
	>();
	const [getTransportersPrice] = useQueryLazyProvider<
		GetTransportersPricesForOrderItemQuery,
		GetTransportersPricesForOrderItemQueryVariables
	>(GET_TRANSPORTERS_PRICES_FOR_ORDER_ITEM, {
		onCompleted: (data) => {
			if (data.transportersPrices) {
				const bestTransporter = [...data.transportersPrices].reduce<
					GetTransportersPricesForOrderItemQuery['transportersPrices']
				>((acc, current) => {
					if (
						!acc.length ||
						(acc[0].transporterPurchasePriceExcludingVAT >= current.transporterPurchasePriceExcludingVAT &&
							current.transporterPurchasePriceExcludingVAT !== 0)
					) {
						acc[0] = current;
					}
					return acc;
				}, [])[0];
				setFieldValue('orderItem', [
					{
						...values.newOrderItem,
						transportersPrices: data.transportersPrices,
						bestTransporter: bestTransporter,
					},
					...values.orderItem,
				]);
				setFieldValue('newOrderItem', initialOrderItem);
			}
			setIsAddingProductLoading(false);
		},
		onError: (e) => {
			setIsAddingProductLoading(false);
		},
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const handleAddProduct = () => {
		if (values.newOrderItem.productName?.length) {
			setIsAddingProductLoading(true);
			getTransportersPrice({
				variables: {
					options: {
						clientId: values.clientId,
						productId: values.newOrderItem.productId,
						numberVersion: values.newOrderItem.numberVersion ?? 1,
						quantity: values.newOrderItem.quantity,
						distributions:
							clientQuote?.clientQuoteDistributions
								?.filter((distribution) => distribution.productId === values.newOrderItem.productId)
								.map((item) => item.quantity) || [],
					},
				},
			});
		}
	};

	return (
		<Box mb={5} mt={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">{title}</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box mb={5}>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={4}>
						<ProductInformationProvider>
							<SelectProductName setIsDisabled={setIsDisabled} />
							<SelectProductQuantity />
							<SelectProductVersionNumber />
						</ProductInformationProvider>
					</Grid>
					<Grid item xs={3}>
						<LayoutField label="Nombre de changements de plaque">
							<Box width="164px">
								<FormikTextField
									type="number"
									fullWidth
									name="newOrderItem.numberPlateChanges"
									variant="outlined"
									inputProps={{ min: 0, max: 12 }}
									disabled
								/>
							</Box>
						</LayoutField>

						<Box mt={2}>
							<Box width="100%" display="flex">
								<LayoutField label="Nombre de traitements de fichiers">
									<Box width="164px" mt={-1}>
										<FormikTextField
											type="number"
											fullWidth
											name="newOrderItem.numberFileProcessing"
											variant="outlined"
											inputProps={{ min: 0, max: 12 }}
											disabled
										/>
									</Box>
								</LayoutField>
							</Box>
						</Box>

						<Box mt={2} display="flex" justifySelf="left">
							<LayoutField label="Poids">
								<Box width="83px">
									<FormikNumberField decimalScale={2} name="newOrderItem.productTotalWeight" disabled />
								</Box>
							</LayoutField>
							<Box mt={5} ml={2}>
								<Typography variant="body1">Kg</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={3}>
						<LayoutField label="Nombre de cliché">
							<Box width="164px">
								<FormikTextField
									type="number"
									fullWidth
									name="newOrderItem.numberShots"
									variant="outlined"
									inputProps={{ min: 0, max: 12 }}
									style={{ width: '164px' }}
									disabled
								/>
							</Box>
						</LayoutField>
						<Box mt={2}>
							<LayoutField label="Outillage">
								<FormikSwitchField name="newOrderItem.tools" disabled />
							</LayoutField>
						</Box>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={10}>
						<DatesAndCommentsProductInformation index={-1} />
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={10}>
						<Grid item xs={10}>
							<Box mt={4} textAlign="right">
								<Button
									variant="contained"
									color="primary"
									disabled={isAddingProductLoading}
									onClick={handleAddProduct}
								>
									Ajouter le produit à ma commande
									{isAddingProductLoading && (
										<CircularProgress
											style={{ marginLeft: 4 }}
											size={16}
											color="inherit"
											aria-label="circular-progress"
										/>
									)}
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ProductInformation;
