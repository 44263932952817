import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { ActionType } from 'react-table';
import produce from 'immer';
import useStorage from '../../hooks/useStorage';
import {
	ClientDataSelected,
	CurrentUserClientsFromModel,
	OptionsButtonType,
	ProductListModel,
	QuoteAttributeContextProp,
	QuotePreviewDataProp,
} from '../form/definition';
import useConfigQuotesQuery from '../hooks/useConfigQuoteQuery';
import { useClientQuoteConfigCreate } from '../hooks/useClientQuoteConfigCreate';
import { useField, useFormikContext } from 'formik';
import {
	ButtonType,
	ClientQuote,
	ClientQuoteFileType,
	ClientQuoteStatus,
	ClientQuoteTranslate,
	ElpevSite,
	QuoteAttributesConfig,
	QuoteClientModel,
} from '../../graphql/types';
import {
	getCheckedAttributes,
	getOnlyAttributesWithQuotePreviewValues,
	handleDataQuotePreview,
	setQuoteValuesToAttributes,
} from '../form/helpers';
import { typeNotifications } from '../../utils/definition';
import { useSnackbar } from 'notistack';
import { useGenerateQuoteFile } from 'client-quote/hooks/useGenerateQuoteFile';
import { useParams } from 'react-router-dom';

const QuoteAttributeReducer = (state: QuoteAttributesConfig[], { type, payload }: ActionType) => {
	switch (type) {
		case 'toggle_checkbox':
			return produce(
				state,
				(draft) =>
					void draft.map((quoteAttribute) => {
						if (payload.familiesGroup === quoteAttribute.familiesGroup) {
							quoteAttribute.familyAttributes = quoteAttribute.familyAttributes.map((attribute) => {
								attribute.characteristics = attribute.characteristics.map((characteristic) => {
									if (payload.attributeId === characteristic.attributeId) {
										characteristic.isChecked = !characteristic.isChecked;
									}
									return characteristic;
								});
								return attribute;
							});
						}
						return quoteAttribute;
					})
			);
		case 'choice_radio':
			return produce(
				state,
				(draft) =>
					void draft.map((quoteAttribute) => {
						quoteAttribute.familyAttributes = quoteAttribute.familyAttributes.map((attribute) => {
							attribute.characteristics = attribute.characteristics.map((characteristic) => {
								payload.forEach((option: { attributeId: string; isChecked: boolean; type: ButtonType }) => {
									if (option.attributeId === characteristic.attributeId && option.type === ButtonType.Radio)
										characteristic.isChecked = option.isChecked;
								});
								return characteristic;
							});
							return attribute;
						});
						return quoteAttribute;
					})
			);
		case 'reset_quote':
		case 'toggle_initial_quote':
			return payload;
		default:
			return state;
	}
};

export const QuoteAttributeContext = createContext<QuoteAttributeContextProp | undefined>(undefined);

const QuoteAttributeProvider = ({
	children,
	clientsUsers,
}: {
	children: ReactNode;
	clientsUsers?: CurrentUserClientsFromModel[];
}): JSX.Element => {
	const { id: quoteId } = useParams<{ id: string }>();

	const { setDataLocalStorage, getDataLocalStorage } = useStorage();
	const { enqueueSnackbar } = useSnackbar();
	const { handleGenerateFile } = useGenerateQuoteFile(quoteId);
	const { quoteAttributes, getQuoteAttributes } = useConfigQuotesQuery();
	const [quoteConfig, dispatch] = useReducer(QuoteAttributeReducer, []);
	const { createClientQuoteConfig } = useClientQuoteConfigCreate();
	const [field, , helpers] = useField<string>('clientId');
	const { values: clientQuote } = useFormikContext<ClientQuote & { products?: ProductListModel | null }>();
	const [clientDataSelected, setClientDataSelected] = useState<QuoteClientModel>({
		elpevSite: ElpevSite.Empty,
		translation: ClientQuoteTranslate.French,
	});
	const [clientQuoteData, setClientQuoteData] = useState<null | QuoteAttributesConfig[]>(null);
	const [clientUser, setClientUser] = useState<undefined | CurrentUserClientsFromModel>(undefined);
	const [quotePreviewData, setQuotePreviewData] = useState<QuotePreviewDataProp | undefined>(undefined);

	const currentUser = useMemo(() => {
		const user = getDataLocalStorage('currentUser');
		return user ? user : undefined;
	}, [getDataLocalStorage]);

	const handleCheckedAttributes = useCallback((familiesGroup: string, attributeId: string, family: string) => {
		dispatch({
			type: 'toggle_checkbox',
			payload: {
				familiesGroup,
				attributeId,
				family,
			},
		});
	}, []);

	const handleRadioAttributes = useCallback((value: string, options: OptionsButtonType[], type: ButtonType) => {
		dispatch({
			type: 'choice_radio',
			payload: options.map((option) => ({
				...option,
				type,
				isChecked: option.label.toLowerCase() === value.toLowerCase(),
			})),
		});
	}, []);

	const generateQuoteFile = useCallback(
		async (type: ClientQuoteFileType) => {
			const quoteAttributesPdf = {
				...quotePreviewData,
				quoteAttributes: quotePreviewData?.quoteAttributes
					?.map((quoteAttributeConfig) => {
						return setQuoteValuesToAttributes([quoteAttributeConfig], clientQuote);
					})
					.filter((_, i) => i < quotePreviewData?.quoteAttributes.length - 1),
			};

			await handleGenerateFile({
				type,
				dataQuote: JSON.stringify({
					...quoteAttributesPdf,
					clientQuoteId: quoteId,
					clientPriceByClientQuote: clientQuote.clientQuoteItem?.length
						? {
								status: ClientQuoteStatus.Waiting,
								clientQuoteItems: clientQuote.clientQuoteItem.map((clientQuote) => ({
									productId: clientQuote.productId,
									quantity: clientQuote.quantity,
									numberVersion: clientQuote.numberVersion,
								})),
								clientId: clientQuote?.clientId,
						  }
						: [],
				}),
			});
		},
		[handleGenerateFile, quoteId, quotePreviewData, clientQuote]
	);

	/**
	 * modify or keep the quote config of tht client
	 */
	const saveTheSettingsForTheClient = useCallback(async () => {
		try {
			const { quoteClient } = await createClientQuoteConfig({
				data: getCheckedAttributes(quoteConfig, clientUser?.id),
				quoteClient: clientDataSelected,
			});
			quoteClient && setClientDataSelected(quoteClient);
			enqueueSnackbar('Configurations enregistrées', { variant: typeNotifications.Success });
		} catch (error) {
			enqueueSnackbar("Erreur lors de l'enregistrement de la configuration", { variant: typeNotifications.Error });
		}
	}, [clientDataSelected, clientUser, createClientQuoteConfig, enqueueSnackbar, quoteConfig]);

	/**
	 * génère la prévisualisation de la config d'un client
	 */
	const generateClientQuote = useCallback(async () => {
		if (quoteConfig.length) {
			const quoteAttributes = getOnlyAttributesWithQuotePreviewValues(quoteConfig);
			if (clientQuote && clientQuote.clientQuoteItem) {
				setClientQuoteData(quoteAttributes);
				setQuotePreviewData(handleDataQuotePreview(clientsUsers, clientQuote, currentUser, quoteAttributes));
			}
		}
	}, [clientsUsers, currentUser, quoteConfig, clientQuote]);

	/**
	 * generates the preview of a client's config
	 */
	const selectClientDataQuote = useCallback(
		(data: Partial<ClientDataSelected>) => {
			setClientDataSelected({
				...clientDataSelected,
				...data,
			});
		},
		[clientDataSelected]
	);

	const getQuoteAttributesByClientId = useCallback(
		async (clientId: string) => {
			await getQuoteAttributes({
				variables: {
					clientId,
				},
			});
			setClientUser(
				[...(clientsUsers as CurrentUserClientsFromModel[])].filter((clientUser) => clientUser.id === clientId)[0]
			);
		},
		[clientsUsers, getQuoteAttributes]
	);

	const getQuoteDataSelectedClient = useCallback(
		async (clientId: string) => {
			await getQuoteAttributesByClientId(clientId);
			helpers.setValue(clientId);
		},
		[getQuoteAttributesByClientId, helpers]
	);

	const handleResetQuoteAttribute = useCallback(() => {
		const data = getDataLocalStorage('data');

		dispatch({
			type: 'reset_quote',
			payload: JSON.parse(data as string),
		});
	}, [getDataLocalStorage]);

	useEffect(() => {
		(async () => {
			if (field.value && !quoteConfig.length) {
				await getQuoteAttributesByClientId(field.value);
			}
		})();
	}, [field.value, getQuoteAttributesByClientId, quoteConfig]);

	useEffect(() => {
		if (clientUser && clientUser.elpevSite && clientUser.translation) {
			setClientDataSelected({
				elpevSite: clientUser.elpevSite,
				translation: clientUser.translation,
			});
		}
	}, [clientUser]);

	useEffect(() => {
		const data = getDataLocalStorage('data');
		if (quoteAttributes) {
			dispatch({
				type: 'toggle_initial_quote',
				payload: quoteAttributes,
			});
		}
		if (!data && quoteAttributes) {
			setDataLocalStorage('data', quoteAttributes);
		}
	}, [getDataLocalStorage, quoteAttributes, setDataLocalStorage]);

	const initialQuoteAttributes = {
		quoteConfig,
		clientsUsers,
		currentUser,
		quotePreviewData,
		clientDataSelected,
		clientQuoteData,
		clientUser,
		selectClientDataQuote,
		generateClientQuote,
		generateQuoteFile,
		getQuoteDataSelectedClient,
		handleCheckedAttributes,
		handleResetQuoteAttribute,
		saveTheSettingsForTheClient,
		handleRadioAttributes,
	};

	return <QuoteAttributeContext.Provider value={initialQuoteAttributes}>{children}</QuoteAttributeContext.Provider>;
};

export default QuoteAttributeProvider;
