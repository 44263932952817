import { useQuery } from '@apollo/client';
import { GET_ALL_DEPARTMENTS } from '../graphql/department/queries';

import { GetAllDepartmentsQuery, GetAllDepartmentsQueryVariables } from '../graphql/department/queries.definitions';
import { QueryFunctionOptions } from '@apollo/client/react/types/types';

const useDepartmentQuery = (onCompleted?: QueryFunctionOptions['onCompleted']) => {
	const { data, loading } = useQuery<GetAllDepartmentsQuery, GetAllDepartmentsQueryVariables>(GET_ALL_DEPARTMENTS, {
		onCompleted,
	});

	return { departments: data?.departments, loading };
};

export default useDepartmentQuery;
