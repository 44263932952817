import React from 'react';
import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { getStepContent, stepClientPriceQuote } from './definitions';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../app/theme/colors.scss';
import { useFormikContext } from 'formik';
import { clientQuoteItemPick, ClientQuoteModel, initialClientQuote } from '../definition';
import { pick } from 'ramda';
import addPercentageToValue from '../../../utils/addPercentageToValue';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '84%',
			margin: '0px auto',
		},
		stepper: {
			marginTop: 6,
			backgroundColor: 'transparent',
		},
		stepLabel: {
			fontSize: 12,
			color: colors.turquoise500,
			fontWeight: 'bold',
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
	})
);

export const AddingClientPriceQuote = () => {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const { values, setFieldValue } = useFormikContext<ClientQuoteModel>();

	const handleNext = () => {
		if (activeStep === 1) {
			setFieldValue('summaryPrice.vatAmount', (values.vatRate * (values.summaryPrice.cost ?? 0)) / 100);
			setFieldValue(
				'summaryPrice.salePrice',
				(values.vatRate * (values?.summaryPrice.cost ?? 0)) / 100 + (values.summaryPrice.cost ?? 0)
			);
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else if (activeStep === 3) {
			const clientQuoteItem = pick(clientQuoteItemPick, {
				...values.summaryPrice,
				sellingPriceIncludingTax: addPercentageToValue(values.vatRate, values?.summaryPrice.cost ?? 0),
				product: values.selectedProduct?.product,
			});

			setActiveStep(0);
			setFieldValue('clientQuoteItem', [
				{
					...clientQuoteItem,
					...(() => {
						return values.clientPricesExisting
							? {
									nbShippingPoints: values.clientPricesExisting[0]?.nbShippingPoints,
									rfaClientEuro: values.clientPricesExisting[0]?.rfaClientEuro,
									sellingPriceWithMargin: values?.clientPricesExisting[0]?.sellingPriceWithMargin,
									transportType: values.clientPricesExisting[0]?.transportType,
									transportSellingPriceHtWithClientRfaRectified:
										values.clientPricesExisting[0]?.transportSellingPriceHtWithClientRfaRectified,
									transportSellingPriceHtAdditionalMargin:
										values.clientPricesExisting[0]?.transportSellingPriceHtAdditionalMargin,
									transportPurchasePriceHt: values.clientPricesExisting[0]?.transportPurchasePriceHt,
									transportSellingPriceMargin: values?.clientPricesExisting[0]?.transportSellingPriceMargin,
									transportSellingPriceHtWithClientRfa:
										values.clientPricesExisting[0]?.transportSellingPriceHtWithClientRfa,
									transportPurchasePriceHtWithRfa: values.clientPricesExisting[0]?.transportPurchasePriceHtWithRfa,
									transportRfa: values.clientPricesExisting[0]?.transportRfa,
									transportSellingPriceHt: values.clientPricesExisting[0]?.transportSellingPriceHt,
									transportSellingPriceByDefaultHt:
										values.clientPricesExisting[0]?.transportSellingPriceByDefaultHt ?? 0,
									franco: values.clientPricesExisting[0]?.franco ?? false,
									corsicaDeliveryExtra: values.clientPricesExisting[0]?.corsicaDeliveryExtra ?? false,
									isMechanizable: values.clientPricesExisting[0]?.isMechanizable ?? false,
									isFinalDistribution: values.clientPricesExisting[0]?.isFinalDistribution ?? false,
									transporterId: values.clientPricesExisting[0]?.transporter?.id ?? '',
							  }
							: initialClientQuote.summaryPrice;
					})(),
				},
				...(values?.clientQuoteItem ?? []),
			]);
			setFieldValue('summaryPrice', initialClientQuote.summaryPrice);
			setFieldValue('selectedProduct', null);
			setFieldValue('quantity', 0);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

	return (
		<Box className={classes.root}>
			<Box py={5}>
				<Typography variant="h2">Ajout d’un prix de vente au devis</Typography>
			</Box>
			<Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
				{stepClientPriceQuote.map((label, index) => (
					<Step key={label}>
						<StepLabel>
							<div className={classes.stepLabel}>{label}</div>
						</StepLabel>
						<StepContent>
							<Box>{getStepContent(index)}</Box>
							<Box className={classes.actionsContainer}>
								<Box>
									<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
										Précédent
									</Button>
									<Button
										disabled={!values.quantity}
										variant="contained"
										color="primary"
										onClick={handleNext}
										className={classes.button}
									>
										{activeStep === stepClientPriceQuote.length - 1
											? activeStep === 2
												? 'Valider'
												: 'Valider et ajouter au devis'
											: 'Suivant'}
									</Button>
								</Box>
							</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</Box>
	);
};
