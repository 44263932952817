import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import colors from '../../../../app/theme/colors.scss';
import { getPadding } from '../../../helpers';
import { CellOrderItemSuppliersPrices } from './CellOrderItemSuppliersPrices';
import ButtonDisplayContent from '../../../../components/ButtonDisplayContent';
import { useStylesSupplierPrices } from './styles';
import { PriceSummaryByProductType } from '../../../definition';
import { isString } from '../../../../utils/typeGuards';

const PriceSummaryByProduct = ({
	summaryDetails,
	totalPurchasePriceExcludingTaxWithRFA,
}: PriceSummaryByProductType) => {
	const classes = useStylesSupplierPrices();
	const [displayPriceDetails, setDisplayPriceDetails] = useState(false);

	return (
		<Grid xs={10} item>
			<Grid justifyContent="center" container>
				<Box className={classes.listSupplier}>
					<CellOrderItemSuppliersPrices
						label=""
						value="Résumé des prix par produits"
						borderColor={colors.turquoise500}
						widthRoot="82%"
						index={0}
						padding={getPadding()}
					/>
					<CellOrderItemSuppliersPrices
						label=""
						value={totalPurchasePriceExcludingTaxWithRFA.toFixed(2)}
						widthRoot="13.4%"
						padding={getPadding()}
					/>
					<Box>
						<CellOrderItemSuppliersPrices widthRoot="100%" label="" padding={getPadding()}>
							<ButtonDisplayContent
								displayContent={displayPriceDetails}
								OnDisplayContent={setDisplayPriceDetails}
								isActive
							/>
						</CellOrderItemSuppliersPrices>
					</Box>
				</Box>
			</Grid>
			<Grid container className={classes.wrapperCollapse}>
				<Grid xs={12} item>
					<Collapse in={displayPriceDetails}>
						{summaryDetails.map((summary, indexTransport) => (
							<Box className={classes.summaryCell}>
								{Object.entries(summary).map(([label, value], index) => (
									<CellOrderItemSuppliersPrices
										label={indexTransport === 0 ? label : ''}
										value={isString(value) ? value : value.toFixed(2)}
										widthRoot="14.4%"
										index={index}
										borderColor={colors.turquoise500}
										padding={getPadding(index)}
										isMarginRoot
										key={label + index}
									/>
								))}
							</Box>
						))}
					</Collapse>
				</Grid>
				<Grid xs={12} item>
					<Collapse in={displayPriceDetails}>
						<Box className={classes.summaryCell}>
							<CellOrderItemSuppliersPrices
								label="Total PA HT avec RFA (€)"
								value={totalPurchasePriceExcludingTaxWithRFA.toFixed(2)}
								widthRoot="14.4%"
								padding={getPadding(1)}
								borderColor={colors.turquoise500}
								isMarginRoot
							/>
						</Box>
					</Collapse>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PriceSummaryByProduct;
