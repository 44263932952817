import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import loginLogo1 from '../public/images/loginLogo1.svg';
import { LoginForm } from './LoginForm';
export const LoginPage = () => {
	return (
		<Grid container direction="column" justifyContent="center" alignContent="center" alignItems="center">
			<Grid item xs={12}>
				<Box mt={6}>
					<img src={loginLogo1} height={65} alt="" />
				</Box>
			</Grid>
			<Grid item xs={3}>
				<Box mt={4} mb={4}>
					<Typography align="center" color="secondary">
						Gestionnaire des produits, des prix d’achats fournisseurs, des devis, des commandes, de la production et de
						la facturation
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<LoginForm />
			</Grid>
			<Grid item xs={12}>
				<Box mt={4}>
					<Typography align="center" color="secondary">
						© OneSupply Inc. Tous droits réservés
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box mt={15}>
					<Typography align="center" color="secondary">
						Cookies - Confidentialité - Conditions d’utilisation
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};
