import { groupBy } from 'ramda';
import { AttributeValuesType, EMPTY_STRING } from 'admin/support/definitions';
import { ProductFromModel } from 'product/definitions';
import { AllClientPricesFromModel, ClientPriceFromModel, clientPricesFromModel } from './definitions';
import { ClientPrice, ClientPriceStatus, MarginType, PrintValue } from 'graphql/types';
import { formatNumberDisplay } from 'utils/prices';
import { ProductFilter, SelectedProduct } from 'context/pricesProductContext/definition';
import { PriceSummaryTable } from './form/product-selling-price/definitions';
import { SupplierPriceFormModel } from 'supplier/definitions';
import { getPriceWithRfaAndMargin } from './form/helpers';
import { StatusClientPrice } from './form/definitions';

export const getAttributeValue = (
	attributeValues: AttributeValuesType,
	filtredProduct: ProductFromModel[],
	selectedSupportId: string,
	printAttributes?: PrintValue[] | null,
	selectedProduct?: SelectedProduct,
	filters?: ProductFilter | null,
	isClariPrint?: boolean
) => {
	const key = attributeValues?.data[0]?.key;
	const valueFirstItem = attributeValues?.data[0]?.value;
	const isAllAtrributesValuesEqual = attributeValues?.data.every(
		(attributeValue) => attributeValue.value === valueFirstItem
	);

	if (isAllAtrributesValuesEqual && !isClariPrint) return valueFirstItem === EMPTY_STRING ? ' ' : valueFirstItem;
	const isAllAtrributesValuesEmpty = attributeValues?.data.every(
		(attributeValue) => attributeValue.value === EMPTY_STRING
	);

	if (filtredProduct.length === 1 && printAttributes?.length === 0) {
		if (isAllAtrributesValuesEmpty) return ' ';
		return (filtredProduct[0][key as keyof ProductFromModel] as string) || '';
	}
	const valueByKey =
		selectedSupportId !== ''
			? selectedProduct && selectedProduct[key as keyof SelectedProduct]
			: filters &&
			  filters[
					key as keyof Omit<
						ProductFilter,
						'conditionnement1' | 'conditionnement2' | 'conditionnement3' | 'conditionnement'
					>
			  ]?.value;

	return valueByKey === null ? ' ' : valueByKey ?? '';
};

export const normalizeDate = (date: string): string | null => {
	if (!date) return null;
	return date.split('T')[0];
};

export const generateCurrentDate = () => normalizeDate(new Date().toISOString());

export const formattedClientPrices = (tabIndex: number, clients?: clientPricesFromModel) =>
	clients
		?.map((client) => client.clientPrices)
		[tabIndex]?.map((price) => ({
			productName: price.productName,
			quantity: price.quantity,
			nbShippingPoints: price.nbShippingPoints,
			productSalesPriceExcludingVAT: formatNumberDisplay(price.productSalesPriceExcludingVAT, 2),
			productPurchasePriceExcludingVAT: formatNumberDisplay(price.productPurchasePriceExcludingVAT, 2),
			productDifferencePrice: formatNumberDisplay(price?.productPurchasePriceExcludingVAT, 2),
			transportSalesPriceExcludingVAT: formatNumberDisplay(price.transportSalesPriceExcludingVAT, 2),
			transportPurchasePriceExcludingVAT: formatNumberDisplay(price.transportPurchasePriceExcludingVAT, 2),
			transportDifferencePrice:
				price?.transportPurchasePriceExcludingVAT &&
				price.transportSalesPriceExcludingVAT &&
				formatNumberDisplay(price.transportSalesPriceExcludingVAT - price?.transportPurchasePriceExcludingVAT, 2),
			cost: price.cost,
			sellingPriceMargin: formatNumberDisplay(price.sellingPriceMargin, 2),
		}));

export const getProductPurchasePriceExcludingVAT = (
	numberPlateChanges: number,
	numberFileProcessing: number,
	numberShots: number,
	tools: boolean,
	buyingPriceWithAmalgam: number,
	supplierPrices?: SupplierPriceFormModel
) => {
	return (
		(buyingPriceWithAmalgam > 0 ? buyingPriceWithAmalgam : supplierPrices?.prixHorsCalage ?? 0) +
		(supplierPrices?.prixCalage ?? 0) +
		(supplierPrices?.prixChangementPlaque ?? 0) * numberPlateChanges +
		(supplierPrices?.prixTraitementFichier ?? 0) * numberFileProcessing +
		(supplierPrices?.autrePrixFixe ?? 0) +
		(tools ? supplierPrices?.prixOutillage ?? 0 : 0) +
		(supplierPrices?.prixCliche ?? 0) * numberShots
	);
};

export const getProductPurchasePriceExcludingVATWithRFA = (
	productPurchasePriceExcludingVAT: number,
	supplierRfa: number
) => productPurchasePriceExcludingVAT - (productPurchasePriceExcludingVAT * supplierRfa) / 100;

export const getProductSalesPriceExcludingVAT = (
	numberPlateChanges: number,
	numberFileProcessing: number,
	numberShots: number,
	tools: boolean,
	salesPricesWithMargin: PriceSummaryTable
) =>
	(salesPricesWithMargin.prixCalage as number) +
	(salesPricesWithMargin.prixHorsCalage as number) +
	(salesPricesWithMargin.prixChangementPlaque as number) * numberPlateChanges +
	(salesPricesWithMargin.prixCliche as number) * numberShots +
	(salesPricesWithMargin.prixTraitementFichier as number) * numberFileProcessing +
	(tools ? (salesPricesWithMargin.prixOutillage as number) : 0);

export const getProductTotalPrices = (
	numberPlateChanges: number,
	numberFileProcessing: number,
	numberShots: number,
	tools: boolean,
	buyingPriceWithAmalgam: number,
	supplierRfa: number,
	salesPricesWithMargin: PriceSummaryTable,
	mainRfaRate?: number,
	isWithRfaClient?: boolean,
	supplierPrices?: SupplierPriceFormModel
) => {
	const productPurchasePriceExcludingVAT = getProductPurchasePriceExcludingVAT(
		numberPlateChanges,
		numberFileProcessing,
		numberShots,
		tools,
		buyingPriceWithAmalgam,
		supplierPrices
	);
	const productPurchasePriceExcludingVATWithRFA = getProductPurchasePriceExcludingVATWithRFA(
		productPurchasePriceExcludingVAT,
		supplierRfa
	);
	const productSalesPriceExcludingVAT = getProductSalesPriceExcludingVAT(
		numberPlateChanges,
		numberFileProcessing,
		numberShots,
		tools,
		salesPricesWithMargin
	);
	const productSalesPriceExcludingVATWithRfa = isWithRfaClient
		? getPriceWithRfaAndMargin(MarginType.Percent, productSalesPriceExcludingVAT, mainRfaRate ?? 0, isWithRfaClient)
		: undefined;

	return [
		{
			width: '14%',
			name: 'productPurchasePriceExcludingVAT',
			isBorder: true,
			hasRate: false,
			label: 'PA Produit HT (€)',
			value: productPurchasePriceExcludingVAT,
		},
		{
			width: '14%',
			name: 'supplierPrices[0].rfa',
			isBorder: false,
			hasRate: false,
			label: 'RFA (%) (Montant RFA : )',
		},
		{
			width: '14%',
			name: 'productPurchasePriceExcludingVATWithRFA',
			isBorder: false,
			hasRate: false,
			label: 'PA Produit HT avec RFA (€)',
			value: productPurchasePriceExcludingVATWithRFA,
		},
		{
			width: '12%',
			name: 'productSalesPriceExcludingVAT',
			isBorder: false,
			hasRate: false,
			label: 'PV produit HT (€)',
			value: productSalesPriceExcludingVAT,
		},
		{
			width: '16%',
			name: 'productSalesPriceExcludingVATWithRfa',
			isBorder: false,
			hasRate: false,
			label: 'PV Produit HT avec RFA client (€)',
			value: isWithRfaClient ? productSalesPriceExcludingVATWithRfa : productSalesPriceExcludingVAT,
		},
		{
			width: '13%',
			name: 'productSalesPriceExcludingVATRectified',
			isBorder: false,
			hasRate: false,
			label: 'PV Produit HT rectifié (€)',
			isNotDisabled: true,
		},
		{
			width: '7%',
			name: 'isFinalDistribution',
			isBorder: false,
			hasRate: false,
			isChecked: true,
			label: 'Sous réserve de répartition définitive',
			isNotDisabled: true,
		},
		{
			width: '7%',
			name: 'isIncluded',
			isBorder: false,
			hasRate: false,
			isChecked: true,
			label: 'Prix de vente inclus dans une offre',
			isNotDisabled: false,
		},
	];
};

export const groupClientsPricesBySupportName = (clientsPrices: ClientPrice[]) => {
	const groupBySupportName = groupBy((clientPrice: ClientPrice) => clientPrice?.product?.support?.name || '');
	return groupBySupportName(clientsPrices);
};

export const groupClientsPricesByStatus = (clientsPrices: AllClientPricesFromModel[]) => {
	const groupByStatus = groupBy((clientPrice: ClientPriceFromModel) => clientPrice?.status || '');
	return clientsPrices.map((client) => {
		const clientPricesByStatus = client?.clientPrices
			? groupByStatus(client?.clientPrices as ClientPriceFromModel[])
			: [];
		const clientsPricesBySupport = Object.keys(clientPricesByStatus).map((status) => ({
			[status]: groupClientsPricesBySupportName(clientPricesByStatus[status as keyof unknown]),
		}));

		return { ...client, clientPrices: clientsPricesBySupport };
	});
};

export const isTransportBySupplier = (supplierPrices?: SupplierPriceFormModel[] | null, isPLV?: boolean) =>
	!!(supplierPrices && supplierPrices[0]?.franco) && isPLV;

export const calculateMargin = (
	cost: number,
	rfaClientEuro: number,
	productPurchasePriceExcludingVATWithRFA: number,
	transportPurchasePriceHtWithRfa: number
) => cost - rfaClientEuro - (productPurchasePriceExcludingVATWithRFA + transportPurchasePriceHtWithRfa);

export const getClientPriceStatusValueFilter = (status: StatusClientPrice) => {
	switch (status) {
		case StatusClientPrice.InProgress:
			return ClientPriceStatus.InProgress;
		case StatusClientPrice.Obsolete:
			return ClientPriceStatus.Obsolete;
		case StatusClientPrice.ValidatedByCustomerQuote:
			return ClientPriceStatus.ValidatedByCustomerQuote;
		case StatusClientPrice.WaitingByValidationQuote:
			return ClientPriceStatus.WaitingByValidationQuote;
		default:
			return undefined;
	}
};
