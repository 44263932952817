import React, { useCallback, useEffect, useRef, useState } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../../app/theme/colors.scss';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import DashedHeader from '../../../../components/DashedHeader';
import { useField } from 'formik';
import { AddCircle, Search } from '@mui/icons-material';
import { AddingSupports } from './components/adding-supports';
import { ClientSupport } from '../../../../graphql/types';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { useClientSupportTable } from './hook/useClientSupportTable';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			backgroundColor: colors.white,
			height: 294,
		},
		wrapGrid: {
			textAlign: 'center',
		},
		iconCamera: {
			display: 'flex',
			padding: 30,
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '50%',
			backgroundColor: colors.blue200,
		},
	})
);

export type MarginSupport = ClientSupport & { icons: string };

export const SpecificMarginSupport = (): JSX.Element | null => {
	const classes = useStyles();
	const id = useParams<string>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [field, , helpers] = useField<ClientSupport[]>('clientsSupports');
	const [marginSupportData, setMarginSupportData] = useState(
		field.value.map((item) => ({
			...item,
			icons: '',
		}))
	);
	const addMarginSupport = useRef(helpers.setValue);
	const [marginSupportToEdit, setMarginSupportToEdit] = useState<MarginSupport | null>(null);
	const [isEditable, setIsEditable] = useState<boolean>(false);

	useEffect(() => {
		setMarginSupportData(
			field.value.map((item) => ({
				...item,
				icons: '',
			}))
		);
	}, [id, field.value]);

	const handleShowSupportToEdit = useCallback(
		(row: MarginSupport): void => {
			setMarginSupportToEdit(row);
			setIsEditable(!isEditable);
			setIsOpen(!isOpen);
		},
		[isEditable, isOpen]
	);

	const handleDeleteMarginSupport = useCallback(
		(rows: MarginSupport[]): void => {
			setMarginSupportData(rows);
			addMarginSupport.current(rows);
		},
		[addMarginSupport]
	);

	const handleCloseClick = (): void => {
		setIsEditable(false);
		setMarginSupportToEdit(null);
		setIsOpen(!isOpen);
	};

	const tableInstance = useClientSupportTable(marginSupportData, handleShowSupportToEdit, handleDeleteMarginSupport);

	return (
		<Box width="100%" my={3}>
			<DashedHeader>Marge commerciale spécifique</DashedHeader>
			{marginSupportData.length ? (
				<>
					<Box p={1} mb={2}>
						<Grid container justifyContent="flex-end">
							<Button startIcon={<AddCircle />} variant="outlined" color="primary" onClick={() => setIsOpen(true)}>
								Ajouter une marge spécifique
							</Button>
						</Grid>
					</Box>

					<Box p={1}>
						<Grid container>
							<AppListTable tableInstance={tableInstance} />
						</Grid>
					</Box>
				</>
			) : (
				<Grid container className={classes.root}>
					<Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={4}>
						<Grid item xs={12}>
							<Box mb={3}>
								<IconButton className={classes.iconCamera} size="large">
									<Search htmlColor={colors.primary} />
								</IconButton>
							</Box>
						</Grid>
						<Grid item xs={12} className={classes.wrapGrid}>
							<Box mb={3}>
								<Typography variant="h2">Pas encore de marge spécifique</Typography>
								<Typography variant="h5">Aucun fournisseur disponible pour ce produit</Typography>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box>
								<Button variant="contained" color="primary" onClick={() => setIsOpen(true)}>
									Ajouter une marge spécifique
								</Button>
							</Box>
						</Grid>
					</Box>
				</Grid>
			)}
			<AddingSupports
				setMarginSupportData={setMarginSupportData}
				marginSupportData={marginSupportData}
				marginSupportToEdit={marginSupportToEdit}
				setMarginSupportToEdit={setMarginSupportToEdit}
				handleCloseClick={handleCloseClick}
				isOpen={isOpen}
				isEditable={isEditable}
				setIsEditable={setIsEditable}
				setIsOpen={setIsOpen}
			/>
		</Box>
	);
};
