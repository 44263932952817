import React from 'react';
import { Box, FormControl, MenuItem, Typography } from '@mui/material';
import colors from '../../../../../app/theme/colors.scss';
import { LayoutField } from '../../../../../components/form/LayoutField';
import FormikSelectField from '../../../../../components/form/FormikSelectField';
import { boxShippingModeOptions, palletShippingModeOptions } from '../../definitions';

interface TransportFormProps {
	disabled: boolean | null | undefined;
}

const TransportForm = ({ disabled }: TransportFormProps): JSX.Element => {
	return (
		<>
			<Box bgcolor={colors.grayCard} textAlign="center" borderColor={colors.indigo700} p={1} mb={2}>
				<Typography variant="h4">Transport colis</Typography>
			</Box>

			<LayoutField label="Mode de livraison par défaut">
				<Box mb={2} bgcolor={disabled ? colors.grey : ''}>
					<FormControl fullWidth>
						<FormikSelectField name="boxShippingMode" variant="outlined" disabled={disabled ?? false}>
							{boxShippingModeOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.name}
								</MenuItem>
							))}
						</FormikSelectField>
					</FormControl>
				</Box>
			</LayoutField>

			<LayoutField label="Poids maxi accepté par carton (en Kg)">
				<Box mb={2} bgcolor={disabled ? colors.grey : ''}>
					<FormControl fullWidth>
						<FormikSelectField name="maxBoxWeight" variant="outlined" disabled={disabled ?? false}>
							{
								//valeurs possibles entre 10 et 15
								Array.from(Array(6).keys()).map((option) => (
									<MenuItem key={option + 10} value={option + 10}>
										{option + 10}
									</MenuItem>
								))
							}
						</FormikSelectField>
					</FormControl>
				</Box>
			</LayoutField>

			<Box bgcolor={colors.grayCard} textAlign="center" borderColor={colors.indigo700} p={1} mb={2} mt={2}>
				<Typography variant="h4">Transport palette</Typography>
			</Box>

			<LayoutField label="Type de transport par défaut">
				<Box mb={2} bgcolor={disabled ? colors.grey : ''}>
					<FormControl fullWidth>
						<FormikSelectField name="palletShippingMode" variant="outlined" disabled={disabled ?? false}>
							{palletShippingModeOptions.map((option) => (
								<MenuItem selected={false} key={option.key} value={option.key}>
									{option.value}
								</MenuItem>
							))}
						</FormikSelectField>
					</FormControl>
				</Box>
			</LayoutField>
		</>
	);
};

export default TransportForm;
