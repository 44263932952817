import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useField } from 'formik';
import CommentForm from './components/CommentForm';
import TotalTable from './components/TotalTable';
import SalePriceTable from './components/SalePriceTable';
import { ClientQuoteItem } from '../../../graphql/types';

const Quote = (): JSX.Element => {
	const [field] = useField<ClientQuoteItem[] | undefined | null>('clientQuoteItem');

	return (
		<Box mt={5} mb={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">Devis </Typography>
					</Grid>
				</Grid>
			</Box>
			{field?.value?.map((summaryPrice, index) => (
				<Grid key={index} container>
					<Grid item xs={1} />
					<SalePriceTable productName={summaryPrice.productName} index={index} />
				</Grid>
			))}
			<Grid container>
				<Grid item xs={1} />
				<Grid item xs={11}>
					<TotalTable />
					<CommentForm />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Quote;
