import React, { useEffect } from 'react';
import { IOSSwitch } from '../../../components/form/FormikSwitchField';
import { Box, Button, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LayoutFieldForm from '../../../components/form/LayoutFieldForm';
import { useField } from 'formik';
import { ClientQuoteItem, QuoteType } from '../../../graphql/types';
import usePrevious from '../../../hooks/usePrevious';
import { LayoutField } from '../../../components/form/LayoutField';
import usePopin from 'hooks/usePopin';
import QuoteDistributionModal from './QuoteDistributionModal';
import { GET_CLIENT_PRICE_WITH_CUSTOM_QUANTITY } from 'clientPrice/graphql/queries';
import { useApolloClient } from '@apollo/client';
import { setClientPricesToQuoteItems } from '../helpers';
interface DeliveryProps {
	nbShippingPoints?: number;
}

const Delivery = ({ nbShippingPoints }: DeliveryProps): JSX.Element => {
	const [typeField] = useField<QuoteType>('type');
	const [isDistributionField, , isDistributionHelpers] = useField<boolean>('isDistribution');
	const [clientQuoteItemField, , setClientQuoteItemField] = useField<ClientQuoteItem[]>('clientQuoteItem');
	const [, , nbShippingPointsHelpers] = useField<number>('nbShippingPoints');
	const [clientId] = useField<string>('clientId');

	const apolloClient = useApolloClient();

	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Répartition des produits par magasins',
		maxWidth: 'xl',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const largestNbShippingAddress = Math.max(
		...clientQuoteItemField.value.map(({ nbShippingPoints }) => nbShippingPoints).concat([nbShippingPoints ?? 1])
	);
	const prevLargestNbShippingAddress = usePrevious(largestNbShippingAddress);

	useEffect(() => {
		if (largestNbShippingAddress > 1 && largestNbShippingAddress !== prevLargestNbShippingAddress) {
			isDistributionHelpers.setValue(true);
			nbShippingPointsHelpers.setValue(largestNbShippingAddress);
		}
	}, [prevLargestNbShippingAddress, nbShippingPointsHelpers, largestNbShippingAddress, isDistributionHelpers]);

	const fetchClientPricesForQuoteItems = async (clientQuoteItems: ClientQuoteItem[]): Promise<void> => {
		const prices = await Promise.all(
			clientQuoteItems.map(async (quoteItem) => {
				const filters = {
					clientId: clientId.value,
					productId: quoteItem.productId,
					quantity: quoteItem.quantity,
					numberVersion: quoteItem.numberVersion ?? 1,
				};

				const { data } = await apolloClient.query({
					query: GET_CLIENT_PRICE_WITH_CUSTOM_QUANTITY,
					variables: {
						filters,
					},
				});

				return data.clientPrice;
			})
		);

		if (!prices) {
			return;
		}

		const newClientQuoteItems = setClientPricesToQuoteItems(clientQuoteItems, prices);
		setClientQuoteItemField.setValue(newClientQuoteItems);
	};

	const onChangeDistribution = () => {
		nbShippingPointsHelpers.setValue(!isDistributionField.value ? largestNbShippingAddress : 1);
		isDistributionHelpers.setValue(!isDistributionField.value);

		if (isDistributionField.value) {
			fetchClientPricesForQuoteItems(clientQuoteItemField.value);
		}
	};

	return (
		<Box mb={5} mt={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item xs={11}>
						<Typography variant="h2">Livraison</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box mb={5}>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={10}>
						<Box mb={2}>
							<LayoutField label="Répartition à prévoir">
								<FormControlLabel
									disabled={typeField.value === QuoteType.ClientPriceToBeValidate}
									control={
										<IOSSwitch
											checked={isDistributionField.value}
											name="isDistribution"
											onChange={() => onChangeDistribution()}
										/>
									}
									label={isDistributionField.value ? 'Oui' : 'Non'}
									labelPlacement="end"
								/>
							</LayoutField>
						</Box>
						<LayoutFieldForm
							disabled={typeField.value === QuoteType.ClientPriceToBeValidate || !isDistributionField.value}
							label="Nombre de points de livraison"
							name="nbShippingPoints"
							type="number"
							width="200px"
							inputProps={{
								inputProps: { min: isDistributionField.value ? 2 : 1, step: 1 },
							}}
						/>
						<Button
							variant="contained"
							disabled={!isDistributionField.value}
							color="primary"
							onClick={openPopin}
							style={{ marginLeft: 0 }}
						>
							Gérer la répartition
						</Button>
					</Grid>
				</Grid>
			</Box>
			{renderPopin(<QuoteDistributionModal closePopin={() => closePopin()} />)}
		</Box>
	);
};

export default Delivery;
