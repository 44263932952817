import gql from 'graphql-tag';
import CONTACT_FRAGMENT from '../../graphql/fragments/contact/contactFragment';
import ADDRESS_FRAGMENT from '../../graphql/fragments/address/addressFragment';
import SUPPLIER_PRICE_FRAGMENT from '../../graphql/fragments/supplier-price/supplierPriceFragment';
import SUPPLIER_FRAGMENT from '../../graphql/fragments/supplier/supplierFragment';

const GET_SUPPLIERS_PRICES = gql`
	query GetSuppliersPrices($productsId: [String!]!) {
		suppliersPrices(productsId: $productsId) {
			id
			name
			productsSuppliersPrices {
				...SupplierPrice
				product {
					codeAt
				}
			}
		}
	}
	${SUPPLIER_PRICE_FRAGMENT}
`;

const GET_SUPPLIERS = gql`
	query GetSuppliers {
		suppliers {
			name
			id
		}
	}
`;

const GET_SUPPLIER_BY_ID = gql`
	query supplier($id: String!) {
		supplier(id: $id) {
			...Supplier
			user {
				id
				email
			}
			escomptes {
				escompte
				client
				dayLimitTime
			}
			discounts {
				ca
				rfa
			}
			transportModes {
				title
			}
			siteAddress1 {
				...Address
			}
			siteAddress2 {
				...Address
			}
			supplierContact {
				...Contact
			}
		}
	}
	${SUPPLIER_FRAGMENT}
	${ADDRESS_FRAGMENT}
	${CONTACT_FRAGMENT}
`;

const FILTERED_SUPPLIER_PRICE = gql`
	query GeSupplierPrices($filters: SupplierPriceFilterInput!) {
		supplierPrices: filtredSupplierPrices(filters: $filters) {
			...SupplierPrice
			departingDepartment
			supplier {
				transportModes {
					title
				}
			}
		}
	}
	${SUPPLIER_PRICE_FRAGMENT}
`;

export { GET_SUPPLIERS_PRICES, GET_SUPPLIERS, GET_SUPPLIER_BY_ID, FILTERED_SUPPLIER_PRICE };
