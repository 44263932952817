import React from 'react';
import { Box, Typography } from '@mui/material';

export const OrderCancellationContent = (): JSX.Element => {
	return (
		<Box p={3}>
			<Typography variant="h4" style={{ marginBottom: 8 }}>
				Etes-vous sûr de vouloir annuler la commande?
			</Typography>
			<Typography>
				Cette action entrainera l'annulation de tous les bons de commande envoyés aux différents fournisseurs{' '}
			</Typography>
		</Box>
	);
};

export default OrderCancellationContent;
