import { Box, Typography } from '@mui/material';
import React from 'react';

const OrderItemDeleteContent = () => {
	return (
		<Box p={3}>
			<Typography variant="h4" style={{ marginBottom: 8 }}>
				Etes-vous sûr de vouloir supprimer le produit?
			</Typography>
		</Box>
	);
};

export default OrderItemDeleteContent;
