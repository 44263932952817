import React, { useCallback, useMemo } from 'react';
import { CellProps, Column, useRowSelect, useTable } from 'react-table';
import { Box, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ClientSupport, MarginType } from '../../../../../graphql/types';
import { MarginSupport } from '../specific-margin-support';

export const useClientSupportTable = (
	supportData: MarginSupport[],
	handleShowSupportToEdit: (support: MarginSupport) => void,
	handleDeleteMarginSupport: (support: MarginSupport[]) => void
) => {
	const handleDeleteSupport = useCallback(
		(row: CellProps<MarginSupport>) => {
			const supports = [...supportData].filter((support) => support.supportId !== row.original.supportId);
			handleDeleteMarginSupport(supports);
		},
		[supportData, handleDeleteMarginSupport]
	);

	const handleEditSupport = useCallback((row: CellProps<MarginSupport>) => handleShowSupportToEdit(row.original), [
		handleShowSupportToEdit,
	]);

	const columns = useMemo<Column<ClientSupport & { icons: string }>[]>(
		() => [
			{
				accessor: 'support',
				Header: () => <Typography>Support</Typography>,
				Cell: ({ row }) => {
					return (
						<Box>
							<Typography>{row.original.support.name}</Typography>
						</Box>
					);
				},
			},
			{
				accessor: 'marginType',
				Header: () => <Typography>Type de marge</Typography>,
				Cell: ({ row }) => (
					<Box>{<Typography>{row.original.marginType === MarginType.Percent ? '%' : '€'}</Typography>}</Box>
				),
			},
			{
				accessor: 'margin',
				Header: () => <Typography>Marge</Typography>,
				Cell: ({ row }) => (
					<Box>
						<Typography>{row.original.margin}</Typography>
					</Box>
				),
			},
			{
				accessor: 'icons',
				Header: () => null,
				Cell: (row) => {
					return (
						<Box display="flex" justifyContent="space-around">
							<EditOutlinedIcon onClick={() => handleEditSupport(row)} />
							<DeleteOutlineIcon onClick={() => handleDeleteSupport(row)} />
						</Box>
					);
				},
			},
		],
		[handleDeleteSupport, handleEditSupport]
	);

	return useTable(
		{
			columns,
			data: supportData,
		},
		useRowSelect
	);
};
