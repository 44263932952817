import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import dc from '../../public/images/dc.svg';
import importfile from '../../public/images/importfile.svg';
import formatting from '../../public/images/formatting.svg';
import fantastik from '../../public/images/fantastik.svg';
import unitc from '../../public/images/unitc.svg';
import db from '../../public/images/db.svg';
import deconix from '../../public/images/deconix.svg';

interface props {
	activeMenu: string;
}

const useStyles = makeStyles((theme) => ({
	menu: {
		marginTop: '50px',
	},
	menul: {
		padding: '0px',
		margin: '0px 10px',
	},
	menuli: {
		'list-style-type': 'none',
		marginBottom: '10px',
		cursor: 'default',
		fontSize: '0.7rem',
	},
	material_icons: {
		verticalAlign: 'middle',
		padding: '5px',
		width: '25px',
	},
	activeMenu: {
		fontWeight: 'bolder',
		fontSize: '0.72rem',
	},
}));

export default function Menu(props: props) {
	const classes = useStyles();

	return (
		<div className={classes.menu}>
			<ul className={classes.menul}>
				<li className={classes.menuli}>
					<img src={importfile} className={classes.material_icons} alt="" />
					<span className={props.activeMenu === 'importer un fichier' ? classes.activeMenu : undefined}>
						Importer un fichier
					</span>
				</li>
				<li className={classes.menuli}>
					<img src={formatting} className={classes.material_icons} alt="" />
					<span className={props.activeMenu === 'formatting' ? classes.activeMenu : undefined}>Formating</span>
				</li>
				<li className={classes.menuli}>
					<img src={dc} className={classes.material_icons} alt="" />
					<span
						className={
							props.activeMenu === 'Data Corrector 1' || props.activeMenu === 'Data Corrector 2'
								? classes.activeMenu
								: undefined
						}
					>
						Data Corrector
					</span>
				</li>
				<li className={classes.menuli}>
					<img src={deconix} className={classes.material_icons} alt="" />
					<span className={props.activeMenu === 'Déconix' ? classes.activeMenu : undefined}>Déconix</span>
				</li>
				<li className={classes.menuli}>
					<img src={unitc} className={classes.material_icons} alt="" />
					<span
						className={
							props.activeMenu === 'Unit convertor 1' || props.activeMenu === 'Unit convertor 2'
								? classes.activeMenu
								: undefined
						}
					>
						Unit Convertor
					</span>
				</li>
				<li className={classes.menuli}>
					<img src={fantastik} className={classes.material_icons} alt="" />
					<span className={props.activeMenu === 'Fantastik' ? classes.activeMenu : undefined}>Fantastik</span>
				</li>
				<li className={classes.menuli}>
					<img src={db} className={classes.material_icons} alt="" />
					<span className={props.activeMenu === 'Intégrer les données' ? classes.activeMenu : undefined}>
						Intégrer les données
					</span>
				</li>
			</ul>
		</div>
	);
}
