import { useParams } from 'react-router-dom';
import useSupportByIdQuery from 'admin/support/hooks/useSupportByIdQuery';
import { useClientPriceUpsert } from './useClientPriceUpsert';
import {
	AttributConditionnementInput,
	ClientPriceInput,
	ClientPriceStatus,
	Margins,
	PricesSource,
	TransporterIdentity,
} from 'graphql/types';
import { useClientPriceQuery } from './useClientPriceQuery';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { omit } from 'ramda';
import { initialClientPrice } from '../form/definitions';
import { SupplierPriceFormModel } from 'supplier/definitions';
import { HEADLINES, PATH } from '../definitions';
import { typeNotifications } from 'utils/definition';
import { useSnackbar } from 'notistack';
import PricesProductContext from '../../context/pricesProductContext/PricesProductContext';
import { useUpsertClariPrintClientPrice } from 'product/hooks/useUpsertClariPrintClientPrice';

type ParamsType = { id: string; productId: string; quantity: string; numberVersion: string };

export const useManageSellingPrice = () => {
	const { id, productId: currentProductId, quantity, numberVersion } = useParams<ParamsType>();
	const { enqueueSnackbar } = useSnackbar();
	const { support, loading } = useSupportByIdQuery(id);
	const { upsertClientPrice } = useClientPriceUpsert();
	const { productFilter } = useContext(PricesProductContext);
	const { getClientPrice, clientPrice } = useClientPriceQuery();
	const { upsertClariPrintClientPrice } = useUpsertClariPrintClientPrice();
	const [isConsultSellingPrice, setIsConsultSellingPrice] = useState<boolean>(false);

	const isUpdateSellingPrice = useMemo(() => Boolean(id && currentProductId && quantity && numberVersion), [
		id,
		numberVersion,
		currentProductId,
		quantity,
	]);

	const isConsultAndEditSellingPrice = useMemo(() => !isConsultSellingPrice && isUpdateSellingPrice, [
		isConsultSellingPrice,
		isUpdateSellingPrice,
	]);

	const salePriceProduct = useMemo(() => {
		const converterDate = (date: Date) => new Date(date).toLocaleDateString().split('/').reverse().join('-');

		return clientPrice
			? omit(['product', 'salePriceExcludingVAT'], {
					...initialClientPrice,

					...clientPrice,
					clientPriceUniqInput: isConsultAndEditSellingPrice
						? {
								quantity: clientPrice.quantity,
								numberVersion: clientPrice.numberVersion,
								clientId: clientPrice.clientId,
								productId: clientPrice.productId,
						  }
						: null,

					isDistribution: clientPrice.nbShippingPoints > 1,
					productTotalWeight: clientPrice?.productTotalWeight ?? 0.0,
					franco: clientPrice?.franco,
					createdAt: converterDate(clientPrice.createdAt),
					updatedAt: converterDate(clientPrice.updatedAt),
					validatedQuoteDate: converterDate(clientPrice?.validatedQuoteDate),
			  })
			: {
					...initialClientPrice,
					support,
			  };
	}, [clientPrice, support, isConsultAndEditSellingPrice]);

	useEffect(() => {
		setIsConsultSellingPrice(
			isUpdateSellingPrice && !!clientPrice && clientPrice.status === ClientPriceStatus.ValidatedByCustomerQuote
		);
	}, [clientPrice, isUpdateSellingPrice]);

	useEffect(() => {
		//@todo possibilité de le déplacer dans le l'action direct du menu (prevoir un refacto)
		getClientPrice({
			variables: {
				filters: {
					clientId: id,
					productId: currentProductId,
					quantity: +quantity,
					numberVersion: +numberVersion,
				},
			},
		});
	}, [id, currentProductId, quantity, numberVersion, getClientPrice]);

	const isSupport = !isConsultSellingPrice && support;

	const getClientPriceSupportCharacteristic = useCallback(() => {
		const attributes = isSupport ? support?.supportAttributes : clientPrice?.product?.support?.supportAttributes;
		const printAttributes = isSupport
			? support?.printAttributes ?? []
			: clientPrice?.product?.support?.printAttributes ?? [];
		const products = isSupport ? support?.products ?? [] : clientPrice?.product?.support?.products ?? [];
		const isClariPrint = isSupport
			? support?.pricesSource === PricesSource.ClariPrint
			: clientPrice?.product?.support?.pricesSource === PricesSource.ClariPrint;
		const isPLV = isSupport ? support?.category === 'PLV' : clientPrice?.product?.support?.category === 'PLV';

		return {
			attributes,
			printAttributes,
			products,
			isClariPrint,
			isPLV,
			product: clientPrice?.product,
		};
	}, [clientPrice, isSupport, support]);

	const supportId = useMemo(
		() =>
			isConsultSellingPrice || (!isConsultSellingPrice && !support)
				? getClientPriceSupportCharacteristic().product?.supportId
				: id,
		[getClientPriceSupportCharacteristic, id, isConsultSellingPrice, support]
	);

	const onSubmit = useCallback(
		async ({
			distributions,
			productId,
			transporter,
			...clientPrice
		}: ClientPriceInput & {
			supplierPrices: SupplierPriceFormModel[] | null;
			transporter?: TransporterIdentity | null;
			isDistribution: boolean;
		}) => {
			const newClientPrice = {
				distributions: distributions?.map((distribution) => ({
					quantity: distribution.quantity,
					zip: distribution.zip,
				})),

				...clientPrice,
				productId: productId ?? currentProductId,
				transporterId: transporter && transporter.id.length ? transporter.id : null,
				productSalesPriceExcludingVATRectified: clientPrice.productSalesPriceExcludingVATRectified ?? null,
				transportSellingPriceHtWithClientRfaRectified:
					clientPrice.transportSellingPriceHtWithClientRfaRectified ?? null,
				purchasePriceExcludingTax: clientPrice.supplierPrices
					? clientPrice.supplierPrices.reduce((acc, current) => {
							(Object.keys(current) as Array<keyof typeof current>).forEach((key) => {
								if (Object.keys(initialClientPrice.purchasePriceExcludingTax).includes(key)) {
									acc = {
										...acc,
										[key]: current[key] ?? 0,
									};
								}
							});
							return acc;
					  }, {} as Margins)
					: omit(['__typename'], salePriceProduct.purchasePriceExcludingTax),
				sellingPriceWithMargin: omit(['__typename'], clientPrice.sellingPriceWithMargin),
				nbShippingPoints: clientPrice.isDistribution ? clientPrice.nbShippingPoints : 1,
			};
			try {
				const { isClariPrint } = getClientPriceSupportCharacteristic();
				if (isClariPrint && !isUpdateSellingPrice && productFilter) {
					const formatProductFilter: Record<string, string | null | AttributConditionnementInput> = {};
					Object.entries(productFilter).forEach(([key, { value }]) => {
						formatProductFilter[key] = value;
					});

					const createdConditionnement = {
						numberOfLots: productFilter.conditionnement?.numberOfLots,
						total: productFilter.conditionnement?.total ?? undefined,
						conditionnementList: productFilter.conditionnement?.conditionnementList.map(
							({ numberOfExLots, firstConditionnement, secondConditionnement, threeConditionnement }) => ({
								numberOfExLots: numberOfExLots ?? undefined,
								...(firstConditionnement.conditionnement && {
									firstConditionnement: {
										conditionnement: firstConditionnement.conditionnement.value,
										nbExConditionnement: firstConditionnement.nbExConditionnement ?? undefined,
										labeling: firstConditionnement.labeling ?? false,
									},
								}),
								...(secondConditionnement.conditionnement && {
									secondConditionnement: {
										conditionnement: secondConditionnement.conditionnement.value,
										nbExConditionnement: secondConditionnement.nbExConditionnement ?? undefined,
										labeling: secondConditionnement.labeling ?? false,
									},
								}),
								...(threeConditionnement.conditionnement && {
									threeConditionnement: {
										conditionnement: threeConditionnement.conditionnement.value,
										nbExConditionnement: threeConditionnement.nbExConditionnement ?? undefined,
										labeling: threeConditionnement.labeling ?? false,
									},
								}),
							})
						),
					} as AttributConditionnementInput;

					await upsertClariPrintClientPrice({
						product: {
							...omit(['support'], formatProductFilter),
							conditionnement: createdConditionnement,
							supportId: supportId ?? '',
						},

						clientPrice: {
							...omit(['__typename', 'isDistribution'], newClientPrice),
							productId: newClientPrice.productId ?? '',
							margins: omit(['__typename'], clientPrice.margins),
							additionalMargins: omit(['__typename', 'distributions'], clientPrice.additionalMargins),
						},
					});
				} else {
					await upsertClientPrice(omit(['isDistribution'], newClientPrice));
				}
				enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
			} catch (error) {
				enqueueSnackbar('Erreur', { variant: typeNotifications.Error });
			}
		},
		[
			salePriceProduct.purchasePriceExcludingTax,
			enqueueSnackbar,
			productFilter,
			upsertClariPrintClientPrice,
			supportId,
			currentProductId,
			upsertClientPrice,
			getClientPriceSupportCharacteristic,
			isUpdateSellingPrice,
		]
	);

	const getManualTabsData = useCallback(() => {
		const paths = [
			PATH[isSupport ? 0 : 1],
			isSupport ? support?.name ?? '' : clientPrice?.product?.support?.name ?? '',
		];

		const headlines = [
			{
				title: `${HEADLINES[isSupport ? 0 : 1].title} | ${
					isSupport ? support?.name : clientPrice?.product?.support?.name
				}`,
				fontSize: HEADLINES[0].fontSize,
			},
		];
		return { paths, headlines };
	}, [clientPrice, isSupport, support]);

	return {
		supportId,
		currentProductId,
		supportMetaData: support?.clariPrintCorrespondence ?? undefined,
		loading,
		onSubmit,
		isConsultSellingPrice,
		salePriceProduct,
		isConsultAndEditSellingPrice,
		setIsConsultSellingPrice,
		getClientPriceSupportCharacteristic,
		...getManualTabsData(),
	};
};
