import React from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import { FormikCheckboxField } from '../../../../components/form/FormikChecboxField';
import LayoutFieldForm from '../../../../components/form/LayoutFieldForm';
import { IsShowSellingPriceSelectedProps } from '../../definitions';

export const MarginAndRfa = ({ isConsultSellingPrice }: IsShowSellingPriceSelectedProps): JSX.Element => {
	return (
		<Box mt={5}>
			<DashedHeader>Marge et RFA</DashedHeader>
			<Grid container justifyContent="space-between">
				<Grid item xs={5}>
					<Box display="flex">
						<LayoutFieldForm
							testid="default-commercial-product-margin"
							label="Marge commerciale produit par défaut"
							name="defaultCommercialProductMargin"
							type="number"
							width="22%"
							disabled
						>
							<Box p={1}>
								<Typography>%</Typography>
							</Box>
						</LayoutFieldForm>
					</Box>
				</Grid>
				<Grid item xs={5}>
					<Box display="flex">
						<FormControl fullWidth>
							<LayoutFieldForm
								testid="default-commercial-shipping-margin"
								label="Marge commerciale transport par défaut"
								name="defaultCommercialShippingMargin"
								type="number"
								width="22%"
								disabled
							>
								<Box p={1}>
									<Typography>%</Typography>
								</Box>
							</LayoutFieldForm>
						</FormControl>
					</Box>
				</Grid>
				<Grid item xs={5}>
					<Box mt={3}>
						<Typography variant="h4">RFA Client</Typography>
					</Box>
					<Box width="100%" display="flex">
						<Box display="flex" alignItems="center" mr={1} ml={-1} mt={-1}>
							<Box>
								<FormikCheckboxField
									testid="is-with-rfa-client"
									disabled={isConsultSellingPrice}
									name="isWithRfaClient"
								/>
							</Box>
							<Box pt={0.5}>
								<Typography>RFA client</Typography>
							</Box>
						</Box>
						<Box>
							<FormControl fullWidth>
								<LayoutFieldForm testid="main-rfa-rate" label="" name="mainRfaRate" type="number" width="50%" disabled>
									<Box p={1}>
										<Typography>%</Typography>
									</Box>
								</LayoutFieldForm>
							</FormControl>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
