import { useQuery } from '@apollo/client';
import { TransporterFilterInput } from '../../../graphql/types';
import { GET_TRANSPORTERS } from '../graphql/queries';
import { GetTransportersQuery, GetTransportersQueryVariables } from '../graphql/queries.definitions';

const useTransportersQuery = (filter?: TransporterFilterInput) => {
	const { data } = useQuery<GetTransportersQuery, GetTransportersQueryVariables>(GET_TRANSPORTERS, {
		variables: {
			filter,
		},
	});

	return { transporters: data?.transporters };
};

export default useTransportersQuery;
