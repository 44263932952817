import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import { Checkbox } from 'formik-mui';
import React from 'react';
import { BillableCellFieldProps } from './BillableCellFieldProps';
import { EntityType } from '../../../../graphql/types';

const useStyle = makeStyles(() =>
	createStyles({
		checkbox: {
			padding: 0,
		},
	})
);

const BillableCellField = ({ row: { original, index } }: BillableCellFieldProps): JSX.Element | null => {
	const classes = useStyle();

	if (original.type === EntityType.Region || original.type === EntityType.Typology) return null;

	return (
		<Field
			component={Checkbox}
			className={classes.checkbox}
			type="checkbox"
			color="primary"
			name={`structures[${index}].isBillable`}
		/>
	);
};

export default BillableCellField;
