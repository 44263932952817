import React, { useEffect, useMemo } from 'react';
import { AutocompleteValue, Box, Grid, TextField } from '@mui/material';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LayoutField } from 'components/form/LayoutField';
import { useClientsByCurrentUser } from 'clients/hooks/useClientsByCurrentUser';
import { useField } from 'formik';
import useUsersByClientIdsQuery from 'admin/user/hooks/useUsersByClientIdsQuery';
import { CurrentUserClientsFromModel } from 'clients/definitions';
import usePrevious from 'hooks/usePrevious';
import colors from 'app/theme/colors.scss';
import { User } from '../../graphql/types';
import FormikAutocompleteField from '../../components/form/FormikAutocompleteField';

interface AddFileReferenceFormProps {
	clientId?: string | null;
}

const AddFileReferenceForm = ({ clientId }: AddFileReferenceFormProps): JSX.Element => {
	const { clientsUsers, loading } = useClientsByCurrentUser(true);

	const [fieldClient, metaClient, helpersClient] = useField<string>('clientId');
	const [, , helpersElpevResponsable] = useField<string>('elpevResponsibleId');
	const { users } = useUsersByClientIdsQuery(fieldClient.value ?? '', ['Fabricant', 'Directeur Clientèle']);

	const handleChange = (value: AutocompleteValue<unknown, undefined, undefined, undefined>) => {
		helpersClient.setValue(((value as unknown) as CurrentUserClientsFromModel)?.id ?? '');
		helpersElpevResponsable.setValue('');
	};
	const prevclientId = usePrevious(clientId);

	useEffect(() => {
		if (clientId && prevclientId !== clientId) helpersClient.setValue(clientId);
	}, [helpersClient, clientId, prevclientId]);

	const clientUser = useMemo(() => clientsUsers?.find(({ id }) => id === clientId), [clientId, clientsUsers]);
	return (
		<Box m={4}>
			<Grid container spacing={5}>
				<Grid item xs={6}>
					<LayoutField label={'Nom du client'}>
						<Box mb={3} bgcolor={!!clientUser ? colors.grey : ''}>
							<Autocomplete<{ name: string }>
								disabled={!!clientUser}
								loading={loading}
								size="small"
								popupIcon={<KeyboardArrowDownIcon />}
								forcePopupIcon
								clearOnBlur
								defaultValue={clientUser}
								getOptionLabel={(option) => (option as { name: string }).name ?? ''}
								onChange={(e, newValue) => handleChange(newValue)}
								options={clientsUsers ?? []}
								renderInput={(params) => (
									<TextField {...params} variant="outlined" error={!!metaClient.error} helperText={metaClient.error} />
								)}
								data-testid="client-autocomplete"
							/>
						</Box>
					</LayoutField>
					<LayoutFieldForm label="Nom de l'opération" name="operationName" testid="operation-name" />
					<LayoutField label="Responsable ELPEV">
						<FormikAutocompleteField<Pick<User, 'id' | 'givenName' | 'familyName'>>
							size="small"
							popupIcon={<KeyboardArrowDownIcon />}
							getOptionLabel={(option) =>
								`${(option as Pick<User, 'id' | 'givenName' | 'familyName'>).familyName} ${
									(option as Pick<User, 'id' | 'givenName' | 'familyName'>).givenName
								}`
							}
							onChange={(e, newValue) => {
								helpersElpevResponsable.setValue((newValue as User)?.id ?? '');
							}}
							isOptionEqualToValue={(option, values) => option.id === values.id}
							options={
								users?.map(({ id, givenName, familyName }) => ({
									id,
									givenName,
									familyName,
								})) ?? []
							}
							testid="elpev-responsible"
						/>
					</LayoutField>
				</Grid>

				<Grid item xs={6}>
					<LayoutFieldForm label="Demander par" name="askBy" testid="ask-by" />
					<LayoutFieldForm label="Référence client" name="clientReference" testid="client-reference" />
				</Grid>
			</Grid>
		</Box>
	);
};

export default AddFileReferenceForm;
