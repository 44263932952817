import { useMutation } from '@apollo/client';
import { UPDATE_CLIENT_BY_COMPANYBASE } from '../graphql/mutations';
import {
	UpdateClientByCompanyBaseMutation,
	UpdateClientByCompanyBaseMutationVariables,
} from '../graphql/mutations.definitions';
import { GET_CLIENT_BY_ID } from '../graphql/queries';

const useClientFromBaseDBUpdate = () => {
	const [updateClientFromBaseMutation] = useMutation<
		UpdateClientByCompanyBaseMutation,
		UpdateClientByCompanyBaseMutationVariables
	>(UPDATE_CLIENT_BY_COMPANYBASE);

	const updateClientFromBaseDB = async (companyBase: string, clientId: string) => {
		const { data } = await updateClientFromBaseMutation({
			variables: { companyBase, clientId },
			refetchQueries: [
				{
					query: GET_CLIENT_BY_ID,
					variables: {
						id: clientId,
					},
				},
			],
		});

		return { clientFromBaseDB: data?.updateClientByCompanyBase };
	};

	return { updateClientFromBaseDB };
};
export default useClientFromBaseDBUpdate;
