import { useMutation } from '@apollo/client';
import { ProductClientPriceCreateInput } from 'graphql/types';
import { UPSERT_CLARIPRINT_CLIENTPRICE } from 'product/graphql/mutations';
import {
	ClariprintClientPriceMutation,
	ClariprintClientPriceMutationVariables,
} from 'product/graphql/mutations.definitions';
import omit from 'ramda/src/omit';
import { GET_ALL_CLIENTS_PRICES } from '../../clientPrice/graphql/queries';

export const useUpsertClariPrintClientPrice = () => {
	const [clariPrintClientPrice] = useMutation<ClariprintClientPriceMutation, ClariprintClientPriceMutationVariables>(
		UPSERT_CLARIPRINT_CLIENTPRICE
	);

	const upsertClariPrintClientPrice = async (product: ProductClientPriceCreateInput) => {
		const { data } = await clariPrintClientPrice({
			variables: {
				filter: {
					product: product.product,
					clientPrice: omit(
						[
							'supplierPrices',
							'transportersPrices',
							'support',
							'clientZipCode',
							'clientPricesExisting',
							'listSupplierPrices',
							'quantitiesIsNotIdentical',
							'isDistribution',
							'createdAt',
							'updatedAt',
							'specificSupplier',
						],
						product.clientPrice
					),
				},
			},
			refetchQueries: [
				{
					query: GET_ALL_CLIENTS_PRICES,
				},
			],
		});

		return {
			product: data,
		};
	};

	return { upsertClariPrintClientPrice };
};
