import { createRef } from 'react';
import { GeSupplierPricesQuery, GetSuppliersPricesQuery, SupplierQuery } from './graphql/queries.definitions';
import { HeadLines } from '../hooks/useManualTabs';
import { QueryLazyOptions } from '@apollo/client';
import { Exact, SupplierPriceFilterInput } from '../graphql/types';
import { GeSupplierPricesOrderItemQuery } from 'order/graphql/queries.definitions';

export const HEADLINES: HeadLines[] = [
	{
		title: '',
		fontSize: 'h2',
	},
];

export const TABS = [
	{
		ref: createRef<HTMLDivElement>(),
		title: 'Informations principales',
	},
	{
		ref: createRef<HTMLDivElement>(),
		title: 'Informations Elpev',
	},
	{
		ref: createRef<HTMLDivElement>(),
		title: 'Informations fournisseur',
	},
	{
		ref: createRef<HTMLDivElement>(),
		title: 'Contact fournisseur',
	},
	{
		ref: createRef<HTMLDivElement>(),
		title: 'Informations techniques',
	},
	{
		ref: createRef<HTMLDivElement>(),
		title: 'Informations commerciales',
	},
];

export enum VATType {
	Français,
	Etrangère,
}

export const PATH = ['Gestion des fiches', 'Fournisseurs'];

export type SupplierPricesFormModel = GetSuppliersPricesQuery['suppliersPrices'][0];
export type SupplierPriceFormModel = GeSupplierPricesQuery['supplierPrices'][0];
export type SupplierFormModel = SupplierQuery['supplier'];
export type SupplierPricesOrderItemFromModel = GeSupplierPricesOrderItemQuery['supplierPrices'][0];
export type GetSupplierPrices = (
	options?:
		| QueryLazyOptions<
				Exact<{
					filters: SupplierPriceFilterInput;
				}>
		  >
		| undefined
) => void;
