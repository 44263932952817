import React, { memo, useMemo } from 'react';
import { CurrentUserClientsFromModel } from '../definition';
import { LayoutField } from 'components/form/LayoutField';
import FormikAutocompleteField from 'components/form/FormikAutocompleteField';
import { Client } from 'graphql/types';
import { isString } from 'utils/typeGuards';
import { HandleSelectClient } from '../../definitions';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import colors from 'app/theme/colors.scss';

type ClientType = Pick<Client, 'name' | 'id'>;

const SelectDataClientQuote = ({
	clientsUsers,
	handleSelectClient,
	id,
	clientId,
	loadingClientsUsers,
}: {
	clientId?: string | null;
	id?: string | null;
	clientsUsers?: CurrentUserClientsFromModel[];
	handleSelectClient: HandleSelectClient;
	loadingClientsUsers?: boolean;
}): JSX.Element => {
	const defaultValue = useMemo(() => {
		const findClient = clientsUsers?.find(({ id }) => id === clientId);

		return {
			name: findClient?.name as string,
			id: findClient?.id as string,
		};
	}, [clientId, clientsUsers]);

	const clientsUsersOptions = useMemo(
		() =>
			clientsUsers
				?.map(({ id, name }) => ({
					id,
					name,
				}))
				.reduce<ClientType[]>((acc, clientUser) => {
					const index = acc.findIndex((client) => client.id === clientUser.id);
					if (index === -1) acc = [...acc, clientUser];
					return acc;
				}, []) ?? [],
		[clientsUsers]
	);

	return (
		<LayoutField label="Nom du client">
			<Box bgcolor={!!id ? colors.grey : ''}>
				<FormControl fullWidth>
					<FormikAutocompleteField<ClientType>
						getOptionLabel={(option) => (option as ClientType).name ?? ''}
						options={clientsUsersOptions}
						loading={loadingClientsUsers}
						value={defaultValue}
						textField={{ size: 'small' }}
						onChange={(_, value) => {
							if (!isString(value)) {
								handleSelectClient(value);
							}
						}}
					/>
				</FormControl>
			</Box>
		</LayoutField>
	);
};

export default memo(SelectDataClientQuote);
