import { gql } from '@apollo/client';

const SUPPLIER_FRAGMENT = gql`
	fragment Supplier on Supplier {
		name
		id
		isActif
		companyBase
		phone
		fax
		email
		website
		comment
		tvaNum
		tvaType
		maxPalletWeight
		updatedBy
		lastConnection
		updatedAt
		createdAt
		rsp
		rfa
		rspStart
		rspEnd
		expressMode
		urgentMode
		urgentModeSupplement
		expressModeSupplement
		rythme
	}
`;

export default SUPPLIER_FRAGMENT;
