import { useQuery } from '@apollo/client';
import { GET_PASSWORD } from '../graphql/queries';
import { GetPasswordQuery, GetPasswordQueryVariables } from '../graphql/queries.definitions';

const usePasswordQuery = () => {
	const { data, refetch: refetchPassword } = useQuery<GetPasswordQuery, GetPasswordQueryVariables>(GET_PASSWORD);

	return { password: data?.password.password, refetchPassword };
};

export default usePasswordQuery;
