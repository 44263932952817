import {
	ButtonType,
	CharacteristicsQuoteAttribute,
	Client,
	ClientPrice,
	ClientQuote,
	ClientQuoteItem,
	ClientQuoteKey,
	ElpevSite,
	QuoteAttributesConfig,
	QuoteType,
} from '../../graphql/types';
import {
	AccountModel,
	CurrentUserClientsFromModel,
	QuotePreviewDataProp,
	QuotePreviewTable,
	SummaryPriceType,
	GroupeByRadioButtonType,
	OptionsButtonType,
	CharacteristicsType,
	ProductListModel,
} from './definition';
import { CORRESPONDENCES_ELPEV_SITE } from './quotePreview/visualization-quote/definition';
import produce from 'immer';
import { CurrentUser } from 'admin/user/definitions';
import { formatNumberDisplay } from 'utils/prices';
const _cloneDeep = require('lodash.clonedeep');

export const getNumberVersion = (
	clientPricesExisting?:
		| Array<
				{ __typename?: 'ClientPrice' } & Pick<
					ClientPrice,
					| 'quantity'
					| 'numberVersion'
					| 'transportPurchasePriceExcludingVAT'
					| 'transportSalesPriceExcludingVAT'
					| 'productSalesPriceExcludingVAT'
					| 'productPurchasePriceExcludingVAT'
					| 'cost'
				>
		  >
		| undefined
) =>
	clientPricesExisting && clientPricesExisting?.length
		? clientPricesExisting.reduce<number[]>((acc, current) => {
				const index = acc.findIndex((version) => version === current.numberVersion);
				if (index === -1) acc.push(current.numberVersion);
				return acc;
		  }, [])
		: [1];

const initial = {
	productPurchasePriceExcludingVAT: 0,
	productSalesPriceExcludingVAT: 0,
	quantity: 0,
	cost: 0,
	transportPurchasePriceExcludingVAT: 0,
	transportSalesPriceExcludingVAT: 0,
	estimatedCiteoTax: 0,
};

export const sum = (clientQuoteItem: SummaryPriceType[] | null) => {
	return (
		clientQuoteItem?.reduce(
			(accumulator, currentValue) => ({
				productPurchasePriceExcludingVAT:
					(accumulator.productPurchasePriceExcludingVAT ?? 0) + (currentValue?.productPurchasePriceExcludingVAT ?? 0),
				productSalesPriceExcludingVAT:
					(accumulator.productSalesPriceExcludingVAT ?? 0) + (currentValue?.productSalesPriceExcludingVAT ?? 0),
				quantity: accumulator.quantity + currentValue.quantity,
				cost: (accumulator?.cost ?? 0) + (currentValue?.cost ?? 0),
				transportPurchasePriceExcludingVAT:
					(accumulator.transportPurchasePriceExcludingVAT ?? 0) +
					(currentValue?.transportPurchasePriceExcludingVAT ?? 0),
				transportSalesPriceExcludingVAT:
					(accumulator.transportSalesPriceExcludingVAT ?? 0) + (currentValue?.transportSalesPriceExcludingVAT ?? 0),
				estimatedCiteoTax: (accumulator.estimatedCiteoTax ?? 0) + (currentValue?.estimatedCiteoTax ?? 0),
			}),
			initial
		) ?? initial
	);
};

export const getFullName = (user?: CurrentUser) => (user ? `${user.familyName} ${user.givenName}` : '');

export const getElpevSite = (key: keyof typeof ElpevSite) => CORRESPONDENCES_ELPEV_SITE[ElpevSite[key]];

export const handleDataQuotePreview = (
	clientsUsers?: CurrentUserClientsFromModel[],
	values?: ClientQuote & {
		clients?: Client[];
		clientQuoteCreatedAt?: Date;
	},
	user?: CurrentUser,
	quoteAttributes: QuoteAttributesConfig[] = []
) => {
	let previewsData: QuotePreviewDataProp | undefined;

	(clientsUsers as CurrentUserClientsFromModel[]).forEach((clientUser) => {
		if (clientUser.id === values?.clientId && user) {
			previewsData = {
				numberQuote: values?.id ?? '',
				version: values.version === 0 ? 1 : values.version,
				type: values.type ?? '',
				billingAddress: values.billingAddress,
				createdAt: values?.clientQuoteCreatedAt,
				comment: values?.comment ?? '',
				quoteRequestedBy: values.quoteRequestedBy ?? '',
				clientQuoteItem: values.clientQuoteItem ?? [],
				quoteAttributes,
				infosElpev: [
					{ elpevUser: getFullName(user), label: 'Contact Elpev' },
					{ phoneNumber: user?.phoneNumber, label: 'Téléphone' },
					{ quoteRequestedBy: values.fileReference?.askBy, label: 'Demandé par' },
				],
				operations: [
					{ operationName: values.operationName, label: "Nom de l'opération " },
					{ clientReference: values.fileReference?.clientReference, label: 'Référence client' },
					{
						fileDeliveryDate: values.fileDeliveryDate ? new Date(values.fileDeliveryDate).toLocaleDateString() : '',
						label: 'Date de remise des fichiers',
					},
					{
						deliveryDate: values.deliveryDate ? new Date(values.deliveryDate).toLocaleDateString() : '',
						label: 'Date de livraison',
					},
				],
				account: [],
				sousReserveDeRepartitionDefinitve: '',
			};
		}
	});

	if (previewsData) {
		previewsData.account = getClientQuoteTotalPrice(quoteAttributes, values);
		previewsData.sousReserveDeRepartitionDefinitve =
			getStatusSousReserveDeRepartitionDefinitve(quoteAttributes)[0] ?? '';
	}

	((previewsData as unknown) as QuotePreviewDataProp).clientInvoice = values?.clients?.filter(
		(invoice) => invoice.id === values.clientId
	)[0];

	return previewsData;
};

const getStatusSousReserveDeRepartitionDefinitve = (quoteAttributes: QuoteAttributesConfig[]) => {
	return quoteAttributes.reduce<string[]>((acc, current) => {
		if (current.familiesGroup === 'Prix produit') {
			current.familyAttributes.forEach((familyAttribute) => {
				familyAttribute.characteristics.forEach((characteristic) => {
					if (characteristic.clientQuoteItemKey === 'sousReserveDeRepartitionDefinitve') {
						acc = [characteristic.name];
					}
				});
			});
		}
		return acc;
	}, []);
};

const getClientPriceTotal = (
	{ clientQuoteKey }: CharacteristicsQuoteAttribute,
	values?: ClientQuote & { clients?: Client[]; clientQuoteCreatedAt?: Date }
) => {
	switch (clientQuoteKey) {
		case 'cost':
			return Number(values?.totalSalesPriceExcludingVAT) ?? 0;
		case 'vatRate':
			return values?.vatRate ?? 0;
		case 'vatAmount':
			return Number(values?.totalSalesPriceVAT) ?? 0;
		case 'estimatedCiteoTax':
			return Number(values?.estimatedCiteoTax) ?? 0;
		case 'totalSalesPriceVAT':
			return Number(values?.vatAmount) ?? 0;
		default:
			return 0;
	}
};

const getClientQuoteTotalPrice = (
	quoteAttributes: QuoteAttributesConfig[],
	values?: ClientQuote & { clients?: Client[]; clientQuoteCreatedAt?: Date }
) =>
	quoteAttributes.reduce<AccountModel[]>((acc, current) => {
		if (current.familiesGroup === 'Total') {
			current.familyAttributes.forEach((familyAttribute) =>
				familyAttribute.characteristics.forEach((characteristic) => {
					acc = [
						...acc,
						{
							name: characteristic.name,
							value: formatNumberDisplay(getClientPriceTotal(characteristic, values), 2) as number,
						},
					];
				})
			);
		}
		return acc;
	}, []);

const getAttributeValue = (
	index: number,
	characteristic: CharacteristicsQuoteAttribute,
	clientQuote?:
		| (Pick<ClientQuote, 'clientQuoteItem' | 'vatRate' | ClientQuoteKey> & { products?: ProductListModel | null })
		| null
) => {
	if (clientQuote && clientQuote.clientQuoteItem && Array.isArray(clientQuote.clientQuoteItem)) {
		if (characteristic.key) {
			if (characteristic.key === 'typeSupport') {
				if (clientQuote.products?.length) {
					const productId = clientQuote.clientQuoteItem[index]['product'].id;
					const supportId = clientQuote.clientQuoteItem[index]['product'].supportId;

					const supportAttached = clientQuote.products.find(
						({ product }) => product && product.id === productId && product.supportId === supportId
					);
					return supportAttached?.product?.support.name ?? '-';
				}
			}
			return clientQuote.clientQuoteItem[index]['product'][characteristic.key] ?? '-';
		} else if (characteristic.clientQuoteItemKey) {
			switch (characteristic.clientQuoteItemKey) {
				case 'prixCentPlusHT':
					return (clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixExemplaireSupp'] * 100).toFixed(3);
				case 'prixMillePlusHT':
					return (clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixExemplaireSupp'] * 1000).toFixed(2);
				case 'prixCentMoinsHT':
					return (clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixExemplaireSupp'] * -100).toFixed(3);
				case 'prixMilleMoinsHT':
					return (clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixExemplaireSupp'] * -1000).toFixed(2);
				case 'prixExSupHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixExemplaireSupp'].toFixed(5);
				case 'prixHorsCalageHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixHorsCalage'].toFixed(2);
				case 'prixCalageHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixCalage'].toFixed(2);
				case 'prixChgtParPlaqueHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixChangementPlaque'].toFixed(2);
				case 'prixTraitementFichierHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixTraitementFichier'].toFixed(2);
				case 'autrePrixFixeHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['autrePrixFixe'].toFixed(2);
				case 'prixClicheHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixCliche'].toFixed(2);
				case 'prixOutillageHT':
					return clientQuote.clientQuoteItem[index].sellingPriceWithMargin['prixOutillage'].toFixed(2);
				case 'nbDePointDeLivraison':
					return clientQuote.clientQuoteItem[index].nbShippingPoints;
				case 'weight':
					return clientQuote.clientQuoteItem[index].productTotalWeight;
				case 'productPurchasePriceExcludingVAT':
					return (clientQuote.clientQuoteItem[index].productSalesPriceExcludingVAT ?? 0).toFixed(2);
				case 'montantRFA':
					return clientQuote.clientQuoteItem[index].rfaClientEuro ?? 0;
				case 'transportSalesPriceExcludingVAT':
					return (clientQuote.clientQuoteItem[index].transportSalesPriceExcludingVAT ?? 0).toFixed(2);
				case 'prixPrepresseHT':
					return (clientQuote.clientQuoteItem[index].prepressSalesPriceExcludingVat ?? 0).toFixed(2);
				case 'transportInclus':
				case 'prepresseInclus':
					return 'Inclus';
				case 'transportNonInclus':
				case 'prepresseNonInclus':
					return 'Non Inclus';
				case 'prepresseNonApplicable':
					return 'Non applicable';
				case 'comment':
					return clientQuote.clientQuoteItem[index].comment ?? '-';
				case 'prixHT':
					return (clientQuote.clientQuoteItem[index].cost ?? 0).toFixed(2);
				case 'tauxTVA':
					return (clientQuote.vatRate ?? 0).toFixed(2);
				case 'montantTVA':
					return (clientQuote.clientQuoteItem[index].vatAmount ?? 0).toFixed(2);
				case 'prixTTC':
					return (clientQuote.clientQuoteItem[index].sellingPriceIncludingTax ?? 0).toFixed(2);
				case 'montantTaxeCiteo':
					return (clientQuote.clientQuoteItem[index].estimatedCiteoTax ?? 0).toFixed(2);
				case 'quantity':
					return formatNumberDisplay(clientQuote.clientQuoteItem[index][characteristic.clientQuoteItemKey], 0) ?? 0;
				case 'versionNumber':
					return clientQuote.clientQuoteItem[index]['numberVersion'];
				default:
					return '-';
			}
		} else {
			if (characteristic.clientQuoteKey) {
				return clientQuote[characteristic.clientQuoteKey] ?? '';
			} else return '';
		}
	} else return '';
};

export const setQuoteValuesToAttributes = (
	quoteAttributesConfig?: QuoteAttributesConfig[],
	clientQuote?:
		| (Pick<ClientQuote, 'clientQuoteItem' | 'vatRate' | ClientQuoteKey> & { products?: ProductListModel | null })
		| null
) => {
	let attributeConfigFormated: QuotePreviewTable = {
		attribute: {
			characteristics: [{ value: '', key: null, clientQuoteItemKey: null, clientQuoteKey: null }],
			family: '',
		},
	};

	const modifyLabelButtonRadio = (label: string) => {
		const transports = ['Prix transport HT (€)', 'Transport inclus', 'Transport non inclus'];
		const prepress = ['Prix prépresse HT (€)', 'Prépresse inclus', 'Prépresse non inclus', 'Prépresse non applicable'];

		if (transports.includes(label)) return 'Transport HT (€)';
		if (prepress.includes(label)) return 'Prépresse (€)';
		return label;
	};

	if (clientQuote && clientQuote.clientQuoteItem && clientQuote?.clientQuoteItem.length) {
		quoteAttributesConfig?.forEach((attributeConfig) => {
			attributeConfig.familyAttributes.forEach((familyAttribute) => {
				familyAttribute.characteristics.forEach((characteristic) => {
					clientQuote?.clientQuoteItem?.forEach((quote, i) => {
						const quoteIdentifier = `${quote.productName}-${i}`;
						const characteristicIndex = attributeConfigFormated.attribute.characteristics.findIndex((attribute) => {
							return (attribute.value as string).toLowerCase() === characteristic.name.toLowerCase();
						});
						if (characteristicIndex === -1) {
							attributeConfigFormated.attribute = {
								family: familyAttribute.family.toLowerCase() === 'produit' ? 'Nom de produit' : familyAttribute.family,
								characteristics: [
									...attributeConfigFormated.attribute.characteristics,
									{
										value: characteristic.name ? modifyLabelButtonRadio(characteristic.name) : ' ',
										key: characteristic.key ?? null,
										clientQuoteItemKey: characteristic.clientQuoteItemKey ?? null,
										clientQuoteKey: characteristic.clientQuoteKey ?? null,
									},
								],
							};
						}
						if (!(quoteIdentifier in attributeConfigFormated)) {
							attributeConfigFormated[quoteIdentifier] = {
								family: quote.productName,
								characteristics: [
									{
										value: getAttributeValue(i, characteristic, clientQuote),
										key: characteristic.key ?? null,
										clientQuoteItemKey: characteristic.clientQuoteItemKey ?? null,
										clientQuoteKey: characteristic.clientQuoteKey ?? null,
									},
								],
							};
						} else {
							const index = attributeConfigFormated[quoteIdentifier].characteristics.findIndex(
								(attribute) => attribute.value === characteristic.name
							);
							if (index === -1) {
								attributeConfigFormated[quoteIdentifier] = {
									family: quote.productName,
									characteristics: [
										...attributeConfigFormated[quoteIdentifier]?.characteristics,
										{
											value: getAttributeValue(i, characteristic, clientQuote),
											key: characteristic.key ?? null,
											clientQuoteItemKey: characteristic.clientQuoteItemKey ?? null,
											clientQuoteKey: characteristic.clientQuoteKey ?? null,
										},
									],
								};
							}
						}
					});
				});
			});
		});
	}

	const deleteFieldsNotIncludedInQuote = <T extends { clientQuoteItemKey: string | null; key: string | null }>(
		data: T[]
	) => {
		return data.filter((characteristic) => {
			return (
				characteristic.clientQuoteItemKey !== 'productName' &&
				characteristic.clientQuoteItemKey !== 'sousReserveDeRepartitionDefinitve'
			);
		});
	};

	attributeConfigFormated.attribute.characteristics.shift();

	attributeConfigFormated.attribute.characteristics = deleteFieldsNotIncludedInQuote(
		attributeConfigFormated.attribute.characteristics
	);
	const attribute = attributeConfigFormated.attribute;
	let attributeConfigSort: QuotePreviewTable = {};

	Object.keys(attributeConfigFormated)
		.filter((key) => key !== 'attribute')
		.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
		.forEach((attribute) => {
			attributeConfigSort[attribute] = attributeConfigFormated[attribute];
		});
	Object.keys(attributeConfigSort).forEach((key) => {
		attributeConfigSort[key].characteristics = deleteFieldsNotIncludedInQuote(attributeConfigSort[key].characteristics);
	});

	if (attribute.family === 'Prix de transport' || attribute.family === 'Prix prépresse') {
		attribute.characteristics = attribute.characteristics.reduce<CharacteristicsType[]>((acc, current) => {
			const index = acc.findIndex((characteristic) => characteristic.value === current.value);
			if (index === -1) acc = [...acc, current];
			return acc;
		}, []);
	}
	return [{ attribute, ...attributeConfigSort }];
};

export const getCheckedAttributes = (quoteConfig: QuoteAttributesConfig[], clientId?: string) => {
	let clientQuoteConfig: { attributeId: string; clientId: string }[] = [];
	quoteConfig.forEach((quote) => {
		quote.familyAttributes.forEach((familyAttribute) => {
			familyAttribute.characteristics.forEach((characteristic) => {
				if (characteristic.isChecked) {
					clientQuoteConfig.push({
						attributeId: characteristic.attributeId,
						clientId: clientId ?? '',
					});
				}
			});
		});
	});

	return clientQuoteConfig;
};

export const getOnlyAttributesWithQuotePreviewValues = (quoteConfig: QuoteAttributesConfig[]) => {
	return produce(quoteConfig, (draft) => {
		draft.filter((quote) => {
			quote.familyAttributes = quote.familyAttributes.filter((attribute) => {
				attribute.characteristics = attribute.characteristics.filter((characteristic) => characteristic.isChecked);
				return attribute.characteristics.length;
			});
			return quote.familyAttributes.length;
		});
		return draft;
	}).reduce<QuoteAttributesConfig[]>((acc, current) => {
		if (current.familiesGroup === 'Caractéristiques du support' || current.familiesGroup === 'Prix') {
			current.familyAttributes.forEach((familyAttribute) => {
				acc = [
					...acc,
					{
						familiesGroup: familyAttribute.family,
						familyAttributes: [familyAttribute],
					},
				];
			});
		} else acc.push(current);
		return acc;
	}, []);
};

export const handleQuoteTitleConfig = (title: string) => {
	switch (title) {
		case 'Total':
			return title;
		case 'Informations produit':
			return 'Produit';
		default:
			return null;
	}
};

export const groupByRadioButton = (characteristics: CharacteristicsQuoteAttribute[], family: string) => {
	return characteristics.reduce<GroupeByRadioButtonType>((acc, current) => {
		if (current.type === ButtonType.Radio) {
			const index = acc.findIndex((characteristic) => characteristic.options);
			if (index !== -1 && acc[index].options) {
				if (current.isChecked) acc[index].defaultValue = current.name;
				acc[index].options = [
					...(acc[index].options as Array<OptionsButtonType>),
					{
						label: current.name,
						value: current.name,
						attributeId: current.attributeId,
					},
				];
			} else {
				acc = [
					...acc,
					{
						...current,
						defaultValue: current.isChecked ? current.name : '',
						name: family !== 'Prix de transport' ? 'prepressRadio' : 'transportRadio',
						options: [
							{
								label: current.name,
								value: current.name,
								attributeId: current.attributeId,
							},
						],
					},
				];
			}
		} else acc.push(current);
		return acc;
	}, []);
};

export const ClientQuoteOptions = [
	{ label: 'Valider un prix de vente', value: QuoteType.ClientPriceToBeValidate },
	{ label: 'Réaliser une commande client', value: QuoteType.ClientOrderToBeCreate },
];

export const setClientPricesToQuoteItems = (clientQuoteItems: ClientQuoteItem[], prices: ClientPrice[]) => {
	const clientQuoteDistributionCopy = _cloneDeep(clientQuoteItems);
	for (const price of prices) {
		const quoteItem = clientQuoteDistributionCopy?.find((item: ClientQuoteItem) => item.product.id === price.productId);
		if (!quoteItem) {
			continue;
		}
		quoteItem.productPurchasePriceExcludingVAT = price.productPurchasePriceExcludingVAT;
		quoteItem.productSalesPriceExcludingVAT = price.productSalesPriceExcludingVAT;
		quoteItem.transportPurchasePriceExcludingVAT = price.transportPurchasePriceExcludingVAT;
		quoteItem.transportSalesPriceExcludingVAT = price.transportSalesPriceExcludingVAT;
		quoteItem.sellingPriceMargin = price.sellingPriceMargin;
		quoteItem.cost = (price.productSalesPriceExcludingVAT || 0) + (price.transportSalesPriceExcludingVAT || 0);
		const vatRate = quoteItem.vatRate ?? 20;
		quoteItem.vatAmount = (quoteItem.cost * vatRate) / 100;
		quoteItem.sellingPriceIncludingTax = quoteItem.cost + quoteItem.vatAmount;
	}

	return clientQuoteDistributionCopy;
};
