import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import colors from '../../app/theme/colors.scss';
import { fonts } from '../../app/theme/theme';

export const LineTabs = withStyles({
	root: {
		paddingLeft: (props: { marginleft?: string }) => props.marginleft || '125px',
		borderBottom: '1px solid ',
		borderBottomColor: colors.gray200,
	},
	indicator: {
		backgroundColor: 'transparent',
	},
})(Tabs);

export const LineTab = withStyles(() =>
	createStyles({
		root: {
			...fonts.h4,
			color: colors.primary,
			opacity: 1,
			padding: 'O',
			'&$selected': {
				border: '1px solid',
				borderBottom: 'transparent',
				borderTopRightRadius: '6px',
				borderTopLeftRadius: '6px',
				borderColor: colors.gray200,
			},
		},
		selected: {
			color: colors.gray100,
		},
	})
)((props: TabProps) => <Tab {...props} disableRipple />);
