import { useQuery } from '@apollo/client';
import { GET_SUPPORTS } from '../graphql/queries';
import { GetSupportsQuery, GetSupportsQueryVariables } from '../graphql/queries.definitions';
import { SupportFiltersInput } from '../../../graphql/types';

const useSupportsQuery = (filters?: SupportFiltersInput) => {
	const { data } = useQuery<GetSupportsQuery, GetSupportsQueryVariables>(GET_SUPPORTS, {
		variables: { filters },
	});
	return { supports: data?.supports };
};

export default useSupportsQuery;
