import { Dispatch, SetStateAction } from 'react';
import {
	ClientPriceStatus,
	MarginType,
	Product,
	BoxShippingMode,
	TransportType,
	TransportMoment,
} from '../../graphql/types';

import { ClientPricesByProductNameAndClientIdQuery } from '../graphql/queries.definitions';

export interface IsShowSellingPriceSelectedProps {
	isConsultSellingPrice: boolean;
	supportId?: string;
}

export interface OnSelectStatusSellingPriceProps {
	onSelectStatusSellingPrice: Dispatch<SetStateAction<boolean>>;
}

export type ProductProps = Pick<Product, 'codeAt' | 'id'>;

export type FilterClientPrice = ClientPricesByProductNameAndClientIdQuery['clientPricesExisting'][0];

export type DistributionsDetails = {
	__typename?: 'Distribution';
	zip?: string;
	quantity?: number;
	weight: number;
	label?: string;
	deliveryParcelPurchasePrice?: number;
};

export enum StatusClientPrice {
	InProgress = 'En cours',
	WaitingByValidationQuote = 'En attente de validation client',
	Obsolete = 'Obsolète',
	ValidatedByCustomerQuote = 'Validé par devis client',
}

export enum Tirage {
	Identique = 'Identique',
	Different = 'Différent',
}

export enum TANSPORTMOMENT {
	InWeek = 'En semaine',
	Saturday = 'Le samedi',
}

export const status = [
	ClientPriceStatus.InProgress,
	ClientPriceStatus.WaitingByValidationQuote,
	ClientPriceStatus.Obsolete,
	ClientPriceStatus.ValidatedByCustomerQuote,
];

export const DEFAULT_MAX_BOX_WEIGHT = 15;

export const initialClientPrice = {
	productName: '',
	productId: '',
	clientId: '',
	isIdentical: true,
	quantities: [],
	quantitiesIsNotIdentical: [],
	mainRfaRate: 0,
	createdAt: null,
	updatedAt: null,
	isWithRfaClient: true,
	isPrepress: false,
	quantity: 1,
	cost: 0,
	franco: false,
	validatedQuoteDate: null,
	defaultCommercialProductMargin: 0,
	defaultCommercialShippingMargin: 0,
	status: ClientPriceStatus.InProgress,
	numberPlateChanges: 0,
	numberFileProcessing: 0,
	numberVersion: 1,
	numberShots: 0,
	productTotalWeight: 0,
	tools: false,
	distributions: [],
	nbShippingPoints: 2,
	supplierPrices: null,
	buyingPriceWithAmalgam: 0,
	additionalMargins: {
		prixCalage: MarginType.Percent,
		prixHorsCalage: MarginType.Percent,
		prixExemplaireSupp: MarginType.Percent,
		prixChangementPlaque: MarginType.Percent,
		prixTraitementFichier: MarginType.Percent,
		autrePrixFixe: MarginType.Percent,
		prixCliche: MarginType.Percent,
		prixOutillage: MarginType.Percent,
	},
	margins: {
		prixCalage: 0,
		prixHorsCalage: 0,
		prixExemplaireSupp: 0,
		prixChangementPlaque: 0,
		prixTraitementFichier: 0,
		autrePrixFixe: 0,
		prixCliche: 0,
		prixOutillage: 0,
	},
	purchasePriceExcludingTax: {
		prixCalage: 0,
		prixHorsCalage: 0,
		prixExemplaireSupp: 0,
		prixChangementPlaque: 0,
		prixTraitementFichier: 0,
		autrePrixFixe: 0,
		prixCliche: 0,
		prixOutillage: 0,
	},

	sellingPriceWithMargin: {
		prixCalage: 0,
		prixHorsCalage: 0,
		prixExemplaireSupp: 0,
		prixChangementPlaque: 0,
		prixTraitementFichier: 0,
		autrePrixFixe: 0,
		prixCliche: 0,
		prixOutillage: 0,
	},
	deliveryParcel: BoxShippingMode.BeforeThirteen,
	maxBoxWeight: DEFAULT_MAX_BOX_WEIGHT,
	bestDeliveryParcelPurchasePrice: 0,
	transportPurchasePriceHt: 0,
	transportSellingPriceHtAdditionalMargin: MarginType.Percent,
	transportRfa: 0,
	transportSellingPriceHt: 0,
	transportSellingPriceByDefaultHt: 0,
	corsicaDeliveryExtra: 0,
	isMechanizable: false,
	transportersPrices: [],
	transportMoment: TransportMoment.InWeek,
	transportType: TransportType.DeliveryByParcel,
	transportSellingPriceMargin: 0,
	transportPurchasePriceHtWithRfa: 0,
	isFinalDistribution: false,
	isIncluded: false,
	productSalesPriceExcludingVATWithRfa: 0,
	sellingPriceMarginRate: 0,
	rfaClientEuro: 0,
	transporter: {
		id: '',
		name: '',
	},
	isDistribution: false,
};

export const CLIENT_PRICES_HEAD_CELLS: { key: string; label: string; sort: boolean }[] = [
	{ key: 'productName', label: 'Nom du produit', sort: true },
	{ key: 'quantity', label: 'Quantité', sort: true },
	{ key: 'nbShippingPoints', label: 'Nombre de point de distribiution', sort: true },
	{ key: 'productSalesPriceExcludingVAT', label: 'PV produit HT (€)', sort: true },
	{ key: 'productPurchasePriceExcludingVAT', label: 'PA produit HT (€)', sort: true },
	{ key: 'productDifferencePrice', label: 'Différence produit (€)', sort: true },
	{ key: 'transportSalesPriceExcludingVAT', label: 'PV Transport HT (€)', sort: true },
	{ key: 'transportPurchasePriceExcludingVAT', label: 'PA Transport HT (€)', sort: true },
	{ key: 'transportDifferencePrice', label: 'Différence transport (€)', sort: true },
	{ key: 'cost', label: 'Prix de vente', sort: true },
	{ key: 'sellingPriceMargin', label: 'Marge', sort: true },
];

export const TRANSPORTMOMENT = [
	{ value: TransportMoment.InWeek, label: 'En semaine' },
	{ value: TransportMoment.Saturday, label: 'Le samedi' },
];

export const TOTAL_WEIGHT_PERCENT_VALUE = 8;
export const MAX_BOX_WEIGHT = 30;
export const MAX_UNIT_WEIGHT_GR = 15000;

export const DELIVERY_BY_PARCEL = 'Livraison par colis';
export const TRANSPORT_BY_SUPPLIER = 'Transport par le fournisseur';
export const WITHOUT_TRANSPORT = 'Sans transport';

export enum Transport {
	deliveryByParcel = 'Livraison par colis',
	trasnportBySupplier = 'Transport par le fournisseur',
	withoutTransport = 'Sans transport',
}
