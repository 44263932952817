import { sum } from 'ramda';
import subtractPercentageToValue from '../../../../../utils/subtractPercentageToValue';
import { OrderItemFormModel } from '../../../order-cockpit/definition';

export const getSupplierPricesByProduct = (orderIrem: OrderItemFormModel[], sellingPriceHt: number) => {
	const buyingPriceHtWithRfa = sum(
		orderIrem?.map(({ totalPurchasePriceExcludingTax }) => totalPurchasePriceExcludingTax)
	);

	const buyingPriceHt =
		sum(orderIrem?.map(({ transporterPurchasePriceExcludingVAT }) => transporterPurchasePriceExcludingVAT)) +
		sum(
			orderIrem.map(({ supplierRspRate, productPurchasePriceExcludingVAT }) =>
				subtractPercentageToValue(supplierRspRate, productPurchasePriceExcludingVAT)
			)
		);

	const marginWithClientRfa = sellingPriceHt - buyingPriceHt;
	const realMargin = sellingPriceHt - buyingPriceHtWithRfa;
	const marginRate = (realMargin * 100) / sellingPriceHt ?? 0;

	return { buyingPriceHtWithRfa, buyingPriceHt, marginWithClientRfa, realMargin, marginRate };
};
