import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from 'graphql/fragments/product/productFragment';

import CLIENT_QUOTE_FRAGMENT from '../../graphql/fragments/client-quote/clientQuoteFragment';
import FILE_REFERENCE_FRAGMENT from '../../graphql/fragments/file-reference/fileReferenceFragment';

export const GET_PRODUCTS_BY_CLIENT_ID = gql`
	query ClientPricesByClientId($filter: ClientPricesFiltersInput!) {
		products: clientPricesByClientId(filter: $filter) {
			productName
			productId
			quantity
			status
			product {
				...Product
			}
		}
	}
	${PRODUCT_FRAGMENT}
`;

export const GET_CLIENT_QUOTES = gql`
	query GetClientQuotes($filters: ClientQuoteFilterInput, $sorts: ClientQuoteSortInput, $pagination: PaginationInput) {
		clientQuotes(filters: $filters, sorts: $sorts, pagination: $pagination) {
			clientQuotes {
				id
				clientReference
				clientQuoteItem {
					quantity
				}
				client {
					id
					name
					elpevSite
					translation
				}
				createdAt
				fileReferenceId
				fileReference {
					id
					elpevResponsible {
						givenName
						familyName
					}
					askBy
					clientReference
					operationName
				}
				operationName
				quoteRequestedBy
				status
				totalSalesPriceExcludingVAT
				type
				updatedAt
			}
			total
		}
	}
`;

export const GET_CLIENT_QUOTE_BY_ID = gql`
	query GetClientQuoteById($id: String!) {
		clientQuote(id: $id) {
			...clientQuote
			fileReference {
				...FileReference
			}
		}
	}
	${CLIENT_QUOTE_FRAGMENT}
	${FILE_REFERENCE_FRAGMENT}
`;

export const GET_CONFIG_QUOTE = gql`
	query GetQuoteAttributes($clientId: String!) {
		quoteAttributes(clientId: $clientId) {
			familiesGroup
			familyAttributes {
				family
				characteristics {
					attributeId
					isChecked
					name
					key
					type
					clientQuoteKey
					clientQuoteItemKey
				}
			}
		}
	}
`;

export const FILTERED_CLIENT_QUOTES = gql`
	query GetClientQuotesFiltered($filters: ClientQuoteFilterInput!) {
		filteredClientQuotes(filters: $filters) {
			id
			version
			totalSalesPriceExcludingVAT
		}
	}
`;

export const GET_CLIENT_PRICES_FROM_DISTRIBUTIONS = gql`
	query GetClientPricesFromDistributions($filters: ClientPricesDistributionsInput!) {
		getClientPricesFromDistributions(filters: $filters) {
			productId
			productSalesPriceExcludingVAT
			productPurchasePriceExcludingVAT
			transportSalesPriceExcludingVAT
			transportPurchasePriceExcludingVAT
			sellingPriceMargin
		}
	}
`;
