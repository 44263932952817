import React, { useEffect, useMemo } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import FormikAutocompleteField from '../../../components/form/FormikAutocompleteField';
import { isString } from '../../../utils/typeGuards';
import { LayoutField } from '../../../components/form/LayoutField';
import useClientShippingAddressQuery from '../../../clients/hooks/useClientShippingAddressQuery';
import { Client } from '../../../graphql/types';
import useClientAndAllChildrenQuery from '../../../clients/hooks/useClientAndAllChildrensQuery';
import { useField } from 'formik';
import { FormikSwitchField } from '../../../components/form/FormikSwitchField';
import Loading from '../../../components/loading/Loading';
import DashedHeader from '../../../components/DashedHeader';
import usePrevious from '../../../hooks/usePrevious';
import { ComponentTitle } from '../../definition';

import {
	ProductInformationProvider,
	useProductInformation,
} from '../order-cockpit/product-information/ProductInformationContext';
import OrderDistributionTable from './OrderDistributionTable';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import { Distribution } from 'utils/distribution';
import usePopin from 'hooks/usePopin';

const DeliveryInformation = ({ title }: ComponentTitle) => {
	const [fieldClientId] = useField<string | undefined>(`clientId`);
	const { clients } = useClientAndAllChildrenQuery(fieldClientId.value ?? '');
	const [fieldClient, , helpersClient] = useField<string | undefined>(`orderDistributions[${0}].clientId`);
	const [fieldClientQuoteId] = useField<string | undefined>(`clientQuoteId`);
	const [, , helpersOrderDistributions] = useField<string | undefined>(`orderDistributions`);
	const [fieldClientShippingAddressId, , helpersClientShippingAddressId] = useField<string | undefined>(
		`orderDistributions[${0}].clientShippingAddressId`
	);
	const [, , helpersClientShippingAddressName] = useField<string | undefined>(
		`orderDistributions[${0}].clientShippingAddressName`
	);

	const [fieldIsDistribution] = useField<boolean>('isDistribution');
	const { clientShippingAddress, loading } = useClientShippingAddressQuery(fieldClient.value);

	const shippingAddress = useMemo(
		() => clientShippingAddress?.find(({ id }) => id === fieldClientShippingAddressId.value),
		[clientShippingAddress, fieldClientShippingAddressId.value]
	);

	const defaultClient = useMemo(() => {
		const findClient = clients?.find(({ id }) => id === (fieldClient.value || fieldClientId.value));

		return {
			name: findClient?.name as string,
			id: findClient?.id as string,
		};
	}, [clients, fieldClient.value, fieldClientId.value]);

	const defaultClientShippingAddress = useMemo(() => {
		const findClient = clientShippingAddress?.find(({ id }) => id === fieldClientShippingAddressId.value);
		return {
			name: findClient?.address?.name as string,
			id: findClient?.id as string,
		};
	}, [clientShippingAddress, fieldClientShippingAddressId.value]);

	const prevIsDistribution = usePrevious(fieldIsDistribution.value);

	const prevClient = usePrevious(fieldClient.value);

	const { clientQuote } = useProductInformation();

	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Répartition des produits par magasins',
		maxWidth: 'xl',
		fullWidth: true,
		isDisplayHeader: true,
	});

	useEffect(() => {
		if (prevIsDistribution !== fieldIsDistribution.value) {
			helpersOrderDistributions.setValue(undefined);
			helpersClient.setValue(fieldClientId.value);
		}
	}, [fieldIsDistribution.value, helpersOrderDistributions, prevIsDistribution, fieldClientId.value, helpersClient]);

	useEffect(() => {
		if (prevClient !== fieldClient.value && clientShippingAddress) {
			helpersClientShippingAddressId.setValue(clientShippingAddress?.find(({ isMain }) => isMain)?.id);
		}
	}, [clientShippingAddress, helpersClientShippingAddressId, fieldClient.value, prevClient]);

	return (
		<ProductInformationProvider>
			<Box mb={5} mt={5}>
				<Box mb={6}>
					<Grid container>
						<Grid item xs={1} />
						<Grid item>
							<Typography variant="h2">{title}</Typography>
						</Grid>
					</Grid>
				</Box>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={3}>
						<Box mb={2}>
							<LayoutField label="Répartition à prévoir">
								<FormikSwitchField
									name="isDistribution"
									value={fieldClientQuoteId.value ? clientQuote?.isDistribution : false}
									disabled
								/>
							</LayoutField>
						</Box>
						{!clientQuote?.isDistribution || !fieldClientQuoteId.value ? (
							<>
								<LayoutField label="Nom du client">
									<Box mb={3}>
										<FormikAutocompleteField<Pick<Client, 'name' | 'id'>>
											disabled={fieldIsDistribution.value}
											name={`orderDistributions[${0}].clientShippingAddressId`}
											value={!fieldIsDistribution.value ? defaultClient : ''}
											options={
												clients?.map(({ id, name }) => ({
													id,
													name,
												})) ?? []
											}
											getOptionLabel={(option) => (option as Pick<Client, 'name' | 'id'>).name ?? ''}
											onChange={(_, value) => {
												if (!isString(value)) {
													helpersClient.setValue(value?.id);
													helpersClientShippingAddressId.setValue(
														clientShippingAddress?.find(({ clientId }) => clientId === fieldClientId.value)?.id
													);
												}
											}}
										/>
									</Box>
								</LayoutField>
								<LayoutField label="Adresse du client">
									<Box mb={3}>
										<FormikAutocompleteField<{ id: string; name: string }>
											disabled={fieldIsDistribution.value}
											options={
												fieldClient.value
													? clientShippingAddress?.map((address) => {
															return {
																name: address?.address?.name as string,
																id: address?.id as string,
															};
													  }) ?? []
													: []
											}
											value={!fieldIsDistribution.value ? defaultClientShippingAddress : ''}
											getOptionLabel={(option) => (option as { id: string; name: string }).name ?? ''}
											onChange={(_, value) => {
												if (!isString(value)) {
													helpersClientShippingAddressId.setValue(value?.id);
													helpersClientShippingAddressName.setValue(value?.name);
												}
											}}
										/>
									</Box>
								</LayoutField>
							</>
						) : (
							<>
								<LayoutFieldForm
									disabled
									label="Nombre de points de livraison"
									name="nbShippingPoints"
									type="number"
									width="200px"
									value={clientQuote?.nbShippingPoints}
								/>
								<Button variant="contained" color="primary" onClick={openPopin} style={{ marginLeft: 0 }}>
									Voir la répartition
								</Button>
								{renderPopin(
									<OrderDistributionTable
										distributions={(clientQuote?.clientQuoteDistributions as Distribution[]) || []}
										closePopin={closePopin}
									/>
								)}
							</>
						)}
					</Grid>
					{!clientQuote?.isDistribution && (
						<>
							<Grid item xs={2} />
							<Grid item xs={3}>
								<DashedHeader>Adresse de livraison</DashedHeader>
								{!loading && shippingAddress ? (
									<>
										<Typography variant="h4">{shippingAddress.address?.name}</Typography>
										<Typography variant="h5">{shippingAddress.address?.firstAddress}</Typography>
										<Typography variant="h5">{shippingAddress.address?.secondAddress}</Typography>
										<Typography variant="h5">{shippingAddress.address?.thirdAddress}</Typography>
										<Typography variant="h5">
											{shippingAddress.address?.zipCode + ' '}
											{shippingAddress.address?.city}
										</Typography>
										<Typography variant="h5">{shippingAddress.address?.country}</Typography>
									</>
								) : (
									<Box position="relative" height="100px" display="flex" alignItems="center" justifyContent="center">
										<Loading loading={loading} />
									</Box>
								)}
							</Grid>
						</>
					)}
				</Grid>
			</Box>
		</ProductInformationProvider>
	);
};

export default DeliveryInformation;
