import React, { useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../../app/theme/colors.scss';
import { ListCharacteristicsProps } from '../../definitions';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			borderBottom: `1px solid ${colors.gray200}`,
		},
		containerItem: {
			padding: '4px 0px 4px 10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			transition: theme.transitions.create(['background-color', 'opacity'], {
				duration: theme.transitions.duration.standard,
			}),
			'&:hover': {
				backgroundColor: '#F4F4F4',
				cursor: 'pointer',
				padding: '0px 0px 0px 10px',
				opacity: 0.9,
			},
		},
	})
);

const ListCharacteristics = ({
	name,
	id,
	isSelected,
	disabled,
	handleOpenPopinToDeleteAttribute,
	editPrintValue,
	handleOpenPopinToDeletePrintValue,
	handleAttributeSelected,
	currentAttributeId,
}: ListCharacteristicsProps): JSX.Element => {
	const classes = useStyles();
	const [displayIcon, setDisplayIcon] = useState<boolean>(false);

	return (
		<Box className={classes.root} onMouseEnter={() => setDisplayIcon(true)} onMouseLeave={() => setDisplayIcon(false)}>
			<Box
				className={classes.containerItem}
				bgcolor={isSelected && id === currentAttributeId ? colors.blue200 : colors.white}
			>
				<Box width="90%" onClick={() => handleAttributeSelected && handleAttributeSelected(name, id)}>
					<Typography variant="h5">{name}</Typography>
				</Box>
				<Box width="15%" pt={1} color="#DADADA" fontWeight={500}>
					{disabled && displayIcon && (
						<EditOutlinedIcon fontSize="small" onClick={() => (editPrintValue ? editPrintValue(id, name) : null)} />
					)}
					{displayIcon && (
						<DeleteForeverOutlinedIcon
							fontSize="small"
							onClick={() =>
								disabled && handleOpenPopinToDeletePrintValue
									? handleOpenPopinToDeletePrintValue(id, name)
									: handleOpenPopinToDeleteAttribute && handleOpenPopinToDeleteAttribute(id)
							}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default ListCharacteristics;
