import React, { memo } from 'react';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import FormikTextField from '../../../../components/form/FormikTextField';
import OrderTableCell from '../components/OrderTableCell';
import { useTableRowStyles } from '../sharedStyles';
import { OrderItemFormModel, OrderProductStatusValue, ChildOrderItemsFormModel } from '../definition';
import FormikSelectField from '../../../../components/form/FormikSelectField';
import { OrderProductStatus } from '../../../../graphql/types';
import useSelectStatus from '../../../hooks/useSelectStatus';

interface ProductStoreProps {
	orderItem?: ChildOrderItemsFormModel | null;
	orderChildIndex: string;
	parentOrderItem?: OrderItemFormModel[] | null;
	productsStoreFormikName?: string[] | null;
}

const ProductStore = ({
	orderItem,
	orderChildIndex,
	parentOrderItem,
	productsStoreFormikName,
}: ProductStoreProps): JSX.Element => {
	const classes = useTableRowStyles({ isColorBlue: true });
	const [selectStatus] = useSelectStatus(productsStoreFormikName, orderChildIndex);

	return (
		<TableRow className={classes.root}>
			<OrderTableCell label={orderItem?.productName} variant="body1" isFirst />
			<OrderTableCell label={orderItem?.quantity} variant="body1" />
			<OrderTableCell label="fournisseur" variant="body1" />
			<OrderTableCell align="center" width="160px">
				<FormikSelectField
					name={`${orderChildIndex}.status`}
					onChange={(e) => {
						const parentProductIndex = parentOrderItem?.findIndex(
							(product) => product.productName === orderItem?.productName
						);
						selectStatus(e.target.value as string, parentProductIndex);
					}}
				>
					{Object.keys(OrderProductStatus).map((status) => (
						<MenuItem selected={false} value={status} key={status}>
							{OrderProductStatusValue[status as OrderProductStatus]}
						</MenuItem>
					))}
				</FormikSelectField>
			</OrderTableCell>
			<OrderTableCell>
				<FormikTextField name={`${orderChildIndex}.trackingLink`} fullWidth disabled={false} />
			</OrderTableCell>
		</TableRow>
	);
};

export default memo(ProductStore);
