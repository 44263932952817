import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Grid, Radio, Typography } from '@mui/material';
import AppListTable from 'components/tables/list-table/AppListTable';
import { OrderItemTransportersPricesModel, TransportersPricesModel } from 'graphql/types';
import { Column, useTable } from 'react-table';
import { formatNumberDisplay } from 'utils/prices';
import { OrderItem } from '../definitions';
import DashedHeader from 'components/DashedHeader';
import FormikTextField from 'components/form/FormikTextField';
import { useField } from 'formik';
import { productAndTransporterCalculation } from 'order/helpers';

// Component for editing transport
const OrderItemEditTransport = ({
	orderItem,
	orderItemIndex,
	closePopin,
}: {
	orderItem?: OrderItem;
	orderItemIndex: number;
	closePopin: () => void;
}) => {
	const [currentTransporter, setCurrentTransporter] = useState(orderItem?.bestTransporter);
	const [commentField] = useField(`orderItem[${orderItemIndex}].commentTransporter`);
	const [orderItemField, , orderItemFieldHelpers] = useField(`orderItem`);

	const handleUpdateTransporter = () => {
		orderItemField.value[orderItemIndex].bestTransporterId = currentTransporter?.transporterId;
		orderItemField.value[orderItemIndex].bestTransporter = currentTransporter;
		orderItemFieldHelpers.setValue(productAndTransporterCalculation(orderItemField.value));
		closePopin();
	};
	const toggleCurrentTransporter = useCallback(
		(selectedTransporterId?: string) => {
			const selectedTransporter = orderItem?.transportersPrices?.find(
				(transporter) => transporter.transporterId === selectedTransporterId
			);
			setCurrentTransporter(selectedTransporter as TransportersPricesModel);
		},
		[orderItem]
	);

	const columns: Column<OrderItemTransportersPricesModel>[] = useMemo(
		() => [
			{
				Header: 'Nom du transporteur',
				accessor: 'transporterName',
			},
			{
				Header: 'Prix',
				accessor: 'transporterPurchasePriceExcludingVAT',
				Cell: ({ value }: { value: number }) => {
					return <Typography>{formatNumberDisplay(value, 2)}</Typography>;
				},
			},
			{
				Header: 'Différence en €',
				accessor: 'transporterPurchasePriceExcludingVAT',
				id: 'euro-diff',
				Cell: ({ value }: { value: number }) => {
					const diff = orderItem?.bestTransporter
						? value - orderItem?.bestTransporter.transporterPurchaseExcludingVATWithRfa
						: 0;
					return <Typography>{diff ? formatNumberDisplay(diff, 2) : '-'}</Typography>;
				},
			},
			{
				Header: 'Différence en %',
				accessor: 'transporterPurchasePriceExcludingVAT',
				id: 'percent-diff',
				Cell: ({ value }: { value: number }) => {
					const currentPrice = orderItem?.bestTransporter?.transporterPurchaseExcludingVATWithRfa || 0;
					const diff = value - currentPrice;
					const percentDiff = diff !== 0 ? (currentPrice !== 0 ? (diff / currentPrice) * 100 : 100) : 0;
					return <Typography>{percentDiff ? `${formatNumberDisplay(percentDiff)} %` : '-'}</Typography>;
				},
			},
			{
				Header: 'Différence en %',
				id: 'selected',
				accessor: 'transporterId',
				Cell: ({ value }: { value: string }) => {
					return (
						<Radio
							checked={value === currentTransporter?.transporterId}
							inputProps={{
								'aria-label': `Select transporter ${value}`,
							}}
							onClick={() => toggleCurrentTransporter(value)}
						/>
					);
				},
			},
		],
		[orderItem, currentTransporter, toggleCurrentTransporter]
	);

	const transporters = useTable<OrderItemTransportersPricesModel>({
		columns,
		data:
			orderItem && orderItem.transportersPrices
				? (orderItem.transportersPrices as OrderItemTransportersPricesModel[]).sort(
						(transporterA, transporterB) =>
							transporterA.transporterPurchasePriceExcludingVAT - transporterB.transporterPurchasePriceExcludingVAT
				  )
				: [],
	});
	return (
		<Box mb={4} px={2}>
			<AppListTable tableInstance={transporters} ariaLabel={'transporters'} />
			<DashedHeader>Justification du changement de transporteur</DashedHeader>
			<Grid container alignItems="center">
				<Grid item xs={9}>
					<FormikTextField
						multiline
						rows={6}
						variant="outlined"
						value={commentField.value}
						name={`orderItem[${orderItemIndex}].commentTransporter`}
						fullWidth
						placeholder="Mon commentaire"
						aria-label="Update transporter comment"
					/>
				</Grid>
				<Grid item xs={3}>
					<Box display="flex" justifyContent="center">
						<Button
							variant="contained"
							color="primary"
							disabled={!commentField.value}
							onClick={handleUpdateTransporter}
							aria-label="Update best transporter"
						>
							Enregistrer le choix
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default OrderItemEditTransport;
