import * as React from 'react';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../app/theme/colors.scss';
const useStyles = makeStyles({
	iconButton: {
		backgroundColor: (props: Pick<ButtonDisplayContentProps, 'displayContent' | 'isActive'>) =>
			props.isActive ? (props.displayContent ? colors.blueDisabled : colors.primary) : colors.blueDisabled,
	},
});
interface ButtonDisplayContentProps {
	displayContent: boolean;
	OnDisplayContent: React.Dispatch<React.SetStateAction<boolean>> | ((isDisplay: boolean) => void);
	isActive?: boolean;
}
const ButtonDisplayContent = ({
	displayContent,
	OnDisplayContent,
	isActive,
}: ButtonDisplayContentProps): JSX.Element => {
	const classes = useStyles({ isActive, displayContent });

	return (
		<IconButton onClick={() => OnDisplayContent(!displayContent)} size="large">
			{displayContent ? (
				<ExpandLessIcon className={classes.iconButton} color="secondary" />
			) : (
				<ExpandMoreIcon className={classes.iconButton} color="secondary" />
			)}
		</IconButton>
	);
};
export default ButtonDisplayContent;
