import { Box, Button, Collapse, Divider, FormLabel, MenuItem, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import { LocalizationProvider } from '@mui/lab/';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ButtonDisplayContent from '../../../components/ButtonDisplayContent';
import { useSnackbar, VariantType } from 'notistack';
import { getUserFormSchema } from './validation/schema';
import { useHistory, useParams } from 'react-router-dom';
import Path from '../../../components/Path';
import { PATH_NAMES } from '../../../Header/menus';
import useRolesQuery from '../../role/hooks/useRolesQuery';
import { useUserInsert } from '../hooks/useUserInsert';
import ReplacedBySubForm from './ReplaceBySubForm';
import ClientsTransfertList from './ClientsTransfertList';
import { UserClientListItem } from '../definitions';
import PasswordField from './PasswordField';
import colors from '../../../app/theme/colors.scss';
import { Client, EntityType, User } from '../../../graphql/types';
import useUserQuery from '../hooks/useUserQuery';
import { useUserUpdate } from '../hooks/useUserUpdate';
import useClientsQuery from '../../../clients/hooks/useClientsQuery';
import { userFormInitialValue } from '../helper';
import FormikSelectField from '../../../components/form/FormikSelectField';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textField: {
			backgroundColor: colors.white,
			'& .MuiFormHelperText-root.Mui-error ': {
				background: colors.bodyBackgroundLight,
				margin: 0,
			},
		},
	})
);
const UserInformationsForm = () => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [displayContent, setDisplayContent] = useState<{ [key: string]: boolean }>({
		userInformations: true,
		priceRules: true,
		replacement: true,
		userClients: true,
	});

	const { id: userId } = useParams<{ id: string }>();
	const history = useHistory();

	const { roles } = useRolesQuery();
	const { clients } = useClientsQuery({ type: { notIn: [EntityType.Region, EntityType.Typology] } });

	const insertUser = useUserInsert();
	const updateUser = useUserUpdate();

	const { user, refetchUser } = useUserQuery(userId);

	const paths: (string | undefined)[] = [
		PATH_NAMES.Admin,
		PATH_NAMES.User,
		user ? user.familyName + ' ' + user.givenName : PATH_NAMES.NewUser,
	];
	const [rightUserClientsList, setRightUserClientsList] = useState<UserClientListItem[]>([]);
	const [leftUserClientsList, setLeftUserClientsList] = useState<UserClientListItem[]>([]);
	const [initialUserCientsList, setInitialUserCientsList] = useState<UserClientListItem[]>([]);
	const [initialUser, setInitialUser] = useState<
		User & { password: string; roleId: string; clientIds: string[]; userClients: Client[] }
	>();

	useEffect(() => {
		const initialLeftListValue: UserClientListItem[] = [];
		const newUserClientsList: UserClientListItem[] = [];

		user?.userClients.forEach((client: Client) => {
			initialLeftListValue.push({
				name: client.name,
				id: client.id,
				isRoot: client.isRoot,
				parentClientId: client?.parentClientId ?? '',
			});
		});

		clients?.forEach((client) => {
			newUserClientsList.push({
				name: client.name,
				id: client.id,
				isRoot: client.isRoot,
				parentClientId: client?.parentClientId ?? '',
			});
		});

		const leftRightListsDifference = newUserClientsList.filter((client) => {
			return initialLeftListValue.findIndex((clientValue) => clientValue.name === client.name) === -1;
		});

		if (user) {
			setInitialUserCientsList(leftRightListsDifference);
			setLeftUserClientsList(leftRightListsDifference);
			setRightUserClientsList(
				user?.userClients.map((userClient: Client) => ({
					name: userClient.name,
					id: userClient.id,
					isRoot: userClient.isRoot,
					parentClientId: userClient?.parentClientId ?? '',
				}))
			);
		} else {
			setInitialUserCientsList(newUserClientsList);
			setLeftUserClientsList(newUserClientsList);
			setRightUserClientsList([]);
		}
	}, [clients, initialUserCientsList.length, user]);

	useEffect(() => {
		if (userId !== 'newUser') {
			user &&
				setInitialUser({
					...user,
					password: '',
					userClients: user.userClients,
					roleId: user.role.id,
					role: { id: user.role.id, name: user.role.name, description: '', permissions: [], users: [] } ?? undefined,
					clientIds: user.userClients.map((user: User) => user.id),
				});
		} else {
			setInitialUser(userFormInitialValue);
		}
	}, [user, userId]);

	if (!initialUser) return null;

	const handleSubmit = async (
		user: User & { password: string; roleId: string; clientIds: string[]; userClients: Client[] }
	) => {
		const variant: VariantType = 'success';
		const variantError: VariantType = 'error';

		try {
			if (userId !== 'newUser') {
				await updateUser(user);
			} else {
				await insertUser(user);
			}

			enqueueSnackbar('Modifications enregistrées', { variant });
		} catch (error) {
			enqueueSnackbar('Email déjà présent', { variant: variantError });
		}
	};

	const handleLeave = async () => {
		refetchUser();
		history.push('/admin/user');
	};

	const handleReset = (
		values: User & { password: string; roleId: string; clientIds: string[]; userClients: Client[] }
	) => {
		setRightUserClientsList(
			values.userClients.map((userClient: Client) => ({
				name: userClient.name,
				id: userClient.id,
				isRoot: userClient.isRoot,
				parentClientId: userClient?.parentClientId ?? '',
			}))
		);

		setInitialUser(userFormInitialValue);

		const leftRightListsDifference = initialUserCientsList.filter((client) => {
			return leftUserClientsList.findIndex((x) => x.name === client.name) === -1;
		});

		setInitialUserCientsList(leftRightListsDifference);
		setLeftUserClientsList(leftRightListsDifference);
	};

	const handleDisplay = (isDisplay: boolean, sectionName: string) => {
		setDisplayContent({ ...displayContent, [sectionName]: isDisplay });
	};

	return (
		<Formik<User & { password: string; roleId: string; clientIds: string[]; userClients: Client[] }>
			initialValues={initialUser}
			validationSchema={getUserFormSchema}
			onSubmit={(values, { resetForm }) => {
				handleSubmit(values);
				resetForm({ values });
			}}
			onReset={handleReset}
			validateOnChange={false}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Form>
					<Box ml={10} mr={10}>
						<Path paths={paths} />
						<Divider />
						<Box display={'flex'} mt={4} mb={4}>
							<Box display={'flex'} flexGrow={1}>
								<Box pr={2}>
									<Typography variant="h2">Informations de l’utilisateur </Typography>
								</Box>
							</Box>
							<Button color="primary" type="reset">
								Annuler
							</Button>
							<Button variant="contained" color="primary" type="submit">
								Enregistrer les modifications
							</Button>
							<Button variant="contained" color="primary" onClick={handleLeave}>
								Quitter
							</Button>
						</Box>
						<Divider />
						<Box display={'flex'} flexGrow={1} mt={4}>
							<Box pr={2}>
								<Typography variant="h2">Coordonnées</Typography>
							</Box>
							<ButtonDisplayContent
								displayContent={displayContent['userInformations']}
								OnDisplayContent={(isDisabled: boolean) => handleDisplay(isDisabled, 'userInformations')}
							/>
						</Box>
						<Box maxWidth={500} mb={4} ml={15}>
							<Collapse in={displayContent['userInformations']}>
								<Box pt={2}>
									<FormLabel>Nom de l'utilisateur</FormLabel>
								</Box>
								<Field
									component={TextField}
									className={classes.textField}
									name="familyName"
									placeholder="Nom"
									variant="outlined"
									margin="dense"
									fullWidth
								/>

								<Box pt={2}>
									<FormLabel>Prénom de l'utilisateur</FormLabel>
								</Box>
								<Field
									component={TextField}
									name="givenName"
									className={classes.textField}
									placeholder="Prénom"
									variant="outlined"
									margin="dense"
									fullWidth
								/>

								<Box pt={2}>
									<FormLabel>Initiales</FormLabel>
								</Box>
								<Field
									component={TextField}
									name="initials"
									className={classes.textField}
									placeholder="Initiales"
									variant="outlined"
									margin="dense"
									fullWidth
								/>

								<Box pt={2}>
									<FormLabel>Rôle</FormLabel>
								</Box>

								<Box className={classes.textField}>
									<FormikSelectField name="roleId" variant="outlined" style={{ width: '100%' }}>
										{roles &&
											roles?.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
									</FormikSelectField>
								</Box>
								<ErrorMessage name="roleId">
									{(msg) => (
										<Box pl={2} style={{ color: '#f44336' }}>
											<Typography variant="body1">{msg}</Typography>
										</Box>
									)}
								</ErrorMessage>
								<Box pt={2}>
									<FormLabel>Téléphone</FormLabel>
								</Box>
								<Field
									component={TextField}
									className={classes.textField}
									name="phoneNumber"
									placeholder=" +(00)33 00 00 00 00"
									variant="outlined"
									margin="dense"
									fullWidth
								/>

								<Box pt={2}>
									<FormLabel> Adresse mail</FormLabel>
								</Box>
								<Field
									component={TextField}
									className={classes.textField}
									id="email"
									name="email"
									variant="outlined"
									margin="dense"
									fullWidth
								/>

								<PasswordField name="password" />
							</Collapse>
						</Box>

						<Divider />
						<Box mt={4} display={'flex'} flexGrow={1} mb={2}>
							<Box pr={2}>
								<Typography variant="h2">Liste des clients visibles</Typography>
							</Box>
							<ButtonDisplayContent
								displayContent={displayContent['userClients']}
								OnDisplayContent={(isDisabled: boolean) => handleDisplay(isDisabled, 'userClients')}
							/>
						</Box>
						<Collapse in={displayContent['userClients']}>
							<Box mb={4}>
								<Typography variant="body1">
									Utilisez les flèches ou glissez-déposez les clients auxquels cet utilisateur aura accès
								</Typography>
							</Box>
							<Box ml={15} mb={5}>
								<ClientsTransfertList
									rightUserClientsList={rightUserClientsList}
									setRightUserClientsList={setRightUserClientsList}
									leftUserClientsList={leftUserClientsList}
									setLeftUserClientsList={setLeftUserClientsList}
									initialUserCientsList={initialUserCientsList}
									setInitialUserCientsList={setInitialUserCientsList}
								/>
							</Box>
						</Collapse>

						<Divider />
						<Box mt={4} display={'flex'} flexGrow={1} mb={2}>
							<Box pr={2}>
								<Typography variant="h2">Restrictions sur la visibilité des prix</Typography>
							</Box>
							<ButtonDisplayContent
								displayContent={displayContent['priceRules']}
								OnDisplayContent={(isDisabled: boolean) => handleDisplay(isDisabled, 'priceRules')}
							/>
						</Box>
						<Collapse in={displayContent['priceRules']}>
							<Box ml={15}>
								<Typography variant="subtitle1">Cet utilisateur peut voir</Typography>
								<Box display="flex" flexDirection="row" alignItems="center">
									<Field component={Checkbox} color="primary" type="checkbox" name="canViewSalePrices" />
									<Typography>Les prix de vente</Typography>
								</Box>
								<Box display="flex" flexDirection="row" alignItems="center">
									<Field component={Checkbox} color="primary" type="checkbox" name="canViewBuyingPrices" />
									<Typography>Les prix d'achat</Typography>
								</Box>
							</Box>
						</Collapse>

						<Divider />
						<Box mt={4} display={'flex'} flexGrow={1} mb={2}>
							<Box pr={2}>
								<Typography variant="h2">Remplacement</Typography>
							</Box>
							<ButtonDisplayContent
								displayContent={displayContent['replacement']}
								OnDisplayContent={(isDisabled: boolean) => handleDisplay(isDisabled, 'replacement')}
							/>
						</Box>
						<Collapse in={displayContent['replacement']}>
							<Box ml={15}>
								<ReplacedBySubForm userId={userId} />
							</Box>
						</Collapse>
					</Box>
				</Form>
			</LocalizationProvider>
		</Formik>
	);
};

export default UserInformationsForm;
