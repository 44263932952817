import { useMutation } from '@apollo/client';
import { Client, User } from '../../../graphql/types';
import { UPDATE_USER_BY_ID } from '../graphql/mutations';
import { UpdateUserMutation, UpdateUserMutationVariables } from '../graphql/mutations.definitions';
import { GET_USER_BY_ID } from '../graphql/queries';
import { GET_CLIENTS_BY_CURRENT_USER } from '../../../clients/graphql/queries';

export const useUserUpdate = () => {
	const [updateUserMutation] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_BY_ID);

	return async ({
		__typename,
		role,
		userClients,
		id,
		...user
	}: User & { password: string; roleId: string; clientIds: string[]; userClients: Client[] }) => {
		const result = await updateUserMutation({
			variables: { userId: id, data: user },
			refetchQueries: [
				{
					query: GET_USER_BY_ID,
					variables: { userId: id },
				},
				{
					query: GET_CLIENTS_BY_CURRENT_USER,
					variables: { hasChildren: false },
				},
			],
		});

		if (!result.data) throw new Error();
		return result;
	};
};
