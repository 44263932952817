import { Grid, Box } from '@mui/material';
import React from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { EscompteInput } from '../../../../graphql/types';
import { useEscompteTable } from '../../../hooks/useStructureEscompteTable';

interface EscompteFieldProps {
	escomptes: EscompteInput[];
}

const EscompteField = ({ escomptes }: EscompteFieldProps) => {
	const escompteStructureTable = useEscompteTable(escomptes ? escomptes : []);
	return (
		<Grid item xs={12}>
			<Box mt={4}>
				<DashedHeader>Escompte (pour information)</DashedHeader>
				<Box my={4}>
					<Grid item xs={6}>
						<AppListTable tableInstance={escompteStructureTable} />
					</Grid>
				</Box>
			</Box>
		</Grid>
	);
};

export default EscompteField;
