import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SUPPLIERS_PRICES } from '../graphql/queries';
import { GetSuppliersPricesQuery, GetSuppliersPricesQueryVariables } from '../graphql/queries.definitions';
import PricesProductContext from '../../context/pricesProductContext/PricesProductContext';

const useSuppliersPricesQuery = (productsId: string[]) => {
	const { setSupplierPricesLength } = useContext(PricesProductContext);

	const { data, loading } = useQuery<GetSuppliersPricesQuery, GetSuppliersPricesQueryVariables>(GET_SUPPLIERS_PRICES, {
		variables: { productsId },
		skip: productsId.length === 0,
		onCompleted: (data) => {
			setSupplierPricesLength(data.suppliersPrices.length);
		},
	});

	return { suppliersPrices: data?.suppliersPrices, loading };
};

export default useSuppliersPricesQuery;
