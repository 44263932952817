import * as React from 'react';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import colors from '../app/theme/colors.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

type PathPropsType = {
	paths: (string | { name: string; href?: string } | undefined)[];
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		ArrowForwardIosIcon: {
			color: colors.hover,
			fontSize: '0.80rem',
			marginLeft: '0.5rem',
			marginRight: '0.5rem',
		},
	})
);

const Path = ({ paths }: PathPropsType): JSX.Element => {
	const classes = useStyles();
	const capitalizePathname = (pathname: string | { name: string; href?: string } | undefined) => {
		if (typeof pathname === 'string') {
			return pathname.charAt(0).toUpperCase() + pathname.slice(1);
		}
		return pathname && pathname.name.charAt(0).toUpperCase() + pathname.name.slice(1);
	};

	return (
		<Box mt={3} mb={1}>
			<Breadcrumbs separator={<ArrowForwardIosIcon className={classes.ArrowForwardIosIcon} />} aria-label="breadcrumb">
				{paths
					.filter((path) => path !== undefined)
					.map((path, index) => {
						if (!path) {
							return null;
						}
						if (typeof path === 'string')
							return (
								<Typography variant="h2" key={index} color="primary">
									{capitalizePathname(path)}
								</Typography>
							);
						return (
							<Link to={path.href || '/'} style={{ textDecoration: 'none' }} key={index}>
								<Typography
									variant="h2"
									color="primary"
									sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
								>
									{capitalizePathname(path.name)}
								</Typography>
							</Link>
						);
					})}
			</Breadcrumbs>
		</Box>
	);
};

export default Path;
