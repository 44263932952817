import { Box } from '@mui/material';
import React from 'react';
import AppListTable from '../../../components/tables/list-table/AppListTable';
import { DescriptionsProps } from './helpers';

import { useStoreDistributionTable } from './hooks/useStoreDistributionsTable';

const StoreDescriptions = ({ setDistributionsData, distributionsData }: DescriptionsProps) => {
	const tableInstance = useStoreDistributionTable(setDistributionsData, distributionsData);

	return (
		<Box p={4}>
			<AppListTable tableInstance={tableInstance} />
		</Box>
	);
};

export default StoreDescriptions;
