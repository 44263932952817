import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ProductDeliveryDetails from './ProductDeliveryDetails';
import ProductPriceDetails from '../components/ProductPriceDetails';
import ProductStoresDetails from './ProductStoresDetails';
import productStores from '../../../helpers';
import ProductOrderDetails from './ProductOrderDetails';
import usePopin from 'hooks/usePopin';
import OrderTableCell from '../components/OrderTableCell';
import { useTableRowStyles } from '../sharedStyles';
import useCollapse from 'hooks/useCollapse';
import { DocumentDownloadButton } from '../../../../components/buttons/DocumentDownloadButton';
import { ChildOrderFormModel, OrderItemFormModel } from '../definition';

export interface ProductItemProps {
	product: OrderItemFormModel;
	childrenOrder?: ChildOrderFormModel[] | null;
	productIndex: number;
}

const ProductItem = ({ product, childrenOrder, productIndex }: ProductItemProps): JSX.Element => {
	const { renderCollapseComponent, displayContent } = useCollapse(false);
	const classes = useTableRowStyles();
	const stores = useMemo(() => (childrenOrder ? productStores(childrenOrder, product.productId) : []), [
		childrenOrder,
		product.productId,
	]);

	const {
		productName,
		product: productItem,
		quantity,
		weight,
		expectedDeliveryDate,
		versionsNumber,
		buyingPriceHt,
		sellingPriceHt,
		transportBuyingPriceHt,
		transporterPurchaseExcludingVATWithRfa,
		productPurchasePriceExcludingVATWithRFA,
		transportSellingPriceHt,
		prepressPriceHt,
		prepressSellingPriceHt,
		productSellingPriceHt,
		tvaRate,
		sellingPriceTtc,
		tva,
		comment,
		productId,
		postDescription,
		bestSupplier,
	} = product;

	const documents = useMemo(() => {
		return [
			{
				documentUrl: product.onePacReplicationUrl,
				documentName: product.onePacReplicationName,
				label: 'Télécharger les répliquages',
			},
			{
				documentUrl: product.onePacDistributionUrl,
				documentName: product.onePacDistributionName,
				label: 'Télécharger la répartition',
			},
			{
				documentUrl: product.assetUrl,
				documentName: product.onePacDocumentName,
				label: 'Télécharger le document',
			},
		];
	}, [product]);

	const [openDetailsProductPopin, renderDetailsProductPopin, closeDetailsProductPopin] = usePopin({
		headerTitle: productName,
		maxWidth: 'md',
		fullWidth: true,
		isDisplayHeader: false,
		bottomFooter: <DocumentDownloadButton documents={documents} />,
	});

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<OrderTableCell isFirst>
					<Box display="flex" justifyContent="center">
						<img
							alt=""
							width="24.57px"
							height="34.57px"
							src={
								product?.thumbnailAssetUrl ??
								'https://image.shutterstock.com/image-vector/empty-placeholder-image-icon-design-260nw-1366372628.jpg'
							}
						/>
					</Box>
				</OrderTableCell>
				<OrderTableCell label={productName} onClick={openDetailsProductPopin} variant="h5" />
				<OrderTableCell label={productItem?.support.name} variant="h5" />
				<OrderTableCell label={quantity} variant="h5" />
				<OrderTableCell label={weight} variant="h5" />
				<OrderTableCell label={bestSupplier?.name} variant="h5" />
				<OrderTableCell label="num comfournisseur" variant="h5" />
				<OrderTableCell width="70px" align="center">
					{renderCollapseComponent(true)}
				</OrderTableCell>
			</TableRow>
			<TableRow>
				<TableCell padding="none" colSpan={12} className={classes.tableCellDetails}>
					<Collapse in={displayContent}>
						<Box pt={3} pl={10} pr={10} mb={4}>
							<ProductDeliveryDetails
								openDetailsProductPopin={openDetailsProductPopin}
								productIndex={productIndex}
								comment={comment}
								expectedDeliveryDate={expectedDeliveryDate}
								versionsNumber={versionsNumber}
								clientNetworkLink={product?.clientNetworkLink}
								stores={stores}
								thumbnailAssetUrl={product?.thumbnailAssetUrl}
								supportCategory={product?.product?.support?.category}
								publicationDate={product?.publicationDate}
							/>
							<ProductPriceDetails
								buyingPriceHt={buyingPriceHt}
								sellingPriceHt={sellingPriceHt}
								transporterPurchaseExcludingVATWithRfa={transporterPurchaseExcludingVATWithRfa}
								productPurchasePriceExcludingVATWithRFA={productPurchasePriceExcludingVATWithRFA}
								transportBuyingPriceHt={transportBuyingPriceHt}
								transportSellingPriceHt={transportSellingPriceHt}
								prepressPriceHt={prepressPriceHt}
								prepressSellingPriceHt={prepressSellingPriceHt}
								productSellingPriceHt={productSellingPriceHt}
								tvaRate={tvaRate}
								sellingPriceTtc={sellingPriceTtc}
								tva={tva}
							/>
							<ProductStoresDetails stores={stores} productIndex={productIndex} />
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			{renderDetailsProductPopin(
				<ProductOrderDetails
					id={productId}
					productName={productName}
					codeAt={productItem?.codeAt}
					closeDetailsProductPopin={closeDetailsProductPopin}
					postDescription={postDescription}
					thumbnailAssetUrl={product?.thumbnailAssetUrl}
				/>
			)}
		</React.Fragment>
	);
};
export default ProductItem;
