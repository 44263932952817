import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MyGrid from '../../components/MyGrid/MyGrid';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import { Data } from '../Displaydata/Displaydata';

type props = {
	goTo: (step: string) => void;
	setData: React.Dispatch<React.SetStateAction<Data>>;
	data: Data;
};
function FileImport(props: props) {
	const goTo = (step: string) => {
		props.goTo(step);
	};

	const useStyles = makeStyles({
		buttons: { paddingLeft: '2vh' },
		buttonsList: {
			marginTop: '10px',
		},
		gridImport: {
			height: '50vh',
		},
		subTitle: {
			textAlign: 'center',
		},
		title: {
			textAlign: 'center',
			color: '#3e64ad',
		},
	});

	const classes = useStyles();

	return (
		<Box>
			<Grid container alignContent="center" direction="row-reverse" spacing={0}>
				<Grid item xs={4} sm={4}></Grid>
				<Grid item xs={4} sm={5}>
					<h2 className={classes.title}>Intégration des données fournisseurs</h2>
				</Grid>
			</Grid>
			<Grid container alignContent="center" direction="row-reverse" spacing={0}>
				<Grid item xs={4} sm={4}></Grid>
				<Grid item xs={4} sm={5}>
					<h3 className={classes.subTitle}>Données {props.data.supplier}</h3>
				</Grid>
			</Grid>

			<Grid container direction="row-reverse" spacing={0}>
				<Grid item xs={4} sm={1}></Grid>
				<Grid className={classes.gridImport} item xs={4} sm={11}>
					<MyGrid disabled={true} empty={false} data={props.data} setData={props.setData} />
				</Grid>
			</Grid>
			<Box paddingTop="30px">
				<Grid container direction="row-reverse" spacing={0}>
					<Grid item xs={4} sm={1}></Grid>

					<Grid item xs={4} sm={2} className={classes.buttons}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								goTo('');
							}}
						>
							Exécuter Deconix
						</Button>
					</Grid>
					<Grid item xs={4} sm={2} className={classes.buttons}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								goTo('');
							}}
						>
							Exécuter Data Corrector
						</Button>
					</Grid>
					<Grid item xs={4} sm={2} className={classes.buttons}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								goTo('formatting');
								props.setData({ ...props.data, step: 'formatting' });
							}}
						>
							Exécuter Formatting
						</Button>
					</Grid>
				</Grid>
				<Grid container direction="row-reverse" spacing={0} className={classes.buttonsList}>
					<Grid item xs={4} sm={1}></Grid>

					<Grid item xs={4} sm={2} className={classes.buttons}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								goTo('');
							}}
						>
							Intégrer les données
						</Button>
					</Grid>
					<Grid item xs={4} sm={2} className={classes.buttons}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								goTo('');
							}}
						>
							Lancer Fantastik
						</Button>
					</Grid>
					<Grid item xs={4} sm={2} className={classes.buttons}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								goTo('');
							}}
						>
							Lancer Unit Convertor
						</Button>
					</Grid>
				</Grid>
				<Grid container direction="row-reverse" spacing={0} className={classes.buttonsList}>
					<Grid item xs={5} sm={1}></Grid>
					<Grid item xs={4} sm={2} className={classes.buttons}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								goTo('home');
							}}
						>
							Quitter
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default FileImport;
