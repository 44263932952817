import { Box, Button, Divider } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import usePopin from 'hooks/usePopin';
import AddFileReferenceForm from './AddFileReferenceForm';
import { FileReferenceType, initialValues } from './definitions';
import { fileReferenceInsertSchema } from './validationShema';
import { useSnackbar } from 'notistack';
import useFileReferenceInsert from 'file-reference/hooks/useFileReferenceInsert';
import { typeNotifications } from 'utils/definition';

interface AddFileReferenceProps {
	clientId?: string | null;
	isExternalCreation?: boolean;
}

const AddFileReference = ({ clientId, isExternalCreation }: AddFileReferenceProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { insertFileReference } = useFileReferenceInsert();
	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: "Création d'une Ref. Dossier",
		maxWidth: 'md',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const onSubmit = async (values: FileReferenceType) => {
		try {
			const fileReference = await insertFileReference(values);
			closePopin();
			enqueueSnackbar(`La réf. dossier ${fileReference?.id} a été créé`, { variant: typeNotifications.Success });
		} catch (error) {
			enqueueSnackbar("Une erreur c'est produite", { variant: typeNotifications.Error });
		}
	};

	return (
		<Box mt={3} mb={2}>
			<>
				<Button
					color="primary"
					variant={isExternalCreation ? 'outlined' : 'contained'}
					onClick={openPopin}
					disabled={!clientId && isExternalCreation}
					data-testid="add-file-reference-button"
				>
					{isExternalCreation ? 'Créer une réf. dossier' : 'Créer une référence'}
				</Button>

				{renderPopin(
					<Formik<FileReferenceType>
						initialValues={initialValues}
						validationSchema={fileReferenceInsertSchema}
						validateOnChange={false}
						onSubmit={(value) => onSubmit(value)}
					>
						<Form>
							<AddFileReferenceForm clientId={clientId} />
							<Divider />
							<Box p={3} display="flex" flexDirection="row-reverse">
								<Button variant="contained" color="primary" type="submit" data-testid="create-file-reference-button">
									Créer la référence dossier
								</Button>
								<Button color="primary" onClick={closePopin}>
									Annuler
								</Button>
							</Box>
						</Form>
					</Formik>
				)}
			</>
		</Box>
	);
};

export default AddFileReference;
