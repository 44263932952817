import * as React from 'react';
import { Box, FormControl } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useField } from 'formik';
import { LayoutField } from 'components/form/LayoutField';
import FormikTextField from 'components/form/FormikTextField';
import colors from 'app/theme/colors.scss';
import { useProductInformation } from './ProductInformationContext';
import { calculateProductTotalWeight } from '../../../../clientPrice/helpers/calculateProductTotalWeight';
import { useMemo } from 'react';
import { OrderItem } from '../../../../graphql/types';
import { includes } from 'ramda';
import { diff } from '../../../../utils/arrays';
import sort from 'ramda/src/sort';

const SelectProductQuantity = () => {
	const { filteredProducts, selectedProduct } = useProductInformation();
	const [{ value: newOrderItem }, , { setValue: setNewOrderItem }] = useField('newOrderItem');
	const [{ value: orderItem }] = useField<OrderItem[]>('orderItem');
	const [{ value: productName }] = useField<OrderItem[]>('newOrderItem.productName');

	const handleChangeQuantity = (newQuantity: number | null) => {
		setNewOrderItem({
			...newOrderItem,
			weigth: calculateProductTotalWeight(Number(newQuantity) | 0, {
				largeurFormatFiniCm: selectedProduct?.largeurFormatFiniCm ?? '',
				longueurFormatFiniCm: selectedProduct?.longueurFormatFiniCm ?? '',
				grammageGr: selectedProduct?.grammageGr ?? '',
				paginationTotale: selectedProduct?.paginationTotale ?? '',
			}),
			quantity: newQuantity,
		});
	};

	const quantitiesOptions = useMemo(
		() =>
			filteredProducts
				?.filter(
					(product) =>
						!includes(
							{
								productName: product.productName,
								quantity: product.quantity,
							},
							orderItem.map((item) => {
								return { productName: item.productName, quantity: item.quantity };
							})
						)
				)
				?.map(({ quantity }) => quantity) ?? [],
		[filteredProducts, orderItem]
	);

	return (
		<LayoutField label="Quantité">
			<Box display="flex" mt={-3}>
				<FormControl fullWidth>
					<Autocomplete
						size="small"
						value={quantitiesOptions.length === 1 && productName ? quantitiesOptions[0] : newOrderItem.quantity ?? 0}
						options={sort(diff, quantitiesOptions) ?? []}
						getOptionLabel={(option) => option.toString() ?? '0'}
						onChange={(_, quantity) => handleChangeQuantity(quantity)}
						renderInput={(params) => (
							<FormikTextField
								name="quantity"
								{...params}
								margin="normal"
								variant="outlined"
								style={{ width: '164px', backgroundColor: colors.white }}
							/>
						)}
					/>
				</FormControl>
			</Box>
		</LayoutField>
	);
};

export default SelectProductQuantity;
