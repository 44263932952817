import { useCallback } from 'react';
import { OrderProductStatus } from '../../graphql/types';
import { useField, useFormikContext } from 'formik';
import { shouldSetProductStoreToDelivered, getCurrentStatusByName } from '../helpers';
import { ChildOrderFormModel } from '../form/order-cockpit/definition';

const useSelectStatus = (itemsFormikNames?: string[] | null, orderChildIndex?: string | null) => {
	const { setFieldValue } = useFormikContext();
	const [{ value: orderChildrenValue }] = useField('childrenOrder');
	const handleShouldSetProductStoreToDelivered = useCallback(
		(orderChildrenValue: ChildOrderFormModel[], formikNames: string[], currentStatusName: string, status: string) => {
			return shouldSetProductStoreToDelivered(orderChildrenValue, formikNames, currentStatusName, status);
		},
		[]
	);

	const selectStatus = (
		selectedStatus: string,
		productIndex?: number,
		isChangeAllOrderChildsStatusToCurrentSelectedStatus?: boolean
	) => {
		setFieldValue(`${orderChildIndex}.status`, selectedStatus);

		if (selectedStatus !== OrderProductStatus.Delivered) {
			isChangeAllOrderChildsStatusToCurrentSelectedStatus &&
				itemsFormikNames &&
				itemsFormikNames.forEach((statusName) => {
					const currentStatus = getCurrentStatusByName(orderChildrenValue, statusName);
					if (currentStatus !== OrderProductStatus.Delivered) {
						setFieldValue(`${statusName}.status`, selectedStatus);
					}
				});

			setFieldValue(`orderItem.${productIndex}.status`, selectedStatus);
		} else {
			if (itemsFormikNames && orderChildIndex) {
				const storeStatus = handleShouldSetProductStoreToDelivered(
					orderChildrenValue,
					itemsFormikNames,
					orderChildIndex,
					selectedStatus
				);
				if (storeStatus) {
					setFieldValue(`orderItem.${productIndex}.status`, OrderProductStatus.Delivered);
				}
			}
		}
	};
	return [selectStatus];
};

export default useSelectStatus;
