import { Box, Button, Dialog, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Checkbox } from 'formik-mui';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import useClientInsert from '../hooks/useClientInsert';
import { ClientInsertInput, EntityType } from '../../graphql/types';
import useClientsQuery from '../hooks/useClientsQuery';
import FormikTextField from '../../components/form/FormikTextField';
import colors from '../../app/theme/colors.scss';

const clientInsertSchema = Yup.object({
	name: Yup.string().required('Vous devez spécifier un nom'),
	isRoot: Yup.boolean().required('Required'),
});

const ClientDialogForm = (): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [clientName, setClientName] = useState('');
	const { insertClient } = useClientInsert();
	const { clients: existingClients } = useClientsQuery({
		type: { notIn: [EntityType.Region, EntityType.Typology] },
		name: clientName,
	});

	const initialValues = useMemo<ClientInsertInput>(
		() => ({
			name: '',
			isRoot: false,
		}),
		[]
	);

	const handleSubmit = async (client: ClientInsertInput, helpers: FormikHelpers<ClientInsertInput>): Promise<void> => {
		if (existingClients?.length) {
			helpers.setErrors({
				name: `Impossible d'ajouter le client: ${client.name}, ce dernier existe déjà`,
			});
		} else {
			await insertClient(client);
			setIsOpen(false);
		}
	};

	return (
		<Box position="fixed" top={52.4} zIndex={2} bgcolor={colors.black100} width={257}>
			<Divider />
			<Box p={1}>
				<IconButton color="inherit" onClick={() => setIsOpen(true)} size="large">
					<Box pr={1} pt={0.5}>
						<AddCircleIcon />
					</Box>
					<Typography variant="h6">Ajouter un client</Typography>
				</IconButton>
			</Box>
			<Dialog onClose={() => setIsOpen(false)} open={isOpen} maxWidth="xs" fullWidth>
				<Formik<ClientInsertInput>
					initialValues={initialValues}
					validationSchema={clientInsertSchema}
					onSubmit={handleSubmit}
					validateOnChange={false}
				>
					<Form>
						<DialogTitle>
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={11}>
									<Typography variant="h2">Ajouter un nouveau client</Typography>
								</Grid>
								<Grid>
									<IconButton aria-label="close" onClick={() => setIsOpen(false)} size="large">
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>
						</DialogTitle>
						<Divider />
						<Box p={3}>
							<Typography variant="h4">Nom du client</Typography>
							<FormikTextField
								name="name"
								fullWidth
								variant="outlined"
								inputProps={{
									onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
										setClientName(event.target.value);
									},
								}}
							/>
						</Box>
						<Box pl={3}>
							<Typography variant="h4">Hiérarchie</Typography>
						</Box>
						<Box pl={1.5} mb={2} display="flex" flexDirection="row" alignItems="center">
							<Field component={Checkbox} color="primary" type="checkbox" name="isRoot" />
							<Typography>Entité mère</Typography>
						</Box>
						<Divider />
						<Box p={3} display="flex" flexDirection="row-reverse">
							<Button variant="contained" color="primary" type="submit">
								Ajouter le client
							</Button>
						</Box>
					</Form>
				</Formik>
			</Dialog>
			<Divider />
		</Box>
	);
};

export default ClientDialogForm;
