import { gql } from '@apollo/client';

export const GET_USERS = gql`
	query GetUsers {
		users {
			id
			givenName
			familyName
			email
			phoneNumber
			initials
			isActive
			role {
				name
				id
			}
		}
	}
`;

export const GET_PASSWORD = gql`
	query GetPassword {
		password {
			password
		}
	}
`;

export const GET_USER_BY_ID = gql`
	query GetUserById($userId: String!) {
		user(id: $userId) {
			id
			givenName
			familyName
			initials
			email
			phoneNumber
			canViewSalePrices
			replacedById
			canViewBuyingPrices
			replacedFrom
			replacedTo
			isActive
			role {
				id
				name
			}
			userClients {
				id
				name
				isRoot
				parentClientId
			}
		}
	}
`;

export const GET_CURRENT_USER = gql`
	query GetCurrentUser {
		currentUser {
			id
			email
			phoneNumber
			familyName
			givenName
			role {
				id
				name
				permissions {
					key
					name
				}
			}
		}
	}
`;
export const GET_USERS_BY_CLIENT_IDS = gql`
	query GetUsersByClientIds($clientId: String!, $roleNames: [String!]) {
		usersByClientIds(clientId: $clientId, roleNames: $roleNames) {
			id
			givenName
			familyName
		}
	}
`;
