import React from 'react';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Box, IconButton } from '@mui/material';
import { PreviewFile } from '../../../../../components/form/FormikFilesField';
import { ImportInvoiceProps } from '../definitions';
import { GetAppOutlined } from '@mui/icons-material';
import { InvoiceStatus } from 'graphql/types';

export const ImportInvoice = ({ accept, name, isMultiple = false, row }: ImportInvoiceProps) => {
	const [, , helpers] = useField<File | string | null | ArrayBuffer>(name);
	const [createdAtField, , createdAtHelpers] = useField<Date | null | undefined>(
		`childrenOrder[${row.id}].invoices[0].createdAt`
	);
	const [validateAtField, , validateAtHelpers] = useField<Date | null | undefined>(
		`childrenOrder[${row.id}].invoices[0].validateAt`
	);
	const [sendAtField, , sendAtHelpers] = useField<Date | null | undefined>(
		`childrenOrder[${row.id}].invoices[0].sendAt`
	);
	const [, , statustHelpers] = useField<InvoiceStatus>(`childrenOrder[${row.id}].invoices[0].status`);

	const { getRootProps, getInputProps } = useDropzone({
		accept,
		multiple: isMultiple,
		onDrop: (acceptedFiles: PreviewFile[]) => {
			createdAtHelpers.setValue(createdAtField.value ?? new Date());
			validateAtHelpers.setValue(validateAtField.value ?? new Date());
			sendAtHelpers.setValue(sendAtField.value ?? new Date());
			statustHelpers.setValue(InvoiceStatus.Counted);
			helpers.setValue(acceptedFiles[0]);
		},
	});

	return (
		<Box {...getRootProps()}>
			<input {...getInputProps()} multiple={isMultiple} />
			<Box>
				<IconButton aria-label="upload picture" component="span" size="large">
					<GetAppOutlined fontSize="small" />
				</IconButton>
			</Box>
		</Box>
	);
};

export default ImportInvoice;
