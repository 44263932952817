import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { ShopImport } from './components/ShopImport';
import Path from '../../components/Path';
import useClients from './hooks/useClientsQuery';
import { GetClientsQuery } from 'clients/graphql/queries.definitions';


export const ImportRoute = () => {
  const { data } = useClients();
	const paths: (string | undefined)[] = ['Administration', 'Import'];
	return (
		<Box ml={7} mr={7} mb={3}>
			<Path paths={paths} />
			<Divider />
			<Box width="100%" my={5}>
				<Typography variant="h2">Importer des données dans OneSupply</Typography>
			</Box>
			{data ? <ShopImport clientNames={data?.clients.map((client: GetClientsQuery['clients'][0]) => client.name)} /> : null}
		</Box>
	);
};
