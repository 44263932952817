import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import colors from 'app/theme/colors.scss';
import { SubmitAndResetButton } from 'components/buttons/submit-and-reset.button';
import { useField } from 'formik';
import { OrderItem } from 'graphql/types';
import usePopin from 'hooks/usePopin';
import React, { useState } from 'react';
import OrderItemDeleteContent from '../../order-cockpit/client-document/component/OrderItemDeleteContent';

import OrderItemProduct from './OrderItem';
import { ComponentTitle } from '../../../definition';

const OrderItems = ({ title }: ComponentTitle) => {
	const [isHidePopin, setIsHidePopin] = useState<boolean>(false);

	const [index, setIndex] = useState<number>(0);
	const [field, , helpers] = useField<(OrderItem & { supportId: string })[]>('orderItem');
	const [orderItemIdsToDeleteField, , orderItemIdsToDeleteHelpers] = useField<string[]>('orderItemIdsToDelete');

	const handleClosePopin = () => setIsHidePopin(false);

	const handleDeleteProduct = () => {
		setIsHidePopin(false);
		const newOrderItem = [...field.value];
		const orderItemToDelete = newOrderItem?.splice(index, 1);
		orderItemIdsToDeleteHelpers.setValue([orderItemToDelete[0].id, ...orderItemIdsToDeleteField.value]);
		helpers.setValue(newOrderItem);
	};
	const headerTitle = field?.value ? `Supprimer ${field?.value[index]?.productName}` : '';

	const [openPopin, renderPopin] = usePopin({
		headerTitle: headerTitle,
		maxWidth: 'xs',
		fullWidth: false,
		isDisplayHeader: true,
		bottomFooter: (
			<SubmitAndResetButton
				handleCancel={handleClosePopin}
				handleSubmit={handleDeleteProduct}
				submitBtnTitle="Supprimer le produit"
				cancelBtnTitle="Annuler"
				bkgColor={colors.error}
			/>
		),
	});

	const handleDeleteOrderItem = async (index: number) => {
		setIndex(index);
		setIsHidePopin(true);
		openPopin();
	};

	return (
		<Box my={4}>
			{isHidePopin && renderPopin(<OrderItemDeleteContent />)}
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">{title}</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box ml={17}>
				{field.value &&
					field.value.map((orderItem, index) => (
						<OrderItemProduct
							key={index}
							productName={orderItem.productName}
							index={index}
							productId={orderItem.productId}
							orderItem={orderItem}
							handleDelete={handleDeleteOrderItem}
						/>
					))}
				{(!field.value || field.value?.length < 1) && (
					<Card aria-label="empty-products">
						<CardContent>
							<Typography variant="h5" color="textSecondary">
								Aucun produit ajouté
							</Typography>
						</CardContent>
					</Card>
				)}
			</Box>
		</Box>
	);
};

export default OrderItems;
