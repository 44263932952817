import React from 'react';
import { Box } from '@mui/material';
import Loading from '../components/loading/Loading';
import { ClientPriceForm, ClientPriceModel } from './form/ClientPriceForm';
import { ClientPrice } from '../graphql/types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { validationSchema } from './form/validation-schema';
import { useManageSellingPrice } from './hooks/useManageSellingPrice';

export const ClientPriceRoute = (): JSX.Element => {
	const {
		supportId,
		supportMetaData,
		loading,
		onSubmit,
		paths,
		headlines,
		isConsultSellingPrice,
		isConsultAndEditSellingPrice,
		salePriceProduct,
		setIsConsultSellingPrice,
		getClientPriceSupportCharacteristic,
	} = useManageSellingPrice();

	if (loading || !paths) return <Loading loading={loading} />;

	return (
		<Box mx={7}>
			<Formik<ClientPriceModel & { isDistribution: boolean }>
				initialValues={salePriceProduct}
				enableReinitialize
				validationSchema={() => Yup.lazy((values: ClientPrice) => validationSchema(values))}
				onSubmit={(clientPrice) => onSubmit(clientPrice)}
				validateOnChange={false}
			>
				<ClientPriceForm
					paths={paths}
					supportId={supportId}
					supportMetaData={supportMetaData}
					headlines={headlines}
					transporterId={salePriceProduct?.transporter?.id}
					isConsultAndEditSellingPrice={isConsultAndEditSellingPrice}
					onSelectStatusSellingPrice={setIsConsultSellingPrice}
					isConsultSellingPrice={isConsultSellingPrice}
					{...getClientPriceSupportCharacteristic()}
				/>
			</Formik>
		</Box>
	);
};
