export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
	DateTime: any;
	/** The `Upload` scalar type represents a file upload. */
	Upload: any;
};

export type AdditionalMargins = {
	__typename?: 'AdditionalMargins';
	prixCalage: MarginType;
	prixHorsCalage: MarginType;
	prixExemplaireSupp: MarginType;
	prixChangementPlaque: MarginType;
	prixTraitementFichier: MarginType;
	autrePrixFixe: MarginType;
	prixCliche: MarginType;
	prixOutillage: MarginType;
	distributions?: Maybe<Array<Distribution>>;
};

export type AdditionalMarginsInput = {
	prixCalage: MarginType;
	prixHorsCalage: MarginType;
	prixExemplaireSupp: MarginType;
	prixChangementPlaque: MarginType;
	prixTraitementFichier: MarginType;
	autrePrixFixe: MarginType;
	prixCliche: MarginType;
	prixOutillage: MarginType;
};

export type Address = {
	__typename?: 'Address';
	id: Scalars['String'];
	name: Scalars['String'];
	firstAddress?: Maybe<Scalars['String']>;
	secondAddress?: Maybe<Scalars['String']>;
	thirdAddress?: Maybe<Scalars['String']>;
	postBox?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	city: Scalars['String'];
	country?: Maybe<Scalars['String']>;
	isShipping: Scalars['Boolean'];
	deliveryDepartment?: Maybe<Scalars['String']>;
};

export type AddressInput = {
	id?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	firstAddress?: Maybe<Scalars['String']>;
	secondAddress?: Maybe<Scalars['String']>;
	thirdAddress?: Maybe<Scalars['String']>;
	postBox?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	city: Scalars['String'];
	country?: Maybe<Scalars['String']>;
	isShipping: Scalars['Boolean'];
	deliveryDepartment?: Maybe<Scalars['String']>;
};

export type AttributConditionnement = {
	__typename?: 'AttributConditionnement';
	numberOfLots: Scalars['Float'];
	total?: Maybe<Scalars['Float']>;
	conditionnementList: Array<AttributConditionnementItem>;
};

export type AttributConditionnementInput = {
	numberOfLots: Scalars['Float'];
	total?: Maybe<Scalars['Float']>;
	conditionnementList: Array<AttributConditionnementItemInput>;
};

export type AttributConditionnementItem = {
	__typename?: 'AttributConditionnementItem';
	numberOfExLots?: Maybe<Scalars['Float']>;
	firstConditionnement?: Maybe<Conditionnement>;
	secondConditionnement?: Maybe<Conditionnement>;
	threeConditionnement?: Maybe<Conditionnement>;
};

export type AttributConditionnementItemInput = {
	numberOfExLots?: Maybe<Scalars['Float']>;
	firstConditionnement?: Maybe<ConditionnementInput>;
	secondConditionnement?: Maybe<ConditionnementInput>;
	threeConditionnement?: Maybe<ConditionnementInput>;
};

export type Attribute = {
	__typename?: 'Attribute';
	family?: Maybe<Scalars['String']>;
	index?: Maybe<Scalars['Float']>;
	tableName?: Maybe<Scalars['String']>;
	familiesGroup?: Maybe<Scalars['String']>;
	key?: Maybe<AttributeKey>;
	type?: Maybe<ButtonType>;
	clientQuoteKey?: Maybe<ClientQuoteKey>;
	clientQuoteItemKey?: Maybe<ClientQuoteItemKey>;
	id: Scalars['String'];
	name: Scalars['String'];
	isChecked?: Maybe<Scalars['Boolean']>;
};

export enum AttributeKey {
	TypeImpression = 'typeImpression',
	Matiere = 'matiere',
	Conditionnement = 'conditionnement',
	TypeSupport = 'typeSupport',
	Faconnage = 'faconnage',
	NbCouleurRecto = 'nbCouleurRecto',
	DescriptionIndication = 'descriptionIndication',
	PrecisionsFaconnage = 'precisionsFaconnage',
	TypePapier = 'typePapier',
	TypeMatiere = 'typeMatiere',
	NbCouleurVerso = 'nbCouleurVerso',
	OptionsFaconnage = 'optionsFaconnage',
	Format = 'format',
	Orientation = 'orientation',
	Couleur = 'couleur',
	ImpressionRectoVerso = 'impressionRectoVerso',
	Decoupe = 'decoupe',
	Surfacage = 'surfacage',
	PaginationTotale = 'paginationTotale',
	Fourniture = 'fourniture',
	GrammageGr = 'grammageGr',
	PrecisionsFourniture = 'precisionsFourniture',
	EpaisseurMicrons = 'epaisseurMicrons',
	PaginationCouverture = 'paginationCouverture',
	FinitionRecto = 'finitionRecto',
	FinitionVerso = 'finitionVerso',
	NbCouleurCouvertureRecto = 'nbCouleurCouvertureRecto',
	PaginationInterieur = 'paginationInterieur',
	Classification = 'classification',
	PaginationInterieurEncart = 'paginationInterieurEncart',
	Certification = 'certification',
	NbCouleurCouvertureVerso = 'nbCouleurCouvertureVerso',
	MatiereCouverture = 'matiereCouverture',
	NbCouleurInterieurRecto = 'nbCouleurInterieurRecto',
	LargeurFormatOuvertCm = 'largeurFormatOuvertCm',
	TypeMatiereCouverture = 'typeMatiereCouverture',
	LongueurFormatOuvertCm = 'longueurFormatOuvertCm',
	NbCouleurInterieurVerso = 'nbCouleurInterieurVerso',
	GrammageCouvertureGr = 'grammageCouvertureGr',
	LargeurFormatPageCm = 'largeurFormatPageCm',
	NbCouleurInterieurEncartRecto = 'nbCouleurInterieurEncartRecto',
	MatiereInterieur = 'matiereInterieur',
	LongueurFormatPageCm = 'longueurFormatPageCm',
	NbCouleurInterieurEncartVerso = 'nbCouleurInterieurEncartVerso',
	LargeurFormatFiniCm = 'largeurFormatFiniCm',
	SurfacageCouvertureRecto = 'surfacageCouvertureRecto',
	TypeMatiereInterieur = 'typeMatiereInterieur',
	LongueurFormatFiniCm = 'longueurFormatFiniCm',
	GrammageInterieurGr = 'grammageInterieurGr',
	TauxSurfacage = 'tauxSurfacage',
	ImpressionLogo = 'impressionLogo',
	LargeurCouvertureFormatOuvertCm = 'largeurCouvertureFormatOuvertCm',
	LongueurCouvertureFormatOuvertCm = 'longueurCouvertureFormatOuvertCm',
	Repiquage = 'repiquage',
	RepiquageDos = 'repiquageDOS',
	LargeurCouvertureFormatPageCm = 'largeurCouvertureFormatPageCm',
	RepiquageCoeur = 'repiquageCoeur',
	LongueurCouvertureFormatPageCm = 'longueurCouvertureFormatPageCm',
	Chant = 'chant',
	LargeurCouvertureFormatFiniCm = 'largeurCouvertureFormatFiniCm',
	LongueurCouvertureFormatFiniCm = 'longueurCouvertureFormatFiniCm',
	LargeurInterieurFormatOuvertCm = 'largeurInterieurFormatOuvertCm',
	LongueurInterieurFormatOuvertCm = 'longueurInterieurFormatOuvertCm',
	LargeurInterieurFormatPageCm = 'largeurInterieurFormatPageCm',
	LongueurInterieurFormatPageCm = 'longueurInterieurFormatPageCm',
	LargeurInterieurFormatFiniCm = 'largeurInterieurFormatFiniCm',
	LongueurInterieurFormatFiniCm = 'longueurInterieurFormatFiniCm',
	LargeurInterieurEncartFormatOuvertCm = 'largeurInterieurEncartFormatOuvertCm',
	LongueurInterieurEncartFormatOuvertCm = 'longueurInterieurEncartFormatOuvertCm',
	LargeurInterieurEncartFormatPageCm = 'largeurInterieurEncartFormatPageCm',
	LongueurInterieurEncartFormatPageCm = 'longueurInterieurEncartFormatPageCm',
	LargeurInterieurEncartFormatFiniCm = 'largeurInterieurEncartFormatFiniCm',
	LongueurInterieurEncartFormatFiniCm = 'longueurInterieurEncartFormatFiniCm',
	ProfondeurFormatFiniCm = 'profondeurFormatFiniCm',
	DiametreCm = 'diametreCm',
	Taille = 'taille',
	EnveloppeFenetre = 'enveloppeFenetre',
	LargeurTailleFenetreCm = 'largeurTailleFenetreCm',
	LongueurTailleFenetreCm = 'longueurTailleFenetreCm',
	OuvertureEnveloppe = 'ouvertureEnveloppe',
	SurfacageCouvertureVerso = 'surfacageCouvertureVerso',
	SurfacageInterieurRecto = 'surfacageInterieurRecto',
	SurfacageInterieurVerso = 'surfacageInterieurVerso',
	TypeEnvoi = 'typeEnvoi',
	Ciblage = 'ciblage',
	ZoneTerritorial = 'zoneTerritorial',
	ObjectifMarketing = 'objectifMarketing',
	PaysDeDiffusion = 'paysDeDiffusion',
	ModeleAchat = 'modeleAchat',
	LargeurPixel = 'largeurPixel',
	LongueurPixel = 'longueurPixel',
	FondsPerdus = 'fondsPerdus',
	FaconnageDeLivraison = 'faconnageDeLivraison',
}

export type AttributesFilterInput = {
	typeImpression?: Maybe<Scalars['String']>;
	matiere?: Maybe<Scalars['String']>;
	conditionnement?: Maybe<AttributConditionnementInput>;
	typeSupport?: Maybe<Scalars['String']>;
	faconnage?: Maybe<Scalars['String']>;
	nbCouleurRecto?: Maybe<Scalars['String']>;
	descriptionIndication?: Maybe<Scalars['String']>;
	precisionsFaconnage?: Maybe<Scalars['String']>;
	typePapier?: Maybe<Scalars['String']>;
	typeMatiere?: Maybe<Scalars['String']>;
	nbCouleurVerso?: Maybe<Scalars['String']>;
	optionsFaconnage?: Maybe<Scalars['String']>;
	format?: Maybe<Scalars['String']>;
	orientation?: Maybe<Scalars['String']>;
	couleur?: Maybe<Scalars['String']>;
	impressionRectoVerso?: Maybe<Scalars['String']>;
	decoupe?: Maybe<Scalars['String']>;
	surfacage?: Maybe<Scalars['String']>;
	paginationTotale?: Maybe<Scalars['String']>;
	fourniture?: Maybe<Scalars['String']>;
	grammageGr?: Maybe<Scalars['String']>;
	precisionsFourniture?: Maybe<Scalars['String']>;
	epaisseurMicrons?: Maybe<Scalars['String']>;
	paginationCouverture?: Maybe<Scalars['String']>;
	finitionRecto?: Maybe<Scalars['String']>;
	finitionVerso?: Maybe<Scalars['String']>;
	nbCouleurCouvertureRecto?: Maybe<Scalars['String']>;
	paginationInterieur?: Maybe<Scalars['String']>;
	classification?: Maybe<Scalars['String']>;
	paginationInterieurEncart?: Maybe<Scalars['String']>;
	certification?: Maybe<Scalars['String']>;
	nbCouleurCouvertureVerso?: Maybe<Scalars['String']>;
	matiereCouverture?: Maybe<Scalars['String']>;
	nbCouleurInterieurRecto?: Maybe<Scalars['String']>;
	largeurFormatOuvertCm?: Maybe<Scalars['String']>;
	typeMatiereCouverture?: Maybe<Scalars['String']>;
	longueurFormatOuvertCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurVerso?: Maybe<Scalars['String']>;
	grammageCouvertureGr?: Maybe<Scalars['String']>;
	largeurFormatPageCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurEncartRecto?: Maybe<Scalars['String']>;
	matiereInterieur?: Maybe<Scalars['String']>;
	longueurFormatPageCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurEncartVerso?: Maybe<Scalars['String']>;
	largeurFormatFiniCm?: Maybe<Scalars['String']>;
	surfacageCouvertureRecto?: Maybe<Scalars['String']>;
	typeMatiereInterieur?: Maybe<Scalars['String']>;
	longueurFormatFiniCm?: Maybe<Scalars['String']>;
	grammageInterieurGr?: Maybe<Scalars['String']>;
	tauxSurfacage?: Maybe<Scalars['String']>;
	impressionLogo?: Maybe<Scalars['String']>;
	largeurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
	repiquage?: Maybe<Scalars['String']>;
	repiquageDOS?: Maybe<Scalars['String']>;
	largeurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
	repiquageCoeur?: Maybe<Scalars['String']>;
	longueurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
	chant?: Maybe<Scalars['String']>;
	largeurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
	longueurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatPageCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatPageCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
	profondeurFormatFiniCm?: Maybe<Scalars['String']>;
	diametreCm?: Maybe<Scalars['String']>;
	taille?: Maybe<Scalars['String']>;
	enveloppeFenetre?: Maybe<Scalars['String']>;
	largeurTailleFenetreCm?: Maybe<Scalars['String']>;
	longueurTailleFenetreCm?: Maybe<Scalars['String']>;
	ouvertureEnveloppe?: Maybe<Scalars['String']>;
	surfacageCouvertureVerso?: Maybe<Scalars['String']>;
	surfacageInterieurRecto?: Maybe<Scalars['String']>;
	surfacageInterieurVerso?: Maybe<Scalars['String']>;
	typeEnvoi?: Maybe<Scalars['String']>;
	ciblage?: Maybe<Scalars['String']>;
	zoneTerritorial?: Maybe<Scalars['String']>;
	objectifMarketing?: Maybe<Scalars['String']>;
	paysDeDiffusion?: Maybe<Scalars['String']>;
	modeleAchat?: Maybe<Scalars['String']>;
	largeurPixel?: Maybe<Scalars['String']>;
	longueurPixel?: Maybe<Scalars['String']>;
	codeAt?: Maybe<Scalars['String']>;
};

export type Auth = {
	__typename?: 'Auth';
	accessToken: Scalars['String'];
	user: User;
};

export type BestSellingOrders = {
	__typename?: 'BestSellingOrders';
	onePacDocumentId: Scalars['String'];
	productSellingPriceHtMin?: Maybe<Scalars['Float']>;
	onePacDocumentIdCount: Scalars['Float'];
};

export type BigShopConfig = {
	__typename?: 'BigShopConfig';
	category?: Maybe<BigShopConfigCategory>;
	subCategory?: Maybe<BigShopConfigSubCategory>;
	categoryIcon?: Maybe<BigShopConfigCategoryIcon>;
	supportId?: Maybe<Scalars['String']>;
	callQuantity?: Maybe<Scalars['Float']>;
	predefinedQuantity1?: Maybe<Scalars['Float']>;
	predefinedQuantity2?: Maybe<Scalars['Float']>;
	predefinedQuantity3?: Maybe<Scalars['Float']>;
	predefinedQuantity4?: Maybe<Scalars['Float']>;
	predefinedQuantity5?: Maybe<Scalars['Float']>;
};

export enum BigShopConfigCategory {
	SignagePos = 'SignagePos',
	AdvertisingPrints = 'AdvertisingPrints',
	AdvertisingItems = 'AdvertisingItems',
	StationeryShop = 'StationeryShop',
	Digital = 'Digital',
	PostersAndLeaflets = 'PostersAndLeaflets',
	SignsAndSignage = 'SignsAndSignage',
	OfferBrochures = 'OfferBrochures',
	Billboards = 'Billboards',
	TrafficSigns = 'TrafficSigns',
}

export enum BigShopConfigCategoryIcon {
	MenuBook = 'MenuBook',
	Tapas = 'Tapas',
	FormatShapes = 'FormatShapes',
	Phonelink = 'Phonelink',
	Style = 'Style',
}

export enum BigShopConfigSubCategory {
	Display = 'Display',
	PointOfSaleAdvertising = 'PointOfSaleAdvertising',
	SalonAndEvent = 'SalonAndEvent',
	Goodies = 'Goodies',
	Office = 'Office',
	Correspondance = 'Correspondance',
	Restoration = 'Restoration',
	AdvertisingPrints = 'AdvertisingPrints',
	Digital = 'Digital',
	PostersAndLeaflets = 'PostersAndLeaflets',
	SignsAndSignage = 'SignsAndSignage',
	OfferBrochures = 'OfferBrochures',
	Billboards = 'Billboards',
	TrafficSigns = 'TrafficSigns',
}

export type BigShopConfigUpsertInput = {
	category?: Maybe<BigShopConfigCategory>;
	subCategory?: Maybe<BigShopConfigSubCategory>;
	categoryIcon?: Maybe<BigShopConfigCategoryIcon>;
	supportId: Scalars['String'];
	callQuantity?: Maybe<Scalars['Float']>;
	predefinedQuantity1?: Maybe<Scalars['Float']>;
	predefinedQuantity2?: Maybe<Scalars['Float']>;
	predefinedQuantity3?: Maybe<Scalars['Float']>;
	predefinedQuantity4?: Maybe<Scalars['Float']>;
	predefinedQuantity5?: Maybe<Scalars['Float']>;
};

export enum BillingCadence {
	Monthly = 'Monthly',
	Weekly = 'Weekly',
	Daily = 'Daily',
	ToOrder = 'ToOrder',
}

export enum BoxShippingMode {
	BeforeNine = 'BeforeNine',
	BeforeThirteen = 'BeforeThirteen',
}

export type BoxesInfo = {
	__typename?: 'BoxesInfo';
	boxesNumber: Scalars['Float'];
	boxWeight: Scalars['Float'];
};

export enum ButtonType {
	Checkbox = 'Checkbox',
	Radio = 'Radio',
}

export type CharacteristicsQuoteAttribute = {
	__typename?: 'CharacteristicsQuoteAttribute';
	key?: Maybe<AttributeKey>;
	clientQuoteKey?: Maybe<ClientQuoteKey>;
	clientQuoteItemKey?: Maybe<ClientQuoteItemKey>;
	type: ButtonType;
	attributeId: Scalars['String'];
	name: Scalars['String'];
	isChecked: Scalars['Boolean'];
};

export enum ClariPrintCorrespondence {
	Feuillet = 'Feuillet',
	Depliants = 'Depliants',
	ChemiseRabat = 'ChemiseRabat',
	Brouchures = 'Brouchures',
	Enveloppe = 'Enveloppe',
}

export type Client = {
	__typename?: 'Client';
	type: EntityType;
	billingCadence?: Maybe<BillingCadence>;
	possibleBillings: Array<EntityType>;
	defaultBilling?: Maybe<EntityType>;
	translation?: Maybe<ClientQuoteTranslate>;
	elpevSite?: Maybe<ElpevSite>;
	fileCode?: Maybe<Scalars['String']>;
	territorialArea?: Maybe<TerritorialArea>;
	id: Scalars['String'];
	name: Scalars['String'];
	createdAt: Scalars['DateTime'];
	updatedAt: Scalars['DateTime'];
	isProspect: Scalars['Boolean'];
	isActif: Scalars['Boolean'];
	companyBase?: Maybe<Scalars['String']>;
	tradeNameInvoice?: Maybe<Scalars['String']>;
	transplantName?: Maybe<Scalars['String']>;
	siretNumber?: Maybe<Scalars['String']>;
	vatNumber?: Maybe<Scalars['String']>;
	rcsNumber?: Maybe<Scalars['String']>;
	vatType?: Maybe<Scalars['Boolean']>;
	parentClientId?: Maybe<Scalars['String']>;
	rootClientId?: Maybe<Scalars['String']>;
	isRoot: Scalars['Boolean'];
	regionId?: Maybe<Scalars['String']>;
	typologyId?: Maybe<Scalars['String']>;
	commercialId?: Maybe<Scalars['String']>;
	responsibleId?: Maybe<Scalars['String']>;
	users?: Maybe<UserDetails>;
	paymentDeadline?: Maybe<Scalars['String']>;
	projectManagerId?: Maybe<Scalars['String']>;
	billingAddress?: Maybe<Address>;
	structures: Array<ClientStructure>;
	typologies?: Maybe<Array<ClientTypology>>;
	regions?: Maybe<Array<ClientRegion>>;
	isGroupedBilling?: Maybe<Scalars['Boolean']>;
	isHorsRFA?: Maybe<Scalars['Boolean']>;
	mainRfaRate?: Maybe<Scalars['Float']>;
	rfaComment?: Maybe<Scalars['String']>;
	defaultCommercialProductMargin?: Maybe<Scalars['Float']>;
	defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
	rootClient?: Maybe<Client>;
	clientContact?: Maybe<Array<Contact>>;
	logo: Scalars['String'];
	clientsSupports: Array<ClientSupport>;
	clientShippingAddress: Array<ClientShippingAddress>;
	clientPrices?: Maybe<Array<ClientPrice>>;
	possibleBillingsClients?: Maybe<Array<Client>>;
	clientAccountingAccount?: Maybe<Scalars['String']>;
	clientAnalyticCode?: Maybe<Scalars['String']>;
	groupingCode?: Maybe<Scalars['String']>;
	clientCode?: Maybe<Scalars['String']>;
	structureTitle?: Maybe<Scalars['String']>;
	level?: Maybe<Scalars['Float']>;
	isBillable?: Maybe<Scalars['Boolean']>;
	ancestors?: Maybe<Array<Scalars['String']>>;
	email?: Maybe<Scalars['String']>;
};

export type ClientChildren = {
	__typename?: 'ClientChildren';
	id: Scalars['String'];
	name: Scalars['String'];
	type?: Maybe<Scalars['String']>;
	isActif: Scalars['Boolean'];
	fileCode?: Maybe<Scalars['String']>;
	children: Array<ClientChildren>;
};

export type ClientFilter = {
	name?: Maybe<StringFilter>;
};

export type ClientFilterInput = {
	AND?: Maybe<Array<ClientFilterInput>>;
	OR?: Maybe<Array<ClientFilterInput>>;
	NOT?: Maybe<Array<ClientFilterInput>>;
	regionId?: Maybe<StringFilter>;
	typologyId?: Maybe<StringFilter>;
	type?: Maybe<EntityTypeFilter>;
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	salesperson?: Maybe<Scalars['String']>;
	isProspect?: Maybe<Scalars['Boolean']>;
	isActif?: Maybe<Scalars['Boolean']>;
	companyBase?: Maybe<Scalars['String']>;
	tradeNameInvoice?: Maybe<Scalars['String']>;
	transplantName?: Maybe<Scalars['String']>;
	siretNumber?: Maybe<Scalars['String']>;
	vatNumber?: Maybe<Scalars['String']>;
	rcsNumber?: Maybe<Scalars['String']>;
	vatType?: Maybe<Scalars['Boolean']>;
	comment?: Maybe<Scalars['String']>;
	isRoot?: Maybe<Scalars['Boolean']>;
	parentClientId?: Maybe<Scalars['String']>;
	rootClientId?: Maybe<Scalars['String']>;
};

export type ClientFilterInputByCompanyBaseInput = {
	name?: Maybe<Scalars['String']>;
	firstAddress?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	storeCode?: Maybe<Scalars['String']>;
};

export type ClientInsertInput = {
	type?: Maybe<EntityType>;
	billingCadence?: Maybe<BillingCadence>;
	possibleBillings?: Maybe<Array<EntityType>>;
	defaultBilling?: Maybe<EntityType>;
	territorialArea?: Maybe<TerritorialArea>;
	name: Scalars['String'];
	isRoot: Scalars['Boolean'];
	parentClientId?: Maybe<Scalars['String']>;
	rootClientId?: Maybe<Scalars['String']>;
	regionId?: Maybe<Scalars['String']>;
	typologyId?: Maybe<Scalars['String']>;
	isActif?: Maybe<Scalars['Boolean']>;
	companyBase?: Maybe<Scalars['String']>;
	tradeNameInvoice?: Maybe<Scalars['String']>;
	transplantName?: Maybe<Scalars['String']>;
	defaultCommercialProductMargin?: Maybe<Scalars['Float']>;
	isProspect?: Maybe<Scalars['Boolean']>;
	email?: Maybe<Scalars['String']>;
};

export type ClientOrderFilter = {
	name?: Maybe<StringFilter>;
};

export type ClientPrice = {
	__typename?: 'ClientPrice';
	/** @deprecated unused, to delete in all queries please! */
	palettes?: Maybe<Scalars['Float']>;
	/** @deprecated unused, to delete in all queries please! */
	tax?: Maybe<Scalars['Float']>;
	validatedQuoteDate?: Maybe<Scalars['DateTime']>;
	/** @deprecated to be deleted and replaced by status.ValidatedByCustomerQuote  */
	validatedByQuote?: Maybe<Scalars['Float']>;
	productPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	productSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	transportPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	transportSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	salePriceExcludingVAT?: Maybe<Scalars['Float']>;
	sellingPriceMargin?: Maybe<Scalars['Float']>;
	deliveryDepartment?: Maybe<Scalars['String']>;
	status: ClientPriceStatus;
	product?: Maybe<Product>;
	deliveryParcel: BoxShippingMode;
	transportMoment: TransportMoment;
	transportSellingPriceHtAdditionalMargin: MarginType;
	transportType: TransportType;
	transportSellingPriceHtWithClientRfaRectified?: Maybe<Scalars['Float']>;
	sellingPriceWithMargin: Margins;
	clientId: Scalars['String'];
	productId: Scalars['String'];
	quantity: Scalars['Float'];
	cost: Scalars['Float'];
	isPrepress: Scalars['Boolean'];
	productName: Scalars['String'];
	nbShippingPoints: Scalars['Float'];
	franco: Scalars['Boolean'];
	createdAt: Scalars['DateTime'];
	updatedAt: Scalars['DateTime'];
	defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
	defaultCommercialProductMargin: Scalars['Float'];
	mainRfaRate?: Maybe<Scalars['Float']>;
	isWithRfaClient: Scalars['Boolean'];
	numberPlateChanges: Scalars['Float'];
	numberFileProcessing: Scalars['Float'];
	numberVersion: Scalars['Float'];
	numberShots: Scalars['Float'];
	tools: Scalars['Boolean'];
	client?: Maybe<Client>;
	buyingPriceWithAmalgam: Scalars['Float'];
	additionalMargins: AdditionalMargins;
	margins: Margins;
	purchasePriceExcludingTax: Margins;
	productTotalWeight: Scalars['Float'];
	maxBoxWeight: Scalars['Float'];
	bestDeliveryParcelPurchasePrice: Scalars['Float'];
	transportPurchasePriceHt: Scalars['Float'];
	transportRfa: Scalars['Float'];
	transportSellingPriceHt: Scalars['Float'];
	transportSellingPriceMargin: Scalars['Float'];
	transportSellingPriceByDefaultHt: Scalars['Float'];
	transportPurchasePriceHtWithRfa: Scalars['Float'];
	corsicaDeliveryExtra: Scalars['Float'];
	isMechanizable: Scalars['Boolean'];
	isFinalDistribution: Scalars['Boolean'];
	productSalesPriceExcludingVATRectified?: Maybe<Scalars['Float']>;
	productSalesPriceExcludingVATWithRfa: Scalars['Float'];
	totalProductSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	sellingPriceMarginRate: Scalars['Float'];
	rfaClientEuro: Scalars['Float'];
	productPurchasePriceExcludingVATWithRFA?: Maybe<Scalars['Float']>;
	transportSellingPriceHtWithClientRfa?: Maybe<Scalars['Float']>;
	isIdentical: Scalars['Boolean'];
	quantities: Array<Scalars['Float']>;
	departingDepartment?: Maybe<Scalars['String']>;
	transporter?: Maybe<TransporterIdentity>;
	deliveryPackagePurchasePriceInfo?: Maybe<Scalars['String']>;
	isSpecificShipment?: Maybe<Scalars['Boolean']>;
	specificSupplierId?: Maybe<Scalars['String']>;
	specificSupplier?: Maybe<Supplier>;
	specificEmail?: Maybe<Scalars['String']>;
	isIncluded: Scalars['Boolean'];
};

export type ClientPriceInput = {
	palettes?: Maybe<Scalars['Float']>;
	tax?: Maybe<Scalars['Float']>;
	validatedByQuote?: Maybe<Scalars['Float']>;
	status: ClientPriceStatus;
	deliveryParcel: BoxShippingMode;
	transportMoment: TransportMoment;
	transportSellingPriceHtAdditionalMargin: MarginType;
	transportType: TransportType;
	transportPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	sellingPriceMargin?: Maybe<Scalars['Float']>;
	transportSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	sellingPriceWithMargin: MarginsInput;
	clientPriceUniqInput?: Maybe<ClientPriceUniqInput>;
	clientId: Scalars['String'];
	productId: Scalars['String'];
	quantity: Scalars['Float'];
	cost: Scalars['Float'];
	isPrepress: Scalars['Boolean'];
	productName: Scalars['String'];
	nbShippingPoints?: Maybe<Scalars['Float']>;
	franco: Scalars['Boolean'];
	defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
	defaultCommercialProductMargin?: Maybe<Scalars['Float']>;
	mainRfaRate?: Maybe<Scalars['Float']>;
	validatedQuoteDate?: Maybe<Scalars['DateTime']>;
	isWithRfaClient: Scalars['Boolean'];
	deliveryDepartment?: Maybe<Scalars['String']>;
	numberPlateChanges: Scalars['Float'];
	numberFileProcessing: Scalars['Float'];
	numberVersion: Scalars['Float'];
	numberShots: Scalars['Float'];
	tools: Scalars['Boolean'];
	buyingPriceWithAmalgam: Scalars['Float'];
	additionalMargins: AdditionalMarginsInput;
	margins: MarginsInput;
	purchasePriceExcludingTax: MarginsInput;
	distributions?: Maybe<Array<DistributionInput>>;
	productTotalWeight: Scalars['Float'];
	maxBoxWeight: Scalars['Float'];
	bestDeliveryParcelPurchasePrice: Scalars['Float'];
	transportPurchasePriceHt: Scalars['Float'];
	transportRfa: Scalars['Float'];
	transportSellingPriceHt: Scalars['Float'];
	transportSellingPriceMargin: Scalars['Float'];
	transportSellingPriceByDefaultHt: Scalars['Float'];
	transportPurchasePriceHtWithRfa: Scalars['Float'];
	corsicaDeliveryExtra: Scalars['Float'];
	isMechanizable: Scalars['Boolean'];
	isFinalDistribution: Scalars['Boolean'];
	productSalesPriceExcludingVATRectified?: Maybe<Scalars['Float']>;
	productSalesPriceExcludingVATWithRfa: Scalars['Float'];
	totalProductSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	sellingPriceMarginRate: Scalars['Float'];
	rfaClientEuro: Scalars['Float'];
	productPurchasePriceExcludingVATWithRFA?: Maybe<Scalars['Float']>;
	transportSellingPriceHtWithClientRfa?: Maybe<Scalars['Float']>;
	transportSellingPriceHtWithClientRfaRectified?: Maybe<Scalars['Float']>;
	isIdentical: Scalars['Boolean'];
	quantities: Array<Scalars['Float']>;
	departingDepartment?: Maybe<Scalars['String']>;
	productPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	productSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	transporterId?: Maybe<Scalars['String']>;
	deliveryPackagePurchasePriceInfo?: Maybe<Scalars['String']>;
	isSpecificShipment?: Maybe<Scalars['Boolean']>;
	specificSupplierId?: Maybe<Scalars['String']>;
	specificEmail?: Maybe<Scalars['String']>;
	isIncluded: Scalars['Boolean'];
};

export type ClientPriceSortInput = {
	productName?: Maybe<SortOrder>;
	status?: Maybe<SortOrder>;
	client?: Maybe<ClientSortOrder>;
	createdAt?: Maybe<SortOrder>;
	product?: Maybe<ProductSortOrder>;
	operationName?: Maybe<SortOrder>;
	quantity?: Maybe<SortOrder>;
	numberVersion?: Maybe<SortOrder>;
	isPrepress?: Maybe<SortOrder>;
	productSalesPriceExcludingVAT?: Maybe<SortOrder>;
	transportSalesPriceExcludingVAT?: Maybe<SortOrder>;
	cost?: Maybe<SortOrder>;
};

export enum ClientPriceStatus {
	InProgress = 'InProgress',
	WaitingByValidationQuote = 'WaitingByValidationQuote',
	Obsolete = 'Obsolete',
	ValidatedByCustomerQuote = 'ValidatedByCustomerQuote',
}

export type ClientPriceUniqInput = {
	clientId: Scalars['String'];
	productId: Scalars['String'];
	quantity: Scalars['Float'];
	numberVersion: Scalars['Float'];
};

export type ClientPriceWithCustomQuantities = {
	__typename?: 'ClientPriceWithCustomQuantities';
	clientPrices: Array<ClientPrice>;
	totalTransportSalesPriceExcludingVAT: Scalars['Float'];
	totalCost: Scalars['Float'];
	totalProductSalesPriceExcludingVAT: Scalars['Float'];
	totalQuantity: Scalars['Float'];
};

export type ClientPricesDistributions = {
	__typename?: 'ClientPricesDistributions';
	productId: Scalars['String'];
	productSalesPriceExcludingVAT: Scalars['Float'];
	productPurchasePriceExcludingVAT: Scalars['Float'];
	transportSalesPriceExcludingVAT: Scalars['Float'];
	transportPurchasePriceExcludingVAT: Scalars['Float'];
	sellingPriceMargin: Scalars['Float'];
};

export type ClientPricesDistributionsInput = {
	distributions: Array<ClientQuoteDistributionInput>;
	clientId: Scalars['String'];
	numberVersion: Scalars['Float'];
};

export type ClientPricesFiltersInput = {
	status?: Maybe<ClientPriceStatus>;
	clientId: Scalars['String'];
	productId?: Maybe<Scalars['String']>;
	productName?: Maybe<Scalars['String']>;
	quantity?: Maybe<Scalars['Float']>;
	attributes?: Maybe<AttributesFilterInput>;
};

export type ClientPricesSearchInput = {
	productName?: Maybe<StringFilter>;
	client?: Maybe<ClientFilter>;
	createdAt?: Maybe<DateTimeFilter>;
	status?: Maybe<ClientPriceStatus>;
	fileReference?: Maybe<FileReferenceFiltersInput>;
	product?: Maybe<ProductSearchInput>;
	quantity?: Maybe<Scalars['Float']>;
	productSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	transportSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	cost?: Maybe<Scalars['Float']>;
	numberVersion?: Maybe<Scalars['Float']>;
};

export type ClientPricesWithPagination = {
	__typename?: 'ClientPricesWithPagination';
	clientPrices?: Maybe<Array<ClientPrice>>;
	total?: Maybe<Scalars['Float']>;
};

export type ClientQuote = {
	__typename?: 'ClientQuote';
	status: ClientQuoteStatus;
	vat: ClientQuoteVat;
	type: QuoteType;
	comment?: Maybe<Scalars['String']>;
	fileReference?: Maybe<FileReference>;
	id: Scalars['String'];
	createdAt: Scalars['DateTime'];
	updatedAt: Scalars['DateTime'];
	client: Client;
	version: Scalars['Float'];
	clientId: Scalars['String'];
	clientName: Scalars['String'];
	contact?: Maybe<Scalars['String']>;
	quoteRequestedBy?: Maybe<Scalars['String']>;
	operationName?: Maybe<Scalars['String']>;
	clientReference?: Maybe<Scalars['String']>;
	fileDeliveryDate?: Maybe<Scalars['DateTime']>;
	deliveryDate?: Maybe<Scalars['DateTime']>;
	clientQuoteItem?: Maybe<Array<ClientQuoteItem>>;
	estimatedCiteoTax: Scalars['Float'];
	totalPurchasePriceExcludingVAT: Scalars['Float'];
	totalSalesPriceExcludingVAT: Scalars['Float'];
	vatAmount: Scalars['Float'];
	totalSalesPriceVAT: Scalars['Float'];
	margin: Scalars['Float'];
	marginRate: Scalars['Float'];
	vatRate: Scalars['Float'];
	cost: Scalars['Float'];
	additionalCostsIncurred: Scalars['Float'];
	billingAddress?: Maybe<ClientQuoteBillingAddress>;
	fileReferenceId?: Maybe<Scalars['String']>;
	nbShippingPoints: Scalars['Float'];
	isDistribution: Scalars['Boolean'];
	clientQuoteDistributions?: Maybe<Array<ClientQuoteDistribution>>;
};

export type ClientQuoteBillingAddress = {
	__typename?: 'ClientQuoteBillingAddress';
	name?: Maybe<Scalars['String']>;
	firstAddress?: Maybe<Scalars['String']>;
	secondAddress?: Maybe<Scalars['String']>;
	thirdAddress?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
};

export type ClientQuoteBillingAddressInput = {
	name?: Maybe<Scalars['String']>;
	firstAddress?: Maybe<Scalars['String']>;
	secondAddress?: Maybe<Scalars['String']>;
	thirdAddress?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
};

export type ClientQuoteConfigAttributes = {
	clientId: Scalars['String'];
	attributeId: Scalars['String'];
};

export type ClientQuoteConfigAttributesCreateInput = {
	data: Array<ClientQuoteConfigAttributes>;
	quoteClient?: Maybe<QuoteClient>;
};

export type ClientQuoteConfigAttributesCreateModel = {
	__typename?: 'ClientQuoteConfigAttributesCreateModel';
	quoteConfig: Array<ClientQuoteConfigAttributesModel>;
	quoteClient: QuoteClientModel;
};

export type ClientQuoteConfigAttributesModel = {
	__typename?: 'ClientQuoteConfigAttributesModel';
	clientId: Scalars['String'];
	attributeId: Scalars['String'];
};

export type ClientQuoteDistribution = {
	__typename?: 'ClientQuoteDistribution';
	id: Scalars['String'];
	clientQuoteId: Scalars['String'];
	storeId: Scalars['String'];
	store?: Maybe<Client>;
	productId: Scalars['String'];
	productName: Scalars['String'];
	quantity: Scalars['Float'];
	clientShippingAddressId: Scalars['String'];
	clientShippingAddress?: Maybe<ClientShippingAddress>;
	transportMoment?: Maybe<Scalars['String']>;
	deliveryParcel?: Maybe<Scalars['String']>;
};

export type ClientQuoteDistributionInput = {
	transportMoment?: Maybe<TransportMoment>;
	deliveryParcel?: Maybe<BoxShippingMode>;
	clientQuoteId?: Maybe<Scalars['String']>;
	clientShippingAddressId: Scalars['String'];
	storeId: Scalars['String'];
	productId: Scalars['String'];
	productName: Scalars['String'];
	quantity: Scalars['Float'];
};

export enum ClientQuoteFileType {
	Xlsx = 'xlsx',
	Pdf = 'pdf',
}

export type ClientQuoteFilterInput = {
	id?: Maybe<StringFilter>;
	status?: Maybe<ClientQuoteStatus>;
	client?: Maybe<ClientFilter>;
	createdAt?: Maybe<DateTimeFilter>;
	quoteRequestedBy?: Maybe<StringFilter>;
	fileReference?: Maybe<FileReferenceFiltersInput>;
	fileReferenceId?: Maybe<Scalars['String']>;
};

export type ClientQuoteGenerateFileInput = {
	dataQuote: Scalars['String'];
	type: ClientQuoteFileType;
};

export type ClientQuoteItem = {
	__typename?: 'ClientQuoteItem';
	sellingPriceWithMargin: Margins;
	transportMoment: TransportMoment;
	transportSellingPriceHtAdditionalMargin: MarginType;
	transportType: TransportType;
	deliveryParcel: BoxShippingMode;
	id?: Maybe<Scalars['String']>;
	quantity: Scalars['Float'];
	productName: Scalars['String'];
	productId: Scalars['String'];
	numberVersion: Scalars['Float'];
	productPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	productSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	transportPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	transportSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	prepressPriceExcludingVat?: Maybe<Scalars['Float']>;
	prepressSalesPriceExcludingVat?: Maybe<Scalars['Float']>;
	cost: Scalars['Float'];
	comment?: Maybe<Scalars['String']>;
	vatAmount?: Maybe<Scalars['Float']>;
	salePrice?: Maybe<Scalars['Float']>;
	estimatedCiteoTax?: Maybe<Scalars['Float']>;
	extraUnitsCost?: Maybe<Scalars['Float']>;
	nbDeChgtDePlaque?: Maybe<Scalars['Float']>;
	numberFileProcessing: Scalars['Float'];
	nbDeCliche?: Maybe<Scalars['Float']>;
	tools: Scalars['Boolean'];
	productCommentaires?: Maybe<Scalars['String']>;
	totalProductSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	sousReserveDeRepartitionDefinitve?: Maybe<Scalars['Boolean']>;
	montantRFA?: Maybe<Scalars['Float']>;
	nbDePointDeLivraison?: Maybe<Scalars['Float']>;
	productTotalWeight: Scalars['Float'];
	nbShippingPoints: Scalars['Float'];
	prixPrepresseHT?: Maybe<Scalars['Float']>;
	referenceGID?: Maybe<Scalars['String']>;
	prixHT?: Maybe<Scalars['Float']>;
	tauxTVA?: Maybe<Scalars['Float']>;
	montantTVA?: Maybe<Scalars['Float']>;
	prixTTC?: Maybe<Scalars['Float']>;
	montantTaxeCiteo?: Maybe<Scalars['Float']>;
	clientQuoteId?: Maybe<Scalars['String']>;
	transportInclus?: Maybe<Scalars['String']>;
	transportNonInclus?: Maybe<Scalars['String']>;
	prepresseInclus?: Maybe<Scalars['String']>;
	prepresseNonInclus?: Maybe<Scalars['String']>;
	prepresseNonApplicable?: Maybe<Scalars['String']>;
	detailPrepressePDF?: Maybe<Scalars['String']>;
	product: Product;
	sellingPriceMargin: Scalars['Float'];
	rfaClientEuro: Scalars['Float'];
	sellingPriceIncludingTax: Scalars['Float'];
	numberShots: Scalars['Float'];
	transportPurchasePriceHt?: Maybe<Scalars['Float']>;
	transportRfa?: Maybe<Scalars['Float']>;
	transportSellingPriceMargin?: Maybe<Scalars['Float']>;
	transportSellingPriceByDefaultHt?: Maybe<Scalars['Float']>;
	transportPurchasePriceHtWithRfa?: Maybe<Scalars['Float']>;
	transportSellingPriceHtWithClientRfa?: Maybe<Scalars['Float']>;
	transportSellingPriceHtWithClientRfaRectified?: Maybe<Scalars['Float']>;
	transportSellingPriceHt?: Maybe<Scalars['Float']>;
	numberPlateChanges: Scalars['Float'];
	franco: Scalars['Boolean'];
	corsicaDeliveryExtra: Scalars['Float'];
	isMechanizable: Scalars['Boolean'];
	isFinalDistribution: Scalars['Boolean'];
	transporterId?: Maybe<Scalars['String']>;
};

export type ClientQuoteItemInsertInput = {
	transportMoment: TransportMoment;
	transportSellingPriceHtAdditionalMargin: MarginType;
	transportType: TransportType;
	deliveryParcel: BoxShippingMode;
	id?: Maybe<Scalars['String']>;
	quantity: Scalars['Float'];
	productName: Scalars['String'];
	productId: Scalars['String'];
	numberVersion: Scalars['Float'];
	productPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	productSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	transportPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	transportSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	prepressPriceExcludingVat?: Maybe<Scalars['Float']>;
	prepressSalesPriceExcludingVat?: Maybe<Scalars['Float']>;
	cost?: Maybe<Scalars['Float']>;
	comment?: Maybe<Scalars['String']>;
	vatAmount?: Maybe<Scalars['Float']>;
	salePrice?: Maybe<Scalars['Float']>;
	estimatedCiteoTax?: Maybe<Scalars['Float']>;
	tools: Scalars['Boolean'];
	extraUnitsCost?: Maybe<Scalars['Float']>;
	sellingPriceMargin: Scalars['Float'];
	numberShots: Scalars['Float'];
	totalSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	sellingPriceWithMargin: MarginsInput;
	productTotalWeight: Scalars['Float'];
	nbShippingPoints: Scalars['Float'];
	rfaClientEuro: Scalars['Float'];
	totalProductSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
	sellingPriceIncludingTax: Scalars['Float'];
	transportPurchasePriceHt?: Maybe<Scalars['Float']>;
	transportRfa?: Maybe<Scalars['Float']>;
	transportSellingPriceMargin?: Maybe<Scalars['Float']>;
	transportSellingPriceByDefaultHt?: Maybe<Scalars['Float']>;
	transportPurchasePriceHtWithRfa?: Maybe<Scalars['Float']>;
	transportSellingPriceHtWithClientRfa?: Maybe<Scalars['Float']>;
	transportSellingPriceHtWithClientRfaRectified?: Maybe<Scalars['Float']>;
	transportSellingPriceHt?: Maybe<Scalars['Float']>;
	numberPlateChanges: Scalars['Float'];
	numberFileProcessing: Scalars['Float'];
	franco: Scalars['Boolean'];
	corsicaDeliveryExtra: Scalars['Float'];
	isMechanizable: Scalars['Boolean'];
	isFinalDistribution: Scalars['Boolean'];
	transporterId?: Maybe<Scalars['String']>;
};

export enum ClientQuoteItemKey {
	ProductName = 'productName',
	VersionNumber = 'versionNumber',
	Quantity = 'quantity',
	Comment = 'comment',
	ExtraUnitsCost = 'extraUnitsCost',
	NbDeChgtDePlaque = 'nbDeChgtDePlaque',
	NumberFileProcessing = 'numberFileProcessing',
	NbDeCliche = 'nbDeCliche',
	Tools = 'tools',
	Cost = 'cost',
	ProductCommentaires = 'productCommentaires',
	ProductPurchasePriceExcludingVat = 'productPurchasePriceExcludingVAT',
	PrixCalageHt = 'prixCalageHT',
	PrixHorsCalageHt = 'prixHorsCalageHT',
	PrixExSupHt = 'prixExSupHT',
	PrixCentPlusHt = 'prixCentPlusHT',
	PrixMillePlusHt = 'prixMillePlusHT',
	PrixCentMoinsHt = 'prixCentMoinsHT',
	PrixMilleMoinsHt = 'prixMilleMoinsHT',
	PrixChgtParPlaqueHt = 'prixChgtParPlaqueHT',
	PrixTraitementFichierHt = 'prixTraitementFichierHT',
	AutrePrixFixeHt = 'autrePrixFixeHT',
	PrixClicheHt = 'prixClicheHT',
	PrixOutillageHt = 'prixOutillageHT',
	SousReserveDeRepartitionDefinitve = 'sousReserveDeRepartitionDefinitve',
	TransportSalesPriceExcludingVat = 'transportSalesPriceExcludingVAT',
	TransportInclus = 'transportInclus',
	TransportNonInclus = 'transportNonInclus',
	MontantRfa = 'montantRFA',
	NbDePointDeLivraison = 'nbDePointDeLivraison',
	Weight = 'weight',
	PrixPrepresseHt = 'prixPrepresseHT',
	ReferenceGid = 'referenceGID',
	DetailPrepressePdf = 'detailPrepressePDF',
	PrepresseNonInclus = 'prepresseNonInclus',
	PrepresseInclus = 'prepresseInclus',
	PrepresseNonApplicable = 'prepresseNonApplicable',
	PrixHt = 'prixHT',
	TauxTva = 'tauxTVA',
	MontantTva = 'montantTVA',
	PrixTtc = 'prixTTC',
	MontantTaxeCiteo = 'montantTaxeCiteo',
}

export enum ClientQuoteKey {
	Cost = 'cost',
	VatRate = 'vatRate',
	TotalSalesPriceVat = 'totalSalesPriceVAT',
	VatAmount = 'vatAmount',
	AdditionalCostsIncurred = 'additionalCostsIncurred',
	EstimatedCiteoTax = 'estimatedCiteoTax',
}

export type ClientQuoteSortInput = {
	id?: Maybe<SortOrder>;
	fileReferenceId?: Maybe<SortOrder>;
	status?: Maybe<SortOrder>;
	client?: Maybe<ClientSortOrder>;
	createdAt?: Maybe<SortOrder>;
	askedBy?: Maybe<SortOrder>;
	operationName?: Maybe<SortOrder>;
	quoteRequestedBy?: Maybe<SortOrder>;
	totalSalesPriceExcludingVAT?: Maybe<SortOrder>;
};

export enum ClientQuoteTranslate {
	French = 'French',
}

export type ClientQuoteUpsertInput = {
	status: ClientQuoteStatus;
	vat: ClientQuoteVat;
	type: QuoteType;
	id?: Maybe<Scalars['String']>;
	version: Scalars['Float'];
	clientId: Scalars['String'];
	clientName: Scalars['String'];
	contact?: Maybe<Scalars['String']>;
	fileDeliveryDate?: Maybe<Scalars['DateTime']>;
	deliveryDate?: Maybe<Scalars['DateTime']>;
	quoteRequestedBy?: Maybe<Scalars['String']>;
	operationName?: Maybe<Scalars['String']>;
	clientReference?: Maybe<Scalars['String']>;
	clientQuoteItem?: Maybe<Array<ClientQuoteItemInsertInput>>;
	estimatedCiteoTax: Scalars['Float'];
	totalPurchasePriceExcludingVAT: Scalars['Float'];
	totalSalesPriceExcludingVAT: Scalars['Float'];
	vatAmount: Scalars['Float'];
	totalSalesPriceVAT: Scalars['Float'];
	margin: Scalars['Float'];
	tools?: Maybe<Scalars['String']>;
	extraUnitsCost?: Maybe<Scalars['Float']>;
	marginRate: Scalars['Float'];
	vatRate: Scalars['Float'];
	billingAddress?: Maybe<ClientQuoteBillingAddressInput>;
	fileReferenceId?: Maybe<Scalars['String']>;
	comment?: Maybe<Scalars['String']>;
	clientQuoteItemIdsToDelete?: Maybe<Array<Scalars['String']>>;
	nbShippingPoints: Scalars['Float'];
	isDistribution: Scalars['Boolean'];
	clientQuoteDistributions?: Maybe<Array<ClientQuoteDistributionInput>>;
};

export enum ClientQuoteVat {
	Francaise = 'Francaise',
}

export type ClientQuoteWithPagination = {
	__typename?: 'ClientQuoteWithPagination';
	clientQuotes: Array<ClientQuote>;
	total?: Maybe<Scalars['Float']>;
};

export type ClientRegion = {
	__typename?: 'ClientRegion';
	id: Scalars['String'];
	name: Scalars['String'];
};

export type ClientRegionInput = {
	id?: Maybe<Scalars['String']>;
	name: Scalars['String'];
};

export type ClientShippingAddress = {
	__typename?: 'ClientShippingAddress';
	contact?: Maybe<ClientShippingAddressContact>;
	boxShippingMode?: Maybe<BoxShippingMode>;
	palletShippingMode?: Maybe<PalletShippingMode>;
	id: Scalars['String'];
	clientId: Scalars['String'];
	addressId?: Maybe<Scalars['String']>;
	address?: Maybe<Address>;
	isMain: Scalars['Boolean'];
	workingDays?: Maybe<Array<WorkingDay>>;
	maxBoxWeight?: Maybe<Scalars['Float']>;
	comment?: Maybe<Scalars['String']>;
	referenceId?: Maybe<Scalars['String']>;
};

export type ClientShippingAddressByCompanyBase = {
	__typename?: 'ClientShippingAddressByCompanyBase';
	type: EntityType;
	billingCadence?: Maybe<BillingCadence>;
	possibleBillings: Array<EntityType>;
	defaultBilling?: Maybe<EntityType>;
	translation?: Maybe<ClientQuoteTranslate>;
	elpevSite?: Maybe<ElpevSite>;
	fileCode?: Maybe<Scalars['String']>;
	territorialArea?: Maybe<TerritorialArea>;
	id: Scalars['String'];
	name: Scalars['String'];
	createdAt: Scalars['DateTime'];
	updatedAt: Scalars['DateTime'];
	isProspect: Scalars['Boolean'];
	isActif: Scalars['Boolean'];
	companyBase?: Maybe<Scalars['String']>;
	tradeNameInvoice?: Maybe<Scalars['String']>;
	transplantName?: Maybe<Scalars['String']>;
	siretNumber?: Maybe<Scalars['String']>;
	vatNumber?: Maybe<Scalars['String']>;
	rcsNumber?: Maybe<Scalars['String']>;
	vatType?: Maybe<Scalars['Boolean']>;
	parentClientId?: Maybe<Scalars['String']>;
	rootClientId?: Maybe<Scalars['String']>;
	isRoot: Scalars['Boolean'];
	regionId?: Maybe<Scalars['String']>;
	typologyId?: Maybe<Scalars['String']>;
	commercialId?: Maybe<Scalars['String']>;
	responsibleId?: Maybe<Scalars['String']>;
	users?: Maybe<UserDetails>;
	paymentDeadline?: Maybe<Scalars['String']>;
	projectManagerId?: Maybe<Scalars['String']>;
	billingAddress?: Maybe<Address>;
	structures: Array<ClientStructure>;
	typologies?: Maybe<Array<ClientTypology>>;
	regions?: Maybe<Array<ClientRegion>>;
	isGroupedBilling?: Maybe<Scalars['Boolean']>;
	isHorsRFA?: Maybe<Scalars['Boolean']>;
	mainRfaRate?: Maybe<Scalars['Float']>;
	rfaComment?: Maybe<Scalars['String']>;
	defaultCommercialProductMargin?: Maybe<Scalars['Float']>;
	defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
	rootClient?: Maybe<Client>;
	clientContact?: Maybe<Array<Contact>>;
	logo: Scalars['String'];
	clientsSupports: Array<ClientSupport>;
	clientShippingAddress: Array<ClientShippingAddress>;
	clientPrices?: Maybe<Array<ClientPrice>>;
	possibleBillingsClients?: Maybe<Array<Client>>;
	clientAccountingAccount?: Maybe<Scalars['String']>;
	clientAnalyticCode?: Maybe<Scalars['String']>;
	groupingCode?: Maybe<Scalars['String']>;
	clientCode?: Maybe<Scalars['String']>;
	structureTitle?: Maybe<Scalars['String']>;
	level?: Maybe<Scalars['Float']>;
	isBillable?: Maybe<Scalars['Boolean']>;
	ancestors?: Maybe<Array<Scalars['String']>>;
	email?: Maybe<Scalars['String']>;
};

export type ClientShippingAddressContact = {
	__typename?: 'ClientShippingAddressContact';
	fax?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	website?: Maybe<Scalars['String']>;
	storeCode?: Maybe<Scalars['String']>;
	directorName?: Maybe<Scalars['String']>;
	isNotificated?: Maybe<Scalars['Boolean']>;
};

export type ClientShippingAddressContactInput = {
	fax?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	website?: Maybe<Scalars['String']>;
	storeCode?: Maybe<Scalars['String']>;
	directorName?: Maybe<Scalars['String']>;
	isNotificated?: Maybe<Scalars['Boolean']>;
};

export type ClientShippingAddressInsertInput = {
	contact?: Maybe<ClientShippingAddressContactInput>;
	boxShippingMode?: Maybe<BoxShippingMode>;
	palletShippingMode?: Maybe<PalletShippingMode>;
	id?: Maybe<Scalars['String']>;
	clientId: Scalars['String'];
	addressId?: Maybe<Scalars['String']>;
	address: AddressInput;
	isMain: Scalars['Boolean'];
	workingDays?: Maybe<Array<WorkingDayInput>>;
	maxBoxWeight?: Maybe<Scalars['Float']>;
	comment?: Maybe<Scalars['String']>;
	referenceId?: Maybe<Scalars['String']>;
};

export type ClientShippingAddressUpdateInput = {
	contact?: Maybe<ClientShippingAddressContactInput>;
	boxShippingMode?: Maybe<BoxShippingMode>;
	palletShippingMode?: Maybe<PalletShippingMode>;
	id: Scalars['String'];
	clientId: Scalars['String'];
	addressId?: Maybe<Scalars['String']>;
	address?: Maybe<AddressInput>;
	isMain: Scalars['Boolean'];
	workingDays?: Maybe<Array<WorkingDayInput>>;
	maxBoxWeight?: Maybe<Scalars['Float']>;
	comment?: Maybe<Scalars['String']>;
	referenceId?: Maybe<Scalars['String']>;
};

export type ClientSortOrder = {
	name?: Maybe<SortOrder>;
};

export type ClientStructure = {
	__typename?: 'ClientStructure';
	type: EntityType;
	title: Scalars['String'];
	level: Scalars['Float'];
	isBillable: Scalars['Boolean'];
};

export type ClientStructureInput = {
	type: EntityType;
	title: Scalars['String'];
	level: Scalars['Float'];
	isBillable: Scalars['Boolean'];
};

export type ClientSupport = {
	__typename?: 'ClientSupport';
	marginType: MarginType;
	supportId: Scalars['String'];
	margin: Scalars['Float'];
	support: Support;
};

export type ClientSupportInput = {
	marginType: MarginType;
	supportId: Scalars['String'];
	margin: Scalars['Float'];
};

export type ClientTypology = {
	__typename?: 'ClientTypology';
	id: Scalars['String'];
	name: Scalars['String'];
};

export type ClientTypologyInput = {
	id?: Maybe<Scalars['String']>;
	name: Scalars['String'];
};

export type ClientUpdateInput = {
	type?: Maybe<EntityType>;
	billingCadence?: Maybe<BillingCadence>;
	possibleBillings?: Maybe<Array<EntityType>>;
	defaultBilling?: Maybe<EntityType>;
	territorialArea?: Maybe<TerritorialArea>;
	id: Scalars['String'];
	name: Scalars['String'];
	salesperson?: Maybe<Scalars['String']>;
	isProspect: Scalars['Boolean'];
	isActif: Scalars['Boolean'];
	companyBase: Scalars['String'];
	tradeNameInvoice?: Maybe<Scalars['String']>;
	transplantName?: Maybe<Scalars['String']>;
	siretNumber?: Maybe<Scalars['String']>;
	vatNumber?: Maybe<Scalars['String']>;
	rcsNumber?: Maybe<Scalars['String']>;
	vatType?: Maybe<Scalars['Boolean']>;
	parentClientId?: Maybe<Scalars['String']>;
	rootClientId?: Maybe<Scalars['String']>;
	regionId?: Maybe<Scalars['String']>;
	typologyId?: Maybe<Scalars['String']>;
	paymentDeadline?: Maybe<Scalars['String']>;
	commercialId?: Maybe<Scalars['String']>;
	responsibleId?: Maybe<Scalars['String']>;
	projectManagerId?: Maybe<Scalars['String']>;
	isRoot: Scalars['Boolean'];
	billingAddress?: Maybe<AddressInput>;
	structures?: Maybe<Array<ClientStructureInput>>;
	typologies?: Maybe<Array<ClientTypologyInput>>;
	regions?: Maybe<Array<ClientRegionInput>>;
	isGroupedBilling?: Maybe<Scalars['Boolean']>;
	isHorsRFA?: Maybe<Scalars['Boolean']>;
	mainRfaRate?: Maybe<Scalars['Float']>;
	rfaComment?: Maybe<Scalars['String']>;
	defaultCommercialProductMargin?: Maybe<Scalars['Float']>;
	defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
	clientContact?: Maybe<Array<ContactInput>>;
	clientsSupports?: Maybe<Array<ClientSupportInput>>;
	clientShippingAddress?: Maybe<Array<ClientShippingAddressUpdateInput>>;
	clientAccountingAccount?: Maybe<Scalars['String']>;
	clientAnalyticCode?: Maybe<Scalars['String']>;
	groupingCode?: Maybe<Scalars['String']>;
	clientCode?: Maybe<Scalars['String']>;
	fileCode?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
};

export type CockpitOrderUpdateInput = {
	followStatus: FollowOrderStatus;
	id: Scalars['String'];
	onepacUserId?: Maybe<Scalars['String']>;
	onepacUserName?: Maybe<Scalars['String']>;
	statusComment?: Maybe<Scalars['String']>;
};

export type CockpitOrderUpsertInput = {
	status?: Maybe<OrderStatus>;
	followStatus?: Maybe<FollowOrderStatus>;
	paymentType?: Maybe<OrderPaymentType>;
	billingAddress?: Maybe<ClientQuoteBillingAddressInput>;
	id?: Maybe<Scalars['String']>;
	orderItem: Array<OrderItemCockpitUpsertInput>;
	childrenOrder?: Maybe<Array<CockpitOrderUpsertInput>>;
	clientName: Scalars['String'];
	invoices?: Maybe<Array<InvoiceUpsertInput>>;
	clientId: Scalars['String'];
	operationName?: Maybe<Scalars['String']>;
	clientReference?: Maybe<Scalars['String']>;
	marginWithClientRfa?: Maybe<Scalars['Float']>;
	realMargin?: Maybe<Scalars['Float']>;
	marginRate?: Maybe<Scalars['Float']>;
	comment?: Maybe<Scalars['String']>;
	priceSupplyClientId?: Maybe<Scalars['String']>;
};

export type CommercialInitials = {
	__typename?: 'CommercialInitials';
	initials?: Maybe<Scalars['String']>;
	fullName?: Maybe<Scalars['String']>;
};

export type Conditionnement = {
	__typename?: 'Conditionnement';
	conditionnement: Scalars['String'];
	nbExConditionnement?: Maybe<Scalars['Float']>;
	labeling: Scalars['Boolean'];
};

export type ConditionnementInput = {
	conditionnement: Scalars['String'];
	nbExConditionnement?: Maybe<Scalars['Float']>;
	labeling: Scalars['Boolean'];
};

export type Contact = {
	__typename?: 'Contact';
	id: Scalars['String'];
	civility?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	functions?: Maybe<Scalars['String']>;
	landlinePhone?: Maybe<Scalars['String']>;
	gsmPhone?: Maybe<Scalars['String']>;
	privatePhone?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	comment?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	inactif?: Maybe<Scalars['Boolean']>;
	linkedIn?: Maybe<Scalars['String']>;
};

export type ContactInput = {
	id?: Maybe<Scalars['String']>;
	civility?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	functions?: Maybe<Scalars['String']>;
	landlinePhone?: Maybe<Scalars['String']>;
	gsmPhone?: Maybe<Scalars['String']>;
	privatePhone?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	comment?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	inactif?: Maybe<Scalars['Boolean']>;
	linkedIn?: Maybe<Scalars['String']>;
};

export type ControlReceptionDetails = {
	__typename?: 'ControlReceptionDetails';
	shop: Scalars['String'];
	version?: Maybe<Scalars['String']>;
	quantity?: Maybe<Scalars['Float']>;
	conformity?: Maybe<Scalars['String']>;
	receptionDone?: Maybe<Scalars['Boolean']>;
};

export type DateTimeFilter = {
	equals?: Maybe<Scalars['DateTime']>;
	lte?: Maybe<Scalars['DateTime']>;
	gte?: Maybe<Scalars['DateTime']>;
};

export type DeferredOrderItem = {
	__typename?: 'DeferredOrderItem';
	orderItemId: Scalars['String'];
	status: StatusDigital;
	isSuccess: Scalars['Boolean'];
	message: MessageBroadcastDefer;
};

export type DeferredOrderItemInput = {
	orderItem: Array<OrderDeferredItemCockpitUpsert>;
};

export type Department = {
	__typename?: 'Department';
	code: Scalars['String'];
	name: Scalars['String'];
};

export type DepartmentInput = {
	code?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};

export type DiscountInput = {
	ca?: Maybe<Scalars['Float']>;
	rfa?: Maybe<Scalars['Float']>;
};

export type DiscountObjectType = {
	__typename?: 'DiscountObjectType';
	ca?: Maybe<Scalars['Float']>;
	rfa?: Maybe<Scalars['Float']>;
};

export type Distribution = {
	__typename?: 'Distribution';
	zip?: Maybe<Scalars['String']>;
	quantity?: Maybe<Scalars['Float']>;
};

export type DistributionInput = {
	zip?: Maybe<Scalars['String']>;
	quantity?: Maybe<Scalars['Float']>;
};

export enum ElpevSite {
	Empty = 'Empty',
	Lille = 'Lille',
	Paris = 'Paris',
	Wittelsheim = 'Wittelsheim',
}

export enum EntityType {
	Group = 'Group',
	Central = 'Central',
	Brand = 'Brand',
	Typology = 'Typology',
	Region = 'Region',
	Cooperative = 'Cooperative',
	Store = 'Store',
	SubGroup1 = 'SubGroup1',
	SubGroup2 = 'SubGroup2',
	SubGroup3 = 'SubGroup3',
	SubGroup4 = 'SubGroup4',
}

export type EntityTypeFilter = {
	equals?: Maybe<EntityType>;
	not?: Maybe<EntityType>;
	in?: Maybe<Array<EntityType>>;
	notIn?: Maybe<Array<EntityType>>;
};

export type EscompteInput = {
	escompte?: Maybe<Scalars['Float']>;
	client?: Maybe<Scalars['String']>;
	dayLimitTime?: Maybe<Scalars['Float']>;
};

export type EscompteObjectType = {
	__typename?: 'EscompteObjectType';
	escompte?: Maybe<Scalars['Float']>;
	client?: Maybe<Scalars['String']>;
	dayLimitTime?: Maybe<Scalars['Float']>;
};

export type ExportQuoteFile = {
	__typename?: 'ExportQuoteFile';
	url: Scalars['String'];
	filename: Scalars['String'];
};

export type FabricationTime = {
	__typename?: 'FabricationTime';
	quantity?: Maybe<Scalars['Float']>;
	standardMode?: Maybe<Scalars['Float']>;
	expressMode?: Maybe<Scalars['Float']>;
	urgentMode?: Maybe<Scalars['Float']>;
};

export type FabricationTimeBySupport = {
	__typename?: 'FabricationTimeBySupport';
	standardMode?: Maybe<Scalars['Float']>;
	expressMode?: Maybe<Scalars['Float']>;
};

export type FabricationTimeBySupportInput = {
	supportId: Scalars['String'];
	quantity: Scalars['Float'];
};

export type FabricationTimeInput = {
	quantity?: Maybe<Scalars['Float']>;
	standardMode?: Maybe<Scalars['Float']>;
	expressMode?: Maybe<Scalars['Float']>;
	urgentMode?: Maybe<Scalars['Float']>;
};

export type FaconnageDetails = {
	key: Scalars['String'];
	value: Scalars['String'];
};

export type FamilyAttributes = {
	__typename?: 'FamilyAttributes';
	family: Scalars['String'];
	characteristics: Array<CharacteristicsQuoteAttribute>;
};

export type FileReference = {
	__typename?: 'FileReference';
	status: FileReferenceStatus;
	source: Source;
	id: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	operationName: Scalars['String'];
	askBy?: Maybe<Scalars['String']>;
	clientReference?: Maybe<Scalars['String']>;
	elpevResponsible: User;
	elpevResponsibleId: Scalars['String'];
	client: Client;
};

export type FileReferenceFilterInput = {
	source?: Maybe<Source>;
	id?: Maybe<Scalars['String']>;
	clientId?: Maybe<Scalars['String']>;
	operationName?: Maybe<Scalars['String']>;
};

export type FileReferenceFiltersInput = {
	id: StringFilter;
	askBy: StringFilter;
	operationName: StringFilter;
	clientReference: StringFilter;
	elpevResponsible: UserSearchInput;
};

export type FileReferenceInsertInput = {
	source?: Maybe<Source>;
	clientId: Scalars['String'];
	operationName: Scalars['String'];
	askBy?: Maybe<Scalars['String']>;
	elpevResponsibleId: Scalars['String'];
	clientReference?: Maybe<Scalars['String']>;
};

export type FileReferenceOrderFilter = {
	id?: Maybe<StringFilter>;
	askBy?: Maybe<StringFilter>;
	operationName?: Maybe<StringFilter>;
	clientReference?: Maybe<StringFilter>;
};

export type FileReferenceSearchInput = {
	source?: Maybe<Source>;
	id?: Maybe<Scalars['String']>;
	clientName?: Maybe<Scalars['String']>;
	operationName?: Maybe<Scalars['String']>;
	askBy?: Maybe<Scalars['String']>;
	userName?: Maybe<Scalars['String']>;
	clientReference?: Maybe<Scalars['String']>;
};

export type FileReferenceSortInput = {
	id?: Maybe<SortOrder>;
	clientName?: Maybe<SortOrder>;
	source?: Maybe<SortOrder>;
	askBy?: Maybe<SortOrder>;
	status?: Maybe<SortOrder>;
	clientReference?: Maybe<SortOrder>;
	operationName?: Maybe<SortOrder>;
	clientId?: Maybe<SortOrder>;
	createdAt?: Maybe<SortOrder>;
};

export enum FileReferenceStatus {
	InStudy = 'InStudy',
	InProgress = 'InProgress',
	InProduction = 'InProduction',
	Delivered = 'Delivered',
	Canceled = 'Canceled',
}

export type FileReferenceWithPaginationModel = {
	__typename?: 'FileReferenceWithPaginationModel';
	pagination?: Maybe<Pagination>;
	fileReferences: Array<FileReference>;
};

export type FiltredClientPriceInput = {
	status?: Maybe<ClientPriceStatus>;
	clientId: Scalars['String'];
	productId?: Maybe<Scalars['String']>;
	productName?: Maybe<Scalars['String']>;
	quantity?: Maybe<Scalars['Float']>;
	productAttributes?: Maybe<AttributesFilterInput>;
};

export enum FollowOrderStatus {
	WaitingValidation = 'WaitingValidation',
	Validated = 'Validated',
	Refused = 'Refused',
	ProductionInProgress = 'ProductionInProgress',
	DeliveryInProgress = 'DeliveryInProgress',
	Delivered = 'Delivered',
	Billed = 'Billed',
}

export type HardlyAccessibleAreasTransporter = {
	__typename?: 'HardlyAccessibleAreasTransporter';
	postalCode?: Maybe<Scalars['String']>;
};

export type HardlyAccessibleAreasTransporterInput = {
	postalCode?: Maybe<Scalars['String']>;
};

export type Invoice = {
	__typename?: 'Invoice';
	status?: Maybe<InvoiceStatus>;
	id: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	validateAt?: Maybe<Scalars['DateTime']>;
	sendAt?: Maybe<Scalars['DateTime']>;
	url: Scalars['String'];
	order: Order;
	orderId?: Maybe<Scalars['String']>;
	validator?: Maybe<Scalars['String']>;
	isExternal: Scalars['Boolean'];
	originalInvoiceName?: Maybe<Scalars['String']>;
};

export type InvoiceFilterInput = {
	status?: Maybe<InvoiceStatus>;
};

export type InvoiceOrderItemInput = {
	id?: Maybe<Scalars['String']>;
	realProductMargin?: Maybe<Scalars['Float']>;
	realTransportMargin?: Maybe<Scalars['Float']>;
	isWithRfaProduct?: Maybe<Scalars['Boolean']>;
	isWithRfaTransport?: Maybe<Scalars['Boolean']>;
};

export type InvoiceSearchInput = {
	createdAt?: Maybe<DateTimeFilter>;
	order?: Maybe<OrderSearchInput>;
	id?: Maybe<Scalars['String']>;
	validateAt?: Maybe<Scalars['String']>;
	sendAt?: Maybe<Scalars['String']>;
	clientName?: Maybe<Scalars['String']>;
	orderId?: Maybe<Scalars['String']>;
	validator?: Maybe<Scalars['String']>;
	isExternal?: Maybe<Scalars['Boolean']>;
	billingEntity?: Maybe<Scalars['String']>;
};

export type InvoiceSortInput = {
	id?: Maybe<SortOrder>;
	createdAt?: Maybe<SortOrder>;
	sendAt?: Maybe<SortOrder>;
	validateAt?: Maybe<SortOrder>;
	orderId?: Maybe<SortOrder>;
	clientName?: Maybe<SortOrder>;
	validator?: Maybe<SortOrder>;
	askBy?: Maybe<SortOrder>;
	operationName?: Maybe<SortOrder>;
	clientReference?: Maybe<SortOrder>;
	elpevResponsible?: Maybe<SortOrder>;
	billingEntity?: Maybe<SortOrder>;
	sellingPriceHt?: Maybe<SortOrder>;
	order?: Maybe<OrderSortInput>;
};

export enum InvoiceStatus {
	ToCount = 'ToCount',
	Counted = 'Counted',
	ToValidate = 'ToValidate',
	Blank = 'Blank',
}

export type InvoiceUpsertInput = {
	status?: Maybe<InvoiceStatus>;
	id?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	validateAt?: Maybe<Scalars['DateTime']>;
	sendAt?: Maybe<Scalars['DateTime']>;
	order?: Maybe<CockpitOrderUpsertInput>;
	orderId?: Maybe<Scalars['String']>;
	validator?: Maybe<Scalars['String']>;
	isExternal: Scalars['Boolean'];
	originalInvoiceName?: Maybe<Scalars['String']>;
};

export type InvoiceValidationInput = {
	id?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	orderItems?: Maybe<Array<InvoiceOrderItemInput>>;
};

export type InvoiceWithPagination = {
	__typename?: 'InvoiceWithPagination';
	invoices: Array<Invoice>;
	total?: Maybe<Scalars['Float']>;
};

export type InvoicesToUpdateInput = {
	ids: Array<Scalars['String']>;
};

export type LoginInput = {
	projectName: ProjectName;
	email: Scalars['String'];
	password: Scalars['String'];
};

export enum MarginType {
	Euro = 'euro',
	Percent = 'percent',
}

export type Margins = {
	__typename?: 'Margins';
	prixCalage: Scalars['Float'];
	prixHorsCalage: Scalars['Float'];
	prixExemplaireSupp: Scalars['Float'];
	prixChangementPlaque: Scalars['Float'];
	prixTraitementFichier: Scalars['Float'];
	autrePrixFixe: Scalars['Float'];
	prixCliche: Scalars['Float'];
	prixOutillage: Scalars['Float'];
};

export type MarginsInput = {
	prixCalage: Scalars['Float'];
	prixHorsCalage: Scalars['Float'];
	prixExemplaireSupp: Scalars['Float'];
	prixChangementPlaque: Scalars['Float'];
	prixTraitementFichier: Scalars['Float'];
	autrePrixFixe: Scalars['Float'];
	prixCliche: Scalars['Float'];
	prixOutillage: Scalars['Float'];
};

export enum MessageBroadcastDefer {
	Published = 'PUBLISHED',
	NotPublished = 'NOT_PUBLISHED',
	Failed = 'FAILED',
}

export type MultipleClientPricesInput = {
	clientPrices: Array<UniqClientPriceInput>;
};

export type Mutation = {
	__typename?: 'Mutation';
	login: Auth;
	createUser: User;
	updateUser: User;
	createRole: Role;
	updateRole: Role;
	insertSupport: Support;
	updateSupportAttributes: Support;
	importProductsSellingPrice: Array<Product>;
	getOrCreateProduct: Product;
	importProductsPurchasePrice: Scalars['Float'];
	insertSupplier: Supplier;
	updateSupplier: Supplier;
	updateSupplierByCompanyBase: Supplier;
	importClients: Array<Client>;
	updateClientByCompanyBase: Client;
	insertClient: Client;
	insertClientShippingAddress: ClientShippingAddress;
	deleteShippingAddressById: ClientShippingAddress;
	updateClient: Client;
	createClientLogoUploadUrl: Scalars['String'];
	generateOrderPdf: OrderPdfInvoiceStatus;
	createOrderInvoiceUploadUrl: Scalars['String'];
	upsertCockpitOrder: Order;
	deferredPublicationOrderItem: Array<DeferredOrderItem>;
	updateCockpitOrder: Order;
	upsertOrder: Order;
	updateTransporters: Array<Transporter>;
	insertTransporter: Transporter;
	upsertClientPrice: ClientPrice;
	upsertClariprintClientPrice: ClientPrice;
	upsertBigShopConfig: BigShopConfig;
	insertPrintValues: Array<PrintValue>;
	generateQuoteFile: ExportQuoteFile;
	upsertClientQuote: ClientQuote;
	createClientQuoteConfig: ClientQuoteConfigAttributesCreateModel;
	updateOneOrManyInvoices: Array<Scalars['String']>;
	upsertInvoice: Invoice;
	validateInvoice: Invoice;
	insertFileReference: FileReference;
	generateFileUrl: ResponsableUrl;
};

export type MutationLoginArgs = {
	data: LoginInput;
};

export type MutationCreateUserArgs = {
	data: UserCreateInput;
};

export type MutationUpdateUserArgs = {
	userId: Scalars['String'];
	data: UserUpdateInput;
};

export type MutationCreateRoleArgs = {
	data: RoleInput;
};

export type MutationUpdateRoleArgs = {
	roleId: Scalars['String'];
	data: RoleInput;
};

export type MutationInsertSupportArgs = {
	name: Scalars['String'];
};

export type MutationUpdateSupportAttributesArgs = {
	data: SupportUpdateInput;
};

export type MutationImportProductsSellingPriceArgs = {
	file: Scalars['Upload'];
};

export type MutationGetOrCreateProductArgs = {
	product: ProductCreateInput;
};

export type MutationImportProductsPurchasePriceArgs = {
	file: Scalars['Upload'];
};

export type MutationInsertSupplierArgs = {
	name: Scalars['String'];
};

export type MutationUpdateSupplierArgs = {
	supplierId: Scalars['String'];
	data: SupplierUpdateInput;
};

export type MutationUpdateSupplierByCompanyBaseArgs = {
	supplierId: Scalars['String'];
	companyBase: Scalars['String'];
};

export type MutationImportClientsArgs = {
	rootClientName: Scalars['String'];
	file: Scalars['Upload'];
};

export type MutationUpdateClientByCompanyBaseArgs = {
	clientId: Scalars['String'];
	companyBase: Scalars['String'];
};

export type MutationInsertClientArgs = {
	client: ClientInsertInput;
};

export type MutationInsertClientShippingAddressArgs = {
	clientShippingAddress: ClientShippingAddressInsertInput;
};

export type MutationDeleteShippingAddressByIdArgs = {
	addressId: Scalars['String'];
	clientShippingAddressId: Scalars['String'];
};

export type MutationUpdateClientArgs = {
	client: ClientUpdateInput;
};

export type MutationCreateClientLogoUploadUrlArgs = {
	mimetype: Scalars['String'];
	id: Scalars['String'];
};

export type MutationGenerateOrderPdfArgs = {
	invoiceId?: Maybe<Scalars['String']>;
	orderId: Scalars['String'];
};

export type MutationCreateOrderInvoiceUploadUrlArgs = {
	mimetype: Scalars['String'];
	id: Scalars['String'];
};

export type MutationUpsertCockpitOrderArgs = {
	order: CockpitOrderUpsertInput;
};

export type MutationDeferredPublicationOrderItemArgs = {
	data: DeferredOrderItemInput;
};

export type MutationUpdateCockpitOrderArgs = {
	payload: CockpitOrderUpdateInput;
};

export type MutationUpsertOrderArgs = {
	order: UpsertOrderInput;
};

export type MutationUpdateTransportersArgs = {
	Transporters: Array<TransporterUpdateInput>;
};

export type MutationInsertTransporterArgs = {
	name: Scalars['String'];
};

export type MutationUpsertClientPriceArgs = {
	clientPrice: ClientPriceInput;
};

export type MutationUpsertClariprintClientPriceArgs = {
	filter: ProductClientPriceCreateInput;
};

export type MutationUpsertBigShopConfigArgs = {
	data: BigShopConfigUpsertInput;
};

export type MutationInsertPrintValuesArgs = {
	prints: Array<PrintValueInput>;
};

export type MutationGenerateQuoteFileArgs = {
	filter: ClientQuoteGenerateFileInput;
};

export type MutationUpsertClientQuoteArgs = {
	data: ClientQuoteUpsertInput;
};

export type MutationCreateClientQuoteConfigArgs = {
	clientQuoteConfig: ClientQuoteConfigAttributesCreateInput;
};

export type MutationUpdateOneOrManyInvoicesArgs = {
	invoiceIds: InvoicesToUpdateInput;
};

export type MutationUpsertInvoiceArgs = {
	invoice: InvoiceUpsertInput;
};

export type MutationValidateInvoiceArgs = {
	invoice: InvoiceValidationInput;
};

export type MutationInsertFileReferenceArgs = {
	fileReference: FileReferenceInsertInput;
};

export type MutationGenerateFileUrlArgs = {
	templateName: TemplateName;
};

export type Order = {
	__typename?: 'Order';
	status?: Maybe<OrderStatus>;
	followStatus: FollowOrderStatus;
	source?: Maybe<OrderSource>;
	paymentType?: Maybe<OrderPaymentType>;
	id: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	comment?: Maybe<Scalars['String']>;
	client?: Maybe<OrderClient>;
	orderItem: Array<OrderItem>;
	childrenOrder?: Maybe<Array<Order>>;
	clientName: Scalars['String'];
	parentOrderId?: Maybe<Scalars['String']>;
	billingAddress: OrderAddress;
	invoices?: Maybe<Array<Invoice>>;
	buyingPriceHt: Scalars['Float'];
	sellingPriceHt: Scalars['Float'];
	transportBuyingPriceHt?: Maybe<Scalars['Float']>;
	transportSellingPriceHt?: Maybe<Scalars['Float']>;
	prepressPriceHt?: Maybe<Scalars['Float']>;
	prepressSellingPriceHt?: Maybe<Scalars['Float']>;
	productSellingPriceHt?: Maybe<Scalars['Float']>;
	tvaRate?: Maybe<Scalars['Float']>;
	tva?: Maybe<Scalars['Float']>;
	sellingPriceTtc?: Maybe<Scalars['Float']>;
	buyingPriceHtWithRfa?: Maybe<Scalars['Float']>;
	marginWithClientRfa?: Maybe<Scalars['Float']>;
	realMargin?: Maybe<Scalars['Float']>;
	marginRate?: Maybe<Scalars['Float']>;
	elepvContactName?: Maybe<Scalars['String']>;
	elpevContactEmail?: Maybe<Scalars['String']>;
	elpevContactPhone?: Maybe<Scalars['String']>;
	version?: Maybe<Scalars['Float']>;
	clientId: Scalars['String'];
	billingEntity?: Maybe<Scalars['String']>;
	versionsNumber?: Maybe<Scalars['Float']>;
	fileReference?: Maybe<FileReference>;
	isDistribution: Scalars['Boolean'];
	orderDistributions?: Maybe<Array<OrderDistributions>>;
	clientQuote?: Maybe<ClientQuote>;
	onepacUserId?: Maybe<Scalars['String']>;
	onepacUserName?: Maybe<Scalars['String']>;
	statusComment?: Maybe<Scalars['String']>;
	statusUpdatedAt?: Maybe<Scalars['DateTime']>;
	priceSupplyClientId?: Maybe<Scalars['String']>;
};

export type OrderAddress = {
	__typename?: 'OrderAddress';
	name: Scalars['String'];
	firstAddress?: Maybe<Scalars['String']>;
	secondAddress?: Maybe<Scalars['String']>;
	thirdAddress?: Maybe<Scalars['String']>;
	postBox?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	deliveryDepartment?: Maybe<Scalars['String']>;
};

export type OrderClient = {
	__typename?: 'OrderClient';
	id: Scalars['String'];
	name: Scalars['String'];
};

export type OrderControlReception = {
	__typename?: 'OrderControlReception';
	articleId: Scalars['String'];
	name?: Maybe<Scalars['String']>;
	receptionDetails?: Maybe<Array<ControlReceptionDetails>>;
};

export type OrderDeferredItemCockpitUpsert = {
	orderItemId: Scalars['String'];
	expectedDeliveryDate: Scalars['DateTime'];
	clientNetworkPageId: Scalars['String'];
	mediumType: Scalars['String'];
	publishDescription: Scalars['String'];
};

export type OrderDeliveries = {
	__typename?: 'OrderDeliveries';
	deliveryPoints?: Maybe<Array<OrderDeliveryPoints>>;
	result?: Maybe<Scalars['Float']>;
	error?: Maybe<Scalars['Float']>;
	nbPackages?: Maybe<Scalars['Float']>;
};

export type OrderDeliveryControls = {
	__typename?: 'OrderDeliveryControls';
	receptions?: Maybe<Array<OrderControlReception>>;
	contactName?: Maybe<Scalars['String']>;
	contactPhone?: Maybe<Scalars['String']>;
	contactEmail?: Maybe<Scalars['String']>;
	eventClosed?: Maybe<Scalars['Boolean']>;
	eventComment?: Maybe<Scalars['String']>;
	eventHistoryHtml?: Maybe<Scalars['String']>;
	answerDate?: Maybe<Scalars['String']>;
};

export type OrderDeliveryPackages = {
	__typename?: 'OrderDeliveryPackages';
	id: Scalars['String'];
	code?: Maybe<Scalars['String']>;
	carrier?: Maybe<Scalars['String']>;
	numberOfPackages?: Maybe<Scalars['Float']>;
	weight?: Maybe<Scalars['Float']>;
	status?: Maybe<Scalars['Float']>;
	deliveryDate?: Maybe<Scalars['String']>;
	receiver?: Maybe<Scalars['String']>;
	deliveryProofLink?: Maybe<Scalars['String']>;
	message?: Maybe<Scalars['String']>;
};

export type OrderDeliveryPoints = {
	__typename?: 'OrderDeliveryPoints';
	packages?: Maybe<Array<OrderDeliveryPackages>>;
	controls?: Maybe<Array<OrderDeliveryControls>>;
	id: Scalars['Float'];
	name?: Maybe<Scalars['String']>;
	code?: Maybe<Scalars['String']>;
	address1?: Maybe<Scalars['String']>;
	address2?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	town?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	longitude?: Maybe<Scalars['String']>;
	latitude?: Maybe<Scalars['String']>;
	status: Scalars['Float'];
};

export type OrderDistributionsUpsertInput = {
	id?: Maybe<Scalars['String']>;
	clientId: Scalars['String'];
	orderId?: Maybe<Scalars['String']>;
	clientShippingAddressId: Scalars['String'];
	clientShippingAddressName?: Maybe<Scalars['String']>;
};

export type OrderFilterInput = {
	status?: Maybe<OrderStatus>;
	source?: Maybe<OrderSource>;
	followStatus?: Maybe<FollowOrderStatus>;
	clientId?: Maybe<Scalars['String']>;
	childrenOrderClientIds?: Maybe<Array<Scalars['String']>>;
	onePacDocumentIds?: Maybe<Array<Scalars['String']>>;
};

export type OrderItem = {
	__typename?: 'OrderItem';
	status?: Maybe<OrderProductStatus>;
	/**
	 * OrderItem weight
	 * @deprecated Use weight instead
	 */
	weigth?: Maybe<Scalars['Float']>;
	resources: Array<Resource>;
	transportMoment: TransportMoment;
	transportSellingPriceHtAdditionalMargin: MarginType;
	transportType: TransportType;
	deliveryParcel: BoxShippingMode;
	deliveryDepartment?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	productName: Scalars['String'];
	quantity: Scalars['Float'];
	isDelivered?: Maybe<Scalars['Boolean']>;
	productId: Scalars['String'];
	trackingLink?: Maybe<Scalars['String']>;
	legacyRef?: Maybe<Scalars['String']>;
	weight?: Maybe<Scalars['Float']>;
	comment?: Maybe<Scalars['String']>;
	commentTransporter?: Maybe<Scalars['String']>;
	expectedDeliveryDate: Scalars['DateTime'];
	shippingProgress: Scalars['Boolean'];
	manufacturingProgress: Scalars['Boolean'];
	versionsNumber?: Maybe<Scalars['Float']>;
	buyingPriceHt?: Maybe<Scalars['Float']>;
	sellingPriceHt?: Maybe<Scalars['Float']>;
	transportBuyingPriceHt?: Maybe<Scalars['Float']>;
	transportSellingPriceHt?: Maybe<Scalars['Float']>;
	prepressPriceHt?: Maybe<Scalars['Float']>;
	prepressSellingPriceHt?: Maybe<Scalars['Float']>;
	productSellingPriceHt?: Maybe<Scalars['Float']>;
	tvaRate?: Maybe<Scalars['Float']>;
	tva?: Maybe<Scalars['Float']>;
	sellingPriceTtc?: Maybe<Scalars['Float']>;
	product: Product;
	shippingAddress?: Maybe<OrderAddress>;
	productMargin: Scalars['Float'];
	transportMargin: Scalars['Float'];
	realProductMargin: Scalars['Float'];
	realTransportMargin: Scalars['Float'];
	isWithRfaProduct: Scalars['Boolean'];
	rfaRate: Scalars['Float'];
	isWithRfaTransport: Scalars['Boolean'];
	clientReference?: Maybe<Scalars['String']>;
	numberVersion?: Maybe<Scalars['Float']>;
	provisionDate?: Maybe<Scalars['DateTime']>;
	collectionDate?: Maybe<Scalars['DateTime']>;
	supplierComment?: Maybe<Scalars['String']>;
	collectionComment?: Maybe<Scalars['String']>;
	deliveryComment?: Maybe<Scalars['String']>;
	numberFileProcessing?: Maybe<Scalars['Float']>;
	numberPlateChanges?: Maybe<Scalars['Float']>;
	tools?: Maybe<Scalars['Boolean']>;
	numberShots?: Maybe<Scalars['Float']>;
	orderId?: Maybe<Scalars['String']>;
	onePacDocumentId: Scalars['String'];
	onePacDocumentName?: Maybe<Scalars['String']>;
	suppliersPrices?: Maybe<Array<OrderItemSuppliersPricesModel>>;
	bestSupplierId?: Maybe<Scalars['String']>;
	onePacThematicName?: Maybe<Scalars['String']>;
	thumbnailAssetId?: Maybe<Scalars['String']>;
	assetUrl?: Maybe<Array<Scalars['String']>>;
	thumbnailAssetUrl?: Maybe<Scalars['String']>;
	onePacDistributionId?: Maybe<Scalars['String']>;
	onePacDistributionName?: Maybe<Scalars['String']>;
	onePacReplicationName?: Maybe<Scalars['String']>;
	onePacDistributionUrl?: Maybe<Scalars['String']>;
	onePacReplicationUrl?: Maybe<Scalars['String']>;
	transportPurchasePriceHt: Scalars['Float'];
	transportRfa: Scalars['Float'];
	transportSellingPriceMargin: Scalars['Float'];
	transportSellingPriceByDefaultHt: Scalars['Float'];
	transportPurchasePriceHtWithRfa: Scalars['Float'];
	transportPurchasePriceExcludingVAT: Scalars['Float'];
	corsicaDeliveryExtra: Scalars['Float'];
	isMechanizable: Scalars['Boolean'];
	isFinalDistribution: Scalars['Boolean'];
	transportSellingPriceHtWithClientRfa: Scalars['Float'];
	transportSellingPriceHtWithClientRfaRectified: Scalars['Float'];
	bestTransporterId?: Maybe<Scalars['String']>;
	transportersPrices?: Maybe<Array<OrderItemTransportersPricesModel>>;
	productTotalWeight: Scalars['Float'];
	clientNetworkLink?: Maybe<Scalars['String']>;
	publishOnNetworkFailed?: Maybe<Scalars['String']>;
	postDescription?: Maybe<Scalars['String']>;
	postErrorDescription?: Maybe<Scalars['String']>;
	publicationDate?: Maybe<Scalars['DateTime']>;
	totalPurchasePriceExcludingTax: Scalars['Float'];
	transporterPurchasePriceExcludingVAT: Scalars['Float'];
	transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
	transporterRfaRate: Scalars['Float'];
	productPurchasePriceExcludingVAT: Scalars['Float'];
	productPurchasePriceExcludingVATWithRFA: Scalars['Float'];
	supplierRspRate: Scalars['Float'];
	supplierRfaRate: Scalars['Float'];
	bestDeliveryParcelPurchasePrice: Scalars['Float'];
	bestSupplier?: Maybe<Supplier>;
};

export type OrderItemAddressInput = {
	name: Scalars['String'];
	firstAddress?: Maybe<Scalars['String']>;
	secondAddress?: Maybe<Scalars['String']>;
	thirdAddress?: Maybe<Scalars['String']>;
	postBox?: Maybe<Scalars['String']>;
	zipCode?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	deliveryDepartment?: Maybe<Scalars['String']>;
};

export type OrderItemCockpitUpsertInput = {
	status?: Maybe<OrderProductStatus>;
	id?: Maybe<Scalars['String']>;
	trackingLink?: Maybe<Scalars['String']>;
	legacyRef?: Maybe<Scalars['String']>;
	expectedDeliveryDate?: Maybe<Scalars['DateTime']>;
	comment?: Maybe<Scalars['String']>;
	weight?: Maybe<Scalars['Float']>;
	productId: Scalars['String'];
	productName?: Maybe<Scalars['String']>;
	quantity: Scalars['Float'];
	shippingAddress?: Maybe<OrderItemAddressInput>;
	onePacDocumentId: Scalars['String'];
	onePacThematicName?: Maybe<Scalars['String']>;
	thumbnailAssetId?: Maybe<Scalars['String']>;
	onePacDocumentName?: Maybe<Scalars['String']>;
	onePacDistributionId?: Maybe<Scalars['String']>;
	onePacDistributionName?: Maybe<Scalars['String']>;
	onePacReplicationId?: Maybe<Scalars['String']>;
	onePacReplicationName?: Maybe<Scalars['String']>;
	clientNetworkLink?: Maybe<Scalars['String']>;
	clientNetworkPageId?: Maybe<Scalars['String']>;
	postDescription?: Maybe<Scalars['String']>;
	publicationDate?: Maybe<Scalars['DateTime']>;
	resources?: Maybe<Array<ResourceInput>>;
	socialNetwork?: Maybe<SocialNetworksInput>;
	isWithRfaProduct?: Maybe<Scalars['Boolean']>;
	realProductMargin?: Maybe<Scalars['Float']>;
	realTransportMargin?: Maybe<Scalars['Float']>;
	isWithRfaTransport?: Maybe<Scalars['Boolean']>;
};

export type OrderItemSupplierPricesInput = {
	supplierId: Scalars['String'];
	supplierPrice: Scalars['Float'];
	supplierName: Scalars['String'];
	supplierRspRate: Scalars['Float'];
	supplierRfaRate: Scalars['Float'];
	buyingPriceHtWithoutRspAndRfa: Scalars['Float'];
};

export type OrderItemSuppliersPricesModel = {
	__typename?: 'OrderItemSuppliersPricesModel';
	supplierId: Scalars['String'];
	supplierPrice: Scalars['Float'];
	supplierName: Scalars['String'];
	supplierRspRate: Scalars['Float'];
	supplierRfaRate: Scalars['Float'];
	buyingPriceHtWithoutRspAndRfa: Scalars['Float'];
};

export type OrderItemTransportersPricesInput = {
	transporterId: Scalars['String'];
	transporterName: Scalars['String'];
	transporterRfaRate: Scalars['Float'];
	transporterPurchasePriceExcludingVAT: Scalars['Float'];
	transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
};

export type OrderItemTransportersPricesModel = {
	__typename?: 'OrderItemTransportersPricesModel';
	transporterId: Scalars['String'];
	transporterName: Scalars['String'];
	transporterRfaRate: Scalars['Float'];
	transporterPurchasePriceExcludingVAT: Scalars['Float'];
	transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
	orderItemId: Scalars['String'];
};

export type OrderItemUpsertInput = {
	status?: Maybe<OrderProductStatus>;
	transportMoment: TransportMoment;
	transportSellingPriceHtAdditionalMargin: MarginType;
	transportType: TransportType;
	deliveryParcel: BoxShippingMode;
	deliveryDepartment?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	trackingLink?: Maybe<Scalars['String']>;
	legacyRef?: Maybe<Scalars['String']>;
	sellingPriceTtc?: Maybe<Scalars['Float']>;
	tva?: Maybe<Scalars['Float']>;
	tvaRate?: Maybe<Scalars['Float']>;
	productSellingPriceHt?: Maybe<Scalars['Float']>;
	prepressPriceHt?: Maybe<Scalars['Float']>;
	prepressSellingPriceHt?: Maybe<Scalars['Float']>;
	transportBuyingPriceHt?: Maybe<Scalars['Float']>;
	transportSellingPriceHt?: Maybe<Scalars['Float']>;
	buyingPriceHt?: Maybe<Scalars['Float']>;
	sellingPriceHt?: Maybe<Scalars['Float']>;
	expectedDeliveryDate?: Maybe<Scalars['DateTime']>;
	comment?: Maybe<Scalars['String']>;
	commentTransporter?: Maybe<Scalars['String']>;
	weight?: Maybe<Scalars['Float']>;
	productId: Scalars['String'];
	productName: Scalars['String'];
	quantity: Scalars['Float'];
	numberVersion?: Maybe<Scalars['Float']>;
	versionsNumber?: Maybe<Scalars['Float']>;
	provisionDate?: Maybe<Scalars['DateTime']>;
	collectionDate?: Maybe<Scalars['DateTime']>;
	supplierComment?: Maybe<Scalars['String']>;
	collectionComment?: Maybe<Scalars['String']>;
	deliveryComment?: Maybe<Scalars['String']>;
	numberFileProcessing?: Maybe<Scalars['Float']>;
	numberPlateChanges?: Maybe<Scalars['Float']>;
	tools?: Maybe<Scalars['Boolean']>;
	numberShots?: Maybe<Scalars['Float']>;
	suppliersPrices?: Maybe<Array<OrderItemSupplierPricesInput>>;
	bestSupplierId?: Maybe<Scalars['String']>;
	onePacDocumentId?: Maybe<Scalars['String']>;
	transportPurchasePriceHt: Scalars['Float'];
	transportRfa: Scalars['Float'];
	transportSellingPriceMargin: Scalars['Float'];
	transportSellingPriceByDefaultHt: Scalars['Float'];
	transportPurchasePriceHtWithRfa: Scalars['Float'];
	transportPurchasePriceExcludingVAT: Scalars['Float'];
	corsicaDeliveryExtra: Scalars['Float'];
	isMechanizable: Scalars['Boolean'];
	isFinalDistribution: Scalars['Boolean'];
	transportSellingPriceHtWithClientRfa: Scalars['Float'];
	transportSellingPriceHtWithClientRfaRectified: Scalars['Float'];
	transportersPrices?: Maybe<Array<OrderItemTransportersPricesInput>>;
	bestTransporterId?: Maybe<Scalars['String']>;
	productTotalWeight?: Maybe<Scalars['Float']>;
	totalPurchasePriceExcludingTax?: Maybe<Scalars['Float']>;
	transporterPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	transporterPurchaseExcludingVATWithRfa?: Maybe<Scalars['Float']>;
	transporterRfaRate?: Maybe<Scalars['Float']>;
	productPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
	productPurchasePriceExcludingVATWithRFA?: Maybe<Scalars['Float']>;
	supplierRspRate?: Maybe<Scalars['Float']>;
	supplierRfaRate?: Maybe<Scalars['Float']>;
	bestDeliveryParcelPurchasePrice?: Maybe<Scalars['Float']>;
};

export enum OrderPaymentType {
	ByInvoice = 'ByInvoice',
	ByCb = 'ByCB',
}

export type OrderPdfInvoiceStatus = {
	__typename?: 'OrderPdfInvoiceStatus';
	success: Scalars['Boolean'];
	description: Scalars['String'];
};

export enum OrderProductStatus {
	Waiting = 'Waiting',
	ManufacturingInProgress = 'ManufacturingInProgress',
	ShippingInProgress = 'ShippingInProgress',
	Delivered = 'Delivered',
	Faillure = 'Faillure',
}

export type OrderSearchInput = {
	id?: Maybe<StringFilter>;
	clientName?: Maybe<StringFilter>;
	status?: Maybe<OrderStatus>;
	followStatus?: Maybe<FollowOrderStatus>;
	source?: Maybe<OrderSource>;
	client?: Maybe<ClientOrderFilter>;
	fileReference?: Maybe<FileReferenceOrderFilter>;
	createdAt?: Maybe<DateTimeFilter>;
	parentOrderId?: Maybe<StringFilter>;
	billingEntity?: Maybe<StringFilter>;
	sellingPriceHt?: Maybe<Scalars['Float']>;
};

export type OrderSortInput = {
	id?: Maybe<SortOrder>;
	clientName?: Maybe<SortOrder>;
	source?: Maybe<SortOrder>;
	askBy?: Maybe<SortOrder>;
	status?: Maybe<SortOrder>;
	clientReference?: Maybe<SortOrder>;
	operationName?: Maybe<SortOrder>;
	clientId?: Maybe<SortOrder>;
	createdAt?: Maybe<SortOrder>;
	fileReference?: Maybe<FileReferenceSortInput>;
	client?: Maybe<ClientSortOrder>;
	parentOrderId?: Maybe<SortOrder>;
};

export enum OrderSource {
	OneSupply = 'OneSupply',
	OnePacBigShop = 'OnePacBigShop',
}

export enum OrderStatus {
	InProgress = 'InProgress',
	Delivered = 'Delivered',
	Canceled = 'Canceled',
	WorkInProgress = 'WorkInProgress',
}

export type OrderWithPagination = {
	__typename?: 'OrderWithPagination';
	orders: Array<Order>;
	total?: Maybe<Scalars['Float']>;
};

export type Pagination = {
	__typename?: 'Pagination';
	totalItemsCount: Scalars['Float'];
};

export type PaginationInput = {
	take: Scalars['Float'];
	skip: Scalars['Float'];
};

export enum PalletShippingMode {
	Strandard = 'Strandard',
	Tailgate = 'Tailgate',
	Other = 'Other',
}

export type Password = {
	__typename?: 'Password';
	password: Scalars['String'];
};

export type Permission = {
	__typename?: 'Permission';
	key: Scalars['String'];
	name?: Maybe<Scalars['String']>;
};

export enum PricesSource {
	OneSupply = 'OneSupply',
	ClariPrint = 'ClariPrint',
	ElpevBase = 'ElpevBase',
	ClariPrintOs = 'ClariPrintOs',
	Digital = 'Digital',
}

export type PrintValue = {
	__typename?: 'PrintValue';
	printValue: Scalars['String'];
	attributeId: Scalars['String'];
	attribute: Attribute;
	printName?: Maybe<Scalars['String']>;
};

export type PrintValueInput = {
	attributeId: Scalars['String'];
	printValue: Scalars['String'];
	printName?: Maybe<Scalars['String']>;
};

export type Product = {
	__typename?: 'Product';
	/**
	 * unitWeightGr
	 * @deprecated unused, to delete in all queries please!
	 */
	unitWeightGr?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	supportId: Scalars['String'];
	support: Support;
	typeImpression?: Maybe<Scalars['String']>;
	matiere?: Maybe<Scalars['String']>;
	conditionnement?: Maybe<AttributConditionnement>;
	typeSupport?: Maybe<Scalars['String']>;
	faconnage?: Maybe<Scalars['String']>;
	nbCouleurRecto?: Maybe<Scalars['String']>;
	descriptionIndication?: Maybe<Scalars['String']>;
	precisionsFaconnage?: Maybe<Scalars['String']>;
	typePapier?: Maybe<Scalars['String']>;
	typeMatiere?: Maybe<Scalars['String']>;
	nbCouleurVerso?: Maybe<Scalars['String']>;
	optionsFaconnage?: Maybe<Scalars['String']>;
	format?: Maybe<Scalars['String']>;
	orientation?: Maybe<Scalars['String']>;
	couleur?: Maybe<Scalars['String']>;
	impressionRectoVerso?: Maybe<Scalars['String']>;
	decoupe?: Maybe<Scalars['String']>;
	surfacage?: Maybe<Scalars['String']>;
	paginationTotale?: Maybe<Scalars['String']>;
	fourniture?: Maybe<Scalars['String']>;
	grammageGr?: Maybe<Scalars['String']>;
	precisionsFourniture?: Maybe<Scalars['String']>;
	epaisseurMicrons?: Maybe<Scalars['String']>;
	paginationCouverture?: Maybe<Scalars['String']>;
	finitionRecto?: Maybe<Scalars['String']>;
	finitionVerso?: Maybe<Scalars['String']>;
	nbCouleurCouvertureRecto?: Maybe<Scalars['String']>;
	paginationInterieur?: Maybe<Scalars['String']>;
	classification?: Maybe<Scalars['String']>;
	paginationInterieurEncart?: Maybe<Scalars['String']>;
	certification?: Maybe<Scalars['String']>;
	nbCouleurCouvertureVerso?: Maybe<Scalars['String']>;
	matiereCouverture?: Maybe<Scalars['String']>;
	nbCouleurInterieurRecto?: Maybe<Scalars['String']>;
	largeurFormatOuvertCm?: Maybe<Scalars['String']>;
	typeMatiereCouverture?: Maybe<Scalars['String']>;
	longueurFormatOuvertCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurVerso?: Maybe<Scalars['String']>;
	grammageCouvertureGr?: Maybe<Scalars['String']>;
	largeurFormatPageCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurEncartRecto?: Maybe<Scalars['String']>;
	matiereInterieur?: Maybe<Scalars['String']>;
	longueurFormatPageCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurEncartVerso?: Maybe<Scalars['String']>;
	largeurFormatFiniCm?: Maybe<Scalars['String']>;
	surfacageCouvertureRecto?: Maybe<Scalars['String']>;
	typeMatiereInterieur?: Maybe<Scalars['String']>;
	longueurFormatFiniCm?: Maybe<Scalars['String']>;
	grammageInterieurGr?: Maybe<Scalars['String']>;
	tauxSurfacage?: Maybe<Scalars['String']>;
	impressionLogo?: Maybe<Scalars['String']>;
	largeurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
	repiquage?: Maybe<Scalars['String']>;
	repiquageDOS?: Maybe<Scalars['String']>;
	largeurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
	repiquageCoeur?: Maybe<Scalars['String']>;
	longueurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
	chant?: Maybe<Scalars['String']>;
	largeurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
	longueurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatPageCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatPageCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
	profondeurFormatFiniCm?: Maybe<Scalars['String']>;
	diametreCm?: Maybe<Scalars['String']>;
	taille?: Maybe<Scalars['String']>;
	enveloppeFenetre?: Maybe<Scalars['String']>;
	largeurTailleFenetreCm?: Maybe<Scalars['String']>;
	longueurTailleFenetreCm?: Maybe<Scalars['String']>;
	ouvertureEnveloppe?: Maybe<Scalars['String']>;
	surfacageCouvertureVerso?: Maybe<Scalars['String']>;
	surfacageInterieurRecto?: Maybe<Scalars['String']>;
	surfacageInterieurVerso?: Maybe<Scalars['String']>;
	typeEnvoi?: Maybe<Scalars['String']>;
	ciblage?: Maybe<Scalars['String']>;
	zoneTerritorial?: Maybe<Scalars['String']>;
	objectifMarketing?: Maybe<Scalars['String']>;
	paysDeDiffusion?: Maybe<Scalars['String']>;
	modeleAchat?: Maybe<Scalars['String']>;
	fondsPerdus?: Maybe<Scalars['String']>;
	faconnageDeLivraison?: Maybe<Scalars['String']>;
	largeurPixel?: Maybe<Scalars['String']>;
	longueurPixel?: Maybe<Scalars['String']>;
	codeAt: Scalars['String'];
	clientPrices?: Maybe<Array<ClientPrice>>;
	productSupplierPrice?: Maybe<Array<ProductSupplierPrice>>;
	clientQuoteItem: ClientQuoteItem;
};

export type ProductAttributesValues = {
	largeurFormatFiniCm?: Maybe<Scalars['String']>;
	longueurFormatFiniCm?: Maybe<Scalars['String']>;
	largeurFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurFormatOuvertCm?: Maybe<Scalars['String']>;
	impressionRectoVerso?: Maybe<Scalars['String']>;
	nbCouleurRecto?: Maybe<Scalars['String']>;
	nbCouleurVerso?: Maybe<Scalars['String']>;
	surfacage?: Maybe<Scalars['String']>;
	typeMatiere?: Maybe<Scalars['String']>;
	typePapier?: Maybe<Scalars['String']>;
	grammageGr?: Maybe<Scalars['String']>;
	decoupe?: Maybe<Scalars['String']>;
	finitionRecto?: Maybe<Scalars['String']>;
	finitionVerso?: Maybe<Scalars['String']>;
	classification?: Maybe<Scalars['String']>;
	certification?: Maybe<Scalars['String']>;
	conditionnement?: Maybe<AttributConditionnementInput>;
	faconnage?: Maybe<FaconnageDetails>;
	precisionsFaconnage?: Maybe<FaconnageDetails>;
	optionsFaconnage?: Maybe<FaconnageDetails>;
	fondsPerdus?: Maybe<Scalars['String']>;
	faconnageDeLivraison?: Maybe<Scalars['String']>;
};

export type ProductClientPrice = {
	__typename?: 'ProductClientPrice';
	quantity: Scalars['Float'];
	numberVersion: Scalars['Float'];
	productId: Scalars['String'];
	productSalesPriceExcludingVAT: Scalars['Float'];
	productName: Scalars['String'];
	isIncluded: Scalars['Boolean'];
};

export type ProductClientPriceCreateInput = {
	product: ProductCreateInput;
	clientPrice: ClientPriceInput;
};

export type ProductCreateInput = {
	supportId: Scalars['String'];
	typeImpression?: Maybe<Scalars['String']>;
	matiere?: Maybe<Scalars['String']>;
	conditionnement?: Maybe<AttributConditionnementInput>;
	typeSupport?: Maybe<Scalars['String']>;
	faconnage?: Maybe<Scalars['String']>;
	nbCouleurRecto?: Maybe<Scalars['String']>;
	descriptionIndication?: Maybe<Scalars['String']>;
	precisionsFaconnage?: Maybe<Scalars['String']>;
	typePapier?: Maybe<Scalars['String']>;
	typeMatiere?: Maybe<Scalars['String']>;
	nbCouleurVerso?: Maybe<Scalars['String']>;
	optionsFaconnage?: Maybe<Scalars['String']>;
	format?: Maybe<Scalars['String']>;
	orientation?: Maybe<Scalars['String']>;
	couleur?: Maybe<Scalars['String']>;
	impressionRectoVerso?: Maybe<Scalars['String']>;
	decoupe?: Maybe<Scalars['String']>;
	surfacage?: Maybe<Scalars['String']>;
	paginationTotale?: Maybe<Scalars['String']>;
	fourniture?: Maybe<Scalars['String']>;
	grammageGr?: Maybe<Scalars['String']>;
	precisionsFourniture?: Maybe<Scalars['String']>;
	epaisseurMicrons?: Maybe<Scalars['String']>;
	paginationCouverture?: Maybe<Scalars['String']>;
	finitionRecto?: Maybe<Scalars['String']>;
	finitionVerso?: Maybe<Scalars['String']>;
	nbCouleurCouvertureRecto?: Maybe<Scalars['String']>;
	paginationInterieur?: Maybe<Scalars['String']>;
	classification?: Maybe<Scalars['String']>;
	paginationInterieurEncart?: Maybe<Scalars['String']>;
	certification?: Maybe<Scalars['String']>;
	nbCouleurCouvertureVerso?: Maybe<Scalars['String']>;
	matiereCouverture?: Maybe<Scalars['String']>;
	nbCouleurInterieurRecto?: Maybe<Scalars['String']>;
	largeurFormatOuvertCm?: Maybe<Scalars['String']>;
	typeMatiereCouverture?: Maybe<Scalars['String']>;
	longueurFormatOuvertCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurVerso?: Maybe<Scalars['String']>;
	grammageCouvertureGr?: Maybe<Scalars['String']>;
	largeurFormatPageCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurEncartRecto?: Maybe<Scalars['String']>;
	matiereInterieur?: Maybe<Scalars['String']>;
	longueurFormatPageCm?: Maybe<Scalars['String']>;
	nbCouleurInterieurEncartVerso?: Maybe<Scalars['String']>;
	largeurFormatFiniCm?: Maybe<Scalars['String']>;
	surfacageCouvertureRecto?: Maybe<Scalars['String']>;
	typeMatiereInterieur?: Maybe<Scalars['String']>;
	longueurFormatFiniCm?: Maybe<Scalars['String']>;
	grammageInterieurGr?: Maybe<Scalars['String']>;
	tauxSurfacage?: Maybe<Scalars['String']>;
	impressionLogo?: Maybe<Scalars['String']>;
	largeurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
	repiquage?: Maybe<Scalars['String']>;
	repiquageDOS?: Maybe<Scalars['String']>;
	largeurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
	repiquageCoeur?: Maybe<Scalars['String']>;
	longueurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
	chant?: Maybe<Scalars['String']>;
	largeurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
	longueurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatPageCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatPageCm?: Maybe<Scalars['String']>;
	largeurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
	longueurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
	largeurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
	longueurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
	profondeurFormatFiniCm?: Maybe<Scalars['String']>;
	diametreCm?: Maybe<Scalars['String']>;
	taille?: Maybe<Scalars['String']>;
	enveloppeFenetre?: Maybe<Scalars['String']>;
	largeurTailleFenetreCm?: Maybe<Scalars['String']>;
	longueurTailleFenetreCm?: Maybe<Scalars['String']>;
	ouvertureEnveloppe?: Maybe<Scalars['String']>;
	surfacageCouvertureVerso?: Maybe<Scalars['String']>;
	surfacageInterieurRecto?: Maybe<Scalars['String']>;
	surfacageInterieurVerso?: Maybe<Scalars['String']>;
	typeEnvoi?: Maybe<Scalars['String']>;
	ciblage?: Maybe<Scalars['String']>;
	zoneTerritorial?: Maybe<Scalars['String']>;
	objectifMarketing?: Maybe<Scalars['String']>;
	paysDeDiffusion?: Maybe<Scalars['String']>;
	modeleAchat?: Maybe<Scalars['String']>;
	fondsPerdus?: Maybe<Scalars['String']>;
	faconnageDeLivraison?: Maybe<Scalars['String']>;
	largeurPixel?: Maybe<Scalars['String']>;
	longueurPixel?: Maybe<Scalars['String']>;
};

export type ProductSearchInput = {
	support: SupportSearchInput;
};

export type ProductSortOrder = {
	support: SupportSortOrder;
};

export type ProductSupplierPrice = {
	__typename?: 'ProductSupplierPrice';
	quantity: Scalars['Float'];
	prixCalage?: Maybe<Scalars['Float']>;
	prixHorsCalage?: Maybe<Scalars['Float']>;
	prixExemplaireSupp?: Maybe<Scalars['Float']>;
	autrePrixFixe?: Maybe<Scalars['Float']>;
	prixChangementPlaque?: Maybe<Scalars['Float']>;
	prixTraitementFichier?: Maybe<Scalars['Float']>;
	prixCliche?: Maybe<Scalars['Float']>;
	prixOutillage?: Maybe<Scalars['Float']>;
	prixPapierKg?: Maybe<Scalars['Float']>;
	nbPosesMax?: Maybe<Scalars['Float']>;
	poidsUnitaireGr?: Maybe<Scalars['Float']>;
	franco?: Maybe<Scalars['Boolean']>;
};

export type ProductsFilterInput = {
	supportCategory?: Maybe<SupportCategory>;
	clientPriceStatus?: Maybe<ClientPriceStatus>;
	supportId?: Maybe<Scalars['String']>;
	clientId?: Maybe<Scalars['String']>;
	supportName?: Maybe<Scalars['String']>;
	attributes?: Maybe<AttributesFilterInput>;
};

export enum ProjectName {
	OneSupply = 'OneSupply',
	OnePartners = 'OnePartners',
}

export type PublishData = {
	__typename?: 'PublishData';
	postId: Scalars['String'];
	postUrl: Scalars['String'];
};

export type Query = {
	__typename?: 'Query';
	authUser: User;
	users: Array<User>;
	user: User;
	password: Password;
	currentUser: User;
	currentUserClients: Array<Client>;
	usersByClientIds: Array<User>;
	permissions: Array<Permission>;
	role: Role;
	roles: Array<Role>;
	supports: Array<Support>;
	support: Support;
	attributes: Array<Attribute>;
	fabricationTimes: FabricationTimeBySupport;
	products: Array<Product>;
	product: Product;
	supplier: Supplier;
	suppliers: Array<Supplier>;
	suppliersPrices: Array<Supplier>;
	filtredSupplierPrices: Array<SupplierPrices>;
	getSupplierPricesForOrderItem: Array<SupplierPrices>;
	clients: Array<Client>;
	client: Client;
	possibleBillingsClients: Array<Client>;
	clientAndAllParents: Array<Client>;
	clientAndAllChildrens: Array<Client>;
	clientByCompanyBase: ClientShippingAddressByCompanyBase;
	clientChildren: Array<ClientChildren>;
	clientShippingAddress: Array<ClientShippingAddress>;
	mainShippingAddress: ClientShippingAddress;
	departments: Array<Department>;
	department: Scalars['String'];
	ordersWithPagination: OrderWithPagination;
	orders: Array<Order>;
	order: Order;
	orderDeliveries: OrderDeliveries;
	bestSellingOrders: Array<BestSellingOrders>;
	getOrderItemById: Order;
	transporters: Array<Transporter>;
	transportListWithPurchasePrice: Array<TransportersPricesModel>;
	transportersPricesForOrderItem: Array<TransportersPricesModel>;
	clientPrice: ClientPrice;
	filtredClientPrice?: Maybe<ClientPrice>;
	clientPriceWithCustomQuantity: ClientPrice;
	clientPricesWithCustomQuantities: ClientPriceWithCustomQuantities;
	getClientPricesFromDistributions: Array<ClientPricesDistributions>;
	productClientPriceWithCustomQuantity: ProductClientPrice;
	clientPrices: Array<ClientPrice>;
	clientPricesWhitPaginantion: ClientPricesWithPagination;
	clientPricesByClientIdAndSupportId: Array<ClientPrice>;
	clientPricesByProductNameAndClientId: Array<ClientPrice>;
	clientPricesByProductsId: Array<Client>;
	exportClientsPrices: Scalars['String'];
	allClientsPrices: Array<Client>;
	bigShopConfig: BigShopConfig;
	printValuesByAttributeId: Array<PrintValue>;
	printValuesCharacteristicsByFamily: Array<Attribute>;
	printValues: Array<PrintValue>;
	clientPricesByClientId: Array<ClientPrice>;
	quoteAttributes: Array<QuoteAttributesConfig>;
	clientQuotes: ClientQuoteWithPagination;
	clientQuote: ClientQuote;
	filteredClientQuotes: Array<ClientQuote>;
	invoices: InvoiceWithPagination;
	invoice: Invoice;
	fileReferences: FileReferenceWithPaginationModel;
	supplierOrdersPaginated: SupplierOrdersWithTotal;
};

export type QueryUserArgs = {
	id: Scalars['String'];
};

export type QueryCurrentUserClientsArgs = {
	hasChildren: Scalars['Boolean'];
};

export type QueryUsersByClientIdsArgs = {
	roleNames?: Maybe<Array<Scalars['String']>>;
	clientId: Scalars['String'];
};

export type QueryRoleArgs = {
	id: Scalars['String'];
};

export type QuerySupportsArgs = {
	filters?: Maybe<SupportFiltersInput>;
};

export type QuerySupportArgs = {
	id: Scalars['String'];
};

export type QueryFabricationTimesArgs = {
	input: FabricationTimeBySupportInput;
};

export type QueryProductsArgs = {
	data: ProductsFilterInput;
};

export type QueryProductArgs = {
	id: Scalars['String'];
};

export type QuerySupplierArgs = {
	id: Scalars['String'];
};

export type QuerySuppliersPricesArgs = {
	productsId?: Maybe<Array<Scalars['String']>>;
};

export type QueryFiltredSupplierPricesArgs = {
	filters: SupplierPriceFilterInput;
};

export type QueryGetSupplierPricesForOrderItemArgs = {
	options: SupplierPricesForOrderItemInput;
};

export type QueryClientsArgs = {
	filter?: Maybe<ClientFilterInput>;
};

export type QueryClientArgs = {
	id: Scalars['String'];
};

export type QueryPossibleBillingsClientsArgs = {
	id: Scalars['String'];
};

export type QueryClientAndAllParentsArgs = {
	id: Scalars['String'];
};

export type QueryClientAndAllChildrensArgs = {
	id: Scalars['String'];
};

export type QueryClientByCompanyBaseArgs = {
	filter?: Maybe<ClientFilterInputByCompanyBaseInput>;
	companyBase: Scalars['String'];
};

export type QueryClientChildrenArgs = {
	id: Scalars['String'];
};

export type QueryClientShippingAddressArgs = {
	clientId?: Maybe<Scalars['String']>;
};

export type QueryMainShippingAddressArgs = {
	clientId: Scalars['String'];
};

export type QueryDepartmentArgs = {
	filter: DepartmentInput;
};

export type QueryOrdersWithPaginationArgs = {
	isOrderCockpit?: Maybe<Scalars['Boolean']>;
	pagination?: Maybe<PaginationInput>;
	sort?: Maybe<OrderSortInput>;
	search?: Maybe<OrderSearchInput>;
};

export type QueryOrdersArgs = {
	filter?: Maybe<OrderFilterInput>;
};

export type QueryOrderArgs = {
	clientId?: Maybe<Scalars['String']>;
	id: Scalars['String'];
};

export type QueryOrderDeliveriesArgs = {
	id?: Maybe<Scalars['String']>;
};

export type QueryBestSellingOrdersArgs = {
	pagination?: Maybe<PaginationInput>;
	clientId: Scalars['String'];
};

export type QueryGetOrderItemByIdArgs = {
	orderItemId: Scalars['String'];
};

export type QueryTransportersArgs = {
	filter?: Maybe<TransporterFilterInput>;
};

export type QueryTransportListWithPurchasePriceArgs = {
	filter: UniqClientPriceInput;
};

export type QueryTransportersPricesForOrderItemArgs = {
	options: TransportersPricesForOrderItemInput;
};

export type QueryClientPriceArgs = {
	filters: UniqClientPriceInput;
};

export type QueryFiltredClientPriceArgs = {
	filters: FiltredClientPriceInput;
};

export type QueryClientPriceWithCustomQuantityArgs = {
	filters: UniqClientPriceInput;
};

export type QueryClientPricesWithCustomQuantitiesArgs = {
	clientPrices: MultipleClientPricesInput;
};

export type QueryGetClientPricesFromDistributionsArgs = {
	filters: ClientPricesDistributionsInput;
};

export type QueryProductClientPriceWithCustomQuantityArgs = {
	filters: UniqClientPriceInput;
};

export type QueryClientPricesArgs = {
	filters: ClientPricesFiltersInput;
};

export type QueryClientPricesWhitPaginantionArgs = {
	pagination?: Maybe<PaginationInput>;
	sort?: Maybe<ClientPriceSortInput>;
	search?: Maybe<ClientPricesSearchInput>;
};

export type QueryClientPricesByClientIdAndSupportIdArgs = {
	supportId: Scalars['String'];
	clientId: Scalars['String'];
};

export type QueryClientPricesByProductNameAndClientIdArgs = {
	filters: ClientPricesFiltersInput;
};

export type QueryClientPricesByProductsIdArgs = {
	productsId: Array<Scalars['String']>;
};

export type QueryExportClientsPricesArgs = {
	data: ProductsFilterInput;
};

export type QueryBigShopConfigArgs = {
	supportId: Scalars['String'];
};

export type QueryPrintValuesByAttributeIdArgs = {
	id: Scalars['String'];
};

export type QueryPrintValuesCharacteristicsByFamilyArgs = {
	family: Scalars['String'];
};

export type QueryClientPricesByClientIdArgs = {
	filter: ClientPricesFiltersInput;
};

export type QueryQuoteAttributesArgs = {
	clientId: Scalars['String'];
};

export type QueryClientQuotesArgs = {
	pagination?: Maybe<PaginationInput>;
	sorts?: Maybe<ClientQuoteSortInput>;
	filters?: Maybe<ClientQuoteFilterInput>;
};

export type QueryClientQuoteArgs = {
	id: Scalars['String'];
};

export type QueryFilteredClientQuotesArgs = {
	filters: ClientQuoteFilterInput;
};

export type QueryInvoicesArgs = {
	pagination?: Maybe<PaginationInput>;
	filter?: Maybe<InvoiceFilterInput>;
	sort?: Maybe<InvoiceSortInput>;
	search?: Maybe<InvoiceSearchInput>;
};

export type QueryInvoiceArgs = {
	id: Scalars['String'];
};

export type QueryFileReferencesArgs = {
	filters?: Maybe<FileReferenceFilterInput>;
	pagination?: Maybe<PaginationInput>;
	sort?: Maybe<FileReferenceSortInput>;
	search?: Maybe<FileReferenceSearchInput>;
};

export type QuerySupplierOrdersPaginatedArgs = {
	filter?: Maybe<SupplierOrderFilterInput>;
	limit: Scalars['Float'];
	offset: Scalars['Float'];
};

export type QuoteAttributesConfig = {
	__typename?: 'QuoteAttributesConfig';
	familiesGroup: Scalars['String'];
	familyAttributes: Array<FamilyAttributes>;
};

export type QuoteClient = {
	translation: ClientQuoteTranslate;
	elpevSite: ElpevSite;
};

export type QuoteClientModel = {
	__typename?: 'QuoteClientModel';
	translation: ClientQuoteTranslate;
	elpevSite: ElpevSite;
};

export enum QuoteType {
	ClientPriceToBeValidate = 'ClientPriceToBeValidate',
	ClientOrderToBeCreate = 'ClientOrderToBeCreate',
}

export type Resource = {
	__typename?: 'Resource';
	resourceIndex: Scalars['Float'];
	resourceId: Scalars['String'];
	mimetype: Scalars['String'];
};

export type ResourceInput = {
	resourceIndex: Scalars['Float'];
	resourceId: Scalars['String'];
	mimetype: Scalars['String'];
};

export type ResourceNetwork = {
	__typename?: 'ResourceNetwork';
	resourceIndex: Scalars['Float'];
	resourceId: Scalars['String'];
	mimetype: Scalars['String'];
};

export type ResponsableUrl = {
	__typename?: 'ResponsableUrl';
	url: Scalars['String'];
};

export type ResponsableUserInitials = {
	__typename?: 'ResponsableUserInitials';
	initials?: Maybe<Scalars['String']>;
	fullName?: Maybe<Scalars['String']>;
};

export type Role = {
	__typename?: 'Role';
	id: Scalars['String'];
	name: Scalars['String'];
	description: Scalars['String'];
	permissions: Array<Permission>;
	users: Array<User>;
};

export type RoleInput = {
	name: Scalars['String'];
	description: Scalars['String'];
	permissionKeys: Array<Scalars['String']>;
};

export enum Rythme {
	Annuel = 'Annuel',
	Semestriel = 'Semestriel',
	Trimestriel = 'Trimestriel',
}

export type SocialNetworksInput = {
	category: Scalars['String'];
	clientNetworkLink?: Maybe<Scalars['String']>;
	clientNetworkPageId?: Maybe<Scalars['String']>;
	publishDescription?: Maybe<Scalars['String']>;
	mediumType: Scalars['String'];
	expectedDeliveryDate: Scalars['DateTime'];
	resources?: Maybe<Array<ResourceInput>>;
};

export enum SortOrder {
	Asc = 'asc',
	Desc = 'desc',
}

export enum Source {
	OneSupply = 'OneSupply',
	OnePacBigShop = 'OnePacBigShop',
}

export enum StatusDigital {
	Published = 'PUBLISHED',
	NotPublished = 'NOT_PUBLISHED',
	Failed = 'FAILED',
}

export type StringFilter = {
	equals?: Maybe<Scalars['String']>;
	not?: Maybe<Scalars['String']>;
	contains?: Maybe<Scalars['String']>;
};

export type Supplier = {
	__typename?: 'Supplier';
	rythme?: Maybe<Rythme>;
	id: Scalars['String'];
	name: Scalars['String'];
	createdAt: Scalars['DateTime'];
	updatedAt: Scalars['DateTime'];
	lastConnection: Scalars['DateTime'];
	productsSuppliersPrices?: Maybe<Array<SupplierPrices>>;
	siteAddress1?: Maybe<Address>;
	siteAddress2?: Maybe<Address>;
	isActif: Scalars['Boolean'];
	companyBase?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	fax?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	website?: Maybe<Scalars['String']>;
	comment?: Maybe<Scalars['String']>;
	tvaNum?: Maybe<Scalars['String']>;
	tvaType?: Maybe<Scalars['Float']>;
	maxPalletWeight?: Maybe<Scalars['Float']>;
	updatedBy?: Maybe<Scalars['String']>;
	escomptes?: Maybe<Array<EscompteObjectType>>;
	rsp?: Maybe<Scalars['Float']>;
	rspStart?: Maybe<Scalars['DateTime']>;
	rspEnd?: Maybe<Scalars['DateTime']>;
	expressMode?: Maybe<Scalars['Boolean']>;
	urgentMode?: Maybe<Scalars['Boolean']>;
	expressModeSupplement?: Maybe<Scalars['Float']>;
	urgentModeSupplement?: Maybe<Scalars['Float']>;
	discounts?: Maybe<Array<DiscountObjectType>>;
	transportModes?: Maybe<Array<TransportModeObjectType>>;
	rfa?: Maybe<Scalars['Float']>;
	user?: Maybe<User>;
	supplierContact: Array<Contact>;
};

export type SupplierOrder = {
	__typename?: 'SupplierOrder';
	status?: Maybe<SupplierOrderStatus>;
	id: Scalars['String'];
	createdAt: Scalars['DateTime'];
	supplierId: Scalars['String'];
	purchaseOrderId?: Maybe<Scalars['String']>;
	orderId: Scalars['String'];
	order: Order;
};

export type SupplierOrderFilterInput = {
	status?: Maybe<SupplierOrderStatus>;
	supplierId?: Maybe<Scalars['String']>;
};

export enum SupplierOrderStatus {
	PendingValidation = 'PendingValidation',
	PendingProduction = 'PendingProduction',
	PendingDelivery = 'PendingDelivery',
	Delivered = 'Delivered',
	Canceled = 'Canceled',
	Refused = 'Refused',
}

export type SupplierOrdersWithTotal = {
	__typename?: 'SupplierOrdersWithTotal';
	supplierOrders: Array<SupplierOrder>;
	total: Scalars['Float'];
};

export type SupplierPriceFilterInput = {
	productId?: Maybe<Scalars['String']>;
	quantity: Scalars['Float'];
	supportId: Scalars['String'];
	isCheapestSupplier?: Maybe<Scalars['Boolean']>;
	tools: Scalars['Boolean'];
	isIdentical: Scalars['Boolean'];
	numberVersion: Scalars['Float'];
	quantities: Array<Scalars['Float']>;
	productAttributes?: Maybe<ProductAttributesValues>;
	supplierId?: Maybe<Scalars['String']>;
};

export type SupplierPrices = {
	__typename?: 'SupplierPrices';
	supplierName?: Maybe<Scalars['String']>;
	quantity: Scalars['Float'];
	prixCalage?: Maybe<Scalars['Float']>;
	prixHorsCalage?: Maybe<Scalars['Float']>;
	prixExemplaireSupp?: Maybe<Scalars['Float']>;
	autrePrixFixe?: Maybe<Scalars['Float']>;
	prixChangementPlaque?: Maybe<Scalars['Float']>;
	prixTraitementFichier?: Maybe<Scalars['Float']>;
	prixCliche?: Maybe<Scalars['Float']>;
	prixOutillage?: Maybe<Scalars['Float']>;
	prixPapierKg?: Maybe<Scalars['Float']>;
	nbPosesMax?: Maybe<Scalars['Float']>;
	poidsUnitaireGr?: Maybe<Scalars['Float']>;
	delais?: Maybe<Scalars['Float']>;
	franco?: Maybe<Scalars['Boolean']>;
	productId?: Maybe<Scalars['String']>;
	supplierId: Scalars['String'];
	validityDate?: Maybe<Scalars['DateTime']>;
	product?: Maybe<Product>;
	totalPAHT?: Maybe<Scalars['Float']>;
	totalPAHTWithRFA?: Maybe<Scalars['Float']>;
	rfa?: Maybe<Scalars['Float']>;
	departingDepartment?: Maybe<Scalars['String']>;
	supplier: Supplier;
	boxesInfo?: Maybe<Array<BoxesInfo>>;
};

export type SupplierPricesForOrderItemInput = {
	productId: Scalars['String'];
	quantityGlobal: Scalars['Float'];
	supportId: Scalars['String'];
	numberVersion: Scalars['Float'];
	distributions?: Maybe<Array<Scalars['Float']>>;
};

export type SupplierUpdateInput = {
	rythme?: Maybe<Rythme>;
	name?: Maybe<Scalars['String']>;
	isActif?: Maybe<Scalars['Boolean']>;
	companyBase?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	fax?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	website?: Maybe<Scalars['String']>;
	comment?: Maybe<Scalars['String']>;
	tvaNum?: Maybe<Scalars['String']>;
	tvaType?: Maybe<Scalars['Float']>;
	maxPalletWeight?: Maybe<Scalars['Float']>;
	updatedBy?: Maybe<Scalars['String']>;
	escomptes?: Maybe<Array<EscompteInput>>;
	rsp?: Maybe<Scalars['Float']>;
	rfa?: Maybe<Scalars['Float']>;
	rspStart?: Maybe<Scalars['DateTime']>;
	rspEnd?: Maybe<Scalars['DateTime']>;
	expressMode?: Maybe<Scalars['Boolean']>;
	urgentMode?: Maybe<Scalars['Boolean']>;
	expressModeSupplement?: Maybe<Scalars['Float']>;
	urgentModeSupplement?: Maybe<Scalars['Float']>;
	discounts?: Maybe<Array<DiscountInput>>;
	transportModes?: Maybe<Array<TransportModeInput>>;
	siteAddress1?: Maybe<AddressInput>;
	siteAddress2?: Maybe<AddressInput>;
	user?: Maybe<UserUpdateInput>;
};

export type Support = {
	__typename?: 'Support';
	category?: Maybe<SupportCategory>;
	pricesSource?: Maybe<PricesSource>;
	clariPrintCorrespondence?: Maybe<ClariPrintCorrespondence>;
	id: Scalars['String'];
	name: Scalars['String'];
	excludingEcotax: Scalars['Boolean'];
	isActif?: Maybe<Scalars['Boolean']>;
	supportAttributes?: Maybe<Array<Attribute>>;
	printAttributes?: Maybe<Array<PrintValue>>;
	bigShopConfig?: Maybe<BigShopConfig>;
	products?: Maybe<Array<Product>>;
	fabricationTimes?: Maybe<Array<FabricationTime>>;
};

export enum SupportCategory {
	Plv = 'PLV',
	Print = 'Print',
	Roto = 'Roto',
	Digital = 'Digital',
}

export type SupportFiltersInput = {
	clientPriceStatus?: Maybe<ClientPriceStatus>;
	clientId?: Maybe<Scalars['String']>;
	withBigShopConfig?: Maybe<Scalars['Boolean']>;
	isActif?: Maybe<Scalars['Boolean']>;
};

export type SupportSearchInput = {
	category?: Maybe<SupportCategory>;
};

export type SupportSortOrder = {
	category?: Maybe<SortOrder>;
};

export type SupportUpdateInput = {
	pricesSource: PricesSource;
	clariPrintCorrespondence?: Maybe<ClariPrintCorrespondence>;
	category?: Maybe<SupportCategory>;
	supportId: Scalars['String'];
	excludingEcotax: Scalars['Boolean'];
	isActif: Scalars['Boolean'];
	attributesIds: Array<Scalars['String']>;
	fabricationTimes?: Maybe<Array<FabricationTimeInput>>;
};

export type TemplateName = {
	templateName: TemplateNameEnum;
};

export enum TemplateNameEnum {
	Store = 'Store',
	ClientPrice = 'ClientPrice',
}

export enum TerritorialArea {
	Urban = 'Urban',
	PeriUrbanArea = 'PeriUrbanArea',
	Rural = 'Rural',
	PeriUrbanAreaRural = 'PeriUrbanAreaRural',
}

export type TransportModeInput = {
	title?: Maybe<Scalars['String']>;
};

export type TransportModeObjectType = {
	__typename?: 'TransportModeObjectType';
	title?: Maybe<Scalars['String']>;
};

export enum TransportMoment {
	InWeek = 'InWeek',
	Saturday = 'Saturday',
}

export enum TransportType {
	DeliveryByParcel = 'deliveryByParcel',
	TrasnportBySupplier = 'trasnportBySupplier',
	WithoutTransport = 'withoutTransport',
}

export type Transporter = {
	__typename?: 'Transporter';
	id: Scalars['String'];
	name: Scalars['String'];
	saturdayDeliveryExtra: Scalars['Float'];
	nonMechanisableParcel: Scalars['Float'];
	nonStandardParcel: Scalars['Float'];
	corsicaDeliveryExtra: Scalars['Float'];
	maxMechanisableParcelLengthCm: Scalars['Float'];
	maxMechanisableParcelWidthCm: Scalars['Float'];
	minWeightKg: Scalars['Float'];
	fuelSurchargeRate: Scalars['Float'];
	standardDeliveryPrices: Array<Scalars['Float']>;
	expressDeliveryPrices: Array<Scalars['Float']>;
	isActif: Scalars['Boolean'];
	nonRegularPickup: Scalars['Float'];
	supplementOverThirtyKg: Scalars['Float'];
	hardlyAccessibleArea: Scalars['Float'];
	hardlyAccessibleAreas: Array<HardlyAccessibleAreasTransporter>;
};

export type TransporterFilterInput = {
	isActif?: Maybe<Scalars['Boolean']>;
};

export type TransporterIdentity = {
	__typename?: 'TransporterIdentity';
	id: Scalars['String'];
	name: Scalars['String'];
};

export type TransporterUpdateInput = {
	id: Scalars['String'];
	name: Scalars['String'];
	saturdayDeliveryExtra: Scalars['Float'];
	nonMechanisableParcel: Scalars['Float'];
	nonStandardParcel: Scalars['Float'];
	corsicaDeliveryExtra: Scalars['Float'];
	maxMechanisableParcelLengthCm: Scalars['Float'];
	maxMechanisableParcelWidthCm: Scalars['Float'];
	minWeightKg: Scalars['Float'];
	fuelSurchargeRate: Scalars['Float'];
	standardDeliveryPrices: Array<Scalars['Float']>;
	expressDeliveryPrices: Array<Scalars['Float']>;
	hardlyAccessibleAreas: Array<HardlyAccessibleAreasTransporterInput>;
	isActif: Scalars['Boolean'];
	nonRegularPickup: Scalars['Float'];
	supplementOverThirtyKg: Scalars['Float'];
	hardlyAccessibleArea: Scalars['Float'];
};

export type TransportersPricesForOrderItemInput = {
	clientId: Scalars['String'];
	productId: Scalars['String'];
	numberVersion: Scalars['Float'];
	quantity: Scalars['Float'];
	distributions?: Maybe<Array<Scalars['Float']>>;
};

export type TransportersPricesModel = {
	__typename?: 'TransportersPricesModel';
	transporterId: Scalars['String'];
	transporterName: Scalars['String'];
	transporterRfaRate: Scalars['Float'];
	transporterPurchasePriceExcludingVAT: Scalars['Float'];
	transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
};

export type UniqClientPriceInput = {
	transportMoment?: Maybe<TransportMoment>;
	deliveryParcel?: Maybe<BoxShippingMode>;
	status?: Maybe<ClientPriceStatus>;
	clientId: Scalars['String'];
	productId: Scalars['String'];
	numberVersion: Scalars['Float'];
	quantity: Scalars['Float'];
	globalQuantity?: Maybe<Scalars['Float']>;
	supplierId?: Maybe<Scalars['String']>;
	transporterId?: Maybe<Scalars['String']>;
};

export type UpsertOrderInput = {
	status?: Maybe<OrderStatus>;
	billingAddress?: Maybe<ClientQuoteBillingAddressInput>;
	id?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	elepvContactName?: Maybe<Scalars['String']>;
	elpevContactEmail?: Maybe<Scalars['String']>;
	elpevContactPhone?: Maybe<Scalars['String']>;
	clientName: Scalars['String'];
	marginWithClientRfa?: Maybe<Scalars['Float']>;
	realMargin?: Maybe<Scalars['Float']>;
	version?: Maybe<Scalars['Float']>;
	clientId: Scalars['String'];
	billingEntity?: Maybe<Scalars['String']>;
	operationName?: Maybe<Scalars['String']>;
	clientReference?: Maybe<Scalars['String']>;
	orderItem?: Maybe<Array<OrderItemUpsertInput>>;
	orderItemIdsToDelete: Array<Scalars['String']>;
	isDistribution: Scalars['Boolean'];
	orderDistributions?: Maybe<Array<OrderDistributionsUpsertInput>>;
	fileReferenceId?: Maybe<Scalars['String']>;
	clientQuoteId?: Maybe<Scalars['String']>;
	buyingPriceHt?: Maybe<Scalars['Float']>;
	buyingPriceHtWithRfa?: Maybe<Scalars['Float']>;
	sellingPriceHt?: Maybe<Scalars['Float']>;
	marginRate?: Maybe<Scalars['Float']>;
	isCreatePurchaseOrders?: Maybe<Scalars['Boolean']>;
	tvaRate?: Maybe<Scalars['Float']>;
	priceSupplyClientId?: Maybe<Scalars['String']>;
};

export type User = {
	__typename?: 'User';
	id: Scalars['String'];
	email: Scalars['String'];
	initials?: Maybe<Scalars['String']>;
	givenName: Scalars['String'];
	familyName: Scalars['String'];
	phoneNumber: Scalars['String'];
	canViewSalePrices: Scalars['Boolean'];
	canViewBuyingPrices: Scalars['Boolean'];
	replacedById?: Maybe<Scalars['String']>;
	replacedFrom?: Maybe<Scalars['DateTime']>;
	replacedTo?: Maybe<Scalars['DateTime']>;
	role: Role;
	isActive: Scalars['Boolean'];
	userClients?: Maybe<Array<Client>>;
	supplierId?: Maybe<Scalars['String']>;
	supplier: Supplier;
};

export type UserCreateInput = {
	email: Scalars['String'];
	password: Scalars['String'];
	initials?: Maybe<Scalars['String']>;
	familyName: Scalars['String'];
	phoneNumber?: Maybe<Scalars['String']>;
	givenName: Scalars['String'];
	isActive: Scalars['Boolean'];
	canViewSalePrices: Scalars['Boolean'];
	canViewBuyingPrices: Scalars['Boolean'];
	replacedById?: Maybe<Scalars['String']>;
	replacedFrom?: Maybe<Scalars['DateTime']>;
	roleId?: Maybe<Scalars['String']>;
	replacedTo?: Maybe<Scalars['DateTime']>;
	clientIds?: Maybe<Array<Scalars['String']>>;
};

export type UserDetails = {
	__typename?: 'UserDetails';
	commercial?: Maybe<CommercialInitials>;
	responsable?: Maybe<ResponsableUserInitials>;
};

export type UserSearchInput = {
	givenName: StringFilter;
};

export type UserUpdateInput = {
	id?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	familyName?: Maybe<Scalars['String']>;
	initials?: Maybe<Scalars['String']>;
	givenName?: Maybe<Scalars['String']>;
	phoneNumber?: Maybe<Scalars['String']>;
	isActive: Scalars['Boolean'];
	roleId?: Maybe<Scalars['String']>;
	canViewSalePrices?: Maybe<Scalars['Boolean']>;
	canViewBuyingPrices?: Maybe<Scalars['Boolean']>;
	replacedById?: Maybe<Scalars['String']>;
	replacedFrom?: Maybe<Scalars['DateTime']>;
	replacedTo?: Maybe<Scalars['DateTime']>;
	clientIds?: Maybe<Array<Scalars['String']>>;
};

export type WorkingDay = {
	__typename?: 'WorkingDay';
	day: Scalars['String'];
	isWorkingDay?: Maybe<Scalars['Boolean']>;
	start?: Maybe<Scalars['String']>;
	end?: Maybe<Scalars['String']>;
};

export type WorkingDayInput = {
	day?: Maybe<Scalars['String']>;
	isWorkingDay?: Maybe<Scalars['Boolean']>;
	start?: Maybe<Scalars['String']>;
	end?: Maybe<Scalars['String']>;
};

export enum ClientQuoteStatus {
	InProgress = 'InProgress',
	Validated = 'Validated',
	Waiting = 'Waiting',
	Ready = 'Ready',
	Canceled = 'Canceled',
}

export type OrderDistributions = {
	__typename?: 'orderDistributions';
	id: Scalars['String'];
	clientId: Scalars['String'];
	clientShippingAddressId: Scalars['String'];
};
