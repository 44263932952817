import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from 'graphql/fragments/product/productFragment';
import { CLIENT_PRICE_FRAGMENT } from '../../graphql/fragments/client-price/clientPriceFragment';
import ATTRIBUT_FRAGMENT from '../../graphql/fragments/attribute/attributeFragment';
import MARGIN_FRAGMENT from '../../graphql/fragments/margins/marginsFragment';

export const GET_CLIENT_PRICE_BY_CLIENT_ID_AND_SUPPORT_ID = gql`
	query ClientPricesByClientIdAndSupportId($clientId: String!, $supportId: String!) {
		clientPrices: clientPricesByClientIdAndSupportId(clientId: $clientId, supportId: $supportId) {
			createdAt
			cost
			defaultCommercialProductMargin
			defaultCommercialShippingMargin
			deliveryPackagePurchasePriceInfo
			franco
			isPrepress
			mainRfaRate
			nbShippingPoints
			status
			updatedAt
			productName
			clientId
			productId
			quantity
			transportPurchasePriceExcludingVAT
			transportSalesPriceExcludingVAT
			productSalesPriceExcludingVAT
			productPurchasePriceExcludingVAT
			validatedQuoteDate
			numberPlateChanges
			numberFileProcessing
			numberVersion
			numberShots
			tools
			isWithRfaClient
			productPurchasePriceExcludingVATWithRFA
			transportSellingPriceHtWithClientRfa
		}
	}
`;

export const GET_CLIENT_PRICE_EXISTING_BY_PRODUCT_NAME = gql`
	query ClientPricesByProductNameAndClientId($filters: ClientPricesFiltersInput!) {
		clientPricesExisting: clientPricesByProductNameAndClientId(filters: $filters) {
			quantity
			numberVersion
			transportPurchasePriceExcludingVAT
			transportSalesPriceExcludingVAT
			transportSellingPriceHtWithClientRfa
			transportSellingPriceHtWithClientRfaRectified
			transportPurchasePriceHt
			transportMoment
			transportSellingPriceHtAdditionalMargin
			transportType
			transportRfa
			transportSellingPriceHt
			transportSellingPriceMargin
			transportSellingPriceByDefaultHt
			transportPurchasePriceHtWithRfa
			productSalesPriceExcludingVAT
			productPurchasePriceExcludingVAT
			deliveryPackagePurchasePriceInfo
			cost
			productName
			productId
			nbShippingPoints
			franco
			status
			numberPlateChanges
			numberFileProcessing
			numberShots
			tools
			sellingPriceMargin
			sellingPriceMarginRate
			productPurchasePriceExcludingVATWithRFA
			deliveryParcel
			transportMoment
			transportType
			productTotalWeight
			nbShippingPoints
			rfaClientEuro
			corsicaDeliveryExtra
			isMechanizable
			isFinalDistribution
			transporter {
				id
				name
			}
			margins {
				...Margins
			}
			sellingPriceWithMargin {
				...Margins
			}
			product {
				support {
					name
					pricesSource
				}
			}
			sellingPriceWithMargin {
				prixCalage
				prixHorsCalage
				prixExemplaireSupp
				prixChangementPlaque
				prixTraitementFichier
				autrePrixFixe
				prixCliche
				prixOutillage
			}
		}
	}
	${MARGIN_FRAGMENT}
`;

export const EXPORT_CLIENTS_PRICES = gql`
	query ExportClientsPrices($supportId: String!, $filters: AttributesFilterInput!) {
		exportClientsPrices(data: { supportId: $supportId, attributes: $filters })
	}
`;

export const GET_CLIENT_PRICES_BY_PRODUCTS_ID = gql`
	query GetClientPricesByProductsId($productsId: [String!]!) {
		clientPricesByProductsId(productsId: $productsId) {
			name
			clientPrices {
				productId
				quantity
				productName
				productPurchasePriceExcludingVAT
				productSalesPriceExcludingVAT
				transportPurchasePriceExcludingVAT
				transportSalesPriceExcludingVAT
				salePriceExcludingVAT
				defaultCommercialProductMargin
				cost
				nbShippingPoints
				sellingPriceMargin
				product {
					codeAt
				}
			}
		}
	}
`;

export const GET_ALL_CLIENTS_PRICES = gql`
	query GetAllClientsPrices {
		allClientsPrices {
			name
			id
			clientPrices {
				productName
				quantity
				status
				productId
				numberVersion
				product {
					support {
						id
						name
					}
				}
				isIdentical
				quantities
			}
		}
	}
`;

export const GET_ALL_CLIENTS_PRICES_WITH_PAGINATION = gql`
	query clientPricesWhitPaginantion(
		$search: ClientPricesSearchInput
		$sort: ClientPriceSortInput
		$pagination: PaginationInput
	) {
		clientPricesWhitPaginantion(search: $search, sort: $sort, pagination: $pagination) {
			clientPrices {
				productName
				quantity
				status
				numberVersion
				isPrepress
				productSalesPriceExcludingVAT
				transportSalesPriceExcludingVAT
				cost
				client {
					id
					name
				}
				product {
					id
					support {
						category
					}
				}
			}
			total
		}
	}
`;

export const GET_CLIENT_PRICES = gql`
	query ClientPrices($filters: ClientPricesFiltersInput!) {
		clientPrices(filters: $filters) {
			productName
			productId
			quantity
			status
			isWithRfaClient
			productPurchasePriceExcludingVATWithRFA
			transportSellingPriceHtWithClientRfa
			product {
				...Product
			}
		}
	}
	${PRODUCT_FRAGMENT}
`;

export const GET_CLIENT_PRICE = gql`
	query ClientPrice($filters: UniqClientPriceInput!) {
		clientPrice(filters: $filters) {
			createdAt
			updatedAt
			isWithRfaClient
			numberVersion
			buyingPriceWithAmalgam
			numberPlateChanges
			numberFileProcessing
			numberShots
			tools
			deliveryParcel
			deliveryDepartment
			productTotalWeight
			transportMoment
			isMechanizable
			bestDeliveryParcelPurchasePrice
			productTotalWeight
			maxBoxWeight
			deliveryParcel
			transportPurchasePriceHt
			transportMoment
			transportSellingPriceHtAdditionalMargin
			transportType
			transportRfa
			transportSellingPriceHt
			transportSellingPriceMargin
			transportSellingPriceByDefaultHt
			transportPurchasePriceHtWithRfa
			corsicaDeliveryExtra
			isFinalDistribution
			isIncluded
			validatedQuoteDate
			productSalesPriceExcludingVATRectified
			productSalesPriceExcludingVATWithRfa
			totalProductSalesPriceExcludingVAT
			sellingPriceMarginRate
			rfaClientEuro
			productPurchasePriceExcludingVATWithRFA
			transportSellingPriceHtWithClientRfa
			transportSellingPriceHtWithClientRfaRectified
			isIdentical
			quantities
			sellingPriceMargin
			additionalMargins {
				prixCalage
				prixHorsCalage
				prixExemplaireSupp
				prixChangementPlaque
				prixTraitementFichier
				autrePrixFixe
				prixCliche
				prixOutillage
				distributions {
					zip
					quantity
				}
			}
			transporter {
				id
				name
			}
			margins {
				...Margins
			}
			purchasePriceExcludingTax {
				...Margins
			}
			sellingPriceWithMargin {
				...Margins
			}
			...ClientPrice
			product {
				...Product
				support {
					name
					pricesSource
					category
					supportAttributes {
						...Attribute
					}
					printAttributes {
						printValue
						attributeId
						printName
						attribute {
							...Attribute
						}
					}
					products {
						...Product
					}
				}
			}
		}
	}
	${CLIENT_PRICE_FRAGMENT}
	${PRODUCT_FRAGMENT}
	${ATTRIBUT_FRAGMENT}
	${MARGIN_FRAGMENT}
`;

export const GET_CLIENT_PRICE_WITH_CUSTOM_QUANTITY = gql`
	query GetClientPriceWithCustomQuantity($filters: UniqClientPriceInput!) {
		clientPrice: clientPriceWithCustomQuantity(filters: $filters) {
			isWithRfaClient
			numberVersion
			buyingPriceWithAmalgam
			numberPlateChanges
			numberFileProcessing
			numberShots
			tools
			deliveryParcel
			deliveryDepartment
			productTotalWeight
			transportMoment
			isMechanizable
			bestDeliveryParcelPurchasePrice
			productTotalWeight
			maxBoxWeight
			deliveryParcel
			transportSellingPriceHtWithClientRfa
			transportSellingPriceHtWithClientRfaRectified
			transportPurchasePriceHt
			transportMoment
			transportSellingPriceHtAdditionalMargin
			transportType
			transportRfa
			transportSellingPriceHt
			transportSellingPriceMargin
			transportSellingPriceByDefaultHt
			transportPurchasePriceHtWithRfa
			corsicaDeliveryExtra
			isFinalDistribution
			validatedQuoteDate
			productSalesPriceExcludingVATRectified
			productSalesPriceExcludingVATWithRfa
			totalProductSalesPriceExcludingVAT
			sellingPriceMarginRate
			rfaClientEuro
			productPurchasePriceExcludingVATWithRFA
			transportSellingPriceHtWithClientRfa
			transportSellingPriceHtWithClientRfaRectified
			isIdentical
			quantities
			sellingPriceMargin
			sellingPriceWithMargin {
				...Margins
			}
			additionalMargins {
				prixCalage
				prixHorsCalage
				prixExemplaireSupp
				prixChangementPlaque
				prixTraitementFichier
				autrePrixFixe
				prixCliche
				prixOutillage
				distributions {
					zip
					quantity
				}
			}
			transporter {
				id
				name
			}
			margins {
				...Margins
			}
			purchasePriceExcludingTax {
				...Margins
			}
			product {
				support {
					name
					pricesSource
				}
			}
			...ClientPrice
		}
	}
	${CLIENT_PRICE_FRAGMENT}
	${MARGIN_FRAGMENT}
`;

export const GET_FABRICATION_TIMES = gql`
	query FabricationTimes($input: FabricationTimeBySupportInput!) {
		fabricationTimes(input: $input) {
			expressMode
			standardMode
		}
	}
`;
