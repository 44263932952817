import { FollowOrderStatus } from '../../../../graphql/types';

export enum FollowStatusListDisabled {
	WaitingValidation = 'En attente de validation',
	Refused = 'Refusée',
}

export const FollowStatusListNotDisabled = [
	{ key: FollowOrderStatus.ProductionInProgress, value: 'En cours de production' },
	{ key: FollowOrderStatus.DeliveryInProgress, value: 'En cours de livraison' },
	{ key: FollowOrderStatus.Delivered, value: 'Livrée' },
	{ key: FollowOrderStatus.Billed, value: 'Facturée' },
	{ key: FollowOrderStatus.WaitingValidation, value: 'En attente de validation' },
	{ key: FollowOrderStatus.Validated, value: 'Validée' },
	{ key: FollowOrderStatus.Refused, value: 'Refusée' },
];
