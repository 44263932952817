import { Column, useTable } from 'react-table';
import { ClientInvoicesTable } from '../ClientInvoices';
import React, { useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import { ClientBillingTableByDate } from '../component/ClientBillingTableByDate';
import { ManagementInvoice } from '../ManagementInvoice';
import { ShowInvoiceStatus } from '../component/ShowInvoiceStatus';

export const useClientInvoiceTable = (invoices: ClientInvoicesTable[]) => {
	const nameField = useCallback((id: string) => `childrenOrder[${id}].invoices[0]`, []);

	const columns = useMemo<Column<ClientInvoicesTable>[]>(
		() => [
			{
				accessor: 'magasin',
				width: 150,
				Header: () => <Typography>Magasin</Typography>,
				Cell: ({ row }) => <Typography>{row.original?.magasin ?? ''}</Typography>,
			},
			{
				accessor: 'createdAt',
				width: 193,
				Header: () => <Typography>Date de création </Typography>,
				Cell: ({ row }) => <ClientBillingTableByDate name={`${nameField(row.id)}.createdAt`} />,
			},
			{
				accessor: 'validateAt',
				width: 193,
				Header: () => <Typography>Date validation facture</Typography>,
				Cell: ({ row }) => <ClientBillingTableByDate name={`${nameField(row.id)}.validateAt`} />,
			},
			{
				accessor: 'sendAt',
				width: 193,
				Header: () => <Typography>Date d'envoi facture</Typography>,
				Cell: ({ row }) => <ClientBillingTableByDate name={`${nameField(row.id)}.sendAt`} />,
			},
			{
				accessor: 'status',
				width: 120,
				Header: () => <Typography>Etat de la facture</Typography>,
				Cell: ({ row }) => <ShowInvoiceStatus name={`${nameField(row.id)}.status`} />,
			},
			{
				accessor: 'icons',
				width: 130,
				Header: () => null,
				Cell: ({ row }) => <ManagementInvoice row={row} />,
			},
		],
		[nameField]
	);
	return useTable({
		columns,
		data: invoices,
		isBorderless: true,
	});
};
