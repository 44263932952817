import { useLazyQuery } from '@apollo/client';
import { EXPORT_CLIENTS_PRICES } from '../graphql/queries';
import { AttributesFilterInput } from '../../graphql/types';
import { ExportClientsPricesQuery, ExportClientsPricesQueryVariables } from '../graphql/queries.definitions';
import downloadFile from '../../utils/downloadFile';

const useExportClientPrices = (supportId: string, filters: AttributesFilterInput) => {
	const [exportClientPrices, { data }] = useLazyQuery<ExportClientsPricesQuery, ExportClientsPricesQueryVariables>(
		EXPORT_CLIENTS_PRICES,
		{
			variables: {
				supportId,
				filters,
			},
			fetchPolicy: 'network-only',
			onCompleted: (data) => {
				downloadFile(
					data?.exportClientsPrices,
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					`Extract PV OS, ${new Date().toLocaleString()} .xlsx`
				);
			},
		}
	);

	return { url: data?.exportClientsPrices, exportClientPrices };
};
export default useExportClientPrices;
