import * as React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box } from '@mui/material';
import MyGrid from '../../components/MyGrid/MyGrid';
import Datacorrector from './Datacorrector';
import Loading from '../../components/loading/Loading';
import colors from '../../app/theme/colors.scss';
import Deconix from './Deconix';
import ExportToXlsx from './ExportToXlsx';

export interface Data {
	grids?:
		| {
				[key: string]: any[];
		  }
		| undefined;
	supplier?: string | undefined;
	date?: string | undefined;
	step: string;
}

type props = {
	goTo: (step: string) => void;
	data: Data;
	setData: React.Dispatch<React.SetStateAction<Data>>;
};

function Displaydata(props: props) {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [excludeColDC, setExcludeColDC] = React.useState<Array<number>>([]);
	const goTo = (step: string) => {
		props.goTo(step);
	};

	const steps: Array<{ name: string; title: string; button: string; info?: string }> = [
		{
			name: 'formatting',
			title: 'Données aprés formatting',
			button: 'Lancer Data Corrector',
		},
		{
			name: 'Data Corrector 1',
			title: 'Sélectionner les colonnes à traiter dans Data Corrector',
			button: 'Exécuter Data Corrector',
			info: 'Merci de sélectionner les colonnes qui devront être traités par Data Corrector',
		},
		{
			name: 'Data Corrector 2',
			title: 'Données aprés Data Corrector',
			button: 'Exécuter Déconix',
			info: 'Vous pouvez maintenant effectuer des changements manuellement',
		},
		{
			name: 'Déconix',
			title: 'Données aprés Déconix',
			button: 'Lancer Unit convertor',
		},
		{
			name: 'Unit convertor 1',
			title: 'Validation de unitées de mesures',
			button: 'Exécuter Unit convertor',
		},
		{
			name: 'Unit convertor 2',
			title: 'Validation de unitées de mesures',
			button: 'Lancer Fantastik',
		},
		{
			name: 'Fantastik',
			title: 'Validation des intitulés de champs',
			button: 'Exécuter Fantastik',
		},
		{
			name: 'Intégrer les données',
			title: 'Données entièrement traitées',
			button: 'Intégrer les données',
		},
	];

	let stepinfo = steps.find((sp) => sp.name === props.data.step);
	let stepinfoIndex = steps.findIndex((sp) => sp === stepinfo);

	const useStyles = makeStyles({
		buttons: { paddingLeft: '2vh' },
	});

	const classes = useStyles();

	return (
		<Box>
			<Loading loading={loading} />
			<Grid container alignContent="center" direction="row-reverse" spacing={0}>
				<Grid item xs={4}></Grid>
				<Grid item xs={4} sm={5}>
					<Box textAlign="center" color={colors.primary}>
						<h2>Intégration des données fournisseurs </h2>
					</Box>
				</Grid>
			</Grid>
			<Grid container alignContent="center" direction="row-reverse" spacing={0}>
				<Grid item xs={4} sm={4}></Grid>
				<Grid item xs={4} sm={5}>
					<Box textAlign="center">
						<h3>{stepinfo?.title} </h3>
					</Box>
				</Grid>
			</Grid>
			<Grid container direction="row-reverse" spacing={0}>
				<Grid item xs={4} sm={1}></Grid>
				<Grid item xs={4} sm={11}>
					<Box height="50vh">
						{(() => {
							switch (props.data.step) {
								case 'Data Corrector 1':
									return (
										<MyGrid
											data={props.data}
											setData={props.setData}
											empty={false}
											excludeColDC={excludeColDC}
											setExcludeColDC={setExcludeColDC}
											disabled={true}
										/>
									);
								case 'Data Corrector 2':
									return (
										<MyGrid
											data={props.data}
											setData={props.setData}
											empty={false}
											editCell={true}
											addCol={true}
											disabled={true}
										/>
									);
								default:
									return <MyGrid data={props.data} setData={props.setData} empty={false} disabled={true} />;
							}
						})()}
					</Box>
				</Grid>
			</Grid>

			<Grid container direction="row-reverse">
				<Grid item xs={4} sm={2}></Grid>
				<Grid item xs={4} sm={9}>
					<Box height="40px" textAlign="center" color={colors.redinfo}>
						<h4>{stepinfo?.info}</h4>
					</Box>
				</Grid>
			</Grid>

			<Grid container direction="row-reverse" spacing={0}>
				<Grid item xs={3} sm={1}></Grid>

				<Grid item xs={4} sm={2} className={classes.buttons}>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={() => {
							if (props.data.step === 'formatting') {
								props.setData({ ...props.data, step: steps[stepinfoIndex + 1].name });
							}
							if (props.data.step === 'Data Corrector 1') {
								setLoading(true);
								Datacorrector(props.data, excludeColDC, (data: { [key: string]: any[] }) => {
									setLoading(false);
									props.setData({ step: steps[stepinfoIndex + 1].name, grids: data });
								});
							}
							if (props.data.step === 'Data Corrector 2') {
								setLoading(true);
								const deconixData = props.data.grids ? Deconix(props.data.grids) : undefined;
								setLoading(false);
								props.setData({ step: steps[stepinfoIndex + 1].name, grids: deconixData });
							}
							if (props.data.step === 'Déconix') {
								props.setData({ step: steps[stepinfoIndex + 1].name, grids: props.data.grids });
							}
						}}
					>
						{steps[stepinfoIndex].button}
					</Button>
				</Grid>
				<Grid item xs={4} sm={2} className={classes.buttons}>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={() => {
							props.setData({ step: ' ' });
							goTo('home');
						}}
					>
						Enregistrer et quitter
					</Button>
				</Grid>
				<Grid item xs={4} sm={2} className={classes.buttons}>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={() => {
							let today = new Date().toLocaleString();
							if (props.data.grids)
								ExportToXlsx(props.data.grids, props.data.supplier + '_' + props.data.step + '_' + today);
						}}
					>
						Exporter
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Displaydata;
