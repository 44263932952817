import { gql } from '@apollo/client';

const GET_ROLES = gql`
	query GetRoles {
		roles {
			id
			name
			description
			users {
				id
				givenName
				familyName
				email
				phoneNumber
				isActive
			}
		}
	}
`;

const GET_ROLE_BY_ID = gql`
	query GetRoleByID($roleId: String!) {
		role(id: $roleId) {
			id
			name
			description
			permissions {
				key
				name
			}
			users {
				id
				givenName
				familyName
				email
				phoneNumber
				isActive
				replacedById
				replacedFrom
				replacedTo
			}
		}
	}
`;

export { GET_ROLES, GET_ROLE_BY_ID };
