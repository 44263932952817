import React, { useEffect, useState } from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import useCollapse from '../../../../hooks/useCollapse';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import DeliveryParcel from './DeliveryParcel';
import TransportTypeItem from './TransportTypeItem';
import { useFormikContext } from 'formik';
import {
	DELIVERY_BY_PARCEL,
	IsShowSellingPriceSelectedProps,
	TRANSPORT_BY_SUPPLIER,
	WITHOUT_TRANSPORT,
} from '../../definitions';
import { isTransportBySupplier } from '../../../helpers';
import { TransportType } from '../../../../graphql/types';
import { ClientPriceModel } from '../../ClientPriceForm';

interface TransportTypeProps extends IsShowSellingPriceSelectedProps {
	isClariPrint?: boolean;
	isPLV?: boolean;
	setSelectedTransportType: (label: string) => void;
	selectedTransportType: string;
	isConsultAndEditSellingPrice: boolean;
}

const TransportGroupType = ({
	selectedTransportType,
	setSelectedTransportType,
	isPLV,
	isConsultSellingPrice,
	isConsultAndEditSellingPrice,
}: TransportTypeProps): JSX.Element => {
	const {
		setFieldValue,
		values: { supplierPrices, franco, transportType },
	} = useFormikContext<ClientPriceModel>();

	const isConsultAndEdit =
		(isConsultSellingPrice && !isConsultAndEditSellingPrice) ||
		(!isConsultSellingPrice && isConsultAndEditSellingPrice);

	useEffect(() => {
		if (isConsultAndEdit) {
			setSelectedTransportType(
				transportType === TransportType.WithoutTransport
					? WITHOUT_TRANSPORT
					: transportType === TransportType.TrasnportBySupplier
					? TRANSPORT_BY_SUPPLIER
					: DELIVERY_BY_PARCEL
			);
		}
	}, [isConsultAndEdit, setSelectedTransportType, transportType]);

	const isTransportBySupplierChecked = !supplierPrices ? franco : isTransportBySupplier(supplierPrices, isPLV);
	const isDeliveryParcel =
		selectedTransportType === DELIVERY_BY_PARCEL || (selectedTransportType === '' && !isTransportBySupplierChecked);
	const isTransportBySupplierType = selectedTransportType
		? selectedTransportType === TRANSPORT_BY_SUPPLIER
		: isTransportBySupplierChecked;

	const [displayCollapse, setDisplayCollapse] = useState<boolean>(
		isConsultAndEdit
			? transportType === TransportType.DeliveryByParcel && franco
			: !(selectedTransportType === WITHOUT_TRANSPORT) && !isTransportBySupplierType && isDeliveryParcel
	);

	const { renderCollapseComponent } = useCollapse(displayCollapse, setDisplayCollapse);

	const handleSelectedTransportType = (label: string) => {
		let franco = false;
		let transportType: TransportType = TransportType.DeliveryByParcel;
		switch (label) {
			case WITHOUT_TRANSPORT:
				transportType = TransportType.WithoutTransport;
				setDisplayCollapse(false);
				break;
			case TRANSPORT_BY_SUPPLIER:
				franco = true;
				transportType = TransportType.TrasnportBySupplier;
				setDisplayCollapse(false);
				break;
			default:
				setDisplayCollapse(true);
				break;
		}
		setFieldValue('franco', franco);
		setFieldValue('transportType', transportType);
		setSelectedTransportType(label);
	};

	const getCheckStatus = (label: string) => {
		switch (label) {
			case WITHOUT_TRANSPORT:
				return !franco && transportType === TransportType.WithoutTransport;
			case TRANSPORT_BY_SUPPLIER:
				return isConsultAndEdit
					? franco && transportType === TransportType.TrasnportBySupplier
					: isTransportBySupplierType;
			default:
				return isConsultAndEdit ? !franco && transportType === TransportType.DeliveryByParcel : isDeliveryParcel;
		}
	};

	return (
		<Box mt={7}>
			<DashedHeader>Type de transport</DashedHeader>
			<TransportTypeItem
				checked={getCheckStatus(WITHOUT_TRANSPORT)}
				onChange={handleSelectedTransportType}
				label={WITHOUT_TRANSPORT}
				disabled={isConsultSellingPrice}
			/>
			<TransportTypeItem
				checked={getCheckStatus(TRANSPORT_BY_SUPPLIER)}
				onChange={handleSelectedTransportType}
				label={TRANSPORT_BY_SUPPLIER}
				disabled={isConsultSellingPrice}
			/>
			<TransportTypeItem
				checked={getCheckStatus(DELIVERY_BY_PARCEL)}
				onChange={handleSelectedTransportType}
				label={DELIVERY_BY_PARCEL}
				disabled={isConsultSellingPrice}
			>
				{renderCollapseComponent(true)}
			</TransportTypeItem>
			<Collapse in={displayCollapse}>
				<DeliveryParcel
					isConsultAndEditSellingPrice={isConsultAndEditSellingPrice}
					isConsultSellingPrice={isConsultSellingPrice}
				/>
			</Collapse>
		</Box>
	);
};

export default TransportGroupType;
