import { useQuery } from '@apollo/client';
import { GET_ATTRIBUTES } from '../graphql/queries';
import { GetAttributesQuery, GetAttributesQueryVariables } from '../graphql/queries.definitions';

const useAttributesQuery = () => {
	const { data } = useQuery<GetAttributesQuery, GetAttributesQueryVariables>(GET_ATTRIBUTES);

	return { attributes: data?.attributes };
};

export default useAttributesQuery;
