import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import { Form, useFormikContext } from 'formik';
import colors from '../../app/theme/colors.scss';
import { HeadLines, useManualTabs } from '../../hooks/useManualTabs';
import { TAB_MENU_CLIENT_QUOTE } from '../definitions';
import GeneralInformation from './general-information/GeneralInformation';
import ClientInformation from './client-information/ClientInformation';
import { AddingClientPriceQuote } from './adding-client-price-quote/AddingClientPriceQuote';
import Quote from './quote/Quote';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import { ClientQuoteStatus } from '../../graphql/types';
import usePopin from '../../hooks/usePopin';
import { QuotePreview } from './quotePreview/QuotePreview';
import QuoteAttributeProvider from '../context/QuoteAttributesProvider';
import { useClientsByCurrentUser } from '../../clients/hooks/useClientsByCurrentUser';
import { ClientQuoteModel } from './definition';
import useClientQuoteUpsert from '../hooks/useClientQuoteUpsert';
import useStorage from '../../hooks/useStorage';
import Delivery from './delivery/Delivery';
import { PATH_NAMES } from 'Header/menus';

interface ClientQuoteFormProps {
	headlines: HeadLines[];
	nbShippingPoints?: number;
}

export const ClientQuoteForm = ({ headlines, nbShippingPoints }: ClientQuoteFormProps): JSX.Element => {
	const { id: quoteId } = useParams<{ id: string }>();
	const { clientsUsers, loading: loadingClientsUsers } = useClientsByCurrentUser(true);
	const { clientQuote, loading, handleClientQuoteSubmit } = useClientQuoteUpsert(quoteId);
	const { values } = useFormikContext<ClientQuoteModel>();
	const { removeDataLocalStorage } = useStorage();

	const paths = [
		{ name: PATH_NAMES.ClientQuote || '', href: '/clientQuotes' },
		!quoteId ? 'Création de devis' : quoteId,
	];

	const handleOpenQuotePreview = async () => {
		values && (await handleClientQuoteSubmit(values));
		openPopin();
	};

	const Buttons = () => (
		<Box>
			<Link to="/clientQuotes" style={{ textDecoration: 'none' }}>
				<Button color="primary" type="reset">
					Annuler
				</Button>
			</Link>
			<Button type="submit" variant="contained" color="primary">
				Sauvegarder
			</Button>
			{clientQuote?.status === ClientQuoteStatus.Ready && (
				<>
					<Button variant="contained" color="primary">
						Transformer en commande
					</Button>
					<Button variant="contained" color="primary">
						Créer un annule et remplace
					</Button>
				</>
			)}
			<Button variant="contained" color="primary" onClick={handleOpenQuotePreview}>
				Valider et générer le devis client
			</Button>
		</Box>
	);

	const { renderingTabs } = useManualTabs({
		tabs: TAB_MENU_CLIENT_QUOTE,
		paths: paths,
		headlines,
		fixedHeaderHeight: 258,
		ButtonAction: Buttons,
	});

	const handleClearStorage = () => removeDataLocalStorage('data');

	const [openPopin, renderQuotePreview, closePopin] = usePopin({
		headerTitle: '',
		maxWidth: 'xl',
		fullWidth: true,
		handleClearStorage,
	});

	if (loading) return <Loading loading={loading} />;

	return (
		<Form>
			<>
				{renderQuotePreview(
					<QuoteAttributeProvider clientsUsers={clientsUsers}>
						<QuotePreview handleCloseQuotePreview={closePopin} />
					</QuoteAttributeProvider>
				)}
				<Box
					position="fixed"
					width="100%"
					top={40}
					left={0}
					px={11.75}
					bgcolor={colors.bodyBackgroundLight}
					zIndex={100}
				>
					{renderingTabs()}
				</Box>
				<Box mt={30} mx={7}>
					<div ref={TAB_MENU_CLIENT_QUOTE[0].ref}>
						<GeneralInformation />
					</div>
					<div ref={TAB_MENU_CLIENT_QUOTE[1].ref}>
						<Divider />
						<ClientInformation clientsUsers={clientsUsers} loadingClientsUsers={loadingClientsUsers} />
					</div>
					<div ref={TAB_MENU_CLIENT_QUOTE[2].ref}>
						<Divider />
						<AddingClientPriceQuote />
					</div>
					<div ref={TAB_MENU_CLIENT_QUOTE[3].ref}>
						<Divider />
						<Delivery nbShippingPoints={nbShippingPoints} />
					</div>
					<div ref={TAB_MENU_CLIENT_QUOTE[4].ref}>
						<Divider />
						<Quote />
					</div>
				</Box>
			</>
		</Form>
	);
};
