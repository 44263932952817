import React, { useState } from 'react';
import { Box, Button, Collapse, Divider, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useStylesTransportColis } from './styles';
import DashedHeader from '../../../../components/DashedHeader';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ButtonDisplayContent from '../../../../components/ButtonDisplayContent';
import colors from '../../../../app/theme/colors.scss';
import { LayoutField } from '../../../../components/form/LayoutField';
import FormikRadioField from '../../../../components/form/FormikRadioField';
import { boxShippingModeOptions } from '../../../../clients/form/delivery-information/definitions';
import { TRANSPORTMOMENT } from '../../../../clientPrice/form/definitions';
import { FormikSwitchField } from '../../../../components/form/FormikSwitchField';
import { FormikNumberField } from '../../../../components/form/FormikNumberField';
import { BootstrapInputCustom } from '../../../../components/BootstrapInputCustom';
import useTransportersQuery from '../../../../admin/transportRules/hooks/useTransportersQuery';
import { TransportColisType } from '../../order-cockpit/definition';
import { EditOutlined } from '@mui/icons-material';

export const TransportColis = ({ orderItem, handleEdit }: TransportColisType): JSX.Element => {
	const classes = useStylesTransportColis();
	const { transporters } = useTransportersQuery({ isActif: true });
	const [displayTransportColis, setDisplayTransportColis] = useState(false);

	return (
		<Grid xs={12} item>
			<Box className={classes.boxWrapper}>
				<DashedHeader>Transport</DashedHeader>
				<Box className={classes.wrapperTransport}>
					<Box className={classes.header}>
						<Box className={classes.iconWrapper}>
							<Box mx={2} pt={1}>
								<RadioButtonCheckedIcon htmlColor={colors.gray300} />
							</Box>
							<Box>
								<Typography>Transport colis</Typography>
							</Box>
						</Box>
						<Box mx={2}>
							<ButtonDisplayContent
								displayContent={displayTransportColis}
								OnDisplayContent={setDisplayTransportColis}
								isActive
							/>
						</Box>
					</Box>
					<Box>
						<Collapse in={displayTransportColis}>
							<Box px={1} my={2} display="flex" style={{ gap: '100px' }}>
								<Box display="flex">
									<Box mr={7} ml={1.5}>
										<LayoutField color={colors.gray700} label="Livraison transport par colis">
											<FormControl fullWidth>
												<FormikRadioField
													disabled
													value={orderItem?.deliveryParcel as string}
													name="deliveryParcel"
													options={boxShippingModeOptions}
												/>
											</FormControl>
										</LayoutField>
									</Box>
									<Box>
										<LayoutField color={colors.gray700} label="Moment">
											<FormControl fullWidth>
												<FormikRadioField
													disabled
													value={orderItem?.transportMoment as string}
													name="transportMoment"
													options={TRANSPORTMOMENT}
												/>
											</FormControl>
										</LayoutField>
										<Box display="flex" flexDirection="column">
											<Typography variant="h4" style={{ marginRight: '10px', color: colors.gray700 }}>
												Mécanisable
											</Typography>
											<FormikSwitchField value={orderItem?.isMechanizable as boolean} disabled name="isMechanizable" />
										</Box>
									</Box>
								</Box>
								<Divider orientation="vertical" flexItem />
								<Box>
									<LayoutField color={colors.gray700} label="Prix achat transport colis HT (€)">
										<Box display="flex">
											<FormikNumberField
												decimalScale={2}
												name="bestDeliveryParcelPurchasePrice"
												value={orderItem?.bestDeliveryParcelPurchasePrice ?? 0.0}
												width="164px"
												variant="outlined"
												disabled
												bgColor={colors.grey}
											/>
										</Box>
									</LayoutField>
									<LayoutField color={colors.gray700} label="Transporteur" mt={5}>
										<Box width="90%" display="flex">
											<FormControl fullWidth>
												<Select
													disabled
													name={'selectedTransporterName'}
													input={<BootstrapInputCustom />}
													variant="outlined"
													value={orderItem?.bestTransporterId ?? ''}
												>
													{transporters?.map((transporter) => (
														<MenuItem value={transporter.id} key={transporter.name}>
															{transporter.name.toUpperCase()}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											{handleEdit ? (
												<Button variant="text" onClick={() => handleEdit()}>
													<EditOutlined />
												</Button>
											) : null}
										</Box>
									</LayoutField>
								</Box>
							</Box>
						</Collapse>
					</Box>
				</Box>
			</Box>
		</Grid>
	);
};
