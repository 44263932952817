export const getParcelTransportRules = (transporterIndex: number) => [
	{
		rules: [
			{
				label: 'Taux de surcharge carburant (en %)',
				name: `transporters[${transporterIndex}].fuelSurchargeRate`,
				decimalScale: 2,
				maxValue: 100,
			},
			{
				label: 'Supplément livraison le samedi (€)',
				name: `transporters[${transporterIndex}].saturdayDeliveryExtra`,
				decimalScale: 2,
			},
			{
				label: 'Supplément livraison Corse (€/colis)',
				name: `transporters[${transporterIndex}].corsicaDeliveryExtra`,
				decimalScale: 2,
			},
		],
	},
	{
		rules: [
			{
				label: 'Colis non mécannisable (€/colis)',
				name: `transporters[${transporterIndex}].nonMechanisableParcel`,
				decimalScale: 2,
			},
			{
				label: 'Longueur maxi colis mécanisable (cm)',
				name: `transporters[${transporterIndex}].maxMechanisableParcelLengthCm`,
				decimalScale: 0,
			},
			{
				label: 'Largeur maxi colis mécanisable(cm)',
				name: `transporters[${transporterIndex}].maxMechanisableParcelWidthCm`,
				decimalScale: 0,
			},
		],
	},
	{
		rules: [
			{
				label: 'Colis hors norme (€/colis)',
				name: `transporters[${transporterIndex}].nonStandardParcel`,
				decimalScale: 2,
			},
			{
				label: 'Poids supérieur à (kg)',
				name: `transporters[${transporterIndex}].minWeightKg`,
				decimalScale: 2,
			},
		],
	},
	{
		rules: [
			{
				label: 'Ramassage non régulier (€/enlèvement)',
				name: `transporters[${transporterIndex}].nonRegularPickup`,
				decimalScale: 2,
			},
			{
				label: 'Supplément au-delà de 30kg (€/Kg)',
				name: `transporters[${transporterIndex}].supplementOverThirtyKg`,
				decimalScale: 2,
			},
			{
				label: 'Zone difficilement accessible (€/colis)',
				name: `transporters[${transporterIndex}].hardlyAccessibleArea`,
				decimalScale: 2,
			},
		],
	},
];

export default getParcelTransportRules;
