import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DashedHeader from 'components/DashedHeader';
import ProductPriceDetails from '../components/ProductPriceDetails';
import ProductStoresDetails from './ProductStoresDetails';
import OrderTableCell from '../components/OrderTableCell';
import { useTableRowStyles } from '../sharedStyles';
import useCollapse from 'hooks/useCollapse';
import { getOrderItemByShippingAddress } from '../../../helpers';
import colors from 'app/theme/colors.scss';
import Typography from '@mui/material/Typography';
import { ChildOrderFormModel, OrderItemFormModel } from '../definition';
interface StoreProps {
	orderChild?: ChildOrderFormModel | null;
	orderChildIndex: number;
	parentOrderItem?: OrderItemFormModel[] | null;
	childrenOrder?: ChildOrderFormModel[] | null;
}

const Store = ({ orderChild, orderChildIndex, parentOrderItem, childrenOrder }: StoreProps): JSX.Element => {
	const { renderCollapseComponent, displayContent } = useCollapse(false);
	const classes = useTableRowStyles();
	const billingAddress = orderChild?.billingAddress;
	const fullBillingAddress = billingAddress
		? `${billingAddress.firstAddress ?? ''} - ${billingAddress.zipCode ?? ''} - ${billingAddress.city ?? ''}`
		: '';

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<OrderTableCell label={orderChild?.clientName} variant="h5" isFirst />
				<OrderTableCell label={fullBillingAddress} variant="h5" />
				<OrderTableCell width="70px" align="center">
					{renderCollapseComponent(true)}
				</OrderTableCell>
			</TableRow>
			<TableRow>
				<TableCell padding="none" colSpan={12} className={classes.tableCellDetails}>
					<Collapse in={displayContent}>
						<Box pt={3} pl={10} pr={10} mb={1}>
							<Box ml={10} color={colors.gray100}>
								<Typography variant="h5">Adresse de livraison</Typography>
							</Box>
							{getOrderItemByShippingAddress(orderChild)?.map((item, index) => {
								return (
									<Box key={index}>
										<ProductStoresDetails
											orderItem={item}
											orderChildIndex={orderChildIndex}
											parentOrderItem={parentOrderItem}
											childrenOrder={childrenOrder}
										/>
									</Box>
								);
							})}
							<DashedHeader>Total</DashedHeader>
							<ProductPriceDetails
								buyingPriceHt={orderChild?.buyingPriceHt}
								sellingPriceHt={orderChild?.sellingPriceHt}
								transportBuyingPriceHt={orderChild?.transportBuyingPriceHt}
								transportSellingPriceHt={orderChild?.transportSellingPriceHt}
								prepressPriceHt={orderChild?.prepressPriceHt}
								prepressSellingPriceHt={orderChild?.prepressSellingPriceHt}
								productSellingPriceHt={orderChild?.productSellingPriceHt}
								tvaRate={orderChild?.tvaRate}
								sellingPriceTtc={orderChild?.sellingPriceTtc}
								tva={orderChild?.tva}
							/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

export default Store;
