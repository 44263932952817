import { useMutation } from '@apollo/client';
import { UpdateSupportMutation, UpdateSupportMutationVariables } from '../graphql/mutations.definitions';
import { UPDATE_SUPPORT } from '../graphql/mutations';
import { ClariPrintCorrespondence, FabricationTimeInput, PricesSource, SupportCategory } from '../../../graphql/types';
import { GET_SUPPORT_BY_ID } from '../graphql/queries';

const useSupportUpdate = () => {
	const [updateSupportMutation] = useMutation<UpdateSupportMutation, UpdateSupportMutationVariables>(UPDATE_SUPPORT);

	const updateSupport = async (
		supportId: string,
		ecotaxe: boolean,
		category: SupportCategory | null,
		pricesSource: PricesSource,
		isActif: boolean,
		clariPrintCorrespondence: ClariPrintCorrespondence | null | undefined,
		attributesChecked: { attributeId: string; supportId: string }[],
		fabricationTimes: FabricationTimeInput[]
	) => {
		await updateSupportMutation({
			variables: {
				supportId,
				ecotaxe,
				category,
				pricesSource,
				isActif,
				clariPrintCorrespondence: pricesSource === PricesSource.ClariPrint ? clariPrintCorrespondence : null,
				attributesChecked: attributesChecked.map((attributeChecked) => attributeChecked.attributeId),
				fabricationTimes: fabricationTimes?.map((fabricationTime) => ({
					quantity: fabricationTime.quantity,
					expressMode: fabricationTime.expressMode,
					standardMode: fabricationTime.standardMode,
					urgentMode: fabricationTime.urgentMode,
				})),
			},
			refetchQueries: [
				{
					query: GET_SUPPORT_BY_ID,
					variables: {
						supportId,
					},
				},
			],
			awaitRefetchQueries: true,
		});
	};

	return { updateSupport };
};

export default useSupportUpdate;
