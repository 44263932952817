import { useQuery } from '@apollo/client';
import { GET_CLIENT_PRICES_BY_PRODUCTS_ID } from '../graphql/queries';
import {
	GetClientPricesByProductsIdQuery,
	GetClientPricesByProductsIdQueryVariables,
} from '../graphql/queries.definitions';

const useClientPricesByProductsId = (productsId: string[]) => {
	const { data, loading } = useQuery<GetClientPricesByProductsIdQuery, GetClientPricesByProductsIdQueryVariables>(
		GET_CLIENT_PRICES_BY_PRODUCTS_ID,
		{
			variables: { productsId },
			skip: productsId.length === 0,
		}
	);

	return { clients: data?.clientPricesByProductsId, loading };
};

export default useClientPricesByProductsId;
