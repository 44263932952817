import { Box, Button, IconButton } from '@mui/material';
import React from 'react';
import usePopin from 'hooks/usePopin';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { CharacteristicByProduct } from 'product/components/CharacteristicByProduct';
import useGetProductById from 'product/hooks/useGetProductById';

interface CharacteristicByProductDialogProps {
	productId?: string;
}

const CharacteristicByProductDialog = ({ productId }: CharacteristicByProductDialogProps): JSX.Element => {
	const { product } = useGetProductById(productId ?? '');
	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Caractéristique du produit',
		maxWidth: 'sm',
		fullWidth: true,
		isDisplayHeader: true,
		bottomFooter: (
			<Box flexDirection="row-reverse" display="flex" p={2}>
				<Button color="primary" onClick={() => closePopin()}>
					Annuler
				</Button>
			</Box>
		),
	});

	return (
		<>
			<IconButton color="primary" onClick={openPopin} disabled={!product} size="large">
				<HelpOutlinedIcon fontSize="small" />
			</IconButton>
			{renderPopin(
				<Box>
					{product && (
						<Box p={3}>
							<CharacteristicByProduct product={product} />
						</Box>
					)}
				</Box>
			)}
		</>
	);
};

export default CharacteristicByProductDialog;
