import React from 'react';
import Typography from '@mui/material/Typography';
import RetrieveElpevInformationButton from '../../components/buttons/RetrieveElpevInformationButton';
import Grid from '@mui/material/Grid';
import useSupplierFromBaseDBUpdate from '../hooks/useSupplierFromBaseDBUpdate';
import { useParams } from 'react-router-dom';
import { useField } from 'formik';

interface SupplierElpevProps {
	title: string;
}

const SupplierElpevFromBaseDB = ({ title }: SupplierElpevProps): JSX.Element | null => {
	const { updateSupplierFromBaseDB } = useSupplierFromBaseDBUpdate();
	const { id } = useParams<{ id: string }>();
	const [field] = useField<string | undefined | null>('companyBase');

	const handleImportSupplierFromBaseDB = async () => {
		if (field.value) {
			await updateSupplierFromBaseDB(field.value, id);
		}
	};

	return (
		<Grid item>
			<Typography variant="h2">{title}</Typography>
			<RetrieveElpevInformationButton handleClick={handleImportSupplierFromBaseDB} />
		</Grid>
	);
};

export default SupplierElpevFromBaseDB;
