import { useField } from 'formik';
import { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { TransporterType } from '../definitions';
import TransporterPricesCellField from '../form/fields/TransporterPricesCellField';
import TransporterPricesTitleCell from '../form/fields/TransporterPricesTitleCell';

const useTransportersPricesTable = (transporterIndex: number, transporterType: TransporterType) => {
	const [fieldIsActif] = useField<boolean>(`transporters[${transporterIndex}].isActif`);

	const columns = useMemo<Column<Record<number, number>>[]>(
		() => [
			{
				Header: 'Poids (kg)',
				Cell: TransporterPricesTitleCell,
			},
			...Array.from(Array(30)).map((_, columnIndex) => ({
				Header: (columnIndex + 1).toString(),
				Cell: TransporterPricesCellField(columnIndex, transporterIndex, transporterType, fieldIsActif.value),
			})),
		],
		[fieldIsActif.value, transporterIndex, transporterType]
	);

	let initialTransportersPrices: Record<number, number> = {};

	Array.from(Array(30)).forEach((_, weightIndex) => (initialTransportersPrices[weightIndex] = 0));

	return useTable({
		columns,
		data: [initialTransportersPrices],
	});
};

export default useTransportersPricesTable;
