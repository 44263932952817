import React, { useContext } from 'react';
import { Box, Grid, Typography, Card, FormControlLabel, Checkbox } from '@mui/material';
import colors from '../../../../app/theme/colors.scss';
import DashedHeader from '../../../../components/DashedHeader';
import { useQuotePreviewStyles } from '../styles';
import { ButtonType, CharacteristicsQuoteAttribute } from '../../../../graphql/types';
import { QuoteAttributeContext } from '../../../context/QuoteAttributesProvider';
import { QuoteAttributeContextProp } from '../../definition';
import FormikRadioField from '../../../../components/form/FormikRadioField';
import { groupByRadioButton } from '../../helpers';

interface CharacteristicsQuoteProps {
	index: number;
	length: number;
	familiesGroup: string;
	familyAttributes: { family: string; characteristics: CharacteristicsQuoteAttribute[] }[];
}

export const CharacteristicsQuote = ({
	index,
	length,
	familyAttributes,
	familiesGroup,
}: CharacteristicsQuoteProps): JSX.Element => {
	const classes = useQuotePreviewStyles();
	const context = useContext<QuoteAttributeContextProp | undefined>(QuoteAttributeContext);

	return (
		<Grid container>
			{index !== length && (
				<Grid xs={12} item>
					<DashedHeader>{familiesGroup}</DashedHeader>
				</Grid>
			)}
			<Grid xs={12} item>
				<Grid container justifyContent="space-between">
					{familyAttributes.map(({ family, characteristics }, indice) => (
						<Grid xs={2} item key={`${family}_${indice}`}>
							<Card>
								<Box
									bgcolor={colors.grayCard}
									p={2}
									textAlign="center"
									borderTop={6}
									borderRadius="4px"
									borderColor={colors.indigo700}
								>
									<Typography variant="h4">{family}</Typography>
								</Box>
							</Card>
							{groupByRadioButton(characteristics, family).map(
								({ options, isChecked, attributeId, name, defaultValue }, i) => {
									return (
										<Box key={i}>
											{!options ? (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															size="medium"
															color="primary"
															checked={isChecked}
															onChange={() => context?.handleCheckedAttributes(familiesGroup, attributeId, family)}
														/>
													}
													label={<Typography variant="h5">{name}</Typography>}
												/>
											) : (
												<FormikRadioField
													defaultValue={defaultValue}
													name={name}
													options={options}
													onChange={(_, value) => {
														context?.handleRadioAttributes(value, options, ButtonType.Radio);
													}}
												/>
											)}
										</Box>
									);
								}
							)}
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
