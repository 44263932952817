import { gql } from '@apollo/client';
import ADDRESS_FRAGMENT from '../../graphql/fragments/address/addressFragment';

export const INSERT_CLIENT = gql`
	mutation InsertClient($client: ClientInsertInput!) {
		client: insertClient(client: $client) {
			id
			name
			isRoot
			type
			regionId
			typologyId
			parentClientId
			rootClientId
		}
	}
`;

export const INSERT_CLIENT_SHIPPING_ADDRESS = gql`
	mutation InsertClientShippingAddress($clientShippingAddress: ClientShippingAddressInsertInput!) {
		insertClientShippingAddress(clientShippingAddress: $clientShippingAddress) {
			clientId
			isMain
			boxShippingMode
			maxBoxWeight
			palletShippingMode
			comment
			contact {
				website
				storeCode
				phone
				fax
				email
				directorName
				isNotificated
			}
			workingDays {
				day
				start
				end
				isWorkingDay
			}
			address {
				...Address
			}
		}
	}
	${ADDRESS_FRAGMENT}
`;

export const GET_UPLOAD_URL = gql`
	mutation CreateClientLogoUploadUrl($id: String!, $mimetype: String!) {
		uploadUrl: createClientLogoUploadUrl(id: $id, mimetype: $mimetype)
	}
`;

export const DELETE_SHIPPING_ADDRESS_BY_ID = gql`
	mutation DeleteShippingAddressById($clientShippingAddressId: String!, $addressId: String!) {
		deleteShippingAddressById(clientShippingAddressId: $clientShippingAddressId, addressId: $addressId) {
			id
		}
	}
`;

export const UPDATE_CLIENT = gql`
	mutation UpdateClient($client: ClientUpdateInput!) {
		client: updateClient(client: $client) {
			id
			name
			isRoot
			isProspect
			isActif
			companyBase
			tradeNameInvoice
			transplantName
			siretNumber
			vatNumber
			vatType
			type
			regionId
			rcsNumber
			typologyId
			parentClientId
			rootClientId
			createdAt
			updatedAt
			commercialId
			responsibleId
			projectManagerId
			logo
			clientAccountingAccount
			clientAnalyticCode
			groupingCode
			clientCode
			fileCode
			billingAddress {
				...Address
			}

			structures {
				isBillable
				level
				title
				type
			}
			regions {
				id
				name
			}
			typologies {
				id
				name
			}
			clientContact {
				civility
				comment
				functions
				email
				id
				gsmPhone
				landlinePhone
				name
				privatePhone
				linkedIn
				inactif
				updatedAt
			}
			billingCadence
			possibleBillings
			defaultBilling
			isGroupedBilling
			isHorsRFA
			mainRfaRate
			rfaComment
			defaultCommercialProductMargin
			defaultCommercialShippingMargin
			clientsSupports {
				margin
				supportId
				marginType
				support {
					id
					name
					excludingEcotax
				}
			}
		}
	}
	${ADDRESS_FRAGMENT}
`;

export const UPDATE_CLIENT_BY_COMPANYBASE = gql`
	mutation UpdateClientByCompanyBase($companyBase: String!, $clientId: String!) {
		updateClientByCompanyBase(companyBase: $companyBase, clientId: $clientId) {
			companyBase
			siretNumber
			rcsNumber
			vatNumber
			vatType
			paymentDeadline
			clientAccountingAccount
			clientAnalyticCode
			users {
				commercial {
					fullName
					initials
				}
				responsable {
					fullName
					initials
				}
			}
			billingAddress {
				id
				isShipping
				name
				firstAddress
				secondAddress
				postBox
				zipCode
				city
				country
			}
			clientShippingAddress {
				boxShippingMode
				comment
				referenceId
				palletShippingMode
				maxBoxWeight
				isMain
				id
				clientId
				address {
					...Address
				}
				contact {
					directorName
					email
					fax
					isNotificated
					phone
					storeCode
					website
				}
				workingDays {
					day
					end
					isWorkingDay
					start
				}
			}
			clientContact {
				id
				civility
				comment
				functions
				email
				gsmPhone
				landlinePhone
				name
				privatePhone
				linkedIn
				inactif
				updatedAt
			}
			mainRfaRate
			defaultCommercialShippingMargin
		}
	}
	${ADDRESS_FRAGMENT}
`;

export const GENERATE_QUOTE_FILE = gql`
	mutation GenerateQuoteFile($filter: ClientQuoteGenerateFileInput!) {
		quoteFile: generateQuoteFile(filter: $filter) {
			url
			filename
		}
	}
`;
