import { isAfter, isBefore } from 'date-fns';
import { sortByKey } from 'utils/arrays';
import getPercentageValue from 'utils/getPercentageValue';
import { SupplierPricesOrderItemFromModel } from './definitions';

export const calculateAndSortSuppliersPrices = (suppliersPrices: SupplierPricesOrderItemFromModel[]) => {
	const formattedSuppliersPrices = suppliersPrices.map((item) => {
		const isRsp =
			item.supplier.rspEnd &&
			item.supplier.rspStart &&
			isAfter(new Date(), new Date(item.supplier.rspStart)) &&
			isBefore(new Date(), new Date(item.supplier.rspEnd));
		const totalSupplierPriceHT = item.totalPAHT ?? 0;
		const rsp = isRsp ? getPercentageValue(item.supplier.rsp ?? 0, item.totalPAHT ?? 0) : 0;
		const rfa = getPercentageValue(item.supplier.rfa ?? 0, item.totalPAHT ?? 0);
		const supplierPrice = totalSupplierPriceHT - rfa - rsp;

		return {
			supplierId: item.supplier.id,
			supplierPrice,
			supplierRspRate: item.supplier.rsp ?? 0,
			supplierRfaRate: item.supplier.rfa ?? 0,
			buyingPriceHtWithoutRspAndRfa: totalSupplierPriceHT,
			supplierName: item.supplier.name,
			supplierTotalPAHT: item?.totalPAHT ?? 0,
			deliveryDepartment: item.supplier.siteAddress1?.deliveryDepartment,
		};
	});

	return formattedSuppliersPrices ? sortByKey(formattedSuppliersPrices, 'supplierPrice') : [];
};
