import React from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';
import AppTable from '../../components/AppTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DisplayInterface } from './definitions';
import { useHistory } from 'react-router-dom';
import useCollapse from '../../hooks/useCollapse';

export interface UserItem {
	id: string;
	familyName: string;
	givenName: string;
	role: string;
	phoneNumber: string;
	email: string;
	isActive: boolean;
}
interface UsersListProps {
	users?: UserItem[];
	titleList?: string;
	isUserAddButtonDisplayed?: boolean;
	onDisplayFormUser: React.Dispatch<React.SetStateAction<DisplayInterface>>;
}
const UsersList = ({ users, titleList, onDisplayFormUser, isUserAddButtonDisplayed }: UsersListProps): JSX.Element => {
	const { renderCollapseComponent, displayContent } = useCollapse();

	const headCells: { key: string; label: string; sort: boolean }[] = [
		{ key: 'familyName', label: 'Nom', sort: true },
		{ key: 'givenName', label: 'Prénom', sort: true },
		{ key: 'role', label: 'Rôle', sort: true },
		{ key: 'phoneNumber', label: 'Téléphone', sort: true },
		{ key: 'email', label: 'Adresse mail', sort: true },
		{ key: 'isActive', label: 'Actif', sort: true },
	];
	const history = useHistory();

	return (
		<Box pt={3}>
			<Box display={'flex'} pb={2}>
				<Box display={'flex'} flexGrow={1}>
					<Box pr={2}>
						<Typography variant="h2">{titleList}</Typography>
					</Box>
					{renderCollapseComponent()}
				</Box>
				{isUserAddButtonDisplayed && (
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							history.push('/admin/userform/newUser');
							onDisplayFormUser(DisplayInterface.DisplayUserForm);
						}}
					>
						<AddCircleIcon />
						<Box pl={1}>Ajouter un utilisateur</Box>
					</Button>
				)}
			</Box>
			<Collapse in={displayContent}>
				<AppTable bodyCelles={users ?? []} headCells={headCells} pathRoute={'/admin/userform/'} />
			</Collapse>
		</Box>
	);
};

export default UsersList;
