import { useQuery } from '@apollo/client';
import { ClientQuoteFilterInput, ClientQuoteSortInput, PaginationInput } from 'graphql/types';
import { GET_CLIENT_QUOTES } from '../graphql/queries';
import { GetClientQuotesQuery, GetClientQuotesQueryVariables } from '../graphql/queries.definitions';

const useClientQuotesQuery = (
	filters?: ClientQuoteFilterInput,
	sorts?: ClientQuoteSortInput,
	pagination?: PaginationInput
) => {
	const { data, refetch, loading } = useQuery<GetClientQuotesQuery, GetClientQuotesQueryVariables>(GET_CLIENT_QUOTES, {
		fetchPolicy: 'cache-and-network',
		variables: { filters, sorts, pagination },
	});
	return {
		clientQuotes: data?.clientQuotes.clientQuotes,
		total: data?.clientQuotes.total,
		refetchClientQuotes: refetch,
		loading,
	};
};

export default useClientQuotesQuery;
