import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { Typography } from '@mui/material';
import { formatNumberDisplay } from '../../../../utils/prices';
import { Maybe } from 'graphql/types';

type SupplierPriceRow = Partial<{
	supplierName: string | Maybe<string>;
	prixCalage: number | Maybe<number>;
	prixHorsCalage: number | Maybe<number>;
	prixExemplaireSupp: number | Maybe<number>;
	prixChangementPlaque: number | Maybe<number>;
	autrePrixFixe: number | Maybe<number>;
	prixCliche: number | Maybe<number>;
	prixOutillage: number | Maybe<number>;
	nbPosesMax: number | Maybe<number>;
	totalPAHT: number | Maybe<number>;
	rfa: number | Maybe<number>;
	totalPAHTWithRFA: number | Maybe<number>;
	prixTraitementFichier: number | Maybe<number>;
}>;

export const useListCheapestSupplier = (supplierPrices: SupplierPriceRow[]) => {
	const cheapestSupplierPrice = supplierPrices.reduce((cheapestPrice, supplier) => {
		return supplier.totalPAHTWithRFA && supplier.totalPAHTWithRFA < cheapestPrice
			? supplier.totalPAHTWithRFA
			: cheapestPrice;
	}, Infinity);
	const columns = useMemo<Column<SupplierPriceRow>[]>(
		() => [
			{
				accessor: 'supplierName',
				width: 1200,
				Header: () => <Typography>Nom</Typography>,
				Cell: ({ value }) => <Typography>{value ?? ''}</Typography>,
			},
			{
				accessor: 'prixCalage',
				Header: () => <Typography>prix calage</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 2) : '-'}</Typography>,
			},
			{
				accessor: 'prixHorsCalage',
				Header: () => <Typography>Prix hors calage</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 2) : '-'}</Typography>,
			},
			{
				accessor: 'prixExemplaireSupp',
				Header: () => <Typography>Prix Ex.Sup.</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 5) : '-'}</Typography>,
			},
			{
				accessor: 'prixChangementPlaque',
				Header: () => <Typography>Prix changement par plaque</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 2) : '-'}</Typography>,
			},
			{
				accessor: 'autrePrixFixe',
				Header: () => <Typography>Autre prix fixe </Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 2) : '-'}</Typography>,
			},
			{
				accessor: 'prixCliche',
				Header: () => <Typography>Prix cliché</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 2) : '-'}</Typography>,
			},
			{
				accessor: 'prixOutillage',
				width: 120,
				Header: () => <Typography>Prix outillage</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 2) : '-'}</Typography>,
			},
			{
				accessor: 'nbPosesMax',
				width: 120,
				Header: () => <Typography>Nb de pose maxi</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value) : '-'}</Typography>,
			},
			{
				accessor: 'totalPAHT',
				width: 120,
				Header: () => <Typography>Total PA HT</Typography>,
				Cell: ({ value }) => <Typography>{formatNumberDisplay(value, 2)}</Typography>,
			},
			{
				accessor: 'rfa',
				width: 120,
				Header: () => <Typography>Taux RFA</Typography>,
				Cell: ({ value }) => <Typography>{value ? formatNumberDisplay(value, 2) : '-'}</Typography>,
			},
			{
				accessor: 'totalPAHTWithRFA',
				width: 120,
				Header: () => <Typography>Total PA HT avec RFA</Typography>,
				Cell: ({ value }) => <Typography>{formatNumberDisplay(value, 2)}</Typography>,
			},
			{
				accessor: 'totalPAHTWithRFA',
				id: 'euroDiff',
				width: 120,
				Header: () => <Typography>Différentiel en €</Typography>,
				Cell: ({ value }) => {
					const currentPrice = cheapestSupplierPrice || 0;
					const diff = value ? value - currentPrice : 0;
					return <Typography>{diff ? `${formatNumberDisplay(diff, 2)} €` : '-'}</Typography>;
				},
			},
			{
				accessor: 'totalPAHTWithRFA',
				id: 'percentageDiff',
				width: 120,
				Header: () => <Typography>Différentiel en %</Typography>,
				Cell: ({ value }) => {
					const currentPrice = cheapestSupplierPrice || 0;
					const diff = value ? value - currentPrice : 0;
					const percentDiff = diff !== 0 ? (currentPrice !== 0 ? (diff / currentPrice) * 100 : 100) : 0;
					return <Typography>{percentDiff ? `${formatNumberDisplay(percentDiff, 2)} %` : '-'}</Typography>;
				},
			},
		],
		[cheapestSupplierPrice]
	);

	return useTable({
		data: supplierPrices,
		columns,
	});
};
