import React from 'react';
import { Box, Button } from '@mui/material';
import colors from 'app/theme/colors.scss';
import { HeadLines, useManualTabs } from 'hooks/useManualTabs';
import { InvoiceModel, TAB_MENU_INVOICE } from '../definitions';
import { GeneralInformation } from './general-information/GeneralInformation';
import { ProductInvoice } from './product/ProductInvoice';
import { Form, Formik } from 'formik';
import { InvoiceStatus } from 'graphql/types';
import { Link } from 'react-router-dom';

interface InvoiceFormProps {
	headlines: HeadLines[];
	invoice: InvoiceModel;
	paths: (
		| string
		| {
				name: string;
				href: string;
		  }
	)[];
	onSubmit: (invoice: InvoiceModel) => void;
}

const Buttons = () => (
	<Box>
		<Link to="/to-validate-invoices-list" style={{ textDecoration: 'none' }}>
			<Button color="primary" type="reset">
				Annuler
			</Button>
		</Link>
		<Button type="submit" variant="contained" color="primary">
			Valider la facture
		</Button>
	</Box>
);

export const InvoiceForm = ({ headlines, invoice, onSubmit, paths }: InvoiceFormProps): JSX.Element => {
	const { renderingTabs } = useManualTabs({
		tabs: TAB_MENU_INVOICE,
		paths,
		headlines,
		fixedHeaderHeight: 258,
		ButtonAction: Buttons,
	});

	const initialValue = {
		...invoice,
		createdAt: new Date(invoice?.createdAt).toLocaleDateString(),
	};

	return (
		<Box>
			<Formik<InvoiceModel>
				initialValues={initialValue}
				enableReinitialize
				validateOnChange={false}
				onSubmit={async (invoice) => {
					await onSubmit({ ...invoice, status: InvoiceStatus.ToCount });
				}}
			>
				<Form>
					<Box
						position="fixed"
						width="100%"
						top={40}
						left={0}
						px={11.75}
						bgcolor={colors.bodyBackgroundLight}
						zIndex={100}
					>
						{renderingTabs()}
					</Box>
					<Box mt={30} mx={7}>
						<div ref={TAB_MENU_INVOICE[0].ref}>
							<GeneralInformation />
						</div>
						<div ref={TAB_MENU_INVOICE[1].ref}>
							<ProductInvoice order={invoice?.order} />
						</div>
					</Box>
				</Form>
			</Formik>
		</Box>
	);
};
