import React, { ReactNode } from 'react';
import { Box, Select } from '@mui/material';
import { useField } from 'formik';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import colors from '../../app/theme/colors.scss';

interface SelectProps {
	name: string;
	children: ReactNode;
	style?: React.CSSProperties;
	defaultValue?: string;
	disabled?: boolean;
	testid?: string;
}

type SelectFieldProps = SelectProps & Pick<SelectInputProps, 'variant' | 'onChange' | 'disabled'>;

const FormikSelectField = ({ name, children, defaultValue, disabled, ...res }: SelectFieldProps) => {
	const [field, meta] = useField<string>(name);
	return (
		<Box bgcolor={disabled ? colors.grey : ''}>
			<Select
				variant="standard"
				data-testid={res.testid}
				fullWidth
				disabled={disabled ?? false}
				defaultValue={defaultValue}
				name={name}
				error={meta.touched && Boolean(meta.error)}
				value={field.value ?? ''}
				onChange={field.onChange}
				{...res}
			>
				{children}
			</Select>
		</Box>
	);
};

export default FormikSelectField;
