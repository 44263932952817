import groupBy from 'ramda/src/groupBy';
import { prop, uniqBy } from 'ramda';

import { ChildOrderFormModel, NewOrderItem, OrderFormModel, OrderItemFormModel } from './form/order-cockpit/definition';
import { Store } from './types';
import {
	ClientQuoteItem,
	FollowOrderStatus,
	InvoiceStatus,
	MarginType,
	OrderItemSuppliersPricesModel,
	OrderProductStatus,
} from '../graphql/types';
import { FollowStatusListMap, PriceSummaryByProductType, TransporterPrices } from './definition';
import { getPriceWithRfaAndMargin } from '../clientPrice/form/helpers';

const productStores = (childrenOrder: ChildOrderFormModel[], productId: string) => {
	let productStores: Store[] = [];
	childrenOrder.forEach((orderChild, index) => {
		orderChild?.orderItem?.forEach((productOrder, i) => {
			if (productOrder.productId === productId) {
				productStores.push({
					id: orderChild.id,
					clientName: orderChild.clientName,
					quantity: productOrder.quantity,
					addressName: productOrder.shippingAddress?.name,
					city: productOrder.shippingAddress?.city,
					status: productOrder?.status,
					legacyRef: productOrder?.legacyRef,
					trackingLink: productOrder?.trackingLink,
					orderChildFormikName: `childrenOrder[${index}].orderItem[${i}]`,
					thumbnailAssetUrl: productOrder?.thumbnailAssetUrl,
					assetUrl: productOrder?.assetUrl,
				});
			}
		});
	});
	return productStores;
};

export const getShippingAddress = (childrenOrder: ChildOrderFormModel[]) => {
	const firstShippinAddress = childrenOrder.length && childrenOrder?.[0].orderItem?.[0].shippingAddress;
	const isEqualShippingAddresses = childrenOrder?.reduce((acc, curr) => {
		const isEqual = !!curr.orderItem?.every(
			(item) => JSON.stringify(item.shippingAddress) === JSON.stringify(firstShippinAddress)
		);
		acc = acc && isEqual;
		return acc;
	}, true);

	return isEqualShippingAddresses ? firstShippinAddress : null;
};

export const getStoresByShippingAddress = (stores: ChildOrderFormModel[]) => {
	let storesByShippingAddress: (ChildOrderFormModel & { index: number })[] = [];
	stores?.forEach((store, index) => {
		const firstShippinAddress = store?.orderItem && store?.orderItem[0]?.shippingAddress;
		if (
			store.orderItem?.every((item) => JSON.stringify(item.shippingAddress) === JSON.stringify(firstShippinAddress))
		) {
			storesByShippingAddress.push({ ...store, index });
		} else {
			const groupByShippingAddress = groupBy((item: OrderItemFormModel) => JSON.stringify(item?.shippingAddress));
			const orderItemByShippingAddress = groupByShippingAddress(
				store?.orderItem as (OrderItemFormModel & { number: number })[]
			);

			Object.keys(orderItemByShippingAddress).map((key) => {
				storesByShippingAddress.push({
					...store,
					index,
					orderItem: orderItemByShippingAddress[key],
				});
				return key;
			});
		}
	});
	return storesByShippingAddress;
};

export const getOrderItemByShippingAddress = (store?: ChildOrderFormModel | null) => {
	let storesByShippingAddress: OrderItemFormModel[][] = [];

	const groupByShippingAddress = groupBy((item: OrderItemFormModel) => JSON.stringify(item?.shippingAddress));
	const orderItemByShippingAddress = groupByShippingAddress(
		store?.orderItem as (OrderItemFormModel & { number: number })[]
	);

	Object.keys(orderItemByShippingAddress).map((key) => {
		storesByShippingAddress.push(orderItemByShippingAddress[key] as OrderItemFormModel[]);
		return key;
	});

	return storesByShippingAddress;
};

export const shouldSetProductStoreToDelivered = (
	orderChildren: ChildOrderFormModel[],
	formikNames: string[],
	currentStatusName: string,
	status: string
) => {
	let storesStatus: (string | null | undefined)[] = [];
	orderChildren.forEach((child: ChildOrderFormModel, index: number) => {
		if (!child.orderItem) return false;
		child.orderItem.forEach((orderItem, i) => {
			if (formikNames.includes(`childrenOrder[${index}].orderItem[${i}]`)) {
				if (`childrenOrder[${index}].orderItem[${i}]` === currentStatusName) {
					storesStatus.push(status);
				} else storesStatus.push(orderItem.status);
			}
		});
	});
	return storesStatus.every((status) => status === OrderProductStatus.Delivered);
};

export const getCurrentStatusByName = (orderChildren: ChildOrderFormModel[], currentStatusName: string) => {
	let currentStatusValue: string | null | undefined;
	orderChildren.forEach((child: ChildOrderFormModel, index: number) => {
		if (!child.orderItem) return false;

		child?.orderItem.forEach((orderItem, i) => {
			if (`childrenOrder[${index}].orderItem[${i}]` === currentStatusName) {
				currentStatusValue = orderItem.status;
			}
		});
	});
	return currentStatusValue;
};

export const getClientInvoices = (values: OrderFormModel) => {
	if (values.childrenOrder && values.childrenOrder.length) {
		return values.childrenOrder.map((item) => {
			const invoice = {
				id: item.id,
				status: item.invoices ? item.invoices[0]?.status : null,
				createdAt: item.invoices ? item.invoices[0]?.createdAt : null,
				validateAt: item.invoices ? item.invoices[0]?.validateAt : null,
				sendAt: item.invoices ? item.invoices[0]?.sendAt : null,
				file: null,
				icons: '',
				magasin: item.clientName,
				invoiceId: item.invoices ? item.invoices[0]?.id : null,
				url: item.invoices ? item.invoices[0]?.url : '',
			};

			return invoice;
		});
	}

	return values.invoices
		? values.invoices.map((item) => ({
				id: item.id,
				status: item.status,
				createdAt: item.createdAt,
				validateAt: item.validateAt,
				sendAt: item.sendAt,
				file: null,
				icons: '',
				url: '',
				magasin: values.clientName,
		  }))
		: [];
};

export const getStatusByType = (type: string) => {
	switch (type) {
		case 'createdAt':
			return InvoiceStatus.ToValidate;
		case 'validateAt':
			return InvoiceStatus.ToCount;
		case 'sendAt':
			return InvoiceStatus.Counted;
		default:
			return InvoiceStatus.Blank;
	}
};

export default productStores;

export const getBestSupplier = (
	suppliersPrices?: (OrderItemSuppliersPricesModel & { deliveryDepartment?: string | null })[] | null,
	bestSupplierId?: string | null
) => {
	return suppliersPrices?.find((supplierPrice) => supplierPrice.supplierId === bestSupplierId);
};

export const productAndTransporterCalculation = (
	orderItems: (OrderItemFormModel & {
		transporter?: TransporterPrices[] | null;
		bestTransporter?: TransporterPrices | null;
		bestSupplierId?: string;
	})[]
) => {
	return orderItems.map((orderItem) => {
		let totalPurchasePriceExcludingTax = 0;
		const supplier = getBestSupplier(orderItem.suppliersPrices, orderItem.bestSupplierId);
		totalPurchasePriceExcludingTax =
			(supplier?.supplierPrice ?? 0) +
			(orderItem?.bestTransporter?.transporterPurchaseExcludingVATWithRfa ?? 0) +
			totalPurchasePriceExcludingTax;

		const transporterPurchasePriceExcludingVAT = orderItem?.bestTransporter?.transporterPurchasePriceExcludingVAT ?? 0;

		const transporterPurchaseExcludingVATWithRfa = getPriceWithRfaAndMargin(
			MarginType.Percent,
			orderItem?.bestTransporter?.transporterPurchaseExcludingVATWithRfa ?? 0,
			0,
			true
		);

		const transporterRfaRate = orderItem?.bestTransporter?.transporterRfaRate ?? 0; //@todo
		const productPurchasePriceExcludingVAT = supplier?.buyingPriceHtWithoutRspAndRfa ?? 0;
		const productPurchasePriceExcludingVATWithRFA = supplier?.supplierPrice ?? 0;
		const deliveryDepartment = supplier?.deliveryDepartment ?? '';

		const bestDeliveryParcelPurchasePrice = orderItem?.bestTransporter?.transporterPurchasePriceExcludingVAT ?? 0;
		const bestTransporterId = orderItem?.bestTransporter?.transporterId ?? '';

		return {
			...orderItem,
			totalPurchasePriceExcludingTax,
			transporterPurchasePriceExcludingVAT,
			transporterPurchaseExcludingVATWithRfa,
			transporterRfaRate,
			productPurchasePriceExcludingVAT,
			productPurchasePriceExcludingVATWithRFA,
			deliveryDepartment,
			bestDeliveryParcelPurchasePrice,
			bestTransporterId,
			supplierRspRate: supplier?.supplierRspRate ?? 0,
			supplierRfaRate: supplier?.supplierRfaRate ?? 0,
		};
	});
};

export const priceSummaryOrderItemByBestSupplier = ({
	orderItems,
}: {
	orderItems: (NewOrderItem & {
		transporter?: TransporterPrices[] | null;
		bestTransporter?: TransporterPrices | null;
		bestSupplierId?: string;
	})[];
}) => {
	return orderItems
		?.map((orderItem) => {
			const totalPurchasePriceExcludingTaxWithRFA =
				(orderItem?.productPurchasePriceExcludingVATWithRFA ?? 0) +
				(orderItem?.transporterPurchaseExcludingVATWithRfa ?? 0);

			return {
				summaryDetails: {
					Produit: orderItem.productName,
					'PA produit HT avec RFA (€)': orderItem?.productPurchasePriceExcludingVATWithRFA ?? 0,
					'PA Transport HT avec RFA (€)': orderItem?.transporterPurchaseExcludingVATWithRfa ?? 0,
					'PA Total HT avec RFA (€)': totalPurchasePriceExcludingTaxWithRFA,
				},
				totalPurchasePriceExcludingTaxWithRFA: totalPurchasePriceExcludingTaxWithRFA,
			};
		})
		.reduce<PriceSummaryByProductType>((acc, current) => {
			const sum = (acc.totalPurchasePriceExcludingTaxWithRFA ?? 0) + current.totalPurchasePriceExcludingTaxWithRFA;
			acc = {
				summaryDetails: [...(acc.summaryDetails ?? []), current.summaryDetails],
				totalPurchasePriceExcludingTaxWithRFA: sum,
			};
			return acc;
		}, {} as PriceSummaryByProductType);
};

export const getWidthRoot = (index?: number) => {
	switch (index) {
		case 0:
		case 1:
		case 2:
			return '29%';
		case 3:
			return '8%';
		default:
			return '100%';
	}
};

export const getPadding = (index?: number) => {
	switch (index) {
		case 0:
		case 3:
			return '10px 6px';
		case 1:
		case 2:
			return '12px 6px';
		default:
			return '14px';
	}
};

export const productHasOneQuantity = (clientQuoteItems: ClientQuoteItem[] | null = [], compare: string) => {
	const filteredProduct = clientQuoteItems?.filter(({ productName }) => productName === compare);

	return {
		quantity: filteredProduct?.map(({ quantity }) => quantity)[0],
		length: filteredProduct?.length,
	};
};

export const uniqByProductName = (clientQuoteItems: ClientQuoteItem[] = []) =>
	uniqBy(
		prop('productName'),
		clientQuoteItems?.map((clientQuoteItem) => clientQuoteItem)
	);

export const getMapStatusValueFilter = (status: FollowStatusListMap) => {
	switch (status) {
		case FollowStatusListMap.ProductionInProgress:
			return FollowOrderStatus.ProductionInProgress;
		case FollowStatusListMap.DeliveryInProgress:
			return FollowOrderStatus.DeliveryInProgress;
		case FollowStatusListMap.Delivered:
			return FollowOrderStatus.Delivered;
		case FollowStatusListMap.Billed:
			return FollowOrderStatus.Billed;
		case FollowStatusListMap.Validated:
			return FollowOrderStatus.Validated;
		case FollowStatusListMap.WaitingValidation:
			return FollowOrderStatus.WaitingValidation;
		case FollowStatusListMap.Refused:
			return FollowOrderStatus.Refused;
		default:
			return undefined;
	}
};
