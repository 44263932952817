import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { Select } from '@mui/material';
import React, { ReactNode } from 'react';

interface SelectProps {
	children: ReactNode;
}

type SelectFieldProps = SelectProps & Pick<SelectInputProps, 'name' | 'variant' | 'onChange' | 'value'>;

const SelectField = ({ children, value, ...res }: SelectFieldProps) => {
	return (
		<Select variant="standard" value={value ?? ''} {...res}>
			{children}
		</Select>
	);
};

export default SelectField;
