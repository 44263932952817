import { useLazyQuery } from '@apollo/client';
import { GET_POSSIBLE_BILLINGS_CLIENTS } from '../graphql/queries';
import {
	GetPossibleBillingsClientsQuery,
	GetPossibleBillingsClientsQueryVariables,
} from '../graphql/queries.definitions';
import { useFormikContext } from 'formik';
import { ClientQuoteModel, CurrentUserClientsFromModel } from '../../client-quote/form/definition';
import { useMemo } from 'react';

const usePossibleBillingsClientsQuery = (clientsUsers?: CurrentUserClientsFromModel[]) => {
	const { setFieldValue, values } = useFormikContext<ClientQuoteModel>();
	const selectedClient = useMemo(() => clientsUsers?.find((clientsUser) => clientsUser.id === values.clientId), [
		clientsUsers,
		values.clientId,
	]);
	const [getPossibleBillingClients, { data, loading }] = useLazyQuery<
		GetPossibleBillingsClientsQuery,
		GetPossibleBillingsClientsQueryVariables
	>(GET_POSSIBLE_BILLINGS_CLIENTS, {
		onCompleted: (data) => {
			if (!values.id)
				setFieldValue(
					'client',
					data.possibleBillingsClients.filter((client) => client.type === selectedClient?.defaultBilling)[0]?.id ?? null
				);
		},
	});

	return { possibleBillingClients: data?.possibleBillingsClients, getPossibleBillingClients, loading };
};

export default usePossibleBillingsClientsQuery;
