import { gql } from '@apollo/client';
import { CLIENT_PRICE_FRAGMENT } from 'graphql/fragments/client-price/clientPriceFragment';
export const UPSERT_CLARIPRINT_CLIENTPRICE = gql`
	mutation clariprintClientPrice($filter: ProductClientPriceCreateInput!) {
		upsertClariprintClientPrice(filter: $filter) {
			...ClientPrice
		}
	}
	${CLIENT_PRICE_FRAGMENT}
`;
