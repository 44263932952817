import React from 'react';
import { Box } from '@mui/material';
import DashedHeader from '../DashedHeader';
import { ItemCellPrice } from './ItemCellPrice';
import { SupplierPriceFormModel } from 'supplier/definitions';
import { FabricationTimesQuery } from 'clientPrice/graphql/queries.definitions';
import FabricationTimes from './FabricationTimes';

type FlexDirectionProperty = 'row' | 'column';

export interface PriceCell {
	width: string;
	name: string;
	isBorder: boolean;
	hasRate: boolean;
	label: string;
	isChecked?: boolean;
	direction?: FlexDirectionProperty;
	value?: string | number;
	isNotDisabled?: boolean;
}

interface SummaryPriceProps {
	title?: string;
	bgcolor?: string;
	padding?: number;
	verticalDataCell: PriceCell[];
	horizontalDataCell: PriceCell[];
	supplierPrices?: SupplierPriceFormModel;
	fabricationTimes?: FabricationTimesQuery['fabricationTimes'];
	hasProductionTimes?: boolean;
}

export const SummaryPrice = ({
	verticalDataCell,
	horizontalDataCell,
	title,
	padding = 0,
	bgcolor = 'transparent',
	fabricationTimes,
	hasProductionTimes = false,
}: SummaryPriceProps): JSX.Element => (
	<Box width="100%" m="10px auto" p={padding} bgcolor={bgcolor}>
		{title && <DashedHeader>{title}</DashedHeader>}
		<Box width="100%" display="flex" justifyContent="space-around">
			{horizontalDataCell.map(({ width, name, isBorder, hasRate, label, isChecked, value, isNotDisabled }, i) => {
				return (
					<ItemCellPrice
						key={`horizontalDataCell_${i}`}
						width={width}
						name={name}
						isBorder={isBorder}
						hasRate={hasRate}
						label={label}
						isChecked={isChecked}
						value={value}
						isNotDisabled={isNotDisabled}
					/>
				);
			})}
		</Box>

		<Box display="flex" justifyContent="space-between">
			<FabricationTimes fabricationTimes={fabricationTimes} hasProductionTimes={hasProductionTimes} />

			<Box display="flex" flexDirection="column" flex="1">
				{verticalDataCell.map(({ width, name, isBorder, value, hasRate, label }, i) => (
					<Box width="100%" display="flex" justifyContent="flex-end" key={`verticalDataCell_${i}`}>
						<ItemCellPrice
							width={width}
							name={name}
							isBorder={isBorder}
							hasRate={hasRate}
							label={label}
							value={value}
						/>
					</Box>
				))}
			</Box>
		</Box>
	</Box>
);
