import moment from 'moment';
import * as Yup from 'yup';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const getUserFormSchema = Yup.object({
	familyName: Yup.string().required('Renseignement du nom obligatoire').max(25, 'Nombre de caractères maximum atteint'),
	givenName: Yup.string().required('Renseignement du prénom obligatoire'),
	roleId: Yup.string().required('Renseignement du role obligatoire'),
	email: Yup.string().email('E-mail invalide').required("Renseignement de l'email obligatoire"),
	phoneNumber: Yup.string()

		.matches(phoneRegExp, 'Numéro de téléphone invalide')
		.required('Renseignement du numéro de téléphone obligatoire')
		.min(10, 'Le Numéro doit avoir au moins 10 chiffres'),

	password: Yup.string().matches(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?#$@!])(?=.{8,})/,
		'doit contenir 8 caractères, une majuscle, une minuscule, un nombre et un caractère spécial ?#$@!'
	),
	replacedFrom: Yup.date()
		.nullable()
		.when('replacedTo', {
			is: (replacedTo: Date) => !!replacedTo,
			then: Yup.date().max(Yup.ref('replacedTo'), 'La date de début doit être inférieure ou égale à la date de fin'),
			otherwise: Yup.date().nullable(),
		})
		.min(moment().startOf('day').toDate(), 'La date de début doit être supérieure ou égale à la date du jour'),
	replacedTo: Yup.date()
		.nullable()
		.min(Yup.ref('replacedFrom'), 'La date de fin doit être antérieure ou égale à la date de début')
		.min(moment().startOf('day').toDate(), 'La date de début doit être supérieure ou égale à la date du jour'),
});
