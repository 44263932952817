import useCurrentUserLazyQuery from 'admin/user/hooks/useCurrentUserLazyQuery';
import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionType } from 'react-table';
import { AuthContextType, StateType, initialState } from './definition';
import useStorage from '../hooks/useStorage';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const authReducer = (state: StateType, action: ActionType) => {
	switch (action.type) {
		case 'LOGIN':
			return { ...state, accessToken: action.payload };
		case 'LOGOUT':
			return { ...state, accessToken: null };
		default:
			return state;
	}
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(authReducer, initialState);
	const { getDataLocalStorage, setDataLocalStorage, clearAllLocalStorage } = useStorage();
	const { getCurrentUser, currentUser } = useCurrentUserLazyQuery();
	const accessToken = getDataLocalStorage('accessToken');

	const login = React.useCallback(
		(accessToken?: string) => {
			if (accessToken) {
				setDataLocalStorage('accessToken', accessToken);
				dispatch({
					type: 'LOGIN',
					payload: accessToken,
				});
			}
		},
		[setDataLocalStorage]
	);

	useEffect(() => {
		if (!currentUser && accessToken) {
			getCurrentUser();
		}
	}, [getCurrentUser, currentUser, getDataLocalStorage, accessToken]);

	const logout = React.useCallback(async () => {
		await clearAllLocalStorage();
		dispatch({ type: 'LOGOUT' });
	}, [clearAllLocalStorage]);

	if (accessToken && currentUser) setDataLocalStorage('currentUser', currentUser);

	//@todo à supprimer le AuthModel en userModel
	const value = React.useMemo(
		() => ({
			accessToken: accessToken || state.accessToken,
			user: state.user,
			login,
			logout,
			currentUser,
		}),
		[accessToken, currentUser, login, logout, state.accessToken, state.user]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
