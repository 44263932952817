import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../app/theme/colors.scss';

export const useTableStyles = makeStyles({
	root: {
		'& > *': {
			padding: '6px',
			fontSize: '9px',
			backgroundColor: (props: { isColorBlue?: boolean }) =>
				props.isColorBlue ? colors.blue200 : colors.bodyBackgroundLight,
			border: 0,
		},
	},
	tableContainer: {
		overflowX: 'hidden',
		marginTop: '3px',
		boxShadow: 'none',
	},
	table: {
		borderCollapse: 'inherit',
	},
});

export const useTableRowStyles = makeStyles({
	root: {
		'& > *': {
			borderRight: '3px solid',
			borderRightColor: (props: { isColorBlue?: boolean }) =>
				props.isColorBlue ? colors.blue200 : colors.bodyBackgroundLight,
			borderBottom: '3px solid',
			borderBottomColor: (props: { isColorBlue?: boolean }) =>
				props.isColorBlue ? colors.blue200 : colors.bodyBackgroundLight,
			padding: '6px',
			fontSize: '10px',
		},
	},
	tableCellDetails: {
		backgroundColor: colors.blue200,
		borderBottom: 'none',
	},
});
