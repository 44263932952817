import React, { Dispatch, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';

import Checkbox from '@mui/material/Checkbox';

import { Box, Typography } from '@mui/material';

import { ArrowBackSharp, ArrowForwardSharp } from '@mui/icons-material';

import { UserClientListItem } from '../definitions';

import { PrimaryIconButton } from '../../../components/buttons/PrimaryIconButton';
import CustomListTransfert from './CustomListTransfert';
import { Field, useFormikContext } from 'formik';

import { UserCreateInput } from '../../../graphql/types';
import SearchBar from '../../../components/inputs/SearchBar';
import { intersection, not, union } from '../../../utils/mathematicalLlogiOperationsc';
import colors from '../../../app/theme/colors.scss';

interface ClientsTransfertListProps {
	rightUserClientsList: UserClientListItem[];
	setRightUserClientsList: Dispatch<UserClientListItem[]>;
	leftUserClientsList: UserClientListItem[];
	setLeftUserClientsList: Dispatch<UserClientListItem[]>;
	initialUserCientsList: UserClientListItem[];
	setInitialUserCientsList: Dispatch<UserClientListItem[]>;
}

const ClientsTransfertList = ({
	rightUserClientsList,
	setRightUserClientsList,
	leftUserClientsList,
	setLeftUserClientsList,
	initialUserCientsList,
	setInitialUserCientsList,
}: ClientsTransfertListProps): JSX.Element => {
	const [checked, setChecked] = useState<UserClientListItem[]>([]);
	const [checkedItem, setCheckedItem] = React.useState<boolean>(false);
	const [searchClient, setSearchClient] = React.useState<string>('');
	const { setFieldValue } = useFormikContext<UserCreateInput>();

	const leftUserClientsListChecked = intersection(checked, leftUserClientsList);
	const rightUserClientsListChecked = intersection(checked, rightUserClientsList);
	useEffect(() => {
		if (rightUserClientsList && rightUserClientsList.length >= 0) {
			const ids = rightUserClientsList.map((item) => item.id);
			setFieldValue('clientIds', ids);
		}
	}, [rightUserClientsList, setFieldValue]);

	const handleToggle = (value: UserClientListItem) => () => {
		const currentIndex = checked.indexOf(value);
		let newChecked = [...checked];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const ItemOfChecked = (items: UserClientListItem[]) => intersection<UserClientListItem>(checked, items).length;

	const handleToggleAll = (items: UserClientListItem[]) => () => {
		if (ItemOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleMoveRightUserClientsList = () => {
		setRightUserClientsList(
			rightUserClientsList.concat(leftUserClientsListChecked).sort((a, b) => a.name.localeCompare(b.name))
		);
		setLeftUserClientsList(not(leftUserClientsList, leftUserClientsListChecked));
		setChecked(not(checked, leftUserClientsListChecked));
	};

	const handleMoveLeftUserClientsList = () => {
		setLeftUserClientsList(
			leftUserClientsList.concat(rightUserClientsListChecked).sort((a, b) => a.name.localeCompare(b.name))
		);
		setRightUserClientsList(not(rightUserClientsList, rightUserClientsListChecked));

		setChecked(not(checked, rightUserClientsListChecked));
	};

	const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		let searchResults = [...initialUserCientsList].filter((item) =>
			item.name.toLowerCase().includes(event.target.value.toLowerCase())
		);

		setSearchClient(event.target.value);
		setLeftUserClientsList(searchResults.filter((x) => !rightUserClientsList.includes(x)));
	};

	const checkParentsEntities = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		let searchResults = [...initialUserCientsList].filter((item) => (event.target.checked ? item.isRoot === true : {}));
		setLeftUserClientsList(searchResults.filter((x) => !rightUserClientsList.includes(x)));
		setCheckedItem(event.target.checked);
	};

	return (
		<Grid container spacing={3}>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid item>
					<Grid container direction="row" spacing={2}>
						<Grid item>
							<Box mt={1}>
								<Typography variant="h3">Clients</Typography>
							</Box>
						</Grid>

						<Grid item>
							<Box display="flex" alignItems="center" justifyContent="flex-start">
								<Field
									component={Checkbox}
									type="checkbox"
									size="small"
									onChange={checkParentsEntities}
									name="ParentEntity"
									checked={checkedItem ?? false}
								/>
								<Box>
									<Typography variant="h5">Entité mère</Typography>
								</Box>
							</Box>
						</Grid>

						<Grid item>
							<Box ml={3}>
								<SearchBar
									isForm
									iconColor="disabled"
									placeholder="Chercher un client"
									onChange={handleChangeSearch}
									value={searchClient}
								/>
							</Box>
						</Grid>
					</Grid>

					<CustomListTransfert
						title={'Nom du Client'}
						items={leftUserClientsList ?? []}
						handleToggle={handleToggle}
						handleToggleAll={handleToggleAll}
						checked={checked}
					/>
					<Grid item>
						<Box mt={1}>
							<Typography variant="inherit" align="center" color="textSecondary">
								Inclure un groupe inclut toutes les entités
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Grid item xs={2}>
					<Grid container direction="column" alignItems="center">
						<PrimaryIconButton
							size="small"
							onClick={handleMoveRightUserClientsList}
							disabled={leftUserClientsListChecked.length === 0}
							aria-label="move selected rightUserClientsList"
							style={{
								backgroundColor: leftUserClientsListChecked.length === 0 ? colors.blueDisabled : colors.primary,
								marginTop: '16px',
							}}
						>
							<ArrowForwardSharp fontSize="large" />
						</PrimaryIconButton>
						<PrimaryIconButton
							size="small"
							onClick={handleMoveLeftUserClientsList}
							disabled={rightUserClientsListChecked.length === 0}
							aria-label="move selected leftUserClientsList"
							style={{
								backgroundColor: rightUserClientsListChecked.length === 0 ? colors.blueDisabled : colors.primary,
								marginTop: '16px',
							}}
						>
							<ArrowBackSharp fontSize="large" />
						</PrimaryIconButton>
					</Grid>
				</Grid>
				<Grid item>
					<Grid item>
						<Typography variant="h3"> Clients séléctionnés</Typography>
					</Grid>

					<CustomListTransfert
						title={'Nom du Client'}
						items={rightUserClientsList ?? []}
						handleToggle={handleToggle}
						handleToggleAll={handleToggleAll}
						checked={checked}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ClientsTransfertList;
