import { useMutation } from '@apollo/client';
import { SupplierFormModel } from '../definitions';
import { UPDATE_SUPPLIER } from '../graphql/mutations';
import omit from 'ramda/src/omit';
import { UpdateSupplierMutation, UpdateSupplierMutationVariables } from '../graphql/mutations.definitions';
import { GET_SUPPLIER_BY_ID } from '../graphql/queries';

export const useSupplierUpdate = () => {
	const [updateRoleMutation] = useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UPDATE_SUPPLIER);
	return async (supplierInput: SupplierFormModel & { user?: { password: string } | null }) => {
		const supplierInputRamdaStructure = omit(
			['__typename', 'supplierContact', 'updatedAt', 'createdAt'],
			supplierInput
		);

		const {
			escomptes,
			discounts,
			id,
			maxPalletWeight,
			transportModes,
			siteAddress1,
			siteAddress2,
			lastConnection,
			user,
			...rs
		} = supplierInputRamdaStructure;

		const result = await updateRoleMutation({
			variables: {
				supplierId: id,
				data: {
					siteAddress1: {
						id: siteAddress1?.id,
						deliveryDepartment: siteAddress1?.deliveryDepartment ?? null,
						country: siteAddress1?.country ?? null,
						firstAddress: siteAddress1?.firstAddress ?? null,
						postBox: siteAddress1?.postBox ?? null,
						secondAddress: siteAddress1?.secondAddress ?? null,
						thirdAddress: siteAddress1?.thirdAddress ?? null,
						zipCode: siteAddress1?.zipCode ?? null,
						name: siteAddress1?.name ?? '',
						city: siteAddress1?.city ?? '',
						isShipping: siteAddress1?.isShipping ?? false,
					},
					siteAddress2: {
						id: siteAddress2?.id,
						deliveryDepartment: siteAddress2?.deliveryDepartment ?? null,
						country: siteAddress2?.country ?? null,
						firstAddress: siteAddress2?.firstAddress ?? null,
						postBox: siteAddress2?.postBox ?? null,
						secondAddress: siteAddress2?.secondAddress ?? null,
						thirdAddress: siteAddress2?.thirdAddress ?? null,
						zipCode: siteAddress2?.zipCode ?? null,
						name: siteAddress2?.name ?? '',
						city: siteAddress2?.city ?? '',
						isShipping: siteAddress2?.isShipping ?? false,
					},
					maxPalletWeight: Number(maxPalletWeight),
					transportModes: transportModes?.map((transporter) => ({ title: transporter.title })),
					discounts: discounts?.map((discount) => ({ ca: discount.ca, rfa: discount.rfa })),
					escomptes: escomptes?.map((escompte) => ({
						client: escompte.client,
						dayLimitTime: escompte.dayLimitTime,
						escompte: escompte.escompte,
					})),
					user: {
						email: user?.email,
						isActive: false,
						password: user?.password,
						id: user?.id,
					},
					...rs,
				},
			},
			refetchQueries: [
				{
					query: GET_SUPPLIER_BY_ID,
					variables: {
						id,
					},
				},
			],
		});
		if (!result.data) throw new Error();
		return result;
	};
};
