import React from 'react';
import { Grid } from '@mui/material';
import DashedHeader from '../../../../components/DashedHeader';
import Departments from '../../../../components/Departments';
import { LayoutField } from '../../../../components/form/LayoutField';
import { FormikNumberField } from '../../../../components/form/FormikNumberField';
import { IsShowSellingPriceSelectedProps } from '../../definitions';

const TransportMaintInformation = ({ isConsultSellingPrice }: IsShowSellingPriceSelectedProps): JSX.Element | null => (
	<>
		<DashedHeader>Informations principales de transport</DashedHeader>
		<Grid container justifyContent="space-between">
			<Grid item xs={5}>
				<Departments
					testid="departing-department"
					title="Département de départ"
					name="supplierPrices[0].departingDepartment"
					disabledDepartments
				/>
			</Grid>

			<Grid item xs={5}>
				<Departments
					testid="delivery-department"
					title="Département de destination"
					name="deliveryDepartment"
					disabledDepartments={isConsultSellingPrice}
				/>
			</Grid>
		</Grid>

		<LayoutField label="Poids (kg)">
			<FormikNumberField
				testid="product-total-weight"
				decimalScale={2}
				name="productTotalWeight"
				width="164px"
				variant="outlined"
				disabled={isConsultSellingPrice}
			/>
		</LayoutField>
	</>
);

export default TransportMaintInformation;
