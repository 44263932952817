import React, { memo } from 'react';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import { Store } from '../../../types';
import FormikTextField from 'components/form/FormikTextField';
import OrderTableCell from '../components/OrderTableCell';
import { useTableRowStyles } from '../sharedStyles';
import FormikSelectField from 'components/form/FormikSelectField';
import { OrderProductStatus } from 'graphql/types';
import useSelectStatus from '../../../hooks/useSelectStatus';
import { OrderProductStatusValue } from '../definition';

interface StoreItemProps {
	store: Store;
	productIndex: number;
	stores: Store[];
}

const StoreItem = ({ store, productIndex, stores }: StoreItemProps): JSX.Element => {
	const classes = useTableRowStyles({ isColorBlue: true });
	const { orderChildFormikName, clientName, city, quantity, addressName } = store;
	const formikNames = stores.map((store) => store.orderChildFormikName);
	const [selectStatus] = useSelectStatus(formikNames, orderChildFormikName);

	return (
		<>
			<TableRow className={classes.root}>
				<OrderTableCell width="12%" label={clientName} variant="body1" isFirst />
				<OrderTableCell width="16%" label={addressName} variant="body1" />
				<OrderTableCell width="16%" label={city} variant="body1" />
				<OrderTableCell width="8%" label={quantity} variant="body1" />
				<OrderTableCell width="30%">
					<FormikTextField name={`${orderChildFormikName}.trackingLink`} fullWidth disabled={false} />
				</OrderTableCell>
				<OrderTableCell width="10%" align="center">
					<FormikSelectField
						name={`${orderChildFormikName}.status`}
						onChange={(e) => {
							selectStatus(e.target.value as string, productIndex, true);
						}}
					>
						{Object.keys(OrderProductStatus).map((status) => (
							<MenuItem selected={false} value={status} key={status}>
								{OrderProductStatusValue[status as OrderProductStatus]}
							</MenuItem>
						))}
					</FormikSelectField>
				</OrderTableCell>
				<OrderTableCell width="8%">
					<FormikTextField name={`${store.orderChildFormikName}.legacyRef`} fullWidth disabled={false} />
				</OrderTableCell>
			</TableRow>
		</>
	);
};
export default memo(StoreItem);
