import React, { useState } from 'react';
import { Grid, Box, Typography, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from 'app/theme/colors.scss';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ButtonDisplayContent from 'components/ButtonDisplayContent';
import { ProductDetailsByFamily } from 'product/components/ProductDetailsByFamily';
import DatesAndCommentsProductInformation from '../../order-cockpit/components/DatesAndCommentsProductInformation';
import { OrderItem as OrderItemType } from 'graphql/types';
import OrderItemDetails from './OrderItemDetails';
import OrderItemSuppliersPrices from './OrderItemSuppliersPrices';
import useGetProductById from '../../../../product/hooks/useGetProductById';
import FormikFilesField from 'components/form/FormikFilesField';

interface props {
	productName: string;
	productId?: string;
	index: number;
	orderItem: OrderItemType & { supportId: string };
	handleDelete: (index: number) => void;
}

const useStyles = makeStyles({
	cell: {
		backgroundColor: colors.white,
		borderLeft: '3px solid',
		borderTopLeftRadius: 3,
		borderBottomLeftRadius: 3,
		borderLeftColor: colors.indigo700,
		padding: 2,
	},
});

const ACCEPTED_FORMATS = ['application/pdf'];

const OrderItem = ({ productName, index, productId, orderItem, handleDelete }: props) => {
	const classes = useStyles();
	const { product } = useGetProductById(productId ?? '');
	const [displayOrderInformation, setDisplayOrderInformation] = useState(false);

	return (
		<Grid item xs={11}>
			<Box display="flex" justifyContent="center" bgcolor={colors.white} className={classes.cell}>
				<Box p={1} flexGrow={1}>
					<Typography variant="h4">{productName}</Typography>
				</Box>
				<Box display="flex" justifyContent="right" mt={1} mr={2}>
					<DeleteForeverOutlinedIcon fontSize="medium" onClick={() => handleDelete(index)} />
				</Box>
				<ButtonDisplayContent
					displayContent={displayOrderInformation}
					OnDisplayContent={setDisplayOrderInformation}
					isActive
				/>
			</Box>
			<Collapse in={displayOrderInformation}>
				<Box width="100%" m="10px auto" bgcolor={colors.blue200} p={2}>
					<ProductDetailsByFamily product={product} />
					<Grid container spacing={6}>
						<Grid item xs={9}>
							<OrderItemDetails orderItem={orderItem} />
							<Box bgcolor={colors.blue200} py={4} pl={1}>
								<DatesAndCommentsProductInformation orderIem={orderItem} index={index} isCell={true} />
							</Box>
						</Grid>
						<Grid item xs={3}>
							<OrderItemSuppliersPrices orderItem={orderItem} index={index} />
						</Grid>
						<Grid item xs={10}>
							<Typography variant="h4">Ajouter un visuel</Typography>
							<Typography variant="inherit" align="left" color="textSecondary">
								Format pdf
							</Typography>
							<FormikFilesField
								name={`orderItem[${index}].assetFile`}
								accept={ACCEPTED_FORMATS}
								imagePlaceholder={orderItem?.thumbnailAssetUrl || null}
							/>
						</Grid>
					</Grid>
				</Box>
			</Collapse>
		</Grid>
	);
};

export default OrderItem;
