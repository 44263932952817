import { Box, Divider, Typography, Button } from '@mui/material';
import colors from 'app/theme/colors.scss';
import Path from 'components/Path';
import AppListTable from 'components/tables/list-table/AppListTable';
import { InvoiceStatus } from 'graphql/types';

import { GetInvoicesQueryVariables } from 'invoice/graphql/queries.definitions';
import useInvoicesQuery from 'invoice/hooks/useInvoicesQuery';
import { isEmpty } from 'ramda';
import React, { useCallback, useState } from 'react';
import { printPDFS } from '../invoicesList-to-be-accounted/helpers';
import { useInvoicesCountedListTable } from './hooks/useInvoicesCountedListTable';

const InvoicesCountedList = () => {
	const [search, setSearch] = useState<GetInvoicesQueryVariables['search']>(undefined);
	const [sort, setSort] = useState<GetInvoicesQueryVariables['sort']>(undefined);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const { invoices, loading, fetchMore, refetchInvoices } = useInvoicesQuery(
		search,
		sort,
		{ status: InvoiceStatus.Counted },
		{
			take: 10,
			skip: 0,
		}
	);

	const toggleSelect = useCallback(
		(id: string) => {
			let updatedRows;

			if (selectedRows.includes(id)) {
				updatedRows = selectedRows.filter((selectedId) => selectedId !== id);
			} else {
				updatedRows = [...selectedRows, id];
			}

			setSelectedRows(updatedRows);
		},

		[selectedRows]
	);

	const isChecked = useCallback((id: string) => selectedRows.includes(id), [selectedRows]);

	const toggleAll = useCallback(
		(isChecked: boolean) => {
			if (isChecked) {
				setSelectedRows(invoices ? invoices.map((invoice) => invoice.id) : []);
			} else {
				setSelectedRows([]);
			}
		},

		[invoices]
	);

	const allChecked = useCallback(() => !!invoices && invoices.length === selectedRows.length, [invoices, selectedRows]);

	const instanceToBeAccountedInvoice = useInvoicesCountedListTable(
		invoices ?? [],
		loading,
		isChecked,
		toggleSelect,
		toggleAll,
		allChecked
	);

	const pdfUrls: string[] = invoices
		? invoices.filter((invoice) => selectedRows.includes(invoice.id)).map((invoice) => invoice.url ?? '')
		: [];

	const loadMore = useCallback(() => {
		if (!loading) {
			fetchMore({
				variables: {
					search: search ?? {},
					sort: sort ?? {},
					filter: { status: InvoiceStatus.Counted },
					pagination: { take: 10, skip: invoices?.length },
				},
			});
		}
	}, [fetchMore, invoices?.length, loading, search, sort]);

	React.useEffect(() => {
		if (!isEmpty(instanceToBeAccountedInvoice.state.filters)) {
			const formattedQuery = instanceToBeAccountedInvoice?.state?.filters?.reduce<{
				[key: string]: string;
			}>((acc, { id, value }: { id: string; value: string }) => {
				if (!value) {
					return {};
				}
				acc[id] = value;
				return acc;
			}, {});

			setSearch(formattedQuery);
		} else {
			setSearch({});
		}
	}, [instanceToBeAccountedInvoice, instanceToBeAccountedInvoice.state.filters]);

	React.useEffect(() => {
		if (!isEmpty(instanceToBeAccountedInvoice?.state?.sortBy)) {
			const { id, desc } = instanceToBeAccountedInvoice?.state?.sortBy?.[0] ?? {};
			const isDesc = desc ? 'desc' : 'asc';

			setSort({ [id]: isDesc });
		}
	}, [instanceToBeAccountedInvoice, instanceToBeAccountedInvoice?.state?.sortBy]);

	const paths = ['Factures clients', 'comptabilisées'];

	React.useEffect(() => {
		if (sort || search) {
			refetchInvoices();
		}
	}, [refetchInvoices, search, sort]);

	return (
		<>
			<Box p={4} ml={10}>
				<Box mb={4}>
					<Path paths={paths} />
					<Divider />
				</Box>
				<Typography variant="h2">Listes des factures clients comptabilisées</Typography>
				<Box mt={3} mr={10}>
					<Box mt={4}>
						<AppListTable
							tableInstance={instanceToBeAccountedInvoice}
							bgColor={colors.bodyBackgroundLight}
							onLoadMore={loadMore}
							isloading={loading}
						/>
					</Box>

					<Box mt={4} textAlign="right">
						<Button
							variant="outlined"
							onClick={() => {
								printPDFS(pdfUrls);
							}}
						>
							Imprimer les facture selectionnées
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default InvoicesCountedList;
