export const formatNumberDisplay = (price?: number | null | 0, fraction: number = 0) => {
	if (price === 0) return numberThousandSeparator(price.toFixed(fraction));
	if (!price) return '';

	return (
		price &&
		numberThousandSeparator(
			new Intl.NumberFormat('fr-FR', {
				style: 'decimal',
				maximumFractionDigits: fraction,
				minimumFractionDigits: fraction,
			})
				.format(price)
				.toLocaleLowerCase()
		)
	);
};

export function numberThousandSeparator(value?: string | null) {
	if (!value) return '';
	return value.replace(/(\d)(?=(?:\d{3})+(?:$1))/g, '$1 ').replace('.', ',');
}
