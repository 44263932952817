import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { typeNotifications } from 'utils/definition';
import downloadFile from 'utils/downloadFile';
import { GENERATE_QUOTE_FILE } from 'clients/graphql/mutations';
import { GenerateQuoteFileMutation, GenerateQuoteFileMutationVariables } from 'clients/graphql/mutations.definitions';
import { GET_CLIENT_QUOTE_BY_ID } from '../graphql/queries';

export const useGenerateQuoteFile = (id: string) => {
	const { enqueueSnackbar } = useSnackbar();
	const [generateQuoteFileMutation, data] = useMutation<GenerateQuoteFileMutation, GenerateQuoteFileMutationVariables>(
		GENERATE_QUOTE_FILE
	);

	const handleGenerateFile = async (filter: GenerateQuoteFileMutationVariables['filter']) => {
		try {
			const { data } = await generateQuoteFileMutation({
				variables: {
					filter,
				},
				refetchQueries: [
					{
						query: GET_CLIENT_QUOTE_BY_ID,
						variables: {
							id,
						},
					},
				],
			});
			if (data) {
				enqueueSnackbar(`${filter.type} généré avec succès`, { variant: typeNotifications.Success });
				await downloadFile(data.quoteFile.url, 'application/pdf', data.quoteFile.filename);
			} else enqueueSnackbar(`Error, ${filter.type} non généré`, { variant: typeNotifications.Error });
		} catch (e) {
			enqueueSnackbar(`Error, ${filter.type} non généré`, { variant: typeNotifications.Error });
		}
	};

	return { handleGenerateFile, data };
};
