import { useQuery } from '@apollo/client';
import { GET_CLIENT_AND_All_CHILDRENS } from '../graphql/queries';
import { GetClientAndAllChildrensQuery, GetClientAndAllChildrensQueryVariables } from '../graphql/queries.definitions';

const useClientAndAllChildrensQuery = (id: string) => {
	const { data, loading } = useQuery<GetClientAndAllChildrensQuery, GetClientAndAllChildrensQueryVariables>(
		GET_CLIENT_AND_All_CHILDRENS,
		{
			variables: {
				id,
			},
		}
	);

	return { clients: data?.clientAndAllChildrens, loading };
};

export default useClientAndAllChildrensQuery;
