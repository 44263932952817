import { Box, Typography } from '@mui/material';
import { AttributConditionnement } from 'graphql/types';
import { getConditionnementInfo } from 'product/helpers';
import React from 'react';

interface ConditionnementDetailsProps {
	detail?: AttributConditionnement | null;
}

const ConditionnementDetails = ({ detail }: ConditionnementDetailsProps) => {
	const conditionnement = getConditionnementInfo(detail);

	const addPaddingConditionnementDesign = (conditionnementInfo: string) => {
		return conditionnementInfo.includes('Lot') ? 2 : 4;
	};

	return (
		<>
			{conditionnement?.map((conditionnementInfo, index) => (
				<Box pl={index === 0 ? 0 : addPaddingConditionnementDesign(conditionnementInfo)}>
					<Typography dangerouslySetInnerHTML={{ __html: conditionnementInfo ?? '' }} />
				</Box>
			))}
		</>
	);
};

export default ConditionnementDetails;
