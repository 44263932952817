import { Box, Grid, Typography } from '@mui/material';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import { useField } from 'formik';
import { orderStatus } from 'order/OrderCockpitDetail';
import React from 'react';
import DateLayoutField from 'components/form/DateLayoutField';
import { ComponentTitle } from '../../definition';

const GeneralInformation = ({ title }: ComponentTitle): JSX.Element => {
	const [, , elpevContactPhoneHelpers] = useField('elpevContactPhone');

	return (
		<Box mb={5} mt={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">{title}</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box mb={5}>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={3}>
						<LayoutFieldForm label="N/Réf" name="id" type="text" disabled />
						<LayoutFieldForm
							label="Contact Nanotera"
							name="elepvContactName"
							disabled
						/>
						<LayoutFieldForm label="Email" name="elpevContactEmail" type="text" disabled />
						<LayoutFieldForm
							inputProps={{
								onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
									elpevContactPhoneHelpers.setValue(event.target.value);
								},
							}}
							label="Téléphone"
							name="elpevContactPhone"
						/>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={3}>
						<LayoutFieldForm
							label="Version"
							name="version"
							type="number"
							inputProps={{
								inputProps: {
									min: 1,
									step: 1,
								},
							}}
							width="164px"
							disabled
						/>
						<LayoutFieldForm label="Status" name="Status" width="164px" value={orderStatus[0].value} disabled />
						<DateLayoutField label="Commande créer le" name="createdAt" width="164px" disabled />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default GeneralInformation;
