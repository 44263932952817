import { Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Field } from 'formik';
import { Checkbox } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import DashedHeader from '../../../components/DashedHeader';
import FormikSelectField from '../../../components/form/FormikSelectField';
import FormikTextField from '../../../components/form/FormikTextField';
import { LayoutField } from '../../../components/form/LayoutField';
import { BoxShippingMode, Client, ClientShippingAddress } from '../../../graphql/types';
import WorkingHours from '../working-days/WorkingHours';
import colors from '../../../app/theme/colors.scss';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import AddressDialogForm from './DeliveryInformationsDialog/DeliveryInformationsDialogForm';
import { boxShippingModeOptions, palletShippingModeOptions } from './definitions';
import LayoutFieldForm from '../../../components/form/LayoutFieldForm';
import Address from '../../../components/Address/Address';
import DeleteShippingAddressDialog from './DeleteShippingAddressDialog';

interface DeliveryInformationsFormProps {
	client: Client;
	isRoleAdmin: boolean;
	onClickImportClientFromBaseDB: () => void;
}

const DeliveryInformationsForm = ({
	client,
	isRoleAdmin,
	onClickImportClientFromBaseDB,
}: DeliveryInformationsFormProps) => {
	const [index, setIndex] = useState(0);
	const [disabled, setDisabled] = useState(true);
	const [addressName, setAddressName] = useState<string | undefined>(undefined);
	const [clientAddressFilter, setClientAddressFilter] = useState<ClientShippingAddress | undefined>(undefined);

	const handleChange = (event: SelectChangeEvent<{ value: string }>) => {
		const newClientShippingAddress = client?.clientShippingAddress?.find((row) => row.id === event.target.value);
		setClientAddressFilter(newClientShippingAddress);
	};

	const getFormikName = (name: string) => `clientShippingAddress[${index}].${name}`;

	const isReferenced = !!client?.clientShippingAddress[index]?.referenceId;

	useEffect(() => {
		const MainAddress = client?.clientShippingAddress[0];
		setIndex(0);
		setClientAddressFilter(MainAddress);
		setAddressName(MainAddress?.address?.name);
		setDisabled(
			((MainAddress?.referenceId || MainAddress?.isMain) && !isRoleAdmin) || client?.clientShippingAddress.length === 0
		);
	}, [client, isRoleAdmin]);

	return (
		<Box my={3}>
			<Typography variant="h2">Informations de livraison</Typography>
			<Button color="primary" onClick={onClickImportClientFromBaseDB}>
				<RefreshIcon height="24px" />
				<Typography variant="h5" color="primary">
					Récupérer les informations de la base Elpev
				</Typography>
			</Button>
			<Grid item xs={12}>
				<Box mt={5} ml={15} mb={-1}>
					<DashedHeader>Adresse et horaires de livraison</DashedHeader>
				</Box>
				<Grid container spacing={10}>
					<Grid item xs={6}>
						<Box mr={5} ml={15}>
							<Box mt={2}>
								<LayoutField label="Nom commercial">
									<Box display="flex">
										<FormControl fullWidth>
											<Select
												displayEmpty
												/*defaultValue={client.clientShippingAddress[0]?.id}*/
												renderValue={() => <>{addressName}</>}
												onChange={handleChange}
												variant="outlined"
											>
												{client?.clientShippingAddress?.map((item, index) => (
													<MenuItem
														value={item.id}
														key={item.id}
														onClick={() => {
															setIndex(index);
															setAddressName(item.address?.name);
															(item.referenceId || item.isMain) && !isRoleAdmin
																? setDisabled(true)
																: setDisabled(false);
														}}
													>
														<Box display="flex">
															<Box gridRow={1}>
																<Typography variant="h4">{item.address?.name}</Typography>
																<Typography variant="body1">{item.address?.firstAddress}</Typography>
																<Typography variant="body1">{item.address?.city} </Typography>
															</Box>
															{item.isMain && (
																<Box pl={20} color={colors.gray100}>
																	<Typography variant={'body2'}>
																		<PersonPinCircleOutlinedIcon fontSize={'small'} />
																		Adresse Princiaple
																	</Typography>
																</Box>
															)}
														</Box>
													</MenuItem>
												))}
											</Select>
										</FormControl>

										<Box display="flex">
											<Box>
												<AddressDialogForm
													isRoleAdmin={isRoleAdmin}
													hasMainAddress={client.clientShippingAddress.some((address) => address.isMain)}
												/>
											</Box>

											{client.clientShippingAddress.length !== 0 &&
												!client?.clientShippingAddress[index]?.isMain &&
												client?.clientShippingAddress[index]?.address?.id && (
													<Box>
														<DeleteShippingAddressDialog
															addressId={client?.clientShippingAddress[index]?.address?.id}
															clientShippingAddressId={client?.clientShippingAddress[index]?.id}
															clientId={client.id}
														/>
													</Box>
												)}
										</Box>
									</Box>
								</LayoutField>

								<Box width="92.5%">
									<Address
										name={`clientShippingAddress[${index}].address`}
										isDisplayDepartements
										disabled={disabled}
										disabledDepartments={isReferenced ?? false}
									/>
								</Box>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={6}>
						<Box mr={5} ml={15} mt={2}>
							<WorkingHours
								client={clientAddressFilter}
								title={'Horaires'}
								index={index}
								disabled={isReferenced ?? false}
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>

			<Box my={5} ml={15}>
				<DashedHeader>Contact</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<LayoutFieldForm
							label={'Nom du directeur'}
							disabled={isReferenced ?? false}
							name={getFormikName('contact.directorName')}
						/>
					</Grid>
					<Grid item xs={5}>
						<LayoutFieldForm
							label={'Code entité'}
							disabled={isReferenced ?? false}
							name={getFormikName('contact.storeCode')}
						/>
					</Grid>
					<Grid item xs={5}>
						<Grid item xs={5}>
							<LayoutFieldForm label="Téléphone" disabled={disabled} name={getFormikName('contact.phone')} />
						</Grid>
					</Grid>
					<Grid item xs={5}>
						<Grid item xs={5}>
							<LayoutFieldForm label="Fax" disabled={disabled} name={getFormikName('contact.fax')} />
						</Grid>
					</Grid>
					<Grid item xs={5}>
						<LayoutFieldForm label="Email" disabled={disabled} name={getFormikName('contact.email')} />
						<LayoutField label="">
							<Box display="flex" alignItems="center" justifyContent="flex-start">
								<Field
									name={getFormikName('contact.isNotificated')}
									component={Checkbox}
									type="checkbox"
									size="small"
									disabled={isReferenced ?? disabled}
								/>

								<Box>
									<Typography variant="h5">Autoriser les notifications email</Typography>
								</Box>
							</Box>
						</LayoutField>
					</Grid>
					<Grid item xs={5}>
						<LayoutField label="Site web">
							<Box mb={3}>
								<FormikTextField
									name={getFormikName('contact.website')}
									fullWidth
									variant="outlined"
									disabled={isReferenced ?? disabled}
								/>
							</Box>
						</LayoutField>
					</Grid>
				</Grid>
			</Box>
			<Box mt={3} mx={15}>
				<DashedHeader>Commentaires</DashedHeader>
				<Grid container>
					<Grid item xs={12}>
						<Box mb={3}>
							<FormikTextField
								fullWidth
								multiline={true}
								rows={7}
								name={getFormikName('comment')}
								variant="outlined"
								disabled={disabled}
							/>
						</Box>
					</Grid>
				</Grid>
				<DashedHeader>Transport colis</DashedHeader>
				<Grid container justifyContent="space-between" style={{ marginBottom: '50px' }}>
					<Grid item xs={5}>
						<LayoutField label="Mode de livraison par défaut">
							<FormControl fullWidth>
								<FormikSelectField
									name={getFormikName('boxShippingMode')}
									variant="outlined"
									defaultValue={BoxShippingMode.BeforeThirteen}
									disabled={isReferenced ?? false}
								>
									{boxShippingModeOptions.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.name}
										</MenuItem>
									))}
								</FormikSelectField>
							</FormControl>
						</LayoutField>
					</Grid>
					<Grid item xs={5}>
						<LayoutField label="Poids maxi accepté par carton (en Kg)">
							<FormControl fullWidth>
								<FormikSelectField
									disabled={isReferenced ?? disabled}
									name={getFormikName('maxBoxWeight')}
									variant="outlined"
								>
									{
										//valeurs possibles entre 10 et 15
										Array.from(Array(6).keys()).map((option) => (
											<MenuItem key={option + 10} value={option + 10}>
												{option + 10}
											</MenuItem>
										))
									}
								</FormikSelectField>
							</FormControl>
						</LayoutField>
					</Grid>
				</Grid>

				<DashedHeader>Transport palette</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<LayoutField label="Type de transport par défaut">
							<FormControl fullWidth>
								<FormikSelectField
									disabled={isReferenced ?? disabled}
									name={getFormikName('palletShippingMode')}
									variant="outlined"
								>
									{palletShippingModeOptions.map((option) => (
										<MenuItem selected={false} key={option.key} value={option.key}>
											{option.value}
										</MenuItem>
									))}
								</FormikSelectField>
							</FormControl>
						</LayoutField>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
export default DeliveryInformationsForm;
