import { useQuery } from '@apollo/client';
import { InvoiceFilterInput } from 'graphql/types';
import { GET_INVOICES } from 'invoice/graphql/queries';
import { GetInvoicesQueryVariables, GetInvoicesQuery } from 'invoice/graphql/queries.definitions';

const useInvoicesQuery = (
	search?: GetInvoicesQueryVariables['search'],
	sort?: GetInvoicesQueryVariables['sort'],
	filter?: InvoiceFilterInput | null,
	pagination?: GetInvoicesQueryVariables['pagination']
) => {
	const { data, loading, fetchMore, refetch: refetchInvoices } = useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
		GET_INVOICES,
		{
			variables: {
				sort,
				search: { ...search, order: { ...search?.order, sellingPriceHt: Number(search?.order?.sellingPriceHt) } },
				filter,
				pagination,
			},
		}
	);

	return {
		invoices: data?.invoices.invoices,
		total: data?.invoices.total,
		loading,
		fetchMore,
		refetchInvoices,
	};
};

export default useInvoicesQuery;
