import { useQuery } from '@apollo/client';
import { GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables } from '../graphql/queries.definitions';
import { GET_INVOICE_BY_ID } from '../graphql/queries';

const useInvoiceByIdQuery = (id: string) => {
	const { data, loading } = useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(GET_INVOICE_BY_ID, {
		variables: {
			id,
		},
	});

	return { invoice: data?.invoice, loading };
};

export default useInvoiceByIdQuery;
