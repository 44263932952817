import { Dispatch } from 'react';
import { User } from '../graphql/types';

export interface StateType {
	accessToken: string | null;
	user?: User;
}

export type ActionType = Dispatch<{ type: string; payload: string }>;

export const initialState: StateType = {
	accessToken: null,
	user: undefined,
};

export interface AuthContextType {
	accessToken?: string;
	user?: User;
	login: (accessToken?: string) => void;
	logout: () => Promise<void>;
}
