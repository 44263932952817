export function not<U>(left: U[], right: U[]) {
	return left.filter((value) => right.indexOf(value) === -1);
}

export function intersection<U>(left: U[], right: U[]) {
	return left.filter((value: U) => right?.indexOf(value) !== -1);
}

export function union<U>(left: U[], right: U[]) {
	return [...left, ...not(right, left)];
}

export function difference<U>(left: U[], right: U[]) {
	return left.filter((value: U) => !right?.includes(value));
}
