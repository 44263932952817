import { GET_FABRICATION_TIMES } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { FabricationTimesQueryVariables, FabricationTimesQuery } from '../graphql/queries.definitions';

const useFabricationTimes = ({ quantity, supportId }: FabricationTimesQueryVariables['input']) => {
	const { data } = useQuery<FabricationTimesQuery, FabricationTimesQueryVariables>(GET_FABRICATION_TIMES, {
		variables: {
			input: {
				supportId,
				quantity,
			},
		},
	});

	return {
		fabricationTimes: data?.fabricationTimes,
	};
};

export default useFabricationTimes;
