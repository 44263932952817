import axios from 'axios';
const downloadFile = async (url: string, type?: string, name?: string): Promise<void> => {
	const filename = name || url.split('/').pop() || '';

	await axios({
		url,
		method: 'GET',
		responseType: 'blob',
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data], { type }));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
	});
};
export default downloadFile;
