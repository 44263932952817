import { useQuery } from '@apollo/client';
import { GetClientsQuery, GetClientsQueryVariables } from '../graphql/queries.definitions';
import { GET_CLIENTS } from '../graphql/queries';
import { ClientFilterInput } from '../../graphql/types';

const useClientsQuery = function (filterData?: ClientFilterInput) {
	const { data, loading, error, refetch } = useQuery<GetClientsQuery, GetClientsQueryVariables>(GET_CLIENTS, {
		variables: {
			filter: filterData ?? {},
		},
	});

	return { clients: data?.clients, loading, error, refetch };
};

export default useClientsQuery;
