import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone';
import colors from '../../app/theme/colors.scss';

import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { ReadFileUploading } from '../../utils/upload-file';
import { Document, Page } from 'react-pdf';

export interface FormikFilesFieldProps {
	accept?: string | string[];
	name: string;
	imagePlaceholder: string | null;
	isMultiple?: boolean;
}

export interface PreviewFile extends File {
	path?: string;
	type: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: '100%',
			height: '180px',
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: colors.white,
			borderRadius: 4,
		},
		iconCamera: {
			width: '72%',
			height: '130px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '50%',
			backgroundColor: colors.blue200,
			position: 'absolute',
		},
	})
);

const FormikFilesField = ({ accept, imagePlaceholder, name, isMultiple = false }: FormikFilesFieldProps) => {
	const classes = useStyles();
	const [, , helpers] = useField<File[]>(name);
	const [targetPreview, setTargetPreview] = useState<string | ArrayBuffer>('');

	const { getRootProps, getInputProps } = useDropzone({
		accept,
		multiple: isMultiple,
		onDrop: (acceptedFiles: PreviewFile[]) => {
			helpers.setValue(acceptedFiles);
			ReadFileUploading(acceptedFiles[0]).then((file) => setTargetPreview(file));
		},
	});

	const isImagePreview = !!targetPreview.toString().length && !targetPreview.toString().includes('application/pdf');
	const isPDFPreview = !!targetPreview.toString().length && targetPreview.toString().includes('application/pdf');

	useEffect(() => {
		if (imagePlaceholder && imagePlaceholder.length > 0) {
			setTargetPreview(imagePlaceholder);
		}
	}, [imagePlaceholder]);

	return (
		<Grid container data-testid={`field-${name}`}>
			<Grid item xs={2}>
				<Box {...getRootProps()} aria-label="files field">
					<input {...getInputProps()} multiple={false} />
					<Box className={classes.root} data-testid={'file-preview'}>
						{isImagePreview && (
							<img
								src={targetPreview.toString() ?? ''}
								data-testid={'image-thumbnail'}
								style={{
									width: '100%',
									height: '100%',
									position: 'relative',
									objectFit: 'contain',
								}}
								alt={name}
							/>
						)}

						<IconButton
							className={!isImagePreview ? classes.iconCamera : ''}
							aria-label="upload picture"
							component="span"
							size="large"
						>
							{!isImagePreview && !isPDFPreview && (
								<Box data-testid={'empty-file-field'}>
									<PhotoCameraTwoToneIcon htmlColor={colors.white} fontSize="large" />
								</Box>
							)}
							{isPDFPreview && (
								<Box sx={{ position: 'relative' }} data-testid={'pdf-thumnail'}>
									<Document file={targetPreview}>
										<Page pageNumber={1} height={150} loading={''} />
									</Document>
								</Box>
							)}
						</IconButton>
						<Box position="absolute" bottom={0} right={10}>
							<AddCircleSharpIcon htmlColor={colors.primary} fontSize="large" />
						</Box>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default FormikFilesField;
