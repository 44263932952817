import { ClariPrintCorrespondence, PricesSource } from 'graphql/types';
import { ProductDetailsType } from '../../product/definitions';
import React from 'react';
import { HeadLines, Tab } from '../../hooks/useManualTabs';

export enum CategoryType {
	OneSupply = 'PLV',
	ClariPrint = 'Print',
	ElpevBase = 'Roto',
	Digital = 'Digital',
	ClariPrintOs = '',
}

export enum FamilyAttribute {
	Format = 'Format',
	Impression = 'Impression',
	Support = 'Support',
	Façonnage = 'Façonnage',
	Conditionnement = 'Conditionnement',
}

export const PricesSourceOptions = [
	{ key: PricesSource.OneSupply, value: 'OneSupply' },
	{ key: PricesSource.ClariPrint, value: 'ClariPrint' },
	{ key: PricesSource.ElpevBase, value: 'Base Elpev' },
	{ key: PricesSource.Digital, value: 'Digital' },
	{ key: PricesSource.ClariPrintOs, value: 'ClariPrint & OS' },
];

export const ClariPrintCorrespondenceOptions = [
	{ key: ClariPrintCorrespondence.Feuillet, value: 'Feuillet' },
	{ key: ClariPrintCorrespondence.Depliants, value: 'Dépliants' },
	{ key: ClariPrintCorrespondence.ChemiseRabat, value: 'Chemise à rabats' },
	{ key: ClariPrintCorrespondence.Brouchures, value: 'Brochures' },
	{ key: ClariPrintCorrespondence.Enveloppe, value: ' Enveloppe' },
];

export enum DelivreryMode {
	expressMode = 'expressMode',
	standardMode = 'standardMode',
}

export const EMPTY_STRING = '(vide)';

export type AttributeValuesType = {
	name?: string | null;
	family?: string | null;
	data: ProductDetailsType[];
};

export const TAB_MENU_SUPPORT: Tab[] = [
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Caractéristiques correspondants',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Délai de Fabrication',
	},
];

export const HEADLINES: HeadLines[] = [
	{
		title: 'Caractéristiques correspondants au support',
		fontSize: 'h2',
	},
];

export const PATH = ['Caractéristiques par support'];
