import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import colors from 'app/theme/colors.scss';
import Path from 'components/Path';
import ManualTabs from 'components/tabs/ManualTabs';
import { PATH_NAMES } from 'Header/menus';
import { TransportTab } from './definitions';
import { GetTransportersQuery } from './graphql/queries.definitions';
import useTransportersQuery from './hooks/useTransportersQuery';
import useTransportersUpdate from './hooks/useTransportersUpdate';
import usePopin from '../../hooks/usePopin';
import ParcelTransportForm from './form/ParcelTransportForm';
import InsertParcelTransportDialog from './form/InsertParcelTransportDialog';
import { getTransportersToUpdate } from './helpers';

const ParcelTransportRoute = (): JSX.Element => {
	const { transporters } = useTransportersQuery();
	const { enqueueSnackbar } = useSnackbar();
	const { updateTransporters } = useTransportersUpdate();
	const tabs = useMemo(
		() =>
			transporters?.map((transporter, index) => ({
				ref: React.createRef<HTMLDivElement>(),
				title: transporter.name.toLocaleUpperCase(),
				index,
			})),
		[transporters]
	);
	const [currentTab, setCurrentTab] = useState<TransportTab | undefined>(undefined);
	const [openTransporterCreationPopin, renderTransporterCreationPopin, closeTransporterCreationPopin] = usePopin({
		headerTitle: 'Ajouter un nouveau transporteur',
		maxWidth: 'xs',
		fullWidth: true,
		isDisplayHeader: true,
	});
	const paths = [PATH_NAMES.Admin, PATH_NAMES.TransportRules, PATH_NAMES.ParcelTransport];

	const onSubmit = async (transporters: GetTransportersQuery['transporters']) => {
		try {
			await updateTransporters(getTransportersToUpdate(transporters));
			enqueueSnackbar('Modifications enregistrées', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Erreur', { variant: 'error' });
		}
	};

	useEffect(() => {
		if (tabs?.length) setCurrentTab(tabs[0]);
	}, [tabs]);

	return (
		<Formik<GetTransportersQuery>
			initialValues={{
				transporters: transporters ?? [],
			}}
			enableReinitialize
			onSubmit={async ({ transporters }) => {
				await onSubmit(transporters);
			}}
		>
			<Form>
				<Box position="fixed" width="100%" top={40} left={0} px={10} zIndex={50} bgcolor={colors.bodyBackgroundLight}>
					<Box display={'flex'}>
						<Box flexGrow={1}>
							<Path paths={paths} />
						</Box>
					</Box>
					<Divider />
					<Box display="flex" justifyContent="space-between" mb={2} pt={5}>
						<Box display="flex">
							<>
								<Typography variant="h2">Liste des transporteurs</Typography>
								<Button onClick={openTransporterCreationPopin} color="primary">
									<AddCircleOutlinedIcon color="action" />
									Ajouter
								</Button>
								{renderTransporterCreationPopin(
									<InsertParcelTransportDialog
										onCloseTransporterCreationPopin={closeTransporterCreationPopin as () => void}
									/>
								)}
							</>
						</Box>
						<Box>
							<Button color="primary" type="reset">
								Annuler
							</Button>
							<Button variant="contained" color="primary" type="submit">
								Enregistrer les modifications
							</Button>
						</Box>
					</Box>
					{tabs && currentTab && (
						<ManualTabs currentTab={currentTab} tabs={tabs} onChange={(tab) => setCurrentTab(tab)} />
					)}
				</Box>
				<Box mt={30} mx={20}>
					{currentTab && <ParcelTransportForm currentTab={currentTab} />}
				</Box>
			</Form>
		</Formik>
	);
};

export default ParcelTransportRoute;
