import { Box, Divider } from '@mui/material';
import React from 'react';
import Path from 'components/Path';
import AddFileReference from './add-file-reference-popin/AddFileReference';
import FileReferencesDatatable from './components/FileReferencesDatatable/FileReferencesDatatable';
import { PATH_NAMES } from 'Header/menus';

const FileReference = (): JSX.Element => {
	const paths = [PATH_NAMES.FileReference];

	return (
		<Box ml={7} mr={7}>
			<Box display={'flex'}>
				<Box flexGrow={1}>
					<Path paths={paths} />
				</Box>
				<AddFileReference />
			</Box>
			<Divider />

			<FileReferencesDatatable />
		</Box>
	);
};

export default FileReference;
