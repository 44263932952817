import { gql } from '@apollo/client';
import { TRANSPORTER_FRAGMENT } from './mutations';

const GET_TRANSPORTERS = gql`
	query GetTransporters($filter: TransporterFilterInput) {
		transporters(filter: $filter) {
			...Transporter
		}
	}
	${TRANSPORTER_FRAGMENT}
`;

export { GET_TRANSPORTERS };
