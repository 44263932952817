import React from 'react';
import { Box } from '@mui/material';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { useProductsDistributionTable } from '../../../hooks/useProductsDistributionTable';
import { OrderFormModel } from '../definition';

interface ProductDistributionProps {
	order: OrderFormModel;
	headerTable: string[];
}

const ProductsDistribution = ({ order, headerTable }: ProductDistributionProps): JSX.Element => {
	const tableInstance = useProductsDistributionTable(order, headerTable);
	return (
		<Box p={4}>
			<AppListTable tableInstance={tableInstance} />
		</Box>
	);
};

export default ProductsDistribution;
