import { useEffect, useRef } from 'react';

export const useInfiniteScroll = (onLoadMore?: () => void, isLoading?: boolean, isComplete = false) => {
	const waypointRef = useRef<HTMLDivElement>(null);
	const prevIntersection = useRef(false);

	useEffect(() => {
		const element = waypointRef.current;
		const observer = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting && !isLoading && !isComplete && !prevIntersection.current) {
				onLoadMore && onLoadMore();
			}
			prevIntersection.current = entry.isIntersecting;
		});
		if (element) {
			observer.observe(element);
		}
		return () => {
			if (element) {
				observer.unobserve(element);
			}
			observer.disconnect();
		};
	}, [onLoadMore, isLoading, isComplete]);

	return { waypointRef };
};
