import gql from 'graphql-tag';
import ADDRESS_FRAGMENT from '../../graphql/fragments/address/addressFragment';
import CONTACT_FRAGMENT from '../../graphql/fragments/contact/contactFragment';
import SUPPLIER_FRAGMENT from '../../graphql/fragments/supplier/supplierFragment';

const INSERT_SUPPLIER = gql`
	mutation InsertSupplier($name: String!) {
		insertSupplier(name: $name) {
			name
		}
	}
`;

const UPDATE_SUPPLIER = gql`
	mutation updateSupplier($supplierId: String!, $data: SupplierUpdateInput!) {
		updateSupplier(supplierId: $supplierId, data: $data) {
			name
			user {
				email
			}
			discounts {
				ca
				rfa
			}
			rythme
			rsp
			escomptes {
				escompte
				client
				dayLimitTime
			}
			siteAddress1 {
				...Address
			}
			siteAddress2 {
				...Address
			}
			rspEnd
			rspStart
			urgentMode
			urgentModeSupplement
			expressModeSupplement
			expressMode
			transportModes {
				title
			}
		}
	}
	${ADDRESS_FRAGMENT}
`;

const UPDATE_SUPPLIER_BY_COMPANYBASE = gql`
	mutation UpdateSupplierByCompanyBase($companyBase: String!, $supplierId: String!) {
		supplier: updateSupplierByCompanyBase(companyBase: $companyBase, supplierId: $supplierId) {
			...Supplier
			escomptes {
				escompte
				client
				dayLimitTime
			}
			discounts {
				ca
				rfa
			}
			transportModes {
				title
			}
			siteAddress1 {
				...Address
			}
			siteAddress2 {
				...Address
			}
			supplierContact {
				...Contact
			}
		}
	}
	${SUPPLIER_FRAGMENT}
	${ADDRESS_FRAGMENT}
	${CONTACT_FRAGMENT}
`;

export { INSERT_SUPPLIER, UPDATE_SUPPLIER, UPDATE_SUPPLIER_BY_COMPANYBASE };
