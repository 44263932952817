import { useMutation } from '@apollo/client';
import { InsertSupportMutation, InsertSupportMutationVariables } from '../graphql/mutations.definitions';
import { INSERT_SUPPORT } from '../graphql/mutations';
import { GET_SUPPORTS } from '../graphql/queries';

const useSupportUpdate = () => {
	const [insertSupportMutation] = useMutation<InsertSupportMutation, InsertSupportMutationVariables>(INSERT_SUPPORT);
	const insertSupport = async (name: string) => {
		await insertSupportMutation({
			variables: { name },
			refetchQueries: [
				{
					query: GET_SUPPORTS,
				},
			],
			awaitRefetchQueries: true,
		});
	};
	return { insertSupport };
};

export default useSupportUpdate;
