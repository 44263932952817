import { Client, User } from '../../graphql/types';

export const userFormInitialValue: User & {
	password: string;
	roleId: string;
	clientIds: string[];
	userClients: Client[];
} = {
	id: '',
	familyName: '',
	givenName: '',
	phoneNumber: '',
	email: '',
	roleId: '',
	isActive: true,
	canViewSalePrices: false,
	canViewBuyingPrices: false,
	replacedFrom: null,
	replacedTo: null,
	replacedById: null,
	password: '',
	role: { id: '', name: '', description: '', permissions: [], users: [] },
	userClients: [],
	clientIds: [],
	supplier: {
		name: '',
		id: '',
		createdAt: '',
		updatedAt: '',
		lastConnection: '',
		isActif: true,
		supplierContact: [],
	},
};
