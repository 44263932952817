import React from 'react';
import { FamilyAttribute } from 'admin/support/definitions';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { sortProductDetailsByFamily } from '../helpers';
import { ProductFromModel } from '../definitions';
import ConditionnementDetails from './ConditionementDetails';

interface CharacteristicsProps {
	product: ProductFromModel;
}

export const CharacteristicByProduct = ({ product }: CharacteristicsProps): JSX.Element => {
	return (
		<>
			{Object.values(FamilyAttribute).map((family, index) => (
				<Box key={index}>
					<Box>
						<u>
							<Typography variant="h4">{family}</Typography>
						</u>
					</Box>

					<Box>
						<Box px={5} pl={10}>
							{sortProductDetailsByFamily(product).map((detail, i) =>
								detail.family?.toLowerCase() !== 'conditionnement'
									? family.toLowerCase() === detail.family?.toLowerCase() && (
											<Box display="flex" key={i}>
												<Box display="flex">
													<Typography variant="h5">
														{detail.name} : <b>{detail.value} </b>
													</Typography>
												</Box>
											</Box>
									  )
									: family.toLowerCase() === 'conditionnement' && (
											<ConditionnementDetails detail={product.conditionnement} key={i} />
									  )
							)}
						</Box>
					</Box>
				</Box>
			))}
		</>
	);
};
