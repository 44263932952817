import React, { memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Price from './Price';
import colors from '../../../../app/theme/colors.scss';
import { formatNumberDisplay } from '../../../../utils/prices';

interface ProductPriceDetailsProps {
	buyingPriceHt?: number | null;
	sellingPriceHt?: number | null;
	transportBuyingPriceHt?: number | null;
	transportSellingPriceHt?: number | null;
	prepressPriceHt?: number | null;
	prepressSellingPriceHt?: number | null;
	productSellingPriceHt?: number | null;
	tvaRate?: number | null;
	sellingPriceTtc?: number | null;
	tva?: number | null;
	transporterPurchaseExcludingVATWithRfa?: number | null;
	productPurchasePriceExcludingVATWithRFA?: number | null;
}

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			marginTop: '5px',
			paddingLeft: '7px',
			boxShadow: 'none',
		},
		priceValue: {
			color: colors.black100,
			borderLeft: '4px solid',
			borderRadius: '4px',
			borderLeftColor: colors.turquoise500,
			paddingLeft: '3px',
			boxShadow: 'none',
		},
	})
);

const ProductPriceDetails = ({
	buyingPriceHt,
	sellingPriceHt,
	transportBuyingPriceHt,
	transportSellingPriceHt,
	prepressPriceHt,
	prepressSellingPriceHt,
	productSellingPriceHt,
	tvaRate,
	sellingPriceTtc,
	tva,
	transporterPurchaseExcludingVATWithRfa,
	productPurchasePriceExcludingVATWithRFA,
}: ProductPriceDetailsProps): JSX.Element => {
	const classes = useStyles();

	return (
		<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
			<Grid item>
				<Price label="PA Produit HT (€)" value={buyingPriceHt ?? productPurchasePriceExcludingVATWithRFA} />
			</Grid>
			<Grid item>
				<Price label="PV Produit HT (€)" value={productSellingPriceHt} />
			</Grid>
			<Grid item>
				<Price label="PA Transport HT (€)" value={transporterPurchaseExcludingVATWithRfa ?? transportBuyingPriceHt} />
			</Grid>
			<Grid item>
				<Price label="PV Transport HT (€)" value={transportSellingPriceHt} />
			</Grid>
			<Grid item>
				<Price label="Prix Prépresse HT (€)" value={prepressPriceHt} />
			</Grid>
			<Grid item>
				<Price label="PV Prépresse HT (€)" value={prepressSellingPriceHt} />
				<Box pt="5px">
					<Typography variant="h5">Taux T.V.A. (%)</Typography>
				</Box>
				<Box pt="5px">
					<Typography variant="h5">Montant T.V.A. (€)</Typography>
				</Box>
				<Box pt="5px">
					<Typography variant="h5">Prix de vente TTC (€)</Typography>
				</Box>
			</Grid>
			<Grid item>
				<Price label="Prix vente HT (€)" value={sellingPriceHt} />
				<Paper className={classes.paper}>
					<Typography variant="h5">{formatNumberDisplay(tvaRate, 2) || '--'}</Typography>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="h5">{formatNumberDisplay(tva, 2) || '--'}</Typography>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="h5" color="error">
						{formatNumberDisplay(sellingPriceTtc, 2) || '--'}
					</Typography>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default memo(ProductPriceDetails);
