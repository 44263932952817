import { PriceCell } from '../../../../components/summaryPrice/SummaryPrice';

export const HORIZONTAL_QUOTE_DATA_CELL: PriceCell[] = [
	{
		width: '25%',
		name: 'selectedProduct.productName',
		isBorder: true,
		hasRate: false,
		label: 'Produit',
	},
	{
		width: '12%',
		name: 'summaryPrice.quantity',
		isBorder: false,
		hasRate: false,
		label: 'Quantité',
		direction: 'column',
	},
	{
		width: '15%',
		name: 'summaryPrice.numberVersion',
		isBorder: false,
		hasRate: false,
		label: 'Nb Version',
		direction: 'column',
	},
	{
		width: '15%',
		name: 'summaryPrice.productPurchasePriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PA produit HT (€)',
	},
	{
		width: '15%',
		name: 'summaryPrice.productSalesPriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PV produit HT (€)',
	},
	{
		width: '15%',
		name: 'summaryPrice.transportPurchasePriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PA Transport HT (€)',
	},
	{
		width: '15%',
		name: 'summaryPrice.transportSalesPriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PV Transport HT (€)',
	},
	{
		width: '15%',
		name: 'summaryPrice.cost',
		isBorder: false,
		hasRate: false,
		label: 'Prix vente HT (€)',
	},
];

export const VERTICAL_QUOTE_DATA_CELL: PriceCell[] = [
	{
		width: '100%',
		name: 'summaryPrice.sellingPriceMargin',
		isBorder: false,
		hasRate: true,
		label: 'Marge (€)',
	},
	{
		width: '100%',
		name: 'summaryPrice.sellingPriceMarginRate',
		isBorder: false,
		hasRate: true,
		label: 'Taux de marge (%)',
	},
	{
		width: '100%',
		name: 'summaryPrice.vatAmount',
		isBorder: false,
		hasRate: true,
		label: 'Estimatif taxe Citéo (€)',
	},
];

export const initialAddClientPriceToQuotes = {
	quantity: 0,
	versionNumber: 1,
	productPurchasePriceExcludingVAT: 0,
	productSalesPriceExcludingVAT: 0,
	transportPurchasePriceExcludingVAT: 0,
	transportSalesPriceExcludingVAT: 0,
	cost: 0,
};
