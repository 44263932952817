import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SupplierTva from '../../components/SupplierTva';
import SupplierContact from '../../components/SupplierContact';
import Address from '../../../components/Address/Address';
import DashedHeader from '../../../components/DashedHeader';
import SupplierElpevFromBaseDB from '../../components/SupplierElpevFromBaseDB';
import SupplierLoginInformation from '../../components/SupplierLoginInformation';

interface SupplierInformationProps {
	title: string;
	isUserSupplier: boolean;
	isRoleAdmin: boolean;
}

const SupplierInformation = ({ title, isUserSupplier, isRoleAdmin }: SupplierInformationProps): JSX.Element => (
	<Box mb={5} pt={5}>
		<Box mb={6}>
			<Grid container>
				<Grid item xs={1} />
				<SupplierElpevFromBaseDB title={title} />
			</Grid>
		</Box>
		<SupplierLoginInformation isUserSupplier={isUserSupplier} />
		<SupplierTva isRoleAdmin={isRoleAdmin} />
		<Box mb={7}>
			<Grid container>
				<Grid item xs={2} />
				<Grid item xs={3}>
					<DashedHeader>Adresse site 1</DashedHeader>
					<Address name="siteAddress1" isDisplayDepartements disabled={!isRoleAdmin} isDisplayAddressName />
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={3}>
					<DashedHeader>Adresse site 2</DashedHeader>
					<Address name="siteAddress2" isDisplayAddressName />
				</Grid>
				<Grid item xs={2} />
			</Grid>
		</Box>
		<SupplierContact isRoleAdmin={isRoleAdmin} />
	</Box>
);

export default memo(SupplierInformation);
