import * as Yup from 'yup';

const getClientSchema = (isRoot: boolean) => {
	const structures = isRoot
		? Yup.array().of(
				Yup.object().shape({
					title: Yup.string().required('Vous devez spécifier un titre'),
				})
		  )
		: Yup.array();

	return Yup.object().shape({
		structures: structures,
		name: Yup.string().required('Vous devez spécifier un nom'),

		/*companyBase: Yup.string()
			.required('Vous devez spécifier une société base')
			.nullable()
		siretNumber: Yup.string().required('Vous devez spécifier le numéro de siret').nullable(),
		vatNumber: Yup.string().required('Vous devez spécifier le nom de TVA').nullable(),
		vatType: Yup.string().required('Vous devez spécifier le type de TVA').nullable(),*/
		...(!isRoot && { rootClientId: Yup.string().required('Vous devez spécifier une entité mère') }),
		type: Yup.string().required('Vous devez spécifier le type client'),
		//mainRfaRate: Yup.number().positive('Le nombre doit être positif').nullable(),
		defaultCommercialShippingMargin: Yup.number().positive('Le nombre doit être positif').nullable(),
		/*billingAddress: Yup.object().shape({
			name: Yup.string().required("Vous devez spécifier le nom de l'adresse de facturation"),
			city: Yup.string().required('Vous devez spécifier une ville'),
		}),
		shippingAddress: Yup.object().shape({
			name: Yup.string().required("Vous devez spécifier le nom de l'adresse livraison"),
			city: Yup.string().required('Vous devez spécifier une ville'),
		}),*/
	});
};

export default getClientSchema;
