import * as XLSX from 'xlsx';
import { Box, Button } from '@mui/material';
import React, { useMemo } from 'react';
import { FamilyAttribute } from '../admin/support/definitions';
import { Attribute, AttributeKey } from '../graphql/types';
import { GetProductsQuery } from './graphql/queries.definitions';
import { SupplierPricesFormModel } from '../supplier/definitions';

interface ExportPurchasePricesProps {
	suppliers?: SupplierPricesFormModel[];
	products: GetProductsQuery['products'];
	supportName: string;
	attributes: Attribute[];
}

const ExportPurchasePrices = ({
	suppliers,
	products,
	supportName,
	attributes,
}: ExportPurchasePricesProps): JSX.Element => {
	const sortedAttributes = useMemo(() => {
		let sortedAttributes: Attribute[] = [];
		Object.values(FamilyAttribute).forEach((family) => {
			const attributesList = attributes.filter((attribute) => attribute.family?.toLowerCase() === family.toLowerCase());
			sortedAttributes.push(...attributesList);
		});
		return sortedAttributes;
	}, [attributes]);

	const newProducts = useMemo(() => {
		return products?.map((product) => {
			let data: Record<string, string | null | undefined> = {};
			sortedAttributes.forEach((attribute) => {
				data['support'] = supportName;
				data['Code AT'] = product.codeAt;
				/**
				 * @todo à faire évoluer quand les règles de conditionnement seront définies
				 */
				data[attribute.name] = attribute.key
					? attribute.key !== AttributeKey.Conditionnement
						? product[attribute.key]
						: ''
					: '';
			});
			return data;
		});
	}, [supportName, products, sortedAttributes]);

	const newSuppliersPrices = useMemo(() => {
		return suppliers?.map((supplier) => {
			return supplier.productsSuppliersPrices?.map((price) => {
				return {
					'Code AT': price.product?.codeAt,
					Fournisseur: supplier.name,
					Quantité: price.quantity,
					'Prix calage HT(\u20ac)': price.prixCalage && Math.floor(price.prixCalage * 100) / 100,
					'Prix hors calage HT(\u20ac)': price.prixHorsCalage && Math.floor(price.prixHorsCalage * 100) / 100,
					'Prix Ex.Sup.HT(\u20ac)': price.prixExemplaireSupp && Math.floor(price.prixExemplaireSupp * 100000) / 100000,
					'Prix Changt par plaque HT(\u20ac)':
						price.prixChangementPlaque && Math.floor(price.prixChangementPlaque * 100) / 100,
					'Nb pose maxi': price.nbPosesMax,
					'Autre prix fixe HT(\u20ac)': price.autrePrixFixe && Math.floor(price.autrePrixFixe * 100) / 100,
					'Prix cliche(\u20ac)': price.prixCliche && Math.floor(price.prixCliche * 100) / 100,
					'Prix outillage(\u20ac)': price.prixOutillage && Math.floor(price.prixOutillage * 100) / 100,
					'Poid unitaire(Gr)': price.poidsUnitaireGr && Math.round(price.poidsUnitaireGr * 100) / 100,
					Franco: price.franco !== null ? (price.franco ? 'Oui' : 'Non') : null,
					Delais: price.delais,
					'Date fin validit\u00e9': price.validityDate ? new Date(price.validityDate)?.toLocaleDateString() : null,
				};
			});
		});
	}, [suppliers]);

	const ExportToXlsx = () => {
		const wb = XLSX.utils.book_new();
		if (newSuppliersPrices) {
			XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(newProducts), 'Produits');
			newSuppliersPrices.length > 1 &&
				XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(newSuppliersPrices.flat()), "Recap prix d'achat");
			suppliers?.forEach((supplier, index) =>
				XLSX.utils.book_append_sheet(
					wb,
					XLSX.utils.json_to_sheet(newSuppliersPrices[index] ?? []),
					supplier.name.substr(0, 30)
				)
			);
			XLSX.writeFile(wb, 'Extract PA OS, ' + new Date().toLocaleString() + '.xlsx');
		}
	};
	return (
		<Box>
			<Button
				variant={'contained'}
				color="primary"
				onClick={() => {
					ExportToXlsx();
				}}
			>
				Extraire les prix d'achat
			</Button>
		</Box>
	);
};

export default ExportPurchasePrices;
