import { gql } from '@apollo/client';

export const INSERT_FILE_REFERENCE = gql`
	mutation InsertFileReference($fileReference: FileReferenceInsertInput!) {
		insertFileReference(fileReference: $fileReference) {
			id
			operationName
		}
	}
`;
