import { useCallback } from 'react';
import { isNumber, isString } from '../utils/typeGuards';

const useStorage = () => {
	const setDataLocalStorage = useCallback(<T>(key: string, value: T) => {
		if (isString(value)) {
			localStorage.setItem(key, value);
		} else if (isNumber(value)) {
			localStorage.setItem(key, value.toString());
		} else {
			localStorage.setItem(key, JSON.stringify(value));
		}
	}, []);

	const getDataLocalStorage = useCallback((key: string) => {
		const data = localStorage.getItem(key);

		if (data) {
			if (isNaN(parseFloat(data))) {
				try {
					//data type is object
					return JSON.parse(data);
				} catch {
					//data type is string
					return data;
				}
			}
			//data type is float
			return parseFloat(data);
		}
		//data is null
		return null;
	}, []);

	const removeDataLocalStorage = useCallback(async (...keys: string[]) => {
		try {
			for (const key of keys) {
				await localStorage.removeItem(key);
			}
		} catch (e) {
			return;
		}
	}, []);

	const clearAllLocalStorage = useCallback(async () => {
		await localStorage.clear();
	}, []);

	return {
		getDataLocalStorage,
		setDataLocalStorage,
		removeDataLocalStorage,
		clearAllLocalStorage,
	};
};

export default useStorage;
