import * as React from 'react';
import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Path from '../../components/Path';
import { PATH_NAMES } from '../../Header/menus';
import FamilyAttributes from './FamilyAttributes';
import {
	CategoryType,
	ClariPrintCorrespondenceOptions,
	FamilyAttribute,
	HEADLINES,
	PricesSourceOptions,
	TAB_MENU_SUPPORT,
} from './definitions';
import colors from '../../app/theme/colors.scss';
import { useSnackbar } from 'notistack';
import useSupportByIdQuery from './hooks/useSupportByIdQuery';
import useAttributesQuery from './hooks/useAttributesQuery';
import { ClariPrintCorrespondence, PricesSource, SupportCategory } from '../../graphql/types';
import SupportUpdate from './hooks/useSupportUpdate';
import { typeNotifications } from '../../utils/definition';
import ManualTabs from '../../components/tabs/ManualTabs';
import FabricationTimesTable from './components/fabrication-times/FabricationTimesTable';
import { GetSupportByIdQuery } from './graphql/queries.definitions';
import { StatusSwitch } from 'components/Switch/StatusSwitch';
import { getSupportCategory } from './helpers';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiFormControlLabel-label': {
				color: colors.gray600,
			},
		},
		form: {
			width: 150,
			height: 33,
			marginRight: '6rem',
			marginTop: '0.30rem',
		},
	})
);
export type Fabrication = GetSupportByIdQuery['support']['fabricationTimes'];

const Support = (): JSX.Element => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { id: supportId } = useParams<{ id: string }>();
	const { support } = useSupportByIdQuery(supportId);
	const { attributes } = useAttributesQuery();
	const { updateSupport } = SupportUpdate();
	const [category, setCategory] = useState<SupportCategory | null>(null);
	const [pricesSource, setPricesSource] = useState<PricesSource | null>(null);
	const [clariPrintCorrespondence, setClariPrintCorrespondence] = useState<ClariPrintCorrespondence | null | undefined>(
		null
	);
	const [ecotaxe, setEcotaxe] = useState<boolean>(false);

	const [fabricationTimesData, setFabricationTimesData] = useState<Fabrication>([]);
	const [isErrorPricesSource, setErrorPricesSource] = useState<boolean>(false);
	const [isActif, setIsActif] = useState<boolean>(false);
	const [stateSupportsAttributes, setStateSupportsAttributes] = useState<Record<string, boolean>>({});
	const paths: (string | undefined)[] = [PATH_NAMES.Admin, PATH_NAMES.Support, support?.name];

	const headlines = [
		{
			title: support?.name ? support?.name : '',
			fontSize: HEADLINES[0].fontSize,
		},
	];

	const handleChangePricesSource = (event: SelectChangeEvent<unknown>) => {
		setPricesSource((event.target.value as unknown) as PricesSource);
		setErrorPricesSource(false);
		setCategory(getSupportCategory(event.target.value as PricesSource));
	};

	const handleChangeClariPrintCorrespondence = (event: SelectChangeEvent<unknown>) => {
		setClariPrintCorrespondence((event.target.value as unknown) as ClariPrintCorrespondence);
	};

	const handleChangeEcotaxe = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEcotaxe(event.target.checked);
	};

	const handleChangeAttribute = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStateSupportsAttributes({ ...stateSupportsAttributes, [event.target.name]: event.target.checked });
	};

	let attributesChecked: { attributeId: string; supportId: string }[] = [];
	for (let i = 0; i < Object.keys(stateSupportsAttributes).length; i++) {
		const row = supportId
			? {
					supportId,
					attributeId: Object.keys(stateSupportsAttributes)[i],
			  }
			: null;
		if (Object.values(stateSupportsAttributes)[i] && row) {
			attributesChecked.push(row);
		}
	}

	useEffect(() => {
		if (support) {
			setFabricationTimesData(support?.fabricationTimes);
			setEcotaxe(support.excludingEcotax);
			setIsActif(support.isActif ?? false);
			if (support.pricesSource) {
				setPricesSource(support.pricesSource);
				setCategory(getSupportCategory(support.pricesSource) ?? null);
				setClariPrintCorrespondence(support.clariPrintCorrespondence);
			} else {
				setPricesSource(null);
				setCategory(null);
				setClariPrintCorrespondence(null);
			}
		}
	}, [support]);

	useEffect(() => {
		let listAttributesChecked: Record<string, boolean> = {};
		const supportsAttributesId = support?.supportAttributes?.map((row) => row.id);
		attributes?.forEach((row) =>
			supportsAttributesId?.includes(row.id)
				? (listAttributesChecked[row.id] = true)
				: (listAttributesChecked[row.id] = false)
		);
		setStateSupportsAttributes(listAttributesChecked);
	}, [support, attributes]);

	const save = async () => {
		if (pricesSource) {
			await updateSupport(
				supportId,
				ecotaxe,
				category,
				pricesSource,
				isActif,
				clariPrintCorrespondence,
				attributesChecked,
				fabricationTimesData ?? []
			);
			enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
		} else {
			setErrorPricesSource(true);
		}
	};

	const cancel = () => {
		let listAttributesChecked: Record<string, boolean> = {};
		const supportsAttributesId = support?.supportAttributes?.map((row) => row.id);
		attributes?.forEach((row) =>
			supportsAttributesId?.includes(row.id)
				? (listAttributesChecked[row.id] = true)
				: (listAttributesChecked[row.id] = false)
		);
		if (support) {
			setCategory(support.category ?? null);
			setPricesSource(support.pricesSource ?? null);
			setClariPrintCorrespondence(support.clariPrintCorrespondence ?? null);
			setEcotaxe(support.excludingEcotax);
			setIsActif(support.isActif ?? false);
			setStateSupportsAttributes(listAttributesChecked);
		}
	};

	const [currentTab, setCurrentTab] = useState(TAB_MENU_SUPPORT[0]);

	const handleTabChange = (tab: typeof TAB_MENU_SUPPORT[0]) => {
		const offsetTop = tab!.ref!.current!.offsetTop;
		setCurrentTab(tab);

		if (offsetTop) window.scroll(0, offsetTop - 230);
	};

	return (
		<Box mt={30}>
			<Box position="fixed" width="100%" top={30} left={0} px={10} bgcolor={colors.white} zIndex={100}>
				<Box>
					<Path paths={paths} />
					<Divider />
				</Box>

				<Box display="flex" justifyContent="space-between" mb={4} pt={5}>
					<Box display="flex" flexDirection="column">
						{headlines.map(({ title, fontSize }) => (
							<Box width="100%" key={title}>
								<Typography variant={fontSize}>{title}</Typography>
							</Box>
						))}
					</Box>

					<Box>
						<Button color="primary" type="reset" onClick={cancel}>
							Annuler
						</Button>
						<Button type="submit" variant="contained" color="primary" onClick={save}>
							{'Enregistrer les modifications'}
						</Button>
					</Box>
				</Box>

				<ManualTabs currentTab={currentTab} tabs={TAB_MENU_SUPPORT} onChange={handleTabChange} />
			</Box>

			<Box>
				<Box mt={4} mx={12}>
					<Box mb={5} pt={5} display="flex">
						<Typography variant="h2">Caractéristiques correspondants</Typography>
						<Box mt={0.9} ml={1}>
							<StatusSwitch status={isActif} onStatusChange={() => setIsActif(!isActif)} />
						</Box>
					</Box>
					<div ref={TAB_MENU_SUPPORT[0].ref}>
						<Box ml={7} mr={7}>
							{attributes && (
								<Box>
									<Box display="flex">
										<Box mt={1} mr={1} color={colors.gray100}>
											<Typography variant="h4">Source des prix:</Typography>
										</Box>
										<FormControl variant="outlined" error={isErrorPricesSource}>
											<Select
												defaultValue={pricesSource}
												className={classes.form}
												value={pricesSource}
												onChange={handleChangePricesSource}
											>
												{PricesSourceOptions.map((option) => (
													<MenuItem selected={false} key={option.key} value={option.key}>
														<Typography variant="h6">{option.value}</Typography>
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<Box mt={1} mr={1} color={colors.gray100}>
											<Typography variant="h4">Catégorie OneSupply:</Typography>
										</Box>
										<FormControl variant="outlined">
											<TextField
												className={classes.form}
												value={pricesSource ? CategoryType[pricesSource] : ''}
												disabled
												variant="outlined"
											/>
										</FormControl>

										{pricesSource === PricesSource.ClariPrint && (
											<>
												<Box mt={1} mr={1} color={colors.gray100}>
													<Typography variant="h4">Correspondance ClariPrint:</Typography>
												</Box>
												<FormControl variant="outlined">
													<Select
														className={classes.form}
														value={clariPrintCorrespondence ?? ''}
														onChange={handleChangeClariPrintCorrespondence}
													>
														{ClariPrintCorrespondenceOptions.map((option) => (
															<MenuItem selected={false} key={option.key} value={option.key}>
																<Typography variant="h6">{option.value}</Typography>
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</>
										)}

										<FormControlLabel
											control={<Checkbox color="primary" checked={ecotaxe} onChange={handleChangeEcotaxe} />}
											label="Hors Ecotaxe"
										/>
									</Box>
									<Box display="flex" pt={2}>
										{Object.values(FamilyAttribute).map((family, index) => (
											<Box width="20%" pr={Object.values(FamilyAttribute).length - 1 !== index ? 2.5 : 0} key={index}>
												<FamilyAttributes family={family} />
												<Box display="inline">
													{attributes.map(
														(rowAttribute) =>
															family.toLowerCase() === rowAttribute.family?.toLowerCase() && (
																<Box key={rowAttribute.id}>
																	<FormControlLabel
																		className={classes.root}
																		key={rowAttribute.id}
																		control={
																			<Checkbox
																				key={rowAttribute.id}
																				size="small"
																				color="primary"
																				checked={stateSupportsAttributes[rowAttribute.id] ?? false}
																				onChange={handleChangeAttribute}
																				name={rowAttribute.id}
																			/>
																		}
																		label={rowAttribute.name}
																	/>
																</Box>
															)
													)}
												</Box>
											</Box>
										))}
									</Box>
								</Box>
							)}
						</Box>
					</div>
				</Box>
			</Box>
			<Box ml={10}>
				<Divider />
				<Box mb={3} pt={3}>
					<Typography variant="h2">Delais de fabrication</Typography>
				</Box>
				<div ref={TAB_MENU_SUPPORT[1].ref}>
					<FabricationTimesTable
						support={support}
						setFabricationTimesData={setFabricationTimesData}
						fabricationTimesData={fabricationTimesData ?? []}
					/>
				</div>
			</Box>
		</Box>
	);
};

export default Support;
