import React, { Dispatch, SetStateAction, useState } from 'react';
import ButtonDisplayContent from '../components/ButtonDisplayContent';

const useCollapse = (displayContentValue = true, setDisplayContentFromOutside?: Dispatch<SetStateAction<boolean>>) => {
	const [displayContent, setDisplayContent] = useState<boolean>(displayContentValue);

	const renderCollapseComponent = (isActive?: boolean) => (
		<ButtonDisplayContent
			displayContent={setDisplayContentFromOutside ? displayContentValue : displayContent}
			OnDisplayContent={setDisplayContentFromOutside ?? setDisplayContent}
			isActive={isActive}
		/>
	);

	return { renderCollapseComponent, displayContent, setDisplayContent };
};

export default useCollapse;
