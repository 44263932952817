import { gql } from '@apollo/client';

export const TRANSPORTER_FRAGMENT = gql`
	fragment Transporter on Transporter {
		id
		name
		saturdayDeliveryExtra
		nonMechanisableParcel
		nonStandardParcel
		corsicaDeliveryExtra
		maxMechanisableParcelLengthCm
		maxMechanisableParcelWidthCm
		minWeightKg
		fuelSurchargeRate
		standardDeliveryPrices
		expressDeliveryPrices
		isActif
		nonRegularPickup
		supplementOverThirtyKg
		hardlyAccessibleArea
		hardlyAccessibleAreas {
			postalCode
		}
	}
`;

const UPDATE_TRANSPORTERS = gql`
	mutation UpdateTransporters($Transporters: [TransporterUpdateInput!]!) {
		updateTransporters(Transporters: $Transporters) {
			...Transporter
		}
	}
	${TRANSPORTER_FRAGMENT}
`;

const INSERT_TRANSPORTER = gql`
	mutation InsertTransporter($name: String!) {
		insertTransporter(name: $name) {
			...Transporter
		}
	}
	${TRANSPORTER_FRAGMENT}
`;

export { UPDATE_TRANSPORTERS, INSERT_TRANSPORTER };
