import * as React from 'react';
import Grid from '@mui/material/Grid';
import Menu from './Menu/Menu';
import Homecontent from './Homecontent/Homecontent';
import FileImport from './FileImport/FileImport';
import Formatting from './Formatting/Formatting';
import Displaydata, { Data } from './Displaydata/Displaydata';

const Quatref = (): JSX.Element => {
	const [active, setActive] = React.useState<string>('home');
	const [data, setData] = React.useState<Data>({
		supplier: undefined,
		date: undefined,
		grids: undefined,
		step: ' ',
	});

	return (
		<Grid container spacing={0}>
			<Grid item xs={2}>
				<Menu activeMenu={data.step} />
			</Grid>
			<Grid item xs={10}>
				{active === 'home' ? <Homecontent setData={setData} goTo={setActive} /> : null}
				{active === 'import' ? <FileImport data={data} setData={setData} goTo={setActive} /> : null}
				{active === 'formatting' ? <Formatting data={data} setData={setData} goTo={setActive} /> : null}
				{active === 'Displaydata' ? <Displaydata data={data} setData={setData} goTo={setActive} /> : null}
			</Grid>
		</Grid>
	);
};

export default Quatref;
