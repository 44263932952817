import React, { Fragment } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useField } from 'formik';
import { FormikNumberField } from 'components/form/FormikNumberField';
import { LayoutField } from 'components/form/LayoutField';
import { StatusSwitchField } from 'components/Switch/StatusSwitchField';
import AppListTable from 'components/tables/list-table/AppListTable';
import { TransporterType, TransportTab } from '../definitions';
import useTransportersPricesTable from '../hooks/useTransportersPricesTable';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import usePopin from 'hooks/usePopin';
import HardlyAccessibleAreas from './HardlyAccessibleAreas';
import { getParcelTransportRules } from '../helpers';

interface ParcelTransportFormProps {
	currentTab: TransportTab;
}

const ParcelTransportForm = ({ currentTab }: ParcelTransportFormProps): JSX.Element => {
	const standardDeliveryPricesTable = useTransportersPricesTable(currentTab.index, TransporterType.standardDelivery);
	const expressDeliveryPricesTable = useTransportersPricesTable(currentTab.index, TransporterType.expressDelivery);
	const [fieldIsActif] = useField<boolean>(`transporters[${currentTab.index}].isActif`);
	const [openPopin, renderPopin] = usePopin({
		headerTitle: 'Liste des zones difficilement accessibles',
		maxWidth: 'sm',
		fullWidth: true,
		isDisplayHeader: true,
		buttonLabel: 'Enregistrer',
		isButtonFooterClosePopin: true,
	});

	const parcelTransportRules = getParcelTransportRules(currentTab.index);

	return (
		<div ref={currentTab.ref}>
			<>
				<Box mb={3}>
					<StatusSwitchField name={`transporters[${currentTab.index}].isActif`} />
				</Box>
				<Typography variant="h2">Règle de transport colis</Typography>
				<Box mt={3} display="flex" justifyContent="space-between">
					{parcelTransportRules.map((item, index) => (
						<Fragment key={index}>
							<Box>
								{item.rules.map((rule, i) => (
									<Box mb={3} key={i}>
										<LayoutField label={rule.label}>
											<Box display={rule.label === 'Zone difficilement accessible (€/colis)' ? 'flex' : ''}>
												<FormikNumberField
													decimalScale={2}
													name={rule.name}
													width="164px"
													maxValue={rule.maxValue}
													variant="outlined"
													disabled={!fieldIsActif.value}
												/>
												{rule.label === 'Zone difficilement accessible (€/colis)' && (
													<Button
														variant="outlined"
														color="primary"
														startIcon={<FormatListBulleted />}
														onClick={openPopin}
													>
														Liste des zones
													</Button>
												)}
											</Box>
										</LayoutField>
									</Box>
								))}
							</Box>
							{index < parcelTransportRules.length - 1 && <Divider orientation="vertical" flexItem />}
						</Fragment>
					))}
				</Box>
				{renderPopin(<HardlyAccessibleAreas transporterIndex={currentTab.index} />)}
				<Box mt={6} mb={5}>
					<Divider />
					<Box mb={2} mt={5}>
						<Typography variant="h2">Livraison standard</Typography>
					</Box>
					<AppListTable tableInstance={standardDeliveryPricesTable} />
				</Box>
				<Box mt={6} mb={10}>
					<Divider />
					<Box mb={2} mt={5}>
						<Typography variant="h2">Livraison express</Typography>
					</Box>
					<AppListTable tableInstance={expressDeliveryPricesTable} />
				</Box>
			</>
		</div>
	);
};

export default ParcelTransportForm;
