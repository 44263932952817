import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../app/theme/colors.scss';

export const useStylesCustom = makeStyles((theme) =>
	createStyles({
		boxIconClient: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginLeft: 20,
			cursor: 'pointer',
		},
		boxIconProduct: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginLeft: 10,
			paddingTop: 12,
			cursor: 'pointer',
		},
		autocompleteRoot: {
			'& .MuiFormControl-root': {
				marginLeft: -6,
				marginTop: 11,
				marginBottom: -5,
				'& .MuiInputBase-root': {
					height: 36,
					backgroundColor: colors.white,
				},
			},
		},
	})
);
