import { gql } from '@apollo/client';

const SUPPLIER_PRICE_FRAGMENT = gql`
	fragment SupplierPrice on SupplierPrices {
		autrePrixFixe
		delais
		franco
		nbPosesMax
		poidsUnitaireGr
		prixCalage
		prixChangementPlaque
		prixCliche
		prixHorsCalage
		prixExemplaireSupp
		prixOutillage
		prixPapierKg
		prixTraitementFichier
		productId
		quantity
		validityDate
		totalPAHT
		totalPAHTWithRFA
		rfa
		supplierName
		boxesInfo {
			boxesNumber
			boxWeight
		}
	}
`;

export default SUPPLIER_PRICE_FRAGMENT;
