import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { BootstrapInput } from './inputs/bootstrap-input';

export const BootstrapInputCustom = withStyles(() =>
	createStyles({
		root: {
			'& .MuiSelect-select': {
				fontSize: 13,
				paddingVertical: 10,
			},
		},
	})
)(BootstrapInput);
