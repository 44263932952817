import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';

type props = {
	setExcludeColDC: Function;
	excludeColDC: Array<number>;
	colindex: number;
};

export default function HeaderBtn(props: props) {
	const [state, setState] = React.useState(true);

	const handleChange = (event: any) => {
		const checked = event.target.checked;
		setState(checked);
		if (checked && props.excludeColDC.includes(props.colindex)) {
			const excludeColDCFiltred = props.excludeColDC.filter((elm) => elm !== props.colindex);
			props.setExcludeColDC(excludeColDCFiltred);
		} else {
			props.excludeColDC.push(props.colindex);
		}
	};

	return (
		<IconButton size="small" aria-label="add" component="span">
			<Checkbox checked={state} onChange={handleChange} color="default" />
		</IconButton>
	);
}
