import { Box, Typography } from '@mui/material';
import { InvoiceListType } from 'invoice/definitions';
import React, { useMemo } from 'react';
import { Column, useTable, useSortBy, HeaderProps, CellProps, useRowSelect } from 'react-table';
import { formatNumberDisplay } from 'utils/prices';
import { IndeterminateCheckbox } from '../IndeterminateCheckBox';
import PdfCell from '../PdfCell';

export const useInvoicesToBeAcountedTable = (toAccountVoices: InvoiceListType[] | undefined, loading?: boolean) => {
	const columns = useMemo<Column<InvoiceListType>[]>(
		() => [
			{
				id: 'selection',

				Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
					<div>
						<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),

				Cell: ({ row }: CellProps<any>) => (
					<div>
						<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
			},
			{
				accessor: 'invoiceId',
				width: 'zip',
				sortType: 'basic',

				Header: (row) => (
					<Box display="flex">
						<Typography>N° de facture</Typography>
					</Box>
				),
			},

			{
				accessor: 'orderId',

				Header: () => <Typography>Référence OS</Typography>,
			},
			{
				accessor: 'createdAt',
				sortType: 'basic',
				Header: (row) => (
					<Box display="flex">
						<Typography>Date de création</Typography>
					</Box>
				),
			},
			{
				accessor: 'clientName',

				Header: () => <Typography>Client facturé</Typography>,
			},

			{
				accessor: 'sellingPriceHt',

				Header: () => <Typography>Montant</Typography>,
				Cell: ({ row }) => {
					return (
						<>
							<Typography>HT : {formatNumberDisplay(row.original.sellingPriceHt, 2)} €</Typography>
							<Typography>TVA : {formatNumberDisplay(row.original.orderTva, 2)} €</Typography>
							<Typography>TTC : {formatNumberDisplay(row.original.sellingPriceTtc, 2)} €</Typography>
						</>
					);
				},
			},
			{
				accessor: 'validator',

				Header: () => <Typography>Valideur</Typography>,
			},

			{
				accessor: 'id',
				Header: () => null,
				Cell: ({ row }: any) => {
					return <PdfCell row={row} />;
				},
			},
		],
		[]
	);

	return useTable(
		{
			columns,
			data: toAccountVoices ?? [],
			borderLess: true,
			loading,
			height: 20,
			scroll: 'scroll',
		},
		useSortBy,
		useRowSelect
	);
};
