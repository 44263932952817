import { Attribute } from '../../../../graphql/types';
import { Box, FormControl, MenuItem, Typography } from '@mui/material';
import { LayoutField } from '../../../../components/form/LayoutField';
import FormikSelectField from '../../../../components/form/FormikSelectField';
import React from 'react';
import { useField } from 'formik';
import FormikTextField from '../../../../components/form/FormikTextField';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LayoutFieldForm from '../../../../components/form/LayoutFieldForm';
import { isString } from '../../../../utils/typeGuards';

export const CharacteristicsList = ({
	name,
	hasDeleted,
	characteristics,
	isAttributes,
	isEditable,
}: {
	name: string | { editValueFieldPrintName: string; editValueFieldPrintValue: string };
	isAttributes: boolean;
	hasDeleted: boolean;
	isEditable: boolean;
	characteristics: Attribute[];
}) => {
	const [, meta] = useField<string | undefined>(isString(name) ? name : name.editValueFieldPrintValue);

	return (
		<Box width="100%" display="flex" justifyContent="center" alignItems="center">
			<Box width="90%" mt={2} mb={3}>
				{hasDeleted ? (
					<Box display="flex" alignItems="center">
						<DeleteForeverOutlinedIcon fontSize="small" />
						<Box pt={0.5}>
							<Typography>Confirmez-vous la suppression de cette valeur ?</Typography>
						</Box>
					</Box>
				) : (
					<>
						<LayoutField label={isAttributes ? 'Caractéristique' : 'Nom de la valeur'}>
							<FormControl fullWidth>
								{isAttributes ? (
									<FormikSelectField name={name as string} variant="outlined">
										{characteristics.map((characteristic) => (
											<MenuItem value={`${characteristic.id}_${characteristic.name}`} key={characteristic.id}>
												{characteristic.name}
											</MenuItem>
										))}
									</FormikSelectField>
								) : (
									<FormikTextField
										name={
											isEditable
												? !isString(name)
													? name['editValueFieldPrintValue']
													: ''
												: 'printValues[0].printValue'
										}
										variant="outlined"
									/>
								)}
							</FormControl>
							{meta.error && <Typography color="error">{meta.error}</Typography>}
						</LayoutField>
						{!isAttributes && (
							<Box mt={2}>
								<LayoutFieldForm
									label="Correspondance ClariPrint"
									name={
										isEditable ? (!isString(name) ? name['editValueFieldPrintName'] : '') : 'printValues[0].printName'
									}
								/>
							</Box>
						)}
					</>
				)}
			</Box>
		</Box>
	);
};
