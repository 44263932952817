import React, { memo, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { formatNumberDisplay } from 'utils/prices';

export type Variant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'caption'
	| 'button'
	| 'overline';

interface OrderPriceProps {
	title: ReactNode;
	variant: Variant;
	color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
	price?: number | null;
	realPrice?: number | null;
	pb?: number;
	isInvoice?: boolean;
}

const OrderPrice = ({ title, variant, color, price, realPrice, pb = 2, isInvoice }: OrderPriceProps): JSX.Element => {
	return (
		<Box display="flex" flexDirection="row" pb={pb} justifyContent="space-between">
			<Typography variant="h4">{title}</Typography>
			<Typography variant={variant} color={color}>
				{formatNumberDisplay(price, 2)}
			</Typography>
			{isInvoice && (
				<Typography variant={variant} color={color}>
					{formatNumberDisplay(realPrice, 2)}
				</Typography>
			)}
		</Box>
	);
};

export default memo(OrderPrice);
