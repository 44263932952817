import { useMutation } from '@apollo/client';
import { INSERT_ROLE } from '../graphql/mutations';
import { InsertRoleMutation, InsertRoleMutationVariables } from '../graphql/mutations.definitions';
import { GET_ROLES } from '../graphql/queries';

export const useRoleInsert = () => {
	const [insertRoleMutation] = useMutation<InsertRoleMutation, InsertRoleMutationVariables>(INSERT_ROLE);
	const insertRole = async (RoleInput: InsertRoleMutationVariables) => {
		const result = await insertRoleMutation({
			variables: RoleInput,
			refetchQueries: [
				{
					query: GET_ROLES,
				},
			],

			awaitRefetchQueries: true,
		});
		if (!result.data) throw new Error();
		return result;
	};

	return insertRole;
};
