import React from 'react';
import { Grid } from '@mui/material';
import { FormikNumberField } from 'components/form/FormikNumberField';
import { LayoutField } from 'components/form/LayoutField';
import colors from 'app/theme/colors.scss';

type ClariPrintProductType = {
	isIdentical: boolean;
	quantities: number[];
	disabled: boolean;
};

const ClariPrintProduct = ({ isIdentical, quantities, disabled }: ClariPrintProductType): JSX.Element | null => {
	return (
		<Grid container spacing={4}>
			{quantities.map((quantity, i) => (
				<Grid item xs={1} key={`quantity_${quantity}_${i}`}>
					<LayoutField label={`Version ${i + 1}`} mt={2}>
						<FormikNumberField
							testid={`quantity-${i}`}
							decimalScale={0}
							disabled={isIdentical || disabled}
							width="100%"
							variant="outlined"
							bgColor={isIdentical ? colors.grey : colors.white200}
							name={`quantitiesIsNotIdentical[${i}]`}
						/>
					</LayoutField>
				</Grid>
			))}
		</Grid>
	);
};

export default ClariPrintProduct;
