import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Store from './Store';
import { useTableStyles } from '../sharedStyles';
import OrderTableHead from '../components/OrderTableHead';
import useSearch from '../../../../hooks/useSearch';
import { ChildOrderFormModel, DETAILS_BY_STORE_TABLE_HEAD_COLUMNS, OrderItemFormModel } from '../definition';

interface DetailsByProductProps {
	childrenOrder?: ChildOrderFormModel[] | null;
	parentOrderItem?: OrderItemFormModel[] | null;
}

export const DetailsByStore = ({ childrenOrder, parentOrderItem }: DetailsByProductProps): JSX.Element => {
	const classes = useTableStyles();
	const { renderSearchComponent, filtredItems } = useSearch('clientName', childrenOrder as ChildOrderFormModel[]);

	return (
		<Box mt={4}>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h2">Détail par magasin</Typography>
				{renderSearchComponent('Chercher un magasin')}
			</Box>
			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table className={classes.table}>
					<OrderTableHead columns={DETAILS_BY_STORE_TABLE_HEAD_COLUMNS} />
					<TableBody>
						{filtredItems?.map((orderChild, index) => (
							<Store
								key={`${orderChild.id}${index}`}
								childrenOrder={childrenOrder}
								orderChild={orderChild}
								parentOrderItem={parentOrderItem}
								orderChildIndex={index}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};
