import { Box, Button, Divider, Grid } from '@mui/material';
import { useManualTabs } from 'hooks/useManualTabs';
import React, { useState } from 'react';
import colors from 'app/theme/colors.scss';
import { Form, useFormikContext } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { HEADLINES, TAB_MENU_SUPPLIER_ORDER } from '../definition';
import { ProductInformationProvider } from './order-cockpit/product-information/ProductInformationContext';
import { NewOrderItem, OrderFormModel, OrderTypeToSubmit } from './order-cockpit/definition';
import { ClientPrice } from '../../graphql/types';
import { PATH_NAMES } from 'Header/menus';

interface OrderCreationFormProps {
	onSubmit: (values: OrderTypeToSubmit, isCreatePurchaseOrders?: boolean) => void;
	orderValues: OrderTypeToSubmit;
}

const OrderCreationForm = ({ onSubmit, orderValues }: OrderCreationFormProps): JSX.Element => {
	const { id: orderId } = useParams<{ id: string }>();
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const { values, setValues } = useFormikContext<
		OrderFormModel & {
			newOrderItem: NewOrderItem;
			clientPricesExisting: ClientPrice[];
			orderItemIdsToDelete: string[];
		}
	>();
	const headlines = [
		{
			title: orderId !== ':id' && orderId !== 'add' ? `Commande n° ${orderId}` : '',
			fontSize: HEADLINES[0].fontSize,
		},
	];
	const PATH_CREATE_ORDER = [{ name: PATH_NAMES.orders, href: '/orders' }, 'Création de commande'];

	const Buttons = () => (
		<Box>
			<Link to="/orders" style={{ textDecoration: 'none' }}>
				<Button color="primary" type="reset">
					Annuler
				</Button>
			</Link>

			<Button type="submit" variant="contained" color="primary">
				Sauvegarder
			</Button>

			<Button
				onClick={() => {
					onSubmit(values, true);
					setValues(orderValues);
				}}
				variant="contained"
				color="primary"
				disabled={isDisabled}
			>
				Lancer la création des bon de commandes
			</Button>
		</Box>
	);

	const { renderingTabs } = useManualTabs({
		tabs: TAB_MENU_SUPPLIER_ORDER,
		paths: PATH_CREATE_ORDER,
		headlines,
		fixedHeaderHeight: 258,
		ButtonAction: Buttons,
	});

	return (
		<Form>
			<>
				<Grid justifyContent="center" container>
					<Grid xs={11} item>
						<Box
							position="fixed"
							width="100%"
							top={40}
							left={0}
							px={11}
							bgcolor={colors.bodyBackgroundLight}
							zIndex={100}
						>
							{renderingTabs()}
						</Box>
					</Grid>

					<Grid xs={11} item>
						<Box mt={30}>
							<ProductInformationProvider>
								{TAB_MENU_SUPPLIER_ORDER.map(
									({ ref, title, Component }, index) =>
										Component && (
											<div ref={ref} key={`${title}_${index}`}>
												<Divider />
												<Component title={title} setIsDisabled={setIsDisabled} />
											</div>
										)
								)}
							</ProductInformationProvider>
						</Box>
					</Grid>
				</Grid>
			</>
		</Form>
	);
};

export default OrderCreationForm;
