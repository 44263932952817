export enum typeNotifications {
	Error = 'error',
	Success = 'success',
}

export type VariantType =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'caption'
	| 'button'
	| 'overline';

export type AlignTableCell = 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;

export const CONTACT_DISPLAY_LIMIT = 5;

export const ERROR_MESSAGE = "Une erreur c'est produite";
export const SUCCESS_UPDATE_MESSAGE = 'Modifications enregistrées';
