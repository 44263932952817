import React from 'react';
import { HeadLines, Tab } from '../hooks/useManualTabs';
import {
	GetClientPricesByProductsIdQuery,
	GetAllClientsPricesQuery,
	ClientPricesQuery,
} from './graphql/queries.definitions';

export const TAB_MENU_CLIENT_PRICE: Tab[] = [
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Caractéristiques du support',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations principales',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Produit',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Répartition',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Transport',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations Taxe Citéo',
	},
];

export const HEADLINES: HeadLines[] = [
	{
		title: 'Création d’un prix de vente pour un produit',
		fontSize: 'h2',
	},
	{
		title: 'Visualisation d’un prix de vente pour un produit',
		fontSize: 'h2',
	},
];

export const PATH = ['Création de prix de vente', 'Gestion des prix de vente'];

export const UNIT_WEIGHT_PERCENT_VALUE = 3;

export type clientPricesFromModel = GetClientPricesByProductsIdQuery['clientPricesByProductsId'];
export type AllClientPricesFromModel = GetAllClientsPricesQuery['allClientsPrices'][0];
export type ClientPriceFromModel = NonNullable<
	NonNullable<GetAllClientsPricesQuery['allClientsPrices']>[0]['clientPrices']
>[0];

export type ClientPricesModel = ClientPricesQuery['clientPrices'];
