import { Box, List, ListItem } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Support } from '../../graphql/types';
import colors from '../../app/theme/colors.scss';
import useSearch from '../../hooks/useSearch';

interface ListSupportsProps {
	supports: Support[];
	onSupportSelected: (support: Support) => void;
	path: string;
}

const ListSupportsSelect = ({ supports, onSupportSelected, path }: ListSupportsProps) => {
	const location = useLocation();
	const { renderSearchComponent, filtredItems } = useSearch('name', supports);

	return (
		<>
			<Box p={2} position="fixed" zIndex={2} bgcolor={colors.black100} width={257}>
				{renderSearchComponent('Support')}
			</Box>
			<Box pt={8}>
				<List>
					{filtredItems?.map((rowSupport) => (
						<ListItem
							button
							key={rowSupport.id}
							onClick={() => onSupportSelected(rowSupport)}
							selected={location.pathname === path + rowSupport.id}
						>
							{rowSupport.name}
						</ListItem>
					))}
				</List>
			</Box>
		</>
	);
};

export default ListSupportsSelect;
