import { HeadLines, Tab } from '../../hooks/useManualTabs';
import React from 'react';

const TAB_MENU: Tab[] = [
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Format',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Impression',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Support',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Façonnage',
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Conditionnement',
	},
];

export const sortFamilyAttributes = ['Format', 'Impression', 'Support', 'Façonnage', 'Conditionnement'];

const HEADLINES: HeadLines[] = [
	{
		title: 'Liste des valeurs pour les supports Clariprint',
		fontSize: 'h2',
	},
	{
		title: "La correspondance doit être réalisée avec les valeurs de l'outil Clariprint",
		fontSize: 'h5',
	},
];

const PATHS = ['Administration', 'Valeurs Print'];

export { PATHS, HEADLINES, TAB_MENU };
