import { PriceCell } from '../../../components/summaryPrice/SummaryPrice';

export const HORIZONTAL_CLIENT_PRICE_DATA_CELL = (index: number): PriceCell[] => [
	{
		width: '19%',
		name: `clientQuoteItem[${index}].productName`,
		isBorder: true,
		hasRate: false,
		label: 'Produit',
	},
	{
		width: '7%',
		name: `clientQuoteItem[${index}].quantity`,
		isBorder: false,
		hasRate: false,
		label: 'Quantité',
	},
	{
		width: '7%',
		name: `clientQuoteItem[${index}].numberVersion`,
		isBorder: false,
		hasRate: false,
		label: 'Nb de version',
	},
	{
		width: '8%',
		name: `clientQuoteItem[${index}].productPurchasePriceExcludingVAT`,
		isBorder: false,
		hasRate: false,
		label: 'PA produit HT (€)',
	},
	{
		width: '8%',
		name: `clientQuoteItem[${index}].productSalesPriceExcludingVAT`,
		isBorder: false,
		hasRate: false,
		label: 'PV produit HT (€)',
	},
	{
		width: '9%',
		name: `clientQuoteItem[${index}].transportPurchasePriceExcludingVAT`,
		isBorder: false,
		hasRate: false,
		label: 'PA Transport HT (€)',
	},
	{
		width: '9%',
		name: `clientQuoteItem[${index}].transportSalesPriceExcludingVAT`,
		isBorder: false,
		hasRate: false,
		label: 'PV Transport HT (€)',
	},
	{
		width: '10%',
		name: `prepressPriceExcludingVat`,
		isBorder: false,
		hasRate: false,
		label: 'Prix Prépresse HT(€)',
	},
	{
		width: '10%',
		name: `prepressSalesPriceExcludingVat`,
		isBorder: false,
		hasRate: false,
		label: 'PV Prépresse HT(€)',
	},
	{
		width: '13%',
		name: `clientQuoteItem[${index}].cost`,
		isBorder: false,
		hasRate: false,
		label: 'Prix vente HT(€)',
	},
];

export const VERTICAL_CLIENT_PRICE_DATA_CELL = (index: number): PriceCell[] => [
	{
		width: '65.5%',
		name: `vatRate`,
		isBorder: false,
		hasRate: true,
		label: 'Taux T.V.A (%)',
	},
	{
		width: '65.5%',
		name: `clientQuoteItem[${index}].vatAmount`,
		isBorder: false,
		hasRate: true,
		label: 'Montant T.V.A (€)',
	},
	{
		width: '65.5%',
		name: `clientQuoteItem[${index}].sellingPriceIncludingTax`,
		isBorder: false,
		hasRate: true,
		label: 'Prix Vente TTC (€)',
	},
	{
		width: '65.5%',
		name: `clientQuoteItem[${index}].estimatedCiteoTax`,
		isBorder: false,
		hasRate: true,
		label: 'Estimatif taxe Citéo(€)',
	},
];
