import React, { useCallback, useMemo } from 'react';
import { Column, Row, useTable } from 'react-table';
import { Typography, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Distribution } from '../../../../graphql/types';
import EditableCell from '../components/EditableCellField';

export const useStoreDistributionTable = (
	setDistributionsData: React.Dispatch<React.SetStateAction<(Distribution & { id: string })[]>>,
	distributionsData: (Distribution & { id: string })[]
) => {
	const updateMyData = useCallback(
		(index: number, id: string | undefined, value: string | number) => {
			if (!id) {
				return;
			}
			setDistributionsData((old: (Distribution & { id: string })[]) =>
				old.map((row: Distribution & { id: string }, rowIndex: number) => {
					if (rowIndex === index) {
						return {
							...old[rowIndex],
							[id]: value,
						};
					}
					return row;
				})
			);
		},
		[setDistributionsData]
	);
	const handleAddDistribution = useCallback((): void => {
		let addedDistributionRow = {
			id: String(Number(distributionsData[distributionsData.length - 1].id) + 1),
			zip: '',
			quantity: null,
		};
		let newDistributions = [...distributionsData, addedDistributionRow];
		setDistributionsData(newDistributions);
	}, [distributionsData, setDistributionsData]);

	const handleDeleteDistribution = useCallback(
		(row: Row<Distribution & { id: string }>): void => {
			if (distributionsData.length > 2) {
				const distributionsDataCopy = [...distributionsData];
				let deleteDistributionsRow = distributionsDataCopy.filter(
					(data: Distribution & { id: string }) => data.id !== row.original.id
				);
				setDistributionsData(deleteDistributionsRow);
			}
		},
		[distributionsData, setDistributionsData]
	);

	const columns = useMemo<Column<Distribution & { id: string }>[]>(
		() => [
			{
				accessor: 'zip',
				width: 'zip',
				Cell: ({ row, column, value }) => {
					return <EditableCell value={value as string} row={row} column={column} updateMyData={updateMyData} />;
				},
				Header: () => <Typography>Code Postal</Typography>,
			},
			{
				accessor: 'quantity',
				width: '129px',
				Cell: ({ row, column, value }) => {
					return <EditableCell value={value as number} row={row} column={column} updateMyData={updateMyData} />;
				},
				Header: () => <Typography>Quantité</Typography>,
			},

			{
				accessor: 'id',
				Header: () => null,
				Cell: ({ row }) => {
					return (
						<span>
							<Box width="100px" display="flex" justifyContent="left">
								<Box width="50%">
									<DeleteForeverOutlinedIcon color="primary" onClick={() => handleDeleteDistribution(row)} />
								</Box>

								{Number(row.id) === distributionsData.length - 1 && (
									<Box width="50%">
										<IconButton onClick={() => handleAddDistribution()} size="large">
											<AddIcon color="primary" />
										</IconButton>
									</Box>
								)}
							</Box>
						</span>
					);
				},
			},
		],
		[distributionsData.length, handleAddDistribution, handleDeleteDistribution, updateMyData]
	);

	return useTable({
		columns,
		updateMyData,
		data: distributionsData,
	});
};
