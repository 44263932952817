import React from 'react';
import { Box } from '@mui/material';
import FormikTextField from '../../../../components/form/FormikTextField';
import DashedHeader from '../../../../components/DashedHeader';

const CommentForm = (): JSX.Element => {
	return (
		<>
			<Box p={1} mt={2}>
				<DashedHeader>Commentaires</DashedHeader>
			</Box>
			<Box mb={3}>
				<FormikTextField
					fullWidth
					multiline={true}
					rows={7}
					name="comment"
					variant="outlined"
					placeholder="Ajouter un commentaire"
				/>
			</Box>
		</>
	);
};

export default CommentForm;
