import { gql } from '@apollo/client';

const MARGIN_FRAGMENT = gql`
	fragment Margins on Margins {
		prixCalage
		prixHorsCalage
		prixExemplaireSupp
		prixChangementPlaque
		prixTraitementFichier
		autrePrixFixe
		prixCliche
		prixOutillage
	}
`;

export default MARGIN_FRAGMENT;
