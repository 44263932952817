import { useLazyQuery } from '@apollo/client';
import { GET_CLIENT_PRICE_BY_CLIENT_ID_AND_SUPPORT_ID } from '../graphql/queries';
import {
	ClientPricesByClientIdAndSupportIdQuery,
	ClientPricesByClientIdAndSupportIdQueryVariables,
} from '../graphql/queries.definitions';

export const useClientPriceByClientIdAndSupportId = () => {
	const [getClientPrices, { data }] = useLazyQuery<
		ClientPricesByClientIdAndSupportIdQuery,
		ClientPricesByClientIdAndSupportIdQueryVariables
	>(GET_CLIENT_PRICE_BY_CLIENT_ID_AND_SUPPORT_ID);
	return { clientPrices: data?.clientPrices, getClientPrices };
};
