import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import colors from '../../../../app/theme/colors.scss';
import { formatNumberDisplay } from '../../../../utils/prices';

interface PriceProps {
	label: string;
	value?: number | null;
}

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			color: colors.black100,
			paddingLeft: '7px',
			boxShadow: 'none',
		},
	})
);

const Price = ({ label, value }: PriceProps): JSX.Element => {
	const classes = useStyles();

	return (
		<>
			<Typography variant="h5">{label}</Typography>
			<Paper className={classes.paper}>
				<Typography variant="h5">{formatNumberDisplay(value, 2) || '--'}</Typography>
			</Paper>
		</>
	);
};

export default Price;
