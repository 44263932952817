import React from 'react';
import useExistingClientPricesTable from '../hooks/useExistingClientPricesTable';
import AppListTable from '../../../../components/tables/list-table/AppListTable';
import { Box } from '@mui/material';
import { FilterClientPrice } from '../../definitions';

const ExistingClientPricesForProduct = ({
	clientPricesExisting,
}: {
	clientPricesExisting?: FilterClientPrice[];
}): JSX.Element => {
	const tableInstance = useExistingClientPricesTable(clientPricesExisting);

	return (
		<Box m={2}>
			<AppListTable tableInstance={tableInstance} />
		</Box>
	);
};

export default ExistingClientPricesForProduct;
