import React from 'react';
import { Box, Typography } from '@mui/material';
import colors from '../../../../../app/theme/colors.scss';
import FormikTextField from '../../../../../components/form/FormikTextField';
interface CommentFormProps {
	disabled: boolean;
}
const CommentForm = ({ disabled }: CommentFormProps): JSX.Element => {
	return (
		<>
			<Box bgcolor={colors.grayCard} textAlign="center" borderColor={colors.indigo700} p={1} mb={3} mt={2}>
				<Typography variant="h4">Commentaires</Typography>
			</Box>
			<Box mb={3} bgcolor={disabled ? colors.grey : ''}>
				<FormikTextField
					fullWidth
					multiline={true}
					rows={7}
					name="comment"
					variant="outlined"
					placeholder="Ajouter un commentaire"
					disabled={disabled}
				/>
			</Box>
		</>
	);
};

export default CommentForm;
