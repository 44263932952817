import useCurrentUserQuery from 'admin/user/hooks/useCurrentUserQuery';
import { useClientsByCurrentUser } from 'clients/hooks/useClientsByCurrentUser';
import PermissionProvider from 'context/permissionContext/PermissionProvider';
import React, { useContext } from 'react';
import { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/Auth';
import Header from '../Header/Header';
import ProtectedRoute from './ProtectedRoute';
import { ROUTES } from './routes';

const AuthRoutes = () => {
	const context = useContext(AuthContext);
	const { user: currentUser } = useCurrentUserQuery();
	useClientsByCurrentUser(true);
	const currentUserPermissions = useMemo(() => currentUser?.role.permissions.map((permission) => permission.key), [
		currentUser,
	]);

	if (!context?.accessToken) {
		return <Redirect to="/login" />;
	}

	if (currentUserPermissions) {
		return (
			<PermissionProvider permissions={currentUserPermissions}>
				<Header />
				<Switch>
					{Object.entries(ROUTES).map(([path, { component, permissionKey }]) => (
						<ProtectedRoute key={path} path={path} component={component} permissionKey={permissionKey} />
					))}
				</Switch>
			</PermissionProvider>
		);
	}

	return <Header />;
};
export default AuthRoutes;
