import React, { useState } from 'react';
import { useField } from 'formik';
import { TextField, Chip } from '@mui/material';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';

export interface EmailOption {
	email: string;
}

interface EmailAutocompleteFieldProps {
	name: string;
	placeholder: string;
	options: EmailOption[];
	fullWidth?: boolean;
	margin?: 'none' | 'dense' | 'normal' | undefined;
}

const EmailAutocompleteField: React.FC<EmailAutocompleteFieldProps> = ({
	name,
	placeholder,
	options = [],
	fullWidth,
	margin,
}) => {
	const [{ onBlur, value }, meta, helper] = useField<EmailOption[]>(name);
	const [inputValue, setInputValue] = useState<string>('');

	// Ensure the value prop is always an array, even if it's initially undefined
	const formattedValue = Array.isArray(value) ? value : [];

	const handleChange = (
		event: React.ChangeEvent<{}>,
		newValue: EmailOption[] | null,
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<EmailOption | string>
	) => {
		if (!newValue) return;
		if (reason === 'createOption' && details && typeof details.option === 'string') {
			helper.setValue([...formattedValue, { email: details.option }]);
		} else if (newValue) {
			helper.setValue(newValue);
		}
		helper.setTouched(true);
	};

	return (
		<Autocomplete
			autoComplete
			multiple
			options={options}
			limitTags={2}
			isOptionEqualToValue={(option, value) => option.email === value.email}
			freeSolo
			renderTags={(options, getTagProps) =>
				options.map(({ email }, index) => <Chip label={email} {...getTagProps({ index })} />)
			}
			renderInput={(params) => (
				<TextField
					{...params}
					name={name}
					error={meta.touched && Boolean(meta.error)}
					helperText={meta.touched && meta.error}
					variant="outlined"
					margin={margin}
					fullWidth={fullWidth}
					placeholder={placeholder}
				/>
			)}
			value={formattedValue}
			inputValue={inputValue}
			onChange={(event, value, reason, details) => handleChange(event, value as EmailOption[] | null, reason, details)}
			onInputChange={(e, value) => setInputValue(value)}
			onBlur={onBlur}
		/>
	);
};

export default EmailAutocompleteField;
