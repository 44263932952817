import { Box } from '@mui/material';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../components/loading/Loading';
import { PATH_NAMES } from '../Header/menus';
import ClientForm from './form/ClientForm';
import useClientQuery from './hooks/useClientQuery';
import useClientUpdate from './hooks/useClientUpdate';
import { Client, ClientInsertInput } from '../graphql/types';
import { useSnackbar } from 'notistack';
import useClientInsert from './hooks/useClientInsert';
import { Formik } from 'formik';
import getClientSchema from './form/validation-schema';
import { EmailOption } from './form/elpev-information/EmailAutocompleteField';

interface ClientRouteParams {
	id: string;
}

interface ClientProps extends RouteComponentProps<ClientRouteParams> {}

const ClientRoute = ({
	match: {
		params: { id },
	},
}: ClientProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { client, loading } = useClientQuery(id);
	const { insertClient } = useClientInsert();
	const { updateClient } = useClientUpdate();
	const [informativeEntities, setInformativeEntities] = useState<Record<number, ClientInsertInput> | undefined>(
		undefined
	);
	const path = [PATH_NAMES.SheetsManagement, PATH_NAMES.Clients, client?.name];

	const onSubmit = async (submittedClient: Client, logoFile: File | null, emails: EmailOption[]) => {
		try {
			const newValues = Object.assign({}, submittedClient);
			if (informativeEntities) {
				const levels = Object.keys(informativeEntities)
					.map((level) => parseInt(level))
					.sort();
				const informativeEntity1 = await insertClient(informativeEntities[levels[0]]);
				const parentClientId = informativeEntity1.client?.id ?? null;

				if (levels.length === 1) {
					newValues.parentClientId = parentClientId;
				} else {
					const newInformativeEntity = { ...informativeEntities[levels[1]], parentClientId };
					const informativeEntity2 = await insertClient(newInformativeEntity);

					newValues['parentClientId'] = informativeEntity2.client?.id ?? null;
				}
				setInformativeEntities(undefined);
			}

			const email = emails.map((element) => element.email).join('/');

			await updateClient({ ...newValues, email }, logoFile);
			enqueueSnackbar('Modifications enregistrées', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Erreur', { variant: 'error' });
		}
	};

	if (!client || loading) return <Loading loading={loading} />;

	const clientValues = {
		...client,
		logoFile: null,
		emails: client?.email
			? (client?.email?.split('/').map((element) => ({ email: element })) as EmailOption[])
			: ([] as EmailOption[]),
	};

	return (
		<Box mx={7}>
			<Formik<Client & { logoFile: File[] | null; emails: EmailOption[] }>
				validationSchema={getClientSchema(client.isRoot)}
				initialValues={clientValues}
				enableReinitialize
				onSubmit={async ({ emails, logoFile, ...restClient }) => {
					onSubmit(restClient, logoFile ? logoFile[0] : null, emails);
				}}
				validateOnChange={false}
			>
				<ClientForm client={client} paths={path} onChangeInformativeEntities={setInformativeEntities} />
			</Formik>
		</Box>
	);
};

export default ClientRoute;
