import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import colors from '../app/theme/colors.scss';

const DashedHeader = withStyles(
	createStyles((theme: Theme) => ({
		root: {
			...theme.typography.h3,
			color: colors.turquoise500,
			display: 'flex',
			borderBottom: '1px dashed',
			marginBottom: 24,
		},
	}))
)(Typography);

export default DashedHeader;
