import { useQuery } from '@apollo/client';
import { GET_CLIENT_CHILDREN } from '../graphql/queries';
import { ChildrenQueryVariables, ChildrenQuery } from '../graphql/queries.definitions';

const useClientChildrenQuery = (id: string) => {
	const { data, loading } = useQuery<ChildrenQuery, ChildrenQueryVariables>(GET_CLIENT_CHILDREN, {
		variables: {
			id,
		},
	});
	return { children: data?.clientChildren, loading };
};

export default useClientChildrenQuery;
