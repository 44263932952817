import React, { useState } from 'react';
import {List, ListItem, ListItemText, Collapse, Box} from '@mui/material';
import KeyboardControlKeyOutlinedIcon from '@mui/icons-material/KeyboardControlKeyOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import colors from '../app/theme/colors.scss';
import {useHistory, useLocation} from 'react-router-dom';
import { Menu } from './definitions';
import Restricted from 'context/permissionContext/Restricted';

interface AppMenuItemProps {
	item: Menu;
	onChangeSelectedMenu: React.Dispatch<React.SetStateAction<Readonly<Menu> | null>>;
	onClose: () => void;
}

const AppMenuItem = ({ item, onChangeSelectedMenu, onClose }: AppMenuItemProps): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const [open, setOpen] = useState(false);

	const goTo = (href: string) => {
		history.push(href);
	};

	const handleItemClick = (item: Menu) => {
		onChangeSelectedMenu(item);

		if (item.href) {
			goTo(item.href);
			onClose();
		} else if (item.subMenus) {
			setOpen(!open);
		}
	};

	return (
		<>
			<ListItem selected={location.pathname === item.href} sx={{height:55}} button onClick={() => handleItemClick(item)} >
				{item.icon && <item.icon fontSize="small" sx={{ marginRight: '6px' }} />}
				<ListItemText primary={item.title} />
				{item.subMenus && (open ? <KeyboardControlKeyOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />)}
			</ListItem>

			{item.subMenus && (
				<Box bgcolor={colors.gray600} border={0}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List>
							<Box pl={2}>
								{item.subMenus.map((subMenu, index) => (
									//it's a restriction based on user permission is needed
									<Restricted key={index} to={subMenu.permissionKey}>
										<AppMenuItem item={subMenu} onChangeSelectedMenu={onChangeSelectedMenu} onClose={onClose} />
									</Restricted>
								))}
							</Box>
						</List>
					</Collapse>
				</Box>
			)}
		</>
	);
};

export default AppMenuItem;
