import { useQuery } from '@apollo/client';
import { useField } from 'formik';
import { SupplierPricesForOrderItemInput } from 'graphql/types';
import { GET_SUPPLIER_PRICES_FOR_ORDER_ITEM } from '../graphql/queries';
import {
	GetSupplierPricesForOrderItemQuery,
	GetSupplierPricesForOrderItemQueryVariables,
} from '../graphql/queries.definitions';
import { calculateAndSortSuppliersPrices } from 'supplier/helpers';
import { productAndTransporterCalculation } from '../helpers';

const useGetSupplierPricesForOrderItemQuery = (options: SupplierPricesForOrderItemInput, index: number) => {
	const [{ value }, , helpersOrderItem] = useField('orderItem');
	const { data, loading } = useQuery<GetSupplierPricesForOrderItemQuery, GetSupplierPricesForOrderItemQueryVariables>(
		GET_SUPPLIER_PRICES_FOR_ORDER_ITEM,
		{
			variables: {
				options,
			},
			onCompleted: (data) => {
				if (value && data?.supplierPrices) {
					const sortedSuppliersPrices = calculateAndSortSuppliersPrices(data.supplierPrices);
					let orderItem = value;
					orderItem[index].suppliersPrices = sortedSuppliersPrices;
					orderItem[index].bestSupplierId = sortedSuppliersPrices[0]?.supplierId;
					orderItem[index].deliveryDepartment = sortedSuppliersPrices[0]?.deliveryDepartment;
					helpersOrderItem.setValue(productAndTransporterCalculation(orderItem));
				}
			},
			fetchPolicy: 'no-cache',
		},

	);

	return { suppliersPrices: data?.supplierPrices, loading };
};

export default useGetSupplierPricesForOrderItemQuery;
