import axios from 'axios';
import { useState } from 'react';
import { PreviewFile } from '../components/form/FormikFilesField';

export const uploadFile = (
	file: File,
	uploadUrl: string | undefined,
	mimetype: string = 'application/octet-stream'
): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const reader = new FileReader();

		if (uploadUrl) {
			reader.onload = () => {
				axios
					.put<void>(uploadUrl, file, {
						headers: {
							'Content-Type': mimetype,
						},
					})
					.then(() => resolve())
					.catch((error) => reject(error));
			};
			reader.readAsDataURL(file);
		}
	});
};

export const getFile = (url: string) => {
	return new Promise<string | ArrayBuffer>(async (resolve, reject) => {
		try {
			const file = await axios.get<string>(url);
			file.status === 200 ? resolve(file.data) : reject(false);
		} catch (error) {
			reject(false);
		}
	});
};

export const ReadFileUploading = (file: PreviewFile) => {
	return new Promise<string | ArrayBuffer>((resolve, reject) => {
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			(e) => {
				if (e.target && e.target.result !== null) {
					resolve(e.target.result);
				}
			},
			false
		);
		reader.readAsDataURL(file); //une url en base64
	});
};

export const FileUploadRestApi = (query: string, variables: Record<string, any>) => {
	const accessToken = localStorage.getItem('accessToken');
	const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);
	const uploadFile = async (acceptedFiles: File, type: string) => {
		setIsUploadInProgress(true);
		const body = new FormData();
		const file = acceptedFiles;
		const operation = {
			query,
			variables: { ...variables, file: file, type },
		};
		const map = {
			'0': ['variables.file'],
		};
		body.append('operations', JSON.stringify(operation));
		body.append('map', JSON.stringify(map));
		body.append('0', file);
		const queryOptions = {
			method: 'POST',
			body,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			await fetch(process.env.REACT_APP_ENDPOINT as any, queryOptions)
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse) {
						setResponse(jsonResponse);
						setIsUploadInProgress(false);
					}
				});
		} catch (err) {
			console.log(err);
		}
	};
	return { isUploadInProgress, setIsUploadInProgress, uploadFile, response };
};
