import { useQuery } from '@apollo/client';
import { GET_USER_BY_ID } from '../graphql/queries';

const useUserQuery = (userId: string) => {
	const { data, refetch: refetchUser } = useQuery(GET_USER_BY_ID, {
		variables: { userId },
	});
	return { user: data?.user, refetchUser };
};

export default useUserQuery;
