import { Typography } from '@mui/material';
import React from 'react';
import { EntityType } from '../../../../graphql/types';
import { Entity } from '../../client-type-organization/TypeAndOrganizationEntity';

const EntityTitleCell = (value: string, type: EntityType, entities?: Entity[]): JSX.Element => {
	const isActif = entities?.find((entity) => entity.name === value && entity.type === type)?.isActif ?? false;
	return <Typography color={isActif ? 'textPrimary' : 'error'}>{value}</Typography>;
};

export default EntityTitleCell;
