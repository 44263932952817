import React, { useEffect, useMemo } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import Loading from '../components/loading/Loading';
import { GetProductsQuery } from './graphql/queries.definitions';
import InformationMessage from '../components/InformationMessage/InformationMessage';
import AppTable from '../components/AppTable';
import { LineTab, LineTabs } from '../components/tabs/LineTabs';
import useSuppliersPricesQuery from '../supplier/hooks/useSuppliersPricesQuery';
import ExportPurchasePrices from './ExportPurchasePrices';
import { Attribute } from '../graphql/types';
import { formatNumberDisplay } from '../utils/prices';
import { SUPPLIER_PRICES_HEAD_CELLS } from './definitions';
import useCollapse from '../hooks/useCollapse';

interface SuppliersListProps {
	supportName: string;
	attributes: Attribute[];
	products: GetProductsQuery['products'];
}

const SuppliersList = ({ supportName, attributes, products }: SuppliersListProps): JSX.Element => {
	const { renderCollapseComponent, displayContent } = useCollapse();
	const [tabIndex, setTabIndex] = React.useState<number>(0);
	const productsId = products.map((product) => product.id);
	const { suppliersPrices, loading } = useSuppliersPricesQuery(productsId);

	const newSupplierPrices = useMemo(() => {
		return suppliersPrices
			?.map((supplier) => supplier.productsSuppliersPrices)
			[tabIndex]?.map((price) => {
				return {
					...price,
					prixCalage: formatNumberDisplay(price.prixCalage, 2),
					prixHorsCalage: formatNumberDisplay(price.prixHorsCalage, 2),
					prixExemplaireSupp: price.prixExemplaireSupp && formatNumberDisplay(price.prixExemplaireSupp, 5),
					prixChangementPlaque: formatNumberDisplay(price.prixChangementPlaque, 2),
					autrePrixFixe: formatNumberDisplay(price.autrePrixFixe, 2),
					prixCliche: formatNumberDisplay(price.prixCliche),
					prixOutillage: formatNumberDisplay(price.prixOutillage),
					poidsUnitaireGr: formatNumberDisplay(price.poidsUnitaireGr),
					franco: price.franco !== null ? (price.franco ? 'Oui' : 'Non') : null,
					validityDate: price.validityDate ? new Date(price.validityDate)?.toLocaleDateString() : null,
				};
			});
	}, [suppliersPrices, tabIndex]);

	useEffect(() => {
		setTabIndex(0);
	}, [suppliersPrices]);

	return (
		<Box pt={3}>
			<Box display={'flex'} flexGrow={1}>
				<Box pr={2}>
					<Typography variant="h2">Listes des fournisseurs</Typography>
				</Box>
				{renderCollapseComponent()}
			</Box>
			<Collapse in={displayContent}>
				{!loading ? (
					newSupplierPrices && newSupplierPrices?.length !== 0 ? (
						<Box pb={3} pt={1}>
							<Box display={'flex'}>
								<Box flexGrow={1}>
									<LineTabs value={tabIndex} onChange={(_, newIndex) => setTabIndex(newIndex)} marginleft="0px">
										{suppliersPrices?.map(({ name, id }) => (
											<LineTab key={id} label={name} />
										))}
									</LineTabs>
								</Box>
								<Box>
									<ExportPurchasePrices
										suppliers={suppliersPrices}
										supportName={supportName}
										attributes={attributes}
										products={products}
									/>
								</Box>
							</Box>
							<AppTable bodyCelles={newSupplierPrices ?? []} headCells={SUPPLIER_PRICES_HEAD_CELLS} />
						</Box>
					) : (
						<InformationMessage
							title={'Pas encore de fournisseur'}
							message={'Aucun fournisseur disponible pour ce produit'}
						/>
					)
				) : (
					<Loading loading={loading} />
				)}
			</Collapse>
		</Box>
	);
};

export default SuppliersList;
