import React, { memo, useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Collapse, Typography, Button } from '@mui/material';
import Loading from '../components/loading/Loading';
import AppTable from '../components/AppTable';
import InformationMessage from '../components/InformationMessage/InformationMessage';
import { LineTab, LineTabs } from '../components/tabs/LineTabs';
import useClientPricesByProductsId from '../clientPrice/hooks/useClientPricesByProductsId';
import { GetProductsQuery } from './graphql/queries.definitions';
import { CLIENT_PRICES_HEAD_CELLS } from './definitions';
import { formattedClientPrices } from './helpers';
import { AttributesFilterInput } from '../graphql/types';
import useExportClientPrices from '../clientPrice/hooks/useExportClientPrices';
import PricesProductContext from '../context/pricesProductContext/PricesProductContext';
import useCollapse from '../hooks/useCollapse';

interface SellingPricesByClientProps {
	products: GetProductsQuery['products'];
	supportId: string;
	filters: AttributesFilterInput;
}

const SellingPricesByClient = ({ products, supportId, filters }: SellingPricesByClientProps): JSX.Element => {
	const history = useHistory();
	const { renderCollapseComponent, displayContent } = useCollapse();
	const context = useContext(PricesProductContext);
	const [tabIndex, setTabIndex] = useState<number>(0);
	const productsIds = products.map((product) => product.id);
	const { clients, loading } = useClientPricesByProductsId(productsIds);
	const clientPrices = useMemo(() => formattedClientPrices(tabIndex, clients), [clients, tabIndex]);
	const { exportClientPrices } = useExportClientPrices(supportId, filters);

	if (loading) return <Loading loading={loading} />;

	return (
		<Box pt={3}>
			<Box display={'flex'} flexGrow={1}>
				<Box pr={2}>
					<Typography variant="h2">Listes des prix de vente</Typography>
				</Box>
				{renderCollapseComponent()}
			</Box>
			<Collapse in={displayContent}>
				{clientPrices && clientPrices.length !== 0 ? (
					<Box pb={3} pt={1}>
						<Box display={'flex'}>
							<Box flexGrow={1}>
								<LineTabs value={tabIndex} onChange={(_, newIndex) => setTabIndex(newIndex)} marginleft="0px">
									{clients?.map((client, index) => (
										<LineTab key={index} label={client.name} />
									))}
								</LineTabs>
							</Box>
							<Box>
								{context?.selectedProduct && context?.supplierPricesLength > 0 && (
									<Button
										variant={'contained'}
										color="primary"
										onClick={() => {
											history.push(`/sellingPrice/${supportId}`);
											context?.setSelectedSupportId(supportId);
										}}
									>
										Créer un prix de vente
									</Button>
								)}
								<Button
									variant={'contained'}
									color="primary"
									onClick={() => {
										exportClientPrices();
									}}
								>
									Extraire les prix de vente
								</Button>
							</Box>
						</Box>
						<AppTable bodyCelles={clientPrices ?? []} headCells={CLIENT_PRICES_HEAD_CELLS} />
					</Box>
				) : (
					<>
						{context?.selectedProduct && context?.supplierPricesLength > 0 && (
							<Box display="flex" flexDirection="row-reverse">
								<Button
									variant={'contained'}
									color="primary"
									onClick={() => {
										history.push(`/sellingPrice/${supportId}`);
										context?.setSelectedSupportId(supportId);
									}}
								>
									Créer un prix de vente
								</Button>
							</Box>
						)}
						<InformationMessage
							title={'Pas encore prix de vente'}
							message={'Aucun prix de vente disponible pour ce produit'}
						/>
					</>
				)}
			</Collapse>
		</Box>
	);
};

export default memo(SellingPricesByClient);
