import React, { createContext, useEffect, useState } from 'react';
import { useManageProductSellingPrice } from '../form/product-selling-price/hooks/useManageProductSellingPrice';
import { InformationsValideContextType, InformationsValideProviderProps } from './definition';
import { IsShowSellingPriceSelectedProps } from '../form/definitions';
import useTransportersQuery from '../../admin/transportRules/hooks/useTransportersQuery';

export const InformationsValideContext = createContext<InformationsValideContextType | undefined>(undefined);

export const InformationsValideProvider = ({
	children,
	onGetSupplierPrices,
	isClariPrint,
	isPLV,
	supportId,
	supportMetaData,
	productId,
	isConsultSellingPrice,
	isConsultAndEditSellingPrice,
	transporterId,
}: InformationsValideProviderProps & IsShowSellingPriceSelectedProps) => {
	const { transporters } = useTransportersQuery({ isActif: true });
	const [selectedTransporterId, setSelectedTransporterId] = useState<string>('');
	const [isValidatePrintsByVersion, setIsValidatePrintsByVersion] = useState(false);
	const [isTransportAttributeChange, setIsTransportAttributeChange] = useState<boolean>(false);

	const {
		handleStatusSwitch,
		handleVersionNumber,
		instanceSuppliers,
		nbOfMaxPoses,
		values,
		handleSupplierList,
		renderingSuppliers,
		quantityDisabled,
		supplierPrices,
		metaQuantity,
		handleChangeSupplierPrices,
	} = useManageProductSellingPrice({ onGetSupplierPrices, isClariPrint, supportId, productId, supportMetaData });

	const getClientPrices = async () => {
		try {
			await handleChangeSupplierPrices();
			setIsValidatePrintsByVersion(true);
			setIsTransportAttributeChange(true);
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	useEffect(() => {
		if ((!isConsultSellingPrice && transporterId) || (!isConsultAndEditSellingPrice && transporterId)) {
			setSelectedTransporterId(transporterId);
		}
	}, [isConsultAndEditSellingPrice, isConsultSellingPrice, transporterId]);

	const value = {
		handleStatusSwitch,
		handleVersionNumber,
		instanceSuppliers,
		nbOfMaxPoses,
		values,
		handleSupplierList,
		renderingSuppliers,
		quantityDisabled,
		supplierPrices,
		metaQuantity,
		getClientPrices,
		isClariPrint,
		isPLV,
		setSelectedTransporterId,
		selectedTransporterId,
		isValidatePrintsByVersion,
		isConsultSellingPrice,
		setIsTransportAttributeChange,
		isTransportAttributeChange,
		transporters,
	};

	return <InformationsValideContext.Provider value={value}>{children}</InformationsValideContext.Provider>;
};
