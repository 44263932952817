import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { Box, Typography } from '@mui/material';

import { EscompteInput } from '../../graphql/types';
export const useEscompteTable = (escomptes: EscompteInput[]) => {
	const columns = useMemo<Column<EscompteInput>[]>(
		() => [
			{
				accessor: 'escompte',
				width: '119px',
				Header: () => (
					<Box width="119px">
						<Typography>%escompte</Typography>
					</Box>
				),
				Cell: ({ row }) => <Typography>{row.original?.escompte ?? null}</Typography>,
			},
			{
				accessor: 'client',
				width: '124px',
				Header: () => (
					<Box width="256px">
						<Typography>Client</Typography>{' '}
					</Box>
				),
				Cell: ({ row }) => <Typography>{row.original?.client ?? null}</Typography>,
			},
			{
				accessor: 'dayLimitTime',
				width: '124px',
				Header: () => (
					<Box width="124px">
						<Typography>Délai en jour</Typography>{' '}
					</Box>
				),
				Cell: ({ row }) => <Typography>{row.original?.dayLimitTime ?? null}</Typography>,
			},
		],
		[]
	);
	return useTable({
		columns,
		data: escomptes,
		isBorderless: true,
	});
};
