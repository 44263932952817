import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, List, ListItem } from '@mui/material';
import colors from 'app/theme/colors.scss';
import { Support } from 'graphql/types';
import useSupportsQuery from 'admin/support/hooks/useSupportsQuery';
import { MenuComponentProps } from 'Header/definitions';
import useSearch from 'hooks/useSearch';

interface SupportListMenuProp extends MenuComponentProps {}
export const ClientPriceListMenu = ({ onClose }: SupportListMenuProp): JSX.Element => {
	const history = useHistory();
	const { supports } = useSupportsQuery({ isActif: true });

	const { renderSearchComponent, filtredItems } = useSearch('name', supports as SupportsFromModel);
	const location = useLocation();

	const handleClientSelected = ({ id }: Pick<Support, 'id'>) => {
		history.push(`/sellingPrice/${id}`);
		onClose?.();
	};

	return (
		<Box>
			{supports && (
				<>
					<Box p={2} position="fixed" zIndex={2} bgcolor={colors.black100} width={257}>
						{renderSearchComponent('Support')}
					</Box>
					<Box py={8}>
						<List>
							{filtredItems?.map((client) => (
								<ListItem
									button
									key={client.id}
									onClick={() => handleClientSelected(client)}
									selected={location.pathname.includes(client.id)}
								>
									{client.name}
								</ListItem>
							))}
						</List>
					</Box>
				</>
			)}
		</Box>
	);
};
