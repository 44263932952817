import { FamilyAttribute, AttributeValuesType } from '../admin/support/definitions';
import { ProductDetailsType, ProductFromModel } from '../product/definitions';
import { clientPricesFromModel } from '../clientPrice/definitions';
import { sortByKey } from '../utils/arrays';
import { EMPTY_STRING } from '../admin/support/definitions';
import omit from 'ramda/src/omit';
import {
	AttributConditionnement,
	AttributConditionnementItem,
	Attribute,
	AttributeKey,
	Conditionnement,
	PrintValue,
} from '../graphql/types';
import { formatNumberDisplay } from '../utils/prices';
import { ProductFilter } from '../context/pricesProductContext/definition';
import { isBoolean } from '../utils/typeGuards';

export type PrintAttributeType = {
	value?: string | null;
	name?: string | null;
	printName?: string | null;
	id?: string | null;
	key?: string | null;
	family?: string | null;
	index?: number | null;
};

const SPACE = ' ';

const getSelectedProductDetails = (product: ProductFromModel) =>
	product?.support?.supportAttributes?.map((attribut) => ({
		name: attribut.name,
		value: attribut.key && attribut.key !== AttributeKey.Conditionnement ? product[attribut.key] : '',
		family: attribut.family,
		id: attribut.id,
		key: attribut.key,
		index: attribut.index,
	}));

const sortProductSupportAttributesByFamily = (productSupportAttributes?: ProductDetailsType[]) => {
	let sortedAttributes: ProductDetailsType[] = [];
	Object.keys(FamilyAttribute).forEach((family) => {
		productSupportAttributes?.forEach((detail) => {
			if (family.toLowerCase() === detail?.family?.toLowerCase()) {
				sortedAttributes.push(detail);
			}
		});
	});

	return sortedAttributes;
};

export const sortProductDetailsByFamily = (product: ProductFromModel) => {
	const selectedProductDetails = getSelectedProductDetails(product);
	return sortProductSupportAttributesByFamily(selectedProductDetails);
};

export const groupBy = (productsDetails: ProductDetailsType[], key: 'family' | 'name') => {
	return productsDetails.reduce<AttributeValuesType[]>((acc, current) => {
		const currentValueByKey = current[key];
		const productDetailsItem = acc.find((item) => {
			const itemValue = item[key];
			return (itemValue && itemValue.toUpperCase()) === (currentValueByKey && currentValueByKey.toUpperCase());
		});

		const value = { ...current, value: current.value || EMPTY_STRING };

		if (productDetailsItem) {
			productDetailsItem.data.push(value);
		} else {
			acc.push({
				[key]: currentValueByKey,
				data: [value],
			});
		}
		return acc;
	}, []);
};

const FilterByObject = (product: ProductFromModel, filters: ProductFilter) =>
	Object.entries(filters).every(
		([detailLabel, detail]) => product[detailLabel as keyof ProductFromModel] === detail?.value
	);

const addPrintValuesAttributes = (
	allProducts: ProductDetailsType[] | null,
	printAttributes: PrintValue[] | null,
	attributes?: Attribute[] | null
) => {
	const filtredPrintAttributes: PrintAttributeType[] = [];
	printAttributes?.forEach((printAttribute) => {
		if (
			printAttribute.attribute?.key &&
			(!printAttribute.attribute?.key.includes('Largeur') || !printAttribute.attribute?.key.includes('Longueur'))
		) {
			filtredPrintAttributes.push({
				name: printAttribute?.attribute?.name,
				value: printAttribute.printValue,
				printName: printAttribute.printName,
				family: printAttribute?.attribute?.family,
				id: printAttribute.attribute?.id,
				key: printAttribute.attribute?.key,
				index: printAttribute?.attribute?.index,
			});
		}
	});

	if (allProducts && allProducts.length > 0) {
		return [...allProducts, ...(filtredPrintAttributes ?? [])];
	}

	let printAttributesWithAllSupportAttributes: ProductDetailsType[] = [];
	attributes?.forEach((attributeItem) => {
		const attributeExist = filtredPrintAttributes?.find(
			(filtredPrintAttribute) => filtredPrintAttribute.id === attributeItem.id
		);
		if (!attributeExist) {
			printAttributesWithAllSupportAttributes.push({
				...attributeItem,
				value: null,
			});
		}
	});

	return [...(filtredPrintAttributes ?? []), ...printAttributesWithAllSupportAttributes];
};

export const getProductsAttributesByFamily = (
	products: ProductFromModel[],
	filters?: ProductFilter | null,
	printAttributes?: PrintValue[] | null,
	attributes?: Attribute[] | null,
	isClariPrint?: boolean
) => {
	const newFilters = omit(['support'], filters);
	const filtredProducts =
		newFilters !== {}
			? products.filter(
					(productItem) =>
						FilterByObject(productItem, newFilters) &&
						// filter the products according to the conditionnement fields
						!(
							filters?.conditionnement1?.nbExConditionnement &&
							productItem?.conditionnement?.conditionnementList[0]?.firstConditionnement?.nbExConditionnement?.toString() !==
								(filters?.conditionnement1?.nbExConditionnement).toString()
						) &&
						!(
							filters?.conditionnement1?.conditionnement &&
							productItem?.conditionnement?.conditionnementList[0]?.firstConditionnement?.conditionnement !==
								filters?.conditionnement1?.conditionnement
						) &&
						!(
							isBoolean(filters?.conditionnement1?.labeling) &&
							productItem?.conditionnement?.conditionnementList[0]?.firstConditionnement?.labeling ===
								filters?.conditionnement1?.labeling
						) &&
						!(
							filters?.conditionnement2?.nbExConditionnement &&
							productItem?.conditionnement?.conditionnementList[0]?.secondConditionnement?.nbExConditionnement?.toString() !==
								(filters?.conditionnement2?.nbExConditionnement).toString()
						) &&
						!(
							filters?.conditionnement2?.conditionnement &&
							productItem?.conditionnement?.conditionnementList[0]?.secondConditionnement?.conditionnement !==
								filters?.conditionnement2?.conditionnement
						) &&
						!(
							isBoolean(filters?.conditionnement2?.labeling) &&
							productItem?.conditionnement?.conditionnementList[0]?.secondConditionnement?.labeling ===
								filters?.conditionnement2?.labeling
						) &&
						!(
							filters?.conditionnement3?.nbExConditionnement &&
							productItem?.conditionnement?.conditionnementList[0]?.threeConditionnement?.nbExConditionnement?.toString() !==
								(filters?.conditionnement3?.nbExConditionnement).toString()
						) &&
						!(
							filters?.conditionnement3?.conditionnement &&
							productItem?.conditionnement?.conditionnementList[0]?.threeConditionnement?.conditionnement !==
								filters?.conditionnement3?.conditionnement
						) &&
						!(
							isBoolean(filters?.conditionnement3?.labeling) &&
							productItem?.conditionnement?.conditionnementList[0]?.threeConditionnement?.labeling ===
								filters?.conditionnement3?.labeling
						)
			  )
			: products;

	let productsConditionnement: (AttributConditionnementItem | undefined | null)[] = [];

	filtredProducts.forEach((product) => {
		if (AttributeKey.Conditionnement && product.conditionnement?.conditionnementList[0])
			productsConditionnement.push(product.conditionnement?.conditionnementList[0]);
	});

	const allProductsAttributes: ProductDetailsType[] = [];
	filtredProducts.forEach((product) => {
		product.support.supportAttributes?.forEach((productSupportAttribute) => {
			if (productSupportAttribute.key && productSupportAttribute.key !== AttributeKey.Conditionnement)
				allProductsAttributes.push({ ...productSupportAttribute, value: product[productSupportAttribute.key] });
		});
	});

	const allProductsAttribuesWithPrintValue =
		printAttributes && isClariPrint
			? addPrintValuesAttributes(null, printAttributes, attributes)
			: allProductsAttributes;
	const allAttribues =
		allProductsAttribuesWithPrintValue.length > 0
			? allProductsAttribuesWithPrintValue
			: attributes?.map((attribute) => ({ ...attribute, value: null }));
	const sortedAllAttributesByIndex = sortByKey(allAttribues ?? [], 'index');
	const sortedAttribuesByFamily = sortProductSupportAttributesByFamily(
		sortedAllAttributesByIndex as ProductDetailsType[]
	);

	const productsAttribuesByFamily = groupBy(sortedAttribuesByFamily, 'family');
	const productsAttribuesByFamilyData = productsAttribuesByFamily.map((productsDetailsValue) => ({
		family: productsDetailsValue.family,
		data: groupBy(productsDetailsValue.data, 'name'),
	}));

	return { productsAttributesByFamily: productsAttribuesByFamilyData, filtredProducts, productsConditionnement };
};

export const formattedClientPrices = (tabIndex: number, clients?: clientPricesFromModel) =>
	clients
		?.map((client) => client.clientPrices)
		[tabIndex]?.map((price) => ({
			productName: price.productName,
			quantity: formatNumberDisplay(price.quantity, 0),
			nbShippingPoints: formatNumberDisplay(price.nbShippingPoints, 0),
			productSalesPriceExcludingVAT: formatNumberDisplay(price.productSalesPriceExcludingVAT, 2),
			productPurchasePriceExcludingVAT: formatNumberDisplay(price.productPurchasePriceExcludingVAT, 2),
			productDifferencePrice: formatNumberDisplay(price?.productPurchasePriceExcludingVAT, 2),
			transportSalesPriceExcludingVAT: formatNumberDisplay(price.transportSalesPriceExcludingVAT, 2),
			transportPurchasePriceExcludingVAT: formatNumberDisplay(price.transportPurchasePriceExcludingVAT, 2),
			transportDifferencePrice:
				price?.transportPurchasePriceExcludingVAT &&
				price.transportSalesPriceExcludingVAT &&
				formatNumberDisplay(price.transportSalesPriceExcludingVAT - price?.transportPurchasePriceExcludingVAT, 2),
			cost: price.cost,
			sellingPriceMargin: formatNumberDisplay(price.sellingPriceMargin, 2),
		}));

export const getConditionnementInfo = (conditionnement: AttributConditionnement | null | undefined) => {
	const severalCopies = (nbExConditionnement?: number | null, adverb?: string, flag?: string) => {
		if (nbExConditionnement) {
			return (
				(flag ?? adverb ?? '') +
				SPACE +
				'<b>' +
				nbExConditionnement +
				'</b>' +
				(!flag ? SPACE + 'exemplaire' + (nbExConditionnement && nbExConditionnement > 1 ? 's' : '') : '')
			);
		} else {
			return '';
		}
	};

	const structureConditionnement = (conditionnementInfo: Conditionnement, adverb: string, flag?: string) =>
		`${
			flag
				? adverb + SPACE + (conditionnementInfo.conditionnement.toLowerCase() ?? '')
				: conditionnementInfo.conditionnement ?? ''
		} ${
			(flag ?? adverb ? severalCopies(conditionnementInfo.nbExConditionnement, adverb, flag) : '') +
			(conditionnementInfo.nbExConditionnement ? SPACE : '') +
			(conditionnementInfo.labeling ? 'avec étiquetage' : '')
		}`;

	const getConditionnementInfoByKey = (key: string, conditionnementInfo?: Conditionnement | null) => {
		if (conditionnementInfo) {
			switch (key) {
				case 'firstConditionnement':
					return structureConditionnement(conditionnementInfo, 'par').trim();
				case 'secondConditionnement':
					return structureConditionnement(conditionnementInfo, 'Puis', 'par paquet de').trim();
				default:
					return `Puis ${conditionnementInfo.conditionnement.toLowerCase()} ${
						conditionnementInfo.labeling ? 'avec étiquetage' : ''
					}`.trim();
			}
		}
		return '';
	};

	const getConditionnement = (conditionnementList: AttributConditionnementItem[], numberOfLots?: number) => {
		return conditionnementList?.reduce<string[]>((acc, current, lotIndex) => {
			(Object.keys(current) as Array<keyof typeof current>).forEach((key) => {
				if (key !== 'numberOfExLots' && key !== '__typename' && current[key]?.conditionnement.length) {
					if (numberOfLots && numberOfLots >= lotIndex) {
						const nbrLot = `Lot${SPACE}${lotIndex + 1}: ${severalCopies(current.numberOfExLots ?? 0).trim()}`;
						if (!acc.includes(nbrLot)) acc.push(nbrLot);
					}
					acc = [...acc, getConditionnementInfoByKey(key, current[key])];
				}
			});
			return acc;
		}, []);
	};

	if (conditionnement) {
		if (conditionnement.numberOfLots > 1) {
			return [
				`Conditionnement avec <b>${conditionnement?.numberOfLots}</b> lots différents`,
				...getConditionnement(conditionnement.conditionnementList, conditionnement.numberOfLots),
			];
		} else {
			return getConditionnement(conditionnement.conditionnementList);
		}
	}
	return [];
};
