import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { LayoutField } from '../../../components/form/LayoutField';
import { FormikNumberField } from '../../../components/form/FormikNumberField';
import SupplierElpevFromBaseDB from '../../components/SupplierElpevFromBaseDB';

interface SupplierTechnicalInormationProps {
	title: string;
}

const SupplierTechnicalInormation = ({ title }: SupplierTechnicalInormationProps): JSX.Element => (
	<Box mb={3} pt={5}>
		<Box mb={6}>
			<Grid container>
				<Grid item xs={1}></Grid>
				<SupplierElpevFromBaseDB title={title} />
			</Grid>
		</Box>
		<Grid container>
			<Grid item xs={2}></Grid>
			<Grid item xs={8}>
				<LayoutField label="Poids maxi d’une palette 80x120 (kg)">
					<FormikNumberField decimalScale={0} name="maxPalletWeight" width="139px" maxValue={1000} variant="outlined" />
				</LayoutField>
			</Grid>

			<Grid item xs={2}></Grid>
		</Grid>
	</Box>
);

export default memo(SupplierTechnicalInormation);
