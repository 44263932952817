import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
	query GetRootClients($filter: ClientFilterInput!) {
		clients(filter: $filter) {
			id
			name
		}
	}
`;
