import { useLazyQuery } from '@apollo/client';
import { CHARACTERISTICS_BY_FAMILY } from '../graphql/queries';
import {
	PrintValuesCharacteristicsByFamilyQuery,
	PrintValuesCharacteristicsByFamilyQueryVariables,
} from '../graphql/queries.definitions';

export const useCharacteristicsQuery = (family: string) => {
	const [getCharacteristicsByFamily, { data }] = useLazyQuery<
		PrintValuesCharacteristicsByFamilyQuery,
		PrintValuesCharacteristicsByFamilyQueryVariables
	>(CHARACTERISTICS_BY_FAMILY, {
		variables: { family },
	});

	return { characteristicsPrintValues: data?.characteristics, getCharacteristicsByFamily };
};
