import { gql } from '@apollo/client';

const CONDITIONNEMENT_FRAGMENT = gql`
	fragment AttributConditionnement on AttributConditionnement {
		conditionnementList {
			numberOfExLots
			firstConditionnement {
				conditionnement
				labeling
				nbExConditionnement
			}
			secondConditionnement {
				conditionnement
				labeling
				nbExConditionnement
			}
			threeConditionnement {
				conditionnement
				labeling
				nbExConditionnement
			}
		}
		total
		numberOfLots
	}
`;

export default CONDITIONNEMENT_FRAGMENT;
