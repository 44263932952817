import React from 'react';
import { useParams } from 'react-router-dom';
import OrdersCreationDetail from './OrdersCreationDetail';
import OrdersCreationList from './OrdersList';
export const OrdersCreationRoute = () => {
	const { id: orderId } = useParams<{ id: string }>();
	if (orderId) {
		return <OrdersCreationDetail orderId={orderId} />;
	} else {
		return <OrdersCreationList isOrderCockpit={false} />;
	}
};
