import React from 'react';
import { useFormikContext } from 'formik';
import DateAddCommentProductInformationFiled from './DateAddCommentProductInformationFiled';
import { OrderItemUpsertInput } from 'graphql/types';
import { getDate, businessDays } from 'utils/normalizer';
import { NewOrderItem, OrderFormModel } from '../definition';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface datesAndCommentsProductInformationProps {
	orderIem?: OrderItemUpsertInput;
	index: number;
	isCell?: boolean;
}

const DatesAndCommentsProductInformation = ({
	orderIem,
	index,
	isCell,
}: datesAndCommentsProductInformationProps): JSX.Element => {
	const { setFieldValue, values } = useFormikContext<
		OrderFormModel & {
			newOrderItem: NewOrderItem;
		}
	>();

	const handleProvisionDateFromChange = (date: Date | null) => {
		orderIem
			? setFieldValue(`orderItem[${index}].provisionDate`, getDate(date))
			: setFieldValue('newOrderItem.provisionDate', getDate(date));
	};
	const handleCollectionDateFromChange = (date: Date | null) => {
		orderIem
			? setFieldValue(`orderItem[${index}].collectionDate`, getDate(date))
			: setFieldValue('newOrderItem.collectionDate', getDate(date));
	};

	const handleDeliveryDateFromChange = (date: Date | null) => {
		if (orderIem) {
			setFieldValue(`orderItem[${index}].expectedDeliveryDate`, getDate(date));
			setFieldValue(`orderItem[${index}].collectionDate`, businessDays('DD-MM-YYYY', 2, date));
			setFieldValue(`orderItem[${index}].provisionDate`, businessDays('DD-MM-YYYY', 3, date));
		} else {
			setFieldValue('newOrderItem.expectedDeliveryDate', getDate(date));
			setFieldValue('newOrderItem.collectionDate', businessDays('DD-MM-YYYY', 2, date));
			setFieldValue('newOrderItem.provisionDate', businessDays('DD-MM-YYYY', 3, date));
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateAddCommentProductInformationFiled
				isCell={isCell}
				dateLabel="Mise à disposition le"
				dateName={orderIem ? `orderItem[${index}].provisionDate` : 'newOrderItem.provisionDate'}
				comentLabel="Commentaire fournisseur"
				commentName={orderIem ? `orderItem[${index}].supplierComment` : 'newOrderItem.supplierComment'}
				handleChange={handleProvisionDateFromChange}
				value={
					index !== -1 && values.orderItem ? values.orderItem[index].provisionDate : values.newOrderItem?.provisionDate
				}
			/>

			<DateAddCommentProductInformationFiled
				isCell={isCell}
				dateLabel="Date d’enlèvement"
				dateName={orderIem ? `orderItem[${index}].collectionDate` : 'newOrderItem.collectionDate'}
				comentLabel="Commentaire enlèvement"
				commentName={orderIem ? `orderItem[${index}].collectionComment` : 'newOrderItem.collectionComment'}
				handleChange={handleCollectionDateFromChange}
				value={
					index !== -1 && values.orderItem
						? values.orderItem[index].collectionDate
						: values.newOrderItem?.collectionDate
				}
			/>

			<DateAddCommentProductInformationFiled
				isCell={isCell}
				dateLabel="Date de livraison"
				dateName={orderIem ? `orderItem[${index}].expectedDeliveryDate` : 'newOrderItem.expectedDeliveryDate'}
				comentLabel="Commentaire livraison"
				commentName={orderIem ? `orderItem[${index}].deliveryComment` : 'newOrderItem.deliveryComment'}
				handleChange={handleDeliveryDateFromChange}
				value={
					index !== -1 && values.orderItem
						? values.orderItem[index].expectedDeliveryDate
						: values.newOrderItem?.expectedDeliveryDate
				}
			/>
		</LocalizationProvider>
	);
};

export default DatesAndCommentsProductInformation;
