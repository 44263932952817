import { useQuery } from '@apollo/client';
import { GET_CLIENT_BY_ID } from '../graphql/queries';
import { GetClientByIdQuery, GetClientByIdQueryVariables } from '../graphql/queries.definitions';

const useClientQuery = (id: string) => {
	const { data, loading } = useQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(GET_CLIENT_BY_ID, {
		variables: {
			id,
		},
	});
	return { client: data?.client, loading };
};

export default useClientQuery;
