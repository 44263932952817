import { gql } from '@apollo/client';

const CONTACT_FRAGMENT = gql`
	fragment Contact on Contact {
		civility
		comment
		functions
		email
		id
		gsmPhone
		landlinePhone
		name
		privatePhone
		linkedIn
		inactif
		updatedAt
	}
`;

export default CONTACT_FRAGMENT;
