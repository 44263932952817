import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UpsertClientQuoteMutation, UpsertClientQuoteMutationVariables } from '../graphql/mutations.definitions';
import { UPSERT_CLIENT_QUOTE } from '../graphql/mutations';
import { GET_CLIENT_QUOTES } from '../graphql/queries';
import { omit } from 'ramda';
import { ClientQuoteModel } from '../form/definition';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useClientQuotesQuery from './useClientQuotesQuery';
import useClientQuoteQuery from './useClientQuoteQuery';
import { typeNotifications } from '../../utils/definition';

const useClientQuoteUpsert = (quoteId: string) => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { clientQuotes } = useClientQuotesQuery();

	const { clientQuote, loading, refetchClientQuote } = useClientQuoteQuery(quoteId);

	const [upsertClientQuoteMutation] = useMutation<UpsertClientQuoteMutation, UpsertClientQuoteMutationVariables>(
		UPSERT_CLIENT_QUOTE
	);

	const upsertClientQuote = useCallback(
		async (clientQuote: ClientQuoteModel, quoteId: string) => {
			const {
				billingAddress,
				versionNumber,
				client,
				selectedProduct,
				summaryPrice,
				quantity,
				productPurchasePriceExcludingVAT,
				productSalesPriceExcludingVAT,
				transportPurchasePriceExcludingVAT,
				transportSalesPriceExcludingVAT,
				cost,
				products,
				clientPricesExisting,
				clientId,
				clientName,
				createdAt,
				updatedAt,
				clientQuoteDistributions,
				...res
			} = clientQuote;

			const clientQuoteDistributionsToAdd =
				clientQuoteDistributions?.map(({ __typename, clientShippingAddress, store, ...keepAttrs }) => keepAttrs) || [];

			await upsertClientQuoteMutation({
				variables: {
					data: {
						...omit(
							['additionalCostsIncurred', 'clientQuoteCreatedAt', 'fileReferences', 'fileReference', 'distributionsGroupedByAddress'],
							res
						),
						clientId: clientId ?? '',
						clientName: clientName ?? '',
						id: quoteId,
						billingAddress: omit(['__typename'], billingAddress),
						clientQuoteItem: res.clientQuoteItem?.map((item) =>
							omit(
								[
									'__typename',
									'product',
									'autrePrixFixe',
									'prixCalage',
									'prixChangementPlaque',
									'prixCliche',
									'prixExemplaireSupp',
									'prixHorsCalage',
									'prixOutillage',
									'prixTraitementFichier',
									'prixMillePlusHT',
									'prixMilleMoinsHT',
									'prixCentPlusHT',
									'prixCentMoinsHT',
									'transportInclus',
									'salePrice',
									'billingAddress',
									'detailPrepressePDF',
									'prepresseNonInclus',
									'prepresseInclus',
									'transportInclus',
									'transportNonInclus',
									'prepresseNonApplicable',
									'initialType',
								],
								{ ...item, sellingPriceWithMargin: omit(['__typename'], item.sellingPriceWithMargin) }
							)
						),
						clientQuoteDistributions: res.isDistribution ? clientQuoteDistributionsToAdd : [],
					},
				},
				refetchQueries: [
					{
						query: GET_CLIENT_QUOTES,
					},
				],
				awaitRefetchQueries: true,
			});
		},
		[upsertClientQuoteMutation]
	);

	const handleClientQuoteSubmit = useCallback(
		async (clientQuote: ClientQuoteModel) => {
			try {
				await upsertClientQuote(omit(['__typename', 'clients', 'fileReferenceSelected'], clientQuote), quoteId);
				const quoteNumber = clientQuotes && clientQuotes[0]?.id ? parseInt(clientQuotes[0]?.id.substr(5)) + 1 : 1;
				const id = 'OS-DC' + quoteNumber;
				if (clientQuote.id) await refetchClientQuote();
				enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
				quoteId === ':id' && history.push(`/clientQuote/${id}`);
			} catch (error) {
				enqueueSnackbar('Erreur', { variant: typeNotifications.Error });
			}
		},
		[clientQuotes, enqueueSnackbar, refetchClientQuote, history, quoteId, upsertClientQuote]
	);

	return { clientQuote, loading, handleClientQuoteSubmit };
};

export default useClientQuoteUpsert;
