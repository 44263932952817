import { Box } from '@mui/material';
import {
	GridColDef,
	frFR,
	DataGrid,
	GridValueGetterParams,
	GridRowParams,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarContainer,
	GridSortModel,
	GridFilterModel,
	getGridStringOperators,
	getGridDateOperators,
	getGridNumericOperators,
	GridFeatureModeConstant,
} from '@mui/x-data-grid';

import { InvoiceSearchInput, InvoiceSortInput, InvoiceStatus } from 'graphql/types';
import { PATH_NAMES } from 'Header/menus';
import useInvoicesQuery from 'invoice/hooks/useInvoicesQuery';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import colors from 'app/theme/colors.scss';
import Path from 'components/Path';
import { formatDateByDDMMYYYY } from 'utils/normalizer';
const set = require('lodash.set');

const ToBeValidatedInvoicesList = () => {
	const history = useHistory();
	const [filters, setFilters] = useState<InvoiceSearchInput>({});
	const [sorts, setSorts] = useState<InvoiceSortInput>({});
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const pagination = { take: pageSize, skip: pageSize * page };
	const { invoices, total, loading, refetchInvoices } = useInvoicesQuery(
		filters,
		sorts,
		{ status: InvoiceStatus.ToValidate },
		pagination
	);

	const columns: GridColDef[] = useMemo(
		() => [
			{
				headerName: 'N° de facture',
				field: 'id',
				flex: 1,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},
			{
				headerName: 'Date de création',
				field: 'createdAt',
				flex: 1,
				type: 'date',

				filterOperators: getGridDateOperators().filter((operator) =>
					['onOrAfter', 'onOrBefore'].includes(operator.value)
				),
				valueGetter: ({ row }: GridValueGetterParams) => formatDateByDDMMYYYY(row.createdAt),
				disableColumnMenu: true,
			},
			{
				headerName: 'N° Réf OS',
				field: 'order.parentOrderId',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row?.order?.parentOrderId,
				disableColumnMenu: true,
			},

			{
				headerName: 'Client',
				field: 'order.clientName',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row?.order.clientName,
				disableColumnMenu: true,
			},
			{
				headerName: 'Demandé par',
				field: 'order.fileReference.askBy',
				flex: 1,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				valueGetter: ({ row }: GridValueGetterParams) =>
					row?.order?.fileReference?.askBy ? row?.order?.fileReference?.askBy : '',
				disableColumnMenu: true,
			},
			{
				headerName: "Nom de l'opération",
				field: 'order.fileReference.operationName',
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row?.order?.fileReference?.operationName,
				disableColumnMenu: true,
			},
			{
				headerName: 'Réf client',
				field: 'order.fileReference.clientReference',
				flex: 1,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				valueGetter: ({ row }: GridValueGetterParams) => row?.order?.fileReference?.clientReference,
				disableColumnMenu: true,
			},
			{
				headerName: 'Responsable Elpev',
				field: 'order.fileReference.elpevResponsible',
				filterable: false,
				sortable: false,
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) =>
					row?.order?.fileReference?.elpevResponsible
						? `${row?.order?.fileReference?.elpevResponsible?.familyName}
					${row?.order?.fileReference?.elpevResponsible?.givenName}`
						: '',
				disableColumnMenu: true,
			},
			{
				headerName: 'Société facturé',
				field: 'order.billingEntity',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row?.order?.billingAddress.name,
				disableColumnMenu: true,
			},
			{
				headerName: 'Montant HT (€)',
				field: 'order.sellingPriceHt',
				flex: 1,
				type: 'number',
				filterOperators: getGridNumericOperators().filter((operator) => operator.value === '='),
				valueGetter: ({ row }: GridValueGetterParams) => `${row?.order?.sellingPriceHt} €`,
				disableColumnMenu: true,
			},
		],
		[]
	);

	const handleRowClick = ({ row }: GridRowParams) => {
		if (!row.id) return;
		history.push(`/invoice/${row.id}`);
	};

	const CustomToolbar = () => (
		<GridToolbarContainer>
			<GridToolbarFilterButton />
			<GridToolbarColumnsButton />
		</GridToolbarContainer>
	);

	const onSortChange = (sortModel: GridSortModel) => {
		const sorts = sortModel.reduce((acc, sort) => {
			set(acc, sort.field, sort.sort);
			return acc;
		}, {});

		setSorts(sorts);
	};

	const onFilterChange = (filterModel: GridFilterModel) => {
		const filters = filterModel.items.reduce((acc: Record<string, string>, filter) => {
			switch (filter.operatorValue) {
				case 'contains':
					set(acc, filter.columnField, { contains: filter.value });
					break;
				case 'onOrAfter':
					set(acc, filter.columnField, { gte: filter.value });
					break;
				case 'onOrBefore':
					set(acc, filter.columnField, { lte: filter.value });
					break;
				default:
					set(acc, filter.columnField, filter.value);
					break;
			}

			return acc;
		}, {});

		setFilters(filters);
	};

	React.useEffect(() => {
		if (filters || sorts || page || pageSize) {
			refetchInvoices();
		}
	}, [refetchInvoices, filters, sorts, page, pageSize]);

	return (
		<Box mx={7}>
			<Box position="fixed" width="100%" top={40} left={0} px={11.75} bgcolor={colors.bodyBackgroundLight} zIndex={100}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					{<Path paths={[PATH_NAMES.Invoices]} />}
				</Box>

				<Box
					mt={2}
					sx={{
						height: '75vh',
						width: '100%',
						'.rowLeftBorder': {
							borderLeft: `8px solid ${colors.primary}`,
						},
					}}
				>
					<DataGrid
						sx={{ fontSize: '0.835rem' }}
						columns={columns}
						loading={!invoices && loading}
						rows={invoices ?? []}
						onRowClick={handleRowClick}
						filterMode={GridFeatureModeConstant.server}
						onFilterModelChange={onFilterChange}
						sortingMode={GridFeatureModeConstant.server}
						onSortModelChange={onSortChange}
						getRowClassName={() => 'rowLeftBorder'}
						components={{
							Toolbar: CustomToolbar,
						}}
						rowsPerPageOptions={[10, 25, 50, 100]}
						pagination={true}
						page={page}
						pageSize={pageSize}
						rowCount={total || 0}
						paginationMode={GridFeatureModeConstant.server}
						onPageChange={setPage}
						onPageSizeChange={setPageSize}
						localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default ToBeValidatedInvoicesList;
