import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import DashedHeader from '../../../../components/DashedHeader';
import { useFormikContext } from 'formik';
import { ClientQuoteModel } from '../../definition';
import colors from '../../../../app/theme/colors.scss';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { sum } from '../../helpers';
import { formatNumberDisplay } from '../../../../utils/prices';

const useStyles = makeStyles(() =>
	createStyles({
		firstCell: {
			borderLeft: '3px solid',
			borderLeftColor: colors.turquoise500,
			borderTopLeftRadius: '3px',
			borderTopRightRadius: '3px',
		},
	})
);

const TotalTable = (): JSX.Element => {
	const classes = useStyles();
	const { setFieldValue, values } = useFormikContext<ClientQuoteModel>();

	const {
		productPurchasePriceExcludingVAT,
		transportPurchasePriceExcludingVAT,
		cost: totalSalesPriceExcludingVAT,
		estimatedCiteoTax,
	} = sum(values.clientQuoteItem);

	const sellingPriceMargin = values?.clientQuoteItem?.reduce<number>((acc, current) => {
		acc += current?.sellingPriceMargin ?? 0;
		return acc;
	}, 0);

	const totalPurchasePriceExcludingVAT = productPurchasePriceExcludingVAT + transportPurchasePriceExcludingVAT;

	const vatAmount = (values.vatRate * totalSalesPriceExcludingVAT) / 100;
	const totalSalesPriceVAT = (values.vatRate * totalSalesPriceExcludingVAT) / 100 + totalSalesPriceExcludingVAT;
	const margin = totalSalesPriceExcludingVAT - totalPurchasePriceExcludingVAT;
	const marginRate =
		totalSalesPriceExcludingVAT - totalPurchasePriceExcludingVAT === totalSalesPriceExcludingVAT
			? 100
			: (totalSalesPriceExcludingVAT * 100) / totalPurchasePriceExcludingVAT - 100;

	useEffect(() => {
		setFieldValue('totalPurchasePriceExcludingVAT', totalPurchasePriceExcludingVAT);
		setFieldValue('totalSalesPriceExcludingVAT', totalSalesPriceExcludingVAT);
		setFieldValue('vatAmount', vatAmount);
		setFieldValue('totalSalesPriceVAT', totalSalesPriceVAT);
		setFieldValue('margin', margin);
		setFieldValue('estimatedCiteoTax', estimatedCiteoTax);
		setFieldValue('marginRate', marginRate);
	}, [
		totalPurchasePriceExcludingVAT,
		totalSalesPriceExcludingVAT,
		vatAmount,
		totalSalesPriceVAT,
		margin,
		estimatedCiteoTax,
		marginRate,
		setFieldValue,
	]);

	const horizontalCell = [
		{ label: 'Totale PA HT (€)', value: values.totalPurchasePriceExcludingVAT },
		{ label: 'Totale PV HT (€)', value: values.totalSalesPriceExcludingVAT },
		{ label: 'Taux T.V.A (%)', value: values.vatRate },
		{ label: 'Montant T.V.A (€)', value: values.vatAmount },
		{ label: 'Total PV TTC (€)', value: values.totalSalesPriceVAT },
	];

	const verticalCell = [
		{ label: 'Marge (€)', value: sellingPriceMargin ?? 0 },
		{ label: 'Taux de marge (%)', value: ((sellingPriceMargin ?? 0) * 100) / values.totalSalesPriceVAT },
		{ label: 'Estimatif Taxe Citéo(€)', value: values.estimatedCiteoTax },
	];

	return (
		<>
			<Box p={1} mt={2}>
				<DashedHeader>Total</DashedHeader>
			</Box>

			<Box width="100%" m="10px auto" p={2}>
				<Box display="flex" justifyContent="flex-end">
					{horizontalCell.map((cell, index) => (
						<Box mr={0.5} key={index}>
							<Typography variant="h6" color="textSecondary">
								{cell.label}
							</Typography>
							<Box
								className={index === 0 ? classes.firstCell : ''}
								borderLeft={'5px'}
								borderColor={colors.red500}
								width="185px"
								bgcolor={colors.white}
								p={1}
							>
								<Typography variant="h5">{formatNumberDisplay(cell.value, 2)}</Typography>
							</Box>
						</Box>
					))}
				</Box>

				{verticalCell.map((cell, index) => (
					<Box key={index} mr={0.5} mt={0.5} display="flex" justifyContent="flex-end">
						<Box p={1}>
							<Typography variant="h6" color="textSecondary">
								{cell.label}
							</Typography>
						</Box>
						<Box width="185px" bgcolor={colors.white} p={1}>
							<Typography variant="h5">{formatNumberDisplay(cell.value, 2)}</Typography>
						</Box>
					</Box>
				))}
			</Box>
		</>
	);
};

export default TotalTable;
