import RoleInformations from '../admin/role/RoleInformations';
import Support from '../admin/support/Support';
import UserInformationsForm from '../admin/user/Form/UserInformationsForm';
import User from '../admin/user/User';
import ClientRoute from '../clients/ClientRoute';
import Product from '../product/Product';
import Quatref from '../Quatref/Quatref';
import BigShopConfigRoute from '../admin/big-shop-management/BigShopConfigRoute';
import ParcelTransportRoute from '../admin/transportRules/ParcelTransportRoute';
import { PrintValuesRoute } from '../admin/print-values/PrintValuesRoute';
import Supplier from '../supplier/Supplier';
import { ClientPriceRoute } from '../clientPrice/ClientPriceRoute';
import { ClientQuoteRoute } from '../client-quote/ClientQuoteRoute';
import { InvoiceRoute } from '../invoice/InvoiceRoute';
import InvoicesListToBeAccounted from 'invoice/form/invoicesList-to-be-accounted/InvoicesListToBeAccounted';
import FileReference from '../file-reference/FileReference';
import ClientQuoteDetail from 'client-quote/ClientQuoteDetail';
import InvoicesCountedList from 'invoice/form/counted-invoices-list/InvoicesCountedList';
import ToBeValidatedInvoicesList from 'invoice/form/to-be-validated-invoices-list/ToBeValidatedInvoicesList';
import AllClientsPricesList from 'clientPrice/menu/ AllClientsPricesList';
import { OrdersCreationRoute } from 'order/OrdersCreationRoute';
import {OrderCockpitRoute} from "../order/OrderCockpitRoute";
import { ImportRoute } from 'admin/import/ImportRoute';

export enum AppRoute {
  Product = '/product/:id',
  Support = '/admin/support/:id',
  User = '/admin/user',
  Role = '/admin/role/:id',
  UserForm = '/admin/userform/:id',
  Quatref = '/quatref',
  SupplierQuote = '/supplierQuote',
  Supplier = '/supplier/:id',
  ClientQuote = '/clientQuotes/:id?',
  NewClientQuote = '/clientQuotes/new',
  Client = '/sheets/client/:id',
  OrderCockpit = '/orderCockpit/:id?',
  BigShop = '/BigShopManagement/:id',
  ParcelTransport = '/parcelTransport',
  PrintValues = '/printValues',
  sellingPrice = '/sellingPrice/:id/:productId?/:quantity?/:numberVersion?',
  invoice = '/invoice/:id',
  InvoicesListToBeAccounted = '/to-be-acounted-invoices-list',
  InvoicesCountedList = '/counted-invoices-list',
  FileReference = '/fileReference',
  ToBeValidatedInvoicesList = '/to-validate-invoices-list',
  AllClientsPricesList = '/sellingPrice/',
  orders = '/orders/:id?',
  Import = '/import',
}

export interface Route {
	component: React.ElementType;
	permissionKey: string;
}

export const ROUTES: { [key in AppRoute]: Route } = {
	[AppRoute.Product]: {
		component: Product,
		permissionKey: 'view:product',
	},
	[AppRoute.Support]: {
		component: Support,
		permissionKey: 'view:admin_supports',
	},
	[AppRoute.Role]: {
		component: RoleInformations,
		permissionKey: 'view:users_management',
	},
	[AppRoute.UserForm]: {
		component: UserInformationsForm,
		permissionKey: 'view:users_management',
	},
	[AppRoute.User]: {
		component: User,
		permissionKey: 'view:users_management',
	},
	[AppRoute.Quatref]: {
		component: Quatref,
		permissionKey: 'view:four_fantastic',
	},
	[AppRoute.SupplierQuote]: {
		component: () => null,
		permissionKey: 'view:supplier_quotes',
	},
	[AppRoute.Supplier]: {
		component: Supplier,
		permissionKey: 'view:client_cards_suppliers_management',
	},
	[AppRoute.NewClientQuote]: {
		component: ClientQuoteDetail,
		permissionKey: 'view:client_quotes',
	},
	[AppRoute.ClientQuote]: {
		component: ClientQuoteRoute,
		permissionKey: 'view:client_quotes',
	},
	[AppRoute.Client]: {
		component: ClientRoute,
		permissionKey: 'view:client_cards_clients_management',
	},
	[AppRoute.BigShop]: {
		component: BigShopConfigRoute,
		permissionKey: 'view:admin',
	},
	[AppRoute.ParcelTransport]: {
		component: ParcelTransportRoute,
		permissionKey: 'view:admin_transport_rules',
	},
	[AppRoute.PrintValues]: {
		component: PrintValuesRoute,
		permissionKey: 'view:print_value',
	},
  [AppRoute.Import]: {
		component: ImportRoute,
		permissionKey: 'view:import',
	},
	[AppRoute.sellingPrice]: {
		component: ClientPriceRoute,
		permissionKey: 'view:sales_price_management',
	},
	[AppRoute.AllClientsPricesList]: {
		component: AllClientsPricesList,
		permissionKey: 'view:sales_price_management',
	},
	[AppRoute.invoice]: {
		component: InvoiceRoute,
		permissionKey: 'view:invoice',
	},
	[AppRoute.OrderCockpit]: {
		component: OrderCockpitRoute,
		permissionKey: 'view:orders_management',
	},
	[AppRoute.orders]: {
		component: OrdersCreationRoute,
		permissionKey: 'view:orders_creation',
	},
	[AppRoute.InvoicesListToBeAccounted]: {
		component: InvoicesListToBeAccounted,
		permissionKey: 'view:invoice_accounting',
	},
	[AppRoute.InvoicesCountedList]: {
		component: InvoicesCountedList,
		permissionKey: 'view:invoice_paid',
	},

	[AppRoute.ToBeValidatedInvoicesList]: {
		component: ToBeValidatedInvoicesList,
		permissionKey: 'view:invoice',
	},

	[AppRoute.FileReference]: {
		component: FileReference,
		permissionKey: 'view:file_reference',
	},
};
