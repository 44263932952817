import { Box } from '@mui/material';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PATH_NAMES } from '../../Header/menus';
import BigShopForm from './form/BigShopForm';
import useBigShopByIdQuery from './hooks/useBigShopConfigByIdQuery';
import { useSnackbar } from 'notistack';
import { BigShopConfig } from '../../graphql/types';
import useBigShopConfigUpsert from './hooks/useBigShopConfigUpsert';
import useSupportByIdQuery from '../support/hooks/useSupportByIdQuery';

interface BigShopRouteParams {
	id: string;
}

interface BigShopRouteProps extends RouteComponentProps<BigShopRouteParams> {}

const BigShopConfigRoute = ({
	match: {
		params: { id },
	},
}: BigShopRouteProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { bigShopConfig } = useBigShopByIdQuery(id);
	const { upsertBigShop } = useBigShopConfigUpsert();
	const { support } = useSupportByIdQuery(id);
	const path = [PATH_NAMES.Admin, PATH_NAMES.BigShopManagement, support?.name];

	const onSubmit = async (submittedBigShop: BigShopConfig | undefined) => {
		try {
			const { __typename, ...newValues } = Object.assign({}, submittedBigShop, { supportId: id });
			await upsertBigShop(newValues);
			enqueueSnackbar('Modifications enregistrées', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Erreur', { variant: 'error' });
		}
	};

	return (
		<Box mx={7}>
			<BigShopForm onSubmit={onSubmit} paths={path} bigShopConfig={bigShopConfig} />
		</Box>
	);
};

export default BigShopConfigRoute;
