import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Grid } from '@mui/material';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined';
import { MIN_PREVIEW_PAGE } from '../definitions';
import { PreviewFile } from 'components/form/FormikFilesField';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewPdfProps {
	file: PreviewFile | string;
}

export const PreviewFiles = ({ file }: PreviewPdfProps) => {
	const [numPages, setNumPages] = useState<number | null>(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }: pdfjs.PDFDocumentProxy) => {
		setNumPages(numPages);
	};

	const handleNextPage = () => {
		if (numPages) {
			if (numPages >= pageNumber) setPageNumber(pageNumber + 1);
			else setPageNumber(numPages);
		}
	};

	const handlePreviousPage = () => {
		if (pageNumber >= MIN_PREVIEW_PAGE) setPageNumber(pageNumber - 1);
	};

	return (
		<Grid container>
			<Grid xs={12} item>
				<Box display="flex" flexDirection="row-reverse">
					Page {pageNumber}/{numPages}
				</Box>
			</Grid>
			<Grid xs={12} item>
				<Grid container>
					<Grid xs={1} item>
						{numPages && numPages > 1 && (
							<Box display="flex" alignItems="center" height="100%">
								<SkipPreviousOutlinedIcon fontSize="large" onClick={handlePreviousPage} />
							</Box>
						)}
					</Grid>
					<Grid xs={10} item>
						<Box display="flex" justifyContent="center">
							<Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
								<Page pageNumber={pageNumber} />
							</Document>
						</Box>
					</Grid>
					<Grid xs={1} item>
						{numPages && numPages > 1 && (
							<Box display="flex" alignItems="center" height="100%">
								<SkipNextOutlinedIcon fontSize="large" onClick={handleNextPage} />
							</Box>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
