import { Box, Chip } from '@mui/material';
import {
	GridColDef,
	getGridStringOperators,
	getGridDateOperators,
	GridValueGetterParams,
	DataGrid,
	GridRowParams,
	GridSortModel,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
	getGridNumericOperators,
	getGridSingleSelectOperators,
} from '@mui/x-data-grid';
import colors from 'app/theme/colors.scss';
import theme from 'app/theme/theme';
import { StatusClientPrice } from 'clientPrice/form/definitions';
import { getClientPriceStatusValueFilter } from 'clientPrice/helpers';
import useAllClientsPricesWithPagination from 'clientPrice/hooks/useAllClientPricesWithPagination';
import Loading from 'components/loading/Loading';
import Path from 'components/Path';
import { ClientPriceSortInput, ClientPricesSearchInput, ClientPriceStatus } from 'graphql/types';
import { PATH_NAMES } from 'Header/menus';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateByDDMMYYYY } from 'utils/normalizer';
const set = require('lodash.set');

interface StatusChipProps {
	status?: keyof typeof ClientPriceStatus;
}

const statusChipStyle = (status: keyof typeof ClientPriceStatus) => {
	switch (status) {
		case ClientPriceStatus.InProgress:
			return {
				background: colors.green100,
				color: theme.palette.getContrastText(colors.green100),
			};

		case ClientPriceStatus.ValidatedByCustomerQuote:
			return {
				background: colors.blue200,
				color: theme.palette.getContrastText(colors.blue200),
			};
		case ClientPriceStatus.WaitingByValidationQuote:
			return {
				background: colors.red100,
				color: theme.palette.getContrastText(colors.red100),
			};
	}
};

const StatusChip = ({ status }: StatusChipProps) => {
	if (!status) {
		return <span>-</span>;
	}
	return <Chip label={StatusClientPrice[status]} style={{ ...statusChipStyle(status) }} />;
};

const AllClientsPricesList = (): JSX.Element => {
	const history = useHistory();
	const [search, setSearch] = useState<ClientPricesSearchInput>();
	const [sorts, setSorts] = useState<ClientPriceSortInput>();
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const pagination = { take: pageSize, skip: pageSize * page };

	const { clientPrices, total, loading } = useAllClientsPricesWithPagination(search, sorts, pagination);

	if (loading) <Loading loading />;

	const columns: GridColDef[] = React.useMemo(
		() => [
			{
				headerName: 'Client',
				field: 'client.name',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row.client.name,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),

				disableColumnMenu: true,
			},
			{
				headerName: 'Nom du produit',
				field: 'productName',
				flex: 1,
				filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
				disableColumnMenu: true,
			},

			{
				headerName: 'Date de création',
				field: 'createdAt',
				flex: 1,
				type: 'date',
				filterOperators: getGridDateOperators().filter((operator) =>
					['onOrAfter', 'onOrBefore'].includes(operator.value)
				),
				valueGetter: ({ row }: GridValueGetterParams) => formatDateByDDMMYYYY(row.createdAt),
				disableColumnMenu: true,
			},
			{
				headerName: 'Type de support',
				field: 'product.support.category',
				flex: 1,
				type: 'singleSelect',
				valueOptions: () => {
					return ['PLV', 'Print', 'Roto', 'Digital'];
				},

				disableColumnMenu: true,
				filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
				valueGetter: ({ row }: GridValueGetterParams) => row.product.support.category,
			},
			{
				headerName: 'Quantité',
				field: 'quantity',
				type: 'number',
				flex: 1,

				filterOperators: getGridNumericOperators().filter((operator) => operator.value === '='),
				disableColumnMenu: true,
			},

			{
				headerName: 'Nombre de version',
				field: 'numberVersion',
				flex: 1,
				filterOperators: getGridNumericOperators().filter((operator) => operator.value === '='),

				disableColumnMenu: true,
			},
			{
				headerName: 'Statut',
				field: 'status',
				flex: 1,
				type: 'singleSelect',
				valueOptions: () => {
					return Object.values(StatusClientPrice);
				},
				renderCell: ({ value }) => {
					return <StatusChip status={value} />;
				},
				disableColumnMenu: true,
			},
			{
				headerName: 'PV Produit HT [€)',
				field: 'productSalesPriceExcludingVAT',
				flex: 1,
				filterOperators: getGridNumericOperators().filter((operator) => operator.value === '='),
				disableColumnMenu: true,
			},
			{
				headerName: 'PV Transport HT [€)',
				field: 'transportSalesPriceExcludingVAT',
				flex: 1,
				filterOperators: getGridNumericOperators().filter((operator) => operator.value === '='),
				disableColumnMenu: true,
			},
			{
				headerName: 'Prix de vente HT (€)',
				field: 'cost',
				flex: 1,
				filterOperators: getGridNumericOperators().filter((operator) => operator.value === '='),
				disableColumnMenu: true,
			},
		],
		[]
	);
	const handleRowClick = ({ row }: GridRowParams) => {
		history.push(`/sellingPrice/${row.client.id}/${row.product.id}/${row.quantity}/${row.numberVersion}`);
	};

	const onSortChange = (sortModel: GridSortModel) => {
		const sorts = sortModel.reduce((acc, sort) => {
			set(acc, sort.field, sort.sort);
			return acc;
		}, {});
		setSorts(sorts);
	};

	const onFilterChange = (filterModel: GridFilterModel) => {
		const filters = filterModel.items.reduce((acc: any, filter) => {
			switch (filter.operatorValue) {
				case 'contains':
					set(acc, filter.columnField, { contains: filter.value });
					break;
				case 'onOrAfter':
					set(acc, filter.columnField, { gte: filter.value });
					break;
				case 'onOrBefore':
					set(acc, filter.columnField, { lte: filter.value });
					break;
				default:
					set(
						acc,
						filter.columnField,
						filter.columnField === 'status' ? getClientPriceStatusValueFilter(filter.value) : filter.value
					);
					break;
			}

			return acc;
		}, {});

		setSearch(filters);
	};
	const CustomToolbar = () => (
		<GridToolbarContainer>
			<GridToolbarFilterButton />
			<GridToolbarColumnsButton />
		</GridToolbarContainer>
	);
	return (
		<Box mx={7}>
			<Box position="fixed" width="100%" top={40} left={0} px={11.75} bgcolor={colors.bodyBackgroundLight} zIndex={100}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Box>
						<Path paths={[PATH_NAMES.SellingPrice]} />
					</Box>
				</Box>
				<Box
					mt={2}
					sx={{
						height: '75vh',
						width: '100%',
						'.rowLeftBorder': {
							borderLeft: `8px solid ${colors.primary}`,
						},
					}}
				>
					<DataGrid
						getRowId={(row) => row.client.id + row.product.id + row.quantity}
						sx={{ fontSize: '0.835rem' }}
						columns={columns}
						loading={!clientPrices && loading}
						rows={clientPrices ?? []}
						onRowClick={handleRowClick}
						filterMode="server"
						onFilterModelChange={onFilterChange}
						sortingMode="server"
						onSortModelChange={onSortChange}
						getRowClassName={() => 'rowLeftBorder'}
						components={{
							Toolbar: CustomToolbar,
						}}
						rowsPerPageOptions={[10, 25, 50, 100]}
						pagination={true}
						page={page}
						pageSize={pageSize}
						rowCount={total || 0}
						paginationMode="server"
						onPageChange={setPage}
						onPageSizeChange={setPageSize}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default AllClientsPricesList;
