import { gql } from '@apollo/client';

const FILE_REFERENCE_FRAGMENT = gql`
	fragment FileReference on FileReference {
		clientReference
		operationName
		id
		source
		askBy
		status
		createdAt
	}
`;

export default FILE_REFERENCE_FRAGMENT;
