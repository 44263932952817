import { useMutation } from '@apollo/client';
import { omit } from 'ramda';
import { UpsertInvoiceMutation, UpsertInvoiceMutationVariables } from '../graphql/mutations.definitions';
import { GET_INVOICES } from '../graphql/queries';
import { UPSERT_INVOICE } from 'invoice/graphql/mutations';
import { GET_ORDERS, GET_ORDER_BY_ID } from 'order/graphql/queries';
import { typeNotifications } from 'utils/definition';
import { InvoiceStatus } from 'graphql/types';

export const useInvoiceUpsert = () => {
	const [upsertInvoiceMutation] = useMutation<UpsertInvoiceMutation, UpsertInvoiceMutationVariables>(UPSERT_INVOICE);

	const upsertInvoice = async (invoice: UpsertInvoiceMutationVariables['invoice']) => {
		const invoiceInput = omit(['__typename', 'createdAt'], invoice);
		const { id, status, orderId, order, ...invoiceRest } = invoiceInput;

		const { data, errors } = await upsertInvoiceMutation({
			variables: {
				invoice: {
					id,
					orderId,
					status,
					order: {
						clientName: order?.clientName ?? '',
						clientId: order?.clientId ?? '',
						id: order?.id ?? '',
						billingAddress: {
							city: order?.billingAddress?.city,
							name: order?.billingAddress?.name,
							country: order?.billingAddress?.country,
							firstAddress: order?.billingAddress?.firstAddress,
							secondAddress: order?.billingAddress?.secondAddress,
							zipCode: order?.billingAddress?.zipCode,
							thirdAddress: order?.billingAddress?.thirdAddress,
						},
						orderItem:
							order?.orderItem.map(
								({
									id,
									isWithRfaProduct,
									isWithRfaTransport,
									realProductMargin,
									realTransportMargin,
									productId,
									productName,
									quantity,
									onePacDocumentId,
								}) => {
									return {
										id,
										isWithRfaProduct,
										isWithRfaTransport,
										realProductMargin,
										realTransportMargin,
										productId,
										productName,
										quantity,
										onePacDocumentId,
									};
								}
							) ?? [],
					},

					...invoiceRest,
				},
			},
			refetchQueries: [
				{
					query: GET_ORDERS,
				},
				{
					query: GET_ORDER_BY_ID,
					variables: { id: orderId },
				},
				{ query: GET_INVOICES, variables: { filter: { status: InvoiceStatus.ToValidate } } },
			],
		});
		if (errors) throw new Error(typeNotifications.Error);
		return data;
	};
	return { upsertInvoice };
};
