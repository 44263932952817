import { TransporterPrices } from 'order/definition';
import { GetOrderByIdQuery } from 'order/graphql/queries.definitions';

export const orderItemPick = [
	'trackingLink',
	'legacyRef',
	'sellingPriceTtc',
	'tva',
	'tvaRate',
	'productSellingPriceHt',
	'prepressPriceHt',
	'prepressSellingPriceHt',
	'transportBuyingPriceHt',
	'transportSellingPriceHt',
	'buyingPriceHt',
	'sellingPriceHt',
	'expectedDeliveryDate',
	'imgUrl',
	'comment',
	'productTotalWeight',
	'productId',
	'status',
	'productName',
	'quantity',
	'numberVersion',
	'versionsNumber',
	'provisionDate',
	'collectionDate',
	'supplierComment',
	'commentTransporter',
	'collectionComment',
	'deliveryComment',
	'numberfileProcessing',
	'numberPlateChanges',
	'tools',
	'numberShots',
	'suppliersPrices',
	'bestSupplierId',
	'onePacDocumentId',
	'transportPurchasePriceHt',
	'transportMoment',
	'transportSellingPriceHtAdditionalMargin',
	'transportType',
	'transportRfa',
	'transportSellingPriceMargin',
	'transportSellingPriceByDefaultHt',
	'transportPurchasePriceHtWithRfa',
	'corsicaDeliveryExtra',
	'isMechanizable',
	'isFinalDistribution',
	'transportSellingPriceHtWithClientRfa',
	'transportSellingPriceHtWithClientRfaRectified',
	'deliveryParcel',
	'transportersPrices',
	'bestTransporterId',
	'productTotalWeight',
	'transportPurchasePriceExcludingVAT',
	'totalPurchasePriceExcludingTax',
	'transporterPurchasePriceExcludingVAT',
	'transporterPurchaseExcludingVATWithRfa',
	'transporterRfaRate',
	'productPurchasePriceExcludingVAT',
	'productPurchasePriceExcludingVATWithRFA',
	'deliveryDepartment',
	'supplierRspRate',
	'supplierRfaRate',
	'bestDeliveryParcelPurchasePrice',
];

export type OrderItem = GetOrderByIdQuery['order']['orderItem'][0] & {
	transporter?: TransporterPrices[] | null;
	bestTransporter?: TransporterPrices | null;
};
