import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import TransportMaintInformation from './components/TransportMaintInformation';
import TotalTransport from './components/TotalTransport';
import { IsShowSellingPriceSelectedProps } from '../definitions';
import TransportGroupType from './components/TransportType';

interface TransportProps extends IsShowSellingPriceSelectedProps {
	isPLV?: boolean;
	isClariPrint?: boolean;
	isConsultAndEditSellingPrice: boolean;
}

export const Transport = ({
	isPLV,
	isConsultSellingPrice,
	isConsultAndEditSellingPrice,
}: TransportProps): JSX.Element | null => {
	const [selectedTransportType, setSelectedTransportType] = useState('');

	return (
		<Box my={6} mx={20}>
			<Typography variant="h2">Transport</Typography>
			<Box px={21}>
				<TransportMaintInformation isConsultSellingPrice={isConsultSellingPrice} />
				<TransportGroupType
					isPLV={isPLV}
					selectedTransportType={selectedTransportType}
					setSelectedTransportType={setSelectedTransportType}
					isConsultSellingPrice={isConsultSellingPrice}
					isConsultAndEditSellingPrice={isConsultAndEditSellingPrice}
				/>
				<TotalTransport
					isPLV={isPLV}
					selectedTransportType={selectedTransportType}
					isConsultSellingPrice={isConsultSellingPrice}
				/>
			</Box>
		</Box>
	);
};
