import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import colors from 'app/theme/colors.scss';
import DashedHeader from 'components/DashedHeader';
import { FormikNumberField } from 'components/form/FormikNumberField';
import { LayoutField } from 'components/form/LayoutField';
import { FormikSelectAdditionalMarginField } from '../../product-selling-price/components/AdditionalMargins';
import useTotalTransport from '../hooks/useTotalTransport';
import { useField } from 'formik';
import { getPriceWithRfaAndMargin } from 'clientPrice/form/helpers';
import { IsShowSellingPriceSelectedProps } from '../../definitions';
import { MarginType } from 'graphql/types';

interface TotalTransportProps extends IsShowSellingPriceSelectedProps {
	isPLV?: boolean;
	selectedTransportType: string;
}
const TotalTransport = ({ isPLV, selectedTransportType, isConsultSellingPrice }: TotalTransportProps) => {
	const {
		transportPurchasePriceHt,
		transportPurchasePriceHtWithRfaValue,
		transportSellingPriceByDefaultHt,
		transportSellingPriceHt,
		transportRfa,
	} = useTotalTransport(selectedTransportType, isPLV);
	const [{ value: mainRfaRate }] = useField<number>('mainRfaRate');
	const [{ value: isWithRfaClient }] = useField<boolean>('isWithRfaClient');

	return (
		<Box mt={7}>
			<DashedHeader>Total de transport</DashedHeader>
			<Box mt={3} display="flex" justifyContent="space-between">
				<Box>
					<Box mb={3}>
						<LayoutField label="PA transport HT (€)">
							<FormikNumberField
								testid="transport-purchase-price-ht"
								bgColor={colors.grey}
								decimalScale={2}
								value={transportPurchasePriceHt}
								name="transportPurchasePriceHt"
								width="164px"
								variant="outlined"
								disabled
							/>
						</LayoutField>
					</Box>
					<Box mb={3}>
						<LayoutField label="%RFA">
							<FormikNumberField
								testid="transport-rfa"
								bgColor={colors.grey}
								decimalScale={2}
								name="transportRfa"
								width="164px"
								variant="outlined"
								disabled
							/>
							<Typography variant="h5">Montant RFA : {transportRfa}€</Typography>
						</LayoutField>
					</Box>
					<Box mb={3}>
						<LayoutField label="PA Transport HT avec RFA (€)">
							<FormikNumberField
								testid="transport-purchase-price-ht-with-rfa"
								bgColor={colors.grey}
								decimalScale={2}
								value={transportPurchasePriceHtWithRfaValue}
								name="transportPurchasePriceHtWithRfa"
								width="164px"
								variant="outlined"
								disabled
							/>
						</LayoutField>
					</Box>
				</Box>
				<Divider orientation="vertical" flexItem />
				<Box>
					<Box mb={3}>
						<LayoutField label="PV par défaut HT (€)">
							<FormikNumberField
								testid="transport-sellingPrice-by-default-ht"
								bgColor={colors.grey}
								decimalScale={2}
								value={transportSellingPriceByDefaultHt}
								name="transportSellingPriceByDefaultHt"
								width="164px"
								variant="outlined"
								disabled
							/>
						</LayoutField>
					</Box>

					<Box mb={3}>
						<LayoutField label="Marge supplémentaire">
							<FormikSelectAdditionalMarginField
								testid="transport-selling-price-ht-additional-margin"
								disabled={isConsultSellingPrice}
								name="transportSellingPriceHtAdditionalMargin"
							/>
						</LayoutField>
					</Box>
					<Box mb={3}>
						<LayoutField label="Marge">
							<FormikNumberField
								testid="transport-selling-price-margin"
								decimalScale={2}
								name="transportSellingPriceMargin"
								width="164px"
								variant="outlined"
								disabled={isConsultSellingPrice}
							/>
						</LayoutField>
					</Box>
				</Box>
				<Divider orientation="vertical" flexItem />
				<Box>
					<Box mb={3}>
						<LayoutField label="PV transport HT (€)">
							<FormikNumberField
								testid="transport-selling-price-ht"
								bgColor={colors.grey}
								decimalScale={2}
								value={transportSellingPriceHt}
								name="transportSellingPriceHt"
								width="164px"
								variant="outlined"
								disabled
							/>
						</LayoutField>
					</Box>
					<Box mb={3}>
						<LayoutField label="PV transport HT avec RFA Client (€)">
							<FormikNumberField
								testid="transport-selling-price-ht-with-client-rfa"
								bgColor={colors.grey}
								decimalScale={2}
								value={getPriceWithRfaAndMargin(
									MarginType.Percent,
									transportSellingPriceHt,
									mainRfaRate,
									isWithRfaClient
								)}
								name="transportSellingPriceHtWithClientRfa"
								width="164px"
								variant="outlined"
								disabled
							/>
						</LayoutField>
					</Box>
					<Box mb={3}>
						<LayoutField label="PV transport HT rectifié (€)">
							<FormikNumberField
								testid="transport-selling-price-ht-with-client-rfa-rectified"
								decimalScale={2}
								name="transportSellingPriceHtWithClientRfaRectified"
								width="164px"
								variant="outlined"
								disabled={isConsultSellingPrice}
							/>
						</LayoutField>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default TotalTransport;
