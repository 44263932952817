import React from 'react';
import Box from '@mui/material/Box';
import EventIcon from '@mui/icons-material/Event';
import colors from '../../app/theme/colors.scss';
import { LayoutField } from './LayoutField';
import FormikTextField from './FormikTextField';

interface DateLayoutFieldProps {
	label: string;
	name: string;
	disabled?: boolean;
	width?: string;
}

const DateLayoutField = ({ label, disabled, name, width }: DateLayoutFieldProps): JSX.Element => (
	<LayoutField label={label}>
		<Box width={width} mb={3} bgcolor={disabled ? colors.grey : ''}>
			<FormikTextField
				disabled={disabled ?? false}
				fullWidth
				name={name}
				variant="outlined"
				inputProps={{
					autoComplete: 'on',
				}}
				InputProps={{
					endAdornment: <EventIcon />,
				}}
			/>
		</Box>
	</LayoutField>
);

export default DateLayoutField;
