import { useQuery } from '@apollo/client';
import { GetRoleByIdQueryVariables } from '../../role/graphql/queries.definitions';

import { GET_USERS } from '../graphql/queries';
import { GetUsersQuery } from '../graphql/queries.definitions';

const useUsersQuery = () => {
	const { data } = useQuery<GetUsersQuery, GetRoleByIdQueryVariables>(GET_USERS);

	return { users: data?.users };
};

export default useUsersQuery;
