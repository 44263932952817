import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import ProductStore from './ProductStore';
import { useTableStyles } from '../sharedStyles';
import OrderTableHead from '../components/OrderTableHead';
import useCollapse from 'hooks/useCollapse';
import { Box, Collapse } from '@mui/material';
import colors from 'app/theme/colors.scss';
import {
	ChildOrderFormModel,
	ChildOrderItemsFormModel,
	OrderItemFormModel,
	STORES_PRODUCT_DETAILS_TABLE_HEAD_COLUMNS,
} from '../definition';

interface ProductStoresDetailsProps {
	orderItem?: ChildOrderItemsFormModel[] | null;
	orderChildIndex: number;
	parentOrderItem?: OrderItemFormModel[] | null;
	childrenOrder?: ChildOrderFormModel[] | null;
}

const ProductStoresDetails = ({
	orderItem,
	orderChildIndex,
	parentOrderItem,
	childrenOrder,
}: ProductStoresDetailsProps): JSX.Element => {
	const classes = useTableStyles({ isColorBlue: true });
	const { renderCollapseComponent, displayContent } = useCollapse(false);
	const productsStoreFormikName = childrenOrder
		? childrenOrder[orderChildIndex].orderItem?.map(
				(_, index) => `childrenOrder[${orderChildIndex}].orderItem[${index}`
		  )
		: [];

	const fullShippingAddress =
		orderItem && orderItem[0]?.shippingAddress
			? `${orderItem[0]?.shippingAddress.name ?? ''} -
		 ${orderItem[0]?.shippingAddress.firstAddress ?? ''} -
		  ${orderItem[0]?.shippingAddress.zipCode ?? ''} -
		  ${orderItem[0]?.shippingAddress.city ?? ''}`
			: '';

	return (
		<>
			<Box display="flex" ml={10}>
				<Box p={1} flexGrow={1} bgcolor={colors.white} borderLeft={'4px solid'} borderColor={colors.indigo700}>
					{fullShippingAddress}
				</Box>
				<Box ml={0.5} p={1} bgcolor={colors.white}>
					{renderCollapseComponent(true)}
				</Box>
			</Box>
			<Box ml={15} pb={displayContent ? 3 : ''}>
				<TableContainer component={Paper} className={classes.tableContainer}>
					<Collapse in={displayContent}>
						<Table className={classes.table}>
							<OrderTableHead columns={STORES_PRODUCT_DETAILS_TABLE_HEAD_COLUMNS} isColorBlue />
							<TableBody>
								{orderItem?.map((orderItemProduct, index: number) => {
									const indexOrderItem = childrenOrder
										? childrenOrder[orderChildIndex]?.orderItem?.findIndex((item) => item.id === orderItemProduct.id)
										: index;
									return (
										<ProductStore
											parentOrderItem={parentOrderItem}
											key={index}
											orderItem={orderItemProduct}
											orderChildIndex={`childrenOrder[${orderChildIndex}].orderItem[${indexOrderItem}]`}
											productsStoreFormikName={productsStoreFormikName}
										/>
									);
								})}
							</TableBody>
						</Table>
					</Collapse>
				</TableContainer>
			</Box>
		</>
	);
};

export default ProductStoresDetails;
