import { Box, TextField } from '@mui/material';
import colors from 'app/theme/colors.scss';
import React from 'react';
import { LayoutField } from './LayoutField';

interface DisplayInTextFieldProps {
	label: string;
	value: string;
}

const DisplayTextInField = ({ label, value }: DisplayInTextFieldProps): JSX.Element => {
	return (
		<LayoutField label={label}>
			<Box bgcolor={colors.grey} mb={3}>
				<TextField value={value} disabled variant="outlined" fullWidth />
			</Box>
		</LayoutField>
	);
};

export default DisplayTextInField;
