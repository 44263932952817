import { useLazyQuery } from '@apollo/client';
import { useField } from 'formik';
import { GET_CLIENT_PRICE_WITH_CUSTOM_QUANTITY } from '../graphql/queries';
import {
	GetClientPriceWithCustomQuantityQuery,
	GetClientPriceWithCustomQuantityQueryVariables,
} from '../graphql/queries.definitions';
import { initialAddClientPriceToQuotes } from '../../client-quote/form/adding-client-price-quote/components/definitions';

export const useClientPriceWithCustomQuantity = (name = 'summaryPrice') => {
	const [{ value }, , helpers] = useField(name);

	const [getClientPrice, { loading, error }] = useLazyQuery<
		GetClientPriceWithCustomQuantityQuery,
		GetClientPriceWithCustomQuantityQueryVariables
	>(GET_CLIENT_PRICE_WITH_CUSTOM_QUANTITY, {
		onCompleted: (data) => {
			helpers.setValue({
				...data.clientPrice,
				productSalesPriceExcludingVAT: data?.clientPrice?.productSalesPriceExcludingVAT,
				versionNumber: data.clientPrice.numberVersion,
				sellingPriceMargin: data?.clientPrice.sellingPriceMargin,
			});
		},
		onError: (error) => {
			helpers.setValue({ ...initialAddClientPriceToQuotes, ...value });
		},
		fetchPolicy: 'network-only',
	});

	return { getClientPrice, loadingGetClientPrice: loading, errorGetClientPrice: error };
};
