import React from 'react';
import { LineTabs, LineTab } from './LineTabs';

export interface Tab {
	title: string;
	hide?: boolean;
}

interface ManualTabProps<T extends Tab> {
	currentTab?: T;
	tabs: T[];
	onChange: (tab: T) => void;
}

function ManualTabs<T extends Tab>({ currentTab, tabs, onChange }: ManualTabProps<T>): JSX.Element {
	const filteredTabs = tabs.filter(({ hide }) => !hide);

	const handleChange = (event: React.ChangeEvent<{}>, value: T) => {
		onChange(value);
	};

	return (
		<LineTabs value={currentTab} onChange={handleChange}>
			{filteredTabs.map((tab) => (
				<LineTab key={tab.title} label={tab.title} value={tab} />
			))}
		</LineTabs>
	);
}

export default ManualTabs;
