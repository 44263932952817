import React from 'react';
import { AutocompleteValue, Box, FormControlLabel, FormLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ProductFilter } from 'context/pricesProductContext/definition';
import { IOSSwitch } from '../form/FormikSwitchField';
import {
	CONDITIONENEMENT,
	ConditionnementFamilyType,
	ConditionnementType,
	LABELIING,
	NB_EX_CONDITIONENEMENT,
} from './definition';
import { AttributeValuesType, EMPTY_STRING } from 'admin/support/definitions';
import { AttributeKey } from 'graphql/types';

interface ConditionnemntFieldsProps {
	isClariPrint?: boolean;
	conditionnementName: (string | number | boolean | null | undefined)[];
	nbExConditionnement: (string | number | boolean | null | undefined)[];
	setProductFilter?: (productFilter?: ProductFilter | null | undefined) => void;
	indexConditionnementFamily: number;
	labeling: (string | number | boolean | null | undefined)[];
	productFilter?: ProductFilter | null;
	printValues: (string | boolean | null | undefined)[] | undefined;
	conditionnement: ConditionnementType;
	setConditionnement: React.Dispatch<React.SetStateAction<ConditionnementType>>;
	family: ConditionnementFamilyType;
	index: number;
	productsAttributesByFamily?: { family: string | null | undefined; data: AttributeValuesType[] }[];
	isProductPage?: boolean;
}

export const ConditionnemntFields = ({
	isClariPrint,
	productsAttributesByFamily,
	family,
	conditionnementName,
	nbExConditionnement,
	indexConditionnementFamily,
	index,
	labeling,
	productFilter,
	setProductFilter,
	printValues,
	conditionnement,
	setConditionnement,
	isProductPage,
}: ConditionnemntFieldsProps): JSX.Element => {
	const onChangeLabelingPrint = (index: number, family: ConditionnementFamilyType) => {
		let newConditionnement = { ...conditionnement };
		newConditionnement.conditionnementList[index][family.key].labeling = !newConditionnement.conditionnementList[index][
			family.key
		].labeling;
		setConditionnement(newConditionnement);
		if (setProductFilter) {
			setProductFilter({ ...productFilter, conditionnement: { ...conditionnement } });
		}
	};

	const onChangeNbExConditionnementPrint = (event: number | null, index: number, family: ConditionnementFamilyType) => {
		let newConditionnement = { ...conditionnement };
		newConditionnement.conditionnementList[index][family.key].nbExConditionnement = event;
		setConditionnement(newConditionnement);
		if (setProductFilter) {
			setProductFilter({ ...productFilter, conditionnement: { ...newConditionnement } });
		}
	};

	const handleFilterChange = (
		key: number,
		tag: string,
		value: AutocompleteValue<unknown, undefined, undefined, undefined>,
		index: number,
		family?: ConditionnementFamilyType
	) => {
		if (isClariPrint && family) {
			const printValueName = productsAttributesByFamily
				?.find(({ family }) => family === 'conditionnement')
				?.data[0].data.find((attribute) => attribute.value === value);
			const valueToSet = value === EMPTY_STRING ? null : (value as string);
			let newConditionnement = { ...conditionnement };
			newConditionnement.conditionnementList[index][family.key].conditionnement = {
				name: printValueName?.printName || valueToSet,
				value: valueToSet,
			};
			setConditionnement(newConditionnement);
			if (setProductFilter) {
				setProductFilter({ ...productFilter, conditionnement: { ...newConditionnement } });
			}
		}

		if (setProductFilter && !isClariPrint) {
			if (value) {
				const valueToSet = value === EMPTY_STRING ? null : (value as string | number | boolean);
				const newFilterValue = productFilter && {
					['conditionnement' + key]: {
						...productFilter[('conditionnement' + key) as keyof ProductFilter],
						[tag]: tag === LABELIING ? !(value === 'Oui') : valueToSet,
					},
				};

				setProductFilter(newFilterValue);
			} else if (value === null) {
				setProductFilter({
					...productFilter,
					...(() =>
						productFilter && {
							['conditionnement' + key]: {
								...productFilter[('conditionnement' + key) as keyof ProductFilter],
								[tag]: undefined,
							},
						})(),
				});
			}
		}
	};

	const getValue = (
		index: number,
		family: ConditionnementFamilyType,
		field: 'conditionnement' | 'nbExConditionnement' | 'labeling'
	) => {
		if (isClariPrint) {
			const printValueName = productsAttributesByFamily
				?.find(({ family }) => family === 'conditionnement')
				?.data[0]?.data?.find((attribute) =>
					field === AttributeKey.Conditionnement
						? attribute?.value === conditionnement?.conditionnementList[index][family.key][field]?.value
						: attribute?.value === conditionnement?.conditionnementList[index][family.key][field]
				);

			return printValueName?.value || '';
		}
		switch (field) {
			case NB_EX_CONDITIONENEMENT:
				return nbExConditionnement.length === 1 ? nbExConditionnement[0] : null;
			case LABELIING:
				return labeling.length === 1 ? labeling[0] : null;
			default:
				return conditionnementName.length === 1 ? conditionnementName[0] : null;
		}
	};

	return (
		<>
			<Box key={indexConditionnementFamily} display="flex" width="100%" pt={2}>
				<Box width="35%" pr={1}>
					<FormLabel>Conditionnement N°{indexConditionnementFamily}</FormLabel>
					<Autocomplete
						data-testid={`${family.key}-conditionnement-${index}`}
						size="small"
						popupIcon={<KeyboardArrowDownIcon />}
						forcePopupIcon
						freeSolo
						clearOnBlur
						getOptionLabel={(option) => option?.toString() ?? ''}
						onInputChange={(_, newValue) => {
							if (!newValue) {
								handleFilterChange(indexConditionnementFamily, CONDITIONENEMENT, null, index);
							}
						}}
						value={
							getValue(index, family, CONDITIONENEMENT) === EMPTY_STRING
								? ' '
								: getValue(index, family, CONDITIONENEMENT)
						}
						onChange={(_, newValue) =>
							handleFilterChange(indexConditionnementFamily, CONDITIONENEMENT, newValue, index, family)
						}
						options={isClariPrint ? printValues ?? [] : conditionnementName ?? []}
						renderInput={(params) => (
							<TextField
								placeholder={!isClariPrint ? (isProductPage ? 'Indifférent' : 'A compléter') : ''}
								{...params}
								variant="outlined"
							/>
						)}
					/>
				</Box>
				<Box width="45%" pr={1}>
					<FormLabel>
						Nb {indexConditionnementFamily !== 1 ? 'paquet' : "d'ex"} Conditionnement N°
						{indexConditionnementFamily === 1 ? indexConditionnementFamily : indexConditionnementFamily - 1}
					</FormLabel>
					{!isClariPrint ? (
						<Autocomplete
							data-testid={`${family.key}-nb-ex-conditionnement-${index}`}
							size="small"
							popupIcon={<KeyboardArrowDownIcon />}
							forcePopupIcon={isClariPrint ? nbExConditionnement[0] !== null : true}
							freeSolo
							clearOnBlur={isClariPrint ? nbExConditionnement[0] !== EMPTY_STRING : true}
							getOptionLabel={(option) => option?.toString() ?? ''}
							onInputChange={(_, newValue) => {
								if (!newValue) {
									handleFilterChange(indexConditionnementFamily, NB_EX_CONDITIONENEMENT, newValue, index);
								}
							}}
							value={
								getValue(index, family, NB_EX_CONDITIONENEMENT) === EMPTY_STRING
									? ' '
									: getValue(index, family, NB_EX_CONDITIONENEMENT)
							}
							onChange={(_, newValue) =>
								handleFilterChange(indexConditionnementFamily, NB_EX_CONDITIONENEMENT, newValue, index)
							}
							options={nbExConditionnement}
							renderInput={(params) => (
								<TextField
									placeholder={!isClariPrint ? (isProductPage ? 'Indifférent' : 'A compléter') : ''}
									{...params}
									variant="outlined"
								/>
							)}
						/>
					) : (
						<TextField
							data-testid={`${family.key}-nb-ex-conditionnement-${index}-clari-print`}
							type="number"
							inputProps={{ min: 1 }}
							fullWidth
							variant="outlined"
							placeholder="Au mieux"
							value={String(conditionnement.conditionnementList[index][family.key].nbExConditionnement)}
							onChange={(e) =>
								onChangeNbExConditionnementPrint(
									!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value as string) : null,
									index,
									family
								)
							}
						/>
					)}
				</Box>
				<Box width="20%" pr={1}>
					<FormLabel>Etiquetage</FormLabel>
					{!isClariPrint ? (
						<Autocomplete
							data-testid={`${family.key}-Labeling-${index}`}
							size="small"
							popupIcon={<KeyboardArrowDownIcon />}
							forcePopupIcon={isClariPrint ? labeling[0] !== EMPTY_STRING : true}
							freeSolo
							clearOnBlur
							onInputChange={(_, newValue) => {
								if (!newValue) {
									handleFilterChange(indexConditionnementFamily, LABELIING, newValue, index);
								}
							}}
							getOptionLabel={(option) => option?.toString() ?? ''}
							value={getValue(index, family, LABELIING) === EMPTY_STRING ? ' ' : getValue(index, family, LABELIING)}
							onChange={(_, newValue) => handleFilterChange(indexConditionnementFamily, LABELIING, newValue, index)}
							options={labeling}
							renderInput={(params) => (
								<TextField
									placeholder={!isClariPrint ? (isProductPage ? 'Indifférent' : 'A compléter') : ''}
									{...params}
									variant="outlined"
								/>
							)}
						/>
					) : (
						<FormControlLabel
							control={
								<IOSSwitch
									data-testid={`${family.key}-Labeling-${index}-clari-print`}
									checked={conditionnement.conditionnementList[index][family.key].labeling ?? false}
									onChange={() => {
										onChangeLabelingPrint(index, family);
									}}
								/>
							}
							label={conditionnement.conditionnementList[index][family.key].labeling ? 'Oui' : 'Non'}
						/>
					)}
				</Box>
			</Box>
		</>
	);
};
