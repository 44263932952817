import { Typography } from '@mui/material';
import { path as pathRamda } from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { CellProps, Column, useTable } from 'react-table';
import { ClientShippingAddress, ClientWithShippingAdresses, DistributionsGroupedByAddresses } from 'utils/distribution';
import { formatNumberDisplay } from 'utils/prices';

import EditableCellQuantity from '../EditableCellQuantity';
import EditableSelectClientAndAllChildrens from '../EditableSelectClientAndAllChildrens';
import EditableSelectShippingAddress from '../EditableSelectShippingAddress';
const _cloneDeep = require('lodash.clonedeep');
const _set = require('lodash.set');

const useQuoteDistributionTable = (
	dataForDistributionTable: DistributionsGroupedByAddresses[],
	clientAndAllChildrensWithShippingAdresses: ClientWithShippingAdresses[] | undefined,
	updateDistributionTable: (data: DistributionsGroupedByAddresses[]) => void
) => {
	const products = useMemo(() => {
		return dataForDistributionTable?.[0]?.productIdsQuantities || [];
	}, [dataForDistributionTable]);

	const clientsName = useMemo(() => {
		return (
			clientAndAllChildrensWithShippingAdresses?.map((item: ClientWithShippingAdresses) => {
				return { id: item.id, name: item.name };
			}) || []
		);
	}, [clientAndAllChildrensWithShippingAdresses]);

	const clientShippingAddresses = useMemo(() => {
		const clientShippingAddresses: { [key: string]: ClientShippingAddress[] } = {};
		if (!clientAndAllChildrensWithShippingAdresses) {
			return clientShippingAddresses;
		}
		for (const item of clientAndAllChildrensWithShippingAdresses) {
			clientShippingAddresses[item.id] = item.clientShippingAddress;
		}
		return clientShippingAddresses;
	}, [clientAndAllChildrensWithShippingAdresses]);

	const getTotals = useCallback(
		() =>
			dataForDistributionTable
				.flatMap((row) =>
					row.productIdsQuantities.map((productIdsQuantity, index) => ({ index, ...productIdsQuantity }))
				)
				.reduce((totals: number[], productQuantity) => {
					if (!totals) {
						totals = [];
					}
					const index = productQuantity.index;
					if (!totals[index]) {
						totals[index] = 0;
					}
					totals[index] += Number(productQuantity.quantity);
					return totals;
				}, []),
		[dataForDistributionTable]
	);

	const [totals, setTotals] = useState<number[]>([]);

	useEffect(() => {
		setTotals(getTotals());
	}, [dataForDistributionTable.length, getTotals]);

	const updateClientDistribution = useCallback(
		async (index: number, id: string, value: string) => {
			_set(dataForDistributionTable[index], id, value);
			updateDistributionTable(_cloneDeep(dataForDistributionTable));
		},
		[dataForDistributionTable, updateDistributionTable]
	);

	const updateProductDistribution = useCallback(
		(index: number, id: string | undefined, value: string | number) => {
			_set(dataForDistributionTable[index], id, value);
			setTotals(getTotals());
		},
		[dataForDistributionTable, setTotals, getTotals]
	);

	const getAdressInformations = useCallback(
		(clientId: string, addressId: string, path: string[]): JSX.Element => {
			if (!clientId || !addressId) {
				return <Typography>{'-'}</Typography>;
			}

			const address =
				clientShippingAddresses[clientId]?.find((item: ClientShippingAddress) => item.id === addressId) || '';
			return <Typography>{pathRamda(path, address)}</Typography>;
		},
		[clientShippingAddresses]
	);

	const columns = useMemo<Column[]>(
		() => [
			{
				Header: 'Magasin',
				accessor: 'clientId',
				Cell: ({ row, column, value }) => {
					return (
						<EditableSelectClientAndAllChildrens
							row={row}
							column={column}
							value={value ? { id: value } : null}
							updateMyData={updateClientDistribution}
							clientsName={clientsName}
						/>
					);
				},
			},
			{
				Header: 'Adresse de livraison',
				accessor: 'clientShippingAddressId',
				Cell: ({ row, column, value }) => {
					return (
						<EditableSelectShippingAddress
							row={row}
							column={column}
							value={value ? { id: value } : null}
							updateMyData={updateClientDistribution}
							clientShippingAddresses={clientShippingAddresses}
							selectedClientId={row.values.clientId}
						/>
					);
				},
			},
			{
				Header: 'N. de magasin',
				accessor: 'storeCode',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, ['contact', 'storeCode']) ||
					'',
			},
			{
				Header: 'Contact',
				accessor: 'contact',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, [
						'contact',
						'directorName',
					]) || '',
			},
			{
				Header: 'Téléphone',
				accessor: 'phone',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, ['contact', 'phone']) || '',
			},
			{
				Header: 'Adresse 1',
				accessor: 'adress1',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, [
						'address',
						'firstAddress',
					]) || '',
			},
			{
				Header: 'Adresse 2',
				accessor: 'adress2',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, [
						'address',
						'secondAddress',
					]) || '',
			},
			{
				Header: 'Adresse 3',
				accessor: 'adress3',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, [
						'address',
						'thirdAddress',
					]) || '',
			},
			{
				Header: 'Code postal',
				accessor: 'zipCode',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, ['address', 'zipCode']) ||
					'',
			},
			{
				Header: 'Ville',
				accessor: 'city',
				Cell: ({ row }) =>
					getAdressInformations(row.values?.clientId, row.values?.clientShippingAddressId, ['address', 'city']) || '',
			},
			...products.map((product, index) => {
				return {
					Header: `${product.productName} (${product.globalQuantity})`,
					accessor: `productIdsQuantities[${index}].quantity`,
					width: 1150,
					Cell: ({ row, column, value }: CellProps<{}>): JSX.Element => {
						return (
							<EditableCellQuantity row={row} column={column} value={value} updateMyData={updateProductDistribution} />
						);
					},
					Footer: () => {
						return (
							<>
								{index === 0 && (
									<Typography variant="h3" sx={{ position: 'absolute', marginLeft: '-80px' }}>
										TOTAL
									</Typography>
								)}

								<Typography variant="h3" sx={{ paddingLeft: '10px' }}>
									{totals && totals[index] ? formatNumberDisplay(totals[index]) : 0}
								</Typography>
							</>
						);
					},
				};
			}),
		],
		[
			products,
			totals,
			clientsName,
			updateClientDistribution,
			updateProductDistribution,
			clientShippingAddresses,
			getAdressInformations,
		]
	);

	return useTable({
		columns,
		data: dataForDistributionTable,
	});
};

export default useQuoteDistributionTable;
