import { gql } from '@apollo/client';
import ATTRIBUT_FRAGMENT from '../../../graphql/fragments/attribute/attributeFragment';

export const CHARACTERISTICS_BY_FAMILY = gql`
	query PrintValuesCharacteristicsByFamily($family: String!) {
		characteristics: printValuesCharacteristicsByFamily(family: $family) {
			...Attribute
		}
	}
	${ATTRIBUT_FRAGMENT}
`;

export const PRINT_VALUES = gql`
	query PrintValues {
		printValues {
			printValue
			attributeId
			printName
			attribute {
				...Attribute
			}
		}
	}
	${ATTRIBUT_FRAGMENT}
`;
