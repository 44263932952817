import React, { memo, useEffect } from 'react';
import { LayoutField } from '../form/LayoutField';
import useDepartmentQuery from '../../clients/hooks/useDepartmentQuery';
import FormikAutocompleteField from '../form/FormikAutocompleteField';
import { FormControl } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEmpty } from 'ramda';
import usePrevious from 'hooks/usePrevious';
const _get = require('lodash.get');
interface DepartmentsProps {
	title: string;
	name: string;
	disabledDepartments?: boolean;
	zipCode?: string;
}

const Departments = ({ title, name, disabledDepartments, zipCode = '' }: DepartmentsProps): JSX.Element => {
	// Use type any because different formik context use this component
	const { values, setFieldValue } = useFormikContext<any>();

	const zipCodeValue = _get(values, zipCode) as string;
	const departementValue = _get(values, name) as string;

	// We use usePrevious to avoid infinite loop and apparently zipCode and department are not always correlated
	const previousZipCode = usePrevious(zipCodeValue);

	const { departments, loading } = useDepartmentQuery();
	const optionsDepartments = React.useMemo(() => departments?.map(({ code, name }) => `${code} - ${name}`), [
		departments,
	]);

	useEffect(() => {
		if (!isEmpty(zipCodeValue)) {
			const selectedDepartement = departments?.find((dept) => dept?.code === zipCodeValue?.substr(0, 2));

			if (!selectedDepartement) {
				return;
			}

			const selectedDepartmentValue = `${selectedDepartement.code} - ${selectedDepartement.name}`;
			if (selectedDepartmentValue !== previousZipCode) {
				setFieldValue(name, selectedDepartmentValue);
			}
		}
	}, [zipCodeValue, departments, previousZipCode, name, setFieldValue]);

	return (
		<LayoutField label={title}>
			<FormControl fullWidth>
				<FormikAutocompleteField<string>
					options={optionsDepartments ?? []}
					name={name}
					loading={loading}
					value={departementValue ?? null}
					disabled={loading || (!loading && isEmpty(optionsDepartments)) || disabledDepartments}
					onChange={(_, value) => setFieldValue(name, value)}
					autoHighlight
					testid="departments-autocomplete"
				/>
			</FormControl>
		</LayoutField>
	);
};

export default memo(Departments);
