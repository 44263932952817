import { useQuery } from '@apollo/client';
import { GET_CLIENT_QUOTE_BY_ID } from '../graphql/queries';
import { GetClientQuoteByIdQuery, GetClientQuoteByIdQueryVariables } from '../graphql/queries.definitions';

const useClientQuoteQuery = (id: string) => {
	const { data, loading, refetch } = useQuery<GetClientQuoteByIdQuery, GetClientQuoteByIdQueryVariables>(
		GET_CLIENT_QUOTE_BY_ID,
		{
			variables: { id },
			skip: !id,
		}
	);
	return { clientQuote: data?.clientQuote, loading, refetchClientQuote: refetch };
};

export default useClientQuoteQuery;
