import { Box, Grid, Typography, Button } from '@mui/material';
import { ArrowBackSharp, ArrowDownwardSharp, ArrowForwardSharp, ArrowUpwardSharp } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { Row } from 'react-table';
import { useField } from 'formik';
import { PrimaryIconButton } from '../../../components/buttons/PrimaryIconButton';
import DashedHeader from '../../../components/DashedHeader';
import FormikTags from '../../../components/FormikTags';
import AppListTable from '../../../components/tables/list-table/AppListTable';
import useClientStructuresField from './fields/useClientStructuresField';
import useClientStructuresTable from './hooks/useClientStructuresTable';
import useEntityTypesTable from './hooks/useEntityTypesTable';
import { Client, ClientStructure, EntityType } from '../../../graphql/types';
import FormikFilesField from '../../../components/form/FormikFilesField';
import usePopin from '../../../hooks/usePopin';
import ClientOrganigram from '../../clientOrganigram/ClientOrganigram';
import EntityDetail from './EntityDetail';
import colors from 'app/theme/colors.scss';
interface ClientStructuresFormProps {
	client: Client;
}

export const ACCEPTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];

const ClientStructuresForm = ({ client }: ClientStructuresFormProps): JSX.Element | null => {
	const { id, name, isActif, fileCode } = client;
	const { structures, addStructure, removeStructure, swapStructures } = useClientStructuresField();
	const [field] = useField<string | undefined>('logo');
	const [openClientOrganigramPopin, renderClientOrganigramPopin] = usePopin({
		headerTitle: 'Détails de la structure actuellement en place',
		buttonLabel: 'Imprimer',
		maxWidth: 'xl',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const [openEntityDetailPopin, renderEntityDetailPopin] = usePopin({
		headerTitle: 'Détails des entités actuellement en place',
		buttonLabel: 'Imprimer',
		maxWidth: 'xl',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const entityTypes = useMemo(
		() =>
			Object.values(EntityType).filter(
				(type) => structures.findIndex((clientStructure) => clientStructure.type === type) === -1
			),
		[structures]
	);

	const clientType = useMemo(() => structures.find((clientStructure) => clientStructure.type === client.type)?.title, [
		structures,
		client.type,
	]);

	const entityTypesTable = useEntityTypesTable(entityTypes);

	const clientStructuresTable = useClientStructuresTable(structures);

	const handleEntityTypeClick = ({ toggleRowSelected }: Row<{ type: EntityType }>) => {
		entityTypesTable.toggleAllRowsSelected(false);
		toggleRowSelected(true);
	};

	const handleClientStructureClick = ({ toggleRowSelected }: Row<ClientStructure>) => {
		clientStructuresTable.toggleAllRowsSelected(false);
		toggleRowSelected(true);
	};

	const handleRightClick = () => {
		const selectedEntityType = entityTypesTable.selectedFlatRows[0]?.original?.type;
		if (!selectedEntityType) return;
		addStructure(selectedEntityType);
	};

	const handleLeftClick = () => {
		const selectedIndex = clientStructuresTable.selectedFlatRows[0]?.index;
		if (selectedIndex !== undefined) {
			removeStructure(selectedIndex);
			clientStructuresTable.toggleAllRowsSelected(false);
		}
	};

	const handleUpClick = () => {
		const selectedIndex = clientStructuresTable.selectedFlatRows[0]?.index;
		if (selectedIndex !== undefined && selectedIndex > 0) {
			swapStructures(selectedIndex, selectedIndex - 1);
		}
	};

	const handleDownClick = () => {
		const index = clientStructuresTable.selectedFlatRows[0]?.index;
		if (index !== undefined && index < structures.length - 1) {
			swapStructures(index, index + 1);
		}
	};

	if (!client.regions || !client.typologies) return null;

	return (
		<Box my={3}>
			<Typography variant="h2">Structure de l’organisation</Typography>
			<Box mt={3} mx={15}>
				<>
					<DashedHeader>Organisation des entités</DashedHeader>
					<Box mb={2} display="flex" flexDirection="row-reverse">
						<Button onClick={openClientOrganigramPopin} variant="outlined" color="primary" size="small">
							Détail structure
						</Button>
						<Button onClick={openEntityDetailPopin} variant="outlined" color="primary" size="small">
							Détail entité
						</Button>
					</Box>
					{renderClientOrganigramPopin(
						<ClientOrganigram id={id} name={name} isActif={isActif} type={clientType} fileCode={fileCode} />
					)}
					{renderEntityDetailPopin(<EntityDetail structures={structures} id={id} />)}
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={4}>
							<AppListTable
								tableInstance={entityTypesTable}
								onRowClick={handleEntityTypeClick}
								withBorder={false}
								withOverflowScroll={false}
								tableStyle={{ border: `1px solid ${colors.chipBackgroundLight}` }}
							/>
						</Grid>
						<Grid item xs={1}>
							<Box display="flex" height="100%" flexDirection="column" alignItems="center" justifyContent="center">
								<Box m={2}>
									<PrimaryIconButton onClick={handleRightClick}>
										<ArrowForwardSharp fontSize="large" />
									</PrimaryIconButton>
								</Box>
								<Box m={2}>
									<PrimaryIconButton onClick={handleLeftClick}>
										<ArrowBackSharp fontSize="large" />
									</PrimaryIconButton>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<AppListTable
								tableInstance={clientStructuresTable}
								onRowClick={handleClientStructureClick}
								withBorder={false}
								withOverflowScroll={false}
								tableStyle={{ border: `1px solid ${colors.chipBackgroundLight}` }}
							/>
							<Typography color="textSecondary">
								* Entité non facturable présente uniquement pour information
							</Typography>
						</Grid>
						<Grid item xs={1}>
							<Box display="flex" height="100%" flexDirection="column" alignItems="center" justifyContent="center">
								<Box m={2}>
									<PrimaryIconButton onClick={handleUpClick}>
										<ArrowUpwardSharp fontSize="large" />
									</PrimaryIconButton>
								</Box>
								<Box m={2}>
									<PrimaryIconButton onClick={handleDownClick}>
										<ArrowDownwardSharp fontSize="large" />
									</PrimaryIconButton>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</>
			</Box>
			<Box mt={3} mx={15}>
				<DashedHeader>Détails des entités informatives</DashedHeader>
				<Grid container spacing={2}>
					{structures.find((structure) => structure.type === 'Region') && (
						<Grid item xs={12}>
							<Box>
								<Typography variant="h4">Régions</Typography>
							</Box>
							<Box>
								<FormikTags<Client>
									defaultValues={client.regions.map((value) => value.name)}
									name="regions"
									label="Régions"
								/>
							</Box>
						</Grid>
					)}
					{structures.find((structure) => structure.type === 'Typology') && (
						<Grid item xs={12}>
							<Box>
								<Typography variant="h4">Typologies</Typography>
							</Box>
							<Box>
								<FormikTags<Client>
									defaultValues={client.typologies.map((value) => value.name)}
									name="typologies"
									label="Typologies"
								/>
							</Box>
						</Grid>
					)}
				</Grid>
			</Box>
			<Box mt={3} mx={15}>
				<DashedHeader>Logo</DashedHeader>
				<FormikFilesField name="logoFile" accept={ACCEPTED_FORMATS} imagePlaceholder={field.value ?? null} />
			</Box>
		</Box>
	);
};

export default ClientStructuresForm;
