import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import StoreItem from './StoreItem';
import DashedHeader from 'components/DashedHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Store } from '../../../types';
import { useTableStyles } from '../sharedStyles';
import OrderTableHead from '../components/OrderTableHead';
import useSearch from 'hooks/useSearch';
import { PRODUCT_STORES_DETAILS_TABLE_HEAD_COLUMNS } from '../definition';

interface ProductStoresDetailsProps {
	stores: Store[];
	productIndex: number;
}

const ProductStoresDetails = ({ stores, productIndex }: ProductStoresDetailsProps): JSX.Element => {
	const classes = useTableStyles({ isColorBlue: true });
	const [displayProductStores, setDisplayProductStores] = useState(false);
	const { renderSearchComponent, filtredItems } = useSearch('clientName', stores as Store[]);
	return (
		<>
			<DashedHeader>
				Détails
				<IconButton onClick={() => setDisplayProductStores((precDisplayStore) => !precDisplayStore)} size="large">
					{displayProductStores ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				</IconButton>
			</DashedHeader>
			<Collapse in={displayProductStores}>
				<>
					<Box display="flex" justifyContent="flex-end">
						{renderSearchComponent('Chercher un magasin')}
					</Box>
					<TableContainer component={Paper} className={classes.tableContainer}>
						<Table className={classes.table}>
							<OrderTableHead columns={PRODUCT_STORES_DETAILS_TABLE_HEAD_COLUMNS} isColorBlue />
							<TableBody>
								{filtredItems.map((store: Store, index) => (
									<StoreItem key={`${store.id}${index}`} store={store} stores={stores} productIndex={productIndex} />
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			</Collapse>
		</>
	);
};

export default ProductStoresDetails;
