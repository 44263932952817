import { Box, Button, FormControl, Grid, Typography } from '@mui/material';
import { useField } from 'formik';
import RefreshIcon from '@mui/icons-material/Refresh';
import React from 'react';
import DashedHeader from '../../../components/DashedHeader';
import { FormikCheckboxField } from '../../../components/form/FormikChecboxField';
import FormikGroupCheckboxField from '../../../components/form/FormikGroupCheckBoxField';
import FormikRadioField from '../../../components/form/FormikRadioField';
import { LayoutField } from '../../../components/form/LayoutField';
import { BillingCadence, EntityType } from '../../../graphql/types';
import useClientQuery from '../../hooks/useClientQuery';
import FormikTextField from '../../../components/form/FormikTextField';
import { useStylesClient } from '../../style';
import Address from '../../../components/Address/Address';

interface BillingInformationsFormProps {
	onClickImportClientFromBaseDB: () => void;
	isRoleAdmin: boolean;
}

const BillingInformationsForm = ({ onClickImportClientFromBaseDB, isRoleAdmin }: BillingInformationsFormProps) => {
	const classes = useStylesClient();
	const billingCadenceOptions = [
		{ value: BillingCadence.Monthly, label: 'Mensuelle' },
		{ value: BillingCadence.Weekly, label: 'Hebdomadaire' },
		{ value: BillingCadence.Daily, label: 'Journalière' },
		{ value: BillingCadence.ToOrder, label: 'À la commande' },
	];

	const [{ value: possibleBillingsValue }, , { setValue }] = useField<EntityType[]>('possibleBillings');
	const [, , { setValue: setDefaultBillingValue }] = useField<EntityType>('defaultBilling');
	const [{ value: rootClientId }] = useField<string>('rootClientId');
	const { client: rootClient } = useClientQuery(rootClientId ?? '');

	const handleChange = (value: EntityType) => {
		let values = [...possibleBillingsValue] || [];
		const index = possibleBillingsValue.indexOf(value);
		if (index === -1) {
			values.push(value);
		} else {
			values.splice(index, 1);
		}
		setValue(values);
	};

	return (
		<Box my={3}>
			<Typography variant="h2">Informations de facturation</Typography>
			<Button color="primary" className={classes.refreshBtn} onClick={onClickImportClientFromBaseDB}>
				<RefreshIcon height="24px" />
				<Typography variant="h5" color="primary" style={{ fontWeight: 500 }}>
					Récupérer les informations de la base Elpev
				</Typography>
			</Button>

			<Grid container spacing={10}>
				<Grid item xs={7}>
					<Box mt={6} mx={15}>
						<DashedHeader>Délai de paiement</DashedHeader>
						<Grid container justifyContent="space-between">
							<Grid item xs={12}>
								<LayoutField label="Délai de paiement ">
									<Box mb={3}>
										<FormikTextField name="paymentDeadline" fullWidth variant="outlined" disabled={!isRoleAdmin} />
									</Box>
								</LayoutField>
							</Grid>
						</Grid>

						<Box mt={5}></Box>
						<DashedHeader>Rythme de facturation</DashedHeader>
						<Grid container justifyContent="space-between">
							<Grid item xs={5}>
								<LayoutField label="Rythme de facturation">
									<FormControl fullWidth>
										<FormikRadioField name="billingCadence" options={billingCadenceOptions} />
									</FormControl>
								</LayoutField>
							</Grid>
						</Grid>
						<Box mt={5}>
							<DashedHeader>Facturation possible à</DashedHeader>
						</Box>
						<Grid container>
							<Grid item xs={6}>
								<LayoutField label="Facturation possible à">
									<FormControl fullWidth>
										{rootClient?.structures.map(
											(structure, index) =>
												structure.isBillable && (
													<Box key={index} pb={2.5} display="flex" alignItems="center">
														<FormikGroupCheckboxField
															name="possibleBillings"
															handleClick={handleChange}
															value={structure.type}
														/>
														<Typography variant="h5">{structure.title}</Typography>
													</Box>
												)
										)}
									</FormControl>
								</LayoutField>
							</Grid>
							<Grid item xs={5}>
								<LayoutField label="Par défaut">
									<FormControl fullWidth>
										<FormikRadioField
											name="defaultBilling"
											onChange={(_, type) => {
												if (type in EntityType) {
													setDefaultBillingValue(type as EntityType);
												}
											}}
											options={rootClient?.structures
												.filter((structure) => structure.isBillable)
												.map((structure) => ({ label: structure.title, value: structure.type }))}
										/>
									</FormControl>
								</LayoutField>
							</Grid>
						</Grid>

						<Box mt={5}></Box>
						<DashedHeader>Complément de facturation</DashedHeader>
						<Grid container justifyContent="space-between">
							<Grid item xs={12}>
								<Box display="flex" alignItems="center">
									<FormikCheckboxField name="isGroupedBilling" />
									<Typography variant="h5">Grouper les commandes sur une facture</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box mt={6}>
						<DashedHeader>Adresse de facturation</DashedHeader>
						<Address isDisplayAddressName name="billingAddress" disabled={!isRoleAdmin} />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default BillingInformationsForm;
