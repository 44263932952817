import React, { useMemo } from 'react';
import { CellProps, useRowSelect, useTable } from 'react-table';
import { Box, Typography } from '@mui/material';
import { ChildOrderFormModel, OrderFormModel } from '../form/order-cockpit/definition';
import { getStoresByShippingAddress } from '../helpers';

export const useProductsDistributionTable = (order: OrderFormModel, headerTable: string[]) => {
	const productsQuantity = (orderItem: ChildOrderFormModel['orderItem']) => {
		const productQuantity: Record<string, string | number> = {};
		orderItem?.forEach((item) => {
			productQuantity[item.productName] = item.quantity;
		});
		return productQuantity;
	};

	const data: Record<string, string | number | null | undefined>[] = useMemo(() => {
		const storesByShippingAddress = getStoresByShippingAddress(order.childrenOrder ?? []);

		return storesByShippingAddress.map((childOrder) => {
			const shippingAddress = childOrder?.orderItem?.[0].shippingAddress;

			return {
				Magasin: childOrder.clientName,
				'Num\u00e9ro du magasin': '',
				Contact: '',
				Téléphone: '',
				'Adresse 1': shippingAddress?.firstAddress,
				'Adresse 2': shippingAddress?.secondAddress,
				'Adresse 3': shippingAddress?.thirdAddress,
				'Code postal': shippingAddress?.zipCode,
				Ville: shippingAddress?.city,
				...productsQuantity(childOrder.orderItem),
			};
		});
	}, [order.childrenOrder]);

	const columns = useMemo(
		() =>
			headerTable.map((header) => ({
				accessor: header,
				Header: () => <Typography>{header}</Typography>,
				Cell: (row: CellProps<Record<string, string | number | null | undefined>>) => {
					return (
						<Box>
							<Typography>{row.row.original[header]}</Typography>
						</Box>
					);
				},
			})),

		[headerTable]
	);

	return useTable(
		{
			columns,
			data,
		},
		useRowSelect
	);
};
