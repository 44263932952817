import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS_BY_CLIENT_ID } from '../graphql/queries';
import { ClientPricesByClientIdQuery, ClientPricesByClientIdQueryVariables } from '../graphql/queries.definitions';
import { useField } from 'formik';

export const useGetProductsByClientId = () => {
	const [, , helperProducts] = useField<ClientPricesByClientIdQuery['products']>('products');

	const [getClientPricesByClientId] = useLazyQuery<ClientPricesByClientIdQuery, ClientPricesByClientIdQueryVariables>(
		GET_PRODUCTS_BY_CLIENT_ID,

		{
			onCompleted: (data) => {
				if (data) helperProducts?.setValue(data?.products);
			},
			fetchPolicy: 'network-only',
		}
	);

	return { getClientPricesByClientId };
};
