import { useMutation } from '@apollo/client';
import { UpsertBigShopConfigMutation, UpsertBigShopConfigMutationVariables } from '../graphql/mutations.definitions';
import { GET_BIG_SHOP_CONFIG_BY_ID } from '../graphql/queries';
import { UPSERT_BIG_SHOP_CONFIG } from '../graphql/mutations';
import { BigShopConfigUpsertInput } from '../../../graphql/types';
import { formatBigShopConfigQuantityToFloat } from '../helpers';

const useBigShopConfigUpsert = () => {
	const [upsertBigShopMutation] = useMutation<UpsertBigShopConfigMutation, UpsertBigShopConfigMutationVariables>(
		UPSERT_BIG_SHOP_CONFIG
	);

	const upsertBigShop = async (bigShopConfig: BigShopConfigUpsertInput) => {
		const formattedBigShopConfig = formatBigShopConfigQuantityToFloat(bigShopConfig);
		await upsertBigShopMutation({
			variables: {
				data: formattedBigShopConfig,
			},
			refetchQueries: [
				{
					query: GET_BIG_SHOP_CONFIG_BY_ID,
					variables: {
						supportId: formattedBigShopConfig.supportId,
					},
				},
			],
			awaitRefetchQueries: true,
		});
	};

	return { upsertBigShop };
};

export default useBigShopConfigUpsert;
