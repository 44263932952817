import React, { useCallback, useState, ReactNode } from 'react';
import Popin from '../components/popin/Popin';

type PopinInputs = {
	headerTitle: string;
	buttonLabel?: string;
	maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	fullWidth: boolean;
	iconButton?: JSX.Element;
	isDisplayHeader?: boolean;
	bottomFooter?: ReactNode;
	handleClosePopinCustom?: () => void;
	handleClearStorage?: () => void;
	isButtonFooterClosePopin?: boolean;
};

const usePopin = ({
	headerTitle,
	buttonLabel,
	maxWidth,
	fullWidth,
	iconButton,
	isDisplayHeader,
	bottomFooter,
	handleClosePopinCustom,
	handleClearStorage,
	isButtonFooterClosePopin,
}: PopinInputs): [() => void, (popin: ReactNode) => JSX.Element, () => void] => {
	const [isPopinOpen, setPopinOpen] = useState<boolean>(false);
	const openPopin: () => void = useCallback(() => setPopinOpen(true), [setPopinOpen]);
	const closePopin: () => void = useCallback(() => {
		setPopinOpen(false);
		handleClearStorage && handleClearStorage();
		handleClosePopinCustom && handleClosePopinCustom();
	}, [handleClearStorage, setPopinOpen, handleClosePopinCustom]);
	const renderPopin = (popin: ReactNode) => (
		<Popin
			isPopinOpen={isPopinOpen}
			closePopin={closePopin}
			headerTitle={headerTitle}
			buttonLabel={buttonLabel}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			iconButton={iconButton}
			isDisplayHeader={isDisplayHeader}
			bottomFooter={bottomFooter}
			isButtonFooterClosePopin={isButtonFooterClosePopin}
		>
			{popin}
		</Popin>
	);

	return [openPopin, renderPopin, closePopin];
};

export default usePopin;
