import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { PriceSummary } from './components/PriceSummary';
import AppListTable from '../../../components/tables/list-table/AppListTable';
import { ProductSellingPriceProps } from './definitions';
import { InformationsValideContext } from '../../context/informationsValideProvider';
import { ProductSellingPriceItems } from './components/ProductSellingPriceItems';

export const ProductSellingPrice = ({ supplierPricesLoading }: ProductSellingPriceProps): JSX.Element | null => {
	const context = useContext(InformationsValideContext);

	if (!context) return null;

	return (
		<>
			{context.renderingSuppliers(<AppListTable tableInstance={context.instanceSuppliers} />)}
			<Box my={3} mx={20}>
				<Grid container>
					<ProductSellingPriceItems
						handleStatusSwitch={context.handleStatusSwitch}
						handleSupplierList={context.handleSupplierList}
						handleVersionNumber={context.handleVersionNumber}
						isClariPrint={context.isClariPrint}
						isIdentical={context.values.isIdentical}
						numberVersion={context.values.numberVersion}
						quantityDisabled={context.quantityDisabled}
						nbOfMaxPoses={context.nbOfMaxPoses}
						error={context.metaQuantity.error}
						quantities={context.values.quantities}
						getClientPrices={context.getClientPrices}
						disabled={context?.isConsultSellingPrice}
					/>
					<Grid xs={12} item>
						<PriceSummary
							disabled={context?.isConsultSellingPrice}
							supplierPrices={context.supplierPrices}
							supplierPricesLoading={supplierPricesLoading}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
