import { useQuery } from '@apollo/client';
import { GetOrderByIdQuery, GetOrderByIdQueryVariables } from '../graphql/queries.definitions';
import { GET_ORDER_BY_ID } from '../graphql/queries';

const useOrderByIdQuery = (id: string) => {
	const { data, loading } = useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GET_ORDER_BY_ID, {
		variables: {
			id,
		},
	});

	return { order: data?.order, loading };
};

export default useOrderByIdQuery;
