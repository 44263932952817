import { Rythme } from '../../../graphql/types';

export interface TransportModeOption {
	title: string;
}
export interface Discount {
	ca: number;
	rfa: number;
}

export interface Escompte {
	escompte: number;
	client: string;
	dayLimitTime: number;
}

export const rfaRytmeOptions = [
	{ value: Rythme.Annuel, label: 'Annuel' },
	{ value: Rythme.Semestriel, label: 'Semestriel' },
	{ value: Rythme.Trimestriel, label: 'Trimestriel' },
];

export const transportList: TransportModeOption[] = [
	{ title: 'TNT' },
	{ title: 'Chronopost' },
	{ title: 'Dpd' },
	{ title: 'XPO' },
];
