import * as XLSX from 'xlsx';
import axios from 'axios';

export default function Datacorrector(dataAfter: any, excludeColDC: number[], callback: Function) {
	const excelRenderer = (file: File | undefined, callback: Function) => {
		return new Promise(function (resolve, reject) {
			let reader = new FileReader();
			reader.onload = function (e) {
				/* Parse data */
				const bstr = e.target ? e.target.result : null;
				const wb = XLSX.read(bstr, { type: 'binary', cellStyles: true });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];
				let json: Array<Array<string>> = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });

				resolve(json);
				return callback(null, json);
			};
			if (file) reader.readAsBinaryString(file);
		});
	};

	//import CorrespondanceWording Excel file
	axios({
		method: 'GET',
		url: 'CorrespondanceWording.xlsx',
		responseType: 'blob',
		headers: {
			'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	})
		.then((res) => {
			excelRenderer(res.data, (ress: any) => {}).then((cw: any) => {
				//loop the data after formatting Step
				dataAfter.grids[Object.keys(dataAfter.grids)[0]].forEach((row: any, rowindex: number) => {
					row.forEach((column: any, colindex: number) => {
						if (typeof column.value === 'string' && !excludeColDC.includes(colindex)) {
							let columnedited: string = column.value.toLowerCase();

							//loop the CorrespondanceWording data
							cw.forEach((rowCw: Array<string>) => {
								const found = rowCw
									.slice(1)
									.sort((a, b) => {
										if (a.length > b.length) {
											return -1;
										}
										return 1;
									})
									.map((a) => (a.trim() === '' ? (a = '') : a.toLowerCase()))
									.find((element) => element !== '' && RegExp(element.split(':')[0]).test(columnedited));

								if (found) {
									const foundElement = found.includes(':') ? RegExp(found.split(':')[1], 'g') : RegExp(found, 'g');

									if (rowCw[0].includes('xxx')) {
										const cwCol = rowCw[0].split('xxx')[1];
										columnedited = columnedited.replace(foundElement, cwCol);

										const foundUnitNbs = columnedited.match(RegExp('[0-9]+ *' + cwCol.trim(), 'g'));

										if (foundUnitNbs) {
											foundUnitNbs.forEach(function (unitNb: string, unitNbInd: number) {
												const uNbWithoutAnySpace = unitNb.replace(RegExp(' {2,}', 'g'), ' ');
												columnedited = columnedited.replace(unitNb, '\\' + uNbWithoutAnySpace);
											});
										}
									} else {
										columnedited = columnedited.replace(foundElement, rowCw[0]);
									}

									dataAfter.grids[Object.keys(dataAfter.grids)[0]][rowindex][colindex].value = columnedited;
								}
							});
						}
					});
				});

				callback(dataAfter.grids);
			});
		})
		.catch((err) => {
			console.log(err);
		});
}
