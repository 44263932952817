import { MarginType } from 'graphql/types';
import { ProductAttributeDetails, ProductFilter } from 'context/pricesProductContext/definition';
import { PriceCell } from 'components/summaryPrice/SummaryPrice';
import {
	getBestTransportDeliveryParcelPurchasePrice,
	getDeliveryParcelPurchasePriceInfo,
	getBestTransportDeliveryParcelPurchasePriceKnownDistributions,
} from './getTransportDeliveryParcelPurchasePrice';
import getInitialSupplierPriceForTable from './getInitialSupplierPriceForTable';
import { calculateMargin } from 'clientPrice/helpers';

const getNumberWithMargin = (price: number, percent: number) => price * (percent / 100) + price;

const getClientPriceWithMargin = (marginType: MarginType, price: number, margin: number, isWithRfaClient: boolean) =>
	marginType === (isWithRfaClient && MarginType.Percent) ? getNumberWithMargin(price, margin) : price + margin;

const getPriceWithRfaAndMargin = (marginType: MarginType, price: number, percent: number, isWithRfaClient: boolean) =>
	marginType === MarginType.Percent && isWithRfaClient
		? price + ((price * (percent / 100) + price) * percent) / 100
		: price;
const getTotalClientPrices = (
	transportSellingPriceHtWithClientRfa: number,
	transportPurchasePriceHtWithRfa: number,
	transportSellingPriceHtWithClientRfaRectified?: number,
	productSalesPriceExcludingVATRectified?: number,
	productSalesPriceExcludingVATWithRfa?: number,
	productSalesPriceExcludingVAT?: number
): PriceCell[] => [
	{
		width: '25%',
		name: 'productName',
		isBorder: true,
		hasRate: false,
		label: 'Produit',
	},
	{
		width: '10%',
		name: 'quantity',
		isBorder: false,
		hasRate: false,
		label: 'Quantité',
	},
	{
		width: '20%',
		name: 'productPurchasePriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PA produit HT (€)',
	},
	{
		width: '20%',
		name: 'totalProductSalesPriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PV produit HT (€)',
		value: productSalesPriceExcludingVATRectified ?? productSalesPriceExcludingVATWithRfa,
	},
	{
		width: '20%',
		name: 'transportPurchasePriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PA Transport HT (€)',
		value: transportPurchasePriceHtWithRfa,
	},
	{
		width: '20%',
		name: 'transportSalesPriceExcludingVAT',
		isBorder: false,
		hasRate: false,
		label: 'PV Transport HT (€)',
		value: transportSellingPriceHtWithClientRfaRectified ?? transportSellingPriceHtWithClientRfa,
	},
	{
		width: '20%',
		name: 'cost',
		isBorder: false,
		hasRate: false,
		label: 'Prix vente HT (€)',
		value:
			(productSalesPriceExcludingVATRectified ??
				productSalesPriceExcludingVATWithRfa ??
				productSalesPriceExcludingVAT ??
				0) + (transportSellingPriceHtWithClientRfaRectified ?? transportSellingPriceHtWithClientRfa),
	},
];

const getVerticalTotalClientPrices = (
	productPurchasePriceExcludingVATWithRFA?: number,
	transportPurchasePriceHtWithRfa?: number,
	cost?: number,
	rfaClientEuro?: number,
	sellingPriceMargin?: number,
	mainRfaRate?: number,
	isWithRfaClient?: boolean
): PriceCell[] => [
	{
		width: '29%',
		name: 'sellingPriceMargin',
		isBorder: false,
		hasRate: true,
		label: 'Marge (€)',
		value: calculateMargin(
			cost ?? 0,
			rfaClientEuro ?? 0,
			productPurchasePriceExcludingVATWithRFA ?? 0,
			transportPurchasePriceHtWithRfa ?? 0
		),
	},
	{
		width: '29%',
		name: 'sellingPriceMarginRate',
		isBorder: false,
		hasRate: true,
		label: 'Taux de marge (%)',
		value: cost ? ((sellingPriceMargin ?? 0) * 100) / (cost - (rfaClientEuro ?? 0)) : 0,
	},
	{
		width: '29%',
		name: 'estimatif',
		isBorder: false,
		hasRate: true,
		label: 'Estimatif taxe Citéo (€)',
	},
	{
		width: '29%',
		name: 'rfaClientEuro',
		isBorder: false,
		hasRate: true,
		label: 'RFA Client (€)',
		value: isWithRfaClient ? (cost ?? 0) * ((mainRfaRate ?? 0) / 100) : 0,
	},
];

const getClariprintKey = (productAttributesDetails?: ProductAttributeDetails) => {
	switch (true) {
		case productAttributesDetails?.value?.includes('pages'):
			return 'folds';
		case productAttributesDetails?.value?.includes('Rainage'):
			return 'creasing';
		case productAttributesDetails?.value?.includes('trou'):
			return 'holes';
		case productAttributesDetails?.value?.includes('Numérotation'):
			return 'numbering';
		case productAttributesDetails?.value?.includes('Piqûre') ??
			productAttributesDetails?.value?.includes('Brochage') ??
			productAttributesDetails?.value?.includes('Reliure') ??
			productAttributesDetails?.value?.includes('Plié non assemblé') ??
			productAttributesDetails?.value?.includes('Collage au pli'):
			return 'binding';
		case productAttributesDetails?.value?.includes('Perforation'):
			return 'linear_perforating';
		default:
			return '';
	}
};

export const getProductAttributes = (productFilter: ProductFilter) => ({
	largeurFormatFiniCm: productFilter?.largeurFormatFiniCm?.name,
	longueurFormatFiniCm: productFilter?.longueurFormatFiniCm?.name,
	largeurFormatOuvertCm: productFilter?.largeurFormatOuvertCm?.name,
	longueurFormatOuvertCm: productFilter?.longueurFormatOuvertCm?.name,
	impressionRectoVerso: productFilter?.impressionRectoVerso?.name,
	nbCouleurRecto: productFilter?.nbCouleurRecto?.name,
	nbCouleurVerso: productFilter?.nbCouleurVerso?.name,
	surfacage: productFilter?.surfacage?.name,
	typeMatiere: productFilter?.typeMatiere?.name,
	typePapier: productFilter?.typePapier?.name,
	grammageGr: productFilter?.grammageGr?.name,
	decoupe: productFilter?.decoupe?.name,
	finitionRecto: productFilter?.finitionRecto?.name,
	finitionVerso: productFilter?.finitionVerso?.name,
	classification: productFilter?.classification?.name,
	certification: productFilter?.certification?.name,
	fondsPerdus: productFilter.fondsPerdus?.name,
	faconnageDeLivraison: productFilter.faconnageDeLivraison?.name,
	conditionnement: {
		...productFilter.conditionnement,
		total: productFilter.conditionnement?.total ?? undefined,
		conditionnementList: productFilter.conditionnement?.conditionnementList.map(
			({ numberOfExLots, firstConditionnement, secondConditionnement, threeConditionnement }) => ({
				numberOfExLots: numberOfExLots ?? undefined,
				...(firstConditionnement.conditionnement && {
					firstConditionnement: {
						conditionnement: firstConditionnement.conditionnement.name,
						nbExConditionnement: firstConditionnement.nbExConditionnement ?? undefined,
						labeling: firstConditionnement.labeling ?? false,
					},
				}),
				...(secondConditionnement.conditionnement && {
					secondConditionnement: {
						conditionnement: secondConditionnement.conditionnement.name,
						nbExConditionnement: secondConditionnement.nbExConditionnement ?? undefined,
						labeling: secondConditionnement.labeling ?? false,
					},
				}),
				...(threeConditionnement.conditionnement && {
					threeConditionnement: {
						conditionnement: threeConditionnement.conditionnement.name,
						nbExConditionnement: threeConditionnement.nbExConditionnement ?? undefined,
						labeling: threeConditionnement.labeling ?? false,
					},
				}),
			})
		),
	},
	faconnage: {
		value: productFilter?.faconnage?.name ?? '',
		key: getClariprintKey(productFilter?.faconnage),
	},
	precisionsFaconnage: {
		value: productFilter?.precisionsFaconnage?.name ?? '',
		key: getClariprintKey(productFilter?.precisionsFaconnage),
	},
	optionsFaconnage: {
		value: productFilter?.optionsFaconnage?.name ?? '',
		key: getClariprintKey(productFilter?.optionsFaconnage),
	},
});

export {
	getNumberWithMargin,
	getClientPriceWithMargin,
	getTotalClientPrices,
	getVerticalTotalClientPrices,
	getInitialSupplierPriceForTable,
	getClariprintKey,
	getBestTransportDeliveryParcelPurchasePriceKnownDistributions,
	getBestTransportDeliveryParcelPurchasePrice,
	getDeliveryParcelPurchasePriceInfo,
	getPriceWithRfaAndMargin,
};
