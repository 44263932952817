import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useField } from 'formik';

interface RadioGroupFieldProps {
	name: string;
}

const RadioGroupField = ({ name }: RadioGroupFieldProps) => {
	const [field] = useField<string>(name);

	return (
		<RadioGroup name={name} value={String(field.value)} onChange={field.onChange}>
			<Box display="flex" flexDirection="row" alignItems="center">
				<FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="Prospect" />
				<FormControlLabel value="false" control={<Radio color="primary" size="small" />} label="Client" />
			</Box>
		</RadioGroup>
	);
};

export default RadioGroupField;
