import { IconButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import colors from '../../app/theme/colors.scss';

export const PrimaryIconButton = withStyles(() =>
	createStyles({
		root: {
			color: colors.white,
			backgroundColor: colors.primary,
			'&:hover': {
				backgroundColor: colors.primary,
			},
		},
	})
)(IconButton);
