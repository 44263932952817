import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import client from './app/apollo/client';
import theme from './app/theme/theme';
import AppRoutes from './routes/AppRoutes';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './context/Auth';
import { PricesProductProvider } from './context/pricesProductContext/PricesProductContext';

const container = document.getElementById('root');

const root = container && createRoot(container);

root?.render(
	<ApolloProvider client={client}>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<AuthProvider>
					<PricesProductProvider>
						<SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
							<AppRoutes />
						</SnackbarProvider>
					</PricesProductProvider>
				</AuthProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
