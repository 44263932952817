import { Box, FormControl } from '@mui/material';
import React from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import FormikRadioField from '../../../../components/form/FormikRadioField';
import { LayoutField } from '../../../../components/form/LayoutField';
import { rfaRytmeOptions } from '../definitions';

const RythmeField = () => {
	return (
		<Box mt={4}>
			<Box my={4}>
				<DashedHeader>Rythme de récupération des RFA</DashedHeader>
				<LayoutField label="Rythme">
					<FormControl fullWidth>
						<FormikRadioField name="rythme" options={rfaRytmeOptions} />
					</FormControl>
				</LayoutField>
			</Box>
		</Box>
	);
};

export default RythmeField;
