import { useMutation } from '@apollo/client';
import { GenerateTemplateUrlMutation, GenerateTemplateUrlMutationVariables } from '../graphql/mutations.definitions';
import { GENERATE_TEMPLATE_URL } from '../graphql/mutations';

const useTemplateUrlGenerate = () => {
	const [generateTemplateUrlMutation] = useMutation<GenerateTemplateUrlMutation, GenerateTemplateUrlMutationVariables>(GENERATE_TEMPLATE_URL);
	const generateTemplateUrl = async (params: any) => {
		const response = await generateTemplateUrlMutation(params);
    if (!response.data) throw new Error();
    return response;
	};
	return { generateTemplateUrl };
};

export default useTemplateUrlGenerate;