import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import DashedHeader from '../../components/DashedHeader';
import LayoutFieldForm from '../../components/form/LayoutFieldForm';
import { Box, TextField } from '@mui/material';
import colors from '../../app/theme/colors.scss';
import { LayoutField } from '../../components/form/LayoutField';
import { useField } from 'formik';
import { VATType } from '../definitions';

interface SupplierTvaProps {
	isRoleAdmin: boolean;
}

const SupplierTva = ({ isRoleAdmin }: SupplierTvaProps): JSX.Element => {
	const [field] = useField<number | unknown | null | undefined>('tvaType');

	return (
		<Box mb={7}>
			<Grid container justifyContent="space-between">
				<Grid item xs={2} />
				<Grid item xs={8}>
					<DashedHeader>TVA</DashedHeader>
				</Grid>
				<Grid item xs={2} />
			</Grid>
			<Grid container justifyContent="space-between">
				<Grid item xs={2} />
				<Grid item xs={3}>
					<LayoutFieldForm label="Numéro de TVA " name="tvaNum" disabled={!isRoleAdmin} />
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={3}>
					<LayoutField label="Type de TVA ">
						<Box bgcolor={colors.grey} mb={3}>
							<TextField value={VATType[(field.value as number) ?? 0]} variant="outlined" fullWidth disabled />
						</Box>
					</LayoutField>
				</Grid>
				<Grid item xs={2} />
			</Grid>
		</Box>
	);
};

export default memo(SupplierTva);
