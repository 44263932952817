import gql from 'graphql-tag';

export const ORDER_ADDRESS_FRAGMENT = gql`
	fragment OrderAddress on OrderAddress {
		name
		city
		country
		firstAddress
		deliveryDepartment
		name
		postBox
		secondAddress
		thirdAddress
		zipCode
	}
`;

export const ORDER_ITEM_FRAGMENT = gql`
	fragment OrderItem on OrderItem {
		buyingPriceHt
		clientReference
		collectionDate
		comment
		commentTransporter
		deliveryComment
		expectedDeliveryDate
		id
		isWithRfaProduct
		legacyRef
		isWithRfaTransport
		numberPlateChanges
		numberShots
		numberVersion
		numberFileProcessing
		orderId
		prepressPriceHt
		prepressSellingPriceHt
		productId
		productMargin
		productName
		productSellingPriceHt
		collectionComment
		provisionDate
		quantity
		realProductMargin
		realTransportMargin
		rfaRate
		sellingPriceHt
		sellingPriceTtc
		status
		supplierComment
		tools
		trackingLink
		transportBuyingPriceHt
		transportMargin
		transportSellingPriceHt
		tva
		tvaRate
		versionsNumber
		weight
		assetUrl
		thumbnailAssetUrl
		productTotalWeight
		corsicaDeliveryExtra
		isMechanizable
		isFinalDistribution
		totalPurchasePriceExcludingTax
		transporterPurchasePriceExcludingVAT
		transporterPurchaseExcludingVATWithRfa
		transporterRfaRate
		productPurchasePriceExcludingVAT
		productPurchasePriceExcludingVATWithRFA
		deliveryDepartment
		supplierRspRate
		supplierRfaRate
		bestTransporterId
		bestDeliveryParcelPurchasePrice
		bestSupplier {
			name
		}
		suppliersPrices {
			supplierId
			supplierPrice
			supplierName
			supplierRspRate
			supplierRfaRate
			buyingPriceHtWithoutRspAndRfa
		}
		product {
			id
			supportId
			support {
				name
				category
			}
			codeAt
		}
		onePacDocumentId
		onePacDocumentName
		onePacDistributionName
		onePacReplicationName
		onePacDistributionUrl
		onePacReplicationUrl
		shippingAddress {
			...OrderAddress
		}
		transportPurchasePriceHt
		transportMoment
		transportSellingPriceHtAdditionalMargin
		transportType
		transportRfa
		transportSellingPriceMargin
		transportSellingPriceByDefaultHt
		transportPurchasePriceHtWithRfa
		transportPurchasePriceExcludingVAT
		corsicaDeliveryExtra
		isMechanizable
		isFinalDistribution
		transportSellingPriceHtWithClientRfa
		transportSellingPriceHtWithClientRfaRectified
		deliveryParcel
		transportersPrices {
			transporterId
			transporterName
			transporterRfaRate
			transporterPurchasePriceExcludingVAT
			transporterPurchaseExcludingVATWithRfa
		}
		clientNetworkLink
		postDescription
		publicationDate
	}
	${ORDER_ADDRESS_FRAGMENT}
`;
const GET_ORDERS = gql`
	query GetOrders(
		$search: OrderSearchInput
		$sort: OrderSortInput
		$pagination: PaginationInput
		$isOrderCockpit: Boolean
	) {
		ordersWithPagination(search: $search, sort: $sort, pagination: $pagination, isOrderCockpit: $isOrderCockpit) {
			orders {
				id
				status
				createdAt
				updatedAt
				source
				followStatus
				buyingPriceHt
				sellingPriceHt
				clientId
				elepvContactName
				elpevContactEmail
				versionsNumber
				elpevContactPhone
				version
				clientName
				client {
					id
					name
				}
				billingAddress {
					...OrderAddress
				}
				fileReference {
					id
					operationName
					askBy
					clientReference
				}
				orderItem {
					quantity
				}
			}
			total
		}
	}
	${ORDER_ADDRESS_FRAGMENT}
`;

const GET_ORDER_BY_ID = gql`
	query GetOrderById($id: String!) {
		order(id: $id) {
			id
			status
			followStatus
			elepvContactName
			elpevContactPhone
			updatedAt
			version
			source
			paymentType
			comment
			clientId
			clientName
			buyingPriceHt
			sellingPriceHt
			buyingPriceHtWithRfa
			marginWithClientRfa
			realMargin
			marginRate
			elpevContactEmail
			createdAt
			status
			isDistribution
			onepacUserId
			onepacUserName
			statusComment
			statusUpdatedAt
			orderDistributions {
				id
				clientId
				clientShippingAddressId
			}
			fileReference {
				id
				clientReference
				operationName
				elpevResponsible {
					givenName
					familyName
				}
			}
			orderItem {
				...OrderItem
			}

			client {
				id
				name
			}
			billingAddress {
				...OrderAddress
			}
			childrenOrder {
				id
				clientName
				buyingPriceHt
				sellingPriceHt
				transportBuyingPriceHt
				transportSellingPriceHt
				prepressPriceHt
				prepressSellingPriceHt
				productSellingPriceHt
				tvaRate
				tva
				sellingPriceTtc
				billingAddress {
					...OrderAddress
				}
				orderItem {
					...OrderItem
				}
				invoices {
					createdAt
					id
					sendAt
					status
					validateAt
					url
				}
			}

			invoices {
				id
				createdAt
				validateAt
				sendAt
				status
			}
			clientQuote {
				id
				version
			}
		}
	}
	${ORDER_ADDRESS_FRAGMENT}
	${ORDER_ITEM_FRAGMENT}
`;

const FILTERED_ORDER_ITEM_SUPPLIER_PRICE = gql`
	query GeSupplierPricesOrderItem($filters: SupplierPriceFilterInput!) {
		supplierPrices: filtredSupplierPrices(filters: $filters) {
			totalPAHT
			totalPAHTWithRFA
			supplier {
				id
				name
				rfa
				rspStart
				rspEnd
				rsp
				siteAddress1 {
					deliveryDepartment
				}
			}
		}
	}
`;

const GET_SUPPLIER_PRICES_FOR_ORDER_ITEM = gql`
	query GetSupplierPricesForOrderItem($options: SupplierPricesForOrderItemInput!) {
		supplierPrices: getSupplierPricesForOrderItem(options: $options) {
			totalPAHT
			totalPAHTWithRFA
			supplier {
				id
				name
				rfa
				rspStart
				rspEnd
				rsp
				siteAddress1 {
					deliveryDepartment
				}
			}
		}
	}
`;

const GET_TRANSPORTERS_PURCHASE_PRICE = gql`
	query GetTransporterPurchasePrice($filter: UniqClientPriceInput!) {
		transportersPrices: transportListWithPurchasePrice(filter: $filter) {
			transporterId
			transporterName
			transporterRfaRate
			transporterPurchasePriceExcludingVAT
			transporterPurchaseExcludingVATWithRfa
		}
	}
`;

const GET_TRANSPORTERS_PRICES_FOR_ORDER_ITEM = gql`
	query GetTransportersPricesForOrderItem($options: TransportersPricesForOrderItemInput!) {
		transportersPrices: transportersPricesForOrderItem(options: $options) {
			transporterId
			transporterName
			transporterRfaRate
			transporterPurchasePriceExcludingVAT
			transporterPurchaseExcludingVATWithRfa
		}
	}
`;

export {
	GET_ORDERS,
	GET_ORDER_BY_ID,
	FILTERED_ORDER_ITEM_SUPPLIER_PRICE,
	GET_TRANSPORTERS_PURCHASE_PRICE,
	GET_SUPPLIER_PRICES_FOR_ORDER_ITEM,
	GET_TRANSPORTERS_PRICES_FOR_ORDER_ITEM,
};
