import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { Typography } from '@mui/material';

import { DiscountObjectType } from '../../graphql/types';
export const useDiscountTable = (discounts: DiscountObjectType[]) => {
	const columns = useMemo<Column<DiscountObjectType>[]>(
		() => [
			{
				accessor: 'ca',
				width: '129px',
				Header: () => <Typography>CA</Typography>,
				Cell: ({ row }) => <Typography>{row.original?.ca ?? null}</Typography>,
			},
			{
				accessor: 'rfa',
				width: '129px',
				Header: () => <Typography>RFA</Typography>,
				Cell: ({ row }) => <Typography>{row.original?.rfa ?? null}</Typography>,
			},
		],
		[]
	);
	return useTable({
		columns,
		data: discounts,
		isBorderless: true,
	});
};
