import PermissionContext from 'context/permissionContext/PermissionContext';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

type ProtectedRouteType = {
	component: React.ElementType;
	path: string;
	permissionKey: string;
};

const ProtectedRoute = ({ component: Component, path, permissionKey }: ProtectedRouteType) => {
	const { isAllowedTo } = useContext(PermissionContext);

	return (
		<Route
			path={path}
			render={(props) => (isAllowedTo(permissionKey) ? <Component {...props} /> : <Redirect to="/" />)}
		/>
	);
};

export default ProtectedRoute;
