import React from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import SupplierForm from './form/SupplierForm';
import useSupplierByIdQuery from './hooks/useSupplierByIdQuery';
import Loading from '../components/loading/Loading';
import validateSupplierSchema from './form/validation-schema';
import { dateToLocaleString } from '../utils/date';
import { useSnackbar } from 'notistack';

import { useSupplierUpdate } from './hooks/useSupplierUpdate';
import { SupplierFormModel } from './definitions';
import { typeNotifications } from '../utils/definition';

const SupplierFormikForm = (): JSX.Element => {
	const { id: supplierId } = useParams<{ id: string }>();
	const { supplier, loading } = useSupplierByIdQuery(supplierId);
	const { enqueueSnackbar } = useSnackbar();

	const updateSupplier = useSupplierUpdate();

	if (!supplier || loading) return <Loading loading={loading} />;

	const initialValues: SupplierFormModel & { user?: { password: string } } = {
		...supplier,
		user: { ...supplier.user, password: '', email: supplier.user?.email ?? '', id: supplier.user?.id ?? '' },
		createdAt: dateToLocaleString(supplier?.createdAt),
		updatedAt: dateToLocaleString(supplier?.updatedAt),
		lastConnection: dateToLocaleString(supplier?.lastConnection),
	};

	const handleSubmit = async (values: SupplierFormModel & { user?: { password: string } | null }) => {
		try {
			if (supplier) await updateSupplier(values);

			enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
		} catch (error) {
			enqueueSnackbar("Une erreur c'est produite", { variant: typeNotifications.Error });
		}
	};

	return (
		<>
			<Formik<SupplierFormModel & { user?: { password: string } }>
				initialValues={initialValues}
				validationSchema={validateSupplierSchema}
				enableReinitialize
				onSubmit={(values) => handleSubmit(values)}
				validateOnChange={false}
			>
				<SupplierForm supplier={supplier} />
			</Formik>
		</>
	);
};

export default SupplierFormikForm;
