import { AttributConditionnement } from '../graphql/types';

export function isString(x: any): x is string {
	return typeof x === 'string';
}

export function isNumber(x: any): x is number {
	return typeof x === 'number';
}

export function isBoolean(x: any): x is boolean {
	return typeof x === 'boolean';
}

export function isFile(x: File | string | null | ArrayBuffer): x is File {
	return (x as File).type !== undefined;
}

export function isAttributeConditionnement(x: any): x is AttributConditionnement {
	return x.hasOwnProperty('conditionnementList');
}
