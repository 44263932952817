import { useMutation } from '@apollo/client';
import { InvoiceStatus, InvoicesToUpdateInput } from 'graphql/types';
import { UPDATE_ONE_OR_MANY_INVOICES } from 'invoice/graphql/mutations';
import {
	UpdateOneOrManyInvoicesMutation,
	UpdateOneOrManyInvoicesMutationVariables,
} from 'invoice/graphql/mutations.definitions';
import { GET_INVOICES } from 'invoice/graphql/queries';
import { useSnackbar } from 'notistack';
import { typeNotifications } from 'utils/definition';

export const useUpdateOneOrManyInvoices = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [updateOneOrManyMutation] = useMutation<
		UpdateOneOrManyInvoicesMutation,
		UpdateOneOrManyInvoicesMutationVariables
	>(UPDATE_ONE_OR_MANY_INVOICES);

	const updateOneOrMany = async (ids: InvoicesToUpdateInput) => {
		try {
			const { data } = await updateOneOrManyMutation({
				variables: { Ids: ids },
				refetchQueries: [
					{ query: GET_INVOICES, variables: { search: {}, sort: {}, filter: { status: InvoiceStatus.ToCount } } },
					{ query: GET_INVOICES, variables: { search: {}, sort: {}, filter: { status: InvoiceStatus.Counted } } },
				],
			});

			if (data?.updateOneOrManyInvoices.length)
				enqueueSnackbar('Commande(s) comptabilisée(s)', { variant: typeNotifications.Success });
			else enqueueSnackbar('Aucune commande  comptabilisée', { variant: typeNotifications.Error });
		} catch (e) {
			enqueueSnackbar(` ${e} `, { variant: typeNotifications.Error });
		}
	};
	return { updateOneOrMany };
};
