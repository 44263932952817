import { AdditionalMarginsInput, Margins, MarginsInput, MarginType } from 'graphql/types';
import { SupplierPriceFormModel } from 'supplier/definitions';
import { getClientPriceWithMargin, getNumberWithMargin } from '.';
import addPercentageToValue from '../../../utils/addPercentageToValue';

const getInitialSupplierPriceForTable = (
	defaultCommercialProductMargin: number,
	buyingPriceWithAmalgam: number,
	additionalMargins: AdditionalMarginsInput,
	margins: MarginsInput,
	purchasePriceExcludingTax: Margins,
	sellingPriceWithMargin: Margins,
	supplierPrices?: SupplierPriceFormModel[]
) => {
	const calculateDefaultSellingPrice = (key: string, amount: number) => {
		return buyingPriceWithAmalgam > 0 && key === 'prixHorsCalage'
			? addPercentageToValue(defaultCommercialProductMargin, buyingPriceWithAmalgam)
			: addPercentageToValue(defaultCommercialProductMargin, amount);
	};

	const calculateSellingPriceWithMargin = (
		key: string,
		addingMargin: string,
		margin: number,
		purchasePrice: number
	) => {
		const defaultPrice = calculateDefaultSellingPrice(key, purchasePrice);
		return addingMargin !== MarginType.Percent ? defaultPrice + margin : addPercentageToValue(margin, defaultPrice);
	};

	if (!supplierPrices || !supplierPrices.length) {
		return [
			{
				price: 'PA HT (€)',
				...(Object.keys(purchasePriceExcludingTax) as Array<keyof MarginsInput>).reduce<Margins>((acc, curr) => {
					acc = {
						...acc,
						[curr]:
							(!purchasePriceExcludingTax[curr] || purchasePriceExcludingTax[curr] === 0
								? ''
								: purchasePriceExcludingTax[curr]) ?? '',
					};
					return acc;
				}, {} as Margins),
			},
			{
				price: 'PA avec Amalgame (€)',
				prixCalage: '',
				prixHorsCalage: '',
				autrePrixFixe: '',
				prixChangementPlaque: '',
				prixTraitementFichier: '',
				prixCliche: '',
				prixOutillage: '',
				prixExemplaireSupp: '',
			},
			{
				price: 'PV défaut HT (€)',
				...(Object.keys(purchasePriceExcludingTax) as Array<keyof MarginsInput>).reduce<Margins>((acc, curr) => {
					acc = {
						...acc,
						[curr]:
							!purchasePriceExcludingTax && !purchasePriceExcludingTax[curr]
								? 0
								: calculateDefaultSellingPrice(curr, purchasePriceExcludingTax[curr]),
					};
					return acc;
				}, {} as Margins),
			},
			{ ...additionalMargins, price: 'Marge supplémentaire' },
			{
				price: 'Marge',
				...(Object.keys(margins) as Array<keyof MarginsInput>).reduce<Margins>((acc, curr) => {
					acc = {
						...acc,
						[curr]: !margins && !margins[curr] ? 0 : margins[curr],
					};
					return acc;
				}, {} as Margins),
			},
			{
				price: 'PV margé HT (€)',
				...(Object.keys(purchasePriceExcludingTax) as Array<keyof MarginsInput>).reduce<Margins>((acc, curr) => {
					acc = {
						...acc,
						[curr]: !purchasePriceExcludingTax[curr]
							? 0
							: calculateSellingPriceWithMargin(
									curr,
									additionalMargins[curr],
									margins[curr],
									purchasePriceExcludingTax[curr]
							  ),
					};
					return acc;
				}, {} as Margins),
			},
		];
	} else {
		const defaultClientPriceExcludingTax = {
			price: 'PV défaut HT (€)',
			prixCalage: supplierPrices[0].prixCalage
				? getNumberWithMargin(supplierPrices[0].prixCalage, defaultCommercialProductMargin)
				: 0,
			prixHorsCalage: supplierPrices[0].prixHorsCalage
				? buyingPriceWithAmalgam > 0
					? getNumberWithMargin(buyingPriceWithAmalgam, defaultCommercialProductMargin)
					: getNumberWithMargin(supplierPrices[0].prixHorsCalage, defaultCommercialProductMargin)
				: 0,
			autrePrixFixe: supplierPrices[0].autrePrixFixe
				? getNumberWithMargin(supplierPrices[0].autrePrixFixe, defaultCommercialProductMargin)
				: 0,
			prixChangementPlaque: supplierPrices[0].prixChangementPlaque
				? getNumberWithMargin(supplierPrices[0].prixChangementPlaque, defaultCommercialProductMargin)
				: 0,
			prixTraitementFichier: supplierPrices[0].prixTraitementFichier
				? getNumberWithMargin(supplierPrices[0].prixTraitementFichier, defaultCommercialProductMargin)
				: 0,
			prixCliche: supplierPrices[0].prixCliche
				? getNumberWithMargin(supplierPrices[0].prixCliche, defaultCommercialProductMargin)
				: 0,
			prixOutillage: supplierPrices[0].prixOutillage
				? getNumberWithMargin(supplierPrices[0].prixOutillage, defaultCommercialProductMargin)
				: 0,
			prixExemplaireSupp: supplierPrices[0].prixExemplaireSupp
				? getNumberWithMargin(
						parseFloat(supplierPrices[0].prixExemplaireSupp.toFixed(5)),
						defaultCommercialProductMargin
				  )
				: 0,
		};

		return [
			{
				price: 'PA HT (€)',
				prixCalage: supplierPrices[0].prixCalage ?? '',
				prixHorsCalage: supplierPrices[0].prixHorsCalage ?? '',
				autrePrixFixe: supplierPrices[0].autrePrixFixe ?? '',
				prixChangementPlaque: supplierPrices[0].prixChangementPlaque ?? '',
				prixTraitementFichier: supplierPrices[0].prixTraitementFichier ?? '',
				prixCliche: supplierPrices[0].prixCliche ?? '',
				prixOutillage: supplierPrices[0].prixOutillage ?? '',
				prixExemplaireSupp: supplierPrices[0].prixExemplaireSupp ?? '',
			},
			{
				price: 'PA avec Amalgame (€)',
				prixCalage: '',
				prixHorsCalage: '',
				autrePrixFixe: '',
				prixChangementPlaque: '',
				prixTraitementFichier: '',
				prixCliche: '',
				prixOutillage: '',
				prixExemplaireSupp: '',
			},
			{
				...defaultClientPriceExcludingTax,
			},
			{ ...additionalMargins, price: 'Marge supplémentaire' },
			{ ...margins, price: 'Marge' },
			{
				price: 'PV margé HT (€)',
				prixCalage: getClientPriceWithMargin(
					additionalMargins.prixCalage,
					defaultClientPriceExcludingTax.prixCalage,
					margins.prixCalage ?? 0,
					true
				),
				prixHorsCalage: getClientPriceWithMargin(
					additionalMargins.prixHorsCalage,
					defaultClientPriceExcludingTax.prixHorsCalage,
					margins.prixHorsCalage ?? 0,
					true
				),
				autrePrixFixe: getClientPriceWithMargin(
					additionalMargins.autrePrixFixe,
					defaultClientPriceExcludingTax.autrePrixFixe,
					margins.autrePrixFixe ?? 0,
					true
				),
				prixChangementPlaque: getClientPriceWithMargin(
					additionalMargins.prixChangementPlaque,
					defaultClientPriceExcludingTax.prixChangementPlaque,
					margins.prixChangementPlaque ?? 0,
					true
				),
				prixTraitementFichier: getClientPriceWithMargin(
					additionalMargins.prixTraitementFichier,
					defaultClientPriceExcludingTax.prixTraitementFichier,
					margins.prixTraitementFichier ?? 0,
					true
				),
				prixCliche: getClientPriceWithMargin(
					additionalMargins.prixCliche,
					defaultClientPriceExcludingTax.prixCliche,
					margins.prixCliche ?? 0,
					true
				),
				prixOutillage: getClientPriceWithMargin(
					additionalMargins.prixOutillage,
					defaultClientPriceExcludingTax.prixOutillage,
					margins.prixOutillage ?? 0,
					true
				),
				prixExemplaireSupp: getClientPriceWithMargin(
					additionalMargins.prixExemplaireSupp,
					defaultClientPriceExcludingTax.prixExemplaireSupp,
					margins.prixExemplaireSupp ?? 0,
					true
				),
			},
		];
	}
};

export default getInitialSupplierPriceForTable;
