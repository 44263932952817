import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OrderItemUpsertInput } from 'graphql/types';
import useGetSupplierPricesForOrderItemQuery from '../../../hooks/useGetSupplierPricesForOrderItemQuery';
import colors from 'app/theme/colors.scss';
import { formatNumberDisplay } from 'utils/prices';
import { useProductInformation } from 'order/form/order-cockpit/product-information/ProductInformationContext';

interface OrderItemSupplierPricesProps {
	orderItem: OrderItemUpsertInput & { supportId: string };
	index: number;
}

const useStyles = makeStyles({
	firstCell: {
		borderLeft: '3px solid',
		borderLeftColor: colors.turquoise500,
		borderTopLeftRadius: '3px',
		borderTopRightRadius: '3px',
	},
});

const OrderItemSuppliersPrices = ({ orderItem, index }: OrderItemSupplierPricesProps) => {
	const classes = useStyles();

	const { clientQuote } = useProductInformation();
	const distributionsForOrderItem = clientQuote?.clientQuoteDistributions?.filter(distribution => distribution.productId === orderItem.productId).map(item => item.quantity) || [];

	const options = {
		productId: orderItem.productId,
		supportId: orderItem.supportId,
		quantityGlobal: Number(orderItem.quantity),
		numberVersion: orderItem.numberVersion ?? 1,
		distributions: distributionsForOrderItem,
	};

	useGetSupplierPricesForOrderItemQuery(options, index);

	return (
		<Box mt={4}>
			<Typography variant="h6" color="textSecondary">
				Fournisseur possible
			</Typography>
			{orderItem.suppliersPrices?.map((item, index) => (
				<Box mr={0.5} key={index}>
					<Box display="flex">
						<Box
							className={classes.firstCell}
							borderLeft={'5px'}
							borderColor={colors.red500}
							width="200px"
							bgcolor={colors.white}
							p={1}
							mr={1}
							mb={1}
						>
							<Typography variant="h5">{item.supplierName}</Typography>
						</Box>

						<Box borderLeft={'5px'} borderColor={colors.red500} width="110px" bgcolor={colors.white} p={1} mb={1}>
							<Typography variant="h5">{formatNumberDisplay(item.supplierPrice as number, 2)}</Typography>
						</Box>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default OrderItemSuppliersPrices;
