import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Radio } from '@mui/material';
import colors from 'app/theme/colors.scss';

interface TransportTypeItemProps {
	checked?: boolean;
	onChange: (label: string) => void;
	label: string;
	children?: ReactNode;
	disabled?: boolean;
}

const TransportTypeItem = ({
	checked,
	onChange,
	label,
	children,
	disabled = false,
}: TransportTypeItemProps): JSX.Element => (
	<Box display="flex" bgcolor={colors.grayCard} px={2} mb={2}>
		<Box flexGrow={1} display="flex">
			<Radio
				disabled={disabled}
				data-testid={label}
				color="primary"
				checked={checked}
				style={{ padding: 0, marginRight: '12px' }}
				onChange={() => {
					onChange(label);
				}}
			/>
			<Typography variant="h2">{label}</Typography>
		</Box>
		{children && children}
	</Box>
);

export default TransportTypeItem;
