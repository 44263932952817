import { Box, Typography } from '@mui/material';
import React from 'react';
import colors from '../../../../app/theme/colors.scss';

const TransporterPricesTitleCell = (): JSX.Element => {
	return (
		<Box width="60px" color={colors.gray100}>
			<Typography variant="h6">Prix (€)</Typography>
		</Box>
	);
};

export default TransporterPricesTitleCell;
