import * as React from 'react';
import Typography from '@mui/material/Typography';
import Importfilepopup from './Importfilepopup/Importfilepopup';
import { Box } from '@mui/material';
import { Data } from '../Displaydata/Displaydata';

interface props {
	goTo: (step: string) => void;
	setData: React.Dispatch<React.SetStateAction<Data>>;
}

const Homecontent: React.FC<props> = ({ goTo, setData }) => {
	return (
		<Box width="fit-content" paddingTop="30px">
			<Typography variant="h3">Intégration des données fournisseurs</Typography>
			<Box marginTop="50px" marginBottom="30px">
				<Typography variant="body1">
					Vous êtes sur le point d'intégrer des grilles de tarifs fournisseurs dans la base de données OneSupply <br />
					Cliquer sur le bouton ''Intégrer un fichier'' pour commencer le processus
				</Typography>
			</Box>
			<Importfilepopup setData={setData} goTo={goTo} />
		</Box>
	);
};

export default Homecontent;
