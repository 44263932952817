import React from 'react';
import Sidebar, { drawerWidth } from './Sidebar';
import clsx from 'clsx';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import OSlogo from '../public/images/OSlogo.svg';
import { Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBarShift: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
			transition: theme.transitions.create(['margin', 'width']),
		},
		toolbar: {
			minHeight: '44px ',
			padding: 0,
		},
	})
);

export default function Header() {
	const classes = useStyles();
	const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
	return (
		<AppBar
			position="fixed"
			className={clsx({
				[classes.appBarShift]: openDrawer,
			})}
		>
			<ClickAwayListener onClickAway={() => setOpenDrawer(false)}>
				<Toolbar className={classes.toolbar}>
					<Sidebar setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
					<Box display="flex" m={1}>
						<img src={OSlogo} height={21} alt="" />
						<Box fontWeight="fontWeightBold" fontSize={18} ml={1} mt={0.35}>
							One
						</Box>
						<Box fontWeight="fontWeightRegular" fontSize={18} mr={2.25} mt={0.35}>
							Supply
						</Box>
					</Box>
				</Toolbar>
			</ClickAwayListener>
		</AppBar>
	);
}
