import React from 'react';
import { FamilyAttribute } from 'admin/support/definitions';
import Box from '@mui/material/Box';
import { Divider, Grid, List, Typography } from '@mui/material';
import { sortProductDetailsByFamily } from '../helpers';
import FamilyAttributes from 'admin/support/FamilyAttributes';
import colors from 'app/theme/colors.scss';
import { ProductQuery } from '../graphql/queries.definitions';
import ConditionnementDetails from './ConditionementDetails';

export type ProductDetailsByFamilyType = ProductQuery['product'];

interface ProductDetailsByFamilyProps {
	product?: ProductDetailsByFamilyType | null;
}

export const ProductDetailsByFamily = ({ product }: ProductDetailsByFamilyProps): JSX.Element => {
	return (
		<Box display="flex">
			{Object.values(FamilyAttribute).map((family, index) => (
				<Box key={index} p={1} width="20%">
					<FamilyAttributes family={family} />
					<Box bgcolor={colors.white}>
						{product &&
							sortProductDetailsByFamily(product).map(
								(detail, i) =>
									family.toLowerCase() === detail.family?.toLowerCase() && (
										<Box key={i} mt={1}>
											<Grid container>
												<Grid item xs={12}>
													{detail.family?.toLowerCase() !== 'conditionnement' ? (
														<Box mx={1} display="flex" justifyContent="space-between">
															<Typography variant="h4">{detail.name}: </Typography>
															<Typography variant="h5">{detail.value}</Typography>
														</Box>
													) : (
														<List style={{ maxHeight: '135px', overflow: 'auto' }}>
															<ConditionnementDetails detail={product.conditionnement} key={i} />
														</List>
													)}
												</Grid>
											</Grid>
											<Divider />
										</Box>
									)
							)}
					</Box>
				</Box>
			))}
		</Box>
	);
};
