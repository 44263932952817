import { Box, FormControl, Grid, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import SelectField from '../../../components/form/SelectField';
import {
	Client,
	ClientInsertInput,
	ClientRegion,
	ClientStructure,
	ClientTypology,
	EntityType,
} from '../../../graphql/types';
import useClientsQuery from '../../hooks/useClientsQuery';
import { Entity } from './TypeAndOrganizationEntity';

interface OrganizationEntityProps {
	onChangeInformativeEntities: React.Dispatch<React.SetStateAction<Record<number, ClientInsertInput> | undefined>>;
	onChangeEntitiesSelected: React.Dispatch<React.SetStateAction<Record<number, string | null | undefined>>>;
	entitiesSelected: Record<number, string | null | undefined>;
	entities: Entity[] | undefined;
	clientTypeLevel: number;
	structure: ClientStructure;
	clientRegions: ClientRegion[] | undefined | null;
	clientTypologies: ClientTypology[] | undefined | null;
	informativeEntitiesLevels: number[] | undefined;
}

const OrganizationEntity = ({
	onChangeEntitiesSelected,
	onChangeInformativeEntities,
	entitiesSelected,
	entities,
	informativeEntitiesLevels,
	clientTypeLevel,
	structure,
	clientRegions,
	clientTypologies,
}: OrganizationEntityProps): JSX.Element => {
	const { setFieldValue, values } = useFormikContext<Client>();

	const parentCurrentEntityId = useMemo(() => {
		for (let i = structure.level - 1; i >= 0; i--) {
			if (entitiesSelected[i]) {
				return entitiesSelected[i];
			}
		}
	}, [entitiesSelected, structure]);

	const childCurrentEntityId = useMemo(() => {
		for (let i = structure.level + 1; i < clientTypeLevel; i++) {
			if (entitiesSelected[i]) {
				return entitiesSelected[i];
			}
		}
	}, [structure, entitiesSelected, clientTypeLevel]);

	const { clients: existingRegions } = useClientsQuery({
		rootClientId: values.rootClientId,
		regionId: { not: null },
		parentClientId: parentCurrentEntityId,
	});

	const { clients: existingTypologies } = useClientsQuery({
		rootClientId: values.rootClientId,
		typologyId: { not: null },
		parentClientId: parentCurrentEntityId,
	});

	const entitiesOptions = useMemo(() => {
		if (structure.type === EntityType.Region && existingRegions) {
			return clientRegions?.map((region) => {
				const existingRegion = existingRegions.find((reg) => reg.regionId === region.id);
				return { label: region.name, value: existingRegion ? existingRegion.id : region.id };
			});
		} else if (structure.type === EntityType.Typology && existingTypologies) {
			return clientTypologies?.map((typology) => {
				const existingTypology = existingTypologies.find((typ) => typ.typologyId === typology.id);
				return { label: typology.name, value: existingTypology ? existingTypology.id : typology.id };
			});
		} else {
			// si isChildCurrentEntityExist = false donc il s'agit d'une entité fille qui est une entité informative(région ou typologie) qui n'existe pas dans la table client
			const isChildCurrentEntityExist = Boolean(entities?.find((entity) => entity.id === childCurrentEntityId));

			return entities
				?.filter(
					(entity) =>
						entity.type === structure.type &&
						(isChildCurrentEntityExist ? entity.id === entitiesSelected[structure.level] : true) &&
						(parentCurrentEntityId !== entitiesSelected[0] ? entity.parentClientId === parentCurrentEntityId : true) &&
						entity.id !== values.id
				)
				.map((entity) => ({ label: entity.name, value: entity.id }));
		}
	}, [
		existingRegions,
		existingTypologies,
		childCurrentEntityId,
		clientRegions,
		clientTypologies,
		entities,
		parentCurrentEntityId,
		structure.type,
		values.id,
		entitiesSelected,
		structure.level,
	]);

	const setParentClientId = useCallback(
		(newEntitySelected: Record<number, string | undefined>) => {
			if (entities && entitiesSelected) {
				const newEntitiesSelected = { ...entitiesSelected, ...newEntitySelected };
				for (let i = clientTypeLevel - 1; i > 0; i--) {
					const isEntityExist = Boolean(entities.find((entity) => entity.id === newEntitiesSelected[i]));
					if (newEntitiesSelected[i] && isEntityExist) {
						setFieldValue('parentClientId', newEntitiesSelected[i]);
						break;
					}
				}
			}
		},
		[entitiesSelected, setFieldValue, clientTypeLevel, entities]
	);

	useEffect(() => {
		const entityIdSelected = entitiesSelected[structure.level];
		if (informativeEntitiesLevels?.includes(structure.level) && entityIdSelected) {
			const isEntityIdExist = entitiesOptions?.find((entityOption) => entityOption.value === entityIdSelected);
			const informativeEntity = entities?.find((entity) => entity.id === entityIdSelected);
			const isParentCurrentEntityExist = Boolean(entities?.find((entity) => entity.id === parentCurrentEntityId));
			const newInformativeEntities: Record<number, ClientInsertInput> = {};

			if (!isEntityIdExist && entitiesOptions?.length) {
				const informativeEntityName = informativeEntity?.name
					? informativeEntity.name
					: structure.type === EntityType.Region
					? clientRegions?.find((clientRegion) => clientRegion.id === entityIdSelected)?.name
					: clientTypologies?.find((clientTypology) => clientTypology.id === entityIdSelected)?.name;
				const newEntityIdSelected = entitiesOptions.find((eo) => eo.label === informativeEntityName)?.value;

				if (informativeEntity) {
					newInformativeEntities[structure.level] = {
						isRoot: false,
						type: structure.type,
						parentClientId: isParentCurrentEntityExist ? (parentCurrentEntityId as string) : null,
						rootClientId: values.rootClientId,
						...(structure.type === EntityType.Region
							? { regionId: newEntityIdSelected }
							: { typologyId: newEntityIdSelected }),
						name: informativeEntity.name,
					};
				}

				setParentClientId({ [structure.level]: newEntityIdSelected });
				onChangeEntitiesSelected({ ...entitiesSelected, [structure.level]: newEntityIdSelected });
			} else {
				if (!informativeEntity) {
					newInformativeEntities[structure.level] = {
						isRoot: false,
						type: structure.type,
						parentClientId: isParentCurrentEntityExist ? (parentCurrentEntityId as string) : null,
						rootClientId: values.rootClientId,
						...(structure.type === EntityType.Region
							? { regionId: entityIdSelected }
							: { typologyId: entityIdSelected }),
						name: isEntityIdExist?.label ?? '',
					};
				}
			}

			onChangeInformativeEntities((informativeEntities) => {
				if (informativeEntities) {
					if (informativeEntity) {
						let informativeEntitiesFiltred: Record<number, ClientInsertInput> = newInformativeEntities;
						if (informativeEntities[structure.level]) {
							//Supprimer InformativeEntity du state InformativeEntities lorsqu'il existe déja dans la table client
							for (let level in informativeEntities) {
								if (structure.level.toString() !== level)
									informativeEntitiesFiltred[parseInt(level)] = informativeEntities[parseInt(level)];
							}
						} else {
							informativeEntitiesFiltred = { ...informativeEntities, ...informativeEntitiesFiltred };
						}
						return Object.keys(informativeEntitiesFiltred).length === 0 ? undefined : informativeEntitiesFiltred;
					}

					//Modifier le state InformativeEntities lorsque parentClientId change
					if (informativeEntities[structure.level]) {
						const informativeEntitiesFiltredd = {
							...informativeEntities,
							[structure.level]: {
								...informativeEntities[structure.level],
								parentClientId: isParentCurrentEntityExist ? parentCurrentEntityId : null,
								...(structure.type === EntityType.Region
									? { regionId: entityIdSelected }
									: { typologyId: entityIdSelected }),
							},
						};
						return informativeEntitiesFiltredd;
					}
				}

				return Object.keys(newInformativeEntities).length === 0 ? undefined : newInformativeEntities;
			});
		}
	}, [
		entitiesOptions,
		setParentClientId,
		informativeEntitiesLevels,
		entities,
		values.rootClientId,
		onChangeInformativeEntities,
		parentCurrentEntityId,
		clientTypologies,
		onChangeEntitiesSelected,
		clientRegions,
		entitiesSelected,
		structure.level,
		structure.type,
	]);

	const handleChange = (event: SelectChangeEvent<unknown>) => {
		//entityId peut etre regionID ou typologyId
		const entityId = event.target.value as string;

		let newEntitiesSelected: Record<number, string | null | undefined> = {
			...entitiesSelected,
			[structure.level]: entityId,
		};

		for (let i = clientTypeLevel - 1; i > structure.level; i--) {
			if (newEntitiesSelected[i] && !informativeEntitiesLevels?.includes(i)) {
				newEntitiesSelected[i] = undefined;
			}
		}

		setParentClientId({ [structure.level]: entityId });
		onChangeEntitiesSelected(newEntitiesSelected);
	};

	return (
		<Box mb={1} display="flex" flexDirection="row" justify-content="space-between" alignItems="center">
			<Grid item xs={3}>
				<Typography variant="h4">{structure.title}</Typography>
			</Grid>
			<Grid item xs={9}>
				<FormControl fullWidth>
					<SelectField value={entitiesSelected[structure.level]} variant="outlined" onChange={handleChange}>
						{entitiesOptions?.map((item) => (
							<MenuItem key={item.value} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</SelectField>
				</FormControl>
			</Grid>
		</Box>
	);
};
export default OrganizationEntity;
