import * as React from 'react';
import { Box, Divider } from '@mui/material';
import Path from '../../components/Path';
import UsersList, { UserItem } from './UsersList';
import RoleInformations from '../role/RoleInformations';
import { DisplayInterface } from './definitions';
import useUsersQuery from './hooks/useUsersQuery';
import RolesList from '../role/RolesList';
import UserInformationsForm from './Form/UserInformationsForm';
import { PATH_NAMES } from '../../Header/menus';

const User = () => {
	const { users } = useUsersQuery();
	const paths: (string | undefined)[] = [PATH_NAMES.Admin, PATH_NAMES.User];

	const [displayInterfaceUser, setDisplayInterfaceUser] = React.useState<DisplayInterface>(
		DisplayInterface.DisplayUserInterface
	);

	const usersList: UserItem[] | undefined = users?.map((user) => ({
		id: user.id,
		familyName: user.familyName,
		givenName: user.givenName,
		role: user.role.name,
		phoneNumber: user.phoneNumber,
		email: user.email,
		isActive: user.isActive,
	}));

	let displayAdminInterfaces;
	switch (displayInterfaceUser) {
		case DisplayInterface.DisplayUserInterface:
			displayAdminInterfaces = (
				<Box>
					<RolesList />

					<UsersList
						users={usersList}
						titleList={'Liste des utilisateurs'}
						onDisplayFormUser={setDisplayInterfaceUser}
						isUserAddButtonDisplayed={true}
					/>
				</Box>
			);

			break;
		case DisplayInterface.DisplayRoleInterface:
			displayAdminInterfaces = (
				<Box>
					<RoleInformations onDisplayInterfaceUser={setDisplayInterfaceUser} />
				</Box>
			);
			break;
		case DisplayInterface.DisplayUserForm:
			displayAdminInterfaces = (
				<Box>
					<UserInformationsForm />
				</Box>
			);
			break;
		default:
	}

	return (
		<Box ml={7} mr={7} mb={3}>
			<Path paths={paths} />
			<Divider />
			{displayAdminInterfaces}
		</Box>
	);
};

export default User;
