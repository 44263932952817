import React, { ChangeEvent } from 'react';
import NumberFormatCustom from 'components/form/NumberFormat';
import { Column, Row } from 'react-table';
import { Box, TextField } from '@mui/material';

interface EditableCellProps {
	value: number;
	row: Row;
	column: Column;
	updateMyData: (index: number, id: string, value: number) => void;
}
const EditableCellQuantity = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateMyData,
}: EditableCellProps) => {
	const [value, setValue] = React.useState(initialValue);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(+e.target.value);
	};

	const onBlur = () => {
		if (!id) {
			return;
		}
		updateMyData(index, id, value);
	};

	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<Box>
			<TextField
				aria-label={`editable-cell-quantity`}
				variant="outlined"
				name={`input${index}`}
				value={value ?? ''}
				InputProps={{
					inputComponent: NumberFormatCustom as any,
				}}
				onChange={onChange}
				onBlur={onBlur}
				autoComplete="off"
				style={{ width: 200 }}
			/>
		</Box>
	);
};

export default EditableCellQuantity;
