import { useMutation } from '@apollo/client';
import { INSERT_CLIENT } from '../graphql/mutations';
import { GET_CLIENTS, GET_CLIENTS_BY_CURRENT_USER } from '../graphql/queries';
import { InsertClientMutation, InsertClientMutationVariables } from '../graphql/mutations.definitions';
import { ClientInsertInput, EntityType } from '../../graphql/types';
import { GET_FILE_REFERENCES } from 'file-reference/graphql/queries';

const useClientInsert = () => {
	const [insertClientMutation] = useMutation<InsertClientMutation, InsertClientMutationVariables>(INSERT_CLIENT);

	const insertClient = async (client: ClientInsertInput) => {
		const { data } = await insertClientMutation({
			variables: {
				client,
			},
			refetchQueries: [
				...(!(client.regionId && client.typologyId)
					? [
							{
								query: GET_CLIENTS,
								variables: {
									filter: {
										type: { notIn: [EntityType.Region, EntityType.Typology] },
									},
								},
							},
							{
								query: GET_CLIENTS,
								variables: {
									filter: {
										type: { notIn: [EntityType.Region, EntityType.Typology] },
										name: client.name,
									},
								},
							},
					  ]
					: []),
				...(client.regionId || client.typologyId
					? [
							{
								query: GET_CLIENTS,
								variables: {
									filter: {
										rootClientId: client.rootClientId,
										typologyId: { not: null },
										parentClientId: client.parentClientId,
									},
								},
							},
					  ]
					: []),
				...(client.regionId || client.typologyId
					? [
							{
								query: GET_CLIENTS,
								variables: {
									filter: {
										rootClientId: client.rootClientId,
										regionId: { not: null },
										parentClientId: client.parentClientId,
									},
								},
							},
					  ]
					: []),
				GET_FILE_REFERENCES,
				GET_CLIENTS_BY_CURRENT_USER,
			],
			awaitRefetchQueries: true,
		});

		return { client: data?.client };
	};

	return { insertClient };
};

export default useClientInsert;
