import ATTRIBUT_FRAGMENT from '../attribute/attributeFragment';
import { gql } from '@apollo/client';
import CONDITIONNEMENT_FRAGMENT from '../conditionnement/conditionnementFragment';

export const PRODUCT_FRAGMENT = gql`
	fragment Product on Product {
		id
		supportId
		typeImpression
		matiere
		typeSupport
		faconnage
		nbCouleurRecto
		descriptionIndication
		precisionsFaconnage
		typePapier
		typeMatiere
		nbCouleurVerso
		optionsFaconnage
		format
		orientation
		couleur
		impressionRectoVerso
		decoupe
		surfacage
		paginationTotale
		fourniture
		grammageGr
		precisionsFourniture
		epaisseurMicrons
		paginationCouverture
		finitionRecto
		finitionVerso
		nbCouleurCouvertureRecto
		paginationInterieur
		classification
		paginationInterieurEncart
		certification
		nbCouleurCouvertureVerso
		matiereCouverture
		nbCouleurInterieurRecto
		largeurFormatOuvertCm
		typeMatiereCouverture
		longueurFormatOuvertCm
		nbCouleurInterieurVerso
		grammageCouvertureGr
		largeurFormatPageCm
		nbCouleurInterieurEncartRecto
		matiereInterieur
		longueurFormatPageCm
		nbCouleurInterieurEncartVerso
		largeurFormatFiniCm
		surfacageCouvertureRecto
		typeMatiereInterieur
		longueurFormatFiniCm
		grammageInterieurGr
		tauxSurfacage
		impressionLogo
		largeurCouvertureFormatOuvertCm
		longueurCouvertureFormatOuvertCm
		repiquage
		repiquageDOS
		largeurCouvertureFormatPageCm
		repiquageCoeur
		longueurCouvertureFormatPageCm
		chant
		largeurCouvertureFormatFiniCm
		longueurCouvertureFormatFiniCm
		largeurInterieurFormatOuvertCm
		longueurInterieurFormatOuvertCm
		largeurInterieurFormatPageCm
		longueurInterieurFormatPageCm
		largeurInterieurFormatFiniCm
		longueurInterieurFormatFiniCm
		largeurInterieurEncartFormatOuvertCm
		longueurInterieurEncartFormatOuvertCm
		largeurInterieurEncartFormatPageCm
		longueurInterieurEncartFormatPageCm
		largeurInterieurEncartFormatFiniCm
		longueurInterieurEncartFormatFiniCm
		profondeurFormatFiniCm
		diametreCm
		taille
		enveloppeFenetre
		largeurTailleFenetreCm
		longueurTailleFenetreCm
		ouvertureEnveloppe
		surfacageCouvertureVerso
		surfacageInterieurRecto
		surfacageInterieurVerso
		typeEnvoi
		ciblage
		zoneTerritorial
		objectifMarketing
		paysDeDiffusion
		modeleAchat
		fondsPerdus
		faconnageDeLivraison
		largeurPixel
		longueurPixel
		codeAt
		productSupplierPrice {
		    poidsUnitaireGr
		}
		support {
			name
			supportAttributes {
				...Attribute
			}
		}
		conditionnement {
			...AttributConditionnement
		}
	}
	${ATTRIBUT_FRAGMENT}
	${CONDITIONNEMENT_FRAGMENT}
`;
