import React from 'react';
import { ComponentTitle } from 'order/definition';
import { Box, Grid, Typography } from '@mui/material';
import colors from '../../../../app/theme/colors.scss';
import ShowProductAndTransport from './components/ShowProductAndTransport';
import PricesSuppliersByProduct from './SupplierPricesByProduct';

const ChoiceOfProductionAndTransport = ({ title }: ComponentTitle) => {
	return (
		<Box mb={5} mt={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">{title}</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box ml={32}>
				<Grid container direction="column" justifyContent="center">
					<Grid xs={4} lg={8}>
						<Box px={3} fontSize={15} mb={10}>
							OneSupply calcule pour vous les prix fournisseurs les moins cher. Vous pouvez retrouver les données de
							calcul dans la partie suivante :{' '}
							<Typography
								component={() => (
									<span style={{ color: colors.blue900, cursor: 'pointer', textDecoration: 'underline' }}>
										Calcul des prix de production et transport
									</span>
								)}
							/>
						</Box>
					</Grid>
					<ShowProductAndTransport />
					<PricesSuppliersByProduct />
				</Grid>
			</Box>
		</Box>
	);
};

export default ChoiceOfProductionAndTransport;
