import React from 'react';
import { Button } from '@mui/material';
import MuiDialogActions from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';

interface FooterPopinProps {
	buttonLabel: string;
	iconButton?: JSX.Element;
	isButtonFooterClosePopin?: boolean;
	closePopin: () => void;
}

const useStyles = makeStyles(() => ({
	footer: {
		margin: 0,
		padding: '15px',
	},
}));

const FooterPopin = ({
	buttonLabel,
	iconButton,
	isButtonFooterClosePopin,
	closePopin,
}: FooterPopinProps): JSX.Element => {
	const classes = useStyles();

	return (
		<MuiDialogActions className={classes.footer}>
			<Button variant="contained" color="primary" onClick={() => isButtonFooterClosePopin && closePopin()}>
				{iconButton} {buttonLabel}
			</Button>
		</MuiDialogActions>
	);
};

export default FooterPopin;
