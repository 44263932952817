import { Box, FormControl, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Client, ClientInsertInput, EntityType } from '../../../graphql/types';
import useClientsQuery from '../../hooks/useClientsQuery';
import useClientQuery from '../../hooks/useClientQuery';
import OrganizationEntity from './OrganizationEntity';
import FormikSelectField from '../../../components/form/FormikSelectField';
import DashedHeader from '../../../components/DashedHeader';
import { LayoutField } from '../../../components/form/LayoutField';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FormikRadioField from '../../../components/form/FormikRadioField';
import FormikFilesField from '../../../components/form/FormikFilesField';
import { ACCEPTED_FORMATS } from '../client-structure/ClientStructuresForm';

interface TypeAndOrganizationEntityProps {
	onChangeInformativeEntities: React.Dispatch<React.SetStateAction<Record<number, ClientInsertInput> | undefined>>;
}

export type Entity = Pick<
	Client,
	'id' | 'name' | 'isRoot' | 'parentClientId' | 'rootClientId' | 'type' | 'regionId' | 'typologyId' | 'isActif'
>;

const TypeAndOrganizationEntity = ({ onChangeInformativeEntities }: TypeAndOrganizationEntityProps): JSX.Element => {
	const { setFieldValue, values } = useFormikContext<Client>();
	const { clients: rootEntities } = useClientsQuery({ isRoot: true });
	const { client: rootClient } = useClientQuery(values.rootClientId ?? '');
	const { clients: entities } = useClientsQuery(
		values.rootClientId
			? { OR: [{ isRoot: true, id: values.rootClientId }, { rootClientId: values.rootClientId }] }
			: {}
	);
	const [entitiesSelected, setEntitiesSelected] = React.useState<Record<number, string | null | undefined>>({});

	const structures = useMemo(() => rootClient?.structures, [rootClient]);

	const clientTypeLevel = useMemo(() => structures?.find((s) => s.type === values.type)?.level, [
		structures,
		values.type,
	]);

	const informativeEntitiesLevels = useMemo(
		() =>
			structures
				?.filter((structure) => structure.type === EntityType.Typology || structure.type === EntityType.Region)
				.map((structure) => structure.level),
		[structures]
	);

	const clientTypesOptions = useMemo(
		() =>
			structures?.slice(1).map((structure) => ({
				label: structure.title,
				value: structure.type,
				disabled: informativeEntitiesLevels?.includes(structure.level),
			})),
		[structures, informativeEntitiesLevels]
	);

	useEffect(() => {
		if (
			entities &&
			structures &&
			values.rootClientId &&
			values.parentClientId &&
			clientTypeLevel &&
			clientTypeLevel !== 1
		) {
			const initialEntitiesSelected: Record<number, string | null | undefined> = {};
			let parentEntity = entities.find((entity) => entity.id === values.parentClientId);
			let parentLevel = structures.find((struc) => struc.type === parentEntity?.type)?.level;
			const getInitialEntitiesSelected = (parentEntity: Entity | undefined) => {
				parentEntity = entities.find((entity) => entity.id === parentEntity?.parentClientId);
				parentLevel = structures.find((struc) => struc.type === parentEntity?.type)?.level;

				if (parentEntity && parentLevel) {
					initialEntitiesSelected[parentLevel] = parentEntity.id;
					getInitialEntitiesSelected(parentEntity);
				}
			};
			initialEntitiesSelected[0] = values.rootClientId;

			if (parentLevel) {
				initialEntitiesSelected[parentLevel] = values.parentClientId;
				getInitialEntitiesSelected(parentEntity);
			}

			setEntitiesSelected((entitiesSelected) => ({ ...entitiesSelected, ...initialEntitiesSelected }));
		}
	}, [structures, clientTypeLevel, entities, values.parentClientId, values.rootClientId]);

	return (
		<Box my={3}>
			<Box mb={4}>
				<Typography variant="h2">Type de client</Typography>
			</Box>
			<Box mx={15}>
				<DashedHeader>Type et organisation</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<Box mb={5}>
							<LayoutField label="Entité mère">
								<FormControl fullWidth>
									<FormikSelectField
										name={'rootClientId'}
										variant="outlined"
										onChange={(event) => {
											const rootClientId = event.target.value as string;
											setFieldValue('rootClientId', rootClientId);
											setFieldValue('parentClientId', rootClientId);
											setEntitiesSelected({ 0: rootClientId });
										}}
									>
										{rootEntities?.map((item) => (
											<MenuItem value={`${item.id}`} key={item.id}>
												{`${item.name}`}
											</MenuItem>
										))}
									</FormikSelectField>
								</FormControl>
							</LayoutField>
						</Box>
					</Grid>
					<Grid item xs={5}></Grid>

					<Grid item xs={5}>
						<LayoutField label="Type de client">
							<FormikRadioField
								name="type"
								options={clientTypesOptions}
								onChange={(_, type) => {
									setFieldValue('type', type);
									setFieldValue('parentClientId', values.rootClientId);
									setEntitiesSelected({ 0: values.rootClientId });
									onChangeInformativeEntities(undefined);
								}}
							/>
						</LayoutField>
					</Grid>

					<Grid item xs={5}>
						<Box mb={2}>
							<Typography variant="h4" align="left">
								Organisation
								<IconButton
									onClick={() => {
										setEntitiesSelected({ 0: values.rootClientId });
										setFieldValue('parentClientId', values.rootClientId);
										onChangeInformativeEntities(undefined);
									}}
									color="primary"
									aria-label="Réintialiser"
									edge="end"
									size="small"
								>
									<SettingsBackupRestoreIcon />
								</IconButton>
							</Typography>
						</Box>
						{clientTypeLevel &&
							rootClient &&
							structures &&
							structures.slice(1, clientTypeLevel).map((structure) => (
								<Box key={structure.level}>
									<OrganizationEntity
										entitiesSelected={entitiesSelected}
										onChangeEntitiesSelected={setEntitiesSelected}
										onChangeInformativeEntities={onChangeInformativeEntities}
										entities={entities}
										structure={structure}
										clientRegions={rootClient.regions}
										clientTypologies={rootClient.typologies}
										clientTypeLevel={clientTypeLevel}
										informativeEntitiesLevels={informativeEntitiesLevels}
									/>
								</Box>
							))}
					</Grid>
				</Grid>
				<Box mt={3}>
					<DashedHeader>Logo</DashedHeader>
					<FormikFilesField name="logoFile" accept={ACCEPTED_FORMATS} imagePlaceholder={values.logo ?? null} />
					<Typography variant="inherit" align="center" color="textSecondary">
						(Fichier ne doit pas excéder 200Mo)
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
export default TypeAndOrganizationEntity;
