import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

type props = {
	grid: any[][];
	setGrid: Function;
	colindex: number;
};

export default function HeaderBtn(props: props) {
	const addNewCol = () => {
		let gridnewcol: any[][] = [];

		Object.assign(gridnewcol, props.grid).map((a, row) => a.splice(props.colindex + 1, 0, { value: '' }));
		props.setGrid(gridnewcol);
	};

	return (
		<IconButton size="small" aria-label="add" component="span" onClick={addNewCol}>
			<AddIcon fontSize="small" />
		</IconButton>
	);
}
