import { Box, Grid } from '@mui/material';
import React from 'react';
import { DiscountInput, EscompteInput } from '../../../graphql/types';
import DiscountField from './components/DiscountField';
import RythmeField from './components/RythmeField';
import RemiseField from './components/RemiseField';
import EscompteField from './components/EscompteField';
import BigShopFactoryFields from './components/BigShopFactoryFields';
import { SupplierFormModel } from '../../definitions';
import SupplierElpevFromBaseDB from '../../components/SupplierElpevFromBaseDB';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';

interface CommerialInformationsProps {
	title: string;
	supplier?: SupplierFormModel;
	isRoleAdmin: boolean;
}

const CommercialInformations = ({ title, supplier, isRoleAdmin }: CommerialInformationsProps): JSX.Element | null => {
	const discounts: DiscountInput[] | undefined | null = supplier?.discounts;

	const escomptes: EscompteInput[] | undefined | null = supplier?.escomptes;

	if (!discounts || !escomptes) return null;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Box mb={5}>
				<Box mb={6}>
					<Grid container>
						<Grid item xs={1} />
						<SupplierElpevFromBaseDB title={title} />
					</Grid>
				</Box>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={10}>
						<Grid container direction="column">
							<Grid item xs={12}>
								<Grid item xs={2} />
								<DiscountField discounts={discounts} isRoleAdmin={isRoleAdmin} />
							</Grid>
							<Grid item xs={12}>
								<Grid item xs={2} />

								<RythmeField />
							</Grid>
							<Grid item xs={12}>
								<EscompteField escomptes={escomptes} />
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<RemiseField />
						</Grid>
						<Grid item xs={12}>
							<BigShopFactoryFields />
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</LocalizationProvider>
	);
};

export default CommercialInformations;
