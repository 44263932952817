import React, { useState } from 'react';
import {
	DataGrid,
	GridColDef,
	GridValueGetterParams,
	GridRowParams,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridFilterModel,
	getGridDateOperators,
	GridSortModel,
} from '@mui/x-data-grid';
import { Box, Button, Chip, Divider } from '@mui/material';
import Path from 'components/Path';
import { FollowOrderStatus, OrderSearchInput, OrderSortInput } from 'graphql/types';
import { PATH_NAMES } from 'Header/menus';
import { useHistory } from 'react-router-dom';
import { formatDateByDDMMYYYY } from 'utils/normalizer';
import colors from 'app/theme/colors.scss';
import useOrdersWithPaginQuery from './hooks/useOrdersWithPaginQuery';
import { FollowStatusListMap, OrderCockpitFollowStatus } from './definition';
import { getMapStatusValueFilter } from './helpers';

const set = require('lodash.set');

interface StatusChipProps {
	status?: keyof typeof OrderCockpitFollowStatus;
}

interface OrdersListProps {
	isOrderCockpit?: boolean;
}

const statusChipStyle = (status: keyof typeof FollowOrderStatus) => {
	switch (status) {
		case OrderCockpitFollowStatus.Delivered:
			return {
				background: colors.green100,
				color: colors.blue900,
			};
		case OrderCockpitFollowStatus.Validated:
			return {
				background: colors.blue200,
				color: colors.blue900,
			};

		case OrderCockpitFollowStatus.Billed:
			return {
				background: colors.green100,
				color: colors.blue900,
			};
		case OrderCockpitFollowStatus.DeliveryInProgress:
			return {
				background: colors.blue200,
				color: colors.blue900,
			};
		case OrderCockpitFollowStatus.ProductionInProgress:
			return {
				background: colors.blue200,
				color: colors.blue900,
			};
		case OrderCockpitFollowStatus.Refused:
			return {
				background: colors.red100,
				color: colors.red500,
			};
		case OrderCockpitFollowStatus.WaitingValidation:
			return {
				background: colors.yellow100,
				color: colors.red500,
			};
	}
};

const StatusChip = ({ status }: StatusChipProps) => {
	if (!status) {
		return <span>-</span>;
	}
	return <Chip label={FollowStatusListMap[status]} style={{ ...statusChipStyle(status) }} />;
};

const OrdersList = ({ isOrderCockpit }: OrdersListProps) => {
	const history = useHistory();
	const [filters, setFilters] = useState<OrderSearchInput>();
	const [sort, setSort] = useState<OrderSortInput>({});

	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const pagination = { take: pageSize, skip: pageSize * page };

	const { orders, total, loading } = useOrdersWithPaginQuery(filters, sort, pagination, isOrderCockpit);

	const columns: GridColDef[] = React.useMemo(
		() => [
			{
				headerName: 'N° de commande',
				field: 'id',
				flex: 1,
				disableColumnMenu: true,
			},
			{
				headerName: 'Date de création',
				field: 'createdAt',
				flex: 1,
				type: 'date',
				filterOperators: getGridDateOperators().filter((operator) =>
					['onOrAfter', 'onOrBefore'].includes(operator.value)
				),
				valueGetter: ({ row }: GridValueGetterParams) => formatDateByDDMMYYYY(row.createdAt),
				disableColumnMenu: true,
			},
			{
				headerName: 'N° Réf OS',
				field: 'fileReference.id',
				flex: 1,

				valueGetter: ({ row }: GridValueGetterParams) => row?.fileReference?.id,
				disableColumnMenu: true,
			},
			{
				headerName: 'Client',
				field: 'client.name',
				flex: 1,
				disableColumnMenu: true,
				valueGetter: ({ row }: GridValueGetterParams) => row?.client.name,
			},
			{
				headerName: 'Demandé par',
				field: 'fileReference.askBy',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row?.fileReference?.askBy,
				disableColumnMenu: true,
			},
			{
				headerName: "Nom de l'opération",
				field: 'fileReference.operationName',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row?.fileReference?.operationName,
				disableColumnMenu: true,
			},
			{
				headerName: 'Réf client',
				field: 'fileReference.clientReference',
				flex: 1,
				valueGetter: ({ row }: GridValueGetterParams) => row?.fileReference?.clientReference,
				disableColumnMenu: true,
			},
			{
				headerName: 'Statut de suivi',
				field: 'followStatus',
				flex: 1,
				type: 'singleSelect',
				valueOptions: () => {
					return Object.values(FollowStatusListMap);
				},
				renderCell: ({ value }) => {
					return <StatusChip status={value} />;
				},
				disableColumnMenu: true,
			},
			{
				headerName: 'Nb de produits',
				field: 'nbProducts',
				flex: 1,
				sortable: false,
				valueGetter: ({ row }: GridValueGetterParams) =>
					row.orderItem?.reduce((total: number, item: { quantity: number }) => total + item.quantity, 0),
				disableColumnMenu: true,
			},
		],
		[]
	);
	const CustomToolbar = () => (
		<GridToolbarContainer>
			<GridToolbarFilterButton />
			<GridToolbarColumnsButton />
		</GridToolbarContainer>
	);

	const handleRowClick = ({ row }: GridRowParams) => {
		if (!row.id) return;
		if (!isOrderCockpit) {
			history.push(`/orders/${row.id}`);
		} else {
			history.push(`/orderCockpit/${row.id}`);
		}
	};

	const handleClickOrderCreate = () => {
		history.push(`/orders/add`);
	};

	const onSortChange = (sortModel: GridSortModel) => {
		const sorts = sortModel.reduce((acc, sort) => {
			set(acc, sort.field, sort.sort);
			return acc;
		}, {});
		setSort(sorts);
	};

	const onFilterChange = (filterModel: GridFilterModel) => {
		const filters = filterModel.items.reduce((acc: Record<string, string>, filter) => {
			switch (filter.operatorValue) {
				case 'contains':
					set(acc, filter.columnField, { contains: filter.value });
					break;
				case 'onOrAfter':
					set(acc, filter.columnField, { gte: filter.value });
					break;
				case 'onOrBefore':
					set(acc, filter.columnField, { lte: filter.value });
					break;

				default:
					set(
						acc,
						filter.columnField,
						filter.columnField === 'followStatus' ? getMapStatusValueFilter(filter.value) : filter.value
					);
					break;
			}

			return acc;
		}, {});

		setFilters(filters);
	};

	return (
		<Box mx={7}>
			<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
				<Box>
					<Path paths={[isOrderCockpit ? PATH_NAMES.orderCockpit : PATH_NAMES.orders]} />
				</Box>
				{!isOrderCockpit && (
					<Box>
						<Button variant="outlined" size="small" color="primary" onClick={handleClickOrderCreate}>
							Créer une commande
						</Button>
					</Box>
				)}
			</Box>
			<Divider />
			<Box mt={2}>
				<Box mt={2} sx={{ height: '75vh', width: '100%' }}>
					<DataGrid
						sx={{ fontSize: '0.835rem' }}
						columns={columns}
						loading={!orders && loading}
						rows={orders ?? []}
						onRowClick={handleRowClick}
						filterMode="server"
						onFilterModelChange={onFilterChange}
						sortingMode="server"
						onSortModelChange={onSortChange}
						getRowClassName={() => 'rowLeftBorder'}
						components={{
							Toolbar: CustomToolbar,
						}}
						rowsPerPageOptions={[10, 25, 50, 100]}
						pagination={true}
						page={page}
						pageSize={pageSize}
						rowCount={total || 0}
						paginationMode="server"
						onPageChange={setPage}
						onPageSizeChange={setPageSize}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default OrdersList;
