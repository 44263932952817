export const UNAUTHORIZED = 401;

const getErrorType = (type: string) => {
	switch (type) {
		case 'UNAUTHORIZED':
			return {
				message: 'Unauthenticated user.',
				statusCode: 401,
			};
		case 'SERVER_ERROR':
			return {
				message: 'Server error.',
				statusCode: 500,
			};
		default:
			return type;
	}
};

export default getErrorType;
