import React, { useMemo } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import FamilyAttributes from 'admin/support/FamilyAttributes';
import { ConditionnemntFields } from './ConditionnemntFields';
import { ProductFromModel } from 'product/definitions';
import { removeDuplicatesAndSort } from 'utils/arrays';
import { ProductFilter } from 'context/pricesProductContext/definition';
import { getProductsAttributesByFamily } from 'product/helpers';
import {
	CONDITIONENEMENT,
	conditionnementFamily,
	ConditionnementType,
	LABELIING,
	NB_EX_CONDITIONENEMENT,
} from './definition';
import { AttributeValuesType, EMPTY_STRING } from 'admin/support/definitions';
import { sum } from 'ramda';

interface conditionnementProps {
	isClariPrint?: boolean;
	isProductPage?: boolean;
	products: ProductFromModel[];
	setProductFilter?: (productFilter?: ProductFilter | null | undefined) => void;
	productFilter?: ProductFilter | null;
	printValues?: (string | null | undefined)[] | undefined;
	productsAttributesByFamily?: { family: string | null | undefined; data: AttributeValuesType[] }[];
	conditionnement: ConditionnementType;
	setConditionnement: React.Dispatch<React.SetStateAction<ConditionnementType>>;
	numberOfLots: number;
	setNumberOfLots: React.Dispatch<React.SetStateAction<number>>;
}

export const Conditionnement = ({
	isClariPrint,
	products,
	productsAttributesByFamily,
	setProductFilter,
	productFilter,
	printValues,
	isProductPage,
	conditionnement,
	setConditionnement,
	numberOfLots,
	setNumberOfLots,
}: conditionnementProps): JSX.Element => {
	const { productsConditionnement } = useMemo(() => getProductsAttributesByFamily(products, productFilter), [
		products,
		productFilter,
	]);
	const conditionnementList = Array.from({ length: Number(numberOfLots) }, (_, i) => i - i);

	const conditionnementOptions = (field: 'conditionnement' | 'nbExConditionnement' | 'labeling', index: number) => {
		const conditionnement = () => {
			switch (index) {
				case 0:
					return 'firstConditionnement';
				case 1:
					return 'secondConditionnement';
				default:
					return 'threeConditionnement';
			}
		};

		if (field === LABELIING)
			return removeDuplicatesAndSort(
				productsConditionnement?.map((item) =>
					item && item[conditionnement()]?.labeling === true
						? 'Oui'
						: item && item[conditionnement()]?.labeling === false
						? 'Non'
						: EMPTY_STRING
				)
			);
		else if (field === NB_EX_CONDITIONENEMENT)
			return removeDuplicatesAndSort(
				productsConditionnement?.map((item) => item && (item[conditionnement()]?.nbExConditionnement ?? EMPTY_STRING)),
				true
			);
		else {
			return removeDuplicatesAndSort(
				productsConditionnement?.map((item) => item && (item[conditionnement()]?.conditionnement ?? EMPTY_STRING))
			);
		}
	};

	const total = useMemo(() => {
		return sum(conditionnement.conditionnementList.map((conditionnement) => conditionnement.numberOfExLots ?? 0));
	}, [conditionnement]);

	const onChangeNbrLot = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNumberOfLots(parseInt(e.target.value as string));
		const newConditionnement = {
			numberOfLots: parseInt(e.target.value as string),
			total: null,
			conditionnementList: Array.from({ length: Number(parseInt(e.target.value as string)) }, () => ({
				numberOfExLots: null,
				firstConditionnement: {
					labeling: false,
					conditionnement: null,
					nbExConditionnement: null,
				},
				secondConditionnement: {
					labeling: false,
					conditionnement: null,
					nbExConditionnement: null,
				},
				threeConditionnement: {
					labeling: false,
					conditionnement: null,
					nbExConditionnement: null,
				},
			})),
		};

		setConditionnement(newConditionnement);
		setProductFilter && setProductFilter({ ...productFilter, conditionnement: { ...newConditionnement } });
	};

	const onChangeNbrExLot = (event: number | null, index: number) => {
		let newConditionnement = { ...conditionnement };
		const total =
			sum(newConditionnement.conditionnementList.map((conditionnement) => conditionnement.numberOfExLots ?? 0)) -
			(newConditionnement?.conditionnementList[index].numberOfExLots ?? 0) +
			(event ?? 0);
		newConditionnement.conditionnementList[index].numberOfExLots = event;
		newConditionnement.total = total;
		setConditionnement(newConditionnement);
		if (setProductFilter) {
			setProductFilter({ ...productFilter, conditionnement: { ...newConditionnement } });
		}
	};

	return (
		<>
			<Box display="flex" width="100%">
				<Box width="10%" mr={2}>
					<Typography variant="h4">Nombre de lots</Typography>
					<TextField
						data-testid="number-of-lots"
						type="number"
						value={numberOfLots ?? 1}
						onChange={onChangeNbrLot}
						fullWidth
						inputProps={{ min: 1 }}
						variant="outlined"
						disabled={!isClariPrint ?? true}
					/>

					{isClariPrint && conditionnement.numberOfLots > 1 && (
						<>
							{conditionnementList.map((_, index) => (
								<Box key={index} pt={2}>
									<Typography variant="h4">Nombre d'ex lot {index + 1}</Typography>
									<TextField
										data-testid="number-of-lots-clari-print"
										value={String(conditionnement.conditionnementList[index].numberOfExLots)}
										fullWidth
										inputProps={{ min: 1 }}
										type="number"
										onChange={(event) =>
											onChangeNbrExLot(
												!isNaN(parseInt(event.target.value)) ? parseInt(event.target.value as string) : null,
												index
											)
										}
										variant="outlined"
									/>
								</Box>
							))}
							<Box pt={2}>
								<Typography variant="h4">Total</Typography>
								<TextField data-testid="total" fullWidth value={total} disabled variant="outlined" />
							</Box>
						</>
					)}
				</Box>

				{conditionnementFamily.map((family, indexConditionnementFamily) => (
					<Box key={family.key} width="30%" mr={2}>
						<FamilyAttributes family={family.value} />
						{conditionnementList.map((_, index) => (
							<Box key={index} display="flex" width="100%">
								<ConditionnemntFields
									productFilter={productFilter}
									indexConditionnementFamily={indexConditionnementFamily + 1}
									index={index}
									setProductFilter={setProductFilter}
									nbExConditionnement={conditionnementOptions(NB_EX_CONDITIONENEMENT, indexConditionnementFamily)}
									conditionnementName={conditionnementOptions(CONDITIONENEMENT, indexConditionnementFamily)}
									labeling={conditionnementOptions(LABELIING, indexConditionnementFamily)}
									isClariPrint={isClariPrint}
									printValues={printValues}
									conditionnement={conditionnement}
									setConditionnement={setConditionnement}
									family={family}
									productsAttributesByFamily={productsAttributesByFamily}
									isProductPage={isProductPage}
								/>
							</Box>
						))}
					</Box>
				))}
			</Box>
		</>
	);
};
