import React, { useMemo } from 'react';
import { Column, useRowSelect, useTable } from 'react-table';
import { Typography } from '@mui/material';
import { OrderItem } from '../../graphql/types';
import { formatNumberDisplay } from '../../utils/prices';

export const useDeliveryInformationTable = (order: OrderItem) => {
	const data = useMemo(
		() => [
			{
				name: order?.shippingAddress?.name ?? '',
				firstAddress: order.shippingAddress?.firstAddress ?? '',
				secondAddress: order.shippingAddress?.secondAddress ?? '',
				thirdAddress: order.shippingAddress?.thirdAddress ?? '',
				zipCode: order.shippingAddress?.zipCode ?? '',
				city: order.shippingAddress?.city ?? '',
				quantity: order.quantity,
				weight: order.weight ?? null,
				/**
				 * @todo à revoir une fois le conditionnement fixé
				 * conditionnement: order.product.conditionnement ?? '',
				 */
				conditionnement: 'conditionnement',
			},
		],
		[order]
	);

	const columns = useMemo<
		Column<{
			name: string;
			firstAddress: string;
			secondAddress: string;
			thirdAddress: string;
			zipCode: string;
			city: string;
			quantity: number;
			weight: number | null;
			conditionnement: string;
		}>[]
	>(
		() => [
			{
				accessor: 'name',
				Header: () => <Typography>Magasin</Typography>,
				Cell: ({ row }) => <Typography>{row.original.name}</Typography>,
			},
			{
				accessor: 'firstAddress',
				Header: () => <Typography>Adresse 1</Typography>,
				Cell: ({ row }) => <Typography>{row.original.firstAddress}</Typography>,
			},
			{
				accessor: 'secondAddress',
				Header: () => <Typography>Adresse 2</Typography>,
				Cell: ({ row }) => <Typography>{row.original.secondAddress}</Typography>,
			},
			{
				accessor: 'thirdAddress',
				Header: () => <Typography>Adresse 3</Typography>,
				Cell: ({ row }) => <Typography>{row.original.thirdAddress}</Typography>,
			},
			{
				accessor: 'zipCode',
				Header: () => <Typography>Code postal</Typography>,
				Cell: ({ row }) => <Typography>{row.original.zipCode}</Typography>,
			},
			{
				accessor: 'city',
				Header: () => <Typography>Ville</Typography>,
				Cell: ({ row }) => <Typography>{row.original.city}</Typography>,
			},
			{
				accessor: 'quantity',
				Header: () => <Typography>Quantité</Typography>,
				Cell: ({ row }) => <Typography>{formatNumberDisplay(row.original.quantity)}</Typography>,
			},
			{
				accessor: 'weight',
				Header: () => <Typography>Poids (Kg)</Typography>,
				Cell: ({ row }) => <Typography>{formatNumberDisplay(row.original.weight)}</Typography>,
			},
			{
				accessor: 'conditionnement',
				Header: () => <Typography>Conditionnement</Typography>,
				Cell: ({ row }) => <Typography>{row.original.conditionnement}</Typography>,
			},
		],
		[]
	);

	return useTable(
		{
			columns,
			data,
		},
		useRowSelect
	);
};
