import React from 'react';
import { FormikNumberField } from 'components/form/FormikNumberField';
import { TransporterType } from '../../definitions';

const TransporterPricesCellField = (
	columnIndex: number,
	transporterIndex: number,
	transporterType: TransporterType,
	isActif: boolean
): JSX.Element => {
	const fieldName =
		transporterType === TransporterType.standardDelivery
			? `transporters[${transporterIndex}].standardDeliveryPrices[${columnIndex}]`
			: `transporters[${transporterIndex}].expressDeliveryPrices[${columnIndex}]`;

	return <FormikNumberField decimalScale={5} name={fieldName} width="90px" disabled={!isActif} />;
};

export default TransporterPricesCellField;
