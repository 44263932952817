import { gql } from '@apollo/client';
import CONTACT_FRAGMENT from '../../graphql/fragments/contact/contactFragment';
import ADDRESS_FRAGMENT from '../../graphql/fragments/address/addressFragment';

export const GET_CLIENTS = gql`
	query GetClients($filter: ClientFilterInput!) {
		clients(filter: $filter) {
			id
			name
			isRoot
			parentClientId
			rootClientId
			type
			regionId
			typologyId
			isActif
		}
	}
`;

export const GET_CLIENT_BY_ID = gql`
	query GetClientById($id: String!) {
		client(id: $id) {
			id
			name
			email
			isRoot
			isProspect
			isActif
			companyBase
			tradeNameInvoice
			transplantName
			siretNumber
			vatNumber
			vatType
			type
			regionId
			rcsNumber
			typologyId
			parentClientId
			rootClientId
			createdAt
			updatedAt
			commercialId
			responsibleId
			projectManagerId
			clientAccountingAccount
			clientAnalyticCode
			groupingCode
			clientCode
			fileCode
			logo
			territorialArea
			clientShippingAddress {
				boxShippingMode
				comment
				referenceId
				palletShippingMode
				maxBoxWeight
				isMain
				id
				clientId
				address {
					...Address
				}
				contact {
					directorName
					email
					fax
					isNotificated
					phone
					storeCode
					website
				}
				workingDays {
					day
					end
					isWorkingDay
					start
				}
			}
			paymentDeadline
			users {
				commercial {
					fullName
					initials
				}
				responsable {
					fullName
					initials
				}
			}
			billingAddress {
				...Address
			}

			structures {
				isBillable
				level
				title
				type
			}
			regions {
				id
				name
			}
			typologies {
				id
				name
			}
			clientsSupports {
				margin
				supportId
				marginType
				support {
					id
					name
					excludingEcotax
				}
			}
			clientContact {
				...Contact
			}
			billingCadence
			possibleBillings
			defaultBilling
			isGroupedBilling
			isHorsRFA
			mainRfaRate
			rfaComment
			defaultCommercialProductMargin
			defaultCommercialShippingMargin
			rootClientId
		}
	}
	${ADDRESS_FRAGMENT}
	${CONTACT_FRAGMENT}
`;

export const CHILDREN_FIELDS = gql`
	fragment Client_Fields on ClientChildren {
		name
		id
		type
		isActif
		fileCode
	}
`;

export const CLIENT_CHILDREN_FRAGMENT = gql`
	fragment Client_Children_Fragment on ClientChildren {
		...Client_Fields
		children {
			...Client_Fields
			children {
				...Client_Fields
				children {
					...Client_Fields
					children {
						...Client_Fields
						children {
							...Client_Fields
							children {
								...Client_Fields
								children {
									...Client_Fields
									children {
										...Client_Fields
										children {
											...Client_Fields
											children {
												...Client_Fields
												children {
													...Client_Fields
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	${CHILDREN_FIELDS}
`;

export const GET_CLIENT_SHIPPING_ADDRESS = gql`
	query GetClientShippingAddress($clientId: String) {
		clientShippingAddress(clientId: $clientId) {
			address {
				...Address
			}
			contact {
				directorName
				email
				isNotificated
				fax
				phone
				storeCode
				website
			}
			workingDays {
				day
				end
				isWorkingDay
				start
			}
			id
			clientId
			comment
			boxShippingMode
			isMain
			maxBoxWeight
			palletShippingMode
			referenceId
		}
	}
	${ADDRESS_FRAGMENT}
`;

export const GET_CLIENT_CHILDREN = gql`
	query children($id: String!) {
		clientChildren(id: $id) {
			...Client_Children_Fragment
		}
	}
	${CLIENT_CHILDREN_FRAGMENT}
`;

export const GET_CLIENTS_BY_CURRENT_USER = gql`
	query CurrentUserClients($hasChildren: Boolean!) {
		clientsUsers: currentUserClients(hasChildren: $hasChildren) {
			defaultCommercialProductMargin
			defaultCommercialShippingMargin
			id
			mainRfaRate
			possibleBillingsClients {
				id
				name
				type
				defaultBilling
				billingAddress {
					...Address
				}
			}
			name
			elpevSite
			translation
			possibleBillings
			defaultBilling
			clientContact {
				...Contact
			}
			billingAddress {
				...Address
			}
			clientShippingAddress {
				id
				address {
					deliveryDepartment
					zipCode
					name
					id
					city
					isShipping
				}
				boxShippingMode
				maxBoxWeight
				isMain
			}
		}
	}
	${CONTACT_FRAGMENT}
	${ADDRESS_FRAGMENT}
`;

export const GET_POSSIBLE_BILLINGS_CLIENTS = gql`
	query GetPossibleBillingsClients($id: String!) {
		possibleBillingsClients(id: $id) {
			id
			name
			type
			vatNumber
			defaultBilling
			elpevSite
			mainRfaRate
			billingAddress {
				...Address
			}
		}
	}

	${ADDRESS_FRAGMENT}
`;

export const GET_CLIENT_AND_All_PARENTS = gql`
	query GetClientAndAllParents($id: String!) {
		clientAndAllParents(id: $id) {
			id
			clientAnalyticCode
		}
	}
`;

export const GET_CLIENT_AND_All_CHILDRENS = gql`
	query GetClientAndAllChildrens($id: String!) {
		clientAndAllChildrens(id: $id) {
			id
			name
		}
	}
`;

export const GET_CLIENT_AND_CHILDRENDS_WITH_SHIPPING_ADDRESS = gql`
	query GetClientAndAllChildrensWithShippingAdress($id: String!) {
		clientAndAllChildrens(id: $id) {
			id
			name
			clientShippingAddress {
				id
				address {
					...Address
				}
				contact {
					directorName
					email
					fax
					isNotificated
					phone
					storeCode
					website
				}
			}
		}
	}
	${ADDRESS_FRAGMENT}
`;
