import React from 'react';
import { Box } from '@mui/material';
import { InvoiceForm } from './form/InvoiceForm';
import useInvoiceByIdQuery from './hooks/useInvoiceByIdQuery';
import { useParams } from 'react-router-dom';
import { InvoiceModel } from './definitions';
import { useSnackbar } from 'notistack';
import { PATH_NAMES } from '../Header/menus';
import Loading from '../components/loading/Loading';
import useGenerateOrderInvoicePdf from '../order/hooks/useGenerateOrderInvoicePdf';
import { useValidateInvoice } from './hooks/useValidateInvoice';
import { typeNotifications } from '../utils/definition';
import { InvoiceOrderItemInput } from 'graphql/types';
import { Variant } from 'hooks/useManualTabs';

export const InvoiceRoute = (): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { id: invoiceId } = useParams<{ id: string }>();
	const { invoice, loading } = useInvoiceByIdQuery(invoiceId);
	const { validateInvoice } = useValidateInvoice();
	const { generateOrderInvoicePdf } = useGenerateOrderInvoicePdf();
	const headlines = [
		{
			title: ``,
			fontSize: 'h2' as Variant,
		},
	];
	const paths = [
		{ name: PATH_NAMES.Invoices, href: '/to-validate-invoices-list' },

		invoiceId.startsWith('PFAC')
			? `A valider  ${invoice?.order.clientName}/${invoice?.order.parentOrderId}`
			: invoiceId,
	];

	const onSubmit = async (invoice: InvoiceModel) => {
		try {
			const invoiceOrderItems: InvoiceOrderItemInput[] = invoice.order.orderItem.map((item: InvoiceOrderItemInput) => ({
				id: item.id,
				realProductMargin: item.realProductMargin,
				realTransportMargin: item.realTransportMargin,
				isWithRfaProduct: item.isWithRfaProduct,
				isWithRfaTransport: item.isWithRfaTransport,
			}));
			const validatedInvoice = await validateInvoice(invoice.id, invoiceOrderItems);

			invoice.order?.id &&
				validatedInvoice &&
				(await generateOrderInvoicePdf(
					invoice.order.id,
					validatedInvoice.id,
					invoice.order.parentOrderId ?? invoice.order.id
				));
			enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
		} catch (error) {
			enqueueSnackbar('Erreur', { variant: typeNotifications.Error });
		}
	};

	if (!invoice) return <Loading loading={loading} />;

	return (
		<Box mx={7}>
			<InvoiceForm headlines={headlines} onSubmit={onSubmit} invoice={invoice} paths={paths} />
		</Box>
	);
};
