import { gql } from '@apollo/client';

export const INSERT_USER = gql`
	mutation InsertUser($data: UserCreateInput!) {
		createUser(data: $data) {
			id
			email
			familyName
			givenName
			replacedFrom
			replacedTo
		}
	}
`;

export const UPDATE_USER_BY_ID = gql`
	mutation UpdateUser($userId: String!, $data: UserUpdateInput!) {
		updateUser(data: $data, userId: $userId) {
			id
			email
			familyName
			givenName
			canViewSalePrices
			canViewBuyingPrices
			phoneNumber
			canViewSalePrices
			canViewBuyingPrices
		}
	}
`;
