import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, FormControl, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DashedHeader from '../../../components/DashedHeader';
import { LayoutField } from '../../../components/form/LayoutField';
import { useFormikContext } from 'formik';
import FormikSelectField from '../../../components/form/FormikSelectField';
import usePossibleBillingsClientsQuery from '../../../clients/hooks/usePossibleBillingsClientsQuery';
import { ClientQuoteModel, clientsUsersProps, VatOptions } from '../definition';
import colors from '../../../app/theme/colors.scss';
import Loading from 'components/loading/Loading';
import { initialAddClientPriceToQuotes } from '../adding-client-price-quote/components/definitions';
import SelectDataClientQuote from '../component/selectDataClientQuote';
import { useGetProductsByClientId } from '../../hooks/useGetProductsByClientId';
import FileReference from './FileReference';
import AddFileReference from '../../../file-reference/add-file-reference-popin/AddFileReference';
import { ClientPriceStatus, QuoteType, Client } from 'graphql/types';
import FormikAutocompleteField from 'components/form/FormikAutocompleteField';
import { isString } from 'utils/typeGuards';
import { HandleSelectClient } from '../../definitions';
import usePrevious from 'hooks/usePrevious';

type ClientType = Pick<Client, 'id' | 'name'>;

const ClientInformation = ({ clientsUsers, loadingClientsUsers }: clientsUsersProps): JSX.Element => {
	const { setFieldValue, values } = useFormikContext<ClientQuoteModel>();
	const { possibleBillingClients, getPossibleBillingClients, loading } = usePossibleBillingsClientsQuery(clientsUsers);
	const { getClientPricesByClientId } = useGetProductsByClientId();

	const selectedBillingAddress = useMemo(
		() =>
			possibleBillingClients?.find((possibleBillingClient) => possibleBillingClient?.id === values?.client)
				?.billingAddress,
		[possibleBillingClients, values]
	);

	const hasStatusFilter = useMemo<ClientPriceStatus | undefined>(() => {
		if (values.type === QuoteType.ClientOrderToBeCreate) return ClientPriceStatus.ValidatedByCustomerQuote;
		else if (values.type === QuoteType.ClientPriceToBeValidate) return ClientPriceStatus.InProgress;
		else return undefined;
	}, [values]);

	const previousClientId = usePrevious(values.clientId);

	useEffect(() => {
		if (values.client) {
			setFieldValue('billingAddress.name', selectedBillingAddress?.name);
			setFieldValue('billingAddress.firstAddress', selectedBillingAddress?.firstAddress);
			setFieldValue('billingAddress.zipCode', selectedBillingAddress?.zipCode);
			setFieldValue('billingAddress.city', selectedBillingAddress?.city);
			setFieldValue('billingAddress.country', selectedBillingAddress?.country);
		}
	}, [values.client, setFieldValue, selectedBillingAddress]);

	useEffect(() => {
		(async () => {
			if (values.clientId && values.clientId !== previousClientId) {
				await getPossibleBillingClients({
					variables: {
						id: values.clientId,
					},
				});

				await getClientPricesByClientId({
					variables: {
						filter: { clientId: values.clientId, status: hasStatusFilter },
					},
				});
			}
			if (possibleBillingClients) {
				setFieldValue('clients', possibleBillingClients);
			}
		})();
	}, [
		values,
		getPossibleBillingClients,
		possibleBillingClients,
		setFieldValue,
		getClientPricesByClientId,
		hasStatusFilter,
		previousClientId,
	]);

	const handleSelectClient: HandleSelectClient = useCallback(
		async (value) => {
			const clientId = value?.id;

			setFieldValue('clientId', clientId);
			setFieldValue('clientName', value?.name);

			await getPossibleBillingClients({
				variables: {
					id: clientId as string,
				},
			});

			clientId &&
				(await getClientPricesByClientId({
					variables: {
						filter: {
							clientId,
							status: hasStatusFilter,
						},
					},
				}));

			setFieldValue('selectedProduct.productName', '');
			(Object.keys(initialAddClientPriceToQuotes) as Array<keyof typeof initialAddClientPriceToQuotes>).forEach(
				(key) => {
					setFieldValue(key, initialAddClientPriceToQuotes[key]);
				}
			);
		},
		[getClientPricesByClientId, getPossibleBillingClients, hasStatusFilter, setFieldValue]
	);

	const handleSelectBillingClient = (value: Pick<Client, 'name' | 'id'> | null) => {
		setFieldValue('client', value?.id);
		setFieldValue('clientName', value?.name);
	};

	return (
		<Box mb={5} pt={5}>
			<Box mb={6}>
				<Grid container>
					<Grid item xs={1} />
					<Grid item>
						<Typography variant="h2">Informations client</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box mb={7}>
				<Grid container>
					<Grid item xs={2} />
					<Grid item xs={3}>
						<DashedHeader>Nom et contact</DashedHeader>

						<SelectDataClientQuote
							handleSelectClient={handleSelectClient}
							clientsUsers={clientsUsers}
							loadingClientsUsers={loadingClientsUsers}
							id={values.id}
							clientId={values.clientId}
						/>

						<Box mb={3} mt={3} width={'100%'}>
							<FileReference />
						</Box>
					</Grid>
					<Grid item xs={2}>
						<Box mt={22.5}>
							<AddFileReference clientId={values.clientId} isExternalCreation={true} />
						</Box>
					</Grid>

					<Grid item xs={3}>
						<DashedHeader>Adresse de facturation</DashedHeader>
						<Box mb={3}>
							<LayoutField label="Client à facturer">
								<FormikAutocompleteField<ClientType>
									name="client"
									options={
										possibleBillingClients?.map(({ name, id }) => ({
											id,
											name,
										})) ?? []
									}
									value={{ id: values?.clientId as string, name: values?.clientName as string }}
									textField={{ size: 'small' }}
									getOptionLabel={(option) => (option as ClientType).name ?? ''}
									onChange={(_: any, value) => {
										if (!isString(value)) {
											handleSelectBillingClient(value);
										}
									}}
								/>
							</LayoutField>
						</Box>
						<Box mb={3}>
							{!loading ? (
								<>
									<Typography variant="h4">{values.billingAddress?.name}</Typography>
									<Typography variant="h5">{values.billingAddress?.firstAddress}</Typography>
									<Typography variant="h5">
										{values.billingAddress?.zipCode} {values.billingAddress?.city}
									</Typography>
									<Typography variant="h5">{values.billingAddress?.country}</Typography>
								</>
							) : (
								<Box position="relative" height="200px" display="flex" alignItems="center" justifyContent="center">
									<Loading loading={loading} />
								</Box>
							)}
						</Box>
						<DashedHeader>Facturation</DashedHeader>
						<Box mb={3}>
							<LayoutField label="TVA">
								<FormControl fullWidth>
									<FormikSelectField name={'vat'} variant="outlined">
										{Object.keys(VatOptions).map((option) => (
											<MenuItem value={option} key={option}>
												{option}
											</MenuItem>
										))}
									</FormikSelectField>
								</FormControl>
							</LayoutField>
						</Box>
						<LayoutField label="Taux de TVA en %">
							<Box mb={3} width="83px" bgcolor={colors.grey} display="flex">
								<TextField value={VatOptions[values.vat]} variant="outlined" fullWidth disabled />
							</Box>
						</LayoutField>
					</Grid>
					<Grid item xs={2} />
				</Grid>
			</Box>
		</Box>
	);
};

export default ClientInformation;
