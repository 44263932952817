import { useQuery } from '@apollo/client';
import { ProductQuery, ProductQueryVariables } from '../graphql/queries.definitions';
import { GET_PRODUCT_BY_ID } from '../graphql/queries';

const useGetProductById = (id: string) => {
	const { data, loading } = useQuery<ProductQuery, ProductQueryVariables>(GET_PRODUCT_BY_ID, {
		variables: {
			id,
		},
	});

	return { product: data?.product, loading };
};

export default useGetProductById;
