import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormikTextField from '../components/form/FormikTextField';
import { LayoutField } from '../components/form/LayoutField';
import { AuthContext } from '../context/Auth';
import { LoginInput, ProjectName } from '../graphql/types';
import { useLogin } from './hooks/useLogin';
import { getAuthFormSchema } from './validation/schema';

const initialLogInput: LoginInput = { email: '', password: '', projectName: ProjectName.OneSupply };

export const LoginForm = () => {
	const loginUser = useLogin();
	const history = useHistory();
	const [isLogin, setIsLogin] = useState<boolean>(true);
	const [isShowPassword, setIsShowPassword] = useState<boolean>(true);
	const authContext = useContext(AuthContext);

	const handleLogin = async (logInput: LoginInput) => {
		try {
			const result = await loginUser(logInput);

			authContext?.login(result.data?.login.accessToken);

			history.push('/');

			return result;
		} catch (error) {
			setIsLogin(false);
		}
	};

	const handleMouseDownPassword = (event: React.MouseEvent) => {
		event.preventDefault();
	};

	const handleClickShowPassword = () => {
		setIsShowPassword(!isShowPassword);
	};

	return (
		<Formik<LoginInput>
			validationSchema={getAuthFormSchema}
			initialValues={initialLogInput}
			onSubmit={(values) => handleLogin(values)}
		>
			<Form>
				<Card style={{ alignContent: 'center' }}>
					<Box my={4} mx={14}>
						<Typography variant="h1">Connectez-vous</Typography>
					</Box>
					<Divider />

					<Box p={4}>
						<Box>
							<LayoutField label="Nom d'utilisateur">
								<FormikTextField
									fullWidth
									name="email"
									variant="outlined"
									type="text"
									onClick={() => setIsLogin(true)}
								/>
							</LayoutField>
						</Box>
						<Box mt={3}>
							<LayoutField label="Mot de passe">
								<FormikTextField
									fullWidth
									name="password"
									variant="outlined"
									type={isShowPassword ? 'password' : 'text'}
									onClick={() => setIsLogin(true)}
									inputProps={{
										autoComplete: 'on',
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													size="large"
												>
													{isShowPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</LayoutField>
						</Box>

						<Box textAlign="center" height="30px" mt={1}>
							{!isLogin && (
								<Typography variant="body1" color="error">
									Email ou mot de passe incorrect
								</Typography>
							)}
						</Box>

						<Box mr={4}>
							<Button variant="contained" color="primary" type="submit" fullWidth>
								Se connecter
							</Button>
						</Box>
					</Box>
				</Card>
			</Form>
		</Formik>
	);
};
