import { useQuery } from '@apollo/client';
import { GET_CLIENT_AND_All_PARENTS } from '../graphql/queries';
import { GetClientAndAllParentsQuery, GetClientAndAllParentsQueryVariables } from '../graphql/queries.definitions';

const useClientAndAllParentsQuery = (id: string) => {
	const { data, loading } = useQuery<GetClientAndAllParentsQuery, GetClientAndAllParentsQueryVariables>(
		GET_CLIENT_AND_All_PARENTS,
		{
			variables: {
				id,
			},
		}
	);
	return { clients: data?.clientAndAllParents, loading };
};

export default useClientAndAllParentsQuery;
