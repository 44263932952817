import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LayoutFieldForm from '../../../components/form/LayoutFieldForm';
import DateLayoutField from '../../../components/form/DateLayoutField';
import SupplierElpevFromBaseDB from '../../components/SupplierElpevFromBaseDB';

interface SupplierElpevInformationProps {
	title: string;
}

const SupplierElpevInformation = ({ title }: SupplierElpevInformationProps): JSX.Element => (
	<Box my={5}>
		<Box mb={6}>
			<Grid container>
				<Grid item xs={1} />
				<SupplierElpevFromBaseDB title={title} />
			</Grid>
		</Box>
		<Grid container justifyContent="space-between">
			<Grid item xs={2} />
			<Grid item xs={3}>
				<DateLayoutField label="Crée le" name="createdAt" disabled width="164px" />
				<DateLayoutField label="Modifié le" name="updatedAt" disabled width="164px" />
				<LayoutFieldForm label="Modifié par" name="updatedBy" disabled />
			</Grid>
			<Grid item xs={7} />
		</Grid>
	</Box>
);

export default memo(SupplierElpevInformation);
