import React from 'react';

type PermissionContextType = {
	isAllowedTo: (permission: string) => boolean;
};

const defaultBehaviour: PermissionContextType = {
	isAllowedTo: () => false,
};

const PermissionContext = React.createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
