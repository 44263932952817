import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { memo } from 'react';
import EventIcon from '@mui/icons-material/Event';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import { useField } from 'formik';
import StatusInformationPopin from './StatusInformationPopin';
import { LayoutField } from '../../../../components/form/LayoutField';
import {FollowOrderStatus, OrderItem, OrderProductStatus} from '../../../../graphql/types';
import colors from '../../../../app/theme/colors.scss';
import { FollowStatusListDisabled, FollowStatusListNotDisabled } from './definitions';

const OrderGeneralInformation = () => {
	const [{ value: followStatus }, , helpers] = useField<string>('followStatus');
	const [{ value: orderItems }, , helpersOrderItems] = useField<OrderItem[]>('orderItem');

	const setOrderItemStatus = (status: string) => {
		switch(status) {
			case 'ProductionInProgress':
				return OrderProductStatus.ManufacturingInProgress
			case 'DeliveryInProgress':
				return OrderProductStatus.ShippingInProgress;
			case 'Delivered':
			case 'Billed':
				return OrderProductStatus.Delivered
			default:
				return OrderProductStatus.Waiting
		}
	}

	const handleChangeFollowStatus = (event: SelectChangeEvent<string>) => {
		helpers.setValue(event.target.value as string);
		helpersOrderItems.setValue(orderItems.map((item) => ({
			...item,
			status: setOrderItemStatus(event.target.value as string)
		})))
	};

	const statusIsDisabled =
		followStatus === FollowOrderStatus.Refused || followStatus === FollowOrderStatus.WaitingValidation;

	return (
		<Box pt={7}>
			<Box mb={1}>
				<Typography variant="h2">Informations générales</Typography>
			</Box>

			<Box mx={15} mb={4} mt={3}>
				<Grid container>
					<Grid item xs={7}>
						<LayoutFieldForm
							label="Commande créé le"
							name="createdAt"
							disabled
							width="139px"
							inputProps={{ endAdornment: <EventIcon /> }}
						/>
					</Grid>

					<Grid item xs={5}>
						<LayoutFieldForm label="Nom de l'opération" name="fileReference.operationName" disabled width="270px" />
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={7}>
						<Box display="flex">
							{!statusIsDisabled ? (
								<LayoutField label="Statut de suivi de commande" width="270px">
									<FormControl>
										<Select
											sx={{ bgcolor: statusIsDisabled ? colors.grey : '' }}
											value={followStatus}
											disabled={statusIsDisabled}
											variant="outlined"
											onChange={handleChangeFollowStatus}
										>
											{FollowStatusListNotDisabled?.map((option) => (
												<MenuItem key={option.key} value={option.key}>
													{option.value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</LayoutField>
							) : (
								<LayoutFieldForm
									label="Statut de suivi de commande"
									name="followStatus"
									disabled
									width="270px"
									value={Object.entries(FollowStatusListDisabled).find(([key]) => key === followStatus)?.[1]}
								/>
							)}
							<Box mt={5} ml={1.5}>
								<StatusInformationPopin statusIsDisabled={statusIsDisabled} />
							</Box>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<LayoutFieldForm label="Référence client" name="fileReference.clientReference" disabled width="270px" />
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={7}>
						<LayoutFieldForm label="Nom du Client" name="clientName" disabled width="270px" />
					</Grid>
					<Grid item xs={5}>
						<LayoutFieldForm label="Responsable ELPEV" name="elpevResponsible" disabled width="270px" />
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={7}>
						<LayoutFieldForm label="Référence dossier" name="fileReference.id" disabled width="270px" />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default memo(OrderGeneralInformation);
