import { Box, Button, Divider, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FormikTextField from '../../../components/form/FormikTextField';
import useParcelTransporterInsert from '../hooks/useParcelTransportInsert';

const parcelTransporterInsertSchema = Yup.object({
	name: Yup.string().required('Vous devez spécifier un nom'),
});

interface InsertParcelTransportDialogProps {
	onCloseTransporterCreationPopin: () => void;
}

const InsertParcelTransportDialog = ({
	onCloseTransporterCreationPopin,
}: InsertParcelTransportDialogProps): JSX.Element => {
	const { insertParcelTransport } = useParcelTransporterInsert();
	const handleSubmit = async (
		transporter: { name: string },
		helpers: FormikHelpers<{ name: string }>
	): Promise<void> => {
		try {
			await insertParcelTransport(transporter.name);
			onCloseTransporterCreationPopin();
		} catch (error) {
			helpers.setErrors({
				name: `Impossible d'ajouter le transporteur: ${transporter.name}, ce dernier existe déjà`,
			});
		}
	};

	return (
		<Formik<{ name: string }>
			initialValues={{ name: '' }}
			validationSchema={parcelTransporterInsertSchema}
			onSubmit={handleSubmit}
			validateOnChange={false}
		>
			<Form>
				<Box p={2} pt={3} pb={3}>
					<Typography variant="h4">Nom du transporteur</Typography>
					<FormikTextField name="name" fullWidth variant="outlined" />
				</Box>
				<Divider />
				<Box p={3} display="flex" flexDirection="row-reverse">
					<Button variant="contained" color="primary" type="submit">
						Ajouter le transporteur
					</Button>
				</Box>
			</Form>
		</Formik>
	);
};

export default InsertParcelTransportDialog;
