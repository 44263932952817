import React from 'react';
import { Box, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../app/theme/colors.scss';

interface ComponentProps {
	backgroundColor: string;
}

interface SubmitAndResetButtonProps {
	handleCancel: () => void;
	handleSubmit: () => void;
	cancelBtnTitle?: string;
	submitBtnTitle: string;
	bkgColor?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			justifyContent: 'flex-end',
			padding: '20px 10px 20px 0px',
			cursor: 'pointer',
		},
		cancelBtn: {
			color: colors.white,
			cursor: 'pointer',
			backgroundColor: (props: ComponentProps) => props.backgroundColor,
		},
	})
);

export const SubmitAndResetButton = ({
	cancelBtnTitle,
	submitBtnTitle,
	handleCancel,
	handleSubmit,
	bkgColor = colors.primary,
}: SubmitAndResetButtonProps): JSX.Element => {
	const classes = useStyles({ backgroundColor: bkgColor });
	return (
		<Box className={classes.root}>
			{cancelBtnTitle && (
				<Button color="primary" onClick={handleCancel}>
					{cancelBtnTitle}
				</Button>
			)}

			<Button variant="contained" className={classes.cancelBtn} onClick={handleSubmit}>
				{submitBtnTitle}
			</Button>
		</Box>
	);
};
