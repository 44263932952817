import { Box, Button, FormControl, Grid, Typography } from '@mui/material';
import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import DashedHeader from '../../../components/DashedHeader';
import { FormikCheckboxField } from '../../../components/form/FormikChecboxField';
import FormikTextField from '../../../components/form/FormikTextField';
import { LayoutField } from '../../../components/form/LayoutField';
import { SpecificMarginSupport } from './client-commercial-information/specific-margin-support';
import { useStylesClient } from '../../style';
import { FormikNumberField } from '../../../components/form/FormikNumberField';

interface CommercialInformationsFormProps {
	onClickImportClientFromBaseDB: () => void;
	isRoleAdmin: boolean;
}

const CommercialInformationsForm = ({
	onClickImportClientFromBaseDB,
	isRoleAdmin,
}: CommercialInformationsFormProps) => {
	const classes = useStylesClient();

	return (
		<Box my={3}>
			<Typography variant="h2">Informations commerciales</Typography>
			<Button color="primary" className={classes.refreshBtn} onClick={onClickImportClientFromBaseDB}>
				<RefreshIcon height="24px" />
				<Typography variant="h5" color="primary">
					Récupérer les informations de la base Elpev
				</Typography>
			</Button>
			<Box mt={3} mx={15}>
				<DashedHeader>Remise de fin d'année</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<LayoutField label="Taux de RFA par défaut">
							<Box width="110px" display="flex">
								<FormControl fullWidth>
									<FormikNumberField decimalScale={2} name="mainRfaRate" width="139px" variant="outlined" />
								</FormControl>
								<Box mx={1} mt={0.5}>
									<Typography variant="h4"> % </Typography>
								</Box>
							</Box>
						</LayoutField>
					</Grid>
					<Grid item xs={12}>
						<Box pt={2} display="flex" alignItems="center">
							<FormikCheckboxField name="isHorsRFA" />
							<Typography variant="h5">Hors RFA</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<Box pt={2} mb={3}>
							<LayoutField label="Remarques">
								<FormControl fullWidth>
									<FormikTextField multiline name="rfaComment" fullWidth variant="outlined" />
								</FormControl>
							</LayoutField>
						</Box>
					</Grid>
				</Grid>
				<DashedHeader>Marge commerciale par défaut</DashedHeader>
				<Grid container justifyContent="space-between">
					<Grid item xs={5}>
						<LayoutField label="Marge commerciale produit par défaut">
							<Box width="110px" display="flex">
								<FormControl fullWidth>
									<FormikNumberField
										decimalScale={2}
										name="defaultCommercialProductMargin"
										width="139px"
										maxValue={100}
										variant="outlined"
									/>
								</FormControl>
								<Box mx={1} mt={0.5}>
									<Typography variant="h4"> % </Typography>
								</Box>
							</Box>
						</LayoutField>
					</Grid>
					<Grid item xs={5}>
						<LayoutField label="Marge commerciale transport par défaut">
							<Box width="110px" display="flex">
								<FormControl fullWidth>
									<FormikNumberField
										decimalScale={2}
										name="defaultCommercialShippingMargin"
										width="139px"
										maxValue={100}
										variant="outlined"
										disabled={!isRoleAdmin}
									/>
								</FormControl>
								<Box mx={1} mt={0.5}>
									<Typography variant="h4"> % </Typography>
								</Box>
							</Box>
						</LayoutField>
					</Grid>
					<Grid item xs={12}>
						<SpecificMarginSupport />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default CommercialInformationsForm;
