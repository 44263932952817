import React, { useEffect } from 'react';
import DashedHeader from 'components/DashedHeader';
import LayoutFieldForm from 'components/form/LayoutFieldForm';
import { Box, Tooltip, Typography } from '@mui/material';
import colors from 'app/theme/colors.scss';
import FormikTextField from 'components/form/FormikTextField';
import { LayoutField } from 'components/form/LayoutField';
import { useField } from 'formik';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import useClientAndAllParentsQuery from '../../hooks/useClientAndAllParentsQuery';
import usePrevious from 'hooks/usePrevious';
import { equals } from 'ramda';

const CommercialInformation = (): JSX.Element => {
	const [fieldClientId] = useField<string>('id');
	const [fieldAnalyticCode] = useField<string>('clientAnalyticCode');
	const [fieldClientCode] = useField<string>('clientCode');
	const [, , helpersGroupingCode] = useField<string | null | undefined>('groupingCode');
	const [, , helpersFileCode] = useField<string | null | undefined>('fileCode');
	const { clients } = useClientAndAllParentsQuery(fieldClientId.value);
	const prevFieldAnalyticCode = usePrevious(fieldAnalyticCode.value);
	const prevFieldClientCode = usePrevious(fieldClientCode.value);
	const analyticCode =
		!fieldAnalyticCode.value || fieldAnalyticCode.value === ''
			? clients?.filter(({ clientAnalyticCode }) => clientAnalyticCode)[0]?.clientAnalyticCode
			: fieldAnalyticCode.value;
	const prevAnalyticCode = usePrevious(analyticCode);

	useEffect(() => {
		if (
			!equals(JSON.stringify(prevFieldAnalyticCode), JSON.stringify(fieldAnalyticCode.value)) ||
			!equals(JSON.stringify(prevFieldClientCode), JSON.stringify(fieldClientCode.value)) ||
			!equals(JSON.stringify(prevAnalyticCode), JSON.stringify(analyticCode))
		) {
			helpersFileCode?.setValue(fieldClientCode.value ? analyticCode?.substr(0, 2)?.concat(fieldClientCode.value) : '');
			helpersGroupingCode.setValue(analyticCode?.substr(0, 2));
		}
	}, [
		helpersFileCode,
		clients,
		fieldAnalyticCode.value,
		helpersGroupingCode,
		fieldClientCode.value,
		prevFieldClientCode,
		prevFieldAnalyticCode,
		prevAnalyticCode,
		analyticCode,
	]);

	return (
		<>
			<DashedHeader>Informations commerciales</DashedHeader>
			<LayoutFieldForm label="Code de regroupement" name="groupingCode" disabled />
			<LayoutField label="Code Client">
				<FormikTextField name="clientCode" fullWidth variant="outlined" inputProps={{ maxLength: 3 }} />
			</LayoutField>
			<LayoutField>
				<Box mb={1} mt={2} display={'flex'}>
					<Typography variant="h4">Code dossier</Typography>
					<Box ml={1}>
						<Tooltip title="Code utilisé pour la génération de la Réf. dossier">
							<InfoOutlined color={'disabled'} />
						</Tooltip>
					</Box>
				</Box>
				<Box bgcolor={colors.grey}>
					<FormikTextField name="fileCode" fullWidth variant="outlined" disabled />
				</Box>
			</LayoutField>
		</>
	);
};

export default CommercialInformation;
