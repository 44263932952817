import { EntityType } from '../../../../graphql/types';

export const ENTITY_TYPES_TITLES = {
	[EntityType.Group]: 'Groupe',
	[EntityType.Central]: 'Centrale',
	[EntityType.Brand]: 'Enseigne',
	[EntityType.Region]: 'Région',
	[EntityType.Typology]: 'Typologie',
	[EntityType.SubGroup1]: 'Sous-groupe 1',
	[EntityType.SubGroup2]: 'Sous-groupe 2',
	[EntityType.SubGroup3]: 'Sous-groupe 3',
	[EntityType.SubGroup4]: 'Sous-groupe 4',
	[EntityType.Cooperative]: 'Coopérative',
	[EntityType.Store]: 'Magasin',
};

export const getEntityTitle = ({ value }: { value: EntityType }) =>
	`${ENTITY_TYPES_TITLES[value]}${value === EntityType.Region || value === EntityType.Typology ? ' *' : ''}`;
