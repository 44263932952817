import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { priceSummaryOrderItemByBestSupplier } from '../../helpers';
import { useStylesSupplierPrices } from './components/styles';
import { useField } from 'formik';
import OrderItemCollapse from './components/OrderItemCollapse';
import PriceSummaryByProduct from './components/PriceSummaryByProduct';
import { NewOrderItem, OrderItemFormModel, PriceDetailsInterface } from '../order-cockpit/definition';

type CalculationProductionAndTransportPriceType = {
	title: string;
};

export const CalculationProductionAndTransportPrice = ({
	title,
}: CalculationProductionAndTransportPriceType): JSX.Element => {
	const classes = useStylesSupplierPrices();
	const [fieldOrderItem] = useField<OrderItemFormModel[] | undefined | null>('orderItem');

	const orderItems = useMemo(() => {
		const orderItems = fieldOrderItem?.value ?? [];
		return orderItems.map((orderItem: NewOrderItem & { bestSupplierId?: string }) => {
			const bestSupplier = orderItem.suppliersPrices?.find(
				(supplierPrice) => supplierPrice.supplierId === orderItem.bestSupplierId
			);
			return {
				...orderItem,
				bestSupplier,
			};
		});
	}, [fieldOrderItem]);

	const priceSummary = useMemo(
		() =>
			fieldOrderItem?.value?.length
				? priceSummaryOrderItemByBestSupplier({
						orderItems: fieldOrderItem?.value,
				  })
				: [],
		[fieldOrderItem]
	);

	return (
		<Grid justifyContent="center" container className={classes.root}>
			<Grid xs={11} item>
				<Grid container justifyContent="center">
					<Grid xs={11} item>
						<Box my={4}>
							<Typography variant="h2">{title}</Typography>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			{orderItems?.map((orderItem, orderItemIndex) => (
				<OrderItemCollapse
					orderItem={orderItem as PriceDetailsInterface}
					orderItemIndex={orderItemIndex}
					key={orderItemIndex}
				/>
			))}
			{orderItems.length && !Array.isArray(priceSummary) ? <PriceSummaryByProduct {...priceSummary} /> : null}
		</Grid>
	);
};
