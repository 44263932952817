import { Row } from 'react-table';
import { ClientInvoicesTable } from './ClientInvoices';

export enum InvoiceStatus {
	ToValidate = 'A valider',
	ToCount = 'A comptabiliser',
	Counted = 'Comptabilisée',
	Blank = '',
}

export interface ImportInvoiceProps {
	accept?: string | string[];
	name: string;
	isMultiple?: boolean;
	row: Row<ClientInvoicesTable>;
}

export const MIN_PREVIEW_PAGE = 2;
export const INDEX_CHILD_ORDER = 2;
export const INDEX_ORDER = 1;
