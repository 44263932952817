import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ShowItemsQuote } from './ShowItemsQuote';
import { QUOTE_CLAUSE } from '../visualization-quote/definition';
import { getElpevSite } from '../../helpers';
import { VisualizationQuoteProps } from '../visualization-quote/VisualizationQuote';
import { ClientQuoteFileType } from 'graphql/types';

const useStyles = makeStyles({
	bold: {
		fontWeight: 600,
	},
});

interface TypeDictionnary {
	[index: string]: string;
}

const QUOTE_TYPE_STRING = {} as TypeDictionnary;
QUOTE_TYPE_STRING['ClientPriceToBeValidate'] = 'BON POUR VALIDATION DES PRIX';
QUOTE_TYPE_STRING['ClientOrderToBeCreate'] = 'BON POUR COMMANDE';

export const QuoteVirtualizationFooter = ({
	context,
	setIsShowQuotePreview,
	handleClosePopin,
}: VisualizationQuoteProps) => {
	const classes = useStyles();

	return (
		<>
			<Grid xs={12} item>
				<Box mb={3}>
					<Typography className={classes.bold} variant="subtitle2" component="span">
						Commentaires :{' '}
					</Typography>
					<Typography variant="subtitle2" component="span">
						{context?.quotePreviewData?.comment ?? ''}
					</Typography>
				</Box>
			</Grid>
			<Grid xs={12} item>
				<ShowItemsQuote categories={QUOTE_CLAUSE} variant="body2" />
			</Grid>
			<Grid xs={12} item>
				<Box width="100%" mt={3}>
					<Grid container justifyContent="flex-end">
						<Grid xs={11} item>
							<Typography variant="subtitle2" aria-label="quote-preview-data-type">
								{QUOTE_TYPE_STRING[context?.quotePreviewData?.type || 'ClientPriceToBeValidate']}
							</Typography>
							<Typography variant="subtitle2">Nom :</Typography>
							<Typography variant="subtitle2">Date : </Typography>
							<Typography variant="subtitle2">Cache et signature : </Typography>
						</Grid>
					</Grid>
					<Grid container justifyContent="flex-end">
						<Grid xs={10} item>
							<Box width="100%" textAlign="center">
								{context?.quotePreviewData?.clientInvoice?.elpevSite && (
									<p>{getElpevSite(context?.quotePreviewData?.clientInvoice?.elpevSite)}</p>
								)}
								<p>
									Tél. +33 389 329 329 - SAS au capital de 1 000 000 d'euros - N° RCS Muhouse B 399 372 465 - TVA FR 823
									993 72 465
								</p>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid xs={12} item>
				<Box display="flex" justifyContent="flex-end" alignItems="center">
					<Grid xs={5} item>
						<Grid container>
							<Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
								<Button color="primary" variant="outlined" onClick={() => handleClosePopin()}>
									Quitter
								</Button>
								<Button color="primary" variant="outlined" onClick={() => setIsShowQuotePreview(false)}>
									Retour
								</Button>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => context?.generateQuoteFile(ClientQuoteFileType.Xlsx)}
								>
									Créer un document Excel
								</Button>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => context?.generateQuoteFile(ClientQuoteFileType.Pdf)}
								>
									Créer le document PDF
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</>
	);
};
