import { useQuery } from '@apollo/client';
import { GET_ROLE_BY_ID } from '../graphql/queries';
import { GetRoleByIdQuery, GetRoleByIdQueryVariables } from '../graphql/queries.definitions';

const useRoleQuery = (roleId: string) => {
	const { data } = useQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GET_ROLE_BY_ID, {
		variables: { roleId },
	});

	return { role: data?.role };
};

export default useRoleQuery;
