import React from 'react';
import { ProductInformation } from '../../../product/components/ProductInformation';

export const AdditionalInformation = (): JSX.Element => {
	return (
		<>
			<ProductInformation label="Poids théorique(Kg)" />
			<ProductInformation label="Nb de chgt de plaque" />
			<ProductInformation label="Nb de cliché" />
			<ProductInformation label="Outillage" />
			<ProductInformation label="Dimensions palettes" />
			<ProductInformation label="Transport" />
		</>
	);
};
