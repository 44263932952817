import { Box, List, ListItem } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Supplier } from '../../graphql/types';
import colors from '../../app/theme/colors.scss';
import useSearch from '../../hooks/useSearch';

interface SupplierListSelectProps {
	suppliers: Array<Pick<Supplier, 'id' | 'name'>>;
	onSupplierSelected: (supplier: Pick<Supplier, 'id' | 'name'>) => void;
}

const SupplierListSelect = ({ suppliers, onSupplierSelected }: SupplierListSelectProps) => {
	const location = useLocation();
	const { renderSearchComponent, filtredItems } = useSearch('name', suppliers);

	return (
		<>
			<Box p={2} position="fixed" zIndex={2} bgcolor={colors.black100} width={257}>
				{renderSearchComponent('Fournisseur')}
			</Box>
			<Box pb={5} mb={2} pt={7}>
				<List>
					{filtredItems?.map((supplier) => (
						<ListItem
							button
							key={supplier.id}
							onClick={() => onSupplierSelected(supplier)}
							selected={location.pathname.includes(supplier.id)}
						>
							{supplier.name}
						</ListItem>
					))}
				</List>
			</Box>
		</>
	);
};

export default SupplierListSelect;
