import { Box, Card, CardHeader, Checkbox, List, ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { intersection } from '../../../utils/mathematicalLlogiOperationsc';
import { UserClientListItem } from '../definitions';

interface CustomListTransfertProps {
	title: React.ReactNode;
	items: UserClientListItem[];

	handleToggleAll: (items: UserClientListItem[]) => () => void;
	handleToggle: (value: UserClientListItem) => () => void;
	checked: UserClientListItem[];
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: 'auto',
		},
		cardHeader: {
			padding: theme.spacing(1, 2),
			border: '1px solid #e7e7e7',
			height: 30,
		},
		list: {
			width: 417,
			height: 400,
			backgroundColor: theme.palette.background.paper,
			overflow: 'auto',
			border: '1px solid #e7e7e7',
		},
		button: {
			margin: theme.spacing(0.5, 0),
		},
		item: {
			height: 30,
		},
	})
);

const CustomListTransfert = ({
	title,
	items,

	handleToggleAll,
	handleToggle,
	checked,
}: CustomListTransfertProps) => {
	const classes = useStyles();

	const ItemOfChecked = (items: UserClientListItem[]) => intersection(checked, items).length;

	return (
		<Card>
			<CardHeader
				className={classes.cardHeader}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={ItemOfChecked(items) === items.length && items.length !== 0}
						indeterminate={ItemOfChecked(items) !== items.length && ItemOfChecked(items) !== 0}
						disabled={items.length === 0}
						inputProps={{ 'aria-label': 'all items selected' }}
					/>
				}
				title={<Box fontWeight="fontWeightBold">{title}</Box>}
			/>

			<List className={classes.list} dense component="div" role="list">
				{items.map((value: UserClientListItem) => {
					const labelId = `transfer-list-all-item-${value}-label`;

					return (
						<ListItem
							className={classes.item}
							key={value.id}
							role="listitem"
							button
							onClick={handleToggle(value)}
							divider
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>

							<ListItemText id={labelId} primary={value.name} />
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Card>
	);
};

export default CustomListTransfert;
