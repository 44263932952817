import React, { memo } from 'react';
import Box from '@mui/material/Box';
import LayoutFieldForm from '../../components/form/LayoutFieldForm';
import Departments from './Departments';

interface AddressProps {
	isDisplayDepartements?: boolean;
	disabled?: boolean;
	name: string;
	isDisplayAddressName?: boolean;
	disabledDepartments?: boolean;
}

const Address = ({
	disabled = false,
	isDisplayDepartements,
	name,
	isDisplayAddressName = false,
	disabledDepartments = false,
}: AddressProps): JSX.Element => {
	const getName = (fieldName: string) => `${name}.${fieldName}`;

	return (
		<>
			<Box mt={2}>
				{isDisplayAddressName && <LayoutFieldForm label="Nom " name={getName('name')} disabled={disabled} />}
				<LayoutFieldForm
					label="Rue 1 "
					name={getName('firstAddress')}
					disabled={disabled}
					testid="first-address-form"
				/>
				<LayoutFieldForm
					label="Rue 2 "
					name={getName('secondAddress')}
					disabled={disabled}
					testid="second-address-form"
				/>
				<LayoutFieldForm
					label="Rue 3 "
					name={getName('thirdAddress')}
					disabled={disabled}
					testid="third-address-form"
				/>
				<LayoutFieldForm label="Boite Postale " name={getName('postBox')} disabled={disabled} testid="postBox-form" />
				<LayoutFieldForm
					label="Code postal "
					name={getName('zipCode')}
					width="20%"
					disabled={disabled}
					testid="zipCode-form"
				/>
				<LayoutFieldForm label="Ville " name={getName('city')} disabled={disabled} testid="city-form" />
				<LayoutFieldForm label="Pays " name={getName('country')} disabled={disabled} testid="country-form" />
				{isDisplayDepartements && (
					<Departments
						disabledDepartments={disabledDepartments}
						title="Département de départ"
						zipCode={getName('zipCode')}
						name={getName('deliveryDepartment')}
					/>
				)}
			</Box>
		</>
	);
};

export default memo(Address);
