import { useMutation } from '@apollo/client';
import { ClientShippingAddressInsertInput } from '../../graphql/types';
import { GET_CLIENT_BY_ID } from '../graphql/queries';
import {
	InsertClientShippingAddressMutation,
	InsertClientShippingAddressMutationVariables,
} from '../graphql/mutations.definitions';
import { INSERT_CLIENT_SHIPPING_ADDRESS } from '../graphql/mutations';

const useClientShippingAddressInsert = () => {
	const [insertClientAddressMutation] = useMutation<
		InsertClientShippingAddressMutation,
		InsertClientShippingAddressMutationVariables
	>(INSERT_CLIENT_SHIPPING_ADDRESS);

	const insertClientShippingAddress = async (
		clientShippingAddress: ClientShippingAddressInsertInput,
		clientId: string
	) => {
		await insertClientAddressMutation({
			variables: {
				clientShippingAddress: {
					clientId,
					contact: {
						fax: clientShippingAddress?.contact?.fax,
						email: clientShippingAddress.contact?.email,
						phone: clientShippingAddress.contact?.phone,
						website: clientShippingAddress.contact?.website,
						storeCode: clientShippingAddress.contact?.storeCode,
						directorName: clientShippingAddress.contact?.directorName,
						isNotificated: clientShippingAddress.contact?.isNotificated,
					},
					address: {
						name: clientShippingAddress.address?.name,
						firstAddress: clientShippingAddress.address?.firstAddress,
						secondAddress: clientShippingAddress.address?.secondAddress,
						thirdAddress: clientShippingAddress.address?.thirdAddress,
						postBox: clientShippingAddress.address?.postBox,
						zipCode: clientShippingAddress.address?.zipCode,
						city: clientShippingAddress.address?.city,
						country: clientShippingAddress.address?.country,
						isShipping: true,
						deliveryDepartment: clientShippingAddress.address?.deliveryDepartment,
					},
					referenceId: clientShippingAddress?.id,
					isMain: clientShippingAddress.isMain,
					boxShippingMode: clientShippingAddress.boxShippingMode,
					maxBoxWeight: clientShippingAddress.maxBoxWeight,
					palletShippingMode: clientShippingAddress.palletShippingMode,
					comment: clientShippingAddress.comment,
					workingDays: clientShippingAddress.workingDays?.map((workingDay) => ({
						day: workingDay.day,
						isWorkingDay: workingDay.isWorkingDay,
						end: workingDay.end,
						start: workingDay.start,
					})),
				},
			},
			refetchQueries: [
				{
					query: GET_CLIENT_BY_ID,
					variables: {
						id: clientId,
					},
				},
			],
			awaitRefetchQueries: true,
		});
	};
	return { insertClientShippingAddress };
};

export default useClientShippingAddressInsert;
