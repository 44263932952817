import React, { useEffect } from 'react';
import { Attribute, PrintValue } from 'graphql/types';
import SupportCharacteristics from 'components/SupportCharacteristics/SupportCharacteristics';
import { ProductFromModel } from 'product/definitions';
import { GetSupplierPrices } from 'supplier/definitions';
import useSupportCharacteristics from 'components/SupportCharacteristics/hooks/useSupportCharacteristics';
import { Box, Button, Typography } from '@mui/material';
import { ProductDetailsByFamilyType } from 'product/components/ProductDetailsByFamily';
import { ProductFilter } from 'context/pricesProductContext/definition';
import { omit } from 'ramda';

type FilterTYpe = Array<keyof Omit<ProductFilter, 'conditionnement1' | 'conditionnement2' | 'conditionnement3'>>;

export interface ClientPriceSupportCharacteristicsProps {
	attributes?: Attribute[] | null;
	printAttributes?: PrintValue[] | null;
	products: ProductFromModel[];
	isClariPrint?: boolean;
	onGetSupplierPrices: GetSupplierPrices;
	product?: ProductDetailsByFamilyType | null;
	supportId?: string;
}
const ClientPriceSupportCharacteristics = ({
	attributes,
	printAttributes,
	products,
	isClariPrint,
	product,
	supportId,
	onGetSupplierPrices,
}: ClientPriceSupportCharacteristicsProps) => {
	const {
		productsAttributesByFamily,
		handleFilterChange,
		filtredProducts,
		setProductFilter,
		setProduct,
		selectedSupportId,
		selectedProduct,
		productFilter,
		conditionnement,
		setConditionnement,
		numberOfLots,
		setNumberOfLots,
	} = useSupportCharacteristics({
		supportId,
		attributes,
		printAttributes,
		products,
		isClariPrint,
	});

	useEffect(() => {
		if (product?.conditionnement?.conditionnementList) {
			setNumberOfLots(product?.conditionnement?.numberOfLots);
			setConditionnement({
				numberOfLots: product.conditionnement.numberOfLots ?? 1,
				total: product.conditionnement?.total ?? null,
				conditionnementList: (product.conditionnement?.conditionnementList).map((c) => ({
					numberOfExLots: c.numberOfExLots ?? null,
					firstConditionnement: {
						conditionnement: {
							name: c.firstConditionnement?.conditionnement ?? null,
							value: c.firstConditionnement?.conditionnement ?? null,
						},
						nbExConditionnement: c.firstConditionnement?.nbExConditionnement ?? null,
						labeling: c.firstConditionnement?.labeling ?? null,
					},
					secondConditionnement: {
						conditionnement: {
							name: c.secondConditionnement?.conditionnement ?? null,
							value: c.secondConditionnement?.conditionnement ?? null,
						},
						nbExConditionnement: c.secondConditionnement?.nbExConditionnement ?? null,
						labeling: c.secondConditionnement?.labeling ?? null,
					},
					threeConditionnement: {
						conditionnement: {
							name: c.threeConditionnement?.conditionnement ?? null,
							value: c.threeConditionnement?.conditionnement ?? null,
						},
						nbExConditionnement: c.threeConditionnement?.nbExConditionnement ?? null,
						labeling: c.threeConditionnement?.labeling ?? null,
					},
				})),
			});

			const filterType = omit(['id', 'supportId', 'conditionnement1', 'conditionnement2', 'conditionnement3'], product);
			setProductFilter(
				(Object.keys(filterType) as FilterTYpe).reduce((acc, current) => {
					if (current !== 'conditionnement')
						acc[current] = {
							name: product[current],
							value: product[current],
						};
					return acc;
				}, {} as ProductFilter)
			);
		}
	}, [product, setProductFilter, setNumberOfLots, setConditionnement]);

	return (
		<>
			<Box display={'flex'} ml={17.5}>
				<Box display={'flex'} flexGrow={1}>
					<Typography variant="h2">Caractéristiques du support</Typography>
				</Box>
				<Button
					onClick={() => {
						setProductFilter(null);
						setProduct(null);
						setNumberOfLots(1);
						setConditionnement({
							numberOfLots: 1,
							total: null,
							conditionnementList: [
								{
									numberOfExLots: null,
									firstConditionnement: {
										labeling: null,
										conditionnement: null,
										nbExConditionnement: null,
									},
									threeConditionnement: {
										labeling: null,
										conditionnement: null,
										nbExConditionnement: null,
									},
									secondConditionnement: {
										labeling: null,
										conditionnement: null,
										nbExConditionnement: null,
									},
								},
							],
						});
					}}
					color={'primary'}
					variant={'outlined'}
				>
					Réinitialiser
				</Button>
			</Box>
			{attributes && (
				<SupportCharacteristics
					supportId={supportId}
					attributes={attributes}
					printAttributes={printAttributes}
					products={products}
					isClariPrint={isClariPrint}
					onGetSupplierPrices={onGetSupplierPrices}
					productsAttributesByFamily={productsAttributesByFamily}
					handleFilterChange={handleFilterChange}
					filtredProducts={filtredProducts}
					setProductFilter={setProductFilter}
					setProduct={setProduct}
					selectedSupportId={selectedSupportId}
					selectedProduct={selectedProduct}
					productFilter={productFilter}
					conditionnement={conditionnement}
					setConditionnement={setConditionnement}
					numberOfLots={numberOfLots}
					setNumberOfLots={setNumberOfLots}
				/>
			)}
		</>
	);
};

export default ClientPriceSupportCharacteristics;
