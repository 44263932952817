import { gql } from '@apollo/client';
import { ORDER_ADDRESS_FRAGMENT } from '../../order/graphql/queries';
import { PRODUCT_FRAGMENT } from '../../graphql/fragments/product/productFragment';

export const GET_INVOICES = gql`
	query GetInvoices(
		$search: InvoiceSearchInput
		$sort: InvoiceSortInput
		$filter: InvoiceFilterInput
		$pagination: PaginationInput
	) {
		invoices(search: $search, sort: $sort, filter: $filter, pagination: $pagination) {
			invoices {
				id
				createdAt
				validateAt
				sendAt
				status
				validator
				isExternal
				url
				order {
					id
					parentOrderId
					clientName
					billingAddress {
						...OrderAddress
					}
					sellingPriceHt
					tva
					sellingPriceTtc
					fileReference {
						operationName
						clientReference
						askBy
						elpevResponsible {
							familyName
							givenName
						}
					}
				}
			}
			total
		}
	}
	${ORDER_ADDRESS_FRAGMENT}
`;

export const GET_INVOICE_BY_ID = gql`
	query GetInvoiceById($id: String!) {
		invoice(id: $id) {
			id
			createdAt
			validateAt
			status
			validator
			isExternal
			url
			sendAt
			order {
				id
				parentOrderId
				orderItem {
					id
					prepressSellingPriceHt
					transportSellingPriceHt
					transportBuyingPriceHt
					productSellingPriceHt
					productId
					productName
					quantity
					weight
					productMargin
					transportMargin
					realProductMargin
					realTransportMargin
					isWithRfaProduct
					rfaRate
					isWithRfaTransport
					buyingPriceHt
					sellingPriceHt
					onePacDocumentId
					shippingAddress {
						...OrderAddress
					}
					product {
						...Product
					}
				}
				clientName
				clientId
				buyingPriceHtWithRfa
				buyingPriceHt
				sellingPriceHt
				marginWithClientRfa
				realMargin
				marginRate
				tvaRate
				tva
				sellingPriceTtc
				transportBuyingPriceHt
				elepvContactName
				elpevContactEmail
				elpevContactPhone
				billingAddress {
					...OrderAddress
				}
			}
		}
	}
	${ORDER_ADDRESS_FRAGMENT}
	${PRODUCT_FRAGMENT}
`;
