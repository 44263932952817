import React from 'react';
import NumberFormat from 'react-number-format';

export interface NumberFormatCustomProps<T> {
	inputRef?: (instance: NumberFormat<T> | null) => void;
	onChange: (event: { target: { name: string; value: number | undefined } }) => void;
	name: string;
	decimalScale?: number;
	maxValue?: number;
	minValue?: number;
	color: string;
}

const NumberFormatCustom = <T extends { [P in keyof T]: T[P] }>({
	inputRef,
	onChange,
	name,
	decimalScale,
	maxValue,
	minValue,
	color,
	...other
}: NumberFormatCustomProps<T>): JSX.Element => {
	const valueMax = maxValue || Math.pow(10, 1000);

	return (
		<NumberFormat
			{...other}
			style={{ color: color }}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name,
						value: values.floatValue && values.floatValue > valueMax ? valueMax : values.floatValue ?? undefined,
					},
				});
			}}
			data-testid={name}
			thousandSeparator=" "
			decimalSeparator=","
			isNumericString={true}
			decimalScale={decimalScale}
			fixedDecimalScale={true}
			allowNegative={true}
			autoComplete="off"
		/>
	);
};

export default NumberFormatCustom;
