import React from 'react';
import { Box, Typography } from '@mui/material';
import { NamesAndProduct } from './components/NamesAndProduct';
import { MarginAndRfa } from './components/MarginAndRFA';
import { DateAndStatus } from './components/DateAndStatus';
import { IsShowSellingPriceSelectedProps, OnSelectStatusSellingPriceProps } from '../definitions';

export interface MainInformationProps extends IsShowSellingPriceSelectedProps {
	isClariPrint?: boolean;
	isConsultAndEditSellingPrice?: boolean;
}

export const MainInformation = ({
	isClariPrint,
	isConsultAndEditSellingPrice,
	isConsultSellingPrice,
	onSelectStatusSellingPrice,
}: MainInformationProps & OnSelectStatusSellingPriceProps): JSX.Element => (
	<Box my={5} mx={20}>
		<Typography variant="h2">Informations commerciales</Typography>
		<Box width="80%" m="6px auto">
			<NamesAndProduct isClariPrint={isClariPrint} isConsultSellingPrice={isConsultSellingPrice} />
			<MarginAndRfa isConsultSellingPrice={isConsultSellingPrice} />
			<DateAndStatus
				onSelectStatusSellingPrice={onSelectStatusSellingPrice}
				isConsultSellingPrice={isConsultSellingPrice}
				isConsultAndEditSellingPrice={isConsultAndEditSellingPrice}
			/>
		</Box>
	</Box>
);
