import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { Column, Row } from 'react-table';
import { ClientsName } from 'utils/distribution';

interface EditableCellProps {
	value: ClientsName | null;
	row: Row;
	column: Column;
	updateMyData: (index: number, id: string, value: string) => void;
}
const EditableSelectClientAndAllChildrens = ({
	value: initialValue,
	row: { index },
	column,
	updateMyData,
	clientsName,
}: EditableCellProps & { clientsName: ClientsName[] }) => {
	const [value, setValue] = React.useState<ClientsName | null>({
		id: initialValue?.id || '',
		name: clientsName.find((item: ClientsName) => item.id === initialValue?.id)?.name || '',
	});

	const [inputValue, setInputValue] = React.useState<string>(
		clientsName.find((item: ClientsName) => item.id === initialValue?.id)?.name || ''
	);

	const handleOnChange = (newValue: ClientsName | null) => {
		setValue(newValue);
		if (!column.id) {
			return;
		}
		updateMyData(index, column.id, newValue?.id || '');
	};
	return (
		<Box>
			<Autocomplete
				defaultValue={value}
				onChange={(_, newValue) => {
					handleOnChange(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				aria-label={`editable-select-store`}
				options={clientsName || []}
				isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
				getOptionLabel={(option: ClientsName) => {
					return option?.name || '';
				}}
				fullWidth
				size="small"
				renderInput={(params) => <TextField {...params} variant="outlined" />}
			/>
		</Box>
	);
};

export default EditableSelectClientAndAllChildrens;
