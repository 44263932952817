import { gql } from '@apollo/client';

export const INSERT_PRINT_VALUES = gql`
	mutation InsertPrintValues($prints: [PrintValueInput!]!) {
		printValues: insertPrintValues(prints: $prints) {
			attributeId
			printValue
			printName
		}
	}
`;
