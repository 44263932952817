import * as React from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useField } from 'formik';

import { LayoutField } from 'components/form/LayoutField';
import colors from 'app/theme/colors.scss';

import { useProductInformation } from './ProductInformationContext';
import { useMemo } from 'react';

const SelectProductVersionNumber = () => {
	const { filteredProducts } = useProductInformation();

	const [{ value: newOrderItem }, , { setValue: setNewOrderItem }] = useField('newOrderItem');

	const numberVersion = useMemo(() => filteredProducts?.map((p) => p.numberVersion)?.sort((a, b) => a - b) ?? [], [
		filteredProducts,
	]);

	const handleSelectVersionNumber = (event: SelectChangeEvent<number>) => {
		setNewOrderItem({
			...newOrderItem,
			numberVersion: event.target.value,
		});
	};

	return (
		<Box mt={2}>
			<LayoutField label="Nombre de version">
				<FormControl>
					<Select
						style={{ backgroundColor: colors.white, width: '164px' }}
						value={newOrderItem?.numberVersion ?? ''}
						variant="outlined"
						onChange={handleSelectVersionNumber}
					>
						{numberVersion?.map((version) => (
							<MenuItem value={version} key={version}>
								{version}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</LayoutField>
		</Box>
	);
};

export default SelectProductVersionNumber;
