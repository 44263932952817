import { useContext, useMemo } from 'react';
import { useField } from 'formik';
import { BoxShippingMode, TransportMoment } from 'graphql/types';
import PricesProductContext from 'context/pricesProductContext/PricesProductContext';
import { DistributionsDetails } from '../form/definitions';
import {
	getBestTransportDeliveryParcelPurchasePrice,
	getBestTransportDeliveryParcelPurchasePriceKnownDistributions,
} from '../form/helpers';

import { SupplierPriceFormModel } from 'supplier/definitions';
import { InformationsValideContext } from '../context/informationsValideProvider';
import { calculateProductTotalWeight } from '../helpers/calculateProductTotalWeight';

const useGetBestTransportDeliveryParcelPurchasePrice = (
	isClariPrint?: boolean,
	selectedTransporterId?: string | null,
	isValidatePrintsByVersion?: boolean
) => {
	const context = useContext(InformationsValideContext);
	const [{ value: maxBoxWeight }] = useField<number>('maxBoxWeight');
	const [{ value: deliveryParcel }] = useField<BoxShippingMode>('deliveryParcel');
	const [{ value: deliveryDepartment }] = useField<BoxShippingMode>('deliveryDepartment');
	const [{ value: transportMoment }] = useField<TransportMoment>('transportMoment');
	const [{ value: isDistribution }] = useField<boolean | undefined>('isDistribution');
	const [{ value: nbShippingPoints }] = useField<number>('nbShippingPoints');
	const [{ value: distributions }] = useField<DistributionsDetails[]>('distributions');
	const [{ value: quantity }] = useField<number>('quantity');
	const [{ value: clientZipCode }] = useField<string | undefined>('clientZipCode');
	const [{ value: supplierPrices }] = useField<SupplierPriceFormModel[] | undefined>('supplierPrices');

	const supplierPricesTransportModes = supplierPrices && supplierPrices[0]?.supplier?.transportModes;
	const productUnitWeight = supplierPrices && supplierPrices[0]?.poidsUnitaireGr;
	const { selectedProduct, productFilter, productTotalWeight } = useContext(PricesProductContext);
	const product = useMemo(
		() =>
			isClariPrint
				? {
						largeurFormatFiniCm: productFilter?.largeurFormatFiniCm?.name,
						longueurFormatFiniCm: productFilter?.longueurFormatFiniCm?.name,
						grammageGr: productFilter?.grammageGr?.name,
						paginationTotale: productFilter?.paginationTotale?.name,
				  }
				: {
						largeurFormatFiniCm: selectedProduct?.largeurFormatFiniCm,
						longueurFormatFiniCm: selectedProduct?.longueurFormatFiniCm,
						grammageGr: selectedProduct?.grammageGr,
						paginationTotale: selectedProduct?.paginationTotale,
						unitWeightGr:
							selectedProduct?.productSupplierPrice && selectedProduct.productSupplierPrice[0].poidsUnitaireGr,
				  },
		[isClariPrint, productFilter, selectedProduct]
	);

	const distributionsWithWeight = distributions?.map((distribution) => ({
		...distribution,
		weight: calculateProductTotalWeight(distribution?.quantity || 0, product),
	}));

	const isKnownDistributions = useMemo(
		() => !!(isDistribution && distributionsWithWeight && distributionsWithWeight[0]?.quantity),
		[isDistribution, distributionsWithWeight]
	);

	const purchasePriceData = useMemo(() => {
		if (!product || !productTotalWeight || !isValidatePrintsByVersion)
			return {
				selectedTransporter: null,
				transportersPrices: null,
				lowerRoundedWholeDigitTotalWeight: null,
				remainingWeigh: null,
				isOneBox: false,
				maxBoxWeight,
			};

		return isKnownDistributions
			? getBestTransportDeliveryParcelPurchasePriceKnownDistributions(
					maxBoxWeight,
					deliveryParcel,
					deliveryDepartment,
					transportMoment,
					product,
					context?.transporters,
					distributionsWithWeight,
					selectedTransporterId,
					supplierPricesTransportModes,
					productUnitWeight
			  )
			: getBestTransportDeliveryParcelPurchasePrice(
					productTotalWeight,
					maxBoxWeight,
					deliveryParcel,
					deliveryDepartment,
					transportMoment,
					product,
					nbShippingPoints,
					quantity,
					isDistribution,
					context?.transporters,
					selectedTransporterId,
					clientZipCode,
					supplierPricesTransportModes,
					productUnitWeight
			  );
	}, [
		product,
		productTotalWeight,
		isValidatePrintsByVersion,
		maxBoxWeight,
		isKnownDistributions,
		deliveryParcel,
		deliveryDepartment,
		transportMoment,
		context,
		distributionsWithWeight,
		selectedTransporterId,
		supplierPricesTransportModes,
		productUnitWeight,
		nbShippingPoints,
		quantity,
		isDistribution,
		clientZipCode,
	]);

	return {
		...purchasePriceData,
		isDistribution,
		nbShippingPoints,
		distributions,
		isKnownDistributions,
		transporters: context?.transporters,
	};
};

export default useGetBestTransportDeliveryParcelPurchasePrice;
