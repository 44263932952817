import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { uploadFile } from '../../utils/upload-file';
import { OrderFormModel } from '../form/order-cockpit/definition';
import { GET_UPLOAD_URL_INVOICE, UPSERT_COCKPIT_ORDER } from '../graphql/mutations';
import {
	CreateOrderInvoiceUploadUrlMutation,
	CreateOrderInvoiceUploadUrlMutationVariables,
	UpsertCockpitOrderMutation,
	UpsertCockpitOrderMutationVariables,
} from '../graphql/mutations.definitions';
import { GET_ORDER_BY_ID } from '../graphql/queries';
import { omit } from 'ramda';

export const useUpsertCockpitOrder = () => {
	const [createUploadUrlMutation] = useMutation<
		CreateOrderInvoiceUploadUrlMutation,
		CreateOrderInvoiceUploadUrlMutationVariables
	>(GET_UPLOAD_URL_INVOICE);
	const [updateOrderMutation] = useMutation<UpsertCockpitOrderMutation, UpsertCockpitOrderMutationVariables>(
		UPSERT_COCKPIT_ORDER
	);
	const createOrderInvoiceUploadUrl = useCallback(
		async (id: string, mimetype: string) => {
			const { data } = await createUploadUrlMutation({
				variables: {
					id,
					mimetype,
				},
			});
			return data?.uploadUrl;
		},
		[createUploadUrlMutation]
	);
	const saveInvoicesOnS3 = useCallback(
		async (invoiceFile: Record<string, File>, invoiceId: string): Promise<void> => {
			await Promise.all(
				Object.keys(invoiceFile).map(async (item) => {
					const file = invoiceFile[item];
					const uploadUrl = await createOrderInvoiceUploadUrl(`${invoiceId}.pdf`, file.type);
					await uploadFile(invoiceFile[item] as File, uploadUrl, (invoiceFile[item] as File).type);
				})
			);
		},
		[createOrderInvoiceUploadUrl]
	);
	const upsertCockpitOrder = async (order: OrderFormModel, invoiceFile?: Record<string, File> | null) => {
		const orderInput = omit(
			[
				'__typename',
				'invoiceFile',
				'clientPricesExisting',
				'createdAt',
				'updatedAt',
				'billingEntity',
				'billingEntityObject',
				'elepvContactName',
				'elpevContactEmail',
				'elpevContactPhone',
				'source',
				'version',
				'elpevResponsible',
				'operationName',
				'isDistribution',
				'orderDistributions',
				'clientQuote',
				'onepacUserId',
				'onepacUserName',
				'statusComment',
				'statusUpdatedAt',
			],
			order
		);

		const {
			invoices,
			childrenOrder,
			clientId,
			clientName,
			id,
			status,
			orderItem,
			buyingPriceHt,
			buyingPriceHtWithRfa,
			sellingPriceHt,
			billingAddress,
			marginRate,
			client,
			fileReference,
			...orderRest
		} = orderInput;

		let originalInvoiceName: string | null = null;

		if (invoiceFile) {
			Object.keys(invoiceFile).forEach((item) => {
				originalInvoiceName = invoiceFile[item].name;
			})
		}

		const { data } = await updateOrderMutation({
			variables: {
				order: {
					id,
					status,
					clientId,
					clientName,
					billingAddress: {
						city: billingAddress?.city,
						name: billingAddress?.name ?? '',
						country: billingAddress?.country,
						firstAddress: billingAddress?.firstAddress,
						secondAddress: billingAddress?.secondAddress,
						zipCode: billingAddress?.zipCode,
						thirdAddress: billingAddress?.thirdAddress,
					},
					invoices: invoices?.map((invoice) => omit(['__typename'], { isExternal: true, ...invoice, originalInvoiceName })),
					childrenOrder: childrenOrder?.map((child) => {
						const { __typename, orderItem, id, ...rest } = child;
						return {
							clientName,
							clientId,
							id,
							invoices: rest.invoices?.map((invoice) => omit(['__typename', 'url'], { isExternal: true, ...invoice, originalInvoiceName })),
							orderItem: orderItem?.map(
								({ trackingLink, status, id, legacyRef, productId, productName, quantity, onePacDocumentId }) => ({
									trackingLink,
									status,
									id,
									legacyRef,
									productId,
									productName,
									quantity,
									onePacDocumentId,
								})
							),
						};
					}),
					orderItem: orderItem?.map(({ status, id, productId, productName, quantity, onePacDocumentId }) => ({
						status,
						id,
						productId,
						productName,
						quantity,
						onePacDocumentId,
					})),
					...orderRest,
				},
			},
			refetchQueries: [
				{
					query: GET_ORDER_BY_ID,
					variables: {
						id,
					},
				},
			],
			onCompleted: async (data) => {
				if (data && invoiceFile) {
					const childrenOrder = data.upsertCockpitOrder.childrenOrder;

					if (childrenOrder) {
						for(let i = 0; i  < childrenOrder.length; i++) {
							const invoices = childrenOrder[i].invoices;
							if (invoices) {
								await saveInvoicesOnS3(invoiceFile, invoices[0].id)
							}
						}
					}
				}
			}
		});

		return data;
	};
	return { upsertCockpitOrder };
};
