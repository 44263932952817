import { Box, Divider, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Path from '../components/Path';
import FiltersProducts from './FiltersProducts';
import { PATH_NAMES } from '../Header/menus';
import useSupportByIdQuery from '../admin/support/hooks/useSupportByIdQuery';
import PricesProductContext from 'context/pricesProductContext/PricesProductContext';

const Product = (): JSX.Element => {
	const { id: supportId } = useParams<{ id: string }>();
	const { support } = useSupportByIdQuery(supportId);
	const paths: (string | undefined)[] = [PATH_NAMES.Product, PATH_NAMES.ProductList, support?.name];
	const context = useContext(PricesProductContext);

	return (
		<Box ml={7} mr={7}>
			<Box display={'flex'}>
				<Box flexGrow={1}>
					<Path paths={paths} />
				</Box>
				{context?.selectedProduct && context?.selectedProduct?.codeAt && (
					<Box pt={4}>
						<Typography variant={'h3'} color={'textSecondary'}>
							Code AT : {context?.selectedProduct?.codeAt}
						</Typography>
					</Box>
				)}
			</Box>
			<Divider />
			{support && <FiltersProducts supportId={supportId} support={support} />}
		</Box>
	);
};

export default Product;
