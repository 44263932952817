import { useMutation } from '@apollo/client';
import { CLIENT_PRICE_UPSERT } from '../graphql/mutations';
import { ClientPriceInput } from '../../graphql/types';
import { UpsertClientPriceMutationVariables, UpsertClientPriceMutation } from '../graphql/mutations.definitions';
import { omit } from 'ramda';
import { SupplierPriceFormModel } from 'supplier/definitions';
import { typeNotifications } from 'utils/definition';
import { GET_ALL_CLIENTS_PRICES } from '../graphql/queries';
import { useHistory } from 'react-router-dom';

export const useClientPriceUpsert = () => {
	const [upsertClientClientMutation] = useMutation<UpsertClientPriceMutation, UpsertClientPriceMutationVariables>(
		CLIENT_PRICE_UPSERT
	);
	const history = useHistory();
	const upsertClientPrice = async (
		clientPrice: ClientPriceInput & {
			supplierPrices: SupplierPriceFormModel[] | null;
		}
	) => {
		const { errors } = await upsertClientClientMutation({
			variables: {
				clientPrice: omit(
					[
						'__typename',
						'supplierPrices',
						'transportersPrices',
						'support',
						'clientZipCode',
						'clientPricesExisting',
						'listSupplierPrices',
						'quantitiesIsNotIdentical',
						'isDistribution',
						'createdAt',
						'updatedAt',
						'specificSupplier',
					],
					{
						...clientPrice,
						productSalesPriceExcludingVATRectified:
							clientPrice.productSalesPriceExcludingVATRectified !== 0
								? clientPrice.productSalesPriceExcludingVATRectified
								: null,
						transportSellingPriceHtWithClientRfaRectified:
							clientPrice.transportSellingPriceHtWithClientRfaRectified !== 0
								? clientPrice.transportSellingPriceHtWithClientRfaRectified
								: null,
						additionalMargins: omit(['__typename', 'distributions'], clientPrice.additionalMargins),
						margins: omit(['__typename'], clientPrice.margins),
					}
				),
			},
			refetchQueries: [
				{
					query: GET_ALL_CLIENTS_PRICES,
				},
			],
		});
		if (errors) throw new Error(typeNotifications.Error);

		history.push(
			`/sellingPrice/${clientPrice.clientId}/${clientPrice.productId}/${clientPrice.quantity}/${clientPrice.numberVersion}`
		);
	};

	return { upsertClientPrice };
};
