import { TransporetrsFromModel } from 'admin/transportRules/definitions';
import { BoxShippingMode, TransportModeObjectType, TransportMoment } from 'graphql/types';
import { ProductFromModel } from 'product/definitions';
import addPercentageToValue from 'utils/addPercentageToValue';
import { DistributionsDetails, MAX_BOX_WEIGHT, MAX_UNIT_WEIGHT_GR, TOTAL_WEIGHT_PERCENT_VALUE } from '../definitions';

import floorOrCeil from 'utils/floorOrCeil';
import { calculateProductUnitWeightGr } from '../../helpers/calculateProductUnitWeightGr';

export type Transporter = TransporetrsFromModel[0] & {
	deliveryParcelPurchasePrice: number;
	isMechanizable: boolean;
	distributions: DistributionsDetails[];
};

export type TransportDeliveryParcelPurchasePrice = {
	selectedTransporter?: Transporter;
	transportersPrices?: Transporter[];
	lowerRoundedWholeDigitTotalWeight: number | null;
	remainingWeigh: number | null;
	isOneBox: boolean;
	maxBoxWeight: number;
};

const isNotMechanizable = (
	mechanizablePackage: number,
	maxMechanisableParcelLengthCm: number,
	maxMechanisableParcelWidthCm: number,
	longueurFormatFiniCm: number,
	largeurFormatFiniCm: number
) =>
	mechanizablePackage > maxMechanisableParcelLengthCm + maxMechanisableParcelWidthCm ||
	(largeurFormatFiniCm > maxMechanisableParcelLengthCm * longueurFormatFiniCm &&
		maxMechanisableParcelLengthCm * longueurFormatFiniCm > maxMechanisableParcelWidthCm) ||
	(largeurFormatFiniCm > maxMechanisableParcelWidthCm * longueurFormatFiniCm &&
		maxMechanisableParcelWidthCm * longueurFormatFiniCm > longueurFormatFiniCm);

const getWeightPrice = (
	isBeforeThirteenShippingMode: boolean,
	weigh: number,
	standardDeliveryPrices: number[],
	expressDeliveryPrices: number[]
) => (isBeforeThirteenShippingMode ? standardDeliveryPrices[weigh - 1] || 0 : expressDeliveryPrices[weigh - 1] || 0);

const getBoxPriceWithSupplementOverThirtyKg = (
	unitWeightGr: number,
	maxBoxWeight: number,
	standardDeliveryPrices: number[],
	expressDeliveryPrices: number[],
	supplementOverThirtyKg: number,
	isBeforeThirteenShippingMode: boolean,
	roundedUpperRemaininglWeigh: number
) => {
	if (unitWeightGr <= MAX_UNIT_WEIGHT_GR)
		return {
			boxPrice: getWeightPrice(
				isBeforeThirteenShippingMode,
				maxBoxWeight,
				standardDeliveryPrices,
				expressDeliveryPrices
			),
			roundedUpperRemaininglWeighPrice: getWeightPrice(
				isBeforeThirteenShippingMode,
				roundedUpperRemaininglWeigh,
				standardDeliveryPrices,
				expressDeliveryPrices
			),
		};

	if (maxBoxWeight <= MAX_BOX_WEIGHT)
		return {
			boxPrice: getWeightPrice(
				isBeforeThirteenShippingMode,
				maxBoxWeight,
				standardDeliveryPrices,
				expressDeliveryPrices
			),
			roundedUpperRemaininglWeighPrice: 0,
		};

	const thirtykgBoxPrice = getWeightPrice(
		isBeforeThirteenShippingMode,
		MAX_BOX_WEIGHT,
		standardDeliveryPrices,
		expressDeliveryPrices
	);
	const overThirtyKg = maxBoxWeight - MAX_BOX_WEIGHT;
	const boxPrice = thirtykgBoxPrice + overThirtyKg * supplementOverThirtyKg;

	return {
		boxPrice,
		roundedUpperRemaininglWeighPrice: 0,
	};
};

const getDeliveryParcelPurchasePriceWithHardlyAccessibleAreas = (
	deliveryParcelPurchasePrice: number,
	hardlyAccessibleAreas: { postalCode?: string | null }[],
	lowerRoundedWholeDigitTotalWeight: number,
	hardlyAccessibleArea: number,
	roundedUpperRemaininglWeigh: number,
	clientZipCode?: string
) => {
	const isHardlyAccessibleAreas = !!hardlyAccessibleAreas?.filter((item) => item.postalCode === clientZipCode)[0];
	return isHardlyAccessibleAreas
		? deliveryParcelPurchasePrice +
				hardlyAccessibleArea * (lowerRoundedWholeDigitTotalWeight + (roundedUpperRemaininglWeigh === 0 ? 0 : 1))
		: deliveryParcelPurchasePrice;
};

const getDeliveryParcelPurchasePriceWithNoRegularPickup = (
	deliveryParcelPurchasePrice: number,
	name: string,
	nonRegularPickup: number,
	fuelSurchargeRate: number,
	supplierPricestransportModes?: TransportModeObjectType[] | null
) => {
	const isRegularPickup = !!supplierPricestransportModes?.filter(
		(item) => item.title?.toUpperCase() === name.toUpperCase()
	)[0];

	return isRegularPickup
		? deliveryParcelPurchasePrice
		: deliveryParcelPurchasePrice + addPercentageToValue(fuelSurchargeRate, nonRegularPickup);
};

const getTransportDeliveryParcelPurchasePrice = (
	maxBoxWeight: number,
	deliveryParcel: BoxShippingMode,
	transportMoment: TransportMoment,
	transport: TransporetrsFromModel[0],
	roundedUpperRemaininglWeigh: number,
	lowerRoundedWholeDigitTotalWeight: number,
	deliveryDepartment: string,
	isNotMechanizableValue: boolean,
	unitWeightGr: number,
	clientZipCode?: string,
	supplierPricestransportModes?: TransportModeObjectType[] | null
) => {
	const {
		standardDeliveryPrices,
		expressDeliveryPrices,
		fuelSurchargeRate,
		corsicaDeliveryExtra,
		saturdayDeliveryExtra,
		nonMechanisableParcel,
		supplementOverThirtyKg,
		hardlyAccessibleAreas,
		hardlyAccessibleArea,
		name,
		nonRegularPickup,
	} = transport;
	const isBeforeThirteenShippingMode = deliveryParcel === BoxShippingMode.BeforeThirteen;

	const { boxPrice, roundedUpperRemaininglWeighPrice } = getBoxPriceWithSupplementOverThirtyKg(
		unitWeightGr,
		maxBoxWeight,
		standardDeliveryPrices,
		expressDeliveryPrices,
		supplementOverThirtyKg,
		isBeforeThirteenShippingMode,
		roundedUpperRemaininglWeigh
	);

	const boxPriceWithFuelSurchargeRate = addPercentageToValue(fuelSurchargeRate, boxPrice);
	const roundedUpperRemaininglWeighPriceWithFuelSurchargeRate = addPercentageToValue(
		fuelSurchargeRate,
		roundedUpperRemaininglWeighPrice
	);

	let deliveryParcelPurchasePrice =
		lowerRoundedWholeDigitTotalWeight * boxPriceWithFuelSurchargeRate +
		roundedUpperRemaininglWeighPriceWithFuelSurchargeRate;

	// is corse departement
	deliveryParcelPurchasePrice =
		deliveryDepartment?.includes('Haute-Corse') || deliveryDepartment?.includes('Corse-du-Sud')
			? deliveryParcelPurchasePrice + (lowerRoundedWholeDigitTotalWeight + 1) * corsicaDeliveryExtra
			: deliveryParcelPurchasePrice;

	// is Saturday
	deliveryParcelPurchasePrice =
		transportMoment === TransportMoment.Saturday
			? deliveryParcelPurchasePrice + saturdayDeliveryExtra
			: deliveryParcelPurchasePrice;
	deliveryParcelPurchasePrice = isNotMechanizableValue
		? deliveryParcelPurchasePrice + (lowerRoundedWholeDigitTotalWeight + 1) * nonMechanisableParcel
		: deliveryParcelPurchasePrice;

	deliveryParcelPurchasePrice = getDeliveryParcelPurchasePriceWithHardlyAccessibleAreas(
		deliveryParcelPurchasePrice,
		hardlyAccessibleAreas,
		lowerRoundedWholeDigitTotalWeight,
		hardlyAccessibleArea,
		roundedUpperRemaininglWeigh,
		clientZipCode
	);
	deliveryParcelPurchasePrice = getDeliveryParcelPurchasePriceWithNoRegularPickup(
		deliveryParcelPurchasePrice,
		name,
		nonRegularPickup,
		fuelSurchargeRate,
		supplierPricestransportModes
	);

	return deliveryParcelPurchasePrice;
};

export const getBestTransportDeliveryParcelPurchasePriceKnownDistributions = (
	maxBoxWeight: number,
	deliveryParcel: BoxShippingMode,
	deliveryDepartment: string,
	transportMoment: TransportMoment,
	selectedProduct: Pick<
		ProductFromModel,
		'productSupplierPrice' | 'largeurFormatFiniCm' | 'longueurFormatFiniCm' | 'paginationTotale' | 'grammageGr'
	>,
	transporters?: TransporetrsFromModel,
	distributionsWithWeight?: DistributionsDetails[],
	selectedTransporterId?: string | null,
	supplierPricestransportModes?: TransportModeObjectType[] | null,
	productUnitWeight?: number | null
): TransportDeliveryParcelPurchasePrice => {
	const largeurFormatFiniCm = Number(selectedProduct.largeurFormatFiniCm);
	const longueurFormatFiniCm = Number(selectedProduct.longueurFormatFiniCm);
	const mechanizablePackage = largeurFormatFiniCm + longueurFormatFiniCm;
	const unitWeightGr = calculateProductUnitWeightGr(selectedProduct, productUnitWeight);
	const transportersPrices = transporters?.map((transport) => {
		const { maxMechanisableParcelLengthCm, maxMechanisableParcelWidthCm } = transport;
		const isNotMechanizableValue = isNotMechanizable(
			mechanizablePackage,
			maxMechanisableParcelLengthCm,
			maxMechanisableParcelWidthCm,
			longueurFormatFiniCm,
			largeurFormatFiniCm
		);

		const distributions =
			distributionsWithWeight?.map((distributionItem) => {
				const productTotalWeightWithPercentValue = addPercentageToValue(
					TOTAL_WEIGHT_PERCENT_VALUE,
					distributionItem.weight
				);
				const lowerRoundedWholeDigitTotalWeight = Math.floor(productTotalWeightWithPercentValue / maxBoxWeight);
				const remainingWeigh = productTotalWeightWithPercentValue - lowerRoundedWholeDigitTotalWeight * maxBoxWeight;
				const roundedUpperRemaininglWeigh = Math.ceil(remainingWeigh);

				const deliveryParcelPurchasePrice = getTransportDeliveryParcelPurchasePrice(
					maxBoxWeight,
					deliveryParcel,
					transportMoment,
					transport,
					roundedUpperRemaininglWeigh,
					lowerRoundedWholeDigitTotalWeight,
					deliveryDepartment,
					isNotMechanizableValue,
					unitWeightGr,
					distributionItem.zip,
					supplierPricestransportModes
				);

				return {
					...distributionItem,
					deliveryParcelPurchasePrice,
					label: getDeliveryParcelPurchasePriceInfo(
						lowerRoundedWholeDigitTotalWeight === 0 && roundedUpperRemaininglWeigh === maxBoxWeight,
						maxBoxWeight,
						lowerRoundedWholeDigitTotalWeight,
						roundedUpperRemaininglWeigh,
						2,
						false
					),
				};
			}) ?? [];
		return {
			...transport,
			deliveryParcelPurchasePrice: distributions?.reduce((acc, curr) => acc + curr.deliveryParcelPurchasePrice, 0) || 0,
			isMechanizable: !isNotMechanizableValue,
			distributions,
		};
	});
	const bestTransporter = transportersPrices?.reduce((prev, curr) =>
		prev.deliveryParcelPurchasePrice < curr.deliveryParcelPurchasePrice ? prev : curr
	);
	const selectedTransporter = transportersPrices?.find((transporter) => transporter.id === selectedTransporterId);

	return {
		selectedTransporter: selectedTransporter ?? bestTransporter,
		transportersPrices,
		lowerRoundedWholeDigitTotalWeight: null,
		remainingWeigh: null,
		isOneBox: false,
		maxBoxWeight,
	};
};

const getWeightInformation = (unitWeightGr: number, productWeight: number, maxBoxWeight: number, quantity: number) => {
	const productTotalWeightWithPercentValue = addPercentageToValue(TOTAL_WEIGHT_PERCENT_VALUE, productWeight);
	if (unitWeightGr < MAX_UNIT_WEIGHT_GR) {
		const lowerRoundedWholeDigitTotalWeight = Math.floor(productTotalWeightWithPercentValue / maxBoxWeight);
		const remainingWeigh = productTotalWeightWithPercentValue - lowerRoundedWholeDigitTotalWeight * maxBoxWeight;
		return {
			lowerRoundedWholeDigitTotalWeight,
			remainingWeigh,
			roundedUpperRemaininglWeigh: Math.ceil(remainingWeigh),
			maxBoxWeightValue: maxBoxWeight,
		};
	}
	const value = (unitWeightGr + (TOTAL_WEIGHT_PERCENT_VALUE * unitWeightGr) / 100) / 1000;

	return {
		maxBoxWeightValue: floorOrCeil(value),
		lowerRoundedWholeDigitTotalWeight: quantity,
		remainingWeigh: 0,
		roundedUpperRemaininglWeigh: 0,
	};
};

export const getBestTransportDeliveryParcelPurchasePrice = (
	productTotalWeight: number,
	maxBoxWeight: number,
	deliveryParcel: BoxShippingMode,
	deliveryDepartment: string,
	transportMoment: TransportMoment,
	selectedProduct: Pick<
		ProductFromModel,
		'productSupplierPrice' | 'largeurFormatFiniCm' | 'longueurFormatFiniCm' | 'paginationTotale' | 'grammageGr'
	>,
	nbShippingPoints: number,
	quantity: number,
	isDistribution?: boolean,
	transporters?: TransporetrsFromModel,
	selectedTransporterId?: string | null,
	clientZipCode?: string,
	supplierPricesTransportModes?: TransportModeObjectType[] | null,
	productUnitWeight?: number | null
): TransportDeliveryParcelPurchasePrice => {
	const productWeight = isDistribution ? productTotalWeight / nbShippingPoints : productTotalWeight;
	const unitWeightGr = calculateProductUnitWeightGr(selectedProduct, productUnitWeight);

	const { maxBoxWeightValue, lowerRoundedWholeDigitTotalWeight, roundedUpperRemaininglWeigh } = getWeightInformation(
		unitWeightGr,
		productWeight,
		maxBoxWeight,
		quantity
	);

	const largeurFormatFiniCm = Number(selectedProduct.largeurFormatFiniCm);
	const longueurFormatFiniCm = Number(selectedProduct.longueurFormatFiniCm);
	const mechanizablePackage = largeurFormatFiniCm + longueurFormatFiniCm;

	const transportersPrices = transporters?.map((transport) => {
		const { maxMechanisableParcelLengthCm, maxMechanisableParcelWidthCm } = transport;

		const isNotMechanizableValue = isNotMechanizable(
			mechanizablePackage,
			maxMechanisableParcelLengthCm,
			maxMechanisableParcelWidthCm,
			longueurFormatFiniCm,
			largeurFormatFiniCm
		);

		const deliveryParcelPurchasePrice = getTransportDeliveryParcelPurchasePrice(
			maxBoxWeightValue,
			deliveryParcel,
			transportMoment,
			transport,
			roundedUpperRemaininglWeigh,
			lowerRoundedWholeDigitTotalWeight,
			deliveryDepartment,
			isNotMechanizableValue,
			unitWeightGr,
			clientZipCode,
			supplierPricesTransportModes
		);

		return {
			...transport,
			deliveryParcelPurchasePrice: isDistribution
				? deliveryParcelPurchasePrice * nbShippingPoints
				: deliveryParcelPurchasePrice,
			isMechanizable: !isNotMechanizableValue,
			distributions: [],
		};
	});

	const bestTransporter = transportersPrices?.reduce((prev, curr) =>
		prev.deliveryParcelPurchasePrice < curr.deliveryParcelPurchasePrice ? prev : curr
	);
	const selectedTransporter = transportersPrices?.find((transpoter) => transpoter.id === selectedTransporterId);

	return {
		selectedTransporter: selectedTransporter ?? bestTransporter,
		transportersPrices,
		lowerRoundedWholeDigitTotalWeight,
		remainingWeigh: roundedUpperRemaininglWeigh,
		isOneBox: lowerRoundedWholeDigitTotalWeight === 0 && roundedUpperRemaininglWeigh === maxBoxWeightValue,
		maxBoxWeight: maxBoxWeightValue,
	};
};

export const getDeliveryParcelPurchasePriceInfo = (
	isOneBox: boolean,
	maxBoxWeight: number,
	lowerRoundedWholeDigitTotalWeight: number,
	remainingWeigh: number,
	nbShippingPoints: number,
	isDistribution?: boolean
) => {
	const totalBoxesWithMaxBoxWeight = isDistribution
		? lowerRoundedWholeDigitTotalWeight * nbShippingPoints
		: lowerRoundedWholeDigitTotalWeight;
	const totalBoxesWithRemainingWeigh = isDistribution ? nbShippingPoints : 1;
	const getBoxLabel = (value: number) => (value > 1 ? 'cartons' : 'carton');

	const totalBoxesWithMaxBoxWeightInfo =
		totalBoxesWithMaxBoxWeight > 0
			? `${totalBoxesWithMaxBoxWeight} ${getBoxLabel(totalBoxesWithMaxBoxWeight)} de ${maxBoxWeight}kg ${
					totalBoxesWithMaxBoxWeight > 0 && remainingWeigh !== 0 ? '+ ' : ''
			  }`
			: '';
	const totalBoxesWithRemainingWeighInfo = `${
		remainingWeigh !== 0
			? `${totalBoxesWithRemainingWeigh} ${getBoxLabel(totalBoxesWithRemainingWeigh)} de ${remainingWeigh}kg`
			: ''
	}`;

	if (isOneBox) return `1 carton de ${maxBoxWeight}kg`;

	return remainingWeigh === maxBoxWeight
		? `${totalBoxesWithMaxBoxWeight + 1} ${getBoxLabel(totalBoxesWithMaxBoxWeight + 1)} de ${maxBoxWeight}kg`
		: `${totalBoxesWithMaxBoxWeightInfo}${totalBoxesWithRemainingWeighInfo}`;
};
