import React from 'react';
import OrderCockpitDetail from './OrderCockpitDetail';
import { useParams } from 'react-router-dom';
import OrdersCreationList from './OrdersList';

export const OrderCockpitRoute = () => {
	const { id: orderId } = useParams<{ id: string }>();
	if (orderId) {
		return <OrderCockpitDetail orderId={orderId} />;
	} else {
		return <OrdersCreationList isOrderCockpit={true} />;
	}
};
