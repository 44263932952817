import { GetCurrentUserQuery, GetUsersByClientIdsQuery } from './graphql/queries.definitions';

export enum DisplayInterface {
	DisplayRoleInterface = 'DisplayRoleInterface',
	DisplayUserForm = 'DisplayUserForm',
	DisplayUserInterface = 'DisplayUserInterface',
	DisplayUserList = 'DisplayUserList',
}

export interface UserClientListItem {
	name: string;
	id: string;
	isRoot?: boolean;
	parentClientId?: string;
}

export type CurrentUser = GetCurrentUserQuery['currentUser'];

export type UsersByClientIdsFromModel = GetUsersByClientIdsQuery['usersByClientIds'][0];
