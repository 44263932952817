import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ENTITY_TYPES_TITLES } from '../hooks/helpers';
import { Client, ClientStructure, EntityType } from '../../../../graphql/types';

const useClientStructuresField = () => {
	const { setFieldValue } = useFormikContext<Client>();

	const [{ value: structures }, , { setValue: setClientStructures }] = useField<ClientStructure[]>('structures');

	const addStructure = (type: EntityType) => {
		const structure: ClientStructure = {
			type: type,
			title: [EntityType.SubGroup1, EntityType.SubGroup2, EntityType.SubGroup3, EntityType.SubGroup4].includes(type)
				? ''
				: ENTITY_TYPES_TITLES[type],
			isBillable: false,
			level: structures.length,
		};

		setClientStructures([...structures, structure]);
	};

	const removeStructure = (structureIndex: number) => {
		setClientStructures(
			structures
				.filter((structure, index) => index !== structureIndex)
				.map((structure, index) => ({
					...structure,
					level: index,
				}))
		);
	};

	const swapStructures = (index1: number, index2: number) => {
		setClientStructures(
			structures.map((structure, index) => {
				return index === index1
					? { ...structures[index2], level: index }
					: index === index2
					? { ...structures[index1], level: index }
					: structure;
			})
		);
	};

	const firstStructure = structures[0];

	// Client type should always match the structure at index zero
	useEffect(() => {
		if (firstStructure) {
			setFieldValue('type', firstStructure.type);
		}
	}, [firstStructure, setFieldValue]);

	return { structures, addStructure, removeStructure, swapStructures };
};

export default useClientStructuresField;
