import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Modal from '@mui/material/Modal';

type props = {
	title: string;
	body: string;
	openModal: boolean;
	handleClose: Function;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
			width: 400,
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			left: '40%',
			top: '40%',
		},
	})
);

export default function MyModal(props: props) {
	const classes = useStyles();

	const handleClose = () => {
		props.handleClose();
	};

	return (
		<Modal open={props.openModal} onClose={handleClose}>
			<div className={classes.paper}>
				<h2 id="simple-modal-title">{props.title}</h2>
				<p id="simple-modal-description">{props.body}</p>
			</div>
		</Modal>
	);
}
