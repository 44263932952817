import { Box } from '@mui/material';
import { Formik } from 'formik';
import { ClientQuoteItem } from 'graphql/types';
import { HeadLines, Variant } from 'hooks/useManualTabs';
import React, { useMemo } from 'react';
import { Distribution, groupedDistributionByAddresses } from 'utils/distribution';
import { ClientQuoteForm } from './form/ClientQuoteForm';
import { ClientQuoteModel, initialClientQuote } from './form/definition';
import { getClientQuoteFormSchema } from './form/validationSchem';
import useClientQuoteUpsert from './hooks/useClientQuoteUpsert';

const ClientQuoteDetail = ({ quoteId }: { quoteId: string }) => {
	const { clientQuote, handleClientQuoteSubmit } = useClientQuoteUpsert(quoteId);

	const headlines: HeadLines[] = [
		{
			title: quoteId ? `Devis n° ${quoteId}` : 'Nouveau devis',
			fontSize: 'h2' as Variant,
		},
	];

	const onSubmit = async (restClientQuote: ClientQuoteModel) => {
		await handleClientQuoteSubmit(restClientQuote);
	};

	const initialValues = useMemo(
		() =>
			clientQuote
				? {
						...initialClientQuote,
						...clientQuote,

						distributionsGroupedByAddress: groupedDistributionByAddresses(
							(clientQuote.clientQuoteDistributions as Distribution[]) || [],
							clientQuote.clientQuoteItem as ClientQuoteItem[]
						),
						clientQuoteDistributions: clientQuote.clientQuoteDistributions as Distribution[],
						updatedAt: new Date(clientQuote.updatedAt).toLocaleDateString(),
						createdAt: new Date(clientQuote.createdAt).toLocaleDateString(),
						clientQuoteCreatedAt: clientQuote.createdAt,
				  }
				: { ...initialClientQuote },
		[clientQuote]
	);
	return (
		<Box mx={7}>
			<Formik<
				ClientQuoteModel & {
					clientQuoteItemIdsToDelete?: string[];
				}
			>
				validateOnChange={false}
				validationSchema={getClientQuoteFormSchema}
				initialValues={initialValues}
				enableReinitialize
				onSubmit={async ({ ...restClientQuote }) => {
					await onSubmit(restClientQuote);
				}}
			>
				<ClientQuoteForm headlines={headlines} nbShippingPoints={clientQuote?.nbShippingPoints} />
			</Formik>
		</Box>
	);
};

export default ClientQuoteDetail;
