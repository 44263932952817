import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ListSupportsSelect from 'admin/support/ListSupportsSelect';
import { MenuComponentProps } from 'Header/definitions';
import useSupportsQuery from 'admin/support/hooks/useSupportsQuery';
import { Support, SupportCategory } from 'graphql/types';

interface ListProductsMenuProps extends MenuComponentProps {}

const ListProductsMenu = ({ onClose }: ListProductsMenuProps): JSX.Element => {
	const history = useHistory();
	const { supports } = useSupportsQuery();
	const handleSupportSelected = (rowSupport: Support) => {
		history.push(`/product/${rowSupport.id}`);
		onClose?.();
	};

	const filteredSupports = supports?.filter((item) => item.category && item.category === SupportCategory.Plv);

	return (
		<Box>
			{filteredSupports && (
				<ListSupportsSelect supports={filteredSupports} path={'/product/'} onSupportSelected={handleSupportSelected} />
			)}
		</Box>
	);
};

export default ListProductsMenu;
