import { useLazyQuery } from '@apollo/client';
import { GET_CONFIG_QUOTE } from '../graphql/queries';
import { GetQuoteAttributesQuery, GetQuoteAttributesQueryVariables } from '../graphql/queries.definitions';

const useConfigQuotesQuery = () => {
	const [getQuoteAttributes, { data }] = useLazyQuery<GetQuoteAttributesQuery, GetQuoteAttributesQueryVariables>(
		GET_CONFIG_QUOTE,
		{
			partialRefetch: true,
		}
	);
	return { quoteAttributes: data?.quoteAttributes, getQuoteAttributes };
};

export default useConfigQuotesQuery;
