import * as React from 'react';
import { Box, Fade, CircularProgress } from '@mui/material';

type props = {
	loading: boolean;
};

export default function Loading(props: props) {
	return (
		<Box position="absolute" top="50%" left="50%">
			<Fade in={props.loading} unmountOnExit>
				<CircularProgress />
			</Fade>
		</Box>
	);
}
