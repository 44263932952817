import React, { memo } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import OrderTableCell from './OrderTableCell';
import { useTableStyles } from '../sharedStyles';
import { AlignTableCell } from '../../../../utils/definition';

interface OrderTableHeadProps {
	columns: string[];
	isColorBlue?: boolean;
	align?: AlignTableCell;
}

const OrderTableHead = ({ columns, isColorBlue, align }: OrderTableHeadProps): JSX.Element => {
	const classes = useTableStyles({ isColorBlue });

	return (
		<TableHead>
			<TableRow className={classes.root}>
				{columns.map((column) => (
					<OrderTableCell align={align} key={column} label={column} variant="h5" />
				))}
			</TableRow>
		</TableHead>
	);
};

export default memo(OrderTableHead);
