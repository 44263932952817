import { useMutation } from '@apollo/client';
import { UPDATE_ROLE_BY_ID } from '../graphql/mutations';
import { UpdateRoleByIdMutation, UpdateRoleByIdMutationVariables } from '../graphql/mutations.definitions';
import { GET_ROLES, GET_ROLE_BY_ID } from '../graphql/queries';

export const useRoleUpdate = () => {
	const [updateRoleMutation] = useMutation<UpdateRoleByIdMutation, UpdateRoleByIdMutationVariables>(UPDATE_ROLE_BY_ID);
	const updateRole = async (roleInput: UpdateRoleByIdMutationVariables) => {
		const result = await updateRoleMutation({
			variables: roleInput,
			refetchQueries: [
				{
					query: GET_ROLES,
				},
				{
					query: GET_ROLE_BY_ID,
					variables: { roleId: roleInput.roleId },
				},
			],

			awaitRefetchQueries: true,
		});
		if (!result.data) throw new Error();
		return result;
	};

	return updateRole;
};
