import { Transporter } from '../../graphql/types';
import { GetTransportersQuery } from './graphql/queries.definitions';

export interface TransportTab {
	ref: React.RefObject<HTMLDivElement>;
	title: string;
	index: number;
}

export enum TransporterType {
	standardDelivery,
	expressDelivery,
}

export type TransporterKey = keyof Omit<
	Transporter,
	| 'id'
	| 'name'
	| 'standardDeliveryPrices'
	| 'expressDeliveryPrices'
	| 'hardlyAccessibleAreas'
	| 'isActif'
	| '__typename'
>;

export type TransporetrsFromModel = GetTransportersQuery['transporters'];
