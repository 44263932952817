import React, { ChangeEvent, useContext, useState } from 'react';
import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import OutlinedButton from '../../../components/buttons/OutlinedButton';
import { LayoutField } from '../../../components/form/LayoutField';
import { FormikSwitchField } from '../../../components/form/FormikSwitchField';
import colors from '../../../app/theme/colors.scss';
import { useField } from 'formik';
import usePopin from '../../../hooks/usePopin';
import StoreDescriptions from './StoreDescriptions';
import { Distribution } from '../../../graphql/types';
import { SubmitAndResetButton } from '../../../components/buttons/submit-and-reset.button';
import { IsShowSellingPriceSelectedProps } from '../definitions';
import PricesProductContext from 'context/pricesProductContext/PricesProductContext';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import usePrevious from 'hooks/usePrevious';
import { ConditionnmentList } from 'components/SupportCharacteristics/definition';

const DISTRIBUTION_EMPTY_OBJECT: Distribution & { id: string } = { id: '', zip: '', quantity: null };

export const createDistributionsData = (
	nbDistributions: number,
	conditionnementList: ConditionnmentList[] = []
): (Distribution & { id: string })[] => {
	const distributionArray = Array.from(Array(nbDistributions), () => {
		return { ...DISTRIBUTION_EMPTY_OBJECT };
	});

	for (let i = 0; i < distributionArray.length; i++) {
		distributionArray[i].id = `${i}`;
		distributionArray[i].quantity = conditionnementList[i]?.numberOfExLots || null;
	}

	return distributionArray;
};

export const ClientPriceDistribution = ({ isConsultSellingPrice }: IsShowSellingPriceSelectedProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const [field] = useField<boolean | undefined>('isDistribution');
	const [distributions, , distributionHelpers] = useField<(Distribution & { id: string })[]>('distributions');
	const [nbShippingPoints, , nbShippingPointsHelpers] = useField<number>('nbShippingPoints');
	const [distributionsData, setDistributionsData] = useState<(Distribution & { id: string })[]>([]);
	const [initialDistributionsData, setInitialDistributionsData] = useState<(Distribution & { id: string })[]>([]);

	const [quantity] = useField<number>('quantity');

	const context = useContext(PricesProductContext);

	const conditionnement = context?.productFilter?.conditionnement || { numberOfLots: 1, conditionnementList: [] };
	const previousConditionnement = usePrevious(conditionnement);
	const previousDistributions = usePrevious(distributions);

	const conditionnementStringify = JSON.stringify(conditionnement); // we can replace this by a useDeepEffect
	const distributionsStringify = JSON.stringify(distributions); // we can replace this by a useDeepEffect

	useEffect(() => {
		const { numberOfLots, conditionnementList } = JSON.parse(conditionnementStringify);
		const { value } = JSON.parse(distributionsStringify);

		if (conditionnementStringify !== JSON.stringify(previousConditionnement)) {
			const nbDistributions = numberOfLots < 2 ? 2 : numberOfLots;
			const distributionsArray = createDistributionsData(nbDistributions, conditionnementList);
			setDistributionsData(distributionsArray);
			setInitialDistributionsData(distributionsArray);
			return;
		}

		if (distributionsStringify !== JSON.stringify(previousDistributions)) {
			if (value?.length < 1) {
				const nbDistributions = numberOfLots < 2 ? 2 : numberOfLots;
				const distributionsArray = createDistributionsData(nbDistributions, conditionnementList);
				setDistributionsData(distributionsArray);
				setInitialDistributionsData(distributionsArray);
				return;
			}

			setDistributionsData(value);
			setInitialDistributionsData(value);
		}
	}, [conditionnementStringify, distributionsStringify, previousConditionnement, previousDistributions]);

	const handleClosePopin = () => {
		setDistributionsData(initialDistributionsData);
		distributionHelpers.setValue(initialDistributionsData);
	};

	const handleSubmit = () => {
		const sumDistributionQuantity = distributionsData
			.map((item) => Number(item.quantity))
			.reduce((prev, next) => prev + next);

		if (sumDistributionQuantity !== quantity.value) {
			enqueueSnackbar(
				'Attention, le total des quantités indiquées dans la répartition est différent du total d’impression demandé',
				{ variant: 'error' }
			);
			return;
		}

		closePopin();
		nbShippingPointsHelpers.setValue(distributionsData?.length);
		distributionHelpers.setValue(distributionsData);
	};

	const nbShippingPointsChange = (e: ChangeEvent<HTMLInputElement>) => {
		const nbShippingPoint = +e.target.value;
		nbShippingPointsHelpers.setValue(nbShippingPoint);

		const delta = nbShippingPoint - distributionsData.length;
		const distributionsDataToAdd = createDistributionsData(
			distributionsData.length + delta,
			conditionnement.conditionnementList
		);
		distributionHelpers.setValue(distributionsDataToAdd);
	};

	const [openQuotaFilePopin, renderQuotaFilePopin, closePopin] = usePopin({
		headerTitle: 'Répartition par magasin',
		maxWidth: 'sm',
		fullWidth: true,
		isDisplayHeader: true,
		bottomFooter: (
			<SubmitAndResetButton handleCancel={handleClosePopin} handleSubmit={handleSubmit} submitBtnTitle="Ajouter" />
		),
		handleClosePopinCustom: handleClosePopin,
	});

	return (
		<Box my={6} mx={20}>
			<Grid container>
				<Grid xs={12} item>
					<Grid container justifyContent="space-between">
						<Grid xs={6} item>
							<Typography variant="h2">Répartition</Typography>
						</Grid>
						<Grid xs={6} item>
							<>
								<Box display="flex" justifyContent="flex-end" alignItems="center">
									{field.value && (
										<>
											<OutlinedButton
												disabled={isConsultSellingPrice}
												handleClickBtn={() => null}
												title="Intégrer une répartition"
											/>
											<Button
												disabled={isConsultSellingPrice}
												onClick={openQuotaFilePopin}
												title="Créer la répartition"
												aria-label="create-distribution"
											>
												Créer la répartition
											</Button>
										</>
									)}

									{distributions.value && (
										<OutlinedButton
											disabled={isConsultSellingPrice}
											handleClickBtn={() => null}
											title="Visualiser la répartition"
										/>
									)}
								</Box>

								{renderQuotaFilePopin(
									<StoreDescriptions
										setDistributionsData={setDistributionsData}
										distributionsData={distributionsData}
									/>
								)}
							</>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} item>
					<Box width="75%" m="26px auto" display="flex" alignItems="center" justifyContent="space-between">
						<Grid xs={6} item>
							<Box width="50%" ml={3}>
								<LayoutField label="Y a-t-il une répartition à prévoir?">
									<FormikSwitchField testid="is-distribution" name="isDistribution" disabled={isConsultSellingPrice} />
								</LayoutField>
							</Box>
						</Grid>
						<Grid xs={6} item>
							<Box width="50%" ml={3}>
								<LayoutField label="Nombre de points de livraison">
									<FormControl fullWidth>
										<Box width="60%" bgcolor={field.value ? colors.white : colors.grey}>
											<TextField
												data-testid="nb-shipping-points"
												type="number"
												fullWidth
												name="nbShippingPoints"
												variant="outlined"
												inputProps={{ min: 2 }}
												disabled={!field.value}
												value={nbShippingPoints.value}
												onChange={nbShippingPointsChange}
											/>
										</Box>
									</FormControl>
								</LayoutField>
							</Box>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
