import React, { useEffect, useState } from 'react';
import AppListTable from 'components/tables/list-table/AppListTable';
import useQuoteDistributionTable from './hooks/useQuoteDistributionTable';
import { useField, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { ClientQuote, ClientQuoteItem } from 'graphql/types';
import useClientAndAllChildrensWithShippingAdressQuery from 'clients/hooks/useClientAndAllChildrensWithShippingAdressQuery';
import { Box, Button, CircularProgress } from '@mui/material';
import {
	ClientWithShippingAdresses,
	DistributionsGroupedByAddresses,
	flattenDistributionsGroupedByAddresses,
} from 'utils/distribution';
import { useApolloClient } from '@apollo/client';
import { GET_CLIENT_PRICES_FROM_DISTRIBUTIONS } from 'client-quote/graphql/queries';
import { LoadingButton } from '@mui/lab';
import { setClientPricesToQuoteItems } from '../helpers';
import { typeNotifications } from 'utils/definition';
const _cloneDeep = require('lodash.clonedeep');
interface QuoteDistributionModalProps {
	closePopin: () => void;
}

export const needToCreateNewDataForDistributionTable = (
	distributionsGroupedByAddress: DistributionsGroupedByAddresses[],
	nbShippingPoints: number,
	clientQuoteItems?: ClientQuoteItem[] | null
): boolean => {
	if (!distributionsGroupedByAddress || distributionsGroupedByAddress.length === 0) {
		return true;
	}

	if (distributionsGroupedByAddress.length !== nbShippingPoints) {
		return true;
	}

	if (distributionsGroupedByAddress[0].productIdsQuantities.length !== clientQuoteItems?.length) {
		return true;
	}

	return false;
};

export const createNewDataForDistributionTable = (
	nbShippingPoints: number,
	clientQuoteItems?: ClientQuoteItem[] | null
): DistributionsGroupedByAddresses[] => {
	const productIdsQuantities = [];
	if (clientQuoteItems) {
		for (const item of clientQuoteItems) {
			const productObject = {
				productId: item.product.id,
				quantity: 0,
				productName: item.productName,
				globalQuantity: item.quantity,
				transportMoment: item.transportMoment,
				deliveryParcel: item.deliveryParcel
			};
			productIdsQuantities.push({ ...productObject });
		}
	}

	const dataForDistributionTable = [];
	for (let i = 0; i < nbShippingPoints; i++) {
		dataForDistributionTable.push({
			clientId: '',
			clientShippingAddressId: '',
			productIdsQuantities: _cloneDeep(productIdsQuantities),
		});
	}
	return dataForDistributionTable;
};

export const hasDuplicateClientsAndShippingAddressesId = (
	distributionsGroupedByAddresses: DistributionsGroupedByAddresses[]
): boolean => {
	const cleanData = distributionsGroupedByAddresses.filter(
		(distribution) => distribution.clientId && distribution.clientShippingAddressId
	);
	const uniqueValues = new Set(cleanData.map((item) => item?.clientId && item?.clientShippingAddressId));

	if (uniqueValues.size < cleanData.length) {
		return true;
	}

	return false;
};

const QuoteDistributionModal = ({ closePopin }: QuoteDistributionModalProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const [dataForDistributionTable, setDataForDistributionTable] = useState<DistributionsGroupedByAddresses[]>([]);
	const [loadingGetPrices, setLoadingGetPrices] = useState<boolean>(false);
	const [distributionsGroupedByAddress, , helpersDistributionsGroupedByAddress] = useField(
		'distributionsGroupedByAddress'
	);

	const { values, setFieldValue } = useFormikContext<ClientQuote>();
	const { clientId, nbShippingPoints, clientQuoteItem, version } = values;

	const { clientAndAllChildrensWithShippingAdress, loading } = useClientAndAllChildrensWithShippingAdressQuery(
		clientId ?? ''
	);

	const apolloClient = useApolloClient();

	useEffect(() => {
		const needToCreateNewDataForDistributionTableValue = needToCreateNewDataForDistributionTable(
			distributionsGroupedByAddress.value,
			nbShippingPoints,
			clientQuoteItem
		);
		if (needToCreateNewDataForDistributionTableValue) {
			const array = createNewDataForDistributionTable(nbShippingPoints, clientQuoteItem);
			setDataForDistributionTable(array);
		} else {
			setDataForDistributionTable(distributionsGroupedByAddress.value);
		}
	}, [distributionsGroupedByAddress.value, nbShippingPoints, clientQuoteItem]);

	const tableInstance = useQuoteDistributionTable(
		dataForDistributionTable,
		clientAndAllChildrensWithShippingAdress as ClientWithShippingAdresses[],
		setDataForDistributionTable
	);

	const handleSaveQuoteDistributionAndCalculatePrice = async () => {
		const isHasDuplicateClientsAndShippingAddressesId = hasDuplicateClientsAndShippingAddressesId(
			dataForDistributionTable
		);

		if (isHasDuplicateClientsAndShippingAddressesId) {
			enqueueSnackbar(`Au moins une des adresses de livraison est en double`, { variant: 'error' });
			return;
		}

		const flatDistributions = flattenDistributionsGroupedByAddresses(dataForDistributionTable);
		if (flatDistributions.length === 0) {
			enqueueSnackbar(`La distribution réalisée n'est pas conforme`, { variant: 'error' });
			return;
		}

		helpersDistributionsGroupedByAddress.setValue(dataForDistributionTable);
		setFieldValue('clientQuoteDistributions', flatDistributions);

		try {
			setLoadingGetPrices(true);
			const filters = {
				clientId: clientId,
				numberVersion: version,
				distributions: flatDistributions,
			};
			const { data } = await apolloClient.query({
				query: GET_CLIENT_PRICES_FROM_DISTRIBUTIONS,
				variables: {
					filters,
				},
				fetchPolicy: 'no-cache',
			});

			if (!data.getClientPricesFromDistributions) {
				enqueueSnackbar(`Le calcul de prix avec répartition n'est pas conforme`, { variant: typeNotifications.Error });
				return;
			}

			if (!clientQuoteItem) {
				enqueueSnackbar(`Il n'y a pas de produit ajouté au devis`, { variant: typeNotifications.Error });
				return;
			}

			const newClientQuoteItems = setClientPricesToQuoteItems(clientQuoteItem, data.getClientPricesFromDistributions);
			setFieldValue('clientQuoteItem', newClientQuoteItems);
			enqueueSnackbar(`Le calcul de prix avec répartition est réalisé`, { variant: typeNotifications.Success });
			closePopin();
		} catch (error) {
			enqueueSnackbar(`Une erreur est survenue durant le calcul de prix avec répartition`, {
				variant: typeNotifications.Error,
			});
		} finally {
			setLoadingGetPrices(false);
		}
	};

	const handleClearQuoteDistribution = () => {
		const array = createNewDataForDistributionTable(nbShippingPoints, clientQuoteItem);
		setDataForDistributionTable(array);
		helpersDistributionsGroupedByAddress.setValue(array);
	};

	return (
		<Box mb={4} px={2}>
			{loading ? <CircularProgress /> : <AppListTable tableInstance={tableInstance} hasFooter />}

			<Box display="flex" justifyContent="flex-end" marginTop={10}>
				<Button color="primary" onClick={handleClearQuoteDistribution}>
					Réinitialiser
				</Button>
				<LoadingButton
					onClick={handleSaveQuoteDistributionAndCalculatePrice}
					loading={loadingGetPrices}
					variant="contained"
				>
					Calculer les prix avec répartitions
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default QuoteDistributionModal;
