import { Box, Typography } from '@mui/material';
import colors from '../../app/theme/colors.scss';
import React from 'react';
import { FabricationTimesQuery } from '../../clientPrice/graphql/queries.definitions';
import DashedHeader from 'components/DashedHeader';

interface FabricationTimesProps {
	fabricationTimes?: FabricationTimesQuery['fabricationTimes'];
	hasProductionTimes?: boolean;
}

const FabricationTimes = ({ fabricationTimes, hasProductionTimes }: FabricationTimesProps) => {
	if (!hasProductionTimes) {
		return null;
	}

	return (
		<Box alignSelf="flex-end">
			<DashedHeader>
				<Box>
					<Typography variant="h3">Délai de production en jour </Typography>

					<Typography> (pour les commandes passées avant 10h)</Typography>
				</Box>
			</DashedHeader>

			<Typography>
				Délai Standard:
				<Box component="span" color={colors.gray400} style={{ float: 'right' }}>
					{fabricationTimes?.standardMode ?? 'Non renseigné'}
				</Box>
			</Typography>
			<Typography color="textPrimary" style={{ marginTop: 8 }}>
				Délai Express:
				<Box component="span" color={colors.gray400} style={{ float: 'right' }}>
					{fabricationTimes?.expressMode ?? 'Non renseigné'}
				</Box>
			</Typography>
		</Box>
	);
};

export default FabricationTimes;
