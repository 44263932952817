import React, { createContext, useState, ReactNode, useCallback } from 'react';
import { PricesProductContextType, PricesProductGlobalContext, ProductFilter, SelectedProduct } from './definition';

const initialValues = {
	selectedProduct: null,
	productFilter: {},
	selectedSupportId: '',
	supplierPricesLength: 0,
	quantity: 0,
	productTotalWeight: 0,
	setSelectedProduct: (selectedProduct?: SelectedProduct | null) => undefined,
	setSelectedSupportId: () => '',
	setProductFilter: (productFilter?: ProductFilter | null, key?: string | null) => undefined,
	setSupplierPricesLength: () => undefined,
	setQuantity: () => undefined,
	setProductTotalWeight: () => undefined,
};

const PricesProductContext = createContext<PricesProductGlobalContext>(initialValues);
export const PricesProductProvider = ({ children }: { children: ReactNode }) => {
	const initialState = {
		selectedSupportId: '',
		supplierPricesLength: 0,
		quantity: 0,
		productTotalWeight: 0,
	};
	const [state, setState] = useState<PricesProductContextType>(initialState);
	const setSelectedProduct = useCallback(
		(selectedProduct?: SelectedProduct | null): void => {
			setState((prevState) =>
				!selectedProduct
					? {
							...prevState,
							selectedProduct: null,
					  }
					: {
							...prevState,
							selectedProduct: { ...prevState.selectedProduct, ...selectedProduct },
					  }
			);
		},
		[setState]
	);

	const setSelectedSupportId = useCallback(
		(selectedSupportId: string): void => {
			setState((prevState) => ({
				...prevState,
				selectedSupportId,
			}));
		},
		[setState]
	);

	const setQuantity = useCallback(
		(quantity: number): void => {
			setState((prevState) => ({
				...prevState,
				quantity,
			}));
		},
		[setState]
	);

	const setProductTotalWeight = useCallback(
		(productTotalWeight: number): void => {
			setState((prevState) => ({
				...prevState,
				productTotalWeight,
			}));
		},
		[setState]
	);
	const setProductFilter = useCallback(
		(filter?: ProductFilter | null, key?: string | null): void => {
			setState((prevState) => {
				return key
					? {
							...prevState,
							productFilter: { ...prevState.productFilter, ...filter },
					  }
					: {
							...prevState,
							productFilter: filter ? { ...prevState.productFilter, ...filter } : {},
					  };
			});
		},
		[setState]
	);

	const setSupplierPricesLength = useCallback(
		(supplierPricesLength: number): void => {
			setState((prevState) => ({
				...prevState,
				supplierPricesLength,
			}));
		},
		[setState]
	);

	const updatedInitialState: PricesProductGlobalContext = {
		...state,
		setSelectedProduct,
		setSelectedSupportId,
		setProductFilter,
		setSupplierPricesLength,
		setQuantity,
		setProductTotalWeight,
	};

	return <PricesProductContext.Provider value={updatedInitialState}>{children}</PricesProductContext.Provider>;
};

export default PricesProductContext;
