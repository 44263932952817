import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import usePopin from '../../../../hooks/usePopin';
import { SubmitAndResetButton } from '../../../../components/buttons/submit-and-reset.button';
import { useCharacteristicsQuery } from '../../hooks/useCharacteristicsQuery';
import { CharacteristicsList } from './CharacteristicsList';
import { CharacteristicsCard } from './CharacteristicsCard';
import { CharacteristicsFamilyProps, PrintValuesType } from '../../definitions';
import { ButtonType, PrintValue } from '../../../../graphql/types';
import colors from '../../../../app/theme/colors.scss';
import {
	addingAPrintValueToAnAttribute,
	removingAnAttributeFromItsNameAndId,
	removingAPrintValueFromAnAttribute,
} from '../../helpers';

export const CharacteristicsFamily = ({ name, attributes }: CharacteristicsFamilyProps): JSX.Element => {
	const { setFieldValue, values } = useFormikContext<PrintValuesType>();
	const [field, , helpers] = useField<string | undefined | null>(name);
	const [filterFamilyCharacteristics, setFilterFamilyCharacteristics] = useState<string>('');
	const { characteristicsPrintValues, getCharacteristicsByFamily } = useCharacteristicsQuery(
		filterFamilyCharacteristics
	);
	const [isHidePopin, setIsHidePopin] = useState<boolean>(false);
	const [attributeDetails, setAttributeDetails] = useState<null | string>(null);
	const [isAttributes, setIsAttributes] = useState<boolean>(true);
	const [currentAttributeId, setCurrentAttributeId] = useState<string>('');
	const [listItemPrintValues, setListItemPrintValues] = useState<Omit<PrintValue, 'attribute'>[]>([]);
	const [isSelected, setIsSelected] = useState<boolean>(false);
	const [isEditable, setIsEditable] = useState<boolean>(false);
	const [hasDeleted, setHasDeleted] = useState<boolean>(false);
	const [hasDeletedAttribute, setHasDeletedAttribute] = useState<boolean>(false);
	const [editValueFieldPrintValue, setEditValueFieldPrintValue] = useState<string>('');
	const [editValueFieldPrintName, setEditValueFieldPrintName] = useState<string>('');
	const [idElementToDelete, setIdElementToDelete] = useState<string>('');
	const [nameElementToDelete, setNameElementToDelete] = useState<string>('');

	useEffect(() => {
		if (currentAttributeId.length) {
			const attribute = attributes.filter((attribute) => attribute.id === currentAttributeId);
			attribute.length && setListItemPrintValues(attribute[0].values);
		}
	}, [attributes, currentAttributeId]);

	const handleSubmit = () => {
		return hasDeleted
			? deletePrintValue
			: hasDeletedAttribute
			? deleteAttribute
			: isEditable
			? handleEditablePrintValue
			: isAttributes
			? handleAddedAttributes
			: handleAddedPrintValue;
	};

	const handleTitle = (title: string = 'Suppression') => {
		return hasDeleted || hasDeletedAttribute
			? title
			: isEditable
			? 'Modifier la valeur'
			: isAttributes
			? 'Ajouter une caractéristique'
			: 'Ajouter une valeur';
	};

	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: handleTitle(),
		maxWidth: 'xs',
		fullWidth: true,
		isDisplayHeader: true,
		handleClosePopinCustom: handleClosePopin,
		bottomFooter: (
			<SubmitAndResetButton
				bkgColor={hasDeleted || hasDeletedAttribute ? colors.error : undefined}
				handleCancel={handleClosePopin}
				handleSubmit={handleSubmit()}
				submitBtnTitle={handleTitle('Supprimer')}
				cancelBtnTitle="Annuler"
			/>
		),
	});

	function handleClosePopin() {
		setIsHidePopin(false);
		helpers.setError(undefined);
		setIsEditable(false);
		setHasDeleted(false);
		setHasDeletedAttribute(false);
		setEditValueFieldPrintValue('');
		values.printValues.pop();
	}

	function handleEditablePrintValue() {
		setIsEditable(false);
		helpers.setError(undefined);
		setIsHidePopin(false);
		closePopin();
	}

	function handleAddedAttributes() {
		if (field.value && field.value.length) {
			const print = field.value.split('_');
			const printValueSelected = {
				id: print[0],
				name: print[1],
				values: [],
				type: ButtonType.Checkbox,
			};

			const index = attributes.findIndex((attributes) => attributes.id === printValueSelected.id);

			if (index !== -1) helpers.setError('Ce caractéristique a déjà été ajouté');
			else {
				const newAttributes = values.prints.map((print) => {
					if (print.family === name) print.attributes = [printValueSelected, ...print.attributes];
					return print;
				});
				setFieldValue('prints', newAttributes);
				setHasDeletedAttribute(false);
				initialModal();
			}
		} else {
			helpers.setError('Ce champs est requis');
		}
	}

	function handleAddedPrintValue() {
		if (values.printValues.length && listItemPrintValues) {
			if (uniquenessPrintValue(values.printValues[0])) {
				const newPrintValues = addingAPrintValueToAnAttribute(values, currentAttributeId);
				setFieldValue('prints', newPrintValues);
				values.printValues.pop();
				initialModal();
			}
		} else {
			helpers.setError('Ce champs est requis');
		}
	}

	function deleteAttribute(): void {
		const newAttributes = removingAnAttributeFromItsNameAndId(values, idElementToDelete, name);
		setFieldValue('prints', newAttributes);
		setIsAttributes(true);
		setAttributeDetails(null);
		initialModal();
	}

	function deletePrintValue(): void {
		const newPrintValues = removingAPrintValueFromAnAttribute(values, idElementToDelete, nameElementToDelete);
		setFieldValue('prints', newPrintValues);
		listItemPrintValues.length === 1 && setAttributeDetails(null);
		initialModal();
	}

	const handleOpenPopinToAddAttribute = (): void => {
		getCharacteristicsByFamily();
		setFilterFamilyCharacteristics(name.toLowerCase());
		setIsAttributes(true);
		setIsHidePopin(true);
		openPopin();
	};

	const handleOpenPopinToAddPrintValue = (): void => {
		setIsAttributes(false);
		setIsHidePopin(true);
		openPopin();
	};

	const initialModal = (): void => {
		name in values && delete values[name];
		helpers.setError(undefined);
		setIsHidePopin(false);
		closePopin();
	};

	const handleAttributeSelected = (name: string, id: string): void => {
		setIsSelected(true);
		setAttributeDetails(name);
		setCurrentAttributeId(id);
		const attribute = attributes.filter((attribute) => attribute.id === id);
		setListItemPrintValues(attribute[0].values);
	};

	const handleOpenPopinToDeleteAttribute = (id: string): void => {
		initialStateOpeningPopin(id, true);
	};

	const handleOpenPopinToDeletePrintValue = (id: string, printValue: string): void => {
		setNameElementToDelete(printValue);
		initialStateOpeningPopin(id);
	};

	const initialStateOpeningPopin = (id: string, isToggle: boolean = false): void => {
		setHasDeleted(!isToggle);
		setIsHidePopin(true);
		setHasDeletedAttribute(isToggle);
		setIdElementToDelete(id);
		openPopin();
	};

	const editPrintValue = (id: string, printValue: string): void => {
		const indexPrints = values.prints.findIndex((print) => print.family.toLowerCase() === name.toLowerCase());
		const indexAttribute = values.prints[indexPrints].attributes.findIndex((attribute) => attribute.id === id);
		const indexValue = values.prints[indexPrints].attributes[indexAttribute].values.findIndex(
			(value) => value.printValue.toLowerCase() === printValue.toLowerCase()
		);

		setEditValueFieldPrintValue(
			`prints[${indexPrints}].attributes[${indexAttribute}].values[${indexValue}].printValue`
		);
		setEditValueFieldPrintName(`prints[${indexPrints}].attributes[${indexAttribute}].values[${indexValue}].printName`);

		setIsEditable(true);
		setCurrentAttributeId(id);
		handleOpenPopinToAddPrintValue();
	};

	const uniquenessPrintValue = ({
		printValue,
		printName,
	}: {
		printValue: string;
		printName: string;
	}): boolean | void => {
		const index = listItemPrintValues.findIndex(
			(print) => print.printValue === printValue || print.printName === printName
		);
		if (index !== -1) helpers.setError('Cette valeur a déjà été ajoutée pour ce caractéristique');
		else return true;
	};

	return (
		<Grid container>
			<>
				{isHidePopin &&
					renderPopin(
						<CharacteristicsList
							hasDeleted={hasDeleted || hasDeletedAttribute}
							isAttributes={isAttributes}
							isEditable={isEditable}
							name={!isEditable ? name : { editValueFieldPrintValue, editValueFieldPrintName }}
							characteristics={characteristicsPrintValues ?? []}
						/>
					)}
			</>
			<Grid xs={6} item>
				<CharacteristicsCard
					isSelected={isSelected}
					handleOpenPopinToDeleteAttribute={handleOpenPopinToDeleteAttribute}
					handleOpenPopinToAddAttribute={handleOpenPopinToAddAttribute}
					listItemAttributes={attributes}
					handleAttributeSelected={handleAttributeSelected}
					name={name}
					currentAttributeId={currentAttributeId}
				/>
			</Grid>
			<Grid xs={6} item>
				{attributeDetails && attributeDetails.length && (
					<CharacteristicsCard
						isSelected={isSelected}
						handleOpenPopinToAddPrintValue={handleOpenPopinToAddPrintValue}
						editPrintValue={editPrintValue}
						handleOpenPopinToDeletePrintValue={handleOpenPopinToDeletePrintValue}
						name={attributeDetails}
						listItemPrintValues={listItemPrintValues}
						disabled
					/>
				)}
			</Grid>
		</Grid>
	);
};
