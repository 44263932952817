import { useEffect, useMemo, useContext, useState } from 'react';
import omit from 'ramda/src/omit';
import { EMPTY_STRING, AttributeValuesType } from 'admin/support/definitions';
import { Attribute, PrintValue } from 'graphql/types';
import { getProductsAttributesByFamily } from 'product/helpers';
import { ProductFromModel } from 'product/definitions';
import usePrevious from 'hooks/usePrevious';
import PricesProductContext from 'context/pricesProductContext/PricesProductContext';
import { ConditionnementType, initialConditionnemt } from '../definition';
import { AutocompleteValue } from '@mui/material';

interface FiltersProductsProps {
	supportId?: string;
	attributes?: Attribute[] | null;
	printAttributes?: PrintValue[] | null;
	products: ProductFromModel[];
	isClariPrint?: boolean;
}

const useSupportCharacteristics = ({
	supportId,
	attributes,
	printAttributes,
	products,
	isClariPrint,
}: FiltersProductsProps) => {
	const {
		selectedProduct,
		setSelectedProduct: setProduct,
		selectedSupportId,
		setProductFilter,
		productFilter,
		setSelectedSupportId,
	} = useContext(PricesProductContext);

	const { productsAttributesByFamily, filtredProducts } = useMemo(
		() => getProductsAttributesByFamily(products, productFilter, printAttributes, attributes, isClariPrint),
		[products, productFilter, printAttributes, attributes, isClariPrint]
	);
	const [conditionnement, setConditionnement] = useState<ConditionnementType>(initialConditionnemt);
	const [numberOfLots, setNumberOfLots] = useState(1);

	const handleFilterChange = (
		attributeValues: AttributeValuesType,
		value: AutocompleteValue<unknown, undefined, undefined, undefined>
	) => {
		let key = attributeValues?.data[0]?.key;

		const printValueName = attributeValues?.data.find((attribute) => attribute.value === value);

		if (value && key) {
			const valueToSet = value === EMPTY_STRING ? null : value;
			const newFilterValue = key
				? {
						[key]: {
							name: printValueName?.printName || valueToSet,
							value: valueToSet,
						},
				  }
				: null;

			setProductFilter(newFilterValue, key);
		}
		if (!value && key) {
			setProductFilter(productFilter, !value ? `${key}` : '');
		}
	};

	const prevFiltredProducts = usePrevious(filtredProducts);

	useEffect(() => {
		if (JSON.stringify(prevFiltredProducts) !== JSON.stringify(filtredProducts)) {
			if (
				(isClariPrint && filtredProducts.length === 1 && printAttributes?.length === 0) ||
				(!isClariPrint && filtredProducts.length === 1)
			) {
				const product = omit(['support', '__typename'], filtredProducts[0]);
				setProduct(product);
			}
		}
	}, [filtredProducts, printAttributes, prevFiltredProducts, isClariPrint, selectedProduct, setProduct]);

	useEffect(() => {
		setProductFilter(null);
		setProduct(null);
		setNumberOfLots(1);
		setConditionnement(initialConditionnemt);
	}, [supportId, setProduct, setConditionnement, setProductFilter]);

	useEffect(() => {
		if (selectedSupportId !== '' && selectedSupportId !== supportId) {
			setProductFilter(null);
			setProduct(null);
		}
	}, [selectedSupportId, setProduct, setProductFilter, supportId]);

	useEffect(() => {
		return () => {
			setProductFilter(null);
			setProduct(null);
			setSelectedSupportId('');
		};
	}, [setProduct, setProductFilter, setSelectedSupportId]);

	return {
		handleFilterChange,
		productsAttributesByFamily,
		filtredProducts,
		setProductFilter,
		setProduct,
		selectedSupportId,
		selectedProduct,
		productFilter,
		conditionnement,
		setConditionnement,
		numberOfLots,
		setNumberOfLots,
	};
};

export default useSupportCharacteristics;
