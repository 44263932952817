import { InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache({
	typePolicies: {
		clients: {
			fields: {
				structures: {
					merge: false,
				},
			},
		},
		Query: {
			fields: {
				invoices: {
					keyArgs: ['filter'],
					merge(existing = [], { invoices, ...rest }, { args }) {
						const newCountedInvoices = rest;
						newCountedInvoices.invoices = [...(existing.invoices ?? []), ...invoices];

						return newCountedInvoices;
					},
				},

				fileReferences: {
					keyArgs: ['filters'],
					merge(existing = [], { fileReferences, ...rest }, { args }) {
						const newFileReferences = rest;
						newFileReferences.fileReferences = [...(existing.fileReferences ?? []), ...fileReferences];

						return newFileReferences;
					},
				},
			},
		},
	},
});

export default cache;
