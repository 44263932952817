import { Box, Typography, Grid, Button } from '@mui/material';
import { Field } from 'formik';
import { Checkbox } from 'formik-mui';
import React from 'react';
import FormikTextField from '../../../components/form/FormikTextField';
import { LayoutField } from '../../../components/form/LayoutField';
import { StatusSwitchField } from '../../../components/Switch/StatusSwitchField';
import RadioGroupField from '../client-detailed-information/fields/RadioGroupField';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useStylesClient } from '../../style';

interface ClientBaseInformationProps {
	handleImportClientFromBaseDB: () => void;
}

const ClientBaseInformation = ({ handleImportClientFromBaseDB }: ClientBaseInformationProps) => {
	const classes = useStylesClient();

	return (
		<Box mb={3} pt={3}>
			<Typography variant="h2">Informations principales</Typography>
			<Box mt={4} mx={12}>
				<Grid container justifyContent="space-between">
					<Grid item xs={4}>
						<Grid container direction="column" spacing={3}>
							<Grid item xs={12}>
								<LayoutField label="Nom du client">
									<FormikTextField name="name" fullWidth variant="outlined" />
								</LayoutField>
							</Grid>
							<Grid item xs={12}>
								<LayoutField label="Catégorie">
									<RadioGroupField name="isProspect" />
								</LayoutField>
							</Grid>
							<Grid item xs={12}>
								<LayoutField label="Hiérarchie">
									<Box display="flex" alignItems="center" justifyContent="flex-start">
										<Field name="isRoot" component={Checkbox} type="checkbox" size="small" />
										<Box>
											<Typography variant="h5">Entité mère</Typography>
										</Box>
									</Box>
								</LayoutField>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={4}>
						<Grid container direction="column" spacing={3}>
							<Grid item xs={12}>
								<LayoutField label="Etat du client">
									<Box pt={1}>
										<StatusSwitchField name="isActif" />
									</Box>
								</LayoutField>
							</Grid>
							<Grid item xs={12}>
								<LayoutField label="Numéro de société dans la base Elpev">
									<FormikTextField name="companyBase" fullWidth variant="outlined" />
								</LayoutField>
								<Box mt={3}>
									<Button color="primary" className={classes.refreshBtn} onClick={handleImportClientFromBaseDB}>
										<RefreshIcon height="24px" />
										<Typography variant="h5" color="primary" style={{ fontWeight: 500 }}>
											Récupérer les informations de la base Elpev
										</Typography>
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ClientBaseInformation;
