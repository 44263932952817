import React, { memo } from 'react';
import AppListTable from '../../../components/tables/list-table/AppListTable';
import { useDeliveryInformationTable } from '../../hooks/useDeliveryInformationTable';
import { OrderItem } from '../../../graphql/types';

interface DeliveryInformationByProductProps {
	orderItem: OrderItem;
}

const DeliveryInformationByProduct = ({ orderItem }: DeliveryInformationByProductProps): JSX.Element => {
	const tableInstance = useDeliveryInformationTable(orderItem);
	return <AppListTable tableInstance={tableInstance} />;
};

export default memo(DeliveryInformationByProduct);
