import { Box, Checkbox, Paper, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Menu, permissionsIdsList, permissionsMenu } from './permissionMenu';
import colors from '../../app/theme/colors.scss';

interface PermissionsListProps {
	setCheckedItems: React.Dispatch<Record<string, boolean>>;
	checkedItems: Record<string, boolean>;
	error: boolean;
}
const useStyles = makeStyles(() =>
	createStyles({
		checkbox: { position: 'absolute', right: 20, bottom: 0 },
		menuText: { position: 'absolute', top: 8 },
	})
);

const PermissionsList = ({ setCheckedItems, checkedItems, error }: PermissionsListProps): JSX.Element => {
	const classes = useStyles();

	let permissionsList: Record<string, boolean> = {};
	const handleChangeCheckedItems = (
		menus: Menu[],
		checkedItem: string,
		isSelected: boolean
	): Record<string, boolean> => {
		menus.forEach((menu: Menu) => {
			if (checkedItem === menu.parentId) {
				permissionsList[menu.key] = isSelected;
				menu.childs && handleChangeCheckedItems(menu.childs, menu.key, isSelected);
			}
		});

		return permissionsList;
	};

	const handleChangePermissions = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleChangeCheckedItems(
			permissionsIdsList.filter((menu) => menu.parentId === event.target.name),
			event.target.name,
			event.target.checked
		);
		setCheckedItems({
			...checkedItems,
			...permissionsList,
			[event.target.name]: event.target.checked,
		});
	};

	const getTreeItem = (menus: Menu[]) => {
		return menus.map((menu) => (
			<TreeItem
				key={menu.key}
				nodeId={menu.key}
				label={
					<Box minHeight={40}>
						<Box>
							<Typography variant="h5" className={classes.menuText}>
								{menu.name}
							</Typography>
						</Box>
						<Box>
							<Checkbox
								checked={checkedItems[menu.key] ?? false}
								name={menu.key}
								className={classes.checkbox}
								color="primary"
								onChange={handleChangePermissions}
								onClick={(e) => e.stopPropagation()}
							/>
						</Box>
					</Box>
				}
			>
				{menu.childs && getTreeItem(menu.childs)}
			</TreeItem>
		));
	};

	return (
		<Box maxHeight={500} maxWidth={500} mt={3}>
			{error && (
				<Box>
					<Alert severity="error">Veuillez renseigner un nom de rôle dans le menu information</Alert>
				</Box>
			)}
			<Paper>
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					defaultExpanded={permissionsMenu.map((menu) => menu.key)}
				>
					<Box display="flex" bgcolor={colors.grayCard}>
						<Box ml={3}>
							<Typography variant="h4" color="textSecondary">
								Menu
							</Typography>
						</Box>
						<Box ml={46}>
							<Typography variant="h4" color="textSecondary">
								Visible
							</Typography>
						</Box>
					</Box>

					<Box mt={1}>{getTreeItem(permissionsMenu)}</Box>
				</TreeView>
			</Paper>
		</Box>
	);
};

export default PermissionsList;
