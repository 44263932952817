import React from 'react';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { PrintValuesForm } from './form/PrintValuesForm';
import { validateSchema } from './validate-schema';
import { usePrintValuesMutation } from './hooks/usePrintValuesMutation';
import { useAttributesValues } from './hooks/useAttributeValuesQuery';
import { PrintValueGroupByFamily, PrintValuesType } from './definitions';
import { PrintValue } from '../../graphql/types';
import { printValueGroupByFamily } from './helpers';
import { typeNotifications } from '../../utils/definition';
import { useSnackbar } from 'notistack';

export const PrintValuesRoute = (): JSX.Element | null => {
	const { enqueueSnackbar } = useSnackbar();
	const { printValues } = useAttributesValues();
	const { insertPrintValues } = usePrintValuesMutation();

	const handleSubmit = async (prints: PrintValueGroupByFamily[]) => {
		try {
			const { printValues } = await insertPrintValues(prints);
			const description = printValues ? 'Les valeurs print sont enregistrées avec succès' : "Une erreur c'est produite";
			const variant = printValues ? typeNotifications.Success : typeNotifications.Error;
			enqueueSnackbar(description, { variant });
		} catch (error) {
			enqueueSnackbar("Une erreur c'est produite", { variant: typeNotifications.Error });
		}
	};

	if (!printValues) return null;

	const initialValues = {
		prints: printValueGroupByFamily(printValues),
		printValues: [],
	};

	return (
		<Box mx={7}>
			<Formik<PrintValuesType & { printValues: PrintValue[] }>
				initialValues={initialValues}
				validationSchema={validateSchema}
				enableReinitialize
				onSubmit={({ prints }: PrintValuesType) => handleSubmit(prints)}
				validateOnChange={false}
			>
				<PrintValuesForm />
			</Formik>
		</Box>
	);
};
