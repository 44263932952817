import React, { useRef, useState } from 'react';
import { IReactToPrintProps, useReactToPrint } from 'react-to-print';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box } from '@mui/material';

interface PrintFileProps {
	file: string;
}

export const usePrintFile = ({ file }: PrintFileProps) => {
	const componentRef = useRef<null | Document>(null);
	const [pageNumber, setNumPages] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }: pdfjs.PDFDocumentProxy) => {
		setNumPages(numPages);
	};

	const handlePrintFile = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
	} as IReactToPrintProps);

	const renderingComponentToPrint = () => (
		<Box display="none">
			<Document file={file} ref={(element) => (componentRef.current = element)} onLoadSuccess={onDocumentLoadSuccess}>
				<Page pageNumber={pageNumber} />
			</Document>
		</Box>
	);

	return [handlePrintFile, renderingComponentToPrint];
};
