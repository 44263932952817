import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import ManualTabs from '../../components/tabs/ManualTabs';
import { OrderStatus } from '../../graphql/types';
import { Form, Formik } from 'formik';
import { OrderFormModel } from './order-cockpit/definition';
import OrderGeneralInformation from './order-cockpit/general-information/OrderGeneralInformation';
import colors from '../../app/theme/colors.scss';
import AdditionalInformation from './order-cockpit/additional-information/AdditionalInformation';
import DetailsByProduct from './order-cockpit/details-by-product/DetailsByProduct';

import { ClientInvoices } from './order-cockpit/client-document/ClientInvoices';
import { orderValidationSchema } from './order-cockpit/validation-schema';
import usePopin from '../../hooks/usePopin';
import OrderCancellationContent from './order-cockpit/client-document/component/OrderCancellationContent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useUpsertCockpitOrder } from '../hooks/useUpsertCockpitOrder';
import { SubmitAndResetButton } from '../../components/buttons/submit-and-reset.button';
import OutlinedButton from '../../components/buttons/OutlinedButton';
import { DetailsByStore } from './order-cockpit/details-by-store/DetailsByStore';
import { Link } from 'react-router-dom';
import { useManualTabs } from '../../hooks/useManualTabs';

interface OrderFormProps {
	order: OrderFormModel;
	paths: (string | undefined | { name: string; href: string })[];
	onSubmit: (order: OrderFormModel, invoiceFile: Record<string, File> | null) => void;
}

const OrderCockpitForm = ({ paths, order, onSubmit }: OrderFormProps): JSX.Element | null => {
	const tabs = useMemo(
		() => [
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations générales',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations complémentaires',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Détail par produit',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Détail par magasin',
			},

			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Document client',
			},
		],
		[]
	);

	const [isHidePopin, setIsHidePopin] = useState<boolean>(false);

	const { upsertCockpitOrder } = useUpsertCockpitOrder();

	const [currentTab, setCurrentTab] = useState(tabs[0]);
	const handleTabChange = (tab: typeof tabs[0]) => {
		const offsetTop = tab.ref.current?.offsetTop;
		const fixedHeaderHeight = 230;
		setCurrentTab(tab);

		if (offsetTop) window.scroll(0, offsetTop - fixedHeaderHeight);
	};

	const handleClosePopin = () => setIsHidePopin(false);

	const handleCancelOrder = async () => {
		const cancel = { ...order, status: OrderStatus.Canceled };
		setIsHidePopin(false);
		await upsertCockpitOrder(cancel);
	};

	const [openPopin, renderPopin] = usePopin({
		headerTitle: 'Annuler la commande',
		maxWidth: 'xs',
		fullWidth: false,
		isDisplayHeader: true,
		bottomFooter: (
			<SubmitAndResetButton
				handleCancel={handleClosePopin}
				handleSubmit={handleCancelOrder}
				submitBtnTitle="Annuler la commande"
				cancelBtnTitle="Annuler"
				bkgColor={colors.error}
			/>
		),
	});

	const handleOpenPopin = () => {
		setIsHidePopin(true);
		openPopin();
	};

	const { renderingTabs } = useManualTabs({
		tabs,
		paths,
		headlines: [],
		fixedHeaderHeight: 258,
	});

	const orderValues = {
		...order,
		createdAt: new Date(order.createdAt).toLocaleDateString(),
		invoiceFile: null,
		elpevResponsible: order?.fileReference?.elpevResponsible
			? `${order?.fileReference?.elpevResponsible.familyName} ${order.fileReference.elpevResponsible?.givenName}`
			: null,
	};

	return (
		<Box m={1}>
			<Formik<OrderFormModel & { invoiceFile: Record<string, File> | null }>
				initialValues={orderValues}
				enableReinitialize
				validationSchema={orderValidationSchema}
				onSubmit={async ({ invoiceFile, ...restOrder }) => {
					onSubmit(restOrder, invoiceFile);
				}}
				validateOnChange={false}
			>
				<Form>
					<>
						{isHidePopin && renderPopin(<OrderCancellationContent />)}
						<Box
							position="fixed"
							width="100%"
							top={40}
							left={0}
							px={10}
							bgcolor={colors.bodyBackgroundLight}
							zIndex={50}
						>
							<Box display={'flex'}>
								<Box
									position="fixed"
									width="100%"
									top={40}
									left={0}
									px={11.75}
									bgcolor={colors.bodyBackgroundLight}
									zIndex={100}
								>
									{renderingTabs()}
								</Box>
								{order.status === 'Canceled' && (
									<Box pt={4}>
										<Typography variant={'h3'} color={'error'}>
											<Typography>Commande annulée</Typography>
										</Typography>
									</Box>
								)}
							</Box>
							<Divider />
							<Box display="flex" justifyContent="space-between" mb={2} pt={3}>
								<Box display="flex">
									<Typography variant="h2">Commande n° {order.id}</Typography>
									<Box mx={1} color={colors.gray400}>
										<Typography variant="h2"> | </Typography>
									</Box>
									<Typography variant="h2">{order.clientName}</Typography>
								</Box>
								<Box>
									<Link to="/orders" style={{ textDecoration: 'none' }}>
										<Button color="primary" type="reset">
											Annuler
										</Button>
									</Link>
									<Button variant="contained" color="primary" type="submit">
										Enregistrer les modifications
									</Button>
									{order.status !== 'Canceled' && (
										<OutlinedButton
											Icon={DeleteForeverOutlinedIcon}
											handleClickBtn={handleOpenPopin}
											title="Annuler la commande"
											color={colors.error}
										/>
									)}
								</Box>
							</Box>

							<Box mx={13}>
								<ManualTabs currentTab={currentTab} tabs={tabs} onChange={handleTabChange} />
							</Box>
						</Box>
						<Box mt={20} mx={15}>
							<div ref={tabs[0].ref}>
								<OrderGeneralInformation />
							</div>
							<div ref={tabs[1].ref}>
								<Divider />
								<AdditionalInformation order={order} />
							</div>
							<div ref={tabs[2].ref}>
								<Divider />
								<DetailsByProduct orderItem={order.orderItem} childrenOrder={order.childrenOrder} />
							</div>
							<div ref={tabs[3].ref}>
								<Divider />
								<DetailsByStore childrenOrder={order.childrenOrder} parentOrderItem={order.orderItem} />
							</div>
							<div ref={tabs[4].ref}>
								<ClientInvoices />
							</div>
						</Box>
					</>
				</Form>
			</Formik>
		</Box>
	);
};

export default OrderCockpitForm;
