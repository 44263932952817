import * as React from 'react';
import { Chip } from '@mui/material';

import { FileReferenceStatusTrans } from '../../definition';

import { FileReferenceStatus } from 'graphql/types';
import colors from 'app/theme/colors.scss';

interface StatusTagProps {
	status: keyof typeof FileReferenceStatus;
}

const statusTagStyle = (status: keyof typeof FileReferenceStatus) => {
	switch (status) {
		case FileReferenceStatus.Delivered:
			return {
				background: colors.green100,
				color: colors.green800,
			};
		case FileReferenceStatus.InStudy:
			return {
				background: colors.yellow100,
				color: colors.yellow400,
			};
		case FileReferenceStatus.InProduction:
			return {
				background: colors.turquoise100,
				color: colors.turquoise400,
			};
		case FileReferenceStatus.InProgress:
			return {
				background: colors.red100,
				color: colors.red400,
			};
	}
};

const StatusTag = ({ status }: StatusTagProps) => {
	return <Chip label={FileReferenceStatusTrans[status]} style={{ ...statusTagStyle(status) }} />;
};

export default StatusTag;
