import { Box, Button, Container, Divider, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import ManualTabs from '../../components/tabs/ManualTabs';
import { Form, useField } from 'formik';
import ClientStructuresForm from './client-structure/ClientStructuresForm';
import TypeAndOrganizationEntity from './client-type-organization/TypeAndOrganizationEntity';
import ClientBaseInformation from './client-information/ClientBaseInformation';
import ClientDetailedInformationForm from './client-detailed-information/ClientDetailedInformationForm';
import { Client, ClientInsertInput } from '../../graphql/types';
import ElpevInformation from './elpev-information/ElpevInformation';
import Path from '../../components/Path';
import colors from '../../app/theme/colors.scss';
import DeliveryInformationsForm from './delivery-information/DeliveryInformationsForm';
import CommercialInformationsForm from './commercial-information/CommercialInformationsForm';
import BillingInformationsForm from './billing-information/BillingInformationsForm';
import useClientFromBaseDBUpdate from '../hooks/useClientFromBaseDBUpdate';
import Contact from '../../components/contact/Contact';
import { CONTACT_DISPLAY_LIMIT } from '../../utils/definition';
import useStorage from 'hooks/useStorage';
import { CurrentUser } from 'admin/user/definitions';
import { RoleName } from 'admin/role/definitions';

interface ClientFormProps {
	client: Client;
	paths: (string | undefined)[];
	onChangeInformativeEntities: React.Dispatch<React.SetStateAction<Record<number, ClientInsertInput> | undefined>>;
}

const ClientForm = ({ client, paths, onChangeInformativeEntities }: ClientFormProps): JSX.Element => {
	const tabs = useMemo(
		() => [
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations principales',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: "Structure de l'organisation",
				hide: !client.isRoot,
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Type de client',
				hide: client.isRoot,
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations Elpev',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations client',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations de facturation',
			},

			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations de livraison',
			},
			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Contact client',
			},

			{
				ref: React.createRef<HTMLDivElement>(),
				title: 'Informations commerciales',
			},
		],
		[client.isRoot]
	);

	const [currentTab, setCurrentTab] = useState(client.isRoot ? tabs[0] : tabs[1]);

	const handleTabChange = (tab: typeof tabs[0]) => {
		const offsetTop = tab.ref.current?.offsetTop;
		const fixedHeaderHeight = 230;
		setCurrentTab(tab);

		if (offsetTop) window.scroll(0, offsetTop - fixedHeaderHeight);
	};

	const [field] = useField<string | undefined | null>('companyBase');
	const { updateClientFromBaseDB } = useClientFromBaseDBUpdate();
	const { getDataLocalStorage } = useStorage();
	const currentUserRoleName = useMemo(() => {
		return (getDataLocalStorage('currentUser') as CurrentUser)?.role?.name;
	}, [getDataLocalStorage]);

	const handleImportClientFromBaseDB = async () => {
		try {
			if (field.value) {
				await updateClientFromBaseDB(field.value, client.id);
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<Box m={3}>
			<Form>
				<Box position="fixed" width="100%" top={30} left={0} px={10} bgcolor={colors.white} zIndex={100}>
					<Box>
						<Path paths={paths} />
						<Divider />
					</Box>
					<Box display="flex" justifyContent="space-between" mb={2} pt={5}>
						<Typography variant="h2">{client.name}</Typography>
						<Box>
							<Button color="primary" type="reset">
								Annuler
							</Button>
							<Button type="submit" variant="contained" color="primary">
								Enregistrer les modifications
							</Button>
						</Box>
					</Box>

					<Box mr={4} ml={4}>
						<ManualTabs currentTab={currentTab} tabs={tabs} onChange={handleTabChange} />
					</Box>
				</Box>

				<Box mt={24}>
					<Container maxWidth="lg">
						<div ref={tabs[0].ref}>
							<ClientBaseInformation handleImportClientFromBaseDB={handleImportClientFromBaseDB} />
						</div>
						{client.isRoot ? (
							<div ref={tabs[1].ref}>
								<Divider />
								<ClientStructuresForm client={client} />
							</div>
						) : (
							<div ref={tabs[2].ref}>
								<Divider />
								<TypeAndOrganizationEntity onChangeInformativeEntities={onChangeInformativeEntities} />
							</div>
						)}
						<div ref={tabs[3].ref}>
							<Divider />
							<ElpevInformation
								client={client}
								onClickImportClientFromBaseDB={handleImportClientFromBaseDB}
								isRoleAdmin={currentUserRoleName === RoleName.Admin}
							/>
						</div>
						<div ref={tabs[4].ref}>
							<Divider />
							<ClientDetailedInformationForm
								onClickImportClientFromBaseDB={handleImportClientFromBaseDB}
								isRoleAdmin={currentUserRoleName === RoleName.Admin}
							/>
						</div>
						<div ref={tabs[5].ref}>
							<Divider />
							<BillingInformationsForm
								onClickImportClientFromBaseDB={handleImportClientFromBaseDB}
								isRoleAdmin={currentUserRoleName === RoleName.Admin}
							/>
						</div>

						<div ref={tabs[6].ref}>
							<Divider />
							<DeliveryInformationsForm
								client={client}
								onClickImportClientFromBaseDB={handleImportClientFromBaseDB}
								isRoleAdmin={currentUserRoleName === RoleName.Admin}
							/>
						</div>
						<div ref={tabs[7].ref}>
							<Divider />
							<Box my={3}>
								<Contact
									title="Contact Client"
									formikContactName="clientContact"
									contactDisplayLimit={CONTACT_DISPLAY_LIMIT}
									onClickImportClientFromBaseDB={handleImportClientFromBaseDB}
								/>
							</Box>
						</div>
						<div ref={tabs[8].ref}>
							<Divider />
							<CommercialInformationsForm
								onClickImportClientFromBaseDB={handleImportClientFromBaseDB}
								isRoleAdmin={currentUserRoleName === RoleName.Admin}
							/>
						</div>
					</Container>
				</Box>
			</Form>
		</Box>
	);
};

export default ClientForm;
