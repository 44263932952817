import { useQuery } from '@apollo/client';

import { GET_FILE_REFERENCES } from '../graphql/queries';
import { GetFileReferencesQuery, GetFileReferencesQueryVariables } from '../graphql/queries.definitions';

const useFileReferencesQuery = (
	search?: GetFileReferencesQueryVariables['search'],
	sort?: GetFileReferencesQueryVariables['sort'],
	pagination?: GetFileReferencesQueryVariables['pagination'],
	filters?: GetFileReferencesQueryVariables['filters']
) => {
	const { data, loading, fetchMore, refetch } = useQuery<GetFileReferencesQuery, GetFileReferencesQueryVariables>(
		GET_FILE_REFERENCES,
		{
			variables: {
				search,
				sort,
				pagination,
				filters,
			},
			fetchPolicy: 'cache-and-network',
		}
	);

	return {
		fileReferences: data?.fileReferences?.fileReferences,
		loading,
		fetchMore,
		refetch,
	};
};

export default useFileReferencesQuery;
