import React, { memo, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, TextField, Typography, MenuItem, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import colors from 'app/theme/colors.scss';
import FormikSelectField from 'components/form/FormikSelectField';
import { OrderProductStatus, SupportCategory } from 'graphql/types';
import { Store } from '../../../types';
import { OrderProductStatusValue } from '../definition';

interface ProductDeliveryDetailsProps {
	clientNetworkLink?: string | null;
	comment?: string | null;
	expectedDeliveryDate: Date;
	openDetailsProductPopin: (event: React.MouseEvent) => void;
	productIndex: number;
	publicationDate?: Date | null;
	stores: Store[];
	supportCategory?: string | null;
	thumbnailAssetUrl?: string | null;
	versionsNumber?: number | null;
}

const useStyles = makeStyles(() =>
	createStyles({
		commentField: {
			backgroundColor: colors.bodyBackgroundLight,
		},
		delivery: {
			'& > h4': {
				lineHeight: '35px',
			},
		},
		publishLink: {
			cursor: 'pointer',
			color: colors.blue500,
			decoration: 'underline',
		},
	})
);

const ProductDeliveryDetails = ({
	clientNetworkLink,
	comment,
	expectedDeliveryDate,
	publicationDate,
	versionsNumber,
	productIndex,
	openDetailsProductPopin,
	stores,
	thumbnailAssetUrl,
	supportCategory,
}: ProductDeliveryDetailsProps): JSX.Element => {
	const classes = useStyles();
	const formikNames = stores.map((store) => store.orderChildFormikName);
	const { setFieldValue } = useFormikContext();
	const isDigitalProduct = useMemo(() => supportCategory === SupportCategory.Digital, [supportCategory]);

	return (
		<Box mb={2}>
			<Grid container>
				<Grid item xs={6}>
					<Grid container spacing={1}>
						<Grid item xs={4}>
							<Box bgcolor={colors.paperBackgroundLight} width="140px" height="140px" py={1} px={2}>
								<img
									alt=""
									width="100%"
									height="100%"
									onClick={openDetailsProductPopin}
									src={
										thumbnailAssetUrl ??
										'https://image.shutterstock.com/image-vector/empty-placeholder-image-icon-design-260nw-1366372628.jpg'
									}
								/>
							</Box>
						</Grid>
						<Grid container xs={8} item className={classes.delivery}>
							<Grid item xs={5}>
								<Typography variant="h4">Livraison prévu le : </Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="h5" aria-label="Delivery date">
									{isDigitalProduct
										? publicationDate && new Date(publicationDate).toLocaleString()
										: new Date(expectedDeliveryDate).toLocaleDateString()}
								</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="h4">Statut du produit :</Typography>
							</Grid>

							<Grid item xs={6}>
								<FormikSelectField
									name={`orderItem.${productIndex}.status`}
									onChange={(e) => {
										formikNames.forEach((name) => {
											setFieldValue(`${name}.status`, e.target.value);
										});
										setFieldValue(`orderItem.${productIndex}.status`, e.target.value);
									}}
								>
									{Object.keys(OrderProductStatus).map((status) => (
										<MenuItem value={status} key={status}>
											{OrderProductStatusValue[status as OrderProductStatus]}
										</MenuItem>
									))}
								</FormikSelectField>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="h4">Nombre de versions :</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="h5">{versionsNumber}</Typography>
							</Grid>
							{isDigitalProduct ? (
								<>
									<Grid item xs={5}>
										<Typography variant="h4">URL de publication :</Typography>
									</Grid>
									<Grid item xs={6}>
										<Box className={classes.publishLink} onClick={() => window.open(clientNetworkLink || '#')}>
											{clientNetworkLink}
										</Box>
									</Grid>
								</>
							) : null}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Box pt={0.55}>
						<Typography variant="h4">Commentaires</Typography>
						<TextField
							disabled={true}
							value={comment}
							multiline
							rows={5}
							fullWidth
							variant="outlined"
							className={classes.commentField}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default memo(ProductDeliveryDetails);
