import React from 'react';
import { useParams } from 'react-router-dom';
import ClientQuoteList from './ClientQuoteList';
import ClientQuoteDetail from './ClientQuoteDetail';

export const ClientQuoteRoute = (): JSX.Element => {
	const { id: quoteId } = useParams<{ id: string }>();

  return quoteId ? <ClientQuoteDetail quoteId={quoteId} /> : <ClientQuoteList />;
};
