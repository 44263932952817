import * as React from 'react';

export interface Coordinates {
	row: number;
	col: number;
}

export interface Borders {
	top: Boolean;
	bottom: Boolean;
	right: Boolean;
	left: Boolean;
}

export interface Range {
	start: {
		col: number;
		row: number;
	};
	end: {
		col: number;
		row: number;
	};
	color?: string;
	isCopied?: boolean;
	droppedAt?: Coordinates;
}

type props = {
	selected?: Boolean;
	colSpan?: number;
	rowSpan?: number;
	value?: any;
	coordinates: Coordinates;
	onMouseUp: Function;
	onMouseDown: Function;
	onContextMenu: Function;
	borders?: Borders;
	bgColor?: string;
	onDragStart?: Function;
	onDrop?: Function;
	draggable?: Boolean;
	editCell?: boolean;
	grid: any[][];
	setGrid?: Function;
	keyPress?: Function;
};

function Cell(props: props) {
	const [isEdit, setIsEdit] = React.useState<boolean>(false);

	const onMouseDown = (e: any, coordinates: Coordinates) => {
		if (e.button === 0) {
			props.onMouseDown(coordinates);
		}
	};

	const onMouseUp = (e: any, coordinates: Coordinates) => {
		if (e.button === 0) {
			props.onMouseUp(coordinates);
		}
	};

	const onContextMenu = (e: any, coordinates: any) => {
		props.onContextMenu(e, coordinates);
		e.preventDefault();
	};

	const onDragStart = (e: any, value: string, coordinates: any, bgColor: string) => {
		if (props.onDragStart) props.onDragStart(value, coordinates, bgColor);
	};

	const onDrop = (e: any, coordinates: any) => {
		e.preventDefault();
		if (props.onDrop) props.onDrop(coordinates);
	};

	const onDragEnd = (e: any, coordinates: any) => {
		e.preventDefault();
	};

	const onDragOver = (e: any) => {
		e.preventDefault();
	};

	const onBlurCell = (e: any) => {
		if (props.grid && props.setGrid) {
			setIsEdit(false);
			const newGrid = props.grid;

			newGrid[props.coordinates.row][props.coordinates.col].value = e.target.innerHTML;
			props.setGrid(newGrid);
		}
	};

	const onClickForEdit = () => {
		if (props.editCell) {
			setIsEdit(true);
		}
	};

	React.useEffect(() => {
		//ctrl+ C / ctrl+V  event listener
		if (props.keyPress && props.selected) {
			window.addEventListener('keyup', (event) => {
				if (props.keyPress) {
					props.keyPress(event);
				}
			});
		}

		return () => {
			window.removeEventListener('keyup', (event) => {
				if (props.keyPress) {
					props.keyPress(event);
				}
			});
		};
	}, [props.selected, props]);

	return (
		<td
			draggable={props.draggable ? true : false}
			onClick={onClickForEdit}
			onDragStart={(e) => onDragStart(e, props.value, props.coordinates, props.bgColor ? props.bgColor : '')}
			onDragEnd={(e) => onDragEnd(e, props.coordinates)}
			onDrop={(e) => onDrop(e, props.coordinates)}
			onDragOver={onDragOver}
			onContextMenu={(e) => onContextMenu(e, props.coordinates)}
			onMouseUp={(e) => onMouseUp(e, props.coordinates)}
			onMouseDown={(e) => onMouseDown(e, props.coordinates)}
			className={`${props.selected ? 'selected' : ''}
						${props.borders && props.borders.top ? 'border-top' : ''} ${
				props.borders && props.borders.bottom ? 'border-bottom' : ''
			}
						${props.borders && props.borders.left ? 'border-left' : ''} ${
				props.borders && props.borders.right ? 'border-right' : ''
			}
						`}
			colSpan={props.colSpan}
			rowSpan={props.rowSpan}
			style={{ backgroundColor: props.bgColor ? props.bgColor : '', whiteSpace: 'pre' }}
			onBlur={onBlurCell}
			contentEditable={isEdit}
			suppressContentEditableWarning={isEdit}
		>
			{props.value}
		</td>
	);
}

export default Cell;
