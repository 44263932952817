import * as React from 'react';
import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useField } from 'formik';
import colors from 'app/theme/colors.scss';
import { LayoutField } from 'components/form/LayoutField';
import FormikAutocompleteField from 'components/form/FormikAutocompleteField';
import CaractiristicByProductDialog from 'product/components/CharacteristicByProductDialog';
import { isString } from 'utils/typeGuards';
import { productHasOneQuantity, uniqByProductName } from '../../../helpers';
import { ClientQuoteItem, OrderItem } from '../../../../graphql/types';
import { useProductInformation } from './ProductInformationContext';
import usePrevious from '../../../../hooks/usePrevious';

interface SelectProductNameProps {
	setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectProductName = ({ setIsDisabled }: SelectProductNameProps): JSX.Element => {
	const { clientQuote, setSelectedProduct } = useProductInformation();

	const [{ value: newOrderItem }, , { setValue: setNewOrderItem }] = useField('newOrderItem');
	const [{ value: orderItem }] = useField<OrderItem[]>('orderItem');

	const productsNames = useMemo(
		() =>
			clientQuote?.clientQuoteItem?.filter(
				(quote) => !orderItem.map((item) => item.productId).includes(quote.productId)
			),
		[orderItem, clientQuote]
	);

	const productsNamesOptions = useMemo(() => uniqByProductName(productsNames as ClientQuoteItem[]), [productsNames]);

	const handleChangeSelectProduct = (product: ClientQuoteItem | null) => {
		if (product) {
			setSelectedProduct({
				productId: product.productId,
				productName: product.productName,
				numberVersion: product.numberVersion,
				product: product.product,
			});

			const hasOneProduct = productHasOneQuantity(
				productsNames?.map((clientQuoteItem) => ({
					...clientQuoteItem,
					transportPurchasePriceExcludingVAT: clientQuoteItem.transportPurchasePriceExcludingVAT ?? 0,
				})) as ClientQuoteItem[],
				product?.productName
			);

			setNewOrderItem({
				...product,
				bestTransporterId: product.transporterId,
				supportId: product.product.supportId,
				quantity: hasOneProduct.length === 1 ? hasOneProduct.quantity : 0,
				tva: product.vatAmount,
				sellingPriceTtc: product.sellingPriceIncludingTax,
				sellingPriceHt: product.cost,
				productSellingPriceHt: product.productSalesPriceExcludingVAT,
				transportSellingPriceHt: product.transportSalesPriceExcludingVAT,
				tvaRate: clientQuote?.vatRate,
			});
		}
	};

	const previousOrderItem = usePrevious(orderItem);

	useEffect(() => {
		if (JSON.stringify(previousOrderItem) !== JSON.stringify(orderItem) && setIsDisabled) {
			orderItem.length === clientQuote?.clientQuoteItem?.length ? setIsDisabled(false) : setIsDisabled(true);
		}
	}, [clientQuote, orderItem, setIsDisabled, previousOrderItem]);
	return (
		<Box mb={3}>
			<LayoutField label="Nom du produit">
				<Box display="flex" alignItems="center">
					<Box width="50%">
						<FormikAutocompleteField<ClientQuoteItem>
							value={newOrderItem}
							options={productsNamesOptions ?? []}
							onChange={(_, value) => {
								if (!isString(value)) {
									handleChangeSelectProduct(value);
								}
							}}
							getOptionLabel={(option) => (option as ClientQuoteItem).productName || ''}
							autoHighlight
						/>
					</Box>

					<Box color={colors.gray100} ml={2}>
						<CaractiristicByProductDialog productId={newOrderItem?.productId ?? ''} />
					</Box>
				</Box>
			</LayoutField>
		</Box>
	);
};

export default SelectProductName;
