import * as Yup from 'yup';

export const orderValidationSchema = Yup.object({
	invoices: Yup.array().of(
		Yup.object({
			createdAt: Yup.date().nullable(),
			validateAt: Yup.date()
				.nullable()
				.min(Yup.ref('createdAt'), 'La date doit être supérieure ou égale à la date création!!'),
			sendAt: Yup.date()
				.nullable()
				.min(Yup.ref('validateAt'), 'La date doit être supérieure ou égale à la date validation!!'),
		})
	),
});
