import * as Yup from 'yup';

const validateSupplierSchema = () => {
	return Yup.object().shape({
		name: Yup.string().required('Vous devez spécifier un nom'),
		user: Yup.object().shape({
			email: Yup.string().email('E-mail invalide'),
		}),
	});
};

export default validateSupplierSchema;
