import React from 'react';
import { Box, FormLabel, Grid, TextField, capitalize, AutocompleteValue } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import FamilyAttributes from 'admin/support/FamilyAttributes';
import { Attribute, PrintValue } from 'graphql/types';
import { removeDuplicatesAndSort } from 'utils/arrays';
import { getAttributeValue } from 'clientPrice/helpers';
import { ProductFromModel } from 'product/definitions';
import { GetSupplierPrices } from 'supplier/definitions';
import { AttributeValuesType, EMPTY_STRING } from 'admin/support/definitions';
import { ProductFilter, SelectedProduct } from 'context/pricesProductContext/definition';
import DashedHeader from '../DashedHeader';
import { Conditionnement } from './Conditionnement';
import { ConditionnementType } from './definition';

interface FiltersProductsProps {
	supportId?: string;
	attributes: Attribute[];
	printAttributes?: PrintValue[] | null;
	products: ProductFromModel[];
	isClariPrint?: boolean;
	onGetSupplierPrices?: GetSupplierPrices;
	isProductPage?: boolean;
	setProductFilter?: (productFilter?: ProductFilter | null | undefined) => void;
	setProduct?: (selectedProduct?: SelectedProduct) => void;
	filtredProducts: ProductFromModel[];
	productsAttributesByFamily?: { family: string | null | undefined; data: AttributeValuesType[] }[];
	selectedSupportId: string;
	selectedProduct?: SelectedProduct | null;
	productFilter?: ProductFilter | null;
	handleFilterChange: (
		attributeValues: AttributeValuesType,
		value: AutocompleteValue<unknown, undefined, undefined, undefined>
	) => void;
	conditionnement: ConditionnementType;
	setConditionnement: React.Dispatch<React.SetStateAction<ConditionnementType>>;
	numberOfLots: number;
	setNumberOfLots: React.Dispatch<React.SetStateAction<number>>;
}

const SupportCharacteristics = ({
	products,
	supportId,
	handleFilterChange,
	productFilter,
	selectedProduct,
	selectedSupportId,
	filtredProducts,
	productsAttributesByFamily,
	printAttributes,
	isClariPrint,
	setProductFilter,
	isProductPage,
	conditionnement,
	setConditionnement,
	numberOfLots,
	setNumberOfLots,
}: FiltersProductsProps): JSX.Element => {
	const printValues = isClariPrint
		? productsAttributesByFamily
				?.find(({ family }) => family === 'conditionnement')
				?.data[0].data.map(({ value }) => value)
		: undefined;

	return (
		<Box pt={3} mx={2.2}>
			<Box display={'flex'} pt={2}>
				{productsAttributesByFamily?.map((productAttributesByFamily, index: number) => {
					if (productAttributesByFamily.family?.toLowerCase() !== 'conditionnement')
						return (
							<Box key={index} width="25%" pr={productsAttributesByFamily.length - 1 !== index ? 2.5 : 0}>
								<FamilyAttributes family={capitalize(productAttributesByFamily?.family || '')} />
								<Box display="inline" p={1}>
									<Grid container spacing={1}>
										{productAttributesByFamily.data?.map((attributeValues, index) => {
											const options = attributeValues.data.map((option) => option.value);
											const sortedList = removeDuplicatesAndSort(options);

											return (
												<Grid item xs={6} key={index}>
													<FormLabel>{attributeValues.name}</FormLabel>
													<Autocomplete
														data-testid={attributeValues.name?.toLowerCase()?.split(' ').join('-')}
														key={supportId}
														size="small"
														popupIcon={<KeyboardArrowDownIcon />}
														forcePopupIcon={
															isClariPrint ? !(sortedList.length === 1 && sortedList[0] === EMPTY_STRING) : true
														}
														freeSolo
														clearOnBlur={
															isClariPrint ? !(sortedList.length === 1 && sortedList[0] === EMPTY_STRING) : true
														}
														getOptionLabel={(option) => (option as string) ?? ''}
														value={getAttributeValue(
															attributeValues,
															filtredProducts,
															selectedSupportId,
															printAttributes,
															selectedProduct,
															productFilter,
															isClariPrint
														)}
														onInputChange={(_, newValue) => {
															if (
																!newValue ||
																(isClariPrint && sortedList.length === 1 && sortedList[0] === EMPTY_STRING)
															) {
																handleFilterChange(attributeValues, newValue);
															}
														}}
														onChange={(_, newValue) => handleFilterChange(attributeValues, newValue)}
														options={
															isClariPrint
																? sortedList.length < 1
																	? sortedList
																	: sortedList[0] === EMPTY_STRING
																	? []
																	: sortedList
																: sortedList
														}
														renderInput={(params) => (
															<TextField
																placeholder={!isClariPrint ? (isProductPage ? 'Indifférent' : 'A compléter') : ''}
																{...params}
																variant="outlined"
															/>
														)}
													/>
												</Grid>
											);
										})}
									</Grid>
								</Box>
							</Box>
						);
					return null;
				})}
			</Box>

			<DashedHeader>Conditionnement</DashedHeader>
			<Conditionnement
				productFilter={productFilter}
				setProductFilter={setProductFilter}
				isClariPrint={isClariPrint}
				isProductPage={isProductPage}
				products={products}
				printValues={printValues}
				productsAttributesByFamily={productsAttributesByFamily}
				conditionnement={conditionnement}
				setConditionnement={setConditionnement}
				numberOfLots={numberOfLots}
				setNumberOfLots={setNumberOfLots}
			/>
		</Box>
	);
};

export default SupportCharacteristics;
