import React from 'react';
import { Box, Divider } from '@mui/material';
import colors from '../../../app/theme/colors.scss';
import { Form, useFormikContext } from 'formik';
import { useManualTabs } from '../../../hooks/useManualTabs';
import { CharacteristicsFamily } from './components/CharacteristicsFamily';
import { PrintValuesType } from '../definitions';
import { HEADLINES, PATHS, TAB_MENU } from '../constant';

export const PrintValuesForm = (): JSX.Element => {
	const {
		values: { prints },
	} = useFormikContext<PrintValuesType>();
	const { renderingTabs } = useManualTabs({
		tabs: TAB_MENU,
		paths: PATHS,
		headlines: HEADLINES,
		fixedHeaderHeight: 258,
	});

	return (
		<Box m={3}>
			<Form>
				<Box position="fixed" width="100%" top={30} left={0} px={10} bgcolor={colors.white} zIndex={100}>
					{renderingTabs()}
				</Box>
				<Box mt={30}>
					{prints?.map(({ family, attributes }, i) => (
						<div ref={TAB_MENU[i].ref} key={`${family}_${i}`}>
							<CharacteristicsFamily name={family} attributes={attributes} />
							{i < TAB_MENU.length - 1 && <Divider />}
						</div>
					))}
				</Box>
			</Form>
		</Box>
	);
};
