import { useQuery } from '@apollo/client';
import { GET_CLIENT_AND_CHILDRENDS_WITH_SHIPPING_ADDRESS } from 'clients/graphql/queries';
import {
	GetClientAndAllChildrensWithShippingAdressQuery,
	GetClientAndAllChildrensWithShippingAdressQueryVariables,
} from 'clients/graphql/queries.definitions';

const useClientAndAllChildrensWithShippingAdressQuery = (id: string) => {
	const { data, loading } = useQuery<
		GetClientAndAllChildrensWithShippingAdressQuery,
		GetClientAndAllChildrensWithShippingAdressQueryVariables
	>(GET_CLIENT_AND_CHILDRENDS_WITH_SHIPPING_ADDRESS, {
		variables: {
			id,
		},
	});

	return { clientAndAllChildrensWithShippingAdress: data?.clientAndAllChildrens, loading };
};

export default useClientAndAllChildrensWithShippingAdressQuery;
