import gql from 'graphql-tag';

const GET_SUPPLIERS = gql`
	query {
		suppliers {
			id
			name
		}
	}
`;

export { GET_SUPPLIERS };
