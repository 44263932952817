import { useQuery } from '@apollo/client';
import { GET_SUPPLIERS } from '../graphql/queries';
import { GetSuppliersQuery, GetSuppliersQueryVariables } from '../graphql/queries.definitions';

const useSuppliersQuery = () => {
	const { data, loading } = useQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GET_SUPPLIERS);

	return { suppliers: data?.suppliers, loading };
};

export default useSuppliersQuery;
