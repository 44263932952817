import { Box, Button, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FormikTextField from '../../components/form/FormikTextField';
import colors from '../../app/theme/colors.scss';
import useSupplierInsert from '../hooks/useSupplierInsert';
import usePopin from '../../hooks/usePopin';

const supplierInsertSchema = Yup.object({
	name: Yup.string().required('Vous devez spécifier un nom'),
	isRoot: Yup.boolean().required('Required'),
});

const SupplierDialogForm = (): JSX.Element => {
	const { insertSupplier } = useSupplierInsert();
	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Ajouter un nouveau fournisseur',
		maxWidth: 'xs',
		fullWidth: true,
		isDisplayHeader: true,
	});

	const initialValues = { name: '', isRoot: false };

	const handleSubmit = async (supplier: { name: string }, helpers: FormikHelpers<{ name: string }>): Promise<void> => {
		try {
			await insertSupplier(supplier.name);
			closePopin();
		} catch (error) {
			helpers.setErrors({
				name: `Impossible d'ajouter le fournisseur: ${supplier.name}, ce dernier existe déjà`,
			});
		}
	};

	return (
		<Box position="fixed" top={52.4} zIndex={2} bgcolor={colors.black100} width={257}>
			<>
				<Divider />
				<Box p={1}>
					<IconButton color="inherit" onClick={openPopin} size="large">
						<Box pr={1} pt={0.5}>
							<AddCircleIcon />
						</Box>
						<Typography variant="h6">Ajouter un fournisseur</Typography>
					</IconButton>
				</Box>
				<Divider />
				{renderPopin(
					<Formik<{ name: string }>
						initialValues={initialValues}
						validationSchema={supplierInsertSchema}
						onSubmit={handleSubmit}
					>
						<Form>
							<Box p={2} pt={3} pb={3}>
								<Typography variant="h4">Nom du fournisseur</Typography>
								<FormikTextField name="name" fullWidth variant="outlined" />
							</Box>
							<Divider />
							<Box p={3} display="flex" flexDirection="row-reverse">
								<Button variant="contained" color="primary" type="submit">
									Ajouter un fournisseur
								</Button>
							</Box>
						</Form>
					</Formik>
				)}
			</>
		</Box>
	);
};

export default SupplierDialogForm;
