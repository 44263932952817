import dayjs from 'dayjs';

export const businessDays = (format: string, nbDay: number, date?: Date | null) => {
	if (!date) return '';

	return dayjs(date, format).subtract(nbDay, 'day');
};

export const getDate = (date: Date | null, format = 'DD-MM-YYYY') => dayjs(date, format);
export const formatDateByDDMMYYYY = (date: Date, format = 'DD/MM/YYYY') => dayjs(date).format(format);
