import * as React from 'react';
import colors from '../../app/theme/colors.scss';
import { Box, Card, Typography } from '@mui/material';

interface FamilyAttributesProps {
	family?: string | null;
}

const FamilyAttributes = ({ family }: FamilyAttributesProps): JSX.Element => {
	return (
		<Card>
			<Box
				bgcolor={colors.grayCard}
				p={2}
				textAlign="center"
				borderTop={6}
				borderRadius="4px"
				borderColor={colors.indigo700}
			>
				<Typography variant="h4">{family}</Typography>
			</Box>
		</Card>
	);
};

export default FamilyAttributes;
