import { Box, TextField } from '@mui/material';
import NumberFormatCustom from 'components/form/NumberFormat';
import colors from 'app/theme/colors.scss';
import React, { useEffect, useState } from 'react';
import { Column, Row } from 'react-table';

interface EditableCellProps {
	value: number;
	row: Row;
	column: Column;
	updateMyData: (index: number, id: string | undefined, value: number) => void;
}
const EditableCellField = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateMyData,
}: EditableCellProps) => {
	const [value, setValue] = useState(initialValue);
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(Number(e.target.value));
		updateMyData(index, id, value);
	};

	const onBlur = () => {
		updateMyData(index, id, value);
	};

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<Box bgcolor={id === 'urgentMode' ? colors.grey : colors.white}>
			<TextField
				variant="outlined"
				name={'input' + index}
				InputProps={{
					inputComponent: NumberFormatCustom as any,
				}}
				value={value ?? ''}
				disabled={id === 'urgentMode'}
				onChange={onChange}
				onBlur={onBlur}
				autoComplete="off"
			/>
		</Box>
	);
};

export default EditableCellField;
