import React from 'react';
import { SelectionClientPrice } from './components/steps/SelectionClientPrice';
import FormikTextField from 'components/form/FormikTextField';
import { Box, Typography } from '@mui/material';

export const stepClientPriceQuote = ['Sélection du prix de vente', 'Prépresse', 'Commentaires', 'Validation'];

export const getStepContent = (step: number): JSX.Element | string => {
	switch (step) {
		case 0:
			return <SelectionClientPrice />;
		case 1:
			return (
				<Box ml={6}>
					<Typography variant="h4">Prepresse non traité pour le moment. A venir dans les versions futures.</Typography>
				</Box>
			);
		case 2:
			return <FormikTextField name="summaryPrice.comment" multiline rows={5} fullWidth variant="outlined" />;
		case 3:
			return (
				<Box ml={6}>
					<Typography variant="h4">
						Valider les informations préalablement rensiegnées et ajouter ce prix de vente au devis.
					</Typography>
				</Box>
			);
		default:
			return 'Unknown step';
	}
};
