import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import cache from './cache';
import { onError } from '@apollo/client/link/error';
import getErrorType, { UNAUTHORIZED } from './errors';
import { isString } from '../../utils/typeGuards';

const params = {
	link: ApolloLink.from([
		onError(({ graphQLErrors }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message }) => {
					if (message) {
						const error = getErrorType(message.toUpperCase());
						if (!isString(error) && error.statusCode === UNAUTHORIZED) {
							localStorage.removeItem('accessToken');
							window.location.href = `${window.location.origin}/login`;
							return;
						}
					}
				});
		}),
		setContext((_, { headers }) => {
			const token = localStorage.getItem('accessToken');

			return {
				headers: {
					...headers,
					authorization: `Bearer ${token}`,
				},
			};
		}),
		new HttpLink({
			uri: process.env.REACT_APP_ENDPOINT ?? '',
			fetchOptions: {
				notifyOnNetworkStatusChange: true,
			},
		}),
	]),
	cache,
};

const client = new ApolloClient(params);

export default client;
