import { gql } from '@apollo/client';
import { ORDER_ITEM_FRAGMENT, ORDER_ADDRESS_FRAGMENT } from './queries';

const GENERATE_AN_ORDER_INVOICE = gql`
	mutation GenerateOrderPdf($orderId: String!, $invoiceId: String) {
		pdf: generateOrderPdf(orderId: $orderId, invoiceId: $invoiceId) {
			success
			description
		}
	}
`;

const GET_UPLOAD_URL_INVOICE = gql`
	mutation CreateOrderInvoiceUploadUrl($id: String!, $mimetype: String!) {
		uploadUrl: createOrderInvoiceUploadUrl(id: $id, mimetype: $mimetype)
	}
`;

const UPSERT_COCKPIT_ORDER = gql`
	mutation upsertCockpitOrder($order: CockpitOrderUpsertInput!) {
		upsertCockpitOrder(order: $order) {
			id
			status
			createdAt
			updatedAt
			source
			paymentType
			comment
			clientName
			buyingPriceHt
			sellingPriceHt
			buyingPriceHtWithRfa
			marginWithClientRfa
			realMargin
			marginRate
			elepvContactName
			elpevContactEmail
			elpevContactPhone
			createdAt
			status
			billingAddress {
				...OrderAddress
			}
			childrenOrder {
				id
				clientName
				billingAddress {
					...OrderAddress
				}
				orderItem {
					id
					productName
					quantity
				}
				invoices {
					createdAt
					id
					sendAt
					status
					validateAt
					originalInvoiceName
				}
			}
			orderItem {
				id
				productName
				quantity
			}
			invoices {
				createdAt
				validateAt
				sendAt
				status
				originalInvoiceName
			}
		}
	}
	${ORDER_ADDRESS_FRAGMENT}
`;

const UPSERT_ORDER = gql`
	mutation upsertOrder($upsertOrderInput: UpsertOrderInput!) {
		upsertOrder(order: $upsertOrderInput) {
			elepvContactName
			elpevContactEmail
			elpevContactPhone

			createdAt
			status
			clientName
			clientId
			orderItem {
				...OrderItem
			}
			billingAddress {
				...OrderAddress
			}
		}
	}
	${ORDER_ADDRESS_FRAGMENT}
	${ORDER_ITEM_FRAGMENT}
`;

export {UPSERT_COCKPIT_ORDER, UPSERT_ORDER, GET_UPLOAD_URL_INVOICE, GENERATE_AN_ORDER_INVOICE };
