import { Box, List, ListItem } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Client } from '../../graphql/types';
import colors from '../../app/theme/colors.scss';
import useSearch from '../../hooks/useSearch';

interface ClientListSelectProps {
	clients: Array<Pick<Client, 'id' | 'name'>>;
	onClientSelected: (client: Pick<Client, 'id' | 'name'>) => void;
}

const ClientListSelect = ({ clients, onClientSelected }: ClientListSelectProps) => {
	const location = useLocation();
	const { renderSearchComponent, filtredItems } = useSearch('name', clients);

	return (
		<>
			<Box p={2} position="fixed" zIndex={2} bgcolor={colors.black100} width={257}>
				{renderSearchComponent('Client')}
			</Box>
			<Box pb={5} mb={2} pt={8}>
				<List>
					{filtredItems?.map((client) => (
						<ListItem
							button
							key={client.id}
							onClick={() => onClientSelected(client)}
							selected={location.pathname.includes(client.id)}
						>
							{client.name}
						</ListItem>
					))}
				</List>
			</Box>
		</>
	);
};

export default ClientListSelect;
