import { useMutation } from '@apollo/client';
import { INSERT_TRANSPORTER } from '../graphql/mutations';
import { InsertTransporterMutation, InsertTransporterMutationVariables } from '../graphql/mutations.definitions';
import { GET_TRANSPORTERS } from '../graphql/queries';

const useParcelTransporterInsert = () => {
	const [insertParcelTransportMutation] = useMutation<InsertTransporterMutation, InsertTransporterMutationVariables>(
		INSERT_TRANSPORTER
	);

	const insertParcelTransport = async (name: string) => {
		const { data } = await insertParcelTransportMutation({
			variables: {
				name,
			},
			refetchQueries: [
				{
					query: GET_TRANSPORTERS,
				},
			],
			awaitRefetchQueries: true,
		});

		return { transporter: data?.insertTransporter };
	};

	return { insertParcelTransport };
};

export default useParcelTransporterInsert;
