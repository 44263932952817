import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { QuotePreviewTable } from '../../../definition';
import { Box, Typography } from '@mui/material';
import { TableType } from '../../../../../components/tables/list-table/tableType';
import { isAttributeConditionnement } from '../../../../../utils/typeGuards';
import ConditionnementDetails from '../../../../../product/components/ConditionementDetails';

export const useQuotePreviewTable = (quoteAttributeConfig: QuotePreviewTable[], index: number) => {
	const columns = useMemo<Column<QuotePreviewTable>[]>(
		() => [
			...(() => {
				//@todo revoir le typage du Header
				let columnItem: any[] = [];
				quoteAttributeConfig.forEach((quoteAttribute) => {
					Object.keys(quoteAttribute).forEach((key, i) => {
						columnItem = [
							...columnItem,
							{
								accessor: key,
								//@todo revoir le typage du Header
								Header: (head: any) => {
									return (
										<Typography variant="h6">
											{key === 'attribute' || (index === 0 && i !== 0)
												? head.data[0][key].family === 'Prix de transport'
													? 'Transport'
													: head.data[0][key].family === 'Prix prépresse'
													? 'Prépresse'
													: head.data[0][key].family
												: ''}
										</Typography>
									);
								},
								//@todo revoir le typage du Cell
								Cell: (cell: {
									row: { original: { [x: string]: { characteristics: { name: string; value: string }[] } } };
								}) => (
									<Box>
										{cell?.row.original[key].characteristics.map((characteristic, i) => {
											return (
												<Box key={i} width="220px" height="auto">
													{isAttributeConditionnement(characteristic.value) ? (
														<ConditionnementDetails detail={characteristic.value} />
													) : (
														<Typography variant="body2">{characteristic.value ?? ''}</Typography>
													)}
												</Box>
											);
										})}
									</Box>
								),
								width: 10,
							},
						];
					});
				});
				return columnItem;
			})(),
		],
		[quoteAttributeConfig, index]
	);

	return useTable({
		data: quoteAttributeConfig,
		columns,
		type: TableType.clientQuote,
	});
};
