import * as React from 'react';
import { useField } from 'formik';

import useClientQuoteLazyQuery from 'client-quote/hooks/useClientQuoteLazyQuery';
import { IProductInformationContext, SelectedProduct, ProductInformationProviderProps } from '../../../types';

const ProductInformationContext = React.createContext<IProductInformationContext | undefined>(undefined);

const ProductInformationProvider = ({ children }: ProductInformationProviderProps) => {
	const [selectedProduct, setSelectedProduct] = React.useState<SelectedProduct>(null);

	const [{ value: clientQuoteId }] = useField<string | null>('clientQuoteId');

	const { getClientQuote, clientQuote } = useClientQuoteLazyQuery();
	React.useEffect(() => {
		if (clientQuoteId) {
			getClientQuote({
				variables: {
					id: clientQuoteId,
				},
			});
		}
	}, [clientQuoteId, getClientQuote]);

	const filteredProducts = React.useMemo(
		() => clientQuote?.clientQuoteItem?.filter(({ productName }) => productName === selectedProduct?.productName) ?? [],
		[clientQuote, selectedProduct]
	);

	const providerValue = React.useMemo(
		() => ({
			selectedProduct,
			setSelectedProduct,
			filteredProducts,
			clientQuote,
		}),
		[clientQuote, filteredProducts, selectedProduct]
	);

	return <ProductInformationContext.Provider value={providerValue}>{children}</ProductInformationContext.Provider>;
};

const useProductInformation = () => {
	const context = React.useContext<IProductInformationContext | undefined>(ProductInformationContext);

	if (context === undefined) {
		throw new Error('useProductInformation must be used within a ProductInformationProvider');
	}

	return context;
};

export { useProductInformation, ProductInformationProvider };
