import React from 'react';
import { Box, Button, Card, CardActionArea, CardContent, Collapse, Grid, Paper, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useHistory } from 'react-router-dom';

import useRolesQuery from './hooks/useRolesQuery';
import useCollapse from '../../hooks/useCollapse';

const RolesList = (): JSX.Element => {
	const { renderCollapseComponent, displayContent } = useCollapse();
	const { roles } = useRolesQuery();
	const history = useHistory();

	return (
		<Box pt={3}>
			<Box display={'flex'} pb={2}>
				<Box display={'flex'} flexGrow={1}>
					<Box pr={2}>
						<Typography variant="h2">Gestion des rôles</Typography>
					</Box>
					{renderCollapseComponent()}
				</Box>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						history.push(`/admin/role/newrole`);
					}}
				>
					<AddCircleIcon />
					<Box pl={1}>Ajouter un rôle</Box>
				</Button>
			</Box>
			<Collapse in={displayContent}>
				<Grid container spacing={3}>
					{roles?.map((roleItem, index) => (
						<Grid item xs={4} key={index}>
							<Paper variant="outlined">
								<Card>
									<CardActionArea
										onClick={() => {
											history.push(`/admin/role/${roleItem.id}`);
										}}
									>
										<CardContent>
											<Typography variant="h2"> {roleItem.name}</Typography>
											<Typography variant="h5" color="textSecondary">
												{roleItem.description}
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Paper>
						</Grid>
					))}
				</Grid>
			</Collapse>
		</Box>
	);
};

export default RolesList;
