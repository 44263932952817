import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuComponentProps } from '../../Header/definitions';
import AddSupportDialogue from './AddSupportDialogue';
import ListSupportsSelect from './ListSupportsSelect';
import useSupportsQuery from './hooks/useSupportsQuery';
import { Support } from '../../graphql/types';

interface ListSupportsMenuProps extends MenuComponentProps {}
const ListSupportsMenu = ({ onClose }: ListSupportsMenuProps) => {
	const history = useHistory();
	const { supports } = useSupportsQuery();

	const handleSupportSelected = (rowSupport: Support) => {
		history.push(`/admin/support/${rowSupport.id}`);
		onClose?.();
	};

	return (
		<>
			<AddSupportDialogue />
			<Box mt={6.5}>
				{supports && (
					<ListSupportsSelect supports={supports} path={'/admin/support/'} onSupportSelected={handleSupportSelected} />
				)}
			</Box>
		</>
	);
};

export default ListSupportsMenu;
