import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import PricesProductContext from 'context/pricesProductContext/PricesProductContext';
import { useField, useFormikContext } from 'formik';
import { ClientPriceModel } from '../../ClientPriceForm';
import { Tirage } from '../../definitions';
import { sum } from 'ramda';
import usePopin from '../../../../hooks/usePopin';
import { getProductAttributes } from '../../helpers';
import { useListCheapestSupplier } from './useListCheapestSupplier';
import { OnChangeUnControlledArgsType } from 'components/form/FormikTextField';
import { ProductSellingPriceProps } from '../definitions';
import { SupplierPriceFormModel } from 'supplier/definitions';
import { FILTERED_SUPPLIER_PRICE } from 'supplier/graphql/queries';
import { ClariPrintCorrespondence, ProductAttributesValues } from 'graphql/types';
import { calculateProductTotalWeight } from '../../../helpers/calculateProductTotalWeight';
import { typeNotifications } from '../../../../utils/definition';
import { useSnackbar } from 'notistack';

export const useManageProductSellingPrice = ({
	onGetSupplierPrices,
	isClariPrint,
	supportId,
	productId,
	supportMetaData,
}: Omit<ProductSellingPriceProps, 'supplierPricesLoading'> & {
	supportId: string;
	productId?: string;
	supportMetaData?: ClariPrintCorrespondence;
}) => {
	const context = useContext(PricesProductContext);
	const { enqueueSnackbar } = useSnackbar();
	const { values, setFieldValue } = useFormikContext<
		ClientPriceModel & { tirage: Tirage; quantitiesIsNotIdentical?: number[] }
	>();
	const [fieldQuantity, metaQuantity, helpersQuantity] = useField<number>('quantity');
	const [fieldTools, ,] = useField<boolean>('tools');
	const [quantityDisabled, setQuantityDisabled] = useState<boolean>(false);
	const [, , helpersDepartingDepartment] = useField<string | undefined>('departingDepartment');
	const [listSupplierPrices, , helpersListSupplierPrices] = useField<SupplierPriceFormModel[] | undefined>(
		'listSupplierPrices'
	);
	const [supplierPrices, , helpersSupplierPrices] = useField<SupplierPriceFormModel[]>('supplierPrices');
	const [, , helpersProductTotalWeight] = useField<number>('productTotalWeight');
	const [, metaNumberVersion, helpersNumberVersion] = useField<number | string>('numberVersion');
	const client = useApolloClient();

	const [openPopin, renderingSuppliers] = usePopin({
		headerTitle: 'Liste des fournisseurs',
		fullWidth: true,
		isDisplayHeader: true,
		maxWidth: 'lg',
	});

	const handleSupplierList = useCallback(async () => {
		onGetSupplierPrices({
			variables: {
				filters: {
					supportId,
					productId: productId ?? context?.selectedProduct?.id,
					quantity: fieldQuantity.value,
					isCheapestSupplier: false,
					tools: fieldTools.value,
					isIdentical: values.isIdentical,
					numberVersion: values.numberVersion,
					quantities: values.quantities,
					...(isClariPrint &&
						context.productFilter &&
						context && { productAttributes: getProductAttributes(context.productFilter) as ProductAttributesValues }),
				},
			},
		});

		listSupplierPrices.value?.length
			? openPopin()
			: enqueueSnackbar(
					'Le produit sélectionné ne peut pas être réalisé. Merci de revoir la configuration de votre produit.',
					{ variant: typeNotifications.Error }
			  );
	}, [
		fieldQuantity.value,
		fieldTools.value,
		isClariPrint,
		onGetSupplierPrices,
		openPopin,
		context,
		productId,
		supportId,
		values.isIdentical,
		values.numberVersion,
		values.quantities,
		enqueueSnackbar,
		listSupplierPrices.value?.length,
	]);

	const handleChangeSupplierPrices = useCallback(async () => {
		if (values.quantity >= 1 && !metaNumberVersion.error) {
			const { data } = await client.query({
				query: FILTERED_SUPPLIER_PRICE,
				variables: {
					filters: {
						supportId,
						productId: productId ?? context?.selectedProduct?.id,
						quantity: values.quantity,
						isCheapestSupplier: true,
						tools: fieldTools.value,
						isIdentical: values.isIdentical,
						numberVersion: values.numberVersion,
						quantities: values.quantities,
						...(isClariPrint &&
							context.productFilter &&
							context && { productAttributes: getProductAttributes(context.productFilter) as ProductAttributesValues }),
					},
				},
			});

			if (data.supplierPrices.length === 1) {
				helpersSupplierPrices.setValue(data.supplierPrices);
				helpersDepartingDepartment.setValue(data.supplierPrices[0]?.departingDepartment || undefined);
			}
			helpersListSupplierPrices.setValue(data.supplierPrices);
			const product = isClariPrint
				? {
						largeurFormatFiniCm: context?.productFilter?.largeurFormatFiniCm?.name,
						longueurFormatFiniCm: context?.productFilter?.longueurFormatFiniCm?.name,
						largeurFormatOuvertCm: context?.productFilter?.largeurFormatOuvertCm?.name,
						longueurFormatOuvertCm: context?.productFilter?.longueurFormatOuvertCm?.name,
						grammageGr: context?.productFilter?.grammageGr?.name,
						paginationTotale: context?.productFilter?.paginationTotale?.name,
				  }
				: {
						largeurFormatFiniCm: context?.selectedProduct?.largeurFormatFiniCm,
						longueurFormatFiniCm: context?.selectedProduct?.longueurFormatFiniCm,
						grammageGr: context?.selectedProduct?.grammageGr,
						paginationTotale: context?.selectedProduct?.paginationTotale,
				  };
			const totalWeight = calculateProductTotalWeight(
				values.quantity,
				product,
				data?.supplierPrices?.[0]?.poidsUnitaireGr,
				supportMetaData
			);
			context?.setProductTotalWeight(totalWeight);
			helpersProductTotalWeight.setValue(totalWeight);
		}

		context?.setQuantity(values.quantity);
		helpersQuantity.setValue(values.quantity);
		helpersNumberVersion.setTouched(true, true);
	}, [
		context,
		values.quantity,
		metaNumberVersion.error,
		helpersQuantity,
		helpersNumberVersion,
		client,
		supportId,
		productId,
		fieldTools.value,
		helpersListSupplierPrices,
		isClariPrint,
		helpersProductTotalWeight,
		helpersSupplierPrices,
		helpersDepartingDepartment,
		values.isIdentical,
		values.numberVersion,
		values.quantities,
		supportMetaData,
	]);

	const nbOfMaxPoses = useMemo(
		() => listSupplierPrices?.value?.length === 1 && listSupplierPrices?.value[0].nbPosesMax,
		[listSupplierPrices]
	);

	const instanceSuppliers = useListCheapestSupplier(listSupplierPrices?.value ? listSupplierPrices?.value : []);

	const equalQuantityCalculation = useCallback(
		(version: number) => {
			const equalQuantity = Math.ceil(values.quantity / version);
			setFieldValue(
				'quantities',
				new Array(version).fill(0).map((_) => equalQuantity)
			);
			setFieldValue(
				'quantitiesIsNotIdentical',
				new Array(version).fill(0).map((_) => equalQuantity)
			);
		},
		[setFieldValue, values.quantity]
	);

	const handleVersionNumber = useCallback(
		(event: OnChangeUnControlledArgsType) => {
			event.preventDefault();
			const numberVersion = +event.target.value;
			if (numberVersion <= 1) {
				setQuantityDisabled(false);
				setFieldValue('quantities', []);
			} else {
				if (values.isIdentical) {
					equalQuantityCalculation(numberVersion);
				} else {
					if (values.quantitiesIsNotIdentical)
						if (numberVersion >= values.quantitiesIsNotIdentical.length) {
							setFieldValue('quantities', [...values.quantitiesIsNotIdentical, 0]);
							setFieldValue('quantitiesIsNotIdentical', [...values.quantitiesIsNotIdentical, 0]);
						} else {
							const quantities = values.quantitiesIsNotIdentical.slice(0, values.quantitiesIsNotIdentical.length - 1);
							setFieldValue('quantities', quantities);
							setFieldValue('quantity', sum(quantities));
							setFieldValue('quantitiesIsNotIdentical', quantities);
						}
				}
			}
			setFieldValue('numberVersion', numberVersion);
		},
		[equalQuantityCalculation, setFieldValue, values.isIdentical, values.quantitiesIsNotIdentical]
	);

	const handleStatusSwitch = useCallback(
		(status: boolean) => {
			setQuantityDisabled((previousQuantityDisabled) => !previousQuantityDisabled);
			setFieldValue('isIdentical', status);
			setFieldValue('quantities', [...new Array(values.numberVersion).fill(0)]);
			setFieldValue('quantity', values.quantitiesIsNotIdentical ? sum(values.quantitiesIsNotIdentical) : 0);
		},
		[setFieldValue, values.numberVersion, values.quantitiesIsNotIdentical]
	);

	useEffect(() => {
		if (values.isIdentical && values.numberVersion > 1) {
			equalQuantityCalculation(values.numberVersion);
		}
	}, [values.quantity, values.numberVersion, setFieldValue, values.isIdentical, equalQuantityCalculation]);

	useEffect(() => {
		let intervalID: NodeJS.Timeout;
		if (!values.isIdentical && values.numberVersion > 1) {
			intervalID = setTimeout(() => {
				if (values.quantitiesIsNotIdentical) {
					setFieldValue('quantities', values.quantitiesIsNotIdentical);
					setFieldValue('quantity', sum(values.quantitiesIsNotIdentical));
				}
			}, 2000);
		}
		return () => intervalID && clearTimeout(intervalID);
	}, [values.numberVersion, setFieldValue, values.isIdentical, values.quantities, values.quantitiesIsNotIdentical]);

	return {
		handleStatusSwitch,
		handleVersionNumber,
		instanceSuppliers,
		nbOfMaxPoses,
		handleSupplierList,
		handleChangeSupplierPrices,
		renderingSuppliers,
		quantityDisabled,
		supplierPrices: supplierPrices.value,
		metaQuantity,
		values,
	};
};
