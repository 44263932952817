import { useQuery } from '@apollo/client';
import {
	ClientPricesWhitPaginantionQuery,
	ClientPricesWhitPaginantionQueryVariables,
} from 'clientPrice/graphql/queries.definitions';
import { ClientPriceSortInput, ClientPricesSearchInput, PaginationInput } from 'graphql/types';
import { GET_ALL_CLIENTS_PRICES_WITH_PAGINATION } from '../graphql/queries';

const useAllClientsPricesWithPagination = (
	search?: ClientPricesSearchInput,
	sort?: ClientPriceSortInput,
	pagination?: PaginationInput
) => {
	const { data, loading } = useQuery<ClientPricesWhitPaginantionQuery, ClientPricesWhitPaginantionQueryVariables>(
		GET_ALL_CLIENTS_PRICES_WITH_PAGINATION,

		{
			fetchPolicy: 'cache-and-network',
			variables: {
				search: {
					...search,
					quantity: Number(search?.quantity),
					productSalesPriceExcludingVAT: Number(search?.productSalesPriceExcludingVAT),
					transportSalesPriceExcludingVAT: Number(search?.transportSalesPriceExcludingVAT),
					cost: Number(search?.cost),
					numberVersion: Number(search?.numberVersion),
				},
				sort,
				pagination,
			},
		}
	);

	return {
		clientPrices: data?.clientPricesWhitPaginantion?.clientPrices,
		total: data?.clientPricesWhitPaginantion?.total,
		loading,
	};
};

export default useAllClientsPricesWithPagination;
