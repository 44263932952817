import { Box, Typography } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import usePopin from 'hooks/usePopin';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface StatusInformationPopinProps {
	statusIsDisabled?: boolean;
}

const StatusInformationPopin = ({ statusIsDisabled }: StatusInformationPopinProps): JSX.Element => {
	const [onepacUserName] = useField<string>('onepacUserName');
	const [onepacUserId] = useField<string>('onepacUserId');
	const [statusComment] = useField<string>('statusComment');
	const [statusUpdatedAt] = useField<string>('statusUpdatedAt');

	const [openPopin, renderPopin] = usePopin({
		headerTitle: 'Détail de mise a jour',
		maxWidth: 'xl',
		fullWidth: false,
		isDisplayHeader: true,
	});

	return (
		<>
			<InfoOutlinedIcon color={'disabled'} onClick={openPopin} />
			{renderPopin(
				<Box p={3}>
					<Typography variant="h4">
						<b>Nom du valideur : </b>
						{onepacUserName.value} ( {onepacUserId.value} )
					</Typography>
					<Typography variant="h4">
						<b>Date de mise a jour du statut : </b>
						{statusUpdatedAt.value && new Date(statusUpdatedAt.value).toLocaleDateString()}
					</Typography>
					<Typography variant="h4">
						<b>Motif : </b>
						{statusComment.value}
					</Typography>
					{statusIsDisabled && (
						<Typography>
							Le statut ne peut pas être modifié car un flux de validation client est en cours sur cette commande
						</Typography>
					)}
				</Box>
			)}
		</>
	);
};

export default StatusInformationPopin;
