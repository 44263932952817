import React from 'react';
import { Typography } from '@mui/material';

type Variant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'caption'
	| 'button'
	| 'overline';

export const ShowItemsQuote = ({ categories, variant }: { categories: string[]; variant?: Variant }) => (
	<>
		{categories.map((category) => (
			<Typography key={category} variant={variant}>
				{category}
			</Typography>
		))}
	</>
);
