import { BoxShippingMode, Client, ClientQuoteItem, TransportMoment } from 'graphql/types';
import cloneDeep from 'lodash.clonedeep';

export type DistributionsGroupedByAddresses = {
	clientId: string;
	clientName?: string;
	clientShippingAddressId: string;
	clientShippingAddress?: ClientShippingAddress | null;
	productIdsQuantities: {
		productId: string;
		quantity: number;
		productName?: string;
		globalQuantity?: number;
		deliveryParcel?: string;
		transportMoment?: string;
	}[];
};

export type Distribution = {
	__typename?: string;
	storeId: string;
	store?: Client | null;
	clientShippingAddressId: string;
	clientShippingAddress?: ClientShippingAddress | null;
	productId: string;
	productName: string;
	quantity: number;
	deliveryParcel?: BoxShippingMode;
	transportMoment?: TransportMoment;
};

export type ClientsName = {
	id: string;
	name?: string;
};

export type ClientShippingAddress = {
	id: string;
	address?: {
		name?: string;
		firstAddress?: string;
		secondAddress?: string;
		thirdAddress?: string;
		zipCode?: string;
		city?: string;
	};
	contact?: {
		directorName?: string;
		phone?: string;
		storeCode?: string;
	};
};
export type ClientWithShippingAdresses = {
	id: string;
	name?: string;
	clientShippingAddress: ClientShippingAddress[];
};

export const flattenDistributionsGroupedByAddresses = (
	distributionsGroupedByAddresses: DistributionsGroupedByAddresses[]
): Distribution[] => {
	const flatDistributions = [];
	for (const distribution of distributionsGroupedByAddresses) {
		for (const productIdQuantity of distribution.productIdsQuantities) {
			if (+productIdQuantity.quantity === 0) {
				continue;
			}
			flatDistributions.push({
				productId: productIdQuantity.productId,
				productName: productIdQuantity.productName || '',
				quantity: productIdQuantity.quantity,
				storeId: distribution.clientId,
				clientShippingAddressId: distribution.clientShippingAddressId,
				transportMoment: productIdQuantity.transportMoment as TransportMoment,
				deliveryParcel: productIdQuantity.deliveryParcel as BoxShippingMode,
			});
		}
	}

	return flatDistributions;
};

export const groupedDistributionByAddresses = (
	flatDistributions: Distribution[],
	clientQuoteItems?: ClientQuoteItem[] | null
): DistributionsGroupedByAddresses[] => {
	let clientQuoteItemsClone = cloneDeep(clientQuoteItems);
	const result: DistributionsGroupedByAddresses[] = [];
	for (const distribution of flatDistributions) {
		const findInGroupArray = result.find(
			(item) => item.clientShippingAddressId === distribution.clientShippingAddressId
		);

		const findInQuoteItems = clientQuoteItemsClone?.find(
			(quoteItem: any) => quoteItem.productId === distribution.productId
		);

		let nameAndGlobalQuantity: { productName?: string; globalQuantity?: number } = {
			productName: distribution.productName,
		};

		if (findInQuoteItems) {
			nameAndGlobalQuantity = {
				...nameAndGlobalQuantity,
				globalQuantity: findInQuoteItems.quantity,
			};
			clientQuoteItemsClone = clientQuoteItemsClone?.filter(
				(clientQuoteItem) => clientQuoteItem.id !== findInQuoteItems.id
			);
		}

		if (findInGroupArray) {
			findInGroupArray.productIdsQuantities.push({
				productId: distribution.productId,
				quantity: distribution.quantity,
				deliveryParcel: distribution.deliveryParcel,
				transportMoment: distribution.transportMoment,
				...nameAndGlobalQuantity,
			});
			continue;
		}

		result.push({
			clientId: distribution.storeId,
			clientName: distribution.store?.name,
			clientShippingAddressId: distribution.clientShippingAddressId,
			clientShippingAddress: distribution.clientShippingAddress,
			productIdsQuantities: [
				{
					productId: distribution.productId,
					quantity: distribution.quantity,
					deliveryParcel: distribution.deliveryParcel,
					transportMoment: distribution.transportMoment,
					...nameAndGlobalQuantity,
				},
			],
		});
	}

	return result;
};
