import { useLazyQuery } from '@apollo/client';
import { GET_CLIENT_QUOTE_BY_ID } from '../graphql/queries';
import { GetClientQuoteByIdQuery, GetClientQuoteByIdQueryVariables } from '../graphql/queries.definitions';

const useClientQuoteLazyQuery = () => {
	const [getClientQuote, { data, loading, refetch }] = useLazyQuery<
		GetClientQuoteByIdQuery,
		GetClientQuoteByIdQueryVariables
	>(GET_CLIENT_QUOTE_BY_ID);
	return { clientQuote: data?.clientQuote, loading, refetchClientQuote: refetch, getClientQuote };
};

export default useClientQuoteLazyQuery;
