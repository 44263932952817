import { Box, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import { FormikNumberField } from '../../../../components/form/FormikNumberField';
import { LayoutField } from '../../../../components/form/LayoutField';
import { UncontrolledDatePicker } from '../../../../components/picker/UncontrolledDatePicker';
import { SupplierUpdateInput } from '../../../../graphql/types';

const RemiseField = () => {
	const {
		values: { ...supplierUpdateInput },
		setFieldValue,
	} = useFormikContext<SupplierUpdateInput>();

	const handleValidFromChange = (date: unknown) => {
		setFieldValue('rspStart', date);
	};

	const handleValidToChange = (date: unknown) => {
		setFieldValue('rspEnd', date);
	};

	return (
		<Box mt={4}>
			<Box my={4}>
				<DashedHeader>Remise sur période</DashedHeader>
				<Grid container>
					<Grid item xs={6}>
						<LayoutField label="Pourcentage RSP">
							<Box width="110px" display="flex">
								<FormikNumberField decimalScale={2} name="rsp" width="139px" maxValue={100} variant="outlined" />

								<Box mx={1} mt={0.5}>
									<Typography variant="h4"> % </Typography>
								</Box>
							</Box>
						</LayoutField>
					</Grid>
					<Grid item xs={6}>
						<LayoutField label="Date de début de période">
							<Box width={164} mb={2}>
								<UncontrolledDatePicker
									value={supplierUpdateInput.rspStart}
									handleClear={() => setFieldValue('rspStart', null)}
									onChange={handleValidFromChange}
									name="rspStart"
								/>
							</Box>
						</LayoutField>

						<LayoutField label="Date de fin de période">
							<Box width={164}>
								<UncontrolledDatePicker
									value={supplierUpdateInput.rspEnd}
									handleClear={() => setFieldValue('rspEnd', null)}
									onChange={handleValidToChange}
									name="rspEnd"
								/>
							</Box>
						</LayoutField>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default RemiseField;
