import { useQuery } from '@apollo/client';
import { GET_SUPPORT_BY_ID } from '../graphql/queries';
import { GetSupportByIdQuery, GetSupportByIdQueryVariables } from '../graphql/queries.definitions';

const useSupportByIdQuery = (supportId: string) => {
	const { data, loading } = useQuery<GetSupportByIdQuery, GetSupportByIdQueryVariables>(GET_SUPPORT_BY_ID, {
		variables: { supportId },
	});

	return { support: data?.support, loading };
};

export default useSupportByIdQuery;
