import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useQuery } from '@apollo/client';
import * as queries from '../../../app/graphql/queries';
import * as XLSX from 'xlsx';
import { Data } from '../../Displaydata/Displaydata';

interface props {
	goTo: (step: string) => void;
	setData: React.Dispatch<React.SetStateAction<Data>>;
}

const Importfilepopup: React.FC<props> = (props) => {
	const [open, setOpen] = React.useState<boolean>(false);
	const [supplier, setSupplier] = React.useState<string>('');
	const { data } = useQuery(queries.GET_SUPPLIERS);
	const [file, setFile] = React.useState<File | null>(null);
	const [validfile, setvalidfile] = React.useState<boolean>(false);
	const [validSupplier, setValidSupplier] = React.useState<boolean>(false);
	const [validDate, setValidDate] = React.useState<boolean>(false);

	const defaultdate = (): string => {
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		let yyyy = today.getFullYear() + 1;

		return yyyy + '-' + mm + '-' + dd;
	};
	const [vdate, setvdate] = React.useState<string>(defaultdate());

	const buttonstyle: React.CSSProperties = {
		float: 'right',
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSupplier(event.target.value);
	};

	const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
		setvdate(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setvalidfile(false);
		setFile(null);
	};

	let supplierOption;

	if (data) {
		supplierOption = data.suppliers.map((opt: any) => (
			<option key={opt.id} value={opt.name}>
				{opt.name}
			</option>
		));
	}
	const validate = () => {
		let isError = false;
		if (!file) {
			setFile(null);
			setvalidfile(true);
			isError = true;
		}

		if (!supplier) {
			setValidSupplier(true);
			isError = true;
		}
		const now = new Date();
		const date = new Date(vdate);
		if (date.setHours(0, 0, 0, 0) <= now.setHours(0, 0, 0, 0)) {
			setValidDate(true);
			isError = true;
		}

		if (!isError && file) {
			excelRenderer(file, (res: any) => {});
			props.goTo('import');
			setOpen(false);
		}
	};

	const excelRenderer = (file: File | undefined, callback: Function) => {
		let promise = new Promise(function (resolve, reject) {
			let reader = new FileReader();
			reader.onload = function (e) {
				/* Parse data */
				const bstr = e.target ? e.target.result : null;
				const wb = XLSX.read(bstr, { type: 'binary', cellStyles: true });
				let ws: { [k: string]: any } = {};

				// if (selectedTab) {
				// 	ws = wb.Sheets[selectedTab];
				// } else {
				// 	var wsname = wb.SheetNames[0];
				// }
				let mergedCells: Object[] | undefined;
				if (ws['!merges']) {
				}
				// let cols = makeCols(ws['!ref']);

				let arrayData: { [key: string]: any[] } = {};
				let arrayMergedCells: { [key: string]: any[] | undefined } = {};
				wb.SheetNames.forEach((sheetName: string) => {
					ws = wb.Sheets[sheetName];
					let json = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '', raw: true });
					let datasheetFormatData = xlsxToDataSheet(json, false);
					arrayData[sheetName] = datasheetFormatData;
					mergedCells = getMergedCells(ws['!merges']);
					arrayMergedCells[sheetName] = mergedCells;
				});

				let data = {
					data: arrayData,
					fileTabs: wb.SheetNames,
					mergedCells: arrayMergedCells ? arrayMergedCells : [],
				};

				resolve(data);
				return callback(null, data);
			};
			if (file) reader.readAsBinaryString(file);
		});

		promise.then((res: any) => {
			let grids: { [key: string]: any[][] } = {};
			res.fileTabs.forEach((fileTab: string) => {
				res.data[fileTab].forEach((row: [], r: number) => {
					let previousColSpan = 1;
					let previousRowSpan = 1;
					row.forEach((cell: any, c: number) => {
						cell.oldCol = c;
						cell.oldRow = r;
						if (res.mergedCells[fileTab]) {
							let filteredCell = res.mergedCells[fileTab].find((mergedCell: any) => {
								return mergedCell.start.r === r && mergedCell.start.c === c;
							});
							cell.previousColSpan = previousColSpan;
							cell.previousRowSpan = previousRowSpan;
							if (filteredCell) {
								previousColSpan = filteredCell.colspan;
								previousRowSpan = filteredCell.rowspan;

								cell.colSpan = filteredCell.colspan;
								cell.rowSpan = filteredCell.rowspan;
							} else {
								cell.previousColSpan = 1;
								cell.previousRowSpan = 1;
								cell.colSpan = 1;
								cell.rowSpan = 1;
							}
						}
					});
				});
				let newGrid = res.data[fileTab];
				res.data[fileTab].forEach((row: [], r: number) => {
					row.forEach((cell: any, c: number) => {
						if (cell.rowSpan > 1) {
							for (let i = r; i < r + cell.rowSpan - 1; i++) {
								if (newGrid[i + 1]) {
									newGrid[i + 1].splice(c, cell.colSpan);
								}
							}
						}
					});
				});
				res.data[fileTab].forEach((row: [], r: number) => {
					row.forEach((cell: any, c: number) => {
						if (cell.colSpan > 1) {
							newGrid[r].splice(c + 1, cell.colSpan - 1);
						}
					});
				});

				// setFileTabs(res.fileTabs);
				// setSelectedTab(selectedTab ? selectedTab : res.fileTabs ? res.fileTabs[0] : '');

				grids[fileTab] = newGrid;
			});

			props.setData({ step: 'importer un fichier', supplier, grids, date: vdate });
		});
	};

	const getMergedCells = (cells: any[] | undefined) => {
		let arrayMerges = Array<Object>();
		if (cells) {
			cells.forEach((cell: { [k: string]: any }) => {
				if (cell.e && cell.s) {
					let colDiff = (cell.e.c ? cell.e.c : 0) - (cell.s.c ? cell.s.c : 0) + 1;
					let rowDiff = (cell.e.r ? cell.e.r : 0) - (cell.s.r ? cell.s.r : 0) + 1;
					arrayMerges.push({ start: cell.s, colspan: colDiff, rowspan: rowDiff });
				}
			});
			return arrayMerges;
		}
	};

	const xlsxToDataSheet = (data: any, isEmpty: Boolean) => {
		let finalOutput = Array<Object>();
		let elementOutput: Object[] = [];
		data.forEach((row: [], index: number) => {
			row.forEach((element) => {
				if (isEmpty) {
					elementOutput.push({ value: '' });
				} else {
					elementOutput.push({ value: element });
				}
			});
			finalOutput.push(elementOutput);
			elementOutput = [];
		});
		return finalOutput;
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const ext = event.target.files[0].name.split('.').pop();

			if (ext === 'xlsx' || ext === 'xls') {
				setvalidfile(false);
				setFile(event.target.files[0]);
			} else {
				setFile(null);
				setvalidfile(true);
			}
		}
	};

	return (
		<div>
			<Button variant="contained" style={buttonstyle} color="primary" onClick={handleClickOpen}>
				Intégrer un fichier
			</Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title"> Importer un fichier </DialogTitle>
				<DialogContent>
					<DialogContentText>Veuillez sélectionner le fichier à intégrer dans les 4 Fantastiques</DialogContentText>
					<Grid container spacing={0}>
						<Grid item xs={9}>
							<TextField
								error={validfile}
								id="standard-readOnly"
								label="Nom du fichier à importer"
								value={file?.name || ' '}
								InputProps={{
									readOnly: true,
								}}
								fullWidth
								helperText={validfile ? 'Veuillez choisir un fichier Excel ! ' : ''}
							/>
						</Grid>
						<Grid item xs={3}>
							<Button variant="contained" component="label" id="selectfile">
								Selectionner
								<input
									type="file"
									style={{ display: 'none' }}
									onChange={onChangeHandler}
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								/>
							</Button>
						</Grid>
						<Grid item xs={9}>
							<TextField
								error={validSupplier}
								id="standard-select-currency-native"
								select
								label="Nom du fourniseur"
								value={supplier}
								onChange={handleChange}
								SelectProps={{
									native: true,
								}}
								margin="dense"
								fullWidth
								helperText={validSupplier ? 'Veuillez choisir un nom de fournisseur ! ' : ''}
							>
								<option value=" "> </option>
								{supplierOption}
							</TextField>
						</Grid>
						<Grid item xs={9}>
							<TextField
								error={validDate}
								id="date"
								type="date"
								value={vdate}
								onChange={handleChangeDate}
								InputLabelProps={{
									shrink: true,
								}}
								label="Date de validité des prix"
								margin="dense"
								fullWidth
								helperText={validDate ? "Veuillez choisir une date supérieur à a date d'aujourd'hui" : ''}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Annuler
					</Button>
					<Button onClick={validate} color="primary">
						Valider
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Importfilepopup;
