import { useQuery } from '@apollo/client';
import { GetClientsQuery } from 'clients/graphql/queries.definitions';
import { GET_CLIENTS } from '../graphql/queries';


const useClients = () => {
	const { data } = useQuery<GetClientsQuery>(GET_CLIENTS, {
		variables: {
			filter: {
				isRoot: true,
			},
		},
	});
	return { data };
};

export default useClients;
