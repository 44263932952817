import { BigShopConfigCategory, BigShopConfigSubCategory } from '../../../../graphql/types';

export const categories = [
	{
		key: BigShopConfigCategory.SignagePos,
		value: 'PLV signalétique',
		subCategory: [
			{
				key: BigShopConfigSubCategory.Display,
				value: 'Affichage',
			},
			{
				key: BigShopConfigSubCategory.PointOfSaleAdvertising,
				value: 'Publicité sur lieu de vente',
			},
			{
				key: BigShopConfigSubCategory.SalonAndEvent,
				value: 'Salon & Evénement',
			},
		],
	},
	{
		key: BigShopConfigCategory.AdvertisingPrints,
		value: 'Imprimés publicitaires',
		subCategory: [
			{
				key: BigShopConfigSubCategory.AdvertisingPrints,
				value: 'Imprimés publicitaires',
			},
		],
	},
	{
		key: BigShopConfigCategory.AdvertisingItems,
		value: 'Objets publicitaires',
		subCategory: [
			{
				key: BigShopConfigSubCategory.Goodies,
				value: 'Goodies',
			},
			{
				key: BigShopConfigSubCategory.Restoration,
				value: 'Restauration',
			},
		],
	},
	{
		key: BigShopConfigCategory.StationeryShop,
		value: 'Papeterie',
		subCategory: [
			{
				key: BigShopConfigSubCategory.Office,
				value: 'Office',
			},
			{
				key: BigShopConfigSubCategory.Correspondance,
				value: 'Correspondance',
			},
		],
	},
	{
		key: BigShopConfigCategory.Digital,
		value: 'Digital',
		subCategory: [
			{
				key: BigShopConfigSubCategory.Digital,
				value: 'Digital',
			},
		],
	},

	{
		key: BigShopConfigCategory.PostersAndLeaflets,
		value: 'Affiches et flyers',
		subCategory: [
			{
				key: BigShopConfigSubCategory.PostersAndLeaflets,
				value: 'Affiches et flyers',
			},
		],
	},
	{
		key: BigShopConfigCategory.SignsAndSignage,
		value: 'Signalétique',
		subCategory: [
			{
				key: BigShopConfigSubCategory.SignsAndSignage,
				value: 'Signalétique',
			},
		],
	},
	{
		key: BigShopConfigCategory.OfferBrochures,
		value: 'Flyers offres',
		subCategory: [
			{
				key: BigShopConfigSubCategory.OfferBrochures,
				value: 'Flyers offres',
			},
		],
	},
	{
		key: BigShopConfigCategory.Billboards,
		value: "Panneaux d'affichage",
		subCategory: [
			{
				key: BigShopConfigSubCategory.Billboards,
				value: "Panneaux d'affichage",
			},
		],
	},
];
