import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { List, Box, Drawer, IconButton, Typography } from '@mui/material';
import colors from '../app/theme/colors.scss';
import { Menu } from './definitions';
import { MENUS } from './menus';
import { drawerWidth } from './Sidebar';
import AppMenuItem from './AppMenuItem';
import Restricted from 'context/permissionContext/Restricted';

const useStyles = makeStyles(() =>
	createStyles({
		drawerPaper: {
			width: drawerWidth,
			background: colors.black100,
			color: colors.white,
			borderStyle: 'none',
		},
	})
);

interface AppMenuProps {
	onClose: () => void;
}

const AppMenu = ({ onClose }: AppMenuProps): JSX.Element => {
	const classes = useStyles();
	const [selectedMenu, setSelectedMenu] = useState<Readonly<Menu> | null>(null);

	return (
		<Box>
			<List>
				{MENUS.map((item, index) => (
					//it's a restriction based on user permission is needed
					<Restricted key={index} to={item.permissionKey}>
						<AppMenuItem item={item} onChangeSelectedMenu={setSelectedMenu} onClose={onClose} />
					</Restricted>
				))}
			</List>
			<Box>
				<Drawer
					variant="persistent"
					anchor="left"
					open={selectedMenu?.component !== undefined}
					classes={{ paper: classes.drawerPaper }}
				>
					<Box p={1} position="sticky" top={0} zIndex={2} bgcolor="#39394c">
						<IconButton color="inherit" onClick={() => setSelectedMenu(null)} size="large">
							<Box pr={1} pt={0.75}>
								<ArrowBackIcon />
							</Box>
							<Typography variant="h6">{selectedMenu?.title}</Typography>
						</IconButton>
					</Box>
					{selectedMenu?.component && <selectedMenu.component onClose={onClose} classification={selectedMenu?.title} />}
				</Drawer>
			</Box>
		</Box>
	);
};

export default AppMenu;
