import { Typography } from '@mui/material';
import { useField } from 'formik';
import usePrevious from 'hooks/usePrevious';
import React, { useEffect } from 'react';
import { formatNumberDisplay } from 'utils/prices';

interface SellingMarginFieldProps {
	name: string;
	data: number;
	marginPrice: string;
}

const SellingMarginField = ({ name, data, marginPrice }: SellingMarginFieldProps) => {
	const [field, , helpers] = useField(name);
	const prevData = usePrevious(data);
	useEffect(() => {
		if (prevData !== field.value) helpers.setValue(data);
	}, [prevData, field.value, data, helpers]);

	return (
		<Typography>
			{name !== 'sellingPriceWithMargin.prixExemplaireSupp'
				? field.value
					? formatNumberDisplay(field.value as number, 2) + marginPrice
					: '0,00'
				: field.value
				? formatNumberDisplay(field.value as number, 5) + marginPrice
				: '0,00000'}
		</Typography>
	);
};

export default SellingMarginField;
