import { useMutation } from '@apollo/client';
import { GET_UPLOAD_URL, UPDATE_CLIENT } from '../graphql/mutations';
import {
	CreateClientLogoUploadUrlMutation,
	CreateClientLogoUploadUrlMutationVariables,
	UpdateClientMutation,
	UpdateClientMutationVariables,
} from '../graphql/mutations.definitions';
import { Client } from '../../graphql/types';
import { useCallback } from 'react';
import { uploadFile } from '../../utils/upload-file';
import { GET_CLIENTS_BY_CURRENT_USER, GET_CLIENT_BY_ID, GET_CLIENT_SHIPPING_ADDRESS } from '../graphql/queries';

const useClientUpdate = () => {
	const [updateClientMutation] = useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UPDATE_CLIENT);
	const [createUploadUrlMutation] = useMutation<
		CreateClientLogoUploadUrlMutation,
		CreateClientLogoUploadUrlMutationVariables
	>(GET_UPLOAD_URL);

	const createClientLogoUploadUrl = useCallback(
		async (id: string, mimetype: string) => {
			const { data } = await createUploadUrlMutation({ variables: { id, mimetype } });
			return { uploadUrl: data?.uploadUrl };
		},
		[createUploadUrlMutation]
	);

	const updateClient = async (client: Client, logoFile: File | null) => {
		const {
			__typename,
			clientShippingAddress,
			regionId,
			typologyId,
			createdAt,
			updatedAt,
			billingAddress,
			logo,
			clientsSupports,
			users,
			...rest
		} = client;

		if (logoFile) {
			const { uploadUrl } = await createClientLogoUploadUrl(client.id, logoFile.type);
			uploadUrl && (await uploadFile(logoFile, uploadUrl, logoFile.type));
		}

		const { data } = await updateClientMutation({
			variables: {
				client: {
					...rest,
					clientShippingAddress: clientShippingAddress.map((shippingAddress) => {
						const { __typename: s, contact, address, workingDays, ...restShippingAddress } = shippingAddress;
						let restContact = null;
						let restAdress = null;
						if (contact) {
							const { __typename, ...restData } = contact;
							restContact = { ...restData };
						}
						if (address) {
							const { __typename, ...restData } = address;
							restAdress = { ...restData };
						}
						return {
							...restShippingAddress,
							contact: restContact,
							address: restAdress,
							workingDays: workingDays
								? workingDays.map((workingDay) => ({
										day: workingDay.day,
										isWorkingDay: workingDay.isWorkingDay,
										start: workingDay.start,
										end: workingDay.end,
								  }))
								: [],
						};
					}),
					commercialId: users ? users?.commercial?.initials : null,
					responsibleId: users ? users?.responsable?.initials : null,
					parentClientId: client.parentClientId === '' ? null : client.parentClientId,
					companyBase: client.companyBase ?? '',
					isProspect: JSON.parse(String(rest.isProspect)),

					billingAddress: {
						id: billingAddress?.id,
						deliveryDepartment: billingAddress?.deliveryDepartment ?? null,
						country: billingAddress?.country ?? null,
						firstAddress: billingAddress?.firstAddress ?? null,
						postBox: billingAddress?.postBox ?? null,
						secondAddress: billingAddress?.secondAddress ?? null,
						thirdAddress: billingAddress?.thirdAddress ?? null,
						zipCode: billingAddress?.zipCode ?? null,
						name: billingAddress?.name ?? '',
						city: billingAddress?.city ?? '',
						isShipping: billingAddress?.isShipping ?? false,
					},
					clientContact: rest.clientContact?.map((contact) => ({
						civility: contact.civility,
						name: contact.name,
						functions: contact.functions,
						landlinePhone: contact.landlinePhone,
						gsmPhone: contact.gsmPhone,
						privatePhone: contact.privatePhone,
						email: contact.email,
						comment: contact.comment,
						inactif: contact.inactif,
						linkedIn: contact.linkedIn,
						updatedAt: new Date().toISOString(),
					})),
					structures: rest.structures?.map((structure) => ({
						type: structure.type,
						title: structure.title,
						level: structure.level,
						isBillable: structure.isBillable,
					})),
					regions: rest.regions?.map((region) => ({
						id: region.id,
						name: region.name,
					})),
					typologies: rest.typologies?.map((typology) => ({
						id: typology.id,
						name: typology.name,
					})),
					clientsSupports: clientsSupports.map((clientSupport) => ({
						margin: clientSupport.margin,
						marginType: clientSupport.marginType,
						supportId: clientSupport.supportId,
					})),
				},
			},
			refetchQueries: [
				{
					query: GET_CLIENT_BY_ID,
					variables: {
						id: client.id,
						filter: { OR: [{ isRoot: true, id: client.rootClientId }, { rootClientId: client.rootClientId }] },
					},
				},
				{
					query: GET_CLIENT_SHIPPING_ADDRESS,
					variables: {
						id: client.id,
					},
				},
				{ query: GET_CLIENTS_BY_CURRENT_USER, variables: { hasChildren: true } },
			],
		});

		return { client: data?.client };
	};

	return { updateClient, createClientLogoUploadUrl };
};
export default useClientUpdate;
