import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import colors from '../../app/theme/colors.scss';
import SupplierElpevInformation from './supplier-elpev-information/SupplierElpevInformation';
import SupplierInformation from './supplier-information/SupplierInformation';
import SupplierMainInformation from './supplier-main-information/SupplierMainInformation';
import SupplierTechnicalInormation from './supplier-technical-information/SupplierTechnicalInormation';
import Contact from '../../components/contact/Contact';
import { SupplierFormModel, HEADLINES, PATH, TABS } from '../definitions';
import { useManualTabs } from '../../hooks/useManualTabs';
import { CONTACT_DISPLAY_LIMIT } from '../../utils/definition';
import CommercialInformations from './ commercial-informations-form/commerialInformations';
import { Form } from 'formik';
import { CurrentUser } from '../../admin/user/definitions';
import useStorage from '../../hooks/useStorage';
import { RoleName } from '../../admin/role/definitions';

interface SupplierFormProps {
	supplier?: SupplierFormModel;
}

const SupplierForm = ({ supplier }: SupplierFormProps): JSX.Element => {
	const headlines = [
		{
			title: `${HEADLINES[0].title} ${supplier?.name}`,
			fontSize: HEADLINES[0].fontSize,
		},
	];

	const { renderingTabs } = useManualTabs({
		tabs: TABS,
		paths: [...PATH, supplier?.name],
		headlines,
		fixedHeaderHeight: 258,
		buttonLabel: 'Valider le fournisseur',
	});

	const { getDataLocalStorage } = useStorage();
	const currentUserRoleName = useMemo(() => {
		return (getDataLocalStorage('currentUser') as CurrentUser)?.role?.name;
	}, [getDataLocalStorage]);

	return (
		<Form>
			<Box position="fixed" width="100%" top={32} left={0} px={11.75} bgcolor={colors.bodyBackgroundLight} zIndex={100}>
				{renderingTabs()}
			</Box>

			<Box mt={30} mx={11.75}>
				<div ref={TABS[0].ref}>
					<SupplierMainInformation title={TABS[0].title} />
				</div>
				<div ref={TABS[1].ref}>
					<Divider />
					<SupplierElpevInformation title={TABS[1].title} />
				</div>
				<div ref={TABS[2].ref}>
					<Divider />
					<SupplierInformation
						title={TABS[2].title}
						isUserSupplier={!!supplier?.user?.email}
						isRoleAdmin={currentUserRoleName === RoleName.Admin}
					/>
				</div>
				<div ref={TABS[3].ref}>
					<Divider />

					<Contact
						title="Contact fournisseur"
						formikContactName="supplierContact"
						contactDisplayLimit={CONTACT_DISPLAY_LIMIT}
						onClickImportClientFromBaseDB={() => {}}
					/>
				</div>
				<div ref={TABS[4].ref}>
					<Divider />
					<SupplierTechnicalInormation title={TABS[4].title} />
				</div>
				<div ref={TABS[5].ref}>
					<Divider />
					<CommercialInformations
						title={TABS[5].title}
						supplier={supplier}
						isRoleAdmin={currentUserRoleName === RoleName.Admin}
					/>
				</div>
			</Box>
		</Form>
	);
};

export default SupplierForm;
