import { TransporterKey } from '../definitions';
import { GetTransportersQuery } from '../graphql/queries.definitions';
import { omit } from 'ramda';

const getTransportersToUpdate = (transporters: GetTransportersQuery['transporters']) =>
	transporters.map((transporter) => {
		const {
			saturdayDeliveryExtra,
			nonMechanisableParcel,
			nonStandardParcel,
			corsicaDeliveryExtra,
			maxMechanisableParcelLengthCm,
			maxMechanisableParcelWidthCm,
			minWeightKg,
			fuelSurchargeRate,
			standardDeliveryPrices,
			expressDeliveryPrices,
			nonRegularPickup,
			supplementOverThirtyKg,
			hardlyAccessibleArea,
			hardlyAccessibleAreas,
			...rest
		} = transporter;

		const transporterPrices = {
			saturdayDeliveryExtra,
			nonMechanisableParcel,
			nonStandardParcel,
			corsicaDeliveryExtra,
			maxMechanisableParcelLengthCm,
			maxMechanisableParcelWidthCm,
			minWeightKg,
			fuelSurchargeRate,
			nonRegularPickup,
			supplementOverThirtyKg,
			hardlyAccessibleArea,
		};
		const standardDeliveryPricesUpdated = [...standardDeliveryPrices];
		const expressDeliveryPricesUpdated = [...expressDeliveryPrices];

		Object.entries(transporterPrices).forEach(([key, value]) => {
			if (value.toString() === '') transporterPrices[key as TransporterKey] = 0;
		});

		//standardDeliveryPrices and expressDeliveryPrices have the same length
		standardDeliveryPrices.forEach((_, index) => {
			if (standardDeliveryPrices[index].toString() === '') standardDeliveryPricesUpdated[index] = 0;
			if (expressDeliveryPrices[index].toString() === '') expressDeliveryPricesUpdated[index] = 0;
		});

		return {
			...omit(['__typename'], rest),
			...transporterPrices,
			standardDeliveryPrices: standardDeliveryPricesUpdated,
			expressDeliveryPrices: expressDeliveryPricesUpdated,
			hardlyAccessibleAreas: hardlyAccessibleAreas.map(({ postalCode }) => ({
				postalCode,
			})),
		};
	});

export default getTransportersToUpdate;
