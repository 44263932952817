import * as React from 'react';
import { useTable, Column, useSortBy, useFilters, useAsyncDebounce, FilterProps } from 'react-table';

import { formatDateByDDMMYYYY } from '../../utils/normalizer';
import StatusTag from '../components/StatusTag/StatusTag';
import { TableType } from '../../components/tables/list-table/tableType';
import { FileReferenceFromModel } from '../definition';

import { Box, Typography } from '@mui/material';
import SearchInput from '../components/SearchInput/SearchInput';

const useFileReferencesTable = (fileReferences: FileReferenceFromModel[], loading: boolean) => {
	const data = React.useMemo(
		() =>
			fileReferences?.map((fileReference) => ({
				...fileReference,
				clientName: fileReference.client?.name,
				userName: `${fileReference.elpevResponsible?.givenName} ${fileReference.elpevResponsible?.familyName}`,
			})) ?? [],
		[fileReferences]
	);

	const defaultColumn = React.useMemo(
		() => ({
			Filter: ({ column: { setFilter, filterValue, id } }: FilterProps<FileReferenceFromModel>) => {
				const [, setValue] = React.useState(filterValue);

				const onChange = useAsyncDebounce((value) => {
					setFilter(value || undefined);
				}, 300);

				return (
					<Box mt="4px">
						<SearchInput
							id={id}
							handleChange={(e) => {
								setValue(e.target.value);
								onChange(e.target.value);
							}}
						/>
					</Box>
				);
			},
		}),
		[]
	);

	const columns = React.useMemo<Column<FileReferenceFromModel>[]>(
		() => [
			{
				accessor: 'id',
				sortType: 'basic',
				isSearchable: true,
				Header: 'N. Réf OS',
				canFilter: true,
			},
			{
				// @TODO => ADD searchinput
				accessor: 'createdAt',
				Header: 'Date de création',
				disableFilters: true,
				Cell: ({ value }) => <Typography>{formatDateByDDMMYYYY(value)}</Typography>,
			},
			{
				accessor: 'status',
				Header: 'État',
				disableFilters: true,
				Cell: ({ value }) => <StatusTag status={value} />,
			},
			{
				accessor: 'clientName',
				Header: 'Client',
				sortType: 'basic',
				disableSortBy: true,
			},
			{
				accessor: 'askBy',
				defaultCanFilter: false,
				isSearchable: true,
				Header: 'Demandé par',
			},
			{
				accessor: 'operationName',
				isSearchable: true,
				Header: "Nom de l'opération",
			},
			{
				accessor: 'clientReference',
				isSearchable: true,
				Header: 'Réf client',
			},
			{
				accessor: 'userName',
				isSearchable: true,
				Header: 'Responsable Elpev',
				disableSortBy: true,
			},
			{
				accessor: 'source',
				isSearchable: true,
				Header: 'Source',
				canFilter: true,
			},
		],
		[]
	);

	return useTable(
		{
			columns,
			data: data ?? [],
			borderLess: true,
			type: TableType.fileReference,
			height: 200,
			scroll: 'scroll',
			manualFilters: true,
			manualSortBy: true,
			defaultColumn,
			loading,
		},
		useFilters,
		useSortBy
	);
};

export default useFileReferencesTable;
