import { useMutation } from '@apollo/client';
import { Client, User } from '../../../graphql/types';
import { GET_ROLE_BY_ID } from '../../role/graphql/queries';

import { INSERT_USER } from '../graphql/mutations';
import { InsertUserMutation, InsertUserMutationVariables } from '../graphql/mutations.definitions';
import { GET_USERS } from '../graphql/queries';
import { omit } from 'ramda';

export const useUserInsert = () => {
	const [insertUserMutation] = useMutation<InsertUserMutation, InsertUserMutationVariables>(INSERT_USER);
	const insertUser = async ({
		id,
		role,
		userClients,
		...userInput
	}: User & { password: string; roleId: string; clientIds: string[]; userClients: Client[] }) => {
		const result = await insertUserMutation({
			variables: { data: omit(['supplier'], userInput) },
			refetchQueries: [
				{
					query: GET_USERS,
				},
				{
					query: GET_ROLE_BY_ID,
					variables: { roleId: userInput.roleId },
				},
			],

			awaitRefetchQueries: true,
		});
		if (!result.data) throw new Error();
		return result;
	};

	return insertUser;
};
