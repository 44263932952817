import ManualTabs from '../components/tabs/ManualTabs';
import { Box, Button, Divider, Typography } from '@mui/material';
import React, { ElementType, useState } from 'react';
import Path from '../components/Path';

export type Variant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'caption'
	| 'button'
	| 'overline';

export interface Tab {
	readonly ref: React.RefObject<HTMLDivElement>;
	title: string;
	Component?: ElementType;
}

export interface HeadLines {
	title: string;
	fontSize: Variant;
}

interface ManualTabsProps {
	tabs: Tab[];
	paths: (string | { name: string; href?: string } | undefined)[];
	headlines: HeadLines[];
	fixedHeaderHeight: number;
	buttonLabel?: string;
	ButtonAction?: ElementType;
}

export function useManualTabs({
	tabs,
	paths,
	headlines,
	fixedHeaderHeight = 230,
	buttonLabel,
	ButtonAction,
}: ManualTabsProps) {
	const [currentTab, setCurrentTab] = useState(tabs[0]);

	const handleTabChange = (tab: typeof tabs[0]) => {
		const offsetTop = tab!.ref!.current!.offsetTop;
		setCurrentTab(tab);

		if (offsetTop) window.scroll(0, offsetTop - fixedHeaderHeight);
	};

	const renderingTabs = () => (
		<>
			<Box>
				<Path paths={paths} />
				<Divider />
			</Box>

			<Box display="flex" justifyContent="space-between" mb={4} pt={5}>
				<Box display="flex" flexDirection="column">
					{headlines.map(({ title, fontSize }) => (
						<Box width="100%" key={title}>
							<Typography variant={fontSize}>{title}</Typography>
						</Box>
					))}
				</Box>
				{ButtonAction ? (
					<ButtonAction />
				) : (
					<Box>
						<Button color="primary" type="reset">
							Annuler
						</Button>
						<Button type="submit" variant="contained" color="primary">
							{buttonLabel || 'Enregistrer les modifications'}
						</Button>
					</Box>
				)}
			</Box>

			<ManualTabs currentTab={currentTab} tabs={tabs} onChange={handleTabChange} />
		</>
	);

	return {
		renderingTabs,
	};
}
