import sortBy from 'ramda/src/sortBy';
import prop from 'ramda/src/prop';
import uniq from 'ramda/src/uniq';
import sort from 'ramda/src/sort';

export const sortByKey = <T extends Record<string, any>>(array: T[], key: keyof T) =>
	sortBy(prop(key as string), array);

export const diff = (a: string | number | null | undefined, b: string | number | null | undefined) =>
	Number(a) - Number(b);

export const removeDuplicatesAndSort = (table: (string | number | null | undefined)[], isArrayOfNumbers?: boolean) =>
	isArrayOfNumbers
		? sort(diff, uniq(table))
		: uniq(table).sort((a, b) =>
				(a as string).localeCompare(b as string, undefined, {
					numeric: true,
					sensitivity: 'base',
				})
		  );
