import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { LayoutField } from '../../../components/form/LayoutField';
import { StatusSwitchField } from '../../../components/Switch/StatusSwitchField';
import LayoutFieldForm from '../../../components/form/LayoutFieldForm';
import SupplierElpevFromBaseDB from '../../components/SupplierElpevFromBaseDB';

interface SupplierMainInformationProps {
	title: string;
}

const SupplierMainInformation = ({ title }: SupplierMainInformationProps): JSX.Element | null => (
	<Box mb={5}>
		<Box mb={6}>
			<Grid container>
				<Grid item xs={1}></Grid>
				<SupplierElpevFromBaseDB title={title} />
			</Grid>
		</Box>
		<Grid container justifyContent="space-between">
			<Grid item xs={2}></Grid>
			<Grid item xs={3}>
				<LayoutFieldForm name="name" label="Nom du fournisseur" />
			</Grid>
			<Grid item xs={2}></Grid>
			<Grid item xs={3}>
				<Grid container direction="column" spacing={3}>
					<Grid item xs={12}>
						<LayoutField label="Etat du fournisseur">
							<StatusSwitchField name="isActif" />
						</LayoutField>
					</Grid>
					<Grid item xs={12}>
						<LayoutFieldForm name="companyBase" label="Numéro de société dans la base Elpev" />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={2}></Grid>
		</Grid>
	</Box>
);

export default memo(SupplierMainInformation);
