import React from 'react';
import { Box, FormControl, Grid, Radio } from '@mui/material';
import Typography from '@mui/material/Typography';
import { LayoutField } from 'components/form/LayoutField';
import FormikRadioField from 'components/form/FormikRadioField';
import { boxShippingModeOptions } from 'clients/form/delivery-information/definitions';
import { useField, useFormikContext } from 'formik';
import { Transport, TRANSPORTMOMENT } from 'clientPrice/form/definitions';
import { equals } from 'ramda';
import { TransportType } from 'graphql/types';
import { ClientQuoteModel } from '../definition';
import { useClientPriceWithCustomQuantity } from 'clientPrice/hooks/useClientPriceWithCustomQuantity';

const TransportInformation = ({ disabled = false, name }: { disabled?: boolean; name: string }): JSX.Element => {
	const [fieldTransportType] = useField(name);
	const { values } = useFormikContext<ClientQuoteModel>();
	const { getClientPrice, loadingGetClientPrice } = useClientPriceWithCustomQuantity();

	const handleChangeTransportAttributes = (value?: string, propsName?: string) => {
		if (!value || !propsName) {
			return;
		}

		const transportOptions = {
			deliveryParcel: fieldTransportType.value.deliveryParcel || undefined,
			transportMoment: fieldTransportType.value.transportMoment || undefined,
		};

		if (propsName === 'deliveryParcel') {
			transportOptions.deliveryParcel = value;
		}

		if (propsName === 'transportMoment') {
			transportOptions.transportMoment = value;
		}

		getClientPrice({
			variables: {
				filters: {
					clientId: values.clientId ?? '',
					productId: values.selectedProduct?.productId ?? '',
					quantity: values.quantity,
					numberVersion: values.versionNumber ?? 1,
					...transportOptions,
				},
			},
		});
	};

	return (
		<Grid container spacing={4}>
			<Grid item xs={4}>
				{fieldTransportType.value.transportType && (
					<Box flexGrow={1} display="flex">
						<Radio disabled color="primary" checked style={{ padding: 0, marginRight: '12px' }} />
						<Typography variant="h4">{Transport[fieldTransportType.value.transportType as TransportType]}</Typography>
					</Box>
				)}
			</Grid>

			<Grid item xs={4}>
				{equals(fieldTransportType.value.transportType, TransportType.DeliveryByParcel) && (
					<LayoutField label="Livraison transport par colis">
						<FormControl fullWidth>
							<FormikRadioField
								disabled={disabled || loadingGetClientPrice}
								name={`${name}.deliveryParcel`}
								options={boxShippingModeOptions}
								onChange={(_, value) => handleChangeTransportAttributes(value, 'deliveryParcel')}
							/>
						</FormControl>
					</LayoutField>
				)}
			</Grid>
			<Grid item xs={4}>
				{equals(fieldTransportType.value.transportType, TransportType.DeliveryByParcel) && (
					<LayoutField label="Moment">
						<FormControl fullWidth>
							<FormikRadioField
								disabled={disabled || loadingGetClientPrice}
								name={`${name}.transportMoment`}
								options={TRANSPORTMOMENT}
								onChange={(_, value) => handleChangeTransportAttributes(value, 'transportMoment')}
							/>
						</FormControl>
					</LayoutField>
				)}
			</Grid>
		</Grid>
	);
};

export default TransportInformation;
