import * as React from 'react';
import { useField } from 'formik';
import { Box, Typography } from '@mui/material';

import FormikAutocompleteField from 'components/form/FormikAutocompleteField';
import { LayoutField } from 'components/form/LayoutField';
import { isString } from 'utils/typeGuards';
import { ClientQuoteStatus, FileReference } from 'graphql/types';
import useClientQuoteByFileReferenceLazyQuery from 'client-quote/hooks/useFilteredClientQuotesLazyQuery';
import { useEffect } from 'react';
import usePrevious from '../../../../hooks/usePrevious';

type ReferenceOptionsType = { id: string; operationName: string };

interface SelectFileReferencesProps {
	fileReferences?: Pick<FileReference, 'id' | 'operationName'>[];
	loading: boolean;
}

const SelectFileReferences = ({ fileReferences = [], loading }: SelectFileReferencesProps) => {
	const [{ value: id }] = useField<string>('id');
	const [{ value: operationName }, , { setValue: setOperationName }] = useField<string>('operationName');
	const [{ value: clientId }] = useField<string>('clientId');
	const [{ value: fileReferenceId }, meta, { setValue: setFileReferenceId }] = useField<string>('fileReferenceId');
	const [, , { setValue: setSellingPriceHt }] = useField<number>('sellingPriceHt');
	const [{ value: clientQuoteId }, , { setValue: setClientQuoteId }] = useField<string>('clientQuoteId');
	const [clientQuote, setClientQuote] = React.useState<{ version: number; id: string } | null>(null);
	const { getFilteredClientQuotes, filteredClientQuotes } = useClientQuoteByFileReferenceLazyQuery({
		onCompleted: ({ filteredClientQuotes }) => {
			if (filteredClientQuotes.length === 1) {
				setClientQuoteId(filteredClientQuotes[0]?.id);
				setClientQuote(filteredClientQuotes?.[0]);
			}
		},
	});

	const fileReferencesOptions = React.useMemo(
		() => (clientId && fileReferences ? fileReferences?.map(({ id, operationName }) => ({ id, operationName })) : []),
		[fileReferences, clientId]
	);

	const quoteVersionsOptions = React.useMemo(
		() =>
			clientId && fileReferenceId && filteredClientQuotes
				? filteredClientQuotes.map(({ version, id }) => ({
						version,
						id,
				  }))
				: [],
		[filteredClientQuotes, clientId, fileReferenceId]
	);

	const handleSelectFileReference = async (value: { id: string; operationName: string } | null) => {
		setFileReferenceId(value?.id ?? '');
		setOperationName(value?.operationName ?? '');
		setClientQuoteId('');

		if (value?.id) {
			await getFilteredClientQuotes({
				variables: {
					filters: {
						fileReferenceId: value?.id,
						status: ClientQuoteStatus.Ready,
					},
				},
			});
		}
	};
	const prevClientQuoteId = usePrevious(clientQuoteId);

	useEffect(() => {
		if (clientQuoteId !== prevClientQuoteId) {
			setSellingPriceHt(filteredClientQuotes?.find(({ id }) => id === clientQuoteId)?.totalSalesPriceExcludingVAT ?? 0);
		}
	}, [prevClientQuoteId, fileReferenceId, filteredClientQuotes, clientQuoteId, setSellingPriceHt]);

	const prevFileReferenceId = usePrevious(fileReferenceId);

	useEffect(() => {
		if (!fileReferenceId && prevFileReferenceId !== fileReferenceId) {
			setClientQuote(null);
		}
	}, [prevFileReferenceId, fileReferenceId]);

	return (
		<>
			<LayoutField label="Référence Dossier">
				<Box mb={3}>
					<FormikAutocompleteField<ReferenceOptionsType>
						options={fileReferencesOptions}
						name="fileReferenceId"
						getOptionLabel={(option) => (fileReferencesOptions.length ? (option as ReferenceOptionsType).id : '')}
						autoHighlight
						disabled={fileReferencesOptions.length === 0}
						loading={loading}
						onChange={async (_, value) => {
							if (!isString(value)) {
								await handleSelectFileReference(value);
							}
						}}
						value={{ id: fileReferenceId ?? '', operationName: operationName ?? '' }}
						textField={{ error: !!meta.error }}
					/>
					{meta.error && fileReferencesOptions.length !== 0 && <Typography color="error">{meta.error}</Typography>}
				</Box>
			</LayoutField>

			<LayoutField label="Nom de l'opération">
				<Box mb={3}>
					<FormikAutocompleteField<{ id: string; operationName: string }>
						options={fileReferencesOptions}
						name="operationName"
						getOptionLabel={(option) =>
							fileReferencesOptions.length ? (option as { id: string; operationName: string }).operationName : ''
						}
						autoHighlight
						disabled={fileReferencesOptions.length === 0 || !!id}
						loading={loading}
						onChange={async (_, value) => {
							if (!isString(value)) {
								await handleSelectFileReference(value);
							}
						}}
						value={{ id: fileReferenceId ?? '', operationName: operationName ?? '' }}
					/>
				</Box>
			</LayoutField>

			<LayoutField label="Numéro du devis">
				<Box mb={3}>
					<FormikAutocompleteField<{ version: number; id: string }>
						options={quoteVersionsOptions ?? []}
						getOptionLabel={(option) =>
							quoteVersionsOptions.length
								? `${(option as { version: number; id: string }).id} (Version ${
										(option as { version: number; id: string }).version
								  })`
								: ''
						}
						onChange={(_, value) => {
							if (!isString(value)) {
								setClientQuoteId(value?.id ?? '');
								setClientQuote(value);
							}
						}}
						value={clientQuote}
						disabled={quoteVersionsOptions.length === 0}
						autoHighlight
					/>
				</Box>
			</LayoutField>
		</>
	);
};

export default SelectFileReferences;
