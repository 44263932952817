import React, { useMemo } from 'react';
import { Column, useRowSelect, useTable } from 'react-table';
import { Typography, Box } from '@mui/material';
import { DistributionsDetails } from 'clientPrice/form/definitions';
import { formatNumberDisplay } from 'utils/prices';

function useDistributionsDetailsTable(distributionsDetails: DistributionsDetails[]) {
	const columns = useMemo<Column<DistributionsDetails>[]>(
		() => [
			{
				accessor: 'zip',
				Header: () => (
					<Box width="57px">
						<Typography>Code postal</Typography>
					</Box>
				),
			},
			{
				accessor: 'quantity',
				Header: () => (
					<Box width="57px">
						<Typography>Quantité</Typography>
					</Box>
				),
			},
			{
				accessor: 'weight',
				Header: () => (
					<Box width="222px">
						<Typography>Poids (kg)</Typography>
					</Box>
				),
				Cell: ({ row: { values } }) => <Typography>{formatNumberDisplay(values.weight, 2)}</Typography>,
			},
			{
				accessor: 'deliveryParcelPurchasePrice',
				Header: () => (
					<Box width="146px">
						<Typography>Prix HT</Typography>
					</Box>
				),
				Cell: ({ row: { values } }) => (
					<Typography>{formatNumberDisplay(values.deliveryParcelPurchasePrice, 2)}</Typography>
				),
			},
			{
				accessor: 'label',
				Header: () => (
					<Box width="128px">
						<Typography>Nombre des cartons</Typography>
					</Box>
				),
			},
		],
		[]
	);

	return useTable(
		{
			columns,
			data: distributionsDetails,
			isBorderless: false,
			height: '340px',
			overflow: 'auto',
			tableCellHeight: '60px',
		},
		useRowSelect
	);
}

export default useDistributionsDetailsTable;
