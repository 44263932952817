import React from 'react';
import Box from '@mui/material/Box';
import AppListTable from '../../../components/tables/list-table/AppListTable';
import { ClientStructure } from '../../../graphql/types';
import useEntitiesTable from './hooks/useEntitiesTable';

interface EntityDetailProps {
	id: string;
	structures: ClientStructure[];
}

const EntityDetail = ({ structures, id }: EntityDetailProps): JSX.Element => {
	const entitiesTable = useEntitiesTable(structures, id);
	return (
		<Box pt={2} pr={2} pl={2}>
			<AppListTable tableInstance={entitiesTable} />;
		</Box>
	);
};

export default EntityDetail;
