import React from 'react';
import { useHistory } from 'react-router-dom';
import { Client, EntityType } from '../../graphql/types';
import { MenuComponentProps } from '../../Header/definitions';
import useClientsQuery from '../hooks/useClientsQuery';
import ClientListSelect from './ClientListSelect';
import { Box } from '@mui/material';
import ClientDialogForm from './ClientDialogForm';

interface ClientListMenuProp extends MenuComponentProps {}

const ClientListMenu = ({ onClose }: ClientListMenuProp): JSX.Element => {
	const history = useHistory();
	const { clients } = useClientsQuery({ type: { notIn: [EntityType.Region, EntityType.Typology] } });

	const handleClientSelected = ({ id }: Pick<Client, 'id'>) => {
		history.push(`/sheets/client/${id}`);
		onClose?.();
	};

	return (
		<>
			<ClientDialogForm />
			<Box mt={6.5}>{clients && <ClientListSelect clients={clients} onClientSelected={handleClientSelected} />}</Box>
		</>
	);
};

export default ClientListMenu;
