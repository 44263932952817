import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import OutlinedButton from '../../../../components/buttons/OutlinedButton';
import colors from '../../../../app/theme/colors.scss';
import { useQuotePreviewStyles } from '../styles';
import { QuoteAttributeContextProp } from '../../definition';

interface HeaderQuotePreviewProps {
	context?: QuoteAttributeContextProp;
	setIsShowQuotePreview: Dispatch<SetStateAction<boolean>>;
	handleClosePopin: () => void;
}

export const HeaderQuotePreview = ({
	handleClosePopin,
	context,
	setIsShowQuotePreview,
}: HeaderQuotePreviewProps): JSX.Element => {
	const classes = useQuotePreviewStyles();

	const handleGenerationClientQuote = () => {
		setIsShowQuotePreview(true);
		context?.generateClientQuote();
	};

	return (
		<Grid xs={12} className={classes.header} item>
			<Grid container alignItems="center">
				<Grid xs={6} item>
					<Box>
						<Typography variant="h4">
							Enregistrer et générer le dévis client - Renseigner les paramètres souhaités dans le devis
						</Typography>
					</Box>
				</Grid>
				<Grid xs={6} item>
					<Box>
						<Button color="primary" onClick={handleClosePopin}>
							Annuler
						</Button>
						<OutlinedButton
							title="Remettre à 0"
							handleClickBtn={() => context?.handleResetQuoteAttribute()}
							variant="outlined"
						/>
						<OutlinedButton
							title="Enregistrer les paramètres pour ce client"
							handleClickBtn={() => context?.saveTheSettingsForTheClient()}
							variant="outlined"
						/>
						<OutlinedButton
							title="Générer le devis client"
							bgColor={colors.primary}
							color={colors.white}
							handleClickBtn={handleGenerationClientQuote}
							variant="text"
						/>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	);
};
