import React from 'react';
import { initialOrder, NewOrderItem, OrderFormModel, OrderTypeToSubmit } from './form/order-cockpit/definition';
import { ClientPrice, OrderDistributionsUpsertInput, OrderStatus } from '../graphql/types';
import { getUpsertOrderFormSchema } from './form/order-creation/validation/schema';
import { Formik } from 'formik';
import { typeNotifications } from '../utils/definition';
import useOrderUpsert from './hooks/useOrderUpsert';
import { useSnackbar } from 'notistack';
import useOrderByIdQuery from './hooks/useOrderByIdQuery';
import OrderCreationForm from './form/OrderCreationForm';
import Loading from '../components/loading/Loading';
import useCurrentUserQuery from '../admin/user/hooks/useCurrentUserQuery';

const OrdersCreationDetail = ({ orderId }: { orderId: string }) => {
	const { user } = useCurrentUserQuery();
	const upsertOrder = useOrderUpsert();
	const { enqueueSnackbar } = useSnackbar();
	const { order, loading } = useOrderByIdQuery(orderId);

	const orderValues = React.useMemo(
		() => ({
			...initialOrder,
			fileReferenceId: order?.fileReference?.id,
			operationName: order?.fileReference?.operationName,
			clientQuoteId: order?.clientQuote?.id,
			isDistribution: order?.isDistribution ?? false,
			elepvContactName: `${user?.givenName ?? ''} ${user?.familyName ?? ''}`,
			elpevContactEmail: user?.email,
			elpevContactPhone: order?.elpevContactPhone ?? user?.phoneNumber,
			...order,
		}),
		[order, user]
	);

	const onSubmit = async (
		orderInput: OrderFormModel & {
			newOrderItem: NewOrderItem;
			clientPricesExisting: ClientPrice[];
			orderItemIdsToDelete: string[];
		},
		isCreatePurchaseOrders?: boolean
	) => {
		const { invoices, orderItemIdsToDelete, newOrderItem, orderItem, id, orderDistributions, ...orderRes } = orderInput;

		try {
			await upsertOrder({
				...orderRes,
				isCreatePurchaseOrders,
				status: isCreatePurchaseOrders ? OrderStatus.InProgress : OrderStatus.WorkInProgress,
				orderDistributions: orderDistributions as OrderDistributionsUpsertInput[],
				isDistribution: orderRes.isDistribution ?? false,
				orderItem,
				id,
				orderItemIdsToDelete,
			});
			enqueueSnackbar('Modifications enregistrées', { variant: typeNotifications.Success });
		} catch (error) {
			enqueueSnackbar('Erreur', { variant: typeNotifications.Error });
		}
	};

	if (loading) return <Loading loading={loading} />;

	return (
		<Formik<OrderTypeToSubmit>
			validateOnChange={false}
			initialValues={orderValues}
			validationSchema={getUpsertOrderFormSchema}
			enableReinitialize
			onSubmit={async (orderSupplier, { resetForm }) => {
				await onSubmit(orderSupplier);
				// resetForm(orderSupplier);
			}}
		>
			<OrderCreationForm onSubmit={onSubmit} orderValues={orderValues} />
		</Formik>
	);
};

export default OrdersCreationDetail;
