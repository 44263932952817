import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import colors from '../../app/theme/colors.scss';
import { useField } from 'formik';
import { FormikCheckboxField } from '../form/FormikChecboxField';
import { FormikNumberField } from '../form/FormikNumberField';
import FormikTextField from '../form/FormikTextField';

interface ItemCellPriceProps {
	width: string;
	isBorder: boolean;
	name: string;
	label?: string;
	hasRate: boolean;
	isChecked?: boolean;
	value?: number | string;
	isNotDisabled?: boolean;
}

type UseStylesProps = Omit<ItemCellPriceProps, 'name' | 'label' | 'isChecked'>;

const useStyles = makeStyles(() =>
	createStyles({
		wrapper: {
			margin: 2,
			width: ({ width }: UseStylesProps) => width,
			display: 'flex',
			color: colors.gray100,
			flexDirection: ({ hasRate }: UseStylesProps) => (hasRate ? 'row' : 'column'),
			justifyContent: ({ hasRate }: UseStylesProps) => (hasRate ? 'flex-end' : 'flex-start'),
		},
		header: {
			paddingLeft: 0.2,
			marginBottom: 1,
			width: '100%',
			textAlign: ({ hasRate }: UseStylesProps) => (hasRate ? 'right' : 'left'),
			paddingTop: ({ hasRate }: UseStylesProps) => (hasRate ? 10 : 0),
			fontSize: 12,
			paddingRight: 10,
		},
		showClientPrice: {
			display: 'block',
			fontSize: '14px',
			margin: '0px',
			padding: '5px',
			backgroundColor: colors.white,
			borderTopLeftRadius: ({ isBorder }: ItemCellPriceProps) => (isBorder ? '4px' : 'none'),
			borderBottomLeftRadius: ({ isBorder }: ItemCellPriceProps) => (isBorder ? '4px' : 'none'),
			borderLeft: ({ isBorder }: ItemCellPriceProps) => (isBorder ? `6px solid ${colors.turquoise500}` : 'none'),
		},
		blockCheckbox: {
			width: ({ width }: UseStylesProps) => width,
			paddingTop: 1,
			display: 'flex',
			alignItems: 'center',
			'& .MuiBox-root MuiBox-root-215': {
				fontSize: '14px',
			},
		},
	})
);

export const ItemCellPrice = ({
	width = '100%',
	isBorder,
	name,
	hasRate,
	label,
	isChecked,
	value,
	isNotDisabled,
}: ItemCellPriceProps) => {
	const classes = useStyles({ isBorder, width, hasRate, name });

	const [field] = useField<string | number | null | undefined>(name);

	if (isChecked)
		return (
			<Box className={classes.blockCheckbox}>
				<Box>
					<FormikCheckboxField disabled={name === 'isIncluded' ? false : isNotDisabled} name={name} />
				</Box>
				<Box>{label}</Box>
			</Box>
		);

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.header}>{label}</Box>
			<Box className={classes.showClientPrice}>
				{(field.value && isNaN(field.value as number)) || field.value === '' ? (
					<FormikTextField value={value} disabled={!isNotDisabled} name={name} bgColor="WHITE" fullWidth />
				) : (
					<FormikNumberField
						value={Number(value)}
						disabled={!isNotDisabled}
						name={name}
						decimalScale={2}
						bgColor="white"
						width="100%"
						color={Number(value) < 0 ? colors.error : ''}
					/>
				)}
			</Box>
		</Box>
	);
};
