export interface Menu {
	key: string;
	name: string;
	parentId: string;
	childs?: Menu[] | null;
}

interface ArrayMenu {
	key: string;
	name: string;
	parentId: string;
	childs?: Menu[] | null;
}

export const permissionsMenu: Menu[] = [
	{
		key: 'view:menu',
		name: 'OneSupply (tous les menus)',
		parentId: 'view:menu',

		childs: [
			{
				key: 'view:product',
				name: 'Produit',
				parentId: 'view:menu',
			},
			{
				key: 'view:four_fantastic',
				name: 'Les 4 Fantastiques',
				parentId: 'view:menu',
			},
			{
				key: 'view:supplier_quotes',
				name: 'Devis Fournisseur',
				parentId: 'view:menu',
				childs: [
					{
						key: 'view:supplier_quotes_to_integrate',
						name: 'Devis à intégrer',
						parentId: 'view:supplier_quotes',
					},
					{
						key: 'view:supplier_quotes_list_supplier',
						name: 'Liste des devis fournisseur',
						parentId: 'view:supplier_quotes',
					},
					{
						key: 'view:supplier_quotes_declined',
						name: 'Déclinés',
						parentId: 'view:supplier_quotes',
					},
				],
			},

			{
				key: 'view:client_cards_clients_management',
				name: 'Gestion des fiches clients',
				parentId: 'view:menu',
			},

			{
				key: 'view:client_cards_suppliers_management',
				name: 'Gestion des fiches Fournisseurs',
				parentId: 'view:menu',
			},

			{
				key: 'view:sales_price_management',
				name: 'Gestion des prix de vente',
				parentId: 'view:menu',
				childs: [
					{
						key: 'view:sale_price_creation',
						name: 'Création de prix de vente',
						parentId: 'view:sales_price_management',
					},
					{
						key: 'view:sale_price_retrocession',
						name: 'Retrocession',
						parentId: 'view:sales_price_management',
					},
					{
						key: 'view:sales_price_list',
						name: 'Liste des prix de vente',
						parentId: 'view:sales_price_management',
					},
				],
			},

			{
				key: 'view:file_reference',
				name: 'Réference dossiers',
				parentId: 'view:menu',
			},

			{
				key: 'view:client_quotes',
				name: 'Devis Client',
				parentId: 'view:menu',
				childs: [
					{
						key: 'view:quote_client_creation',
						name: 'Création de devis client',
						parentId: 'view:client_quotes',
					},
					{ key: 'view:quotes_list', name: 'Recherche de devis', parentId: 'view:client_quotes' },
				],
			},

			{
				key: 'view:orders_management',
				name: 'Commandes clients',
				parentId: 'view:menu',
			},

			{
				key: 'view:orders_creation',
				name: 'Commandes Fournisseurs',
				parentId: 'view:menu',
			},

			{
				key: 'view:invoice',
				name: 'Factures clients',
				parentId: 'view:menu',
				childs: [
					{ key: 'view:invoice_creation', name: 'A valider', parentId: 'view:invoice' },
					{ key: 'view:invoice_accounting', name: 'A comptabiliser', parentId: 'view:invoice' },
					{ key: 'view:invoice_paid', name: 'Comptabilisé', parentId: 'view:invoice' },
				],
			},

			{
				key: 'view:admin',
				name: 'Administration',
				parentId: 'view:menu',
				childs: [
					{ key: 'view:admin_wording4F', name: 'Wording 4F', parentId: 'view:admin' },
					{ key: 'view:admin_supports', name: 'Caractéristiques par support', parentId: 'view:admin' },

					{
						key: 'view:admin_transport_rules',
						name: 'Régles de transports',
						parentId: 'view:admin',
						childs: [
							{
								key: 'view:admin_transport_rules_palette',
								name: 'Transport palette',
								parentId: 'view:admin_transport_rules',
							},
							{
								key: 'view:admin_transport_rules_colis',
								name: 'Transport Colis',
								parentId: 'view:admin_transport_rules',
							},
						],
					},
					{ key: 'view:users_management', name: 'Gestion des utilisateurs', parentId: 'view:admin' },
					{ key: 'view:print_value', name: 'Valeur Print', parentId: 'view:admin' },
          { key: 'view:import', name: 'Import', parentId: 'view:admin' },
				],
			},
		],
	},
];

let allMenuKeys: ArrayMenu[] = [];

const checkChildren = (menus: Menu[]) => {
	menus.forEach((menu) => {
		allMenuKeys.push({ key: menu.key, name: menu.name, parentId: menu.parentId, childs: menu.childs });
		if (menu.childs) {
			checkChildren(menu.childs);
		}
	});

	return allMenuKeys;
};

export const permissionsIdsList = checkChildren(permissionsMenu);
