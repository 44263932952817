import gql from 'graphql-tag';

const GET_BIG_SHOP_CONFIG_BY_ID = gql`
	query GetBigShopConfigById($supportId: String!) {
		bigShopConfig(supportId: $supportId) {
			supportId
			category
			subCategory
			categoryIcon
			callQuantity
			predefinedQuantity1
			predefinedQuantity2
			predefinedQuantity3
			predefinedQuantity4
			predefinedQuantity5
		}
	}
`;

export { GET_BIG_SHOP_CONFIG_BY_ID };
