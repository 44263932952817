import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import ProductItem from './ProductItem';
import { useTableStyles } from '../sharedStyles';
import OrderTableHead from '../components/OrderTableHead';
import useSearch from 'hooks/useSearch';
import { ChildOrderFormModel, DETAILS_BY_PRODUCT_TABLE_HEAD_COLUMNS, OrderItemFormModel } from '../definition';

interface DetailsByProductProps {
	orderItem?: OrderItemFormModel[] | null;
	childrenOrder?: ChildOrderFormModel[] | null;
}

const DetailsByProduct = ({ orderItem, childrenOrder }: DetailsByProductProps): JSX.Element => {
	const { renderSearchComponent, filtredItems } = useSearch('productName', orderItem as OrderItemFormModel[]);
	const classes = useTableStyles();

	return (
		<Box mt={4}>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h2">Détail par produit</Typography>
				{renderSearchComponent('Chercher un produit')}
			</Box>
			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table className={classes.table}>
					<OrderTableHead columns={DETAILS_BY_PRODUCT_TABLE_HEAD_COLUMNS} />
					<TableBody>
						{filtredItems &&
							filtredItems.map((product: OrderItemFormModel, index: number) => (
								<ProductItem
									key={product.onePacDocumentId}
									product={product}
									childrenOrder={childrenOrder}
									productIndex={index}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box mt={6}>
				<Divider />
			</Box>
		</Box>
	);
};

export default memo(DetailsByProduct);
