import {ProductFromModel} from "../../product/definitions";
import {ClariPrintCorrespondence} from "../../graphql/types";
import addPercentageToValue from "../../utils/addPercentageToValue";
import {UNIT_WEIGHT_PERCENT_VALUE} from "../definitions";

export const calculateProductUnitWeightGr = (
	selectedProduct: Pick<
		ProductFromModel,
		|'productSupplierPrice'
		| 'largeurFormatFiniCm'
		| 'longueurFormatFiniCm'
		| 'largeurFormatOuvertCm'
		| 'longueurFormatOuvertCm'
		| 'paginationTotale'
		| 'grammageGr'
		>,
	selectedProductUnitWeight?: number | null,
	supportMetaData?: ClariPrintCorrespondence
) => {
	if (selectedProductUnitWeight) return selectedProductUnitWeight;

	const largeurFormatFiniCm = Number(selectedProduct.largeurFormatFiniCm) / 100;
	const longueurFormatFiniCm = Number(selectedProduct.longueurFormatFiniCm) / 100;
	const largeurFormatOuvertCm = Number(selectedProduct.largeurFormatOuvertCm) / 100;
	const longueurFormatOuvertCm = Number(selectedProduct.longueurFormatOuvertCm) / 100;
	const unitWeight =
		(supportMetaData === ClariPrintCorrespondence.Depliants
			? largeurFormatOuvertCm * longueurFormatOuvertCm
			: largeurFormatFiniCm * longueurFormatFiniCm) * Number(selectedProduct.grammageGr);
	const productUnitWeight = selectedProduct.paginationTotale
		? (unitWeight * Number(selectedProduct.paginationTotale)) / 2
		: unitWeight;

	return addPercentageToValue(UNIT_WEIGHT_PERCENT_VALUE, productUnitWeight);
};
