import React, { useMemo } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Path from 'components/Path';
import AppListTable from 'components/tables/list-table/AppListTable';
import { InvoiceListType } from 'invoice/definitions';

import { InvoiceStatus } from 'graphql/types';
import { useInvoicesToBeAcountedTable } from './hooks/useInvoicesToBeAcountedTable';

import { printPDFS } from './helpers';
import { useUpdateOneOrManyInvoices } from 'invoice/hooks/useUpdateOneOrMany';
import useInvoicesQuery from 'invoice/hooks/useInvoicesQuery';

const InvoicesListToBeAccounted = () => {
	const { invoices } = useInvoicesQuery({}, {}, { status: InvoiceStatus.ToCount });

	const { updateOneOrMany } = useUpdateOneOrManyInvoices();

	const toBeAccountedInvoices: InvoiceListType[] | undefined = useMemo(
		() =>
			invoices?.map((invoice) => ({
				invoiceId: invoice.id,
				orderId: invoice.order?.parentOrderId ?? '',
				createdAt: invoice?.createdAt,
				clientName: invoice.order?.clientName ?? '',
				sellingPriceHt: invoice.order?.sellingPriceHt ?? 0,
				sellingPriceTtc: invoice.order?.sellingPriceTtc ?? 0,
				orderTva: invoice.order?.tva,
				validator: invoice?.validator,
				icons: invoice.id,
				url: invoice.url,
				id: '',
			})),
		[invoices]
	);

	const instanceToBeAccountedInvoice = useInvoicesToBeAcountedTable(toBeAccountedInvoices);
	const { selectedFlatRows } = instanceToBeAccountedInvoice;

	const pdfUrls = selectedFlatRows.map((d) => d.original.url ?? '');

	const handleCountInvoices = async () => {
		const invoiceSelected = selectedFlatRows.map((d) => d.original.invoiceId);
		await updateOneOrMany({ ids: invoiceSelected });
	};

	const paths = ['Factures clients', 'A comptabiliser'];

	return (
		<>
			<Box p={4} ml={10}>
				<Box mb={4}>
					<Path paths={paths} />
					<Divider />
				</Box>
				<Typography variant="h2">Listes des factures clients à comptabiliser</Typography>
				<Box mt={3} mr={10}>
					<Box>
						<AppListTable tableInstance={instanceToBeAccountedInvoice} />
					</Box>

					<Box mt={4} textAlign="right">
						<Button
							variant="outlined"
							onClick={() => {
								printPDFS(pdfUrls);
							}}
						>
							Imprimer les facture selectionnées
						</Button>

						<Button variant="outlined" color="primary" onClick={handleCountInvoices}>
							Comptabiliser les factures sélectionnées
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default InvoicesListToBeAccounted;
