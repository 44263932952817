import { useQuery } from '@apollo/client';
import { GetBigShopConfigByIdQuery, GetBigShopConfigByIdQueryVariables } from '../graphql/queries.definitions';
import { GET_BIG_SHOP_CONFIG_BY_ID } from '../graphql/queries';

const useBigShopConfigByIdQuery = (id: string) => {
	const { data, loading } = useQuery<GetBigShopConfigByIdQuery, GetBigShopConfigByIdQueryVariables>(
		GET_BIG_SHOP_CONFIG_BY_ID,
		{
			variables: {
				supportId: id,
			},
		}
	);

	return { bigShopConfig: data?.bigShopConfig, loading };
};

export default useBigShopConfigByIdQuery;
