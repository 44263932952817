import React, { memo } from 'react';
import { Box, Grid } from '@mui/material';
import DashedHeader from 'components/DashedHeader';
import FormikTextField from 'components/form/FormikTextField';
import LayoutFieldForm from 'components/form/LayoutFieldForm';

interface SupplierContactProps {
	isRoleAdmin: boolean;
}

const SupplierContact = ({ isRoleAdmin }: SupplierContactProps): JSX.Element => (
	<>
		<Grid container justifyContent="space-between">
			<Grid item xs={2} />
			<Grid item xs={8}>
				<DashedHeader>Contact</DashedHeader>
			</Grid>
			<Grid item xs={2} />
		</Grid>
		<Grid container justifyContent="space-between">
			<Grid item xs={2} />

			<Grid item xs={3}>
				<Box width="122px">
					<LayoutFieldForm label="Téléphone " name="phone" disabled={!isRoleAdmin} />
				</Box>
				<LayoutFieldForm label="Email " name="email" disabled={!isRoleAdmin} />
			</Grid>
			<Grid item xs={2} />
			<Grid item xs={3}>
				<LayoutFieldForm width="122px" label="Fax " name="fax" disabled={!isRoleAdmin} />
				<LayoutFieldForm label="Site web " name="website" disabled={!isRoleAdmin} />
			</Grid>
			<Grid item xs={2} />
		</Grid>
		<Grid container>
			<Grid item xs={2} />
			<Grid item xs={8}>
				<Box mt={7}>
					<DashedHeader>Commentaires</DashedHeader>
					<FormikTextField
						fullWidth
						multiline={true}
						rows={7}
						disabled={!isRoleAdmin}
						name="comment"
						variant="outlined"
					/>
				</Box>
			</Grid>
			<Grid item xs={2} />
		</Grid>
	</>
);

export default memo(SupplierContact);
