import { ProductAttributeDetails } from 'context/pricesProductContext/definition';

export type ConditionnmentList = {
	numberOfExLots: number | null;
	firstConditionnement: {
		labeling: boolean | null;
		conditionnement: ProductAttributeDetails | null;
		nbExConditionnement: number | null;
	};
	secondConditionnement: {
		labeling: boolean | null;
		conditionnement: ProductAttributeDetails | null;
		nbExConditionnement: number | null;
	};
	threeConditionnement: {
		labeling: boolean | null;
		conditionnement: ProductAttributeDetails | null;
		nbExConditionnement: number | null;
	};
};

export type ConditionnementType = {
	numberOfLots: number;
	total?: number | null;
	conditionnementList: ConditionnmentList[];
};

export const initialconditionnementList = {
	numberOfExLots: null,
	firstConditionnement: {
		labeling: null,
		conditionnement: null,
		nbExConditionnement: null,
	},
	threeConditionnement: {
		labeling: null,
		conditionnement: null,
		nbExConditionnement: null,
	},
	secondConditionnement: {
		labeling: null,
		conditionnement: null,
		nbExConditionnement: null,
	},
};

export const initialConditionnemt = {
	numberOfLots: 1,
	total: null,
	conditionnementList: [initialconditionnementList],
};

export type ConditionnementFamilyType = {
	key: 'firstConditionnement' | 'secondConditionnement' | 'threeConditionnement';
	value: string;
};

export const conditionnementFamily: ConditionnementFamilyType[] = [
	{ key: 'firstConditionnement', value: 'Conditionnement 1' },
	{ key: 'secondConditionnement', value: 'Conditionnement 2' },
	{ key: 'threeConditionnement', value: 'Conditionnement 3' },
];

export const LABELIING = 'labeling';
export const CONDITIONENEMENT = 'conditionnement';
export const NB_EX_CONDITIONENEMENT = 'nbExConditionnement';
