import { useQuery } from '@apollo/client';

import { GET_USERS_BY_CLIENT_IDS } from '../graphql/queries';
import { GetUsersByClientIdsQuery, GetUsersByClientIdsQueryVariables } from '../graphql/queries.definitions';

const useUsersByClientIdsQuery = (clientId: string, roleNames?: string[]) => {
	const { data, loading } = useQuery<GetUsersByClientIdsQuery, GetUsersByClientIdsQueryVariables>(GET_USERS_BY_CLIENT_IDS, {
		variables: { clientId, roleNames },
	});

	return { users: data?.usersByClientIds, loading };
};

export default useUsersByClientIdsQuery;
