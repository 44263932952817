import { useField } from 'formik';
import React from 'react';
import { StatusSwitch, StatusSwitchProps } from './StatusSwitch';

export type StatusSwitchFieldProps = {
	name: string;
	handleStatusSwitch?: (status: boolean) => void;
	disabled?: boolean;
	testid?:string;
} & Omit<StatusSwitchProps, 'status' | 'onStatusChange'>;

export const StatusSwitchField = ({ name, ...rest }: StatusSwitchFieldProps): JSX.Element => {
	const [, meta, helpers] = useField<boolean>(name);

	const statusChange = (status: boolean) => {
		rest.handleStatusSwitch ? rest.handleStatusSwitch(status) : helpers.setValue(status);
	};
	return <StatusSwitch testid={rest.testid} status={meta.value} onStatusChange={(status) => statusChange(status)} {...rest} />;
};
