import React from 'react';
import { Box, Typography } from '@mui/material';

interface QuoteHeaderDataDisplayProp {
	value?: string | null;
	label: string | null;
}

export const QuoteHeaderDataDisplay = ({ value, label }: QuoteHeaderDataDisplayProp): JSX.Element => (
	<Box display="flex" flexDirection="row">
		<Box textAlign="right" width="40%">
			<Typography>{label ?? ''} :</Typography>
		</Box>
		<Box pl={1}>
			<Typography>{value ?? ''}</Typography>
		</Box>
	</Box>
);
