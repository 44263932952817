import { HeadLines, Tab } from '../hooks/useManualTabs';
import React, { ReactNode } from 'react';
import { OrderItem, OrderStatus } from '../graphql/types';
import { initialOrderItem } from './form/order-cockpit/definition';
import GeneralInformation from './form/order-creation/GeneralInformations';
import ChoiceOfProductionAndTransport from './form/order-creation/production-and-transport/ChoiceOfProductionAndTransport';
import DeliveryInformation from './form/order-creation/DeliveryInformation';
import { CalculationProductionAndTransportPrice } from './form/order-creation/CalculationProductionAndTransportPrices';
import OrderItems from './form/order-creation/order-items/OrderItems';
import ClientInformations from './form/order-creation/ClientInformations';
import ProductInformation from './form/order-creation/ProductInformation';
import { GetTransporterPurchasePriceQuery } from './graphql/queries.definitions';
import { CSSProperties } from '@mui/styles';
import { VariantType } from '../utils/definition';

export const TAB_MENU_SUPPLIER_ORDER: Tab[] = [
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations générales',
		Component: GeneralInformation,
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations client',
		Component: ClientInformations,
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Informations produit',
		Component: ProductInformation,
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Récapitulatif produit',
		Component: OrderItems,
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Livraison',
		Component: DeliveryInformation,
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Choix production et transport',
		Component: ChoiceOfProductionAndTransport,
	},
	{
		ref: React.createRef<HTMLDivElement>(),
		title: 'Calcul des prix de production et transport',
		Component: CalculationProductionAndTransportPrice,
	},
];

export const HEADLINES: HeadLines[] = [
	{
		title: '',
		fontSize: 'h2',
	},
];

export const initialOrder = {
	buyingPriceHt: 0,
	buyingPriceHtWithRfa: 0,
	sellingPriceHt: 0,
	clientId: '',
	status: OrderStatus.InProgress,
	billingAddress: { name: '' },
	invoiceFile: null,
	invoices: [],
	orderItem: [],
	newOrderItem: initialOrderItem,
	clientPricesExisting: [],
	clientName: '',
	marginWithClientRfa: 0,
	realMargin: 0,
	orderItemIdsToDelete: [],
	id: '',
	isDistribution: false,
};

export type OrderItemType = OrderItem & { supportId: string };

export type ItemCellSupplierPriceProps = Pick<
	CellOrderItemSuppliersPricesType,
	'index' | 'widthRoot' | 'isMarginRoot' | 'borderColor' | 'padding'
>;

export type CellOrderItemSuppliersPricesType = {
	label?: string;
	value?: string | number;
	index?: number;
	children?: ReactNode;
	widthRoot: CSSProperties['width'];
	isMarginRoot?: boolean;
	borderColor?: string;
	variant?: VariantType;
	padding?: string;
	handleEdit?: () => void;
	name?: string;
};

export type ComponentTitle = {
	title: string;
	setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TransporterPrices = GetTransporterPurchasePriceQuery['transportersPrices'][0];

export type FilesFromOrderItem = {
	file: File[];
	orderItemId: string | undefined;
};

export type PriceSummaryByProductType = {
	summaryDetails: Record<string, number | string>[];
	totalPurchasePriceExcludingTaxWithRFA: number;
};

export enum OrderCockpitFollowStatus {
	ProductionInProgress = 'ProductionInProgress',
	DeliveryInProgress = 'DeliveryInProgress',
	Delivered = 'Delivered',
	Billed = 'Billed',
	Validated = 'Validated',
	Refused = 'Refused',
	WaitingValidation = 'WaitingValidation',
}

export enum FollowStatusListMap {
	ProductionInProgress = 'En cours de production',
	DeliveryInProgress = 'En cours de livraison',
	Delivered = 'Livrée',
	Billed = 'Facturée',
	WaitingValidation = 'En attente de validation',
	Validated = 'Validée',
	Refused = 'Refusée',
}
