import React, { memo, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import ClientInformation from './client-information/ClientInformation';
import { HeaderQuotePreview } from './component/HeaderQuotePreview';
import { CharacteristicsQuote } from './component/CharacteristicsQuote';
import { useQuotePreviewStyles } from './styles';
import { QuoteAttributeContextProp, QuotePreviewProps } from '../definition';
import { QuoteAttributeContext } from '../../context/QuoteAttributesProvider';
import { handleQuoteTitleConfig } from '../helpers';
import { useField } from 'formik';
import { VisualizationQuote } from './visualization-quote/VisualizationQuote';
import useStorage from '../../../hooks/useStorage';

export const QuotePreview = memo(
	({ handleCloseQuotePreview }: QuotePreviewProps): JSX.Element => {
		const classes = useQuotePreviewStyles();
		const [clientId] = useField<string>('clientId');
		const [isShowQuotePreview, setIsShowQuotePreview] = useState<boolean>(false);
		const context = useContext<QuoteAttributeContextProp | undefined>(QuoteAttributeContext);
		const { removeDataLocalStorage } = useStorage();

		const handleClosePopin = async () => {
			handleCloseQuotePreview(false);
			await removeDataLocalStorage('data');
		};

		return (
			<>
				{!isShowQuotePreview ? (
					<Grid container>
						<HeaderQuotePreview
							handleClosePopin={handleClosePopin}
							setIsShowQuotePreview={setIsShowQuotePreview}
							context={context}
						/>
						<ClientInformation clientsUsers={context?.clientsUsers} clientId={clientId.value} context={context} />
						{context?.quoteConfig.map(({ familiesGroup, familyAttributes }, index) => (
							<Grid xs={12} className={classes.body} key={`${familiesGroup}_${index}`} item>
								<Grid container spacing={3}>
									<Grid xs={12} item>
										<Typography variant="h2">{handleQuoteTitleConfig(familiesGroup)}</Typography>
									</Grid>
									<Grid xs={12} item>
										<CharacteristicsQuote
											familiesGroup={familiesGroup}
											index={index}
											length={context?.quoteConfig.length - 1}
											familyAttributes={familyAttributes}
										/>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
				) : (
					<Grid container>
						<VisualizationQuote
							context={context}
							setIsShowQuotePreview={setIsShowQuotePreview}
							handleClosePopin={handleClosePopin}
						/>
					</Grid>
				)}
			</>
		);
	}
);
