import React, { memo, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../../app/theme/colors.scss';
import { VariantType, AlignTableCell } from '../../../../utils/definition';

const useStyles = makeStyles({
	firstTableCell: {
		borderLeft: '4px solid',
		borderLeftColor: colors.indigo700,
	},
});

interface TableCellOrderProps {
	children?: ReactNode;
	width?: string | number;
	label?: string | number | null;
	onClick?: ((event?: React.MouseEvent) => void) | null;
	className?: string;
	isFirst?: boolean;
	variant?: VariantType;
	align?: AlignTableCell;
}

const TableCellOrder = ({
	children,
	label,
	onClick,
	isFirst,
	variant,
	align,
	width,
}: TableCellOrderProps): JSX.Element => {
	const classes = useStyles();

	return (
		<TableCell
			width={width}
			align={align}
			onClick={() => onClick && onClick()}
			className={isFirst ? classes.firstTableCell : ''}
		>
			{label && <Typography variant={variant}>{label}</Typography>}
			{children}
		</TableCell>
	);
};

export default memo(TableCellOrder);
