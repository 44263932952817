import { useQuery } from '@apollo/client';
import { SupplierQueryVariables, SupplierQuery } from '../graphql/queries.definitions';
import { GET_SUPPLIER_BY_ID } from '../graphql/queries';

const useSupplierByIdQuery = (id: string) => {
	const { data, loading } = useQuery<SupplierQuery, SupplierQueryVariables>(GET_SUPPLIER_BY_ID, {
		variables: {
			id,
		},
	});

	return { supplier: data?.supplier, loading };
};

export default useSupplierByIdQuery;
