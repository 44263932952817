import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../app/theme/colors.scss';

export const useQuotePreviewStyles = makeStyles(() =>
	createStyles({
		header: {
			position: 'sticky',
			top: 0,
			zIndex: 100,
			backgroundColor: colors.grayCard,
			padding: '10px 30px',
			'& .MuiGrid-container': {
				'& .MuiGrid-item:last-child': {
					textAlign: 'right',
				},
			},
		},
		body: {
			padding: '10px 30px',
		},
		checkbox: {
			'& .MuiFormControlLabel-label': {
				color: colors.gray600,
				fontSize: '12px',
			},
		},
		wrapVisualization: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			margin: '20px auto',
			padding: 10,
			width: '80%',
		},
		quoteTabData: {
			width: '100%',
			height: 'auto',
			margin: '16px 0',
			border: `0.5px solid ${colors.gray}`,
		},
		marginHeader: {
			lineHeight: 0.5,
			marginTop: '2px',
		},
	})
);
