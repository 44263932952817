import { ProductQuery } from './graphql/queries.definitions';

export type ProductFromModel = ProductQuery['product'];

export type ProductDetailsType = {
	name: string;
	value?: string | null;
	printName?: string | null;
	family?: string | null;
	id: string;
	key?: string | null;
	index?: number | null;
};

export const SUPPLIER_PRICES_HEAD_CELLS: { key: string; label: string; sort: boolean }[] = [
	{ key: 'quantity', label: 'Quantité', sort: true },
	{ key: 'prixCalage', label: 'Prix calage HT(€)', sort: true },
	{ key: 'prixHorsCalage', label: 'Prix hors calage HT(€)', sort: true },
	{ key: 'prixExemplaireSupp', label: 'Prix Ex.Sup.HT(€)', sort: true },
	{ key: 'prixChangementPlaque', label: 'Prix Changt par plaque HT(€)', sort: true },
	{ key: 'nbPosesMax', label: 'Nb pose maxi', sort: true },
	{ key: 'autrePrixFixe', label: 'Autre prix fixe HT(€)', sort: true },
	{ key: 'prixCliche', label: 'Prix de cliché HT(€)', sort: true },
	{ key: 'prixOutillage', label: 'Prix Outillage(€)', sort: true },
	{ key: 'poidsUnitaireGr', label: 'Poid unitaire(Gr)', sort: true },
	{ key: 'franco', label: 'Franco', sort: true },
	{ key: 'delais', label: 'Délais(J+)', sort: true },
	{ key: 'validityDate', label: 'Date fin validité', sort: true },
];

export const CLIENT_PRICES_HEAD_CELLS: { key: string; label: string; sort: boolean; width?: string }[] = [
	{ key: 'productName', label: 'Nom du produit', sort: true, width: '190px' },
	{ key: 'quantity', label: 'Quantité', sort: true },
	{ key: 'nbShippingPoints', label: 'Nb de point de distribiution', sort: true },
	{ key: 'productSalesPriceExcludingVAT', label: 'PV produit HT (€)', sort: true },
	{ key: 'productPurchasePriceExcludingVAT', label: 'PA produit HT (€)', sort: true },
	{ key: 'productDifferencePrice', label: 'Différence produit (€)', sort: true },
	{ key: 'transportSalesPriceExcludingVAT', label: 'PV Transport HT (€)', sort: true },
	{ key: 'transportPurchasePriceExcludingVAT', label: 'PA Transport HT (€)', sort: true },
	{ key: 'transportDifferencePrice', label: 'Différence transport (€)', sort: true },
	{ key: 'cost', label: 'Prix de vente', sort: true },
	{ key: 'sellingPriceMargin', label: 'Marge', sort: true },
];
