import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import {
	WorkingDay,
	WeekTranslate,
	initialWorkingDays,
	initialWorkingEndHour,
	initialWorkingStartHour,
} from './definitions';
import timeFormat from './time';
import { Client, ClientShippingAddress } from '../../../graphql/types';
import { LayoutField } from '../../../components/form/LayoutField';
import { useFormikContext } from 'formik';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface WorkingHoursProps {
	client: ClientShippingAddress | undefined;
	disabled?: boolean | null | undefined;
	title?: string;
	index?: number;
}
const WorkingHours = ({ client, disabled, title, index }: WorkingHoursProps): JSX.Element => {
	const { setFieldValue } = useFormikContext<Client>();

	const [workingDays, setWorkingDays] = useState<WorkingDay[]>(initialWorkingDays);

	useEffect(() => {
		if (client?.workingDays && client?.workingDays?.length) {
			const workindDayList: WorkingDay[] = [];

			client.workingDays.map((item) => {
				return workindDayList.push({
					day: item.day ?? '',
					isWorkingDay: item.isWorkingDay ?? undefined,
					start: item.start ? new Date(item.start) : initialWorkingStartHour,
					end: item.end ? new Date(item.end) : initialWorkingEndHour,
				});
			});

			setWorkingDays(workindDayList);
		} else {
			setWorkingDays(initialWorkingDays);
		}
	}, [client]);

	const handleDateChange = (dayIndex: number, field: string, date?: unknown) => {
		const newWorkingHours = workingDays.map((day, index) =>
			dayIndex === index
				? {
						...day,
						start: field === 'start' ? date : day.start,
						end: field === 'end' ? date : day.end,
				  }
				: day
		);
		setWorkingDays(newWorkingHours as WorkingDay[]);
		setFieldValue(index !== undefined ? `clientShippingAddress[${index}].workingDays` : 'workingDays', newWorkingHours);
	};

	const handleWorkingDay = (dayIndex: number) => {
		const newWorkingDays = workingDays.map((day, index) =>
			dayIndex === index
				? {
						...day,
						isWorkingDay: !day.isWorkingDay,
						start: !day.isWorkingDay ? day.start : initialWorkingStartHour,
						end: !day.isWorkingDay ? day.end : initialWorkingEndHour,
				  }
				: day
		);

		setWorkingDays(newWorkingDays);
		setFieldValue(index !== undefined ? `clientShippingAddress[${index}].workingDays` : 'workingDays', newWorkingDays);
	};

	const keyboardTimePickerCommonProps = (day: WorkingDay) => ({
		size: 'small',
		format: timeFormat['FR'],
		cancelLabel: ' Annuler',
		ampm: false,
		margin: 'normal',
		inputVariant: 'outlined',
		disabled: disabled || !day.isWorkingDay,
	});

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<LayoutField label={title}>
				<FormGroup>
					{workingDays.map((day, index) => {
						return (
							<Grid key={index} container alignItems="center" spacing={2}>
								<Grid item xs={4}>
									<FormControlLabel
										disabled={!!disabled}
										key={index}
										label={WeekTranslate[day.day]}
										control={<Checkbox checked={day.isWorkingDay} />}
										onChange={() => handleWorkingDay(index)}
									/>
								</Grid>
								<Grid item xs={4}>
									<TimePicker
										label={`working-day-start-${day.day}`}
										value={day.start}
										onChange={(date) => handleDateChange(index, 'start', date)}
										{...keyboardTimePickerCommonProps(day)}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Grid>
								<Grid item xs={4}>
									<TimePicker
										label={`working-day-end-${day.day}`}
										value={day.end}
										onChange={(date) => handleDateChange(index, 'end', date)}
										{...keyboardTimePickerCommonProps(day)}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Grid>
							</Grid>
						);
					})}
				</FormGroup>
			</LayoutField>
		</LocalizationProvider>
	);
};

export default WorkingHours;
