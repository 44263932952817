import { createTheme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import './fonts.scss';

const borderRadius: string = '200px';
const border = '1px solid #767684';

type Fonts = {
	h1: CSSProperties;
	h2: CSSProperties;
	h3: CSSProperties;
	h4: CSSProperties;
	h5: CSSProperties;
	h6: CSSProperties;
	body1: CSSProperties;
	body2: CSSProperties;
	button: CSSProperties;
};

const colors = {
	bodyBackgroundLight: '#fafafa',
	bodyBackgroundDark: '#262744',
	primary: '#3e64ad',
	primaryLight: 'rgba(50, 108, 240, 0.1)',
	secondary: '#83eba0',
	secondaryLight: '#00d7a0',
	secondaryExtraLight: '#00d7a0',
	documentHeaderLight: '#5f6dbc',
	documentHeaderDark: '#3b3b50',
	sideBackBackgroundLight: '#01397c',
	sideBackBackgroundDark: '#00002e',
	paperBackgroundLight: '#ffffff',
	paperBackgroundDark: '#00002e',
	documentCardRowLight: '#ffffff',
	documentCardRowDark: '#262744',
	chipBackgroundLight: '#e0e0e0',
	error: '#f65a5a',
	disabled: '#000000',
	gray: '#8b9898',
	gray100: '#767684',
	gray200: '#e7e7e7',
	black: '#000000',
	indigo900: '#262744',
	indigo1000: '#00002e',
	purple600: '#5f6dbc',
	purple200: '#3269f0',
	purple500: '#f5f6fb',
	purple300: '#e8e9ef',
	purple100: '#f5f6fb',
	indigo700: '#3e64ad',
	indigo100: 'rgba(50, 108, 240, 0.2)',
	blue200: '#edf2fc',
	blue900: '#01397c',
	blue500: '#023067',
	green100: '#caf2a7',
	green800: '#67db00',
	green700: '#83eba0',
	black100: '#3b3b50',
	grey100: '#000000',
	grey: '#edeff6',
	red100: '#fbdfe8',
	red400: '#e52865',
	red500: '#ff5a38',
	white: '#ffffff',
	white200: 'rgba(255, 255, 255, 0.7)',
	pagesPanelBackgroundGray: '#e5e5e5',
	blueCircleOfIncompleteProducts: '#95cadc',
	redCircleOfNonViewedProductsComments: '#f8bcbb',
	historyBox: '#dae7f5',
	orange100: '#f2d3ab',
	orange200: '#d57e09',
	orange300: '#fff2e0',
	yellow: '#fec800',
	yellow100: '#ffefb5',
	yellow400: '#f19222',
	gray700: '#3b3a50',
	redinfo: '#d61d1d',
	gray600: '#59596c',
	hover: '#ee7766',
	grayCard: '#f4f4f4',
	gray300: '#dadada',
	blueDisabled: '#c1cde4',
	turquoise100: '#d9fbf9',
	turquoise400: '#01c0ee',
	turquoise500: '#64c1c0',
	gray400: '#afb1b8',
	orange: '#ea5540',
	orangeDisabled: '#f8c8c1',
};

export const fonts: Fonts = {
	h1: {
		// 24px
		fontFamily: 'ScandiaWebBold',
		fontSize: '1.5rem',
		lineHeight: '2.125rem',
	},
	h2: {
		// 20px
		fontFamily: 'ScandiaWebBold',
		fontSize: '1.25rem',
		lineHeight: '2rem',
	},
	h3: {
		// 16px
		fontFamily: 'ScandiaWebMedium',
		fontSize: '1rem',
		lineHeight: '1.75rem',
	},
	h4: {
		// 14px
		fontFamily: 'ScandiaWebMedium',
		fontSize: '0.875rem',
		lineHeight: '1.625rem',
	},
	h5: {
		// 14px
		fontFamily: 'ScandiaWebRegular',
		fontSize: '0.875rem',
		lineHeight: '1.625rem',
	},
	h6: {
		// 12px
		fontFamily: 'ScandiaWebMedium',
		fontSize: '0.75rem',
		lineHeight: '1.5rem',
	},
	body1: {
		// 12px
		fontFamily: 'ScandiaWebRegular',
		fontSize: '0.75rem',
		lineHeight: '1.25rem',
	},
	body2: {
		// 10px
		fontFamily: 'ScandiaWebRegular',
		fontSize: '0.625rem',
		lineHeight: '1.125rem',
	},
	button: {
		// 14px
		fontFamily: 'ScandiaWebMedium',
		fontSize: '0.875rem',
	},
};

const { h1, h2, h3, h4, h5, h6, body1, body2, button } = fonts;

export default createTheme({
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
		background: {
			paper: colors.paperBackgroundLight,
		},
		common: {
			black: colors.black100,
		},
		text: {
			primary: colors.black100,
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1024,
			lg: 1320,
			xl: 1920,
		},
	},
	typography: {
		fontFamily: ['Arial', 'sans-serif'].join(','),
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		body1,
		body2,
		button: {
			...button,
			textTransform: 'unset',
		},
	},
	components: {
		MuiToolbar: {
			styleOverrides: {
				regular: {
					padding: 0,
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				colorPrimary: {
					color: colors.black100,
				},
				colorSecondary: {
					color: 'white',
				},
				colorAction: {
					color: colors.primary,
				},
				root: {
					borderRadius: '50%',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				'@global': {
					body: {
						backgroundColor: colors.bodyBackgroundLight,
						height: '100vh',
						display: 'flex',
						flexDirection: 'column',
						fontFamily: 'ScandiaWebRegular',
						color: colors.black100,
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius,
					...fonts.button,
					marginLeft: 13,
				},
				contained: {
					backgroundColor: 'primary',
				},
				containedSecondary: {
					color: colors.black100,
					...fonts.button,
				},
			},
		},
		MuiButtonGroup: {
			styleOverrides: {
				root: {
					borderRadius: borderRadius,
				},
				contained: {
					boxShadow: 'unset',
					border,
					color: colors.grey100,
				},
				groupedContainedHorizontal: {
					borderRight: 'unset',
				},
			},
		},
		MuiList: {
			styleOverrides: {
				padding: {
					paddingTop: 0,
					paddingBottom: 0,
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					'&$:hover': {
						backgroundColor: 'transparent',
					},
					'&$selected': {
						backgroundColor: colors.gray100,
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: h6,
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: 'transparent',
					'&:hover': {
						backgroundColor: 'transparent!important',
						'@media (hover: none)': {
							backgroundColor: 'transparent',
						},
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					padding: 10,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: colors.black100,
					...fonts.h6,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				/*	root: {
					color: 'black',
				},*/
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					boxShadow: 'unset',
					borderRadius: '4px',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		//	class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"

		MuiTextField: {
			styleOverrides: {
				root: {
					error: {
						background: colors.grey,
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					'&:focus': {
						backgroundColor: 'none',
					},
				},
			},
		},
		MuiSnackbar: {
			styleOverrides: {
				root: {
					width: '100%',
				},
				anchorOriginBottomCenter: {
					bottom: 0,
					'@media (min-width: 600px)': {
						bottom: 0,
					},
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paperWidthSm: {
					maxWidth: 700,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					color: colors.gray100,
					backgroundColor: colors.grayCard,
					borderTop: '2 solid ' + colors.gray300,
					...h6,
				},
				body: {
					...h5,
				},
				stickyHeader: {
					backgroundColor: colors.grayCard,
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				colorSecondary: {
					'&$checked': {
						color: colors.primary,
					},
				},
			},
		},
	},
});
