import React, { ReactNode } from 'react';
import { InputProps as MuiInputProps } from '@mui/material/Input';
import { Box } from '@mui/material';
import colors from '../../app/theme/colors.scss';
import FormikTextField, { OnChangeUnControlledArgsType } from './FormikTextField';
import { LayoutField } from './LayoutField';

interface LayoutFieldFormProps {
	label: string;
	disabled?: boolean;
	name: string;
	width?: string;
	type?: string;
	children?: ReactNode;
	inputProps?: MuiInputProps;
	direction?: 'row' | 'column';
	value?: unknown;
	marginButton?: number;
	placeholder?: string;
	marginRight?: number;
	onChangeUnControlled?: (event: OnChangeUnControlledArgsType) => void;
	testid?: string;
	multiline?: boolean;
	rows?: number;
}

const LayoutFieldForm = ({
	label,
	disabled,
	name,
	width,
	type = 'text',
	children,
	inputProps,
	direction = 'row',
	value,
	marginButton = 3,
	placeholder,
	marginRight,
	...res
}: LayoutFieldFormProps) => {
	return (
		<LayoutField label={label}>
			<Box display={children ? 'flex' : 'block'} mr={marginRight} flexDirection={direction} mb={marginButton}>
				<Box width={width} bgcolor={disabled ? colors.grey : colors.white}>
					<FormikTextField
						name={name}
						fullWidth
						variant="outlined"
						disabled={disabled}
						type={type}
						InputProps={inputProps}
						value={value}
						placeholder={placeholder}
						{...res}
					/>
				</Box>
				{children}
			</Box>
		</LayoutField>
	);
};

export default LayoutFieldForm;
