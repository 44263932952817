import gql from 'graphql-tag';
import { PRODUCT_FRAGMENT } from '../../graphql/fragments/product/productFragment';
import CONDITIONNEMENT_FRAGMENT from '../../graphql/fragments/conditionnement/conditionnementFragment';

const GET_PRODUCTS = gql`
	query GetProducts($supportId: String!, $filters: AttributesFilterInput!) {
		products(data: { supportId: $supportId, attributes: $filters }) {
			id
			supportId
			typeImpression
			matiere
			typeSupport
			faconnage
			nbCouleurRecto
			descriptionIndication
			precisionsFaconnage
			typePapier
			typeMatiere
			nbCouleurVerso
			optionsFaconnage
			format
			orientation
			couleur
			impressionRectoVerso
			decoupe
			surfacage
			paginationTotale
			fourniture
			grammageGr
			precisionsFourniture
			epaisseurMicrons
			paginationCouverture
			finitionRecto
			finitionVerso
			nbCouleurCouvertureRecto
			paginationInterieur
			classification
			paginationInterieurEncart
			certification
			nbCouleurCouvertureVerso
			matiereCouverture
			nbCouleurInterieurRecto
			largeurFormatOuvertCm
			typeMatiereCouverture
			longueurFormatOuvertCm
			nbCouleurInterieurVerso
			grammageCouvertureGr
			largeurFormatPageCm
			nbCouleurInterieurEncartRecto
			matiereInterieur
			longueurFormatPageCm
			nbCouleurInterieurEncartVerso
			largeurFormatFiniCm
			surfacageCouvertureRecto
			typeMatiereInterieur
			longueurFormatFiniCm
			grammageInterieurGr
			tauxSurfacage
			impressionLogo
			largeurCouvertureFormatOuvertCm
			longueurCouvertureFormatOuvertCm
			repiquage
			repiquageDOS
			largeurCouvertureFormatPageCm
			repiquageCoeur
			longueurCouvertureFormatPageCm
			chant
			largeurCouvertureFormatFiniCm
			longueurCouvertureFormatFiniCm
			largeurInterieurFormatOuvertCm
			longueurInterieurFormatOuvertCm
			largeurInterieurFormatPageCm
			longueurInterieurFormatPageCm
			largeurInterieurFormatFiniCm
			longueurInterieurFormatFiniCm
			largeurInterieurEncartFormatOuvertCm
			longueurInterieurEncartFormatOuvertCm
			largeurInterieurEncartFormatPageCm
			longueurInterieurEncartFormatPageCm
			largeurInterieurEncartFormatFiniCm
			longueurInterieurEncartFormatFiniCm
			profondeurFormatFiniCm
			diametreCm
			taille
			enveloppeFenetre
			largeurTailleFenetreCm
			longueurTailleFenetreCm
			ouvertureEnveloppe
			surfacageCouvertureVerso
			surfacageInterieurRecto
			surfacageInterieurVerso
			typeEnvoi
			ciblage
			zoneTerritorial
			objectifMarketing
			paysDeDiffusion
			modeleAchat
			fondsPerdus
			faconnageDeLivraison
			largeurPixel
			longueurPixel
			codeAt
		}
	}
	${CONDITIONNEMENT_FRAGMENT}
`;

const GET_PRODUCT_BY_ID = gql`
	query product($id: String!) {
		product(id: $id) {
			...Product
		}
	}
	${PRODUCT_FRAGMENT}
`;

export { GET_PRODUCTS, GET_PRODUCT_BY_ID };
