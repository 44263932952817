import { useLazyQuery } from '@apollo/client';
import { FILTERED_SUPPLIER_PRICE } from '../graphql/queries';
import { GeSupplierPricesQuery, GeSupplierPricesQueryVariables } from '../graphql/queries.definitions';
import { useField } from 'formik';
import { SupplierPriceFormModel } from 'supplier/definitions';

export const useFiltredSupplierPrices = () => {
	const [, , helpersSupplierPrices] = useField<SupplierPriceFormModel[] | undefined>('supplierPrices');
	const [, , helpersDepartingDepartment] = useField<string | undefined>('departingDepartment');
	const [, , helpersListSupplierPrices] = useField<SupplierPriceFormModel[] | undefined>('listSupplierPrices');

	const [getSupplierPrices, { loading, data }] = useLazyQuery<GeSupplierPricesQuery, GeSupplierPricesQueryVariables>(
		FILTERED_SUPPLIER_PRICE,
		{
			onCompleted: (data) => {
				if (data.supplierPrices.length === 1) {
					helpersSupplierPrices.setValue(data.supplierPrices);
					helpersDepartingDepartment.setValue(data.supplierPrices[0]?.departingDepartment || undefined);
				}
				helpersListSupplierPrices.setValue(data.supplierPrices);
			},
		}
	);

	return { getSupplierPrices, listSupplierPrices: data?.supplierPrices, loading };
};
