import { gql } from '@apollo/client';
import ADDRESS_FRAGMENT from '../address/addressFragment';
import CONDITIONNEMENT_FRAGMENT from '../conditionnement/conditionnementFragment';
import MARGIN_FRAGMENT from '../margins/marginsFragment';

const CLIENT_QUOTE_FRAGMENT = gql`
	fragment clientQuote on ClientQuote {
		id
		status
		createdAt
		updatedAt
		type
		clientId
		clientName
		contact
		version
		quoteRequestedBy
		operationName
		clientReference
		fileDeliveryDate
		deliveryDate
		vat
		estimatedCiteoTax
		totalPurchasePriceExcludingVAT
		totalSalesPriceExcludingVAT
		vatAmount
		totalSalesPriceVAT
		margin
		marginRate
		vatRate
		cost
		totalSalesPriceVAT
		additionalCostsIncurred
		fileReferenceId
		comment
		nbShippingPoints
		isDistribution
		billingAddress {
			city
			country
			firstAddress
			name
			secondAddress
			thirdAddress
			zipCode
		}
		clientQuoteItem {
			id
			quantity
			productName
			productId
			numberVersion
			sellingPriceMargin
			productPurchasePriceExcludingVAT
			productSalesPriceExcludingVAT
			transportPurchasePriceExcludingVAT
			transportSalesPriceExcludingVAT
			transportPurchasePriceHt
			transportMoment
			transportSellingPriceHtAdditionalMargin
			transportType
			transportRfa
			transportSellingPriceMargin
			transportSellingPriceByDefaultHt
			transportPurchasePriceHtWithRfa
			transportSellingPriceHtWithClientRfa
			transportSellingPriceHtWithClientRfaRectified
			prepressPriceExcludingVat
			prepressSalesPriceExcludingVat
			transportSellingPriceHt
			cost
			comment
			vatAmount
			salePrice
			tools
			extraUnitsCost
			nbShippingPoints
			productTotalWeight
			rfaClientEuro
			sellingPriceIncludingTax
			numberShots
			numberPlateChanges
			numberFileProcessing
			franco
			deliveryParcel
			corsicaDeliveryExtra
			isMechanizable
			isFinalDistribution
			transporterId
			sellingPriceWithMargin {
				...Margins
			}
			totalProductSalesPriceExcludingVAT
			estimatedCiteoTax
			transportInclus
			transportNonInclus
			prepresseInclus
			prepresseNonInclus
			prepresseNonApplicable
			detailPrepressePDF
			product {
				id
				supportId
				typeImpression
				matiere
				typeSupport
				faconnage
				nbCouleurRecto
				descriptionIndication
				precisionsFaconnage
				typePapier
				typeMatiere
				nbCouleurVerso
				optionsFaconnage
				format
				orientation
				couleur
				impressionRectoVerso
				decoupe
				surfacage
				paginationTotale
				fourniture
				grammageGr
				precisionsFourniture
				epaisseurMicrons
				paginationCouverture
				finitionRecto
				finitionVerso
				nbCouleurCouvertureRecto
				paginationInterieur
				classification
				paginationInterieurEncart
				certification
				nbCouleurCouvertureVerso
				matiereCouverture
				nbCouleurInterieurRecto
				largeurFormatOuvertCm
				typeMatiereCouverture
				longueurFormatOuvertCm
				nbCouleurInterieurVerso
				grammageCouvertureGr
				largeurFormatPageCm
				nbCouleurInterieurEncartRecto
				matiereInterieur
				longueurFormatPageCm
				nbCouleurInterieurEncartVerso
				largeurFormatFiniCm
				surfacageCouvertureRecto
				typeMatiereInterieur
				longueurFormatFiniCm
				grammageInterieurGr
				tauxSurfacage
				impressionLogo
				largeurCouvertureFormatOuvertCm
				longueurCouvertureFormatOuvertCm
				repiquage
				repiquageDOS
				largeurCouvertureFormatPageCm
				repiquageCoeur
				longueurCouvertureFormatPageCm
				chant
				largeurCouvertureFormatFiniCm
				longueurCouvertureFormatFiniCm
				largeurInterieurFormatOuvertCm
				longueurInterieurFormatOuvertCm
				largeurInterieurFormatPageCm
				longueurInterieurFormatPageCm
				largeurInterieurFormatFiniCm
				longueurInterieurFormatFiniCm
				largeurInterieurEncartFormatOuvertCm
				longueurInterieurEncartFormatOuvertCm
				largeurInterieurEncartFormatPageCm
				longueurInterieurEncartFormatPageCm
				largeurInterieurEncartFormatFiniCm
				longueurInterieurEncartFormatFiniCm
				profondeurFormatFiniCm
				diametreCm
				taille
				enveloppeFenetre
				largeurTailleFenetreCm
				longueurTailleFenetreCm
				ouvertureEnveloppe
				surfacageCouvertureVerso
				surfacageInterieurRecto
				surfacageInterieurVerso
				typeEnvoi
				ciblage
				zoneTerritorial
				objectifMarketing
				paysDeDiffusion
				modeleAchat
				fondsPerdus
				faconnageDeLivraison
				codeAt
				productSupplierPrice {
				     poidsUnitaireGr
				}
				conditionnement {
					...AttributConditionnement
				}
			}
		}
		clientQuoteDistributions {
			clientShippingAddressId
			storeId
			store {
				id
				name
			}
			productId
			quantity
			productName
			clientShippingAddress {
				address {
					...Address
				}
				contact {
					directorName
					phone
					storeCode
				}
				id
				addressId
			}
			transportMoment
			deliveryParcel
		}
	}
	${ADDRESS_FRAGMENT}
	${CONDITIONNEMENT_FRAGMENT}
	${MARGIN_FRAGMENT}
`;

export default CLIENT_QUOTE_FRAGMENT;
