import { Box, Typography } from '@mui/material';
import { DelivreryMode } from 'admin/support/definitions';
import { FabricationTime } from 'graphql/types';
import React, { useCallback, useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { formatNumberDisplay } from 'utils/prices';
import EditableCellField from '../EditableCellField';

const useFabricationTimesTale = (
	fabricationTimes: FabricationTime[],
	supportName: string,
	setFabricationTimesData: React.Dispatch<React.SetStateAction<FabricationTime[]>>
) => {
	const updateMyData = useCallback(
		(rowIndex: number, columnId: string | undefined, value: number) => {
			setFabricationTimesData((previousFabricationTimes: FabricationTime[]) =>
				previousFabricationTimes.map((row: FabricationTime, index: number) => {
					if (index === rowIndex) {
						if (columnId === DelivreryMode.expressMode) {
							return {
								...previousFabricationTimes[rowIndex],
								[columnId]: value,

								urgentMode: value,
							};
						} else if (columnId === DelivreryMode.standardMode) {
							return {
								...previousFabricationTimes[rowIndex],
								standardMode: value,
							};
						} else {
							return {
								...previousFabricationTimes[rowIndex],
								urgentMode: value,
							};
						}
					}

					return row;
				})
			);
		},
		[setFabricationTimesData]
	);
	const defaultColumn = {
		Cell: EditableCellField,
	};

	const columns = useMemo<Column<FabricationTime>[]>(
		() => [
			{
				Header: supportName,

				columns: [
					{
						Header: 'Quantity',
						accessor: 'quantity',
						Cell: ({ row }) => <Typography>{formatNumberDisplay(row.original?.quantity, 0) ?? null}</Typography>,
					},
				],
			},
			{
				Header: 'Mise à disposition des produits pour les commandes passées',
				columns: [
					{
						accessor: 'avant10',
						Header: (
							<Box display="flex" justifyContent="center" alignItems="center">
								<Typography variant="h6"> Avant 10h</Typography>
							</Box>
						),
						columns: [
							{
								Header: 'Standard (J+)',
								accessor: 'standardMode',
								Cell: ({ row, column, value }) => {
									return (
										<EditableCellField value={value as number} row={row} column={column} updateMyData={updateMyData} />
									);
								},
							},
							{
								Header: 'Express (J+)',
								accessor: 'expressMode',
								Cell: ({ row, column, value }) => {
									return (
										<EditableCellField value={value as number} row={row} column={column} updateMyData={updateMyData} />
									);
								},
							},
						],
					},
					{
						Header: 'Avant 13h',
						columns: [
							{
								Header: 'Urgent (J+)',
								accessor: 'urgentMode',
								Cell: ({ row, column, value }) => {
									return (
										<EditableCellField value={value as number} row={row} column={column} updateMyData={updateMyData} />
									);
								},
							},
						],
					},
				],
			},
		],
		[supportName, updateMyData]
	);

	return useTable({
		columns,
		updateMyData,
		...defaultColumn,
		data: fabricationTimes,
	});
};

export default useFabricationTimesTale;
