import { Box, IconButton } from '@mui/material';
import { PrintOutlined, SearchOutlined } from '@mui/icons-material';
import { InvoiceListType } from 'invoice/definitions';
import React, { useState } from 'react';
import { Row } from 'react-table';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import usePopin from 'hooks/usePopin';
import { PreviewFiles } from 'order/form/order-cockpit/client-document/component/PreviewFiles';
import { printPDFS } from './helpers';

interface PdfCellProps {
	row: Row<InvoiceListType>;
}
const PdfCell = ({ row }: PdfCellProps) => {
	const [isHidden, setIsHidden] = useState<boolean>(false);
	const [openPopin, renderPopin, closePopin] = usePopin({
		headerTitle: 'Aperçu facture',
		maxWidth: 'md',
		fullWidth: true,
	});

	const isPreviewInvoice = !!row.original.url;
	return (
		<Box display="flex" alignItems="center" justifyContent="center">
			{row.original.url && renderPopin(<PreviewFiles file={row.original.url ?? ''} />)}
			<Box>
				<IconButton component="span" color="primary" onClick={() => setIsHidden(!isHidden)}>
					<MoreVertIcon fontSize="large" />
				</IconButton>
			</Box>
			<Box>
				<Box mt={3}>
					<IconButton
						component="span"
						color="primary"
						style={{ visibility: isHidden ? 'visible' : 'hidden' }}
						onClick={() => printPDFS([row.original.url ?? ''] ?? [''])}
					>
						<PrintOutlined fontSize="small" />
					</IconButton>
				</Box>

				<Box mb={3}>
					<IconButton
						component="span"
						color="primary"
						style={{ visibility: isHidden ? 'visible' : 'hidden' }}
						onClick={() => (isPreviewInvoice ? openPopin() : closePopin())}
					>
						<SearchOutlined fontSize="small" />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default PdfCell;
