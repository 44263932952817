import React from 'react';
import { useHistory } from 'react-router-dom';
import { Supplier } from '../../graphql/types';
import { MenuComponentProps } from '../../Header/definitions';
import { Box } from '@mui/material';
import useSuppliersQuery from '../hooks/useSuppliersQuery';
import SupplierListSelect from './SupplierListSelect';
import SupplierDialogForm from './SupplierDialogForm';

interface SupplierListMenuProp extends MenuComponentProps {}

const SupplierListMenu = ({ onClose }: SupplierListMenuProp): JSX.Element => {
	const history = useHistory();
	const { suppliers } = useSuppliersQuery();

	const handleSupplierSelected = ({ id }: Pick<Supplier, 'id'>) => {
		history.push(`/supplier/${id}`);
		onClose?.();
	};

	return (
		<>
			<SupplierDialogForm />
			<Box mt={6.5}>
				{suppliers && <SupplierListSelect suppliers={suppliers} onSupplierSelected={handleSupplierSelected} />}
			</Box>
		</>
	);
};

export default SupplierListMenu;
