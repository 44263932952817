import { useQuery } from '@apollo/client';
import { PRINT_VALUES } from '../graphql/queries';
import { PrintValuesQueryVariables } from '../graphql/queries.definitions';
import { PrintValuesQuery } from '../graphql/queries.definitions';

export const useAttributesValues = () => {
	const { data } = useQuery<PrintValuesQuery, PrintValuesQueryVariables>(PRINT_VALUES);

	return { printValues: data?.printValues };
};
