import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Divider, TextField, Typography, Dialog, Button } from '@mui/material';
import useSupportInsert from './hooks/useSupportInsert';
import colors from '../../app/theme/colors.scss';

const AddSupportDialogue = (): JSX.Element => {
	const [openPop, setOpenPop] = React.useState<boolean>(false);
	const [nameSupport, setNameSupport] = React.useState<string>('');
	const [error, setError] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string>('');
	const { insertSupport } = useSupportInsert();

	const handleChangeNameSupport = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNameSupport(event.target.value);
		setError(false);
	};

	const addSupport = async () => {
		if (nameSupport !== '') {
			try {
				await insertSupport(nameSupport);
				setNameSupport('');
				setOpenPop(false);
			} catch (error) {
				setError(true);
				setErrorMessage(`Impossible d'ajouter le support: ${nameSupport}, ce dernier existe déjà`);
			}
		} else {
			setError(true);
			setErrorMessage('Veuillez entrer un nom de support');
		}
	};

	return (
		<Box position="fixed" top={52.4} zIndex={2} bgcolor={colors.black100} width={257}>
			<Divider />
			<Box p={1}>
				<IconButton color="inherit" onClick={() => setOpenPop(true)} size="large">
					<Box pr={1} pt={0.5}>
						<AddCircleIcon />
					</Box>
					<Typography variant="h6">Ajouter un support</Typography>
				</IconButton>
			</Box>
			<Dialog onClose={() => setOpenPop(false)} open={openPop}>
				<Box display={'flex'} p={3}>
					<Box pr={12}>
						<Typography variant="h2">Ajout d'un nouveau support</Typography>
					</Box>
					<Box>
						<IconButton aria-label="close" onClick={() => setOpenPop(false)} size="large">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
				<Divider />
				<Box p={3}>
					<Typography variant="h6">Nom du support</Typography>
					<TextField
						fullWidth
						error={error}
						helperText={error && errorMessage}
						variant="outlined"
						value={nameSupport}
						onChange={handleChangeNameSupport}
					/>
				</Box>
				<Divider />
				<Box p={3} display="flex" flexDirection="row-reverse">
					<Button variant="contained" color="primary" onClick={addSupport}>
						Ajouter
					</Button>
				</Box>
			</Dialog>
			<Divider />
		</Box>
	);
};

export default AddSupportDialogue;
