import React, { ChangeEvent, useMemo, useEffect } from 'react';
import { AutocompleteValue, Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useFormikContext } from 'formik';
import colors from 'app/theme/colors.scss';
import { LayoutField } from 'components/form/LayoutField';
import { useClientPriceExistingByProduct } from 'clientPrice/hooks/useClientPriceExistingByProduct';
import { HORIZONTAL_QUOTE_DATA_CELL, initialAddClientPriceToQuotes, VERTICAL_QUOTE_DATA_CELL } from '../definitions';
import { getNumberVersion } from '../../../helpers';
import { ClientQuoteModel, initialClientQuote } from '../../../definition';
import { SummaryPrice } from 'components/summaryPrice/SummaryPrice';
import CharacteristicByProductDialog from 'product/components/CharacteristicByProductDialog';
import { ClientPriceStatus, QuoteType } from 'graphql/types';
import { FormikNumberField } from 'components/form/FormikNumberField';
import { useClientPriceWithCustomQuantity } from 'clientPrice/hooks/useClientPriceWithCustomQuantity';
import FormikAutocompleteField from 'components/form/FormikAutocompleteField';
import { isString } from 'utils/typeGuards';
import { ItemCellPrice } from 'components/summaryPrice/ItemCellPrice';
import TransportInformation from '../../../component/TransportInformation';
import { useDebounce } from 'hooks/useDebounce';

type ProductType = { productId: string; productName: string };

export const SelectionClientPrice = (): JSX.Element | null => {
	const { values, setFieldValue } = useFormikContext<ClientQuoteModel>();
	const isClientPriceToBeValidate = values.type === QuoteType.ClientPriceToBeValidate;
	const { getClientPrice, loadingGetClientPrice } = useClientPriceWithCustomQuantity();
	const { getClientPricesExisting, clientPricesExisting } = useClientPriceExistingByProduct({
		onCompleted: ({ clientPricesExisting }) => {
			setFieldValue('clientPricesExisting', clientPricesExisting);

			if (clientPricesExisting?.length === 1) {
				handleSelectQuantity(values.type === QuoteType.ClientOrderToBeCreate ? 0 : clientPricesExisting?.[0]?.quantity);
			}
		},
	});

	const debouncedValue = useDebounce(values.quantity, 1000);

	useEffect(() => {
		if (debouncedValue && debouncedValue > 0 && values.selectedProduct?.productName) {
			getClientPrice({
				variables: {
					filters: {
						clientId: values.clientId ?? '',
						productId: values.selectedProduct?.productId ?? '',
						quantity: +debouncedValue,
						numberVersion: values.versionNumber ?? 1,
					},
				},
			});
		}
	}, [debouncedValue, getClientPrice, values.clientId, values.selectedProduct, values.versionNumber]);

	const quantitiesWithZeroValue = useMemo(
		() =>
			values.clientPricesExisting && values.selectedProduct
				? [...values.clientPricesExisting?.map((clientPrice) => clientPrice.quantity)]
				: [],
		[values.clientPricesExisting, values.selectedProduct]
	);

	const numberVersion = useMemo(() => values.clientPricesExisting && getNumberVersion(values.clientPricesExisting), [
		values.clientPricesExisting,
	]);

	const handleSelectProduct = async (value: { productId: string; productName: string } | null) => {
		const filteredProduct = values.products?.find(({ productId }) => productId === value?.productId);

		if (filteredProduct && values.clientId) {
			setFieldValue('summaryPrice', {
				...initialClientQuote.summaryPrice,
				productName: filteredProduct.productName,
			});
			setFieldValue('selectedProduct', filteredProduct);

			handleInitialAddClientPriceToQuotes();

			// get quantities
			await getClientPricesExisting({
				variables: {
					filters: {
						clientId: values.clientId,
						productName: filteredProduct.productName,
						...(values?.type === QuoteType.ClientPriceToBeValidate && { status: ClientPriceStatus.InProgress }),
					},
				},
			});
		}
	};

	const handleSelectQuantity = (value: AutocompleteValue<number, false, false, true>) => {
		const clientPrice = clientPricesExisting?.find(({ quantity }) => quantity === value);

		setFieldValue('quantity', value);

		if (clientPrice) {
			setFieldValue('summaryPrice.productId', clientPrice?.productId);

			(Object.keys(clientPrice) as Array<keyof typeof clientPrice>).forEach((key) => {
				if (key === 'sellingPriceWithMargin') {
					(Object.keys(clientPrice[key]) as Array<keyof typeof clientPrice['sellingPriceWithMargin']>).forEach(
						(keySellingPrice) => {
							setFieldValue(`summaryPrice.${keySellingPrice}`, clientPrice[key][keySellingPrice] ?? 0.0);
						}
					);
				} else {
					setFieldValue(
						`summaryPrice.${key}`,
						key === 'sellingPriceMargin' ? clientPrice[key] ?? 0.0 : clientPrice[key]
					);
				}
			});
		} else {
			handleInitialAddClientPriceToQuotes();
		}
	};

	const leastOneNumberVersion = !numberVersion || !numberVersion.length ? [1] : numberVersion;

	const handleInitialAddClientPriceToQuotes = () =>
		(Object.keys(initialAddClientPriceToQuotes) as Array<keyof typeof initialAddClientPriceToQuotes>).forEach((key) => {
			setFieldValue(key, initialAddClientPriceToQuotes[key]);
		});

	const handleSelectVersionNumber = (event: SelectChangeEvent<number>) =>
		setFieldValue('summaryPrice.numberVersion', event.target.value);

	const onChangeControllable = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFieldValue(event.target.name, event.target.value);
	};

	return (
		<Grid container spacing={1} justifyContent="space-between">
			<Grid xs={4} item>
				<LayoutField label="Nom commercial du produit">
					<FormControl fullWidth>
						<Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
							<Box flex="1">
								<FormikAutocompleteField<ProductType>
									disabled={!values.fileReferenceId}
									options={values.products?.map(({ productName, productId }) => ({ productId, productName })) ?? []}
									getOptionLabel={(option) => (option as ProductType).productName ?? ''}
									autoHighlight
									selectOnFocus
									value={{
										productId: values?.selectedProduct?.productId as string,
										productName: values?.selectedProduct?.productName as string,
									}}
									onChange={async (_, value) => {
										if (!isString(value) && value?.productId !== '' && value?.productName !== '') {
											await handleSelectProduct(value);
										}
									}}
								/>
							</Box>

							<Box color={colors.gray100} ml={2}>
								<CharacteristicByProductDialog productId={values.selectedProduct?.productId} />
							</Box>
						</Box>
					</FormControl>
				</LayoutField>
			</Grid>

			<Grid xs={2} item>
				<LayoutField label="Quantité">
					{values.type !== QuoteType.ClientOrderToBeCreate ? (
						<FormControl fullWidth>
							<FormikAutocompleteField<number>
								options={quantitiesWithZeroValue ?? []}
								textField={{ size: 'small' }}
								value={values?.quantity}
								getOptionLabel={(quantity) => quantity.toString() ?? ''}
								onChange={(_, value) => handleSelectQuantity(value)}
							/>
						</FormControl>
					) : (
						<FormikNumberField
							decimalScale={0}
							width="100%"
							variant="outlined"
							name="quantity"
							onChangeControllable={onChangeControllable}
							loading={loadingGetClientPrice}
						/>
					)}
				</LayoutField>
			</Grid>
			<Grid xs={2} item>
				<LayoutField label="Nombre de version">
					<FormControl fullWidth>
						<Select
							value={values.versionNumber}
							variant="outlined"
							onChange={handleSelectVersionNumber}
							inputProps={{ min: 1 }}
						>
							{leastOneNumberVersion.map((quantity) => (
								<MenuItem value={quantity} key={quantity}>
									{quantity}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</LayoutField>
			</Grid>
			<Grid xs={12} item>
				<SummaryPrice horizontalDataCell={HORIZONTAL_QUOTE_DATA_CELL} verticalDataCell={[]} />
				<Box display={'flex'}>
					<Box width={'70%'} m={3}>
						{(values?.quantity as number) > 0 && (
							<TransportInformation name="summaryPrice" disabled={isClientPriceToBeValidate} />
						)}
					</Box>
					<Box width={'30%'}>
						{VERTICAL_QUOTE_DATA_CELL.map(({ width, name, isBorder, value, hasRate, label,isNotDisabled }, i) => (
							<Box width="100%" display="flex" justifyContent="flex-end" key={`verticalDataCell_${i}`}>
								<ItemCellPrice
									width={width}
									name={name}
									isBorder={isBorder}
									hasRate={hasRate}
									label={label}
									value={value}
									isNotDisabled={isNotDisabled}
								/>
							</Box>
						))}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
