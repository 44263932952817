import ListSupportMenu from '../admin/support/ListSupportMenu';
import ClientListMenu from '../clients/menu/ClientListMenu';
import ListProductsMenu from '../product/ListProductsMenu';
import { Menu } from './definitions';
import BigShopConfigMenu from '../admin/big-shop-management/BigShopConfigMenu';
import SupplierListMenu from '../supplier/menu/SupplierListMenu';
import { AppRoute } from '../routes/routes';
import { ClientPriceListMenu } from '../clientPrice/menu/ClientPriceListMenu';
import ImportantDevicesOutlinedIcon from '@mui/icons-material/ImportantDevicesOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

// To be replaced with i18n later if needed
export const PATH_NAMES = {
	Admin: 'Administration',
	Support: 'Caractéristiques par support',
	User: 'Gestion des utilisateurs',
	ClientQuote: 'Devis Clients',
	SupplierQuote: 'Devis Fournisseurs',
	Product: 'Produits',
	ProductList: 'Liste des produits',
	Quatref: '4 Fantastiques',
	SheetsManagement: 'Gestion des fiches clients ',
	SheetsManagementSuppliers: 'Gestion des fiches fournisseurs',
	Clients: 'Clients',
	NewRole: 'Nouveau rôle',
	NewUser: 'Nouveau utilisateur',
	orderCockpit: 'Commandes Clients',
	orders: 'Commandes Fournisseurs',
	BigShopManagement: 'Gestion BigShop',
	PrintValues: 'Valeurs Print',
	TransportRules: 'Règles de transport',
	ParcelTransport: 'Transport colis',
	supplier: 'Fournisseur',
	SellingPrice: 'Gestion des prix de vente',
	CreationOfSalesPrice: 'Création de prix de vente',
	SalePricesList: 'Liste des prix de vente',
	CreationQuotes: 'Création de devis',
	Invoices: 'Factures clients',
	FileReference: 'Référence dossiers',
  Import: 'Import',
};

//TO DO: improve permissionKey names
export const MENUS: readonly Readonly<Menu>[] = Object.freeze([
	{
		title: PATH_NAMES.Product,
		component: ListProductsMenu,
		permissionKey: 'view:product',
		icon: ImportantDevicesOutlinedIcon,
	},
	{
		href: AppRoute.Quatref,
		title: PATH_NAMES.Quatref,
		permissionKey: 'view:four_fantastic',
		icon: Looks4OutlinedIcon,
	},
	{
		href: AppRoute.SupplierQuote,
		title: PATH_NAMES.SupplierQuote,
		permissionKey: 'view:supplier_quotes',
		icon: ListAltOutlinedIcon,
	},
	{
		title: PATH_NAMES.SheetsManagement,
		permissionKey: 'view:client_cards_clients_management',
		component: ClientListMenu,
		icon: AccountBoxOutlinedIcon,
	},
	{
		title: PATH_NAMES.SheetsManagementSuppliers,
		component: SupplierListMenu,
		permissionKey: 'view:client_cards_suppliers_management',
		icon: BusinessOutlinedIcon,
	},
	{
		title: PATH_NAMES.SellingPrice,
		permissionKey: 'view:sales_price_management',
		icon: EuroOutlinedIcon,
		subMenus: [
			{
				title: PATH_NAMES.CreationOfSalesPrice,
				component: ClientPriceListMenu,
				permissionKey: 'view:sale_price_creation',
			},
			{
				title: PATH_NAMES.SalePricesList,
				href: AppRoute.AllClientsPricesList,
				permissionKey: 'view:sales_price_list',
			},
		],
	},
	{
		href: AppRoute.FileReference,
		title: PATH_NAMES.FileReference,
		permissionKey: 'view:file_reference',
		icon: FolderSharedOutlinedIcon,
	},
	{
		href: '/clientQuotes/',
		title: PATH_NAMES.ClientQuote,
		permissionKey: 'view:client_quotes',
		icon: AttachFileOutlinedIcon,
	},
	{
		title: PATH_NAMES.orderCockpit,
		permissionKey: 'view:orders_management',
		href: '/orderCockpit/',
		icon: ReorderOutlinedIcon,
	},
	{
		title: PATH_NAMES.orders,
		permissionKey: 'view:orders_creation',
		href: '/orders/',
		icon: BookmarkBorderIcon,
	},

	{
		title: PATH_NAMES.Invoices,
		permissionKey: 'view:invoice',
		icon: ReceiptOutlinedIcon,
		subMenus: [
			{
				title: 'A valider',
				href: AppRoute.ToBeValidatedInvoicesList,
				permissionKey: 'view:invoice',
			},
			{
				title: 'A comptabiliser',
				href: AppRoute.InvoicesListToBeAccounted,
				permissionKey: 'view:invoice',
			},
			{
				title: 'Comptabilisé',
				href: AppRoute.InvoicesCountedList,
				permissionKey: 'view:invoice',
			},
		],
	},
	{
		title: PATH_NAMES.Admin,
		permissionKey: 'view:admin',
		icon: SettingsOutlinedIcon,
		subMenus: [
			{
				component: ListSupportMenu,
				title: PATH_NAMES.Support,
				permissionKey: 'view:admin_supports',
			},
			{
				href: AppRoute.User,
				title: PATH_NAMES.User,
				permissionKey: 'view:users_management',
			},
			{
				component: BigShopConfigMenu,
				title: PATH_NAMES.BigShopManagement,
			},
			{
				title: PATH_NAMES.TransportRules,
				permissionKey: 'view:admin_transport_rules',
				subMenus: [
					{
						href: AppRoute.ParcelTransport,
						title: PATH_NAMES.ParcelTransport,
						permissionKey: 'view:admin_transport_rules_colis',
					},
				],
			},
			{
				title: PATH_NAMES.PrintValues,
				permissionKey: 'view:print_value',
				href: AppRoute.PrintValues,
			},
      {
				title: PATH_NAMES.Import,
				permissionKey: 'view:import',
				href: AppRoute.Import,
			},
		],
	},
]);
