import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import AppListTable from 'components/tables/list-table/AppListTable';
import { Distribution, DistributionsGroupedByAddresses, groupedDistributionByAddresses } from 'utils/distribution';
import { Box } from '@mui/system';
import { Button } from '@mui/material';

type QuoteDistributionTableProps = { distributions: Distribution[]; closePopin?: () => void };

function useDistributionsTable(distributions: DistributionsGroupedByAddresses[]) {
	const columns = useMemo<Column[]>(
		() => [
			{
				Header: 'Magasin',
				accessor: 'clientName',
			},
			{
				Header: 'Adresse de livraison',
				accessor: 'clientShippingAddress.address.name',
			},
			{
				Header: 'N. de magasin',
				accessor: 'clientShippingAddress.contact.storeCode',
			},
			{
				Header: 'Contact',
				accessor: 'clientShippingAddress.contact.directorName',
			},
			{
				Header: 'Téléphone',
				accessor: 'clientShippingAddress.contact.phone',
			},
			{
				Header: 'Adresse 1',
				accessor: 'clientShippingAddress.address.firstAddress',
			},
			{
				Header: 'Adresse 2',
				accessor: 'clientShippingAddress.address.secondAddress',
			},
			{
				Header: 'Adresse 3',
				accessor: 'clientShippingAddress.address.thirdAddress',
			},
			{
				Header: 'Code postal',
				accessor: 'clientShippingAddress.address.zipcode',
			},
			{
				Header: 'Ville',
				accessor: 'clientShippingAddress.address.city',
			},
			...distributions[0]?.productIdsQuantities?.map((product, index) => {
				return {
					Header: `${product.productName}`,
					accessor: `productIdsQuantities[${index}].quantity`,
				};
			}),
		],
		[distributions]
	);

	return useTable({
		columns,
		data: distributions,
	});
}

const OrderDistributionTable = ({ distributions, closePopin }: QuoteDistributionTableProps) => {
	const groupedDistributions = useMemo(() => {
		return groupedDistributionByAddresses(distributions);
	}, [distributions]);
	const tableInstance = useDistributionsTable(groupedDistributions);

	return (
		<Box mb={4} px={2}>
			<AppListTable tableInstance={tableInstance} />

			<Box display="flex" justifyContent="flex-end" marginTop={10}>
				<Button variant="outlined" color="primary" onClick={closePopin}>
					Fermer
				</Button>
			</Box>
		</Box>
	);
};

export default OrderDistributionTable;
