import { useMutation } from '@apollo/client';
import { LoginInput } from '../../graphql/types';
import { LOGIN } from '../graphql/mutations';
import { LoginMutation, LoginMutationVariables } from '../graphql/mutations.definitions';

export const useLogin = () => {
	const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);

	const loginUser = async (loginInput: LoginInput) => {
		const result = await login({ variables: { data: loginInput } });
		if (!result.data) throw new Error();
		return result;
	};

	return loginUser;
};
