import { BigShopConfigUpsertInput } from '../../graphql/types';
import { isString } from '../../utils/typeGuards';

export const formatBigShopConfigQuantityToFloat = (bigShopConfig: BigShopConfigUpsertInput) => {
	const callQuantity = isString(bigShopConfig.callQuantity)
		? parseFloat(bigShopConfig.callQuantity)
		: bigShopConfig.callQuantity;
	const predefinedQuantity1 = isString(bigShopConfig.predefinedQuantity1)
		? parseFloat(bigShopConfig.predefinedQuantity1)
		: bigShopConfig.predefinedQuantity1;
	const predefinedQuantity2 = isString(bigShopConfig.predefinedQuantity2)
		? parseFloat(bigShopConfig.predefinedQuantity2)
		: bigShopConfig.predefinedQuantity2;
	const predefinedQuantity3 = isString(bigShopConfig.predefinedQuantity3)
		? parseFloat(bigShopConfig.predefinedQuantity3)
		: bigShopConfig.predefinedQuantity3;
	const predefinedQuantity4 = isString(bigShopConfig.predefinedQuantity4)
		? parseFloat(bigShopConfig.predefinedQuantity4)
		: bigShopConfig.predefinedQuantity4;
	const predefinedQuantity5 = isString(bigShopConfig.predefinedQuantity5)
		? parseFloat(bigShopConfig.predefinedQuantity5)
		: bigShopConfig.predefinedQuantity5;
	return {
		...bigShopConfig,
		callQuantity,
		predefinedQuantity1,
		predefinedQuantity2,
		predefinedQuantity3,
		predefinedQuantity4,
		predefinedQuantity5,
	};
};
