import { GetOrderByIdQuery, GetOrdersQuery } from '../../graphql/queries.definitions';
import {
	Address,
	BoxShippingMode,
	Client,
	ClientPrice,
	ClientQuoteItem,
	FollowOrderStatus,
	MarginType,
	OrderItemSuppliersPricesModel,
	OrderStatus,
	TransportMoment,
	TransportType,
} from '../../../graphql/types';
import { TransporterPrices } from '../../definition';

export enum OrderProductStatusValue {
	Waiting = 'en attente',
	ManufacturingInProgress = 'fabrication en cours',
	ShippingInProgress = 'livraison en cours',
	Delivered = 'livré',
	Faillure = 'Erreur de publication',
}

export type OrderFormModel = GetOrderByIdQuery['order'];

export type OrdersFormModel = GetOrdersQuery['ordersWithPagination']['orders'][0];

export type OrderItemFormModel = NonNullable<GetOrderByIdQuery['order']['orderItem']>[0];

export type ChildOrderFormModel = NonNullable<GetOrderByIdQuery['order']['childrenOrder']>[0];

export type ChildOrderItemsFormModel = NonNullable<
	NonNullable<GetOrderByIdQuery['order']['childrenOrder']>[0]['orderItem']
>[0];

export type GenericPriceDetailsType = {
	[x: string]: number | string | unknown;
};

type TransportsType = {
	isMechanizable: boolean;
	transportMoment: TransportMoment;
	deliveryParcel: BoxShippingMode;
	bestDeliveryParcelPurchasePrice: number;
	bestTransporterId: string;
};

export type OrderItemsType = NewOrderItem & {
	transporter?: TransporterPrices[] | null;
	bestTransporter?: TransporterPrices | null;
};

export type SubItemPriceDetails = {
	label: string;
	productPrice?: GenericPriceDetailsType;
	transportPrice?: GenericPriceDetailsType;
	totalWithRfa?: GenericPriceDetailsType;
	transports?: TransportsType;
	product?: GenericPriceDetailsType;
};

export type PriceDetailsInterface = NewOrderItem & {
	bestSupplier?: OrderItemSuppliersPricesModel & { deliveryDepartment?: string | null };
};

export type PriceDetailsType = {
	orderItem?: PriceDetailsInterface;
	label: string;
};

export const DETAILS_BY_PRODUCT_TABLE_HEAD_COLUMNS = [
	'Miniature',
	'Produit',
	'Article',
	'Quantité',
	'Poids (kg)',
	'Fournisseur',
	'Numéro commande Fournisseur',
	'',
];

export const PRODUCT_STORES_DETAILS_TABLE_HEAD_COLUMNS = [
	'Magasin',
	'Nom adresse de livraison',
	'Ville',
	'Quantité',
	'Lien de suivi',
	'Livré',
	'Réf legacy',
];

export const DETAILS_BY_STORE_TABLE_HEAD_COLUMNS = ['Magasin', 'Adresse de facturation', ''];

export const STORES_PRODUCT_DETAILS_TABLE_HEAD_COLUMNS = [
	'Produit',
	'Quantité',
	'Fournisseur',
	'Statut',
	'Lien de suivi',
];

export const initialOrderItem = {
	isWithRfaProduct: false,
	isWithRfaTransport: false,
	manufacturingProgress: false,
	onePacDocumentId: '',
	productMargin: 0,
	realProductMargin: 0,
	realTransportMargin: 0,
	rfaRate: 0,
	shippingProgress: false,
	transportMargin: 0,
	id: '',
	productId: '',
	numberVersion: 0,
	quantity: 0,
	productName: '',
	productTotalWeight: 0,
	numberShots: 0,
	provisionDate: null,
	collectionDate: null,
	expectedDeliveryDate: null,
	supplierComment: '',
	collectionComment: '',
	deliveryComment: '',
	numberFileProcessing: 0,
	numberPlateChanges: 0,
	tools: false,
	transportPurchasePriceHt: 0,
	transportPurchasePriceExcludingVAT: 0,
	transportMoment: TransportMoment.InWeek,
	transportSellingPriceHtAdditionalMargin: MarginType.Percent,
	transportType: TransportType.WithoutTransport,
	transportRfa: 0,
	transportSellingPriceMargin: 0,
	transportSellingPriceByDefaultHt: 0,
	transportPurchasePriceHtWithRfa: 0,
	corsicaDeliveryExtra: 0,
	isMechanizable: false,
	isFinalDistribution: false,
	transportSellingPriceHtWithClientRfa: 0,
	transportSellingPriceHtWithClientRfaRectified: 0,
	deliveryParcel: BoxShippingMode.BeforeThirteen,
	totalPurchasePriceExcludingTax: 0,
	transporterPurchasePriceExcludingVAT: 0,
	transporterPurchaseExcludingVATWithRfa: 0,
	transporterRfaRate: 0,
	productPurchasePriceExcludingVAT: 0,
	productPurchasePriceExcludingVATWithRFA: 0,
	supplierRspRate: 0,
	supplierRfaRate: 0,
	bestDeliveryParcelPurchasePrice: 0,
};

export interface PriceCell {
	width: string;
	name: string;
	isBorder: boolean;
	hasRate: boolean;
	label: string;
}

export type SelectProduct = ClientQuoteItem & {
	supportId: string;
};

export const initialOrder = {
	buyingPriceHt: 0,
	buyingPriceHtWithRfa: 0,
	followStatus: FollowOrderStatus.WaitingValidation,
	sellingPriceHt: 0,
	clientId: '',
	status: OrderStatus.InProgress,
	billingAddress: { name: '' },
	invoiceFile: null,
	invoices: [],
	orderItem: [],
	newOrderItem: initialOrderItem,
	clientPricesExisting: [],
	clientName: '',
	marginWithClientRfa: 0,
	realMargin: 0,
	orderItemIdsToDelete: [],
	id: '',
	shippingAddress: null,
};

export type SelectBillingAddress = Pick<Client, 'billingAddress' | 'name' | 'id'>;
export type NewOrderItem = Omit<OrderFormModel['orderItem'][0], 'product'>;
export type TransportColisType = Pick<PriceDetailsType, 'orderItem'> & {
	handleEdit: () => void;
};

export type OrderTypeToSubmit = OrderFormModel & {
	newOrderItem: NewOrderItem & {
		transporter?: TransporterPrices[] | null;
		bestTransporter?: TransporterPrices | null;
	};
	clientPricesExisting: ClientPrice[];
	orderItemIdsToDelete: string[];
	shippingAddress?: Address | null;
};
