import React, { useEffect } from 'react';
import DashedHeader from '../../../../components/DashedHeader';
import { Box } from '@mui/material';
import OrderPrice from '../../order-cockpit/components/OrderPrice';
import { useFormikContext } from 'formik';
import { Order } from '../../../../graphql/types';
import usePrevious from '../../../../hooks/usePrevious';
import { getSupplierPricesByProduct } from './helpers';

const PricesSuppliersByProduct = (): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<Order>();
	const previousField = usePrevious(values.orderItem);

	useEffect(() => {
		if (JSON.stringify(previousField) !== JSON.stringify(values.orderItem)) {
			const pricesSuppliersByProduct = getSupplierPricesByProduct(values.orderItem, values.sellingPriceHt);
			setFieldValue('buyingPriceHtWithRfa', pricesSuppliersByProduct.buyingPriceHtWithRfa);
			setFieldValue('buyingPriceHt', pricesSuppliersByProduct.buyingPriceHt);
			setFieldValue('marginWithClientRfa', pricesSuppliersByProduct.marginWithClientRfa);
			setFieldValue('sellingPriceHt', values.sellingPriceHt);
			setFieldValue('realMargin', pricesSuppliersByProduct.realMargin);
			setFieldValue('marginRate', pricesSuppliersByProduct.marginRate);
		}
	}, [values.orderItem, previousField, setFieldValue, values.sellingPriceHt]);

	return (
		<Box width="40%" pl={3}>
			<DashedHeader>Prix pour les fournisseurs les moins cher par produit</DashedHeader>
			<Box>
				<OrderPrice
					variant="h5"
					title="Total PA HT avec RFA (€) :"
					price={values.buyingPriceHtWithRfa}
					pb={1}
					color="textSecondary"
				/>
				<OrderPrice variant="h2" title="Total PA HT sans RFA (€) :" price={values.buyingPriceHt} pb={1} />
				<OrderPrice variant="h2" title="Total PV HT (€) :" price={values.sellingPriceHt} pb={1} />
				<OrderPrice
					variant="h5"
					title="Marge HT avec RFA client (€) :"
					price={values.marginWithClientRfa}
					color="textSecondary"
				/>
				<OrderPrice variant="h4" title="Marge HT réel (€) :" price={values.realMargin} />
				<OrderPrice variant="h5" title="Taux de marge (%) :" price={values.marginRate} color="textSecondary" />
			</Box>
		</Box>
	);
};

export default PricesSuppliersByProduct;
