import * as Yup from 'yup';

export const validateSchema = Yup.object().shape({
	prints: Yup.array().of(
		Yup.object({
			attribute: Yup.array().of(
				Yup.object({
					values: Yup.array().of(
						Yup.object({
							printValues: Yup.number(),
						})
					),
				})
			),
		})
	),
});
