import React from 'react';
import { useField } from 'formik';
import { InvoiceStatus } from '../definitions';
import { TextField, TextFieldProps } from '@mui/material';

type TProps = { name: string } & Omit<
	TextFieldProps,
	'value' | 'onChange' | 'error' | 'helperText' | 'format' | 'outlined'
>;

export const ShowInvoiceStatus = ({ name, ...rest }: TProps) => {
	const [field] = useField<null | undefined>(name);

	return (
		<TextField
			name={name}
			value={field.value ? InvoiceStatus[field.value] : ''}
			onChange={field.onChange}
			disabled={true}
			variant="standard"
			style={{ borderColor: 'transparent' }}
			{...rest}
		/>
	);
};
