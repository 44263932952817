import React from 'react';
import { Box, Typography } from '@mui/material';
import colors from '../../../../../app/theme/colors.scss';
import { LayoutField } from '../../../../../components/form/LayoutField';
import { Field } from 'formik';
import { Checkbox } from 'formik-mui';
import LayoutFieldForm from '../../../../../components/form/LayoutFieldForm';

interface ContactFormProps {
	disabled: boolean;
}

const ContactForm = ({ disabled }: ContactFormProps): JSX.Element => {
	return (
		<>
			<Box bgcolor={colors.grayCard} textAlign="center" borderColor={colors.indigo700} p={1} mb={2} pt={2}>
				<Typography variant="h4">Contact</Typography>
			</Box>
			<LayoutFieldForm label="Nom du directeur" disabled={disabled} name="contact.directorName" />
			<LayoutFieldForm label="Code magasin" disabled={disabled} name="contact.storeCode" />
			<LayoutFieldForm label="Téléphone" disabled={disabled} name="contact.phone" />
			<LayoutFieldForm label="Fax" disabled={disabled} name="contact.fax" />
			<LayoutFieldForm label="Site web" disabled={disabled} name="contact.website" />
			<LayoutFieldForm label="Email" disabled={disabled} name="contact.email" />
			<LayoutField label="">
				<Box display="flex" alignItems="center" justifyContent="flex-start">
					<Field name="contact.isNotificated" component={Checkbox} type="checkbox" disabled={disabled} size="small" />
					<Box>
						<Typography variant="h5">Autoriser les notifications email</Typography>
					</Box>
				</Box>
			</LayoutField>
		</>
	);
};

export default ContactForm;
