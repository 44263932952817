import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { HeaderGroup, Row, TableInstance } from 'react-table';
import { Box } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';

import Loading from '../../../components/loading/Loading';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import colors from 'app/theme/colors.scss';

interface ListTableProps<T extends object> {
	tableInstance: TableInstance<T>;
	onRowClick?: (row: Row<T>) => void;
	onLoadMore?: () => void;
	bgColor?: string;
	hasFooter?: boolean;
	withBorder?: boolean;
	withOverflowScroll?: boolean;
	tableStyle?: React.CSSProperties;
	ariaLabel?: string;
	isloading?: boolean;
}

export function AppListTable<T extends object>({
	tableInstance,
	onRowClick,
	bgColor,
	onLoadMore,
	hasFooter = false,
	withBorder = true,
	withOverflowScroll = true,
	tableStyle,
	ariaLabel,
	isloading,
}: ListTableProps<T>): JSX.Element {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, scroll, footerGroups } = tableInstance;

	const { waypointRef } = useInfiniteScroll(onLoadMore, isloading);
	const tableContainerRef = React.useRef<HTMLDivElement | null>();

	return (
		<Box {...{ ref: tableContainerRef }} component={TableContainer} aria-label={ariaLabel}>
			<Table size="small" stickyHeader={scroll === 'scroll'} {...getTableProps()} style={tableStyle}>
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column: HeaderGroup<T & { isSearchable?: boolean }>) => (
								<TableCell key={column.id}>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<Box>{column.render('Header')}</Box>

										{column?.canSort && (
											<span {...column.getHeaderProps(column.getSortByToggleProps())}>
												<UnfoldMore style={{ verticalAlign: 'middle' }} />
											</span>
										)}
									</Box>

									{column?.canFilter && column.render('Filter')}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{rows.map((row, index) => {
						prepareRow(row);
						return (
							<TableRow
								{...row.getRowProps()}
								selected={row.isSelected}
								onClick={() => onRowClick?.(row)}
								sx={{
									cursor: !!onRowClick ? 'pointer' : 'default',
									'&:hover': { backgroundColor: colors.grey },
									backgroundColor: bgColor ? bgColor : '',
								}}
							>
								{row.cells.map((cell, index) => (
									<TableCell
										{...cell.getCellProps()}
										sx={{
											borderLeft: withBorder && index === 0 ? `8px solid ${colors.primary}` : 0,
										}}
									>
										{cell.render('Cell')}
									</TableCell>
								))}
							</TableRow>
						);
					})}
				</TableBody>
				{hasFooter && footerGroups ? (
					<TableFooter
						style={{
							position: 'sticky',
							bottom: 0,
							background: 'white',
							borderLeft: `8px solid ${colors.primary}`,
						}}
					>
						{footerGroups.map((footerGroup) => (
							<TableRow {...footerGroup.getFooterGroupProps()} sx={{ backgroundColor: bgColor ? bgColor : '' }}>
								{footerGroup.headers.map((column: HeaderGroup<T>) => (
									<TableCell key={column.id}>{column.render('Footer')}</TableCell>
								))}
							</TableRow>
						))}
					</TableFooter>
				) : null}
			</Table>
			{isloading ? <Loading loading={isloading} /> : <div ref={waypointRef} />}
		</Box>
	);
}

export default AppListTable;
