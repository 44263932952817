import { useLazyQuery } from '@apollo/client';
import { GET_CURRENT_USER } from '../graphql/queries';
import { GetCurrentUserQuery, GetCurrentUserQueryVariables } from '../graphql/queries.definitions';

const useCurrentUserLazyQuery = () => {
	const [getCurrentUser, { data }] = useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GET_CURRENT_USER);

	return { currentUser: data?.currentUser, getCurrentUser };
};

export default useCurrentUserLazyQuery;
