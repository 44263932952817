import { useMutation } from '@apollo/client';
import { INSERT_FILE_REFERENCE } from 'file-reference/graphql/mutations';
import {
	InsertFileReferenceMutation,
	InsertFileReferenceMutationVariables,
} from 'file-reference/graphql/mutations.definitions';

import { FileReferenceInsertInput, Source } from 'graphql/types';

const useFileReferenceInsert = () => {
	const [insertFileReferenceMutation] = useMutation<InsertFileReferenceMutation, InsertFileReferenceMutationVariables>(
		INSERT_FILE_REFERENCE
	);

	const insertFileReference = async (fileReference: FileReferenceInsertInput) => {
		return (
			await insertFileReferenceMutation({
				variables: { fileReference: { ...fileReference, source: Source.OneSupply } },
				awaitRefetchQueries: true,
				refetchQueries: ['GetFileReferences'],
			})
		).data?.insertFileReference;
	};

	return { insertFileReference };
};

export default useFileReferenceInsert;
