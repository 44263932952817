import Grid from '@mui/material/Grid';
import { Box, Collapse, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../../app/theme/colors.scss';
import ButtonDisplayContent from '../../../../components/ButtonDisplayContent';
import { HORIZONTAL_CLIENT_PRICE_DATA_CELL, VERTICAL_CLIENT_PRICE_DATA_CELL } from '../definitions';
import React, { useState } from 'react';
import { ItemCellPrice } from '../../../../components/summaryPrice/ItemCellPrice';
import { LayoutField } from '../../../../components/form/LayoutField';
import FormikTextField from '../../../../components/form/FormikTextField';
import TransportInformation from '../../component/TransportInformation';
import DeleteProductPopin from './DeleteProductPopin';

export const useStyles = makeStyles({
	cell: {
		backgroundColor: colors.white,
		borderLeft: '3px solid',
		borderTopLeftRadius: 3,
		borderBottomLeftRadius: 3,
		borderLeftColor: colors.indigo700,
		padding: 2,
	},
});

interface props {
	productName: string | null;
	index: number;
}

const SalePriceTable = ({ productName, index }: props): JSX.Element => {
	const classes = useStyles();
	const [displayOrderInformation, setDisplayOrderInformation] = useState(false);

	return (
		<>
			<Grid item xs={11}>
				<Box mb={0.5} width="100%">
					<Box display="flex" bgcolor={colors.white} className={classes.cell}>
						<Box p={1} flexGrow={1}>
							<Typography variant="h4">{productName}</Typography>
						</Box>
						<Box mt={1} mr={2}>
							<DeleteProductPopin index={index} />
						</Box>
						<ButtonDisplayContent
							displayContent={displayOrderInformation}
							OnDisplayContent={setDisplayOrderInformation}
							isActive
						/>
					</Box>
					<Collapse in={displayOrderInformation}>
						<Box width="100%" m="10px auto" bgcolor={colors.blue200} p={2}>
							<Box width="100%" display="flex" justifyContent="space-around">
								{HORIZONTAL_CLIENT_PRICE_DATA_CELL(index).map(({ width, name, isBorder, hasRate, label }, i) => (
									<ItemCellPrice
										key={`horizontalDataCell_${i}`}
										width={width}
										name={name}
										isBorder={isBorder}
										hasRate={hasRate}
										label={label}
									/>
								))}
							</Box>
							<Box display={'flex'} width="100%">
								<Box m={3} width={'80%'} flexGrow={1} display="flex" justifyContent="space-between">
									<Box width="65%">
										<TransportInformation disabled name={`clientQuoteItem[${index}]`} />
									</Box>

									<Box width="35%">
										<LayoutField label="Commentaires">
											<Box bgcolor={colors.white} width="100%">
												<FormikTextField
													fullWidth
													multiline={true}
													rows={5}
													name={`clientQuoteItem[${index}].comment`}
													variant="outlined"
													disabled
												/>
											</Box>
										</LayoutField>
									</Box>
								</Box>
								<Box width={'20%'}>
									{VERTICAL_CLIENT_PRICE_DATA_CELL(index).map(({ width, name, isBorder, hasRate, label }, i) => (
										<Box width="100%" display="flex" justifyContent="flex-end" key={`verticalDataCell_${i}`}>
											<ItemCellPrice width={width} name={name} isBorder={isBorder} hasRate={hasRate} label={label} />
										</Box>
									))}
								</Box>
							</Box>
						</Box>
					</Collapse>
				</Box>
			</Grid>
		</>
	);
};
export default SalePriceTable;
