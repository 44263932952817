import React from 'react';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loading from 'components/loading/Loading';
import IconButton from '@mui/material/IconButton';
import useGetProductById from 'product/hooks/useGetProductById';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'app/theme/colors.scss';
import { CharacteristicByProduct } from 'product/components/CharacteristicByProduct';
import { ProductInformation } from 'product/components/ProductInformation';
import { DialogTitle } from '@mui/material';

interface ProductOrderDetailsProps {
	id: string;
	closeDetailsProductPopin: (event: React.MouseEvent) => void;
	codeAt: string | null | undefined;
	productName: string;
	postDescription: string | null | undefined;
	thumbnailAssetUrl?: string | null;
}

const useStyles = makeStyles(() => ({
	header: {
		margin: 0,
		padding: '20px',
	},
	headerDiviver: {
		width: '2px',
		marginLeft: '25px',
		marginRight: '25px',
	},
}));

const ProductOrderDetails = ({
	id,
	closeDetailsProductPopin,
	codeAt,
	productName,
	thumbnailAssetUrl,
	postDescription,
}: ProductOrderDetailsProps) => {
	const classes = useStyles();
	const { product, loading } = useGetProductById(id);
	if (loading) return <Loading loading={loading} />;

	return (
		<>
			<Box position="sticky" top={0} bgcolor={colors.documentCardRowLight}>
				<DialogTitle className={classes.header}>
					<Box display="flex" alignItems="center">
						<Typography variant="h4">{productName}</Typography>
						<Divider className={classes.headerDiviver} orientation="vertical" flexItem />
						<Typography variant="body1">{codeAt}</Typography>
					</Box>
					<Box right="30px" top="22px" position="absolute">
						<IconButton aria-label="close" onClick={closeDetailsProductPopin} size="large">
							<CloseIcon />
						</IconButton>
					</Box>
				</DialogTitle>
				<Divider />
			</Box>
			<Box>
				<Grid container>
					<Grid container item xs={6}>
						{postDescription ? (
							<Grid item alignContent="center" sx={{ justifyContent: 'center' }} xs={12}>
								<Typography variant="h5" align="center">
									{postDescription}
								</Typography>
							</Grid>
						) : null}
						<Grid item xs={12}>
							<img
								width="100%"
								height="100%"
								src={
									thumbnailAssetUrl ??
									'https://image.shutterstock.com/image-vector/empty-placeholder-image-icon-design-260nw-1366372628.jpg'
								}
								alt=""
							/>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Box p={1.5}>
							<ProductInformation label="Nom Support" value={product?.support.name} />
							{product && <CharacteristicByProduct product={product} />}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default ProductOrderDetails;
