import { useMemo } from 'react';
import { Column, useRowSelect, useTable } from 'react-table';
import { getEntityTitle } from './helpers';
import { EntityType } from '../../../../graphql/types';
import { Typography } from '@mui/material';
import React from 'react';

const useEntityTypesTable = (entityTypes: Array<EntityType>) => {
	const columns = useMemo<Column<{ type: EntityType }>[]>(
		() => [
			{
				accessor: 'type',
				Header: 'Entités disponibles',
				Cell: (row) => <Typography>{getEntityTitle(row)}</Typography>,
			},
		],
		[]
	);

	const data = useMemo(() => entityTypes.map((type) => ({ type })), [entityTypes]);

	return useTable(
		{
			columns,
			data,
			getRowId: ({ type }) => type,
		},
		useRowSelect
	);
};

export default useEntityTypesTable;
