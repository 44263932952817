export default function Deconix(dataAfter: { [key: string]: any[] }): { [key: string]: any[] } {
	let deconixData: { [key: string]: any[] } = JSON.parse(JSON.stringify(dataAfter));
	let dataAfterLengR: number = dataAfter[Object.keys(dataAfter)[0]].length;
	let dataAfterCountC: number = 0;
	let dataAfterCountR: number = 1;

	//Add new col through '\' : Col looping
	function dataAfterCol(row: Array<any>, rowind: number, first: boolean, newcolind: number) {
		if (dataAfterCountC < dataAfter[Object.keys(dataAfter)[0]][rowind].length) {
			const MaxColCount = Math.max(
				...dataAfter[Object.keys(dataAfter)[0]].map((r) => r[dataAfterCountC].value.toString().split('\\').length)
			);
			let values = [row[dataAfterCountC]];

			if (first) {
				newcolind = dataAfterCountC;
				first = false;
			}

			if (typeof row[dataAfterCountC].value === 'string' && row[dataAfterCountC].value.includes('\\')) {
				values = row[dataAfterCountC].value.split('\\').map((elm: any) => {
					return { value: elm };
				});
			}

			while (values.length < MaxColCount) {
				values.push({ value: '' });
			}

			//Change Headers name
			if (dataAfter[Object.keys(dataAfter)[0]].length - 1 === rowind && MaxColCount > 1) {
				let header = deconixData[Object.keys(deconixData)[0]][0][newcolind].value;
				let headers = Array(values.length - 1)
					.fill(header)
					.map(() => {
						header += "'";
						return { value: header };
					});

				deconixData[Object.keys(dataAfter)[0]][0].splice(newcolind + 1, 0, ...headers);
			}

			deconixData[Object.keys(deconixData)[0]][rowind].splice(newcolind, 1, ...values);
			newcolind += MaxColCount;
			dataAfterCountC++;
			dataAfterCol(row, rowind, first, newcolind);
		}
	}

	//Add new col through '\' : Row looping
	function deconixAddCol() {
		if (dataAfterCountR < dataAfterLengR) {
			let newcolind: number = 0;
			let first: boolean = true;

			dataAfterCol(dataAfter[Object.keys(dataAfter)[0]][dataAfterCountR], dataAfterCountR, first, newcolind);
			dataAfterCountC = 0;
			dataAfterCountR++;
			deconixAddCol();
		}
	}

	deconixAddCol();

	let lengR: number = deconixData[Object.keys(deconixData)[0]].length;
	let countC: number = 0;
	let countR: number = 0;

	//Add new row through '§' : Col looping
	function deconixCol(row: Array<any>, rowind: number) {
		if (countC < deconixData[Object.keys(deconixData)[0]][rowind].length) {
			if (typeof row[countC].value === 'string' && row[countC].value.includes('§')) {
				const valuesNewRow = row[countC].value.split('§').map((elm: string) => {
					return { value: elm };
				});

				deconixData[Object.keys(deconixData)[0]][rowind].splice(countC, 1, valuesNewRow[0]);
				for (let i = 1; i < valuesNewRow.length; i++) {
					deconixData[Object.keys(deconixData)[0]].splice(
						rowind + 1,
						0,
						JSON.parse(JSON.stringify(deconixData[Object.keys(deconixData)[0]][rowind]))
					);
					deconixData[Object.keys(deconixData)[0]][rowind + 1].splice(countC, 1, valuesNewRow[i]);
				}
			}
			countC++;
			deconixCol(row, rowind);
		}
	}

	//Add new row through '§' : Row looping
	function deconixAddRow() {
		if (countR < lengR) {
			deconixCol(deconixData[Object.keys(deconixData)[0]][countR], countR);
			countC = 0;
			countR++;
			deconixAddRow();
		}
	}

	deconixAddRow();

	return deconixData;
}
