import gql from 'graphql-tag';

const INSERT_SUPPORT = gql`
	mutation InsertSupport($name: String!) {
		insertSupport(name: $name) {
			name
		}
	}
`;

const UPDATE_SUPPORT = gql`
	mutation UpdateSupport(
		$supportId: String!
		$ecotaxe: Boolean!
		$category: SupportCategory
		$pricesSource: PricesSource!
		$isActif: Boolean!
		$clariPrintCorrespondence: ClariPrintCorrespondence
		$attributesChecked: [String!]!
		$fabricationTimes: [FabricationTimeInput!]!
	) {
		updateSupportAttributes(
			data: {
				pricesSource: $pricesSource
				supportId: $supportId
				category: $category
				excludingEcotax: $ecotaxe
				isActif: $isActif
				clariPrintCorrespondence: $clariPrintCorrespondence
				attributesIds: $attributesChecked
				fabricationTimes: $fabricationTimes
			}
		) {
			id
		}
	}
`;

export { UPDATE_SUPPORT, INSERT_SUPPORT };
