import { useQuery } from '@apollo/client';
import { CurrentUserClientsQuery, CurrentUserClientsQueryVariables } from '../graphql/queries.definitions';
import { GET_CLIENTS_BY_CURRENT_USER } from '../graphql/queries';

export const useClientsByCurrentUser = (hasChildren: boolean) => {
	const { data, loading } = useQuery<CurrentUserClientsQuery, CurrentUserClientsQueryVariables>(
		GET_CLIENTS_BY_CURRENT_USER,
		{
			variables: {
				hasChildren,
			},
		}
	);

	return { clientsUsers: data?.clientsUsers, loading };
};
