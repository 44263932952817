import React, { ReactNode } from 'react';
import { useCallback } from 'react';
import PermissionContext from './PermissionContext';

type PermissionProviderProps = {
	permissions: string[];
	children: ReactNode;
};

// This provider is intended to be surrounding the whole application.
// It receives users permissions as parameter
const PermissionProvider = ({ permissions, children }: PermissionProviderProps): JSX.Element => {
	// it returns whether the requested permission is available in the list of permissions
	const isAllowedTo = useCallback((permission: string) => permissions.includes(permission), [permissions]);

	return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
