import * as React from 'react';
import { Box } from '@mui/material';
import { isEmpty } from 'ramda';

import AppListTable from 'components/tables/list-table/AppListTable';
import useFileReferencesTable from '../../hooks/useFileReferencesTable';
import { GetFileReferencesQueryVariables } from '../../graphql/queries.definitions';
import useFileReferences from '../../hooks/useFileReferencesQuery';
import colors from '../../../app/theme/colors.scss';

const FileReferencesDatatable = () => {
	const [search, setSearch] = React.useState<GetFileReferencesQueryVariables['search']>(undefined);
	const [sort, setSort] = React.useState<GetFileReferencesQueryVariables['sort']>(undefined);

	const { fileReferences, loading, fetchMore, refetch } = useFileReferences(
		search,
		sort,
		{
			take: 20,
			skip: 0,
		},
		{}
	);

	const filesReferenceDatatable = useFileReferencesTable(fileReferences ?? [], loading);

	const loadMore = () => {
		if (!loading) {
			fetchMore({
				variables: {
					search,
					sort,
					pagination: { take: 20, skip: fileReferences?.length },
					filters: {},
				},
			});
		}
	};
	React.useEffect(() => {
		if (!isEmpty(filesReferenceDatatable.state.filters)) {
			const formattedQuery = filesReferenceDatatable?.state?.filters.reduce<{ [key: string]: string }>(
				(acc, { id, value }: { id: string; value: string }) => {
					if (!value) {
						return {};
					}
					acc[id] = value;
					return acc;
				},
				{}
			);

			setSearch(formattedQuery);
		} else {
			setSearch({});
		}
	}, [filesReferenceDatatable, filesReferenceDatatable.state.filters]);

	React.useEffect(() => {
		if (!isEmpty(filesReferenceDatatable?.state?.sortBy)) {
			const { id, desc } = filesReferenceDatatable?.state?.sortBy?.[0] ?? {};
			const isDesc = desc ? 'desc' : 'asc';

			setSort({ [id]: isDesc });
		}
	}, [filesReferenceDatatable, filesReferenceDatatable.state.sortBy]);

	React.useEffect(() => {
		if (sort || search) {
			refetch();
		} else {
		}
	}, [refetch, search, sort]);
	return (
		<Box mt={3} mb={5}>
			<AppListTable
				tableInstance={filesReferenceDatatable}
				bgColor={colors.bodyBackgroundLight}
				onLoadMore={() => loadMore()}
				tableStyle={{ maxHeight: '60%' }}
			/>
		</Box>
	);
};

export default FileReferencesDatatable;
