import gql from 'graphql-tag';
import ATTRIBUT_FRAGMENT from '../../../graphql/fragments/attribute/attributeFragment';
import { PRODUCT_FRAGMENT } from '../../../graphql/fragments/product/productFragment';

const GET_ATTRIBUTES = gql`
	query GetAttributes {
		attributes {
			...Attribute
		}
	}
	${ATTRIBUT_FRAGMENT}
`;

const GET_SUPPORTS = gql`
	query GetSupports($filters: SupportFiltersInput) {
		supports(filters: $filters) {
			id
			name
			category
			excludingEcotax
			pricesSource
			clariPrintCorrespondence
			isActif
			fabricationTimes {
				quantity
				standardMode
				expressMode
				urgentMode
			}
		}
	}
`;

const GET_SUPPORT_BY_ID = gql`
	query GetSupportById($supportId: String!) {
		support(id: $supportId) {
			id
			name
			category
			excludingEcotax
			pricesSource
			clariPrintCorrespondence
			isActif
			fabricationTimes {
				quantity
				standardMode
				expressMode
				urgentMode
			}
			supportAttributes {
				...Attribute
			}
			printAttributes {
				printValue
				attributeId
				printName
				attribute {
					...Attribute
				}
			}
			products {
				...Product
			}
		}
	}
	${ATTRIBUT_FRAGMENT}
	${PRODUCT_FRAGMENT}
`;

export { GET_ATTRIBUTES, GET_SUPPORT_BY_ID, GET_SUPPORTS };
